import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import Modal from '../containers/modal';
import ButtonColors from '../buttons/buttonColors';
import QuickAddCustomerFormProps from './quickAddCustomerFormProps';
import CustomerCompanySearchField from '../fields/customerCompanySearchField';

class QuickAddCustomerForm extends ObservingComponent<QuickAddCustomerFormProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.quickAddCustomerForm.observationProvider);
    }

    public render({ quickAddCustomerForm }: QuickAddCustomerFormProps): JSX.Element {
        if (!quickAddCustomerForm.isVisible) {
            return null;
        }

        return (
            <Modal
                close={quickAddCustomerForm.close}
                heading={quickAddCustomerForm.heading}
                footerRightButtons={[
                    <Button
                        color={ButtonColors.Secondary}
                        button={quickAddCustomerForm.cancelButton} />,
                    <Button
                        color={ButtonColors.Primary}
                        button={quickAddCustomerForm.saveButton}/>
                ]}>

                <div className="form-container">

                    <div class="pt-1 pb-1 text-sm">
                        Enter the new customer information below and click save.
                    </div>

                    <div class="grid-container">
                        {quickAddCustomerForm.form.fields.map((field, index) => {
                            if (index <= 5) {
                                return (
                                    <div className="sm-6">
                                        <Field
                                            className='stacked-label mb-0'
                                            field={field} />
                                    </div>
                                );
                            }
                        })}
                    </div>

                    <div class="mt-1">
                        <CustomerCompanySearchField
                            className="stacked-label"
                            companySearchField={quickAddCustomerForm.customerCompanySearchField} />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default QuickAddCustomerForm;
