"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ButtonActions;
(function (ButtonActions) {
    ButtonActions["Clicked"] = "Clicked";
    ButtonActions["DisabledChanged"] = "DisabledChanged";
    ButtonActions["ProcessingChanged"] = "ProcessingChanged";
    ButtonActions["LoadingChanged"] = "LoadingChanged";
    ButtonActions["VisibilityChanged"] = "VisibilityChanged";
    ButtonActions["TextChanged"] = "TextChanged";
    ButtonActions["ProcessingTextChanged"] = "ProcessingTextChanged";
    ButtonActions["BadgeUpdated"] = "BadgeUpdated";
    ButtonActions["HoverTextChanged"] = "HoverTextChanged";
})(ButtonActions || (ButtonActions = {}));
exports.default = ButtonActions;
