"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventHistoryType;
(function (EventHistoryType) {
    EventHistoryType[EventHistoryType["Users"] = 1] = "Users";
    EventHistoryType[EventHistoryType["Customers"] = 2] = "Customers";
    EventHistoryType[EventHistoryType["InventoryItems"] = 3] = "InventoryItems";
    EventHistoryType[EventHistoryType["VendorCompanies"] = 4] = "VendorCompanies";
})(EventHistoryType || (EventHistoryType = {}));
exports.default = EventHistoryType;
