import Axios, { AxiosRequestConfig } from 'axios';
import { IApiPlugin, IListResponse, BinaryFile } from 'core.frontend';

export default class ApiPlugin implements IApiPlugin {

    public getTablePage<T>(endpointUrl: string, authenticationToken: string): Promise<IListResponse<T>> {
        return Axios
                .get<IListResponse<T>>(endpointUrl, this.getRequestConfig(authenticationToken))
                .then((res) => res.data);
    }

    public getSingle<T>(endpointUrl: string, authenticationToken?: string): Promise<T> {
        return Axios
                .get<T>(endpointUrl, this.getRequestConfig(authenticationToken))
                .then((res) => {
                    if (res.status === 204) {
                        return null;
                    }

                    return res.data;
                });
    }

    public post<T, K>(endpointUrl: string, authenticationToken: string, payload: T, options: any = null): Promise<K> {

        let config = this.getRequestConfig(authenticationToken);

        if (options) {
            for (const key in options) {
                if (options.hasOwnProperty(key)) {
                    config[key] = options[key];
                }
            }
        }

        return Axios
                .post<K>(endpointUrl, payload, config)
                .then((res) => res.data);
    }

    public uploadFile<T>(endpointUrl: string, authenticationToken: string, file: BinaryFile): Promise<T> {

        var formData = new FormData();
        formData.append('fileName', file.fileName);
        formData.append('fileSize', file.fileSize.toString());
        formData.append('fileType', file.fileType);
        formData.append('formFile', file.formFile);

        for (const key in file) {
            if (!formData.has(key)) {
                formData.append(key, file[key]);
            }
        }

        const headers = this.getRequestConfig(authenticationToken);
        headers['content-type'] = 'multipart-form/data';

        return Axios
            .post<T>(endpointUrl, formData, headers)
            .then((response) => response.data);
    }

    public put<T>(endpointUrl: string, authenticationToken: string, id: number, payload: T): Promise<void> {
        return Axios
                .put<void>(endpointUrl, payload, this.getRequestConfig(authenticationToken))
                .then((res) => res.data);
    }

    public delete(endpointUrl: string, authenticationToken: string, id: number): Promise<void> {
        return Axios
                .delete(endpointUrl, this.getRequestConfig(authenticationToken))
                .then(() => {});
    }

    private getRequestConfig(authenticationToken?: string): AxiosRequestConfig {
        return {
            headers: {
                ...this.getAuthorizationHeder(authenticationToken)
            }
        } as AxiosRequestConfig;
    }

    private getAuthorizationHeder(authenticationToken: string): any {
        if (authenticationToken) {
            return { Authorization: `Bearer ${authenticationToken}` };
        } else {
            return null;
        }
    }
}
