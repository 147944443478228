import { h, Component, JSX } from 'preact';
import TableLoadingPlaceholderRow from './tableLoadingPlaceholderRow';
import TableLoadingPlaceholderTBodyProps from './tableLoadingPlaceholderTBodyProps';

class TableLoadingPlaceholderTBody extends Component<TableLoadingPlaceholderTBodyProps> {

    private getRows = (): JSX.Element[] => {
        const { columnCount, rowCount } = this.props;

        const rows: JSX.Element[] = [];

        for (let i = 0; i < rowCount; i++){
              rows.push(<TableLoadingPlaceholderRow columnCount={columnCount} />);
        }

        return rows;
    }

    public render(): JSX.Element {
        return (
            <tbody>
                { this.getRows() }
            </tbody>
        );
    }
}

export default TableLoadingPlaceholderTBody;
