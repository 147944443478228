"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SystemSettingsPageActions;
(function (SystemSettingsPageActions) {
    SystemSettingsPageActions["RecordLoaded"] = "RecordLoaded";
    SystemSettingsPageActions["RecordLoading"] = "RecordLoading";
    SystemSettingsPageActions["RemoveAccessDomainCancelled"] = "RemoveAccessDomainCancelled";
    SystemSettingsPageActions["ApprovedAccessDomainRemoved"] = "AllowedAccessDomainRemoved";
    SystemSettingsPageActions["ApprovedAccessDomainAdded"] = "ApprovedAccessDomainAdded";
    SystemSettingsPageActions["Saving"] = "Saving";
    SystemSettingsPageActions["Saved"] = "Saved";
    SystemSettingsPageActions["PaneChanged"] = "PaneChanged";
    SystemSettingsPageActions["DepartmentAdded"] = "DepartmentAdded";
    SystemSettingsPageActions["DepartmentRemoved"] = "DepartmentRemoved";
    SystemSettingsPageActions["RemoveDepartmentCancelled"] = "RemoveDepartmentCancelled";
    SystemSettingsPageActions["DepartmentReferenceShown"] = "DepartmentReferenceShown";
    SystemSettingsPageActions["DepartmentReferenceHidden"] = "DepartmentReferenceHidden";
    SystemSettingsPageActions["BusinessAreaAdded"] = "BusinessAreaAdded";
    SystemSettingsPageActions["BusinessAreaRemoved"] = "BusinessAreaRemoved";
    SystemSettingsPageActions["RemoveBusinessAreaCancelled"] = "RemoveBusinessAreaCancelled";
    SystemSettingsPageActions["BusinessAreaReferencesShown"] = "BusinessAreaReferencesShown";
    SystemSettingsPageActions["BusinessAreaReferencesHidden"] = "BusinessAreaReferencesHidden";
    SystemSettingsPageActions["ApiAuthTokenReCreated"] = "ApiAuthTokenReCreated";
})(SystemSettingsPageActions || (SystemSettingsPageActions = {}));
exports.default = SystemSettingsPageActions;
