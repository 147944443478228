"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("../../api");
var utils_1 = require("../../utils");
var _1 = require(".");
var tables_1 = require("../tables");
var pages_1 = require("../../pages");
var downloadButton_1 = require("../button/downloadButton");
var HelpListModal = /** @class */ (function () {
    function HelpListModal(api, currentUserManager) {
        var _this = this;
        this.api = api;
        this.currentUserManager = currentUserManager;
        this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var url, helpListModalData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = api_1.EndpointUrls.HelpUrls + "/GetHelpList" + this.api.buildListQueryString(this.table.recordsPerPage, this.table.currentPage, this.table.sortExpression, this.table.searchPhrase);
                        return [4 /*yield*/, this.api.get(url)];
                    case 1:
                        helpListModalData = _a.sent();
                        this._administratorName = helpListModalData.tenantAdministratorName;
                        this._administratorEmail = helpListModalData.tenantAdministratorEmail;
                        this._administratorPhone = helpListModalData.tenantAdministratorPhone;
                        this.observationProvider.notifyObservers(_1.HelpListModalActions.Loaded);
                        return [2 /*return*/, helpListModalData.helpListItems];
                }
            });
        }); };
        this.videoLinkClicked = function (helpUrl) {
            _this._helpModal = new _1.HelpModal(_this.api, helpUrl.helpUrlType);
            if (_this.currentUserManager.clientChecker.isMobileApp()) {
                _this._selectedVideoUrl = helpUrl.appUrl;
            }
            else {
                _this._selectedVideoUrl = helpUrl.browserUrl;
            }
            _this._helpModal.observationProvider.observe(function () {
                _this.observationProvider.notifyObservers(_1.HelpListModalActions.HelpModalClosed);
            }, _1.HelpModalActions.Closed);
            _this.helpModal.open();
            _this.observationProvider.notifyObservers(_1.HelpListModalActions.HelpModalOpened);
        };
        this.open = function (helpUrlType) {
            if (helpUrlType === void 0) { helpUrlType = null; }
            if (helpUrlType) {
                switch (helpUrlType) {
                    case pages_1.HelpUrlType.TravelAndAccommodationsTile:
                        _this.table.search('Travel And Accommodations Tile');
                        break;
                    case pages_1.HelpUrlType.EventLandingPage:
                        _this.table.search('Event Landing Page');
                        break;
                    case pages_1.HelpUrlType.EventInfoTile:
                        _this.table.search('Event Info Tile');
                        break;
                    case pages_1.HelpUrlType.MeetingsTile:
                        _this.table.search('Meetings Tile');
                        break;
                    case pages_1.HelpUrlType.EmailAndServicesTile:
                        _this.table.search('Email And Services Tile');
                        break;
                    case pages_1.HelpUrlType.AttendeesTile:
                        _this.table.search('Attendees Tile');
                        break;
                    case pages_1.HelpUrlType.BadgingTile:
                        _this.table.search('Badging Tile');
                        break;
                    case pages_1.HelpUrlType.ReportsTile:
                        _this.table.search('Reports Tile');
                        break;
                    case pages_1.HelpUrlType.InventoryTile:
                        _this.table.search('Inventory Tile');
                        break;
                    case pages_1.HelpUrlType.FilesTile:
                        _this.table.search('Files Tile');
                        break;
                    case pages_1.HelpUrlType.CostManagementTile:
                        _this.table.search('Cost Management Tile');
                        break;
                    case pages_1.HelpUrlType.ExtrasTile:
                        _this.table.search('Extras Tile');
                        break;
                    case pages_1.HelpUrlType.EventListPage:
                        _this.table.search('Event List Page');
                        break;
                    case pages_1.HelpUrlType.InventoryListPage:
                        _this.table.search('Inventory List Page');
                        break;
                    case pages_1.HelpUrlType.InventorySchedulerPage:
                        _this.table.search('Inventory Scheduler Page');
                        break;
                    case pages_1.HelpUrlType.InventoryForecast:
                        _this.table.search('Inventory Forecast');
                        break;
                    case pages_1.HelpUrlType.UsersListPage:
                        _this.table.search('Users List Page');
                        break;
                    case pages_1.HelpUrlType.CustomerListPage:
                        _this.table.search('Customer List Page');
                        break;
                    case pages_1.HelpUrlType.VendorsListPage:
                        _this.table.search('Vendors List Page');
                        break;
                    case pages_1.HelpUrlType.UserGroupsListPage:
                        _this.table.search('User Groups List Page');
                        break;
                    case pages_1.HelpUrlType.SystemSettingsPage:
                        _this.table.search('System Settings Page');
                        break;
                    case pages_1.HelpUrlType.CustomerCompanyListPage:
                        _this.table.search('Customer Company List Page');
                        break;
                    case pages_1.HelpUrlType.VendorCompanyListPage:
                        _this.table.search('Vendor Company List Page');
                        break;
                    case pages_1.HelpUrlType.EmailTemplatesListPage:
                        _this.table.search('Email Templates List Page');
                        break;
                    case pages_1.HelpUrlType.EventConfigurationFileManagement:
                        _this.table.search('Event Configuration File Management');
                        break;
                    case pages_1.HelpUrlType.EventConfigurationAlertMessage:
                        _this.table.search('Event Configuration Alert Message');
                        break;
                    case pages_1.HelpUrlType.EventConfigurationMeetings:
                        _this.table.search('Event Configuration Meetings');
                        break;
                    case pages_1.HelpUrlType.EventConfigurationHotel:
                        _this.table.search('Event Configuration Hotel');
                        break;
                    case pages_1.HelpUrlType.EventConfigurationTrasnportation:
                        _this.table.search('Event Configuration Trasnportation');
                        break;
                    case pages_1.HelpUrlType.EventConfigurationInventory:
                        _this.table.search('Event Configuration Inventory');
                        break;
                    case pages_1.HelpUrlType.EventConfigurationCostManagement:
                        _this.table.search('Event Configuration Cost Management');
                        break;
                    case pages_1.HelpUrlType.InventoryDetailPage:
                        _this.table.search('Inventory Detail Page');
                        break;
                    case pages_1.HelpUrlType.UserDetailPage:
                        _this.table.search('User Detail Page');
                        break;
                    case pages_1.HelpUrlType.CustomerDetailPage:
                        _this.table.search('Customer Detail Page');
                        break;
                    case pages_1.HelpUrlType.VendorDetailPage:
                        _this.table.search('Vendor Detail Page');
                        break;
                    case pages_1.HelpUrlType.UserGroupDetailPage:
                        _this.table.search('User Group Detail Page');
                        break;
                    case pages_1.HelpUrlType.CustomerCompanyDetailPage:
                        _this.table.search('Customer Company Detail Page');
                        break;
                    case pages_1.HelpUrlType.VendorCompanyDetailPage:
                        _this.table.search('Vendor Company Detail Page');
                        break;
                    case pages_1.HelpUrlType.EmailTemplateDetailPage:
                        _this.table.search('Email Template Detail Page');
                        break;
                    case pages_1.HelpUrlType.NewEventPage:
                        _this.table.search('New Event Page');
                        break;
                    case pages_1.HelpUrlType.MeetingDetail:
                        _this.table.search('Meeting Detail');
                        break;
                    case pages_1.HelpUrlType.LoginPage:
                        _this.table.search('Login Page');
                        break;
                    case pages_1.HelpUrlType.UserProfile:
                        _this.table.search('User Profile');
                        break;
                    case pages_1.HelpUrlType.ApplicationHeader:
                        _this.table.search('Application Header');
                        break;
                    case pages_1.HelpUrlType.TravelAndAccommodationTileAttendeeInfoPane:
                        _this.table.search('Travel And Accommodation Tile Attendee Info Pane');
                        break;
                    case pages_1.HelpUrlType.TravelAndAccommodationTileArrivalPane:
                        _this.table.search('Travel And Accommodation Tile Arrival Pane');
                        break;
                    case pages_1.HelpUrlType.TravelAndAccommodationTileDeparturePane:
                        _this.table.search('Travel And Accommodation Tile Departure Pane');
                        break;
                    case pages_1.HelpUrlType.TravelAndAccommodationTileDailyTransportPane:
                        _this.table.search('Travel And Accommodation Tile Daily Transport Pane');
                        break;
                    case pages_1.HelpUrlType.TravelAndAccommodationTileHotelInfo:
                        _this.table.search('Travel And Accommodation Tile Hotel Info');
                        break;
                    default:
                        break;
                }
            }
            else {
                _this.table.search("");
            }
            _this.table.load();
            _this._isOpen = true;
            _this.observationProvider.notifyObservers(_1.HelpListModalActions.Opened);
        };
        this.close = function () {
            _this._isOpen = false;
            _this.observationProvider.notifyObservers(_1.HelpListModalActions.Closed);
        };
        this.buildDownloadButton = function (helpUrl) {
            return new downloadButton_1.default({
                api: _this.api,
                text: 'Click to view PDF',
                url: helpUrl.pdfUrl,
            });
        };
        this.table = new tables_1.Table(this.loadData, this.api);
        this.observationProvider = new utils_1.ObservationProvider();
        if (this.currentUserManager) {
            this.isApp = this.currentUserManager.clientChecker.isMobileApp();
        }
        this.loadData();
    }
    Object.defineProperty(HelpListModal.prototype, "administratorName", {
        get: function () {
            return this._administratorName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HelpListModal.prototype, "administratorEmail", {
        get: function () {
            return this._administratorEmail;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HelpListModal.prototype, "administratorPhone", {
        get: function () {
            return this._administratorPhone;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HelpListModal.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HelpListModal.prototype, "helpModal", {
        get: function () {
            return this._helpModal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HelpListModal.prototype, "selectedVideoUrl", {
        get: function () {
            return this._selectedVideoUrl;
        },
        enumerable: true,
        configurable: true
    });
    return HelpListModal;
}());
exports.default = HelpListModal;
