import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import FormattedDate from '../text/formattedDate';
import { DateUtils, InteractiveVenueAssignerMeeting, MeetingStatuses, MeetingSchedule as FrontendMeetingSchedule } from 'core.frontend';
import MeetingDimension from '../events/reports/meetingDimension';
import Field from '../fields/field';

export interface MeetingScheduleProps {
    meetingSchedule: FrontendMeetingSchedule;
}

class MeetingSchedule extends ObservingComponent<MeetingScheduleProps> {

    private readonly rowHeight: number = 48;
    private readonly columnMinWidth: number = 150;
    private readonly minMeetingHeight: number = 20;

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.meetingSchedule.observationProvider);
    }

    private getFirstGridDateTime(dateStr: string) {
        const { meetingSchedule } = this.props;

        var dateParts = dateStr.split('-');
        dateParts[2] = dateParts[2].split('T')[0];

        var year = dateParts[0];
        var monthStr = dateParts[1];
        var dayStr = dateParts[2];

        if (monthStr.length < 2) {
            monthStr = '0' + monthStr;
        }

        if (dayStr.length < 2) {
            dayStr = '0' + dayStr;
        }

        const firstDateTime = new Date(`${year}-${monthStr}-${dayStr}T${meetingSchedule.timeSlots[0].substring(0,2)}:${meetingSchedule.timeSlots[0].substring(2)}`);

        return firstDateTime;
    }

    private getMeetingOffsetMinutes = (startDateTime: Date) => {
        /* calculates the number of minutes a meeting start time is before the first time on the grid */
        var offset = DateUtils.getMinutesDifference(this.getFirstGridDateTime(startDateTime.toString()), startDateTime);
        return offset > 0 ? offset : 0;
    }

    private getMeetingBlockHeight = (meeting: InteractiveVenueAssignerMeeting) => {
        let lengthInMinutes = DateUtils.getMinutesDifference(meeting.endDateTime, meeting.startDateTime);
        lengthInMinutes -= this.getMeetingOffsetMinutes(meeting.startDateTime);

        const exactHeight = (lengthInMinutes / 30 * this.rowHeight) - 1;

        const heightTrimmedAtBottom = Math.min(
            exactHeight - 2,
            (this.rowHeight * this.props.meetingSchedule.timeSlots.length) - exactHeight - 2
        )

        return Math.max(this.minMeetingHeight, heightTrimmedAtBottom);
    }

    private getMeetingBlockTop = (meeting: InteractiveVenueAssignerMeeting) => {
        let minutesFromBase = DateUtils.getMinutesDifference(meeting.startDateTime, this.getFirstGridDateTime(meeting.startDateTime.toString()));
        minutesFromBase += this.getMeetingOffsetMinutes(meeting.startDateTime);

        return minutesFromBase / 30 * this.rowHeight;
    }

    private getMeetingDimensions = () => {
        const { meetingSchedule } = this.props;

        const meetingDimensions: MeetingDimension[] = [];

        for(let j = 0; j < meetingSchedule.meetings.length; j++) {

            const meeting = meetingSchedule.meetings[j];

            const top = this.getMeetingBlockTop(meeting) + 32; // 2rem headeroffset
            const height =  this.getMeetingBlockHeight(meeting);

            meetingDimensions.push({
                top: top,
                height: height,
                left: 0,
                right: 0,
                bottom: top + height,
                rightAdjacentMeetings: 0,
                column: 0,
                meetingId: meeting.id
            });
        }

        // sort by top then by bottom
        const sortedMeetingDimensions = meetingDimensions.sort((a, b) => {
            if (a.top < b.top) {
                return -1;
            }

            if (a.top > b.top) {
                return 1;
            }

            if (a.bottom > b.bottom) {
                return -1;
            }

            if (a.bottom < b.bottom) {
                return 1;
            }

            return 0;
        });

        this.setMeetingDimensionsColumnNumber(sortedMeetingDimensions, 0);

        return sortedMeetingDimensions;
    }

    private setMeetingDimensionsColumnNumber(meetingDimensions: MeetingDimension[], seedColumnNumber: number) {

        var lastMeetingBottom = null;
        var columns = [];

        for (let i = 0; i < meetingDimensions.length; i++) {
             const meetingDimension = meetingDimensions[i];

            if (lastMeetingBottom !== null && meetingDimension.top >= lastMeetingBottom) {
                this.setColumnNumber(columns);
                columns = [];
                lastMeetingBottom = null;
            }

            var placed = false;

            for (var j = 0; j < columns.length; j++) {
                var col = columns[j];

                if (
                    !(
                        col[col.length-1].bottom > meetingDimension.top &&
                        col[col.length-1].top < meetingDimension.bottom
                    )
                ) {
                    col.push(meetingDimension);
                    placed = true;
                    break;
                }
            }

            if (!placed) {
                columns.push([meetingDimension]);
            }

            if (lastMeetingBottom === null || meetingDimension.bottom > lastMeetingBottom) {
                lastMeetingBottom = meetingDimension.bottom;
            }
        }

        if (columns.length > 0) {
            this.setColumnNumber(columns);
        }
    }

    private setColumnNumber(columns: MeetingDimension[][]) {
        var columnCount = columns.length;

        for (var i = 0; i < columnCount; i++) {
            var col = columns[i];

            for(var j = 0; j < col.length; j++) {
                const meetingDimension = col[j];
                meetingDimension.column = i + 1;
                meetingDimension.rightAdjacentMeetings = columnCount - meetingDimension.column;
                meetingDimension.leftPercent = `${((meetingDimension.column - 1) / columnCount) * 100}%`;
                meetingDimension.widthPercent = `${1 / columnCount * 100}%`;
            }
        }
    }

    private getColumnWidth(meetingDimensions: MeetingDimension[]) {
        return this.columnMinWidth * Math.max(...meetingDimensions.map((dimension) => dimension.column));
    }

    private buildMeetingStyle = (meeting: InteractiveVenueAssignerMeeting, meetingDimensions: MeetingDimension[], columnWidth: number) => {
        const meetingDimension = meetingDimensions.find((d) => d.meetingId === meeting.id);

        if (!meetingDimension) {
            return;
        }

        return {
            top: meetingDimension.top + 1,
            height: meetingDimension.height,
            left: meetingDimension.leftPercent,
            width: meetingDimension.widthPercent
        };
    }

    private meetingClicked = (meeting: InteractiveVenueAssignerMeeting) => (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.meetingSchedule.meetingClicked(meeting);
    }

    private buildMeeting = (meeting: InteractiveVenueAssignerMeeting, meetingDimensions: MeetingDimension[], columnWidth: number) => {
        const { meetingSchedule } = this.props;

        const style = this.buildMeetingStyle(meeting, meetingDimensions, columnWidth);

        return (
            <div
                class={'cursor-default meeting ' + (meeting.status == MeetingStatuses.Working ? ' working ' : '')}
                style={{
                    top: style.top,
                    left: style.left,
                    width: style.width,
                    margin: '1px',
                    border: '1px solid #fff',
                    cursor: 'pointer !important',
                    fontSize: '1rem',
                }}
                id={'meeting_' + meeting.id}
                onClick={this.meetingClicked(meeting)}>


                <div class="flex">
                    <div
                        style={{height: style.height}}
                        class="fill meeting-label">

                        #{meeting.meetingNumber} | {meeting.name} | {meeting.venue}

                        {!!meeting.assignedTables &&
                            <span> | {meeting.assignedTables}</span>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private buildMeetings = (meetingDimensions: MeetingDimension[], columnWidth: number) => {
        return this.props.meetingSchedule.meetings.map((meeting) => {
            return this.buildMeeting(meeting, meetingDimensions, columnWidth);
        });
    }

    private buildSchedule() {
        const { meetingSchedule } = this.props;
        const meetingDimensions = this.getMeetingDimensions();
        const columnWidth = this.getColumnWidth(meetingDimensions);

        return (
            <div class="mt-1">
                <div class="pl-1 flex text-lg">

                    <Button
                        className="v-center ml-auto mr-1 btn-icon"
                        button={meetingSchedule.previousDayButton} />

                    <FormattedDate
                        className="v-center"
                        date={meetingSchedule.selectedDate} />

                    <Button
                        className="v-center mr-auto ml-1 btn-icon"
                        button={meetingSchedule.nextDayButton} />
                </div>

                <div class="flex mt-p5">
                    <div class="time-column">
                        <div class="header"></div>

                        {meetingSchedule.timeSlots.map((timeSlot) => (
                            <div class="time-slot">
                                <span>{timeSlot}</span>
                            </div>
                        ))}
                    </div>

                    <div class="venue">

                        <div class='header'>
                            <span>My Meeting Schedule</span>
                        </div>

                        {meetingSchedule.timeSlots.map(() => (
                            <div class="time-slot"></div>
                        ))}

                        {this.buildMeetings(meetingDimensions, columnWidth)}
                    </div>
                </div>
            </div>
        );
    }

    public render({ meetingSchedule }: MeetingScheduleProps): JSX.Element {
        return (
            <div class="interactive-room-assigner">

                <div class="fill mt-1">
                    <Field
                        className="no-label"
                        field={meetingSchedule.attendeeDropdown} />
                </div>

                {!meetingSchedule.isLoading &&
                    this.buildSchedule()
                }

                {!!meetingSchedule.isLoading &&
                    <div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                    </div>
                }
            </div>
        );
    }
}

export default MeetingSchedule;