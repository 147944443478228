import { FieldActions, ActionUtils, DateField as FrontEndDateField } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';
import Calendar from '../calendar/calendar';

export interface DateFieldProps {
    id?: string;
    field: FrontEndDateField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class DateField extends ObservingComponent<DateFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    private inputClicked = (e: Event) => {
        e.stopPropagation();

        this.props.field.calendar.show();
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: DateFieldProps): JSX.Element {

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div className={`input-field ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} /> }

                <label
                    className={labelClassName}
                    for={id}>

                    <span class="fill">{field.label}</span>

                    {field.labelButtonText && <a onClick={field.handleLabelButtonClick}>{field.labelButtonText}</a>}

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>


                <input
                    type="text"
                    id={id}
                    name={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    onClick={this.inputClicked}
                    value={field.stringValue}
                    onChange={this.valueChanged}
                    className={field.isInvalid ? 'invalid' : ''}
                    disabled={field.isDisabled}
                    maxLength={field.maxCharacters} />

                { field.isActive && !field.stringValue && field.placeholderLabel && <label class="placeholder-label">{ field.placeholderLabel }</label> }

                <Calendar calendar={field.calendar} />
            </div>
        );
    }
}

export default DateField;
