import { h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import VendorCompanyInfoPageProps from './vendorCompanyInfoPageProps';
import Alert from '../../alerts/alert';
import FormButtonContainer from '../../containers/formButtonContainer';
import Field from '../../fields/field';
import Button from '../../buttons/button';
import ButtonColors from '../../buttons/buttonColors';
import DataList from '../../dataList/dataList';


class VendorCompanyInfoPage extends ObservingComponent<VendorCompanyInfoPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: VendorCompanyInfoPageProps): JSX.Element {

        return (
            <div>
                <Alert alert={page.alert} />

                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <div class="form-container">
                    {page.form.fields.map((field) =>
                        <Field
                            field={field}
                            isLoading={page.isLoading} />
                    )}
                </div>

                <div class="form-container mt-2">
                    <h4 class="flex">
                        Users

                        <Button
                            className="ml-auto text-sm"
                            button={page.usersDataList.addItemButton} />
                    </h4>

                    <hr/>

                    <DataList dataList={page.usersDataList} />
                </div>

                <FormButtonContainer
                    leftButtons={[]}
                    rightButtons={[
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton}/>
                    ]} />
            </div>
        );
    }
}

export default VendorCompanyInfoPage;
