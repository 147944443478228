import { h, JSX } from 'preact';
import SubmittedStatusProps from './submittedStatusProps';
import ObservingComponent from '../componentBases/observingComponent';

class SubmittedStatus extends ObservingComponent<SubmittedStatusProps> {

    public render({ isSubmitted, className }: SubmittedStatusProps): JSX.Element {
        return (
            <div class={className}>
                {!!isSubmitted &&
                    <b class="text-green">Submitted</b>
                }

                {!isSubmitted &&
                    <b>Not Yet Submitted</b>
                }
            </div>
        );
    }
}

export default SubmittedStatus;
