"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
var _1 = require(".");
var button_1 = require("../button/button");
var ConfirmationPrompt = /** @class */ (function () {
    function ConfirmationPrompt(enabled, heading, _message, deleteButtonText, cancelButtonText, deleteButtonProcessingText) {
        var _this = this;
        this.enabled = enabled;
        this.heading = heading;
        this._message = _message;
        this.cancelClicked = function () {
            _this._isVisible = false;
            _this.observationProvider.notifyObservers(_1.ConfirmationPromptActions.Cancelled);
        };
        this.deleteClicked = function () {
            _this._isVisible = true;
            _this.observationProvider.notifyObservers(_1.ConfirmationPromptActions.Confirmed);
        };
        this.show = function () {
            if (!_this.enabled) {
                return;
            }
            _this._isVisible = true;
            _this.observationProvider.notifyObservers(_1.ConfirmationPromptActions.Shown);
        };
        this.hide = function () {
            _this._isVisible = false;
            _this.observationProvider.notifyObservers(_1.ConfirmationPromptActions.Hidden);
        };
        this.setMessage = function (message) {
            _this._message = message;
            _this.observationProvider.notifyObservers(_1.ConfirmationPromptActions.MessageChanged);
        };
        this.observationProvider = new utils_1.ObservationProvider();
        this.deleteButton = new button_1.default({
            onClick: this.deleteClicked,
            text: deleteButtonText,
            processingText: deleteButtonProcessingText
        });
        this.cancelButton = new button_1.default({
            onClick: this.cancelClicked,
            text: cancelButtonText
        });
    }
    Object.defineProperty(ConfirmationPrompt.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmationPrompt.prototype, "message", {
        get: function () {
            return this._message;
        },
        enumerable: true,
        configurable: true
    });
    return ConfirmationPrompt;
}());
exports.default = ConfirmationPrompt;
