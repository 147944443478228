import { h, JSX } from 'preact';
import RightSliderProps from './rightSiderProps';
import ObservingComponent from '../../componentBases/observingComponent';
import Button from '../../buttons/button';

class RightSlider extends ObservingComponent<RightSliderProps> {

    public componentWillMount(): void {
        const { slideContainer } = this.props;

        this.registerUpdateObserver(slideContainer.observationProvider);
    }

    public render({ slideContainer, children, footer }: RightSliderProps): JSX.Element {
        return (
            <div>
                <div
                    class={'slider-background nav-background' + (slideContainer.isOpen ? ' active' : '')}
                    onClick={slideContainer.close}>
                </div>

                <div class={'right-slider' + (slideContainer.isOpen ? ' active' : '')}>

                    <Button
                        className="btn-slide-closer"
                        button={slideContainer.closeButton} />

                    <div class="slider-content">
                        {children}
                    </div>

                    { footer &&
                        <div class="slider-footer">
                            {footer}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default RightSlider;
