"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CostManagementCostFactor;
(function (CostManagementCostFactor) {
    CostManagementCostFactor[CostManagementCostFactor["Area"] = 1] = "Area";
    CostManagementCostFactor[CostManagementCostFactor["Attendee"] = 2] = "Attendee";
    CostManagementCostFactor[CostManagementCostFactor["BusinessArea"] = 3] = "BusinessArea";
    CostManagementCostFactor[CostManagementCostFactor["DividedEqually"] = 4] = "DividedEqually";
    CostManagementCostFactor[CostManagementCostFactor["PointValue"] = 5] = "PointValue";
    CostManagementCostFactor[CostManagementCostFactor["Weight"] = 6] = "Weight";
})(CostManagementCostFactor || (CostManagementCostFactor = {}));
exports.default = CostManagementCostFactor;
