import { h, JSX } from 'preact';
import { EventTableItem, EventStatus, DateFormats } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Card from '../containers/card';
import EventListPageProps from './eventListPageProps';
import EventOverview from './eventOverview';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import Button from '../buttons/button';
import FormattedDate from '../text/formattedDate';
import Field from '../fields/field';
import ReportOptionSlideContainer from './rightSlider/reportOptionSlideContainer';
import ButtonColors from '../buttons/buttonColors';
import PhoneButton from '../buttons/phoneButton';
import EmailButton from '../buttons/emailButton';
import ButtonSizes from '../buttons/buttonSizes';
import Alert from '../alerts/alert';
import VideoModalButton from '../buttons/videoModalButton';

class EventListPage extends ObservingComponent<EventListPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private getAccessRequest = (tableItem: EventTableItem): JSX.Element => {
        const { page } = this.props;

        if (tableItem.userIsInEvent) {
            return null;
        }

        if (page.sentAccessRequests.indexOf(tableItem.id) > -1) {
            return <span class="text-green m-auto">Request Sent</span>
        }

        return (
            <Button
                color={ButtonColors.Primary}
                size={ButtonSizes.Small}
                button={page.buildAccessRequestButton(tableItem) } />
        );
    }

    private buildColumns = (): SmartTableColumn<EventTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'Events.name',
                cellTemplate: (item, index) =>
                    (<span class="flex">

                        <span class="fill">
                            {page.table.isIndexDeleting(index) || !item.userIsInEvent ? (
                                <span>{item.name}</span>
                            ) : (
                                <Button
                                    className="btn-link"
                                    button={page.buildEventLandingButton(item)} />
                            )}

                            {item.status === EventStatus.Archived &&
                                <span class="text-red"> - Archived</span>
                            }

                            {item.status === EventStatus.Deleted &&
                                <span class="text-red"> - Deleted</span>
                            }

                            <br/>
                            <span class="text-sm">{item.city}, {item.country}</span>

                            {item.leadCompany &&
                                <span class="d-md-none sm">
                                    <br/>
                                    {item.leadCompany}
                                </span>
                            }

                            <span class="d-md-none sm">
                                <br/>
                                <FormattedDate
                                    date={item.startDate}
                                    format={DateFormats.MMMdYYYY} />

                                    <span> - </span>

                                <FormattedDate
                                    date={item.endDate}
                                    format={DateFormats.MMMdYYYY} />
                            </span>

                            <span class="d-md-none sm">
                                <br/>
                                <Button
                                    className="btn-sm btn-link"
                                    button={page.buildEventOverviewButton(item.id)} />
                            </span>
                        </span>

                        <span class="d-md-none m-auto">{this.getAccessRequest(item)}</span>
                    </span>),
                appendSavingIndicator: true
            },
            {
                className: 'd-none d-md-table-cell fit-content text-center',
                heading: 'Dates',
                sortExpression: 'Events.StartDate',
                cellTemplate: (item) => (
                    <span class="text-sm">

                        <FormattedDate
                            date={item.startDate}
                            format={DateFormats.MMMdYYYY} />

                        <br/>

                        <FormattedDate
                            date={item.endDate}
                            format={DateFormats.MMMdYYYY} />
                    </span>),
            },
            {
                className: 'd-none d-md-table-cell fit-content text-center',
                heading: 'Lead BA',
                sortExpression: 'BusinessAreas.name',
                cellTemplate: (item) => <span class="text-sm">{item.leadCompany}</span>,
            },
            {
                className: 'text-center d-none d-md-table-cell fit-content',
                cellTemplate: (item) => (
                    <span>
                        <Button
                            className="btn-link"
                            button={page.buildEventOverviewButton(item.id)} />

                        <br/>
                        {this.getAccessRequest(item)}
                    </span>
                )
            }
        ];
    }

    public render({ page }: EventListPageProps): JSX.Element {

        if (page.accessRequestPromptVisible) {
            return (
                <div>
                    <Alert alert={page.accessRequestAlert} />

                    <div class="page-heading">
                        <h3>Request Access to {page.accessRequestEvent.name}</h3>
                    </div>

                    <div class="flex">
                        <div>
                            <b>Event Producer:</b>
                        </div>
                        <div class="fill pl-1">
                            {page.accessRequestEvent.eventProducerName} <br/>
                            {page.accessRequestEvent.eventProducerPhone &&
                                <div class="flex">
                                    <span class="v-center mr-p5">
                                        {page.accessRequestEvent.eventProducerPhone}
                                    </span>

                                    <PhoneButton phoneNumber={page.accessRequestEvent.eventProducerPhone} />
                                </div>
                            }

                            <span class="v-center mr-p5">
                                {page.accessRequestEvent.eventProducerEmail}
                            </span>

                            <EmailButton emailAddress={page.accessRequestEvent.eventProducerEmail} />
                        </div>
                    </div>

                    <p class="mt-1">
                        We will send a notification to the event producer to let them know that you would like access to this event.
                        <br/>
                        Please provide the following information.
                    </p>

                    <div class="form-container mt-2">
                        {page.accessRequestForm.fields.map((field) => <Field field={field}></Field>)}
                    </div>

                    <div class="form-footer">
                        <div class="btn-container">

                            <Button
                                color={ButtonColors.Secondary}
                                button={page.cancelRequestButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.submitAccessRequestButton} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div class="page-heading">
                        <h3 className="width-100">
                            <div className="flex">
                                <span className="fill">{page.title}</span>
                            </div>
                        </h3>
                    </div>

                    <Card
                        headingLeftContent={
                            <div class="button-container fill">
                                <Button
                                    color={ButtonColors.Primary}
                                    button={page.reportsButton} />
                            </div>
                        }
                        headingRightContent={
                            <div class="button-container flex">

                                <Button
                                    className="btn-icon btn-lg"
                                    button={page.helpButton} />

                                <Button
                                    color={ButtonColors.Primary}
                                    button={page.newEventButton} />
                            </div>
                        }>

                        <SmartTable
                            table={page.table}
                            columns={this.buildColumns()}
                            listOptionSlideContainer={page.listOptionSlideContainer} />
                    </Card>

                    <EventOverview eventOverview={page.eventOverview} />
                    <ReportOptionSlideContainer reportOptionSlideContainer={page.reportOptionSlideContainer} />
                </div>
            );
        }
    }
}

export default EventListPage;
