import { h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import AttendeeReportPageProps from './attendeeReportPageProps';
import FormattedDate from '../../text/formattedDate';
import { DateFormats } from 'core.frontend';
import Avatar from '../../avatar/avatar';
import Button from '../../buttons/button';

class AttendeeReportPage extends ObservingComponent<AttendeeReportPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: AttendeeReportPageProps): JSX.Element {

        return (
            <div class="report">

                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                <h3 class="text-center pl-1 pr-1 mt-1">
                    {!page.isLoading &&
                        <span>{page.reportData.eventName}</span>
                    }
                </h3>

                <div class="text-sm flex mb-1">
                    <div class="fill">
                        Printed by: {page.currentUsersName}
                        <div><b>{page.title}</b></div>
                    </div>
                    <div class="text-right">

                        <FormattedDate
                            date={new Date()}
                            format={DateFormats.dddMMMMDhmmA} />

                        {!page.isLoading &&
                            <div>
                                <span>Total Attendees: </span>
                                {page.reportData.reportDataItems.length}
                            </div>
                        }
                    </div>
                </div>

                {!page.isLoading &&
                    <div class="report-page text-sm">
                        <table>
                            <thead>
                                <tr>
                                    {page.reportOptions.includePhotos &&
                                        <th>&nbsp;</th>
                                    }
                                    <th>Title</th>
                                    <th>Attendee Name</th>
                                    <th>Email</th>
                                    <th>Business Area</th>
                                    <th>Operating Company</th>
                                    <th>Line of Business</th>
                                    <th>Phone</th>
                                    <th>Area of Expertise</th>
                                </tr>
                            </thead>

                            { page.reportData.reportDataItems.map((attendee) =>
                                <tr>
                                    {page.reportOptions.includePhotos &&
                                        <td class="text-center">
                                            <Avatar url={attendee.profileImageUrl} />
                                        </td>
                                    }
                                    <td>{attendee.title}</td>
                                    <td>{attendee.fullName}</td>
                                    <td>{attendee.email}</td>
                                    <td>{attendee.businessArea}</td>
                                    <td>{attendee.department}</td>
                                    <td>{attendee.lineOfBusiness}</td>
                                    <td>{attendee.phone}</td>
                                    <td>{attendee.productKnowledge}</td>
                                </tr>
                            )}
                        </table>
                    </div>
                }
            </div>
        );
    }
}

export default AttendeeReportPage;
