
import { FunctionUtils, Button as FrontEndButton, Icons } from 'core.frontend';
import { h, JSX, Fragment } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import TableLoadingPlaceholderTBody from './tableLoadingPlaceholderTBody';
import TableProps from './tableProps';
import TableState from './tableState';
import Icon from '../icons/icon';

class Table<T extends {id: number}> extends ObservingComponent<TableProps<T>, TableState> {

    constructor(props: TableProps<T>) {
        super(props);

        this.setState({
            expandedIndexes: []
        });
    }

    private cellClicked = (rowIndex: number) => (e: Event) => {
        const { table } = this.props;

        table.selectRow(rowIndex);
    }

    private buildExpandCell = (rowIndex: number, item: T) => {
        var arrayIndex = this.state.expandedIndexes.indexOf(rowIndex);

        if (arrayIndex >= 0) {
            return (
                <td
                    className="fit-content expand-cell"
                    onClick={() => { this.collapseRow(arrayIndex); }}>
                        <Icon icon={Icons.ChevronDown} />
                </td>
            );
        } else {
            return (
                <td
                    className="fit-content expand-cell"
                    onClick={() => { this.expandRow(rowIndex, item); }}>
                        <Icon icon={Icons.ChevronRight} />
                </td>
            );
        }
    }

    private collapseRow = (arrayIndex: number) => {
        this.setState({
            expandedIndexes: this.state.expandedIndexes.filter((value, index) => index != arrayIndex)
        });
    }

    private expandRow = (rowIndex: number, item: T) => {
        const { table } = this.props;

        table.loadExpandedContent(item);

        this.setState({
            expandedIndexes: this.state.expandedIndexes.concat(rowIndex)
        });
    }

    private buildExpandAllCell = () => {
        var allExpanded = this.state.expandedIndexes.length === this.props.items.length;

        if (allExpanded) {
            return (
                <th
                    className="fit-content expand-cell"
                    onClick={() => { this.collapseAll(); }}>
                        <Icon icon={Icons.ChevronDown} />
                </th>
            );
        } else {
            return (
                <th
                    className="fit-content expand-cell"
                    onClick={() => { this.expandAll(); }}>
                        <Icon icon={Icons.ChevronRight} />
                </th>
            );
        }
    }

    private collapseAll = () => {
        this.setState({
            expandedIndexes: []
        });
    }

    private expandAll = () => {
        const { table } = this.props;

        for (const item of table.items) {
            table.loadExpandedContent(item);
        }

        this.setState({
            expandedIndexes: this.props.items.map((item, index) => index)
        });
    }

    public render({ columns, expandedContentTemplate, isLoading, items, className, headerClassName, rowClassName, hideHeading, loadingRowCount, id, table}: TableProps<T>): JSX.Element {
        if (isLoading && this.state.expandedIndexes.length) {
            this.setState({expandedIndexes: []});
        }

        if (!id) {
            id = 'table';
        }

        return (
            <table className={"table table-striped " + className} >
                { !hideHeading && (
                    <thead class={headerClassName}>
                        <tr>
                            {expandedContentTemplate && this.buildExpandAllCell() }

                            {columns.map((column, colIndex) => {
                                return (
                                    <th
                                        className={column.headingClassName + ' ' + column.className}
                                        style={column.width ? {width: column.width} : null}>

                                            <span>{ column.heading }</span>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                )}
                {isLoading ? (
                    <TableLoadingPlaceholderTBody
                        columnCount={columns.length + (!!expandedContentTemplate ? 1 : 0)}
                        rowCount={loadingRowCount ? loadingRowCount : 3} />
                ) : (
                    <Fragment>
                        {items.map((item, rowIndex) => {

                            const isExpanded = this.state.expandedIndexes.indexOf(rowIndex) >= 0;

                            return (
                                <tbody id={id + '_row_' + rowIndex.toString()}>
                                    <tr className={
                                        FunctionUtils.functionify<number, string>(rowClassName)(rowIndex) +
                                        (rowIndex == table.selectedIndex ? ' selected' : '') }>

                                        {expandedContentTemplate && this.buildExpandCell(rowIndex, item)}

                                        {columns.map((column, colIndex) => {
                                            return (
                                                <td
                                                    key={colIndex}
                                                    className={column.cellClassName + ' ' + column.className}
                                                    style={column.width ? {width: column.width} : null}
                                                    onClick={this.cellClicked(rowIndex)}>

                                                    { column.cellTemplate(item, rowIndex) }
                                                </td>
                                            );
                                        })}
                                    </tr>

                                    {expandedContentTemplate && isExpanded &&
                                        <tr className={'expanded-content ' + isExpanded ? 'active' : ''}>
                                            <td
                                                colSpan={columns.length + 1}
                                                onClick={this.cellClicked(rowIndex)}>

                                                {expandedContentTemplate(item)}
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            );
                        })}
                    </Fragment>
                )}
            </table>
        );
    }
}

export default Table;
