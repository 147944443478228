import { Fragment, h, JSX } from 'preact';
import EventInventoryTileProps from './eventInventoryTileProps';
import EventInfoTile from './eventInfoTile';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import GroupContainer from '../groupContainers/groupContainer';
import ListOptionSlideContainer from './rightSlider/listOptionSlideContainer';
import { EventInventory, EventInventoryPanes, DateFormats } from 'core.frontend';
import SearchField from '../fields/searchField';
import Field from '../fields/field';
import FormattedDate from '../text/formattedDate';
import ImageCarousel from '../imageCarousel/imageCarousel';
import EmptyState from '../uiStates/emptyState';
import ButtonSizes from '../buttons/buttonSizes';
import ButtonColors from '../buttons/buttonColors';
import TagCollection from '../tags/tagCollection';
import DataList from '../dataList/dataList';
import Alert from '../alerts/alert';

class EventInventoryTile extends ObservingComponent<EventInventoryTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    public render({ tile }: EventInventoryTileProps): JSX.Element {
        return (
            tile.isVisible && <EventInfoTile
                color="yellow"
                tile={tile}
                icon="ti-event-inventory">

                    { tile.isLoading ?
                        'Loading...'
                    : (
                        <div>
                            <Alert
                                className="mt-1"
                                alert={tile.alert} />

                            <div class="btn-group mt-1 flex">
                                <Button
                                    size={ButtonSizes.Small}
                                    color={ButtonColors.Primary}
                                    button={tile.showEventInventoryPaneButton}
                                    className={' fill ' + (tile.activePane === EventInventoryPanes.EventInventory ? 'active' : '')} />
                                <Button
                                    size={ButtonSizes.Small}
                                    color={ButtonColors.Primary}
                                    button={tile.showEventInventoryRequestPaneButton}
                                    className={' fill ' + (tile.activePane === EventInventoryPanes.EventInventoryRequests ? 'active' : '')}/>
                                <Button
                                    size={ButtonSizes.Small}
                                    color={ButtonColors.Primary}
                                    button={tile.showNotesPaneButton}
                                    className={' fill ' + (tile.activePane === EventInventoryPanes.Notes ? 'active' : '')} />
                            </div>

                            {tile.activePane === EventInventoryPanes.EventInventory &&
                                <div>

                                    <div class="flex mt-1">

                                        <div class="fill">
                                            <TagCollection tagCollection={tile.listOptionSlideContainer.groupByTagCollection} />
                                            <TagCollection tagCollection={tile.listOptionSlideContainer.filterTagCollection} />
                                        </div>

                                        <Button
                                            className="ml-auto btn-icon btn-no-inner-margin"
                                            color={ButtonColors.Secondary}
                                            button={tile.listOptionSlideContainer.openButton} />
                                    </div>

                                    {tile.isEditMode &&
                                        <div class="form-container search-field-wrapper pb-1">
                                            <SearchField searchField={tile.inventorySearchField} />

                                            <Button
                                                color={ButtonColors.Primary}
                                                size={ButtonSizes.Small}
                                                button={tile.addEventInventoryItemButton} />
                                        </div>
                                    }

                                    {!tile.noEventInventory &&
                                        <div class="mt-1">

                                            <GroupContainer
                                                hideHeaderOnNoGroup={true}

                                                headingTemplate={(group) => (
                                                    <div class="attendee-header">
                                                        {group.groupHeading} ({group.data.length})
                                                    </div>
                                                )}

                                                itemWrapperTemplate={(group, itemTemplate) => (
                                                    <ul class="mt-0 mb-0 data-list">
                                                        {itemTemplate}
                                                    </ul>
                                                )}

                                                itemTemplate={(item: EventInventory, index: number) => (
                                                    <li class={'flex pl-0 ' + (item.isRemoved ? 'deleting' : '') }>

                                                        <div class="v-center flex column text-center p-1">
                                                            <Button
                                                                className="btn-icon"
                                                                color={ButtonColors.Secondary}
                                                                size={ButtonSizes.XLarge}
                                                                button={tile.buildShowImageCarousel(item)} />

                                                            { item.loadingImages &&
                                                                <span class="text-sm">Loading...</span>
                                                            }

                                                            { !item.loadingImages &&
                                                                <span class="text-sm">{item.imageCount} image{item.imageCount > 1 ? 's' : ''}</span>
                                                            }
                                                        </div>

                                                        <div class="fill">
                                                            <div class="flex">

                                                                <div class="fill">
                                                                    <b>{item.inventoryItemName}</b>
                                                                </div>

                                                                <Button
                                                                    className="btn-remove-data-item"
                                                                    button={tile.buildRemoveItemButton(item)} />
                                                            </div>

                                                            <div class="text-sm">
                                                                <div>
                                                                    <b>Crate#:</b> {tile.buildCrateNumberString(item)}
                                                                </div>

                                                                <div>
                                                                    <b>Serial/Item#:</b> {item.serialNumber}
                                                                </div>

                                                                <div>
                                                                    <b>Scale: </b> {item.scale}
                                                                </div>

                                                                {!!item.eventInventoryRequestId &&
                                                                    <Fragment>
                                                                        <div>
                                                                            <b>Requested By:</b> {item.requestedByUserFullName} {!!item.requestedDateTime && <span> on <FormattedDate date={item.requestedDateTime} /></span>}
                                                                        </div>
                                                                        <div>
                                                                            <b>Fulfilled By:</b> {item.createdByUserFullName} {!!item.createdDateTime && <span> on <FormattedDate date={item.createdDateTime} /></span>}
                                                                        </div>
                                                                    </Fragment>
                                                                }

                                                                {!item.eventInventoryRequestId &&
                                                                    <div>
                                                                        <b>Manually Added By:</b> {item.createdByUserFullName} {!!item.createdDateTime && <span> on <FormattedDate date={item.createdDateTime} /></span>}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}

                                                arrayGroups={tile.tileData}>
                                            </GroupContainer>
                                        </div>
                                    }

                                    {tile.noEventInventory &&
                                        <EmptyState
                                            title=""
                                            message={
                                                tile.listOptionSlideContainer.filterCount ?
                                                    'No inventory items assigned that match your search' :
                                                    'No inventory items assigned'
                                            } />
                                    }
                                </div>
                            }

                            {tile.activePane === EventInventoryPanes.EventInventoryRequests &&
                                <div class="mt-1">
                                    {tile.isEditMode &&
                                        <div class="form-container search-field-wrapper pb-1">
                                            <SearchField
                                                className="no-label"
                                                searchField={tile.inventoryItemGroupSearchField} />

                                            <Button
                                                color={ButtonColors.Primary}
                                                size={ButtonSizes.Small}
                                                button={tile.addInventoryRequestButton} />
                                        </div>
                                    }

                                    <DataList
                                        dataList={tile.inventoryRequestDataList}
                                        itemTemplate={(item) =>
                                            <div>
                                                <b>{item.form.model.inventoryItemGroupName}</b>
                                                <div class="text-sm">
                                                    <b>Reuested By: </b>
                                                    <span>{item.form.model.requestedByUserFullName} </span>
                                                    {!!item.form.model.createdDateTime &&
                                                        <span> on <FormattedDate date={item.form.model.createdDateTime} format={DateFormats.MMMdYYYY} /></span>
                                                    }
                                                </div>
                                                <Field field={item.form.fields[0]} />
                                            </div>
                                        } />
                                </div>
                            }

                            {tile.activePane === EventInventoryPanes.Notes &&
                                <div class="mt-1">
                                    {!tile.isEditMode &&
                                        <div>
                                            <b>Notes: </b>
                                            <span>{tile.inventoryNotes}</span>
                                            {!tile.inventoryNotes && <i> No inventory notes</i>}
                                        </div>
                                    }

                                    {tile.isEditMode &&
                                        <div>
                                            <Field field={tile.inventoryNotesField} />
                                        </div>
                                    }
                                </div>
                            }

                            <ListOptionSlideContainer
                                headerText = "Inventory Options"
                                listOptionSlideContainer = { tile.listOptionSlideContainer } />
                        </div>
                    )
                }

                { tile.imageCarousel &&
                    <div class="modal-container">
                        <div class="modal-content">
                            <ImageCarousel imageCarousel={tile.imageCarousel} />
                        </div>

                        <div
                            class="modal-background"
                            onClick={tile.imageCarousel.close}>
                        </div>
                    </div>
                }
            </EventInfoTile>
        );
    }
}

export default EventInventoryTile;
