"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReportOptionSlideContainerActions;
(function (ReportOptionSlideContainerActions) {
    ReportOptionSlideContainerActions["Hidden"] = "Hidden";
    ReportOptionSlideContainerActions["Shown"] = "Shown";
    ReportOptionSlideContainerActions["RunReport"] = "OptionsApplied";
    ReportOptionSlideContainerActions["Loaded"] = "Loaded";
    ReportOptionSlideContainerActions["VendorTypeChanged"] = "VendorTypeChanged";
    ReportOptionSlideContainerActions["EmailOptionsShown"] = "EmailOptionsShown";
    ReportOptionSlideContainerActions["EmailSent"] = "EmailSent";
})(ReportOptionSlideContainerActions || (ReportOptionSlideContainerActions = {}));
exports.default = ReportOptionSlideContainerActions;
