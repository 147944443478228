import { h, JSX } from 'preact';
import Button from '../buttons/button';
import ObservingComponent from '../componentBases/observingComponent';
import CalendarProps from './calendarProps';

class Calendar extends ObservingComponent<CalendarProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.calendar.observationProvider);
        window.addEventListener('click', this.props.calendar.hide);
        window.addEventListener('keyup', this.keyUp);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.props.calendar.hide);
        window.removeEventListener('keyup', this.keyUp);
    }

    private keyUp = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
            this.props.calendar.hide();
        }
    }

    private handleClick = (e: Event) => {
        e.stopPropagation();
    }

    private handleMouseDown = (e: Event) => {
        this.props.calendar.focus();
    }

    public render({ calendar }: CalendarProps): JSX.Element {

        if (!calendar.visible) {
            return null;
        }

        return (
            <div
                class="date-field-calendar"
                onClick={this.handleClick}
                onMouseDown={this.handleMouseDown}>

                <div class="date-field-calendar-header">

                    <Button
                        className="btn-icon"
                        button={calendar.calendarPreviousMonthButton} />

                    <div class="date-field-calendar-header-center">
                        {calendar.calendarHeader}
                    </div>

                    <Button
                        className="btn-icon"
                        button={calendar.calendarNextMonthButton} />

                </div>

                <div class="date-field-calendar-body">
                    <div class="date-field-calendar-row">
                        {calendar.calendarWeekHeader.map((weekDayHeader) =>
                            <div class="date-field-calendar-day">
                                {weekDayHeader}
                            </div>
                        )}
                    </div>

                    {calendar.calendarGrid.map((week) =>
                        <div class="date-field-calendar-row">
                            {week.map((dayButton) =>
                                <div class="date-field-calendar-day">
                                    <Button
                                        className="btn-icon"
                                        stopPropagation={true}
                                        button={dayButton} />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div class="date-field-calendar-footer">
                    <Button
                        className="btn-icon"
                        stopPropagation={true}
                        button={calendar.todayButton} />
                </div>
            </div>
        );
    }
}

export default Calendar;
