"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var button_1 = require("../../../components/button/button");
var helpButton_1 = require("../../../components/button/helpButton");
var helpUrls_1 = require("../../helpUrls");
var vendorPortal_1 = require("../../vendorPortal");
var tiles_1 = require("./tiles");
var dining_1 = require("./tiles/dining");
var EventLandingPage = /** @class */ (function (_super) {
    __extends(EventLandingPage, _super);
    function EventLandingPage(api, navigator, currentUserManager, eventId, meetingId) {
        var _this = _super.call(this, __1.PageTypes.EventLanding, '') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.eventId = eventId;
        _this.meetingId = meetingId;
        _this.goToEventConfigurationPage = function () {
            if (_this._eventManager.eventConfigurationEditable) {
                _this.navigator.goToEventConfiguration(_this.eventId);
            }
        };
        _this.goToEventLanding = function (eventId) { return function () {
            if (_this.eventId == eventId) {
                return;
            }
            _this.navigator.goToEventLanding(eventId);
        }; };
        _this.goToEventList = function () {
            _this.navigator.goToEventList();
        };
        _this.loadEventData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 1:
                        _a._eventManager = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.api.getHelpUrls()];
                    case 2:
                        _b._helpUrls = _c.sent();
                        this.title = this.eventLandingData.name;
                        this.setTilesVisiblity();
                        this.setTilesEditibility();
                        this.setButtonsVisibility();
                        this.setupEmailAndServicesTile();
                        if (this.eventLandingData.status === __1.EventStatus.Deleted) {
                            this.showDeletedAlert();
                        }
                        else if (this.eventLandingData.status === __1.EventStatus.Archived) {
                            this.showArchivedAlert();
                        }
                        else if (this.eventLandingData.status === __1.EventStatus.Active && this.eventLandingData.toBeArchived === true) {
                            this.showReArchiveAlert();
                        }
                        this._eventAlertTitle = this.eventLandingData.alertTitle;
                        this._eventAlertMessage = this.eventLandingData.alertMessage;
                        this._eventAlertEnabled = this.eventLandingData.alertEnabled;
                        this.dropdown.setItems(this.getEventDropdownItems(), this.eventLandingData.eventSelectionDropdownItems.findIndex(function (item) { return item.eventId === _this.eventId; }));
                        if (this._eventManager.canActivateEvent) {
                            this._archivedAlertMessage += ' Click the activate button to edit this event. Once active, the event will be automatically archived if the event end date is not adjusted today.';
                            this._deletedAlertMessage += ' Click the activate button to place this event back into an active status.';
                        }
                        this.observationProvider.notifyObservers(__1.EventLandingPageActions.EventDataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setButtonsVisibility = function () {
            _this.configurationButtton.setVisibility(_this._eventManager.eventConfigurationEditable);
        };
        _this.getEventDropdownItems = function () {
            var dropdownItems = [];
            for (var _i = 0, _a = _this.eventLandingData.eventSelectionDropdownItems; _i < _a.length; _i++) {
                var event_1 = _a[_i];
                var eventName = event_1.name;
                if (_this.currentUserManager.clientChecker.isMobileApp()) {
                    eventName = __1.StringUtils.truncate(event_1.name, 20);
                }
                dropdownItems.push({
                    data: event_1,
                    button: new button_1.default({
                        onClick: _this.goToEventLanding(event_1.eventId),
                        text: eventName,
                        subText: __1.DateUtils.formatDate(event_1.startDate, __1.DateFormats.MMMdYYYY) + ' - ' + __1.DateUtils.formatDate(event_1.endDate, __1.DateFormats.MMMdYYYY)
                    })
                });
            }
            var eventListNavText = 'Request access to an event';
            if (dropdownItems.length) {
                dropdownItems.push({
                    isSeparator: true
                });
                eventListNavText = 'All Events';
            }
            dropdownItems.push({
                button: new button_1.default({
                    onClick: _this.goToEventList,
                    text: eventListNavText
                })
            });
            return dropdownItems;
        };
        _this.showDeletedAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                message: _this._deletedAlertMessage,
                heading: 'Deleted Event',
                button: _this.getActivateButtton()
            });
        };
        _this.showArchivedAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                message: _this._archivedAlertMessage,
                heading: 'Archived Event',
                button: _this.getActivateButtton()
            });
        };
        _this.showReArchiveAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                message: 'This event was archived and reactivated. It will be automatically archived overnight.',
                heading: 'Will Auto-Archive',
                button: _this.getReArchiveButton()
            });
        };
        _this.getActivateButtton = function () {
            var activateButton = null;
            if (_this._eventManager.canActivateEvent) {
                activateButton = new button_1.default({
                    text: 'Activate',
                    onClick: _this.activateEvent
                });
            }
            return activateButton;
        };
        _this.getReArchiveButton = function () {
            var reacriveButton = null;
            if (_this._eventManager.canActivateEvent) {
                reacriveButton = new button_1.default({
                    text: 'Archive',
                    onClick: _this.archiveEvent
                });
            }
            return reacriveButton;
        };
        _this.activateEvent = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.put(__1.EndpointUrls.Events + '/Activate', this.eventId, null)];
                    case 1:
                        _a.sent();
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.archiveEvent = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.put(__1.EndpointUrls.Events + '/Archive', this.eventId, null)];
                    case 1:
                        _a.sent();
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setTilesVisiblity = function () {
            if (_this._eventManager.isInventoryEnabled) {
                _this.eventInventoryTile.setVisibility(true);
            }
            if (_this._eventManager.isMeetingsEnabled) {
                _this.meetingsTile.setVisibility(true);
            }
            if (_this._eventManager.isFileManagementEnabled) {
                _this.eventFilesTile.setVisibility(true);
            }
            if (_this._eventManager.isAccommodationsEnabled) {
                _this.travelAndAccommodationsTile.setVisibility(true);
            }
            if (_this._eventManager.isCostManagementEnabled) {
                _this.costManagementTile.setVisibility(true);
            }
            if (_this._eventManager.isSelfManageEnabled) {
                _this.emailAndServicesTile.setVisibility(true);
            }
            if (_this._eventManager.isBadgingEnabled) {
                _this.badgingTile.setVisibility(true);
            }
            if (_this._eventManager.isDiningEnabled) {
                _this.diningRoomTile.setVisibility(true);
            }
        };
        _this.setTilesEditibility = function () {
            _this.eventInfoTile.setIsEditable(_this._eventManager.isEventInfoTileEditable);
            _this.eventAttendeesTile.setIsEditable(_this._eventManager.isEventAttendeeTileEditable);
            _this.eventInventoryTile.setIsEditable(_this._eventManager.isEventInventoryTileEditable);
            _this.eventFilesTile.setIsEditable(_this._eventManager.isFileMagagementTileEditable);
            _this.meetingsTile.setOriginalIsEditable(_this._eventManager.isMeetingTileEditable);
            _this.diningRoomTile.setIsEditable(_this._eventManager.isDiningRoomTileEditable);
            _this.badgingTile.setIsEditable(_this._eventManager.isBadgingTileEditable);
            _this.costManagementTile.setIsEditable(_this._eventManager.isCostManagementTileEditable);
            _this.emailAndServicesTile.setIsEditable(_this._eventManager.isSelfManageEnabled);
        };
        _this.setupEmailAndServicesTile = function () {
            _this.emailAndServicesTile.setIsTransportationVendor(_this._eventManager.isSelfManageTransportationEnabled);
            _this.emailAndServicesTile.setIsHotelVendor(_this._eventManager.isSelfManageHotelEnabled);
        };
        _this.goToHistoryPage = function () {
            _this.navigator.goToHistoryPage({ historyType: __1.HistoryTypes.EventHistory, recordId: _this.eventId });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventLandingPage, currentUserManager.clientChecker.isMobileApp() ? '' : 'Support', navigator, api);
        _this.eventInfoTile = new __1.EventInfoTile(eventId, _this.api, false, currentUserManager, _this.navigator);
        _this.eventAttendeesTile = new __1.EventAttendeesTile(eventId, _this.api, false, currentUserManager, _this.navigator);
        _this.eventFilesTile = new __1.EventFilesTile(eventId, _this.api, currentUserManager, false, _this.navigator);
        _this.eventInventoryTile = new __1.EventInventoryTile(eventId, _this.api, currentUserManager, _this.navigator);
        _this.meetingsTile = new __1.EventMeetingsTile(eventId, meetingId, _this.api, navigator, _this.currentUserManager, _this.navigator);
        _this.travelAndAccommodationsTile = new __1.TravelAndAccommodationsTile(eventId, _this.api, currentUserManager, _this.navigator);
        _this.diningRoomTile = new dining_1.DiningTile(eventId, _this.api, false, false, _this.currentUserManager);
        _this.badgingTile = new tiles_1.BadgingTile(eventId, _this.api, _this.currentUserManager, false, false, _this.navigator);
        _this.reportsTile = new tiles_1.ReportsTile(eventId, _this.api, navigator, currentUserManager);
        _this.extrasTile = new tiles_1.ExtrasTile(eventId, _this.api, navigator, currentUserManager);
        _this.costManagementTile = new tiles_1.CostManagementTile(eventId, _this.api, _this.currentUserManager, false, false, _this.navigator);
        _this.emailAndServicesTile = new vendorPortal_1.EmailAndServicesTile(eventId, currentUserManager.tenantId, currentUserManager, api, false, false, _this.navigator);
        _this.meetingsTile.toggleOpen();
        _this.observationProvider = new __1.ObservationProvider();
        _this._archivedAlertMessage = 'This event has passed and is now archived.';
        _this._deletedAlertMessage = 'This event has been deleted.';
        _this.dropdown = new __1.Dropdown([]);
        _this.historyButton = new button_1.default({
            onClick: _this.goToHistoryPage,
            leftIcon: __1.Icons.Clock,
            text: 'History'
        });
        _this.configurationButtton = new button_1.default({
            onClick: _this.goToEventConfigurationPage,
            leftIcon: __1.Icons.Gear,
            isVisible: false,
            text: 'Tools'
        });
        _this.alertExpandButton = new button_1.default({
            text: 'Read More',
            onClick: function () {
                _this._eventAlertExpanded = true;
                _this.observationProvider.notifyObservers(__1.EventLandingPageActions.EventAlertExpanded);
            }
        });
        _this.alertCollapseButton = new button_1.default({
            text: 'Read Less',
            onClick: function () {
                _this._eventAlertExpanded = false;
                _this.observationProvider.notifyObservers(__1.EventLandingPageActions.EventAlertCollapsed);
            }
        });
        _this.loadEventData();
        return _this;
    }
    Object.defineProperty(EventLandingPage.prototype, "eventLandingData", {
        get: function () {
            return this._eventManager.eventLandingData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingPage.prototype, "helpUrls", {
        get: function () {
            return this._helpUrls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingPage.prototype, "eventAlertEnabled", {
        get: function () {
            return this._eventAlertEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingPage.prototype, "eventAlertMessage", {
        get: function () {
            return this._eventAlertMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingPage.prototype, "eventAlertTitle", {
        get: function () {
            return this._eventAlertTitle;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingPage.prototype, "eventAlertExpanded", {
        get: function () {
            return this._eventAlertExpanded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    return EventLandingPage;
}(__1.BasePage));
exports.default = EventLandingPage;
