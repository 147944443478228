import { h, JSX } from 'preact';
import { UserTableItem } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Card from '../containers/card';
import UserListPageProps from './userListPageProps';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';
import FormattedPhone from '../text/formattedPhone';

class UserListPage extends ObservingComponent<UserListPageProps> {

    private buildColumns = (): SmartTableColumn<UserTableItem>[] => {
        const { page } = this.props;

        return [
            {
                className: 'd-none d-md-table-cell',
                cellTemplate: (item, index) => (<span>{item.id}</span>)
            },
            {
                heading: 'Name',
                sortExpression: 'lastName,firstName',
                cellTemplate: (item, index) =>
                    (<span class="flex">
                        <span class="fill">

                            {page.table.isIndexDeleting(index) ? (
                                <span>{item.firstName} {item.lastName}</span>
                            ) : (
                                <span>
                                    <Button
                                        id={'userTable_row_' + index + '_editButton'}
                                        className="btn-link"
                                        button={page.buildEditUserButton(item)} />
                                </span>
                            )}

                            {!item.isActive && (
                                <span class="text-sm text-red"> - Login Inactive</span>
                            )}

                            {item.lockedOut && (
                                <span class="text-sm text-red"> - Too many login attempts</span>
                            )}

                            <span class="text-sm  d-md-none">
                                <br/>
                                {item.email}
                                <span> | </span>
                                <FormattedPhone phone={item.contactPhone} />
                            </span>

                            <span class="text-sm d-md-none">
                                <br/>
                                Operating Company: {item.departmentName} | Line of Business: {!!item.lineOfBusinessName ? item.lineOfBusinessName : <i>Unknown</i>}
                            </span>
                        </span>
                    </span>),
                appendSavingIndicator: true
            },
            {
                heading: 'Email',
                className: 'd-none d-md-table-cell',
                sortExpression: 'email',
                cellTemplate: (item, index) => (
                    <span>{item.email}</span>
                ),
            },
            {
                heading: 'Operating Company',
                className: 'd-none d-md-table-cell',
                sortExpression: 'departmentName',
                cellTemplate: (item, index) => (
                    <span>{item.departmentName}</span>
                ),
            },
            {
                heading: 'Line of Business',
                className: 'd-none d-md-table-cell',
                sortExpression: 'lineOfBusinessName',
                cellTemplate: (item, index) => (
                    <span>{item.lineOfBusinessName}</span>
                ),
            },
            {
                heading: 'Phone',
                className: 'd-none d-md-table-cell',
                sortExpression: 'contactPhone',
                cellTemplate: (item, index) => (
                    <span>{item.contactPhone}</span>
                ),
            }
        ];
    }

    public render({ page }: UserListPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    heading="All Users"
                    headingRightContent={
                        <div class="button-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                id="newUserButton"
                                color={ButtonColors.Primary}
                                button={page.newUserButton} />
                        </div>
                    }>

                    <SmartTable
                        id="userTable"
                        table={page.table}
                        columns={this.buildColumns()}
                        listOptionSlideContainer={page.listOptionSlideContainer} />
                </Card>
            </div>
        );
    }
}

export default UserListPage;
