import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import { UserDetailPageActions, FileInputField } from 'core.frontend';
import UserDetailPageProps from './userDetailPageProps';
import UserProfileImageField from '../fields/userProfileImageField';
import Alert from '../alerts/alert';
import FormButtonContainer from '../containers/formButtonContainer';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';
import InternationalPhoneField from '../fields/internationalPhoneField';

class UserDetailPage extends ObservingComponent<UserDetailPageProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [
            UserDetailPageActions.RecordLoaded,
            UserDetailPageActions.Unlocking,
            UserDetailPageActions.Unlocked,
            UserDetailPageActions.ResetToDefaultPassword,
            UserDetailPageActions.ResettingToDefaultPassword,
            UserDetailPageActions.ResetPasswordPromptChanged,
            UserDetailPageActions.SubmissionError,
            UserDetailPageActions.Saving
        ]);
    }

    public render({ page }: UserDetailPageProps): JSX.Element {
        return (
            <div>
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />
                <Alert alert={page.alert} />

                <div>
                    <div class="page-heading">
                        <h3>
                            {page.title}
                        </h3>
                        <div class="btn-container">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-icon"
                                button={page.resetPasswordButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="ml-1 btn-icon"
                                button={page.eventHistoryButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="ml-1 btn-icon"
                                button={page.historyButton} />
                        </div>
                    </div>

                    <div class="form-container">
                        <div class="grid-container">

                            <div class="sm-4 mt-1">
                                <UserProfileImageField field={page.form.fields[0] as FileInputField} />
                            </div>

                            {page.form.fields.map((field, index) => {
                                if (index > 0 && field.key !== 'contactPhone') {
                                    return <Field
                                                id={field.key + 'Field'}
                                                className={'stacked-label ' +
                                                        (index === 1 || index === 2 ? 'md-mt-0 ' : '' ) +
                                                        ([
                                                            'firstName',
                                                            'lastName',
                                                            'email',
                                                            'contactEmail',
                                                            'contactPhone',
                                                            'productKnowledgeOptionId',
                                                            'departmentId',
                                                            'lineOfBusinessId',
                                                            'userTitle',
                                                            'userTitleId'
                                                        ].indexOf(field.key) !== -1 ? 'sm-4' :
                                                        [
                                                            'isExecutive'
                                                        ].indexOf(field.key) !== -1 ? 'sm-4 mt-2' :
                                                        'sm-12'
                                                    )}
                                                field={field}
                                                isLoading={page.isLoading} />
                                } else if (field.key === 'contactPhone') {
                                    return (
                                        <InternationalPhoneField
                                            className="sm-4"
                                            field={field} />
                                    )}
                            })}
                        </div>
                    </div>

                    {page.userGroupFields.length > 0 &&
                        <div class="form-container">
                            <h4 class="section-header">User Groups</h4>

                            <p class="text-sm">If no user group is selected this user will have view only rights</p>

                            <div class="grid-container">
                                {page.userGroupFields.map((field) =>
                                    <Field
                                        field={field}
                                        className="sm-4" />
                                )}
                            </div>
                        </div>
                    }

                    {page.showHotelRewardNumbers &&
                        <div class="form-container">
                            <h4 class="section-header">Hotel Reward Numbers</h4>

                            <div class="grid-container">
                                {page.hotelRewardNumbersForm.map((field) =>
                                    <Field
                                        field={field}
                                        className="sm-4" />
                                )}
                            </div>
                        </div>
                    }

                    <FormButtonContainer
                        className='sticky-button-footer'
                        leftButtons={[
                            <Button
                                id="deactivateUserButton"
                                color={ButtonColors.Secondary}
                                className="btn-icon v-center"
                                button={page.deactivateButton} />
                        ]}
                        rightButtons={[
                            <Button
                                id="cancelNewUserButton"
                                color={ButtonColors.Secondary}
                                button={page.cancelButton} />,
                            <Button
                                id="saveNewUserButton"
                                color={ButtonColors.Primary}
                                button={page.saveButton} />
                        ]} />
                </div>
            </div>
        );
    }
}

export default UserDetailPage;
