import { h, Component } from 'preact';
import FormattedPhoneProps from './formattedPhoneProps';
import PhoneInput, { parsePhoneNumber, formatPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';

class FormattedPhone extends Component<FormattedPhoneProps> {

    public render({phone, className}: FormattedPhoneProps) {

        const formattedPhone = formatPhoneNumber(phone);

        return (
            <span class={className}>
                {formattedPhone}
            </span>
        )
    }
}

export default FormattedPhone;
