"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var api_1 = require("../../api");
var components_1 = require("../../components");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var UserDetailPage = /** @class */ (function (_super) {
    __extends(UserDetailPage, _super);
    function UserDetailPage(api, navigator, currentUserManager, userId) {
        var _this = _super.call(this, __1.PageTypes.UserDetail, 'New User') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.userId = userId;
        _this.userGroupFieldPrefix = 'UserGroup_';
        _this.checkAllLoaded = function () {
            if (!_this._hotelRewardsForm.isLoading && !_this._isLoading) {
                _this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordLoaded);
            }
        };
        _this.showDeactivatedAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'User Inactive',
                message: 'This user record is not active. Activating this record will include it in user dropdowns throught the system.',
                button: new button_1.default({
                    onClick: _this.activateUser,
                    processingText: 'Activating...',
                    text: 'Activate'
                })
            });
        };
        _this.showMatchingNameAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Matching User Found',
                message: 'Another user with the same first and last name already exists. Continuing will create a new user with the same name.',
                button: new button_1.default({
                    onClick: _this.saveWithDuplicateName,
                    text: 'Continue',
                    processingText: 'Saving...'
                })
            });
        };
        _this.showPasswordResetConfirmAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Reset Password',
                message: 'This will reset the users password to the system default password',
                button: new button_1.default({
                    text: 'Confirm Reset',
                    processingText: 'Resetting...',
                    onClick: _this.resetToDefaultPassword
                })
            });
        };
        _this.showPasswordResetSuccessAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Success,
                heading: 'Password Reset',
                message: 'Password has been reset to the default password.'
            });
        };
        _this.showLockedOutAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Account Locked',
                message: 'This account is locked due to the number of failed login attempts.',
                button: new button_1.default({
                    onClick: _this.unlockAccount,
                    text: 'Unlock Account',
                    processingText: 'Unlocking...'
                })
            });
        };
        _this.saveWithDuplicateName = function () {
            _this._saveWithDuplicateNameConfirmed = true;
            _this._form.submit();
        };
        _this.save = function () {
            _this._saveWithDuplicateNameConfirmed = false;
            _this._form.submit();
        };
        _this.formSubmitted = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1, nameModel, duplicateNameExists, err_2, _a, err_3;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._form.model.userGroupIds = this._userGroupFields
                            .filter(function (field) { return field.value === true; })
                            .map(function (field) { return _this.parseUserGroupIdField(field); });
                        this._form.model.hotelRewardNumbers = this._hotelRewardsForm.getHotelRewardNumbers();
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.Saving);
                        if (!this.userId) return [3 /*break*/, 5];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordEditing, this.form.model);
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Users, this._form.model.id, this._form.model)];
                    case 2:
                        _b.sent();
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordEdited, this._form.model);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.handleError(err_1);
                        return [2 /*return*/];
                    case 4: return [3 /*break*/, 12];
                    case 5:
                        if (!!this._saveWithDuplicateNameConfirmed) return [3 /*break*/, 9];
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, 8, , 9]);
                        nameModel = {
                            firstName: this._form.model.firstName,
                            lastName: this._form.model.lastName
                        };
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Users + '/ByName', nameModel)];
                    case 7:
                        duplicateNameExists = _b.sent();
                        if (duplicateNameExists) {
                            this.showMatchingNameAlert();
                            this.saveButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        err_2 = _b.sent();
                        this.handleError(err_2);
                        return [2 /*return*/];
                    case 9:
                        _b.trys.push([9, 11, , 12]);
                        _a = this.form.model;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Users, this._form.model)];
                    case 10:
                        _a.id = _b.sent();
                        this.saveButton.setProcessing(false);
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordAdding, this.form.model);
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordAdded, this._form.model);
                        return [3 /*break*/, 12];
                    case 11:
                        err_3 = _b.sent();
                        this.handleError(err_3);
                        return [2 /*return*/];
                    case 12:
                        this.navigator.goToUserList();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.formInvalid = function () {
            _this.saveButton.setProcessing(false);
        };
        _this.userTitleFieldChanged = function (value) {
            if (value === -1) {
                _this._userTitleField.setVisibility(false);
                _this._newUserTitleField.setVisibility(true);
            }
        };
        _this.setUserTitleTypeToExisting = function () {
            _this._userTitleField.setValue(_this._form.model.userTitleId);
            _this._userTitleField.setVisibility(true);
            _this._newUserTitleField.setVisibility(false);
            _this._newUserTitleField.clearValue();
        };
        _this.emailFieldChanged = function () {
            if (!_this._contactEmailField.value && _this._emailField.value) {
                _this._contactEmailField.setValue(_this._emailField.value);
            }
        };
        _this.unlockAccount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.Unlocking);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Users + '/Unlock/' + this.userId, {})];
                    case 1:
                        _a.sent();
                        this._form.model.lockedOut = false;
                        this.alert.show({
                            alertType: __1.AlertType.Success,
                            heading: 'Login Unlocked',
                            message: 'This user account was successfully unlocked.'
                        });
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.Unlocked);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.loadRecord = function (recordId) { return __awaiter(_this, void 0, void 0, function () {
            var user, _i, _a, userGroupField, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordLoading);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.getById(__1.EndpointUrls.Users, recordId)];
                    case 2:
                        user = _c.sent();
                        this._form.setModel(user);
                        for (_i = 0, _a = this._userGroupFields; _i < _a.length; _i++) {
                            userGroupField = _a[_i];
                            userGroupField.setValue(user.userGroupIds.indexOf(this.parseUserGroupIdField(userGroupField)) > -1);
                        }
                        this._hotelRewardsForm.setHotelRewardNumbers(user.hotelRewardNumbers);
                        return [3 /*break*/, 4];
                    case 3:
                        _b = _c.sent();
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordLoadFailure, 'Unable to load record for edit');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.resetToDefaultPassword = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.ResettingToDefaultPassword);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Users + '/ResetToDefaultPassword/' + this.userId, {})];
                    case 1:
                        _a.sent();
                        this.showPasswordResetSuccessAlert();
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.ResetToDefaultPassword);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.cancel = function () {
            _this.observationProvider.notifyObservers(__1.UserDetailPageActions.Cancel);
            _this.navigator.goToUserList();
        };
        _this.deactivateUser = function () { return __awaiter(_this, void 0, void 0, function () {
            var userActiveEvents;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isDeactivatable) {
                            throw new Error('Cannot deactivate because the recordId is not specified');
                        }
                        this._form.model.isActive = false;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Users + '/ActiveEvents', { userId: this.userId })];
                    case 1:
                        userActiveEvents = _a.sent();
                        if (userActiveEvents.length) {
                            this._confirmationPrompt.hide();
                            this.alert.show({
                                alertType: __1.AlertType.Error,
                                heading: 'User has upcoming events',
                                message: 'You must first remove this user from the following events before deactivating:<br/>' +
                                    userActiveEvents.map(function (activeEvent) { return '<br/>' + activeEvent.name; })
                            });
                        }
                        else {
                            this.api.put(__1.EndpointUrls.Users + '/deactivate', this.userId, null);
                            this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordDeactivated, this._form.model);
                            this.navigator.goToUserList();
                        }
                        this._confirmationPrompt.deleteButton.setProcessing(false);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.activateUser = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.Saving, this._form.model);
                        this._form.model.isActive = true;
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Users + '/activate', this.userId, null)];
                    case 1:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.UserDetailPageActions.RecordActivated, this._form.model);
                        this.navigator.goToUserList();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.goToEventHistoryPage = function () {
            _this.navigator.goToEventHistoryPage({ eventHistoryType: __1.EventHistoryType.Users, recordId: _this.userId });
        };
        _this.goToHistoryPage = function () {
            _this.navigator.goToHistoryPage({ historyType: __1.HistoryTypes.UserHistory, recordId: _this.userId });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.UserDetailPage, null, navigator, api);
        _this.observationProvider = new __1.ObservationProvider();
        _this._hotelRewardsForm = new __1.HotelRewardsForm(_this.api);
        _this._hotelRewardsForm.observationProvider.observe(_this.checkAllLoaded, __1.HotelRewardsFormActions.HotelsLoaded);
        _this._userGroupFields = [];
        _this._confirmationPrompt = new __1.ConfirmationPrompt(!!userId, 'Deactivate User', 'Deactivating user accounts will restrict access to the system for the user and exclude the user from dropdown options throughout the system. Are you sure you want to deactive this user account?', 'Deactivate', 'Cancel');
        _this._confirmationPrompt.deleteButton.setProcessingText('Deactivating...');
        _this._confirmationPrompt.observationProvider.observe(_this.deactivateUser, __1.ConfirmationPromptActions.Confirmed);
        _this.initPage().then(_this.checkAllLoaded);
        _this._form.setModel({ isActive: true });
        _this.resetPasswordButton = new button_1.default({
            isVisible: false,
            onClick: _this.showPasswordResetConfirmAlert,
            leftIcon: __1.Icons.Key
        });
        _this.eventHistoryButton = new button_1.default({
            isVisible: false,
            onClick: _this.goToEventHistoryPage,
            leftIcon: __1.Icons.Calendar
        });
        _this.historyButton = new button_1.default({
            isVisible: false,
            onClick: _this.goToHistoryPage,
            leftIcon: __1.Icons.Clock
        });
        _this.deactivateButton = new button_1.default({
            onClick: _this.confirmationPrompt.show,
            leftIcon: __1.Icons.Trash,
            isVisible: !!userId
        });
        _this.cancelButton = new button_1.default({
            onClick: _this.cancel,
            text: 'Cancel'
        });
        _this.saveButton = new button_1.default({
            onClick: _this._form.submit,
            text: 'Save',
            processingText: 'Saving...'
        });
        return _this;
    }
    Object.defineProperty(UserDetailPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailPage.prototype, "userGroupFields", {
        get: function () {
            return this._userGroupFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailPage.prototype, "confirmationPrompt", {
        get: function () {
            return this._confirmationPrompt;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailPage.prototype, "showHotelRewardNumbers", {
        get: function () {
            return this.currentUserManager && this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.TravelAndLodging);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailPage.prototype, "hotelRewardNumbersForm", {
        get: function () {
            return this._hotelRewardsForm.fields;
        },
        enumerable: true,
        configurable: true
    });
    UserDetailPage.prototype.initPage = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoading = true;
                        return [4 /*yield*/, this.buildForm()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.buildUserGroupsForm()];
                    case 2:
                        _a.sent();
                        if (!this.userId) return [3 /*break*/, 4];
                        this.title = 'Edit User';
                        return [4 /*yield*/, this.loadRecord(this.userId)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!this.form.model.isActive) {
                            this.showDeactivatedAlert();
                        }
                        else if (this.form.model.lockedOut) {
                            this.showLockedOutAlert();
                        }
                        if (this.userId && this.form.model.isActive) {
                            this.resetPasswordButton.setVisibility(true);
                        }
                        if (this.userId) {
                            this.eventHistoryButton.setVisibility(true);
                            this.historyButton.setVisibility(true);
                        }
                        this._isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    UserDetailPage.prototype.buildUserGroupsForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _i, _b, userGroup;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.currentUserManager.hasPermission(api_1.PermissionType.EditUsersAdvanced)) {
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.api.getUserGroups()];
                    case 1:
                        _a._userGroups = _c.sent();
                        for (_i = 0, _b = this._userGroups; _i < _b.length; _i++) {
                            userGroup = _b[_i];
                            this._userGroupFields.push(new __1.CheckBoxField(this.userGroupFieldPrefix + userGroup.id, userGroup.name));
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UserDetailPage.prototype.handleError = function (err) {
        if (err && err.message) {
            this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Unable to save',
                message: err.message
            });
        }
        else {
            'An error occurred trying to save this user';
        }
        this.saveButton.setProcessing(false);
        this.observationProvider.notifyObservers(__1.UserDetailPageActions.SubmissionError);
    };
    UserDetailPage.prototype.buildForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var productKnowledgeNoSelectionLabel, titleNoSelectionLabel, productKowledgeDropdown, departmentDropdown, lineOfBusinessDropdown, fields;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._emailField = new __1.EmailField('email', true, 'Login Email');
                        if (this.userId && !this.currentUserManager.hasPermission(api_1.PermissionType.EditUsersAdvanced)) {
                            this._emailField.isDisabled = true;
                        }
                        this._emailField.observationProvider.observe(this.emailFieldChanged, __1.FieldActions.ValueChanged);
                        this._contactEmailField = new __1.EmailField('contactEmail', true, 'Contact Email');
                        productKnowledgeNoSelectionLabel = null;
                        if (!this.currentUserManager.productKnowledgeRequired) {
                            productKnowledgeNoSelectionLabel = 'None';
                        }
                        titleNoSelectionLabel = null;
                        if (!this.currentUserManager.titleRequired) {
                            titleNoSelectionLabel = 'None';
                        }
                        this._userTitleField = new __1.UserTitlesDropdown(this.api, 'userTitleId', 'Title', this.currentUserManager.titleRequired, 'required', true, null, titleNoSelectionLabel);
                        this._userTitleField.observationProvider.observe(this.userTitleFieldChanged, __1.FieldActions.ValueChanged);
                        this._newUserTitleField = new __1.TextField('newTitle', 'New Title', true, 100, 'required');
                        this._newUserTitleField.setVisibility(false);
                        this._newUserTitleField.labelButtonText = 'Use Existing Title';
                        this._newUserTitleField.observationProvider.observe(this.setUserTitleTypeToExisting, __1.FieldActions.LabelButtonClicked);
                        productKowledgeDropdown = new components_1.ProductKnowledgeDropdownField(this.api, 'productKnowledgeOptionId', 'Area of Expertise', this.currentUserManager.productKnowledgeRequired, 'required', null, productKnowledgeNoSelectionLabel);
                        departmentDropdown = new __1.DepartmentDropdown(this.api, 'departmentId', true);
                        lineOfBusinessDropdown = new __1.LinesOfBusinessDropdown(this.api, 'lineOfBusinessId', false, null, 'None');
                        fields = [
                            new __1.FileInputField('profileImageUrl', 'Profile Image', false),
                            new __1.TextField('firstName', 'First Name', true, 100, 'required'),
                            new __1.TextField('lastName', 'Last Name', true, 100, 'required'),
                            this._emailField,
                            this._contactEmailField,
                            new __1.TextField('contactPhone', 'Mobile Phone', true, 50),
                            productKowledgeDropdown,
                            departmentDropdown,
                            lineOfBusinessDropdown,
                            this._userTitleField,
                            new __1.CheckBoxField('isExecutive', 'Is Executive'),
                            this._newUserTitleField,
                            new __1.TextField('comments', 'Comments', false, 500)
                        ];
                        this._form = new __1.Form(fields);
                        this._form.observationProvider.observe(this.formSubmitted, __1.FormActions.FormSubmitted);
                        this._form.observationProvider.observe(this.formInvalid, __1.FormActions.FormSubmissionFailure);
                        return [4 /*yield*/, productKowledgeDropdown.loadingPromise.then(function () { })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, departmentDropdown.loadingPromise.then(function () { })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, lineOfBusinessDropdown.loadingPromise.then(function () { })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserDetailPage.prototype.parseUserGroupIdField = function (field) {
        return parseInt(field.key.replace(this.userGroupFieldPrefix, ''), 10);
    };
    Object.defineProperty(UserDetailPage.prototype, "isDeactivatable", {
        get: function () {
            return !!this.userId && this.form.model.isActive;
        },
        enumerable: true,
        configurable: true
    });
    return UserDetailPage;
}(__1.BasePage));
exports.default = UserDetailPage;
