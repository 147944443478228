import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { NavigatorActions, Icons } from 'core.frontend';
import Button from '../buttons/button';
import { UserDropdownProps } from './userDropdownProps';

class UserDropdown extends ObservingComponent<UserDropdownProps> {

    public componentWillMount() {
        const { app } = this.props;

        this.registerUpdateObserver(app.navigator.observationProvider, [NavigatorActions.UserDropdownOpened, NavigatorActions.UserDropdownClosed]);
        window.addEventListener('click', app.navigator.closeUserDropdown);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.props.app.navigator.closeUserDropdown);
    }

    public render({ app }: UserDropdownProps): JSX.Element {

        return (
            <ul class={"dropdown user-nav" + (app.navigator.userDropdownOpen ? ' open' : '')}>
                <li class="user-info">
                    <b>{app.currentUserManager.userFullName}</b>
                </li>
                <li>
                    <Button button={app.userProfileButton} />
                </li>
                <li>
                    <Button button={app.vendorUserProfileButton} />
                </li>
                <li>
                    <Button button={app.logoutButton} />
                </li>
            </ul>
        );
    }
}

export default UserDropdown;
