"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var button_1 = require("../button/button");
var utils_1 = require("../../utils");
var accordianActions_1 = require("./accordianActions");
var Accordian = /** @class */ (function () {
    function Accordian(initialOpenState) {
        var _this = this;
        if (initialOpenState === void 0) { initialOpenState = true; }
        this.toggleOpenState = function () {
            _this.isOpen = _this._isOpen !== true;
        };
        this.setOpenState = function (isOpen) {
            if (isOpen != _this._isOpen) {
                _this._isOpen = isOpen;
                _this.toggleButton.setLeftIcon(_this._isOpen ? __1.Icons.ChevronDown : __1.Icons.ChevronRight);
                _this.observationProvider.notifyObservers(accordianActions_1.default.Toggled);
            }
        };
        this.observationProvider = new utils_1.ObservationProvider();
        this._isOpen = initialOpenState;
        this.toggleButton = new button_1.default({
            leftIcon: initialOpenState ? __1.Icons.ChevronDown : __1.Icons.ChevronRight,
            onClick: this.toggleOpenState
        });
    }
    Object.defineProperty(Accordian.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        set: function (value) {
            this.setOpenState(value);
        },
        enumerable: true,
        configurable: true
    });
    return Accordian;
}());
exports.default = Accordian;
