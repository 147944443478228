import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import InventoryDetailPageProps from './inventoryDetailPageProps';
import Button from '../buttons/button';
import Field from '../fields/field';
import BinaryFileInputField from '../fields/binaryFileInputField';
import FileInputField from '../fields/fileInputField';
import EmptyState from '../uiStates/emptyState';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import DataList from '../dataList/dataList';
import FormButtonContainer from '../containers/formButtonContainer';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import { Icons, TextField } from 'core.frontend';
import Alert from '../alerts/alert';
import FileListItem from '../dataList/fileListItem/fileListItem';
import VideoModalButton from '../buttons/videoModalButton';

class InventoryDetailPage extends ObservingComponent<InventoryDetailPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: InventoryDetailPageProps): JSX.Element {
        return (
            <div>
                <ConfirmationPrompt confirmationPrompt={page.updateCurrentEventsCostManagementValuesConfirmationPrompt} />
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />

                <Alert alert={page.alert} />

                <div>
                    <div class="page-heading">
                        <h3>
                            {page.title}
                        </h3>
                        <div class="btn-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="ml-1 btn-icon"
                                button={page.duplicateButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-icon"
                                button={page.eventHistoryButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-icon"
                                button={page.historyButton} />
                        </div>
                    </div>

                    <div class="form-container grid-container">

                        <Field
                            field={page.nameField}
                            isLoading={page.isLoading}
                            className= "sm-9"/>

                        <Field
                            field={page.scaleField}
                            isLoading={page.isLoading}
                            className= "sm-3"/>

                        <div className="sm-12">
                            <div className="grid-container">
                                <Field
                                    field={page.inventoryItemTypeField}
                                    isLoading={page.isLoading}
                                    className="sm-4" />
                            </div>
                        </div>

                        <Field
                            field={page.businessAreaField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.lineOfBusinessField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.legnthField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.widthField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.heightField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.weightField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.serialNumberField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.valueField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.inventoryItemGroupField}
                            isLoading={page.isLoading}
                            className= "sm-4"/>

                        <Field
                            field={page.descriptionField}
                            isLoading={page.isLoading}
                            className= "sm-12"/>
                    </div>

                    <div class="form-container mt-2">
                    <div class="flex">
                        <div class="fill flex">
                            <b class="v-center d-none d-md-inline">
                                Files
                            </b>
                        </div>

                        <BinaryFileInputField
                            buttonClassName="btn btn-sm btn-primary v-center"
                            leftIcon={Icons.Plus}
                            field={page.addFileField} />
                    </div>

                    <hr/>

                    <DataList
                        dataList={page.fileDataList}
                        itemTemplate={(item) => (

                        <FileListItem
                            key={item.form.model.id}
                            icon={page.getIcon(item)}
                            downloadButton={page.buildDownloadButton(item)}
                            fileType={item.form.model.fileType}
                            isApp={page.isApp}
                            createdDateTime={item.form.model.createdDateTime}
                            uploadedByUsersName={item.form.model.uploadedByUsersName}
                            isDownloadable={!!item.form.model.id}
                            fileName={item.form.model.fileName}
                            fileSize={item.form.model.fileSize}
                            friendlyNameField={item.form.fields[0] as TextField}
                            descriptionField={item.form.fields[1] as TextField}
                            allowManualEditMode={!!item.form.model.id}
                            isEditMode={!item.form.model.id} />
                        )}/>

                    </div>

                    <div class="form-container">
                        <div class="flex">
                            <div class="fill flex">
                                <b class="v-center d-none d-md-inline">Images</b>
                            </div>

                            <FileInputField
                                field={page.addImageField}
                                buttonClassName="btn btn-sm btn-primary"
                                leftIcon={Icons.Plus} />
                        </div>

                        <hr/>

                        <div class="grid-container">
                            {page.form.model.images.map((image, index) =>
                                <div class={'sm-3 image-container ' + (image.isRemoved ? 'deleted ' : '')}>
                                    <img src={image.imageUrl} />

                                    <Button
                                        className="btn-remove-data-item"
                                        button={page.buildRemoveImageButton(image, index)} />

                                    <Button
                                        className="btn-remove-data-item"
                                        button={page.buildCancelRemoveImageButton(image, index)} />
                                </div>
                            )}
                        </div>

                        {page.form.model.images.length === 0 &&
                            <EmptyState
                                message="This inventory item has no images"
                                title="" />
                        }
                    </div>

                    {page.crateDataList.isVisible &&
                        <div>
                            <div class="form-container">
                                <div class="flex">
                                    <div class="fill flex">
                                        <b class="v-center d-none d-md-inline">Crates</b>
                                    </div>

                                    <Button
                                        size={ButtonSizes.Small}
                                        color={ButtonColors.Primary}
                                        button={page.addCrateButton} />
                                </div>
                            </div>

                            <hr/>

                            <DataList
                                dataList={page.crateDataList}
                                itemTemplate={(item) =>
                                    <li class={item.form.model.isRemoved ? 'deleting' : ''}>
                                        <div class="form-container grid-container">
                                            {item.form.fields.map((field, index) => {
                                                return (
                                                    <Field field={field}
                                                        className={[2,3,4,5].indexOf(index) >= 0 ? 'sm-3' :
                                                                    [9].indexOf(index) >= 0 ? 'sm-12' :
                                                                    [6,7,8].indexOf(index) >= 0 ? 'sm-4' : 'sm-6'} />
                                                );
                                            })}
                                        </div>
                                    </li>
                                }/>
                        </div>
                    }

                    <FormButtonContainer
                        className='sticky-button-footer'
                        leftButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-icon"
                                button={page.deactivateButton} />
                        ]}
                        rightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={page.cancelButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={page.saveButton} />
                        ]} />
                </div>
            </div>
        );
    }
}

export default InventoryDetailPage;
