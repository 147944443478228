import { h, JSX, Component } from 'preact';
import MeetingAttendeesLabelProps from './meetingAttendeesLabelProps';

class MeetingAttendeesLabel extends Component<MeetingAttendeesLabelProps> {

    public render({ meeting }: MeetingAttendeesLabelProps): JSX.Element {
        return (
            <div>
                {meeting.allAttendees &&
                    <span>All Attendees</span>
                }

                <div>
                    {meeting.attendeeBusinessArea}
                </div>

                <div>
                    {meeting.attendeeDepartment}
                </div>

                {meeting.attendees.map((attendee, index) =>
                    <div>
                        {attendee.fullName}
                        {attendee.isLead &&
                            <span> (POC)</span>
                        }
                        {attendee.isMeetAndGreet &&
                            <span> (M&G)</span>
                        }

                        {index + 1 < meeting.attendees.length &&
                            <span>,&nbsp;</span>
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default MeetingAttendeesLabel;
