"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var button_1 = require("../components/button/button");
var EventLandingTile = /** @class */ (function () {
    function EventLandingTile(title, api, isApp, _isVisible, _isEditable) {
        var _this = this;
        if (_isVisible === void 0) { _isVisible = true; }
        if (_isEditable === void 0) { _isEditable = true; }
        this.api = api;
        this.isApp = isApp;
        this._isVisible = _isVisible;
        this._isEditable = _isEditable;
        this.refresh = function () {
            _this.processLoadData();
            if (!_this.isOpen) {
                _this.toggleOpen();
            }
        };
        this.callUpdate = function () {
            _this.update();
        };
        this.update = function () { };
        this.setEditFormSaving = function () {
            _this._isEditFormSaving = true;
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.EditFormSaving);
        };
        this.hideHeader = function () {
            _this._isHeaderHidden = true;
        };
        this.showHeader = function () {
            _this._isHeaderHidden = false;
        };
        this.toggleFullScreen = function () {
            _this._isFullScreen = !_this._isFullScreen;
            _this._isOpen = true;
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.FullScreenChanged);
        };
        this.setIsEditable = function (isEditable) {
            if (isEditable === _this._isEditable) {
                return;
            }
            _this._isEditable = isEditable;
            _this.editButton.setVisibility(_this._isEditable);
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.IsEditableChanged);
        };
        this.setVisibility = function (isVisible) {
            _this._isVisible = isVisible;
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.VisibilityToggled);
        };
        this.activateEditMode = function () {
            if (!_this.isEditable) {
                return;
            }
            _this._isEditMode = true;
            _this.saveButton.setProcessing(false);
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.EditModeActivated);
            if (!_this.isOpen) {
                _this.toggleOpen();
            }
        };
        this.deactivateEditMode = function () {
            _this._isEditMode = false;
            _this._isEditFormSaving = false;
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.EditModeDeactivated);
        };
        this.toggleOpen = function () {
            if (_this._isFullScreen) {
                return;
            }
            _this._isOpen = !_this._isOpen;
            if (!_this._isOpen) {
                _this._isEditMode = false;
            }
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.Toggled, _this._isOpen);
            if (!_this._isLoaded) {
                _this.processLoadData();
            }
        };
        this.processLoadData = function () {
            if (_this.isOpen && !_this.isEditMode) {
                _this._isLoading = true;
                _this.observationProvider.notifyObservers(__1.EventLandingTileActions.DataLoading);
                _this.loadData()
                    .then(function () {
                    _this._isLoaded = true;
                    _this._isLoading = false;
                    _this.observationProvider.notifyObservers(__1.EventLandingTileActions.DataLoaded);
                });
            }
        };
        this.loadData = function () {
            return new Promise(function (resolve) { resolve(); });
        };
        this.setTitle = function (title) {
            _this._title = title;
            _this.observationProvider.notifyObservers(__1.EventLandingTileActions.TitleChanged);
        };
        this.observationProvider = new __1.ObservationProvider();
        this._title = title;
        this._isOpen = false;
        this._isEditFormSaving = false;
        this._isLoading = true;
        this._isEditMode = false;
        this._isHeaderHidden = false;
        this._isFullScreen = false;
        this.exitEditModeButton = new button_1.default({
            text: 'Exit',
            onClick: this.deactivateEditMode
        });
        this.saveButton = new button_1.default({
            text: 'Save',
            onClick: this.callUpdate,
            processingText: 'Saving...'
        });
        this.fullScreenButton = new button_1.default({
            leftIcon: __1.Icons.Expand,
            onClick: this.toggleFullScreen,
        });
        this.editButton = new button_1.default({
            leftIcon: __1.Icons.Edit,
            onClick: this.activateEditMode,
            isVisible: this._isEditable
        });
        this.refreshButton = new button_1.default({
            leftIcon: __1.Icons.Refresh,
            onClick: this.refresh
        });
    }
    Object.defineProperty(EventLandingTile.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "helpButton", {
        get: function () {
            return this._helpButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "isEditFormSaving", {
        get: function () {
            return this._isEditFormSaving;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "isEditMode", {
        get: function () {
            return this._isEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "isEditable", {
        get: function () {
            return this._isEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "isHeaderHidden", {
        get: function () {
            return this._isHeaderHidden;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "isFullScreen", {
        get: function () {
            return this._isFullScreen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventLandingTile.prototype, "title", {
        get: function () {
            return this._title;
        },
        enumerable: true,
        configurable: true
    });
    return EventLandingTile;
}());
exports.default = EventLandingTile;
