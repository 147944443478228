import { h, JSX } from 'preact';
import ObservingComponent from '../../../componentBases/observingComponent';
import { VenueDataGridReportItem, DateFormats, DateUtils, MeetingStatuses } from 'core.frontend';
import FormattedDate from '../../../text/formattedDate';
import VenueDataGridReportPageProps from './venueDataGridReportPageProps';
import Button from '../../../buttons/button';
import MeetingDimension from '../meetingDimension';

class VenueDataGridReportPage extends ObservingComponent<VenueDataGridReportPageProps> {

    private readonly rowHeight: number = 50;
    private readonly columnMinWidth: number = 150;

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private getFirstGridDateTime(date: Date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        let monthStr = month.toString();
        let dayStr = day.toString();

        if (month < 10) {
            monthStr = '0' + monthStr;
        }

        if (day < 10) {
            dayStr = '0' + dayStr;
        }

        const firstDateTime = new Date(`${year}-${monthStr}-${dayStr}T${this.props.page.firstGridTime}`);

        return firstDateTime;
    }

    private getMeetingOffsetMinutes = (startDateTime: Date) => {
        /* calculates the number of minutes a meeting start time is before the first time on the grid */
        var offset = DateUtils.getMinutesDifference(this.getFirstGridDateTime(startDateTime), startDateTime);
        return offset > 0 ? offset : 0;
    }

    private getMeetingBlockHeight = (meeting: VenueDataGridReportItem) => {
        let lengthInMinutes = DateUtils.getMinutesDifference(meeting.endDateTime, meeting.startDateTime);
        lengthInMinutes -= this.getMeetingOffsetMinutes(meeting.startDateTime);

        return (lengthInMinutes / 30 * this.rowHeight) - 1;
    }

    private getMeetingBlockTop = (meeting: VenueDataGridReportItem) => {
        let minutesFromBase = DateUtils.getMinutesDifference(meeting.startDateTime, this.getFirstGridDateTime(meeting.startDateTime));
        minutesFromBase += this.getMeetingOffsetMinutes(meeting.startDateTime);

        return minutesFromBase / 30 * this.rowHeight;
    }

    private getMeetingDimensionsForDate = (date: Date) => {
        const { page } = this.props;

        const meetingDimensions: MeetingDimension[][] = [];

        for(let i = 0; i < page.reportData.venues.length; i++) {
            const venue = page.reportData.venues[i];

            meetingDimensions[venue.id] = [];

            const meetings = page.getMeetings(venue, date);

            for(let j = 0; j < meetings.length; j++) {

                const meeting = meetings[j];

                const top = this.getMeetingBlockTop(meeting);
                const height =  this.getMeetingBlockHeight(meeting);

                meetingDimensions[venue.id].push({
                    top: top,
                    height: height,
                    left: 0,
                    right: 0,
                    bottom: top + height,
                    rightAdjacentMeetings: 0,
                    column: 0,
                    meetingId: meeting.meetingId
                });
            }

            // sort by top then by bottom
            meetingDimensions[venue.id] = meetingDimensions[venue.id].sort((a, b) => {
                if (a.top < b.top) {
                    return -1;
                }

                if (a.top > b.top) {
                    return 1;
                }

                if (a.bottom > b.bottom) {
                    return -1;
                }

                if (a.bottom < b.bottom) {
                    return 1;
                }

                return 0;
            });

            this.setMeetingDimensionsColumnNumber(meetingDimensions[venue.id], 0);
        }

        return meetingDimensions;
    }

    private setMeetingDimensionsColumnNumber(meetingDimensions: MeetingDimension[], seedColumnNumber: number) {

        for (let i = 0; i < meetingDimensions.length; i++) {
            const meetingDimension = meetingDimensions[i];

            if (meetingDimension.column > 0) {
                continue;
            }

            meetingDimension.column = seedColumnNumber + 1;
            meetingDimension.left = this.columnMinWidth * seedColumnNumber;
            meetingDimension.right = this.columnMinWidth * seedColumnNumber + 1;

            const adjacentMeetings = meetingDimensions
                .filter((meeting) =>
                    meeting.meetingId != meetingDimension.meetingId &&
                    meeting.top >= meetingDimension.top &&
                    meeting.top < meetingDimension.bottom
                );

            this.setMeetingDimensionsColumnNumber(adjacentMeetings, seedColumnNumber + 1);

            if (adjacentMeetings.length > 0) {
                let maxAdjacentMeetings = 0;

                for(let j = 0; j < adjacentMeetings.length; j++) {
                    if (adjacentMeetings[j].rightAdjacentMeetings + 1 > maxAdjacentMeetings) {
                        maxAdjacentMeetings += adjacentMeetings[j].rightAdjacentMeetings + 1;
                    }
                }

                meetingDimension.rightAdjacentMeetings = Math.max(maxAdjacentMeetings, 1);
            } else {
                meetingDimension.rightAdjacentMeetings = 0;
            }
        }
    }

    private getColumnWidths(meetingDimensions: MeetingDimension[][]) {
        const { page } = this.props;

        const columnWidths: number[] = [];

        for(let i = 0; i < page.reportData.venues.length; i++) {
            const venue = page.reportData.venues[i];

            columnWidths[venue.id] = this.columnMinWidth * Math.max(...meetingDimensions[venue.id].map((dimension) => dimension.column));
        }

        return columnWidths;
    }

    public render({ page }: VenueDataGridReportPageProps): JSX.Element {

        return (
            <div class="report allow-overflow">

                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                {!!page.showHeader &&
                    <div id="reportHeader">
                        <h3 class="text-center mb-p5">
                            {!page.isLoading &&
                                <span>{page.reportData.eventName}</span>
                            }
                        </h3>

                        <div class="text-sm flex mb-p5">
                            <div class="fill">
                                Printed by: {page.currentUsersName}
                                <div><b>{page.title}</b></div>
                            </div>
                            <div class="text-right">
                                <FormattedDate
                                    date={new Date()}
                                    format={DateFormats.dddMMMMDhmmA} />
                            </div>
                        </div>
                    </div>
                }

                {!page.isLoading &&
                    <div>
                        {page.reportDates.map((reportDate) => {

                            const meetingDimensions = this.getMeetingDimensionsForDate(reportDate);
                            const columnWidths = this.getColumnWidths(meetingDimensions);

                            return (
                                <div class="report-page text-sm">

                                <div class="text-center mb-1">
                                    <b>
                                        <FormattedDate
                                            date={reportDate}
                                            format={DateFormats.MMMdYYYY} />
                                    </b>
                                </div>

                                <div class="datagrid">
                                    <div class="datagrid-header">
                                        <div class="datagrid-report-time-cell">&nbsp;</div>
                                        {page.reportData.venues.map((venue) =>
                                            <div
                                                style={{
                                                    minWidth: columnWidths[venue.id],
                                                    maxWidth: columnWidths[venue.id]
                                                }}
                                                class="datagrid-column-header">

                                                    {venue.name}
                                                </div>
                                        )}
                                    </div>

                                    <div class="datagrid-content">
                                        <div class="datagrid-report-time-cell-column">
                                            {page.gridTimes.map((time, index) =>
                                                    <div
                                                        style={{
                                                            height: this.rowHeight
                                                        }}
                                                        class="datagrid-report-time-cell">

                                                        <span>
                                                            {time}
                                                        </span>
                                                    </div>
                                            )}
                                        </div>

                                        {page.reportData.venues.map((venue) => (
                                            <div
                                                style={{
                                                    height: this.rowHeight * page.gridTimes.length,
                                                    minWidth: columnWidths[venue.id],
                                                    maxWidth: columnWidths[venue.id]
                                                }}
                                                class="datagrid-report-items-column">

                                                {page.gridTimes.map((time, index) =>
                                                    <span
                                                        style={{
                                                            top: (this.rowHeight * index),
                                                            height: this.rowHeight
                                                        }}
                                                        class="datagrid-report-items-cell"></span>
                                                )}

                                                {page.getMeetings(venue, reportDate).map((meeting) => {
                                                    const meetingDimension = meetingDimensions[venue.id].find((d) => d.meetingId === meeting.meetingId);

                                                    return (
                                                        <div
                                                            style={{
                                                                top: meetingDimension.top + 1,
                                                                minHeight: Math.min(
                                                                    meetingDimension.height - 2,
                                                                    (this.rowHeight * page.gridTimes.length) - meetingDimension.top - 2
                                                                ),
                                                                left: meetingDimension.left + 1,
                                                                width: Math.min(
                                                                    columnWidths[venue.id] - (meetingDimension.rightAdjacentMeetings * this.columnMinWidth) - meetingDimension.left - 2,
                                                                    this.columnMinWidth - 2
                                                                )
                                                            }}
                                                            class="datagrid-report-item">

                                                            <div class="datagrid-report-item-content">
                                                                {!page.hideMeetingDetails &&
                                                                    <div>
                                                                        <b>
                                                                            {!!page.showMeetingName &&
                                                                                <span>
                                                                                    {meeting.status == MeetingStatuses.Working &&
                                                                                        <span class='invalid'>(Working) </span>
                                                                                    }
                                                                                    {meeting.status == MeetingStatuses.Requested &&
                                                                                        <span class='invalid'>(R) </span>
                                                                                    }
                                                                                    {meeting.meetingName}
                                                                                &nbsp;
                                                                                </span>
                                                                            }

                                                                            {!!page.showMeetingNumber &&
                                                                                <span>
                                                                                    ({meeting.meetingNumber})
                                                                                </span>
                                                                            }
                                                                        </b>

                                                                        {!!meeting.dataGridComments &&
                                                                            <div>{meeting.dataGridComments}</div>
                                                                        }

                                                                        {!! page.showMeetingTime &&
                                                                            <div>
                                                                                <FormattedDate
                                                                                    date={meeting.startDateTime}
                                                                                    format={DateFormats.hhmm} />
                                                                                <span> - </span>
                                                                                <FormattedDate
                                                                                    date={meeting.endDateTime}
                                                                                    format={DateFormats.hhmm} />
                                                                            </div>
                                                                        }

                                                                        {!!page.showLead && !!meeting.lead &&
                                                                            <div>
                                                                                POC: {meeting.lead}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            )}
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default VenueDataGridReportPage;
