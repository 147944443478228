import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import EventAttendeeMultiselectFieldProps from './eventAttendeeMultiselectFieldProps';
import { EventAttendeeMultiselectFieldOption } from 'core.frontend';

class EventAttendeeMultiselectField extends ObservingComponent<EventAttendeeMultiselectFieldProps> {
    private isFocusing: boolean;

    public componentWillMount(): void {
        const { eventAttendeeMultiselectField } = this.props;

        this.registerUpdateObserver(eventAttendeeMultiselectField.observationProvider);
        window.addEventListener('mousedown', this.blurred);
    }

    public componentWillUnmount() {
        window.removeEventListener('mousedown', this.blurred);
    }

    private attendeeOptionClicked = (eventAttendeeOption: EventAttendeeMultiselectFieldOption) => (e: Event) => {
        e.preventDefault();

        this.props.eventAttendeeMultiselectField.toggleSelection(eventAttendeeOption);
    }

    public focused = (e: Event) => {
        const { eventAttendeeMultiselectField } = this.props;

        // set isFocusing because propogation will fire window.mousedown
        // which fires blurred. Otherwise focus would immediately blur
        this.isFocusing = true;
        eventAttendeeMultiselectField.focus();
    }

    public blurred = (e: Event) => {
        const { eventAttendeeMultiselectField } = this.props;

        if (!this.isFocusing && eventAttendeeMultiselectField.dropdownOpen) {
            eventAttendeeMultiselectField.blur();
        }

        this.isFocusing = false;
    }

    public searchPhraseChanged = (e: any) => {
        const { eventAttendeeMultiselectField } = this.props;
        const newValue = e.target.value;

        eventAttendeeMultiselectField.search(newValue);
    }

    public render({ eventAttendeeMultiselectField, className }: EventAttendeeMultiselectFieldProps): JSX.Element {

        return (
            <div
                class={
                    `search-field search-field ${className}
                    ${eventAttendeeMultiselectField.isFocused ? ' focused ' : ''}
                    ${eventAttendeeMultiselectField.dropdownOpen ? ' active ' : ''}`
                }
                onMouseDown={this.focused}>

                <div class="input-field">
                    <label>
                        <span class="fill v-center mr-p5">
                            {eventAttendeeMultiselectField.label}
                        </span>

                        {eventAttendeeMultiselectField.errorMessage && (
                            <span className="v-center invalid">{eventAttendeeMultiselectField.errorMessage}</span>
                        )}
                    </label>

                    <input
                        placeholder="Search by name..."
                        type="text"
                        className={eventAttendeeMultiselectField.isInvalid ? 'invalid' : ''}
                        id={eventAttendeeMultiselectField.uniqueKey}
                        name={eventAttendeeMultiselectField.uniqueKey}
                        maxLength={250}
                        value={eventAttendeeMultiselectField.searchBoxValue}
                        onKeyUp={this.searchPhraseChanged}
                        disabled={eventAttendeeMultiselectField.isDisabled} />
                </div>

                <div class="search-dropdown">
                    {!!eventAttendeeMultiselectField.options.length &&
                        <div class="search-results">
                            <ul>
                                { eventAttendeeMultiselectField.options.map((attendeeOption) => {
                                    return (
                                        <li
                                            onClick={this.attendeeOptionClicked(attendeeOption)}
                                            class={attendeeOption.selected ? 'selected' : ''}>

                                            {attendeeOption.selected &&
                                                <i class="ti ti-check"></i>
                                            }

                                            {attendeeOption.fullName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    }

                    {!eventAttendeeMultiselectField.options.length &&
                        <div>
                            {eventAttendeeMultiselectField.searchBoxValue &&
                                <div class="empty-state">
                                        No matching attendees
                                </div>
                            }

                            {!eventAttendeeMultiselectField.searchBoxValue &&
                                <div class="empty-state">
                                        No attendees available
                                </div>
                            }
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default EventAttendeeMultiselectField;
