import { FieldActions, ActionUtils, CheckBoxField as FrontEndCheckBoxField } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface ToggleSwitchFieldProps {
    id?: string;
    field: FrontEndCheckBoxField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class ToggleSwitchField extends ObservingComponent<ToggleSwitchFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;

        field.setValue(field.value === true ? false : true);
    }

    private clicked = (e: Event) => {
        e.stopPropagation();
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: ToggleSwitchFieldProps): JSX.Element {

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div
                onClick={this.clicked}
                className={`checkbox ${className}`}>

                <label
                    className={labelClassName}
                    for={field.uniqueKey}>

                    <span className="flex">
                        <span class="label left-label toggle-switch-label v-center">
                            {field.label}
                        </span>

                        <div className="toggle-switch-wrapper v-center">
                            <input
                                type="checkbox"
                                id={field.uniqueKey}
                                name={field.uniqueKey}
                                onFocus={field.focus}
                                onBlur={field.blur}
                                checked={field.value === true}
                                onChange={this.valueChanged}
                                className={field.isInvalid ? 'invalid' : ' toggle-switch'}
                                disabled={field.isDisabled} />

                            <span class="toggle-switch-slider"></span>
                        </div>
                    </span>
                </label>

            </div>
        );
    }
}

export default ToggleSwitchField;
