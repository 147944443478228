import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import VendorCompanyDetailPageProps from './vendorCompanyDetailPageProps';
import Alert from '../alerts/alert';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import FormButtonContainer from '../containers/formButtonContainer';
import Field from '../fields/field';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';

class VendorCompanyDetailPage extends ObservingComponent<VendorCompanyDetailPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: VendorCompanyDetailPageProps): JSX.Element {

        return (
            <div>
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />
                <Alert  alert={page.alert} />

                <div class="page-heading">
                    <h3>{page.title}</h3>

                    <div class="btn-container flex">

                        <Button
                            className="btn-icon btn-lg"
                            button={page.helpButton} />


                        <Button
                            color={ButtonColors.Secondary}
                            className="ml-1 btn-icon"
                            button={page.eventHistoryButton} />
                    </div>
                </div>

                <div class="form-container">
                    {page.form.fields.map((field) =>
                        <Field
                            field={field}
                            isLoading={page.isLoading} />
                    )}
                </div>

                <FormButtonContainer
                    leftButtons={[
                        <Button
                            className="btn-icon"
                            color={ButtonColors.Secondary}
                            button={page.deactivateButton} />
                    ]}
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.cancelButton} />
                        ,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton}/>
                    ]} />
            </div>
        );
    }
}

export default VendorCompanyDetailPage;
