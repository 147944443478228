import { h, Component, JSX }from 'preact';
import LabeledDividerProps from './LabeledDividerProps';

class LabeledDivider extends Component<LabeledDividerProps> {

    public render({ text }: LabeledDividerProps): JSX.Element {
        return (
            <div class="labeled-divider"><span>{text}</span></div>
        );
    }
}

export default LabeledDivider;
