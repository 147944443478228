"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventConfigurationPageMeetingPanes;
(function (EventConfigurationPageMeetingPanes) {
    EventConfigurationPageMeetingPanes[EventConfigurationPageMeetingPanes["Settings"] = 1] = "Settings";
    EventConfigurationPageMeetingPanes[EventConfigurationPageMeetingPanes["Venues"] = 2] = "Venues";
    EventConfigurationPageMeetingPanes[EventConfigurationPageMeetingPanes["MeetingCategories"] = 3] = "MeetingCategories";
    EventConfigurationPageMeetingPanes[EventConfigurationPageMeetingPanes["MeetingRequests"] = 4] = "MeetingRequests";
})(EventConfigurationPageMeetingPanes || (EventConfigurationPageMeetingPanes = {}));
exports.default = EventConfigurationPageMeetingPanes;
