"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AccommodationDestinationType;
(function (AccommodationDestinationType) {
    AccommodationDestinationType[AccommodationDestinationType["Air"] = 0] = "Air";
    AccommodationDestinationType[AccommodationDestinationType["Rail"] = 1] = "Rail";
    AccommodationDestinationType[AccommodationDestinationType["Bus"] = 2] = "Bus";
    AccommodationDestinationType[AccommodationDestinationType["Other"] = 3] = "Other";
})(AccommodationDestinationType || (AccommodationDestinationType = {}));
exports.default = AccommodationDestinationType;
