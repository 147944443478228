import { h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import EmailAndServicesTileProps from './emailAndServicesTileProps';
import EventInfoTile from '../../events/eventInfoTile';
import LodgingStatus from '../../events/lodgingStatus';
import DailyTransportStatus from '../../events/dailyTransportStatus';
import GroupContainer from '../../groupContainers/groupContainer';
import EmptyState from '../../uiStates/emptyState';
import PhoneButton from '../../buttons/phoneButton';
import EmailButton from '../../buttons/emailButton';
import Button from '../../buttons/button';
import ButtonColors from '../../buttons/buttonColors';
import FormattedDate from '../../text/formattedDate';
import FormattedTimespan from '../../text/formattedTimespan';
import { DateFormats } from 'core.frontend';
import Field  from '../../fields/field';
import FulfillmentForm from '../fulfillment/fulfillmentForm';
import Alert from '../../alerts/alert';
import TagCollection from '../../tags/tagCollection';
import ListOptionSlideContainer from '../../events/rightSlider/listOptionSlideContainer';

class EmailAndServicesTile extends ObservingComponent<EmailAndServicesTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    public render({ tile }: EmailAndServicesTileProps): JSX.Element {

        return (
            tile.isVisible && <EventInfoTile
                color="maroon"
                tile={tile}
                icon="ti-email-and-services"
                editModeButtons={[
                    <Button
                        stopPropagation={true}
                        button={tile.exitEditModeButton}
                        className="cancel-button v-center"  />,
                    <Button
                        stopPropagation={true}
                        button={tile.sendEmailsButton}
                        className="update-button v-center"  />
                ]}>

                {tile.isLoading ?
                    'Loading...'
                    :
                    (
                        <div>
                            <FulfillmentForm fulfillmentForm={tile.fulfillmentForm} />

                            <Alert
                                className="mt-1"
                                alert={tile.alert} />

                            <div class="flex mt-1">
                                <div class="fill">
                                    <TagCollection tagCollection={tile.listOptionSlideContainer.groupByTagCollection} />
                                    <TagCollection tagCollection={tile.listOptionSlideContainer.filterTagCollection} />
                                </div>

                                <Button
                                    className="ml-auto btn-icon btn-no-inner-margin"
                                    color={ButtonColors.Secondary}
                                    button={tile.listOptionSlideContainer.openButton} />
                            </div>

                            {tile.isEditMode &&
                                <div class="flex text-sm mt-1 ">
                                    <Field
                                        className="fill v-center"
                                        field={tile.checkAllCheckbox} />

                                    <Field
                                        className="v-center input-xs mr-1"
                                        field={tile.emailTemplateDropdown} />

                                    <Field
                                        className="v-center input-xs-long"
                                        field={tile.ccEmailField} />
                                </div>
                            }

                            <GroupContainer
                                className="mt-1"
                                arrayGroups={tile.attendeeGroups}
                                hideHeaderOnNoGroup={true}

                                headingTemplate={(group) => {
                                    if (group.groupHeading) {
                                        return (
                                            <div class="sub-header">
                                                <span>{group.groupHeading} </span>
                                                <span>({group.data.length}) </span>
                                            </div>
                                        );
                                    }
                                }}

                                itemWrapperTemplate={(group, itemTemplate) => (
                                    <ul class="mt-0 mb-0 data-list">
                                        {itemTemplate}
                                    </ul>
                                )}

                                itemTemplate={(attendee, index) => (
                                    <li key={attendee.userId}>

                                        <div class="flex">
                                            <Field
                                                className="v-center mr-1"
                                                field={attendee.checkbox} />

                                                <div class="fill">
                                                    <div class="flex">
                                                        <span class="fill flex">
                                                            <span class="flex">

                                                                <b>
                                                                    <Button
                                                                        className="btn-link"
                                                                        button={tile.buildFulfillButton(attendee)} />
                                                                </b>

                                                                <span>&nbsp;-&nbsp;</span>

                                                                <b>
                                                                    <i class="v-center text-sm">{attendee.businessArea}</i>
                                                                </b>

                                                                <span>&nbsp;/&nbsp;</span>

                                                                <b>
                                                                    <i class="v-center text-sm">{attendee.department}</i>
                                                                </b>
                                                            </span>
                                                        </span>

                                                        {!tile.isEditMode && attendee.contactPhone && tile.shouldShowAttendeeDetails(attendee) &&
                                                            <PhoneButton phoneNumber={attendee.contactPhone} />
                                                        }

                                                        {!tile.isEditMode && !attendee.contactPhone && attendee.usersContactPhone && tile.shouldShowAttendeeDetails(attendee) &&
                                                            <PhoneButton phoneNumber={attendee.usersContactPhone} />
                                                        }

                                                        {!tile.isEditMode && attendee.contactEmail && tile.shouldShowAttendeeDetails(attendee) &&
                                                            <EmailButton emailAddress={attendee.contactEmail} />
                                                        }

                                                        {!tile.isEditMode && !attendee.contactEmail && attendee.usersContactEmail && tile.shouldShowAttendeeDetails(attendee) &&
                                                            <EmailButton emailAddress={attendee.usersContactEmail} />
                                                        }
                                                    </div>

                                                    <div class="text-sm">
                                                        <span>Contact Email:&nbsp;</span>

                                                        {!tile.isEditMode &&
                                                            <b>
                                                                {!!attendee.contactEmail &&
                                                                    <a href={'mailTo:' + attendee.contactEmail}>{attendee.contactEmail}</a>
                                                                }

                                                                {!!attendee.usersContactEmail && !attendee.contactEmail &&
                                                                    <a href={'mailTo:' + attendee.usersContactEmail}>{attendee.usersContactEmail}</a>
                                                                }

                                                                {!attendee.contactEmail && !attendee.usersContactEmail &&
                                                                    <i>Unknown</i>
                                                                }
                                                            </b>
                                                        }

                                                        {tile.isEditMode &&
                                                            <Field
                                                                className="input-xs no-label d-inline-block"
                                                                field={attendee.emailField} />
                                                        }
                                                    </div>

                                                    {tile.shouldShowAttendeeDetails(attendee) &&
                                                        <div class="text-sm">
                                                            <span>Arr/Depart:&nbsp;</span>

                                                            <b>
                                                                {!attendee.arrivalDate && !attendee.departureDate &&
                                                                    <i>Unknown</i>
                                                                }

                                                                {(attendee.arrivalDate || attendee.departureDate) &&
                                                                    <span>
                                                                        {attendee.arrivalDate &&
                                                                            <span>
                                                                                <FormattedDate
                                                                                    date={attendee.arrivalDate}
                                                                                    format={DateFormats.MMMd} />

                                                                                &nbsp;

                                                                                <FormattedTimespan
                                                                                    timespan={attendee.arrivalTime} />


                                                                                &nbsp;

                                                                                {(attendee.arrivalCarrier || attendee.arrivalNumber) &&
                                                                                    <span>({attendee.arrivalCarrier} {attendee.arrivalNumber})</span>
                                                                                }
                                                                            </span>
                                                                        }

                                                                        {!attendee.arrivalDate &&
                                                                            <i>Arrival Unknown</i>
                                                                        }

                                                                        <span> - </span>

                                                                        {attendee.departureDate &&
                                                                            <span>
                                                                                <FormattedDate
                                                                                    date={attendee.departureDate}
                                                                                    format={DateFormats.MMMd} />

                                                                                &nbsp;

                                                                                <FormattedTimespan
                                                                                    timespan={attendee.departureTime} />


                                                                                &nbsp;

                                                                                {(attendee.departureCarrier || attendee.departureNumber) &&
                                                                                    <span>({attendee.departureCarrier} {attendee.departureNumber})</span>
                                                                                }
                                                                            </span>
                                                                        }

                                                                        {!attendee.departureDate &&
                                                                            <i>Departure Unknown</i>
                                                                        }
                                                                    </span>
                                                                }
                                                            </b>
                                                        </div>
                                                    }

                                                    {tile.shouldShowAttendeeDetails(attendee) &&
                                                        <div class="text-sm flex">
                                                            <span>Hotel Status:&nbsp;</span>

                                                            <b>
                                                                <LodgingStatus
                                                                    className="d-inline"
                                                                    status={attendee.lodgingStatus} />
                                                            </b>

                                                            <span>&nbsp;|&nbsp;</span>

                                                            <span>
                                                                &nbsp;Assigned Hotel:&nbsp;

                                                                {attendee.assignedHotel}

                                                                {!attendee.assignedHotel &&
                                                                    <i>Unassigned</i>
                                                                }
                                                            </span>

                                                            <span>&nbsp;|&nbsp;</span>

                                                            <span>
                                                                &nbsp;Reward #:&nbsp;

                                                                {attendee.hotelRewardNumber}

                                                                {!attendee.hotelRewardNumber &&
                                                                    <i>Unknown</i>
                                                                }
                                                            </span>

                                                            <span>&nbsp;|&nbsp;</span>

                                                            <span>Transportation Status:&nbsp;</span>

                                                            <b>
                                                                <DailyTransportStatus status={attendee.dailyTransportStatus} />
                                                            </b>
                                                        </div>
                                                    }
                                                </div>

                                            </div>

                                            {tile.isEditMode &&
                                                <div class="pt-p5"></div>
                                            }
                                        </li>
                                    )}>
                            </GroupContainer>

                            {tile.noResults &&
                                <EmptyState message="No matching records found" />
                            }

                            <ListOptionSlideContainer
                                headerText = "Filter Event Attendee"
                                listOptionSlideContainer = { tile.listOptionSlideContainer } />

                        </div>
                    )
                }

            </EventInfoTile>
        );
    }
}

export default EmailAndServicesTile;
