import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { DeviceRegistrationPage as FrontEndDeviceRegistrationPage } from 'core.frontend'
import ButtonColors from '../buttons/buttonColors';
import Button from '../buttons/button';
import Alert from '../alerts/alert';
import LabeledDivider from '../text/labeledDivider';
import Field from '../fields/field';

export interface DeviceRegistrationPageProps {
    page: FrontEndDeviceRegistrationPage;
}

class DeviceRegistrationPage extends ObservingComponent<DeviceRegistrationPageProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider);
    }

    public render({ page }: DeviceRegistrationPageProps): JSX.Element {

        return (
            <div class="login-page">
                <header class="app-header">
                    <img src="img/logo-icon.png" />
                    <span>TEMS</span>
                </header>
                <main>
                    <div class="card raised mt-1">

                        <div class="card-header text-center">
                            <img src="img/logo-blue.png" class="header-logo-blue" />
                            <LabeledDivider text="Register New Device" />
                        </div>

                        <div class="card-main">
                            <Alert alert={page.alert} />

                            {page.isLoading &&
                                <div>
                                    <div className="width-100 h-3 loading-placeholder shimmer"></div>
                                    <div className="width-100 h-3 loading-placeholder shimmer"></div>

                                    <div className="text-right">
                                        <div className="h-4 w-2 loading-placeholder shimmer"></div>
                                    </div>

                                    <div className="mt-1 width-100 h-3 loading-placeholder shimmer"></div>
                                    <div className="width-100 h-3 loading-placeholder shimmer"></div>

                                    <div className="text-right">
                                        <div className="h-4 w-2 loading-placeholder shimmer"></div>
                                    </div>
                                </div>
                            }

                            {!page.isLoading && !page.verificationCodeSent && page.deviceRegistrationData &&
                                <div>
                                    We see that you are attempting to login from an unregistered device. To ensure we keep your account secure use one of the following options to verify your identity and register this device.

                                    <hr class="mt-1"/>

                                    <div class="mt-1">
                                        <div>
                                            Send an email to {page.deviceRegistrationData.email} that contains a verification code.
                                        </div>

                                        <div class="text-left mt-p5">
                                            <Button
                                                id="deviceRegistrationSendEmailButton"
                                                className="btn-sm"
                                                color={ButtonColors.Primary}
                                                button={page.sendEmailButton} />
                                        </div>
                                    </div>

                                    <hr class="mt-1"/>

                                    <div class="mt-1">
                                        <div>
                                            Send a text message to {page.deviceRegistrationData.maskedPhoneNumber} that contains a verification code.
                                        </div>

                                        <div class="text-left mt-p5">
                                            <Button
                                                id="deviceRegistrationSendTextButton"
                                                className="btn-sm"
                                                color={ButtonColors.Primary}
                                                button={page.sendSmsButton} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {!page.isLoading && page.verificationCodeSent &&
                                <div>
                                    <form
                                        id="deviceRegistrationForm"
                                        noValidate
                                        autocomplete="off">

                                        <p>Check your device or email for a verification code. This code is valid for 15 minutes. Once you receive your code, enter it below to register this device.</p>

                                        <Field
                                            id="deviceRegistrationVerificationCodeField"
                                            field={page.submitVerificationCodeForm.fields[0]}
                                            className="input-lg floating-label mt-1 mb-2" />

                                        <Button
                                            id="deviceRegistrationSubmitCodeButton"
                                            color={ButtonColors.Primary}
                                            className="btn-full-width"
                                            button={page.submitVerivicationCodeButton} />
                                    </form>
                                </div>
                            }
                        </div>

                        <div class="card-footer">
                            <LabeledDivider text="Having trouble? Go back to try again." />

                            <Button
                                id="deviceRegistrationBackButton"
                                color={ButtonColors.Primary}
                                button={page.backButton}
                                className="btn btn-full-width mt-3"/>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default DeviceRegistrationPage;
