"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../");
var button_1 = require("../button/button");
var Dropdown = /** @class */ (function () {
    function Dropdown(initialDropdownItems, staticDropdownButtonText, emptyButtonText) {
        var _this = this;
        if (emptyButtonText === void 0) { emptyButtonText = 'Loading...'; }
        this.staticDropdownButtonText = staticDropdownButtonText;
        this.emptyButtonText = emptyButtonText;
        this.initializeNewDropdownItems = function (selectedIndex) {
            if (selectedIndex === void 0) { selectedIndex = 0; }
            if (_this.dropdownItems.length && selectedIndex >= 0) {
                _this._selectedItem = _this.dropdownItems[selectedIndex];
                if (!_this.staticDropdownButtonText) {
                    _this.dropdownButton.setText(_this._selectedItem.button.text);
                    _this.dropdownButton.setSubText(_this._selectedItem.button.subText);
                }
                _this.dropdownButton.setDisabled(false);
            }
            else {
                _this.dropdownButton.setText(_this.emptyButtonText);
                _this.dropdownButton.setDisabled(true);
            }
        };
        this.open = function () {
            _this._isOpen = true;
            _this.observationProvider.notifyObservers(__1.DropdownActions.Opened);
        };
        this.close = function () {
            _this._isOpen = false;
            _this.observationProvider.notifyObservers(__1.DropdownActions.Closed);
        };
        this.toggle = function () {
            if (_this._isOpen) {
                _this.close();
            }
            else {
                _this.open();
            }
        };
        this.setVisibility = function (isVisible) {
            _this.dropdownButton.setVisibility(isVisible);
            _this.observationProvider.notifyObservers(__1.DropdownActions.VisibilityChanged);
        };
        this.setItems = function (items, selectedIndex) {
            if (selectedIndex === void 0) { selectedIndex = 0; }
            _this._dropdownItems = items;
            _this.initializeNewDropdownItems(selectedIndex);
            _this.observationProvider.notifyObservers(__1.DropdownActions.ItemsSet);
        };
        this.selectItem = function (item) { return function () {
            _this._selectedItem = item;
            if (!_this.staticDropdownButtonText) {
                _this.dropdownButton.setText(item.button.text);
            }
            if (item.button) {
                item.button.click();
            }
            _this.observationProvider.notifyObservers(__1.DropdownActions.SelectedChanged);
        }; };
        this.buildItemButton = function (item) {
            if (!item.button || !item.button.isVisible) {
                return;
            }
            return new button_1.default({
                leftIcon: item.button.leftIcon,
                rightIcon: item.button.rightIcon,
                text: item.button.text,
                subText: item.button.subText,
                buttonType: item.button.buttonType,
                isActiveState: item.button.isActiveState,
                isDisabled: item.button.isDisabled,
                isLoading: item.button.isLoading,
                processingText: item.button.processingText,
                onClick: _this.selectItem(item)
            });
        };
        this._dropdownItems = initialDropdownItems;
        this.dropdownButton = new button_1.default({
            onClick: this.toggle,
            text: staticDropdownButtonText,
            rightIcon: __1.Icons.ChevronDown
        });
        this.initializeNewDropdownItems();
        this.observationProvider = new __1.ObservationProvider();
    }
    Object.defineProperty(Dropdown.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dropdown.prototype, "selectedItem", {
        get: function () {
            return this._selectedItem;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dropdown.prototype, "dropdownItems", {
        get: function () {
            return this._dropdownItems;
        },
        enumerable: true,
        configurable: true
    });
    return Dropdown;
}());
exports.default = Dropdown;
