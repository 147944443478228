import { FieldActions, ActionUtils } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';
import RichTextFieldProps from './richTextFieldProps';
import FieldLoadingPlaceholder from './fieldLoadingPlaceholder';

class RichTextField extends ObservingComponent<RichTextFieldProps> {

    constructor(props: RichTextFieldProps) {
        super(props);

        props.field.observationProvider.observe(this.setInnerHtmlValue, FieldActions.ValueChanged);
    }

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public componentDidMount(): void {
        if (this.props.field.value) {
            this.setInnerHtmlValue();
        }
    }

    public componentDidUpdate(): void {
        if (this.props.field.value) {
            this.setInnerHtmlValue();
        }
    }

    private setInnerHtmlValue = () => {
        const div = document.getElementById(this.props.field.uniqueKey);

        if (div) {
            div.innerHTML = this.props.field.value;
        }
    }

    private handleBlur = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.innerHTML;

        field.setValue(newValue);
        field.blur();
    }

    public render({ field, labelClassName, isLoading, className = '', leftIcon = '' }: RichTextFieldProps): JSX.Element {

        labelClassName += field.isActive ? ' active' : '';
        labelClassName += field.errorMessage ? ' invalid' : '';

        if (!field.isVisible) {
            return null;
        }

        if (isLoading) {
            return <FieldLoadingPlaceholder className={className} />;
        }

        return (
            <div className={`input-field ${className}`}>

                { leftIcon &&
                    <FieldIcon
                        icon={leftIcon}
                        invalid={!!field.errorMessage} />
                }

                <label
                    className={labelClassName}
                    for={field.uniqueKey}>

                    <span class="fill">{field.label}</span>

                    {field.labelButtonText && <a onClick={field.handleLabelButtonClick}>{field.labelButtonText}</a>}

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>


                <div
                    id={field.uniqueKey}
                    name={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={this.handleBlur}
                    className={(field.errorMessage ? 'invalid' : '') + ' rich-text-editor'}
                    disabled={field.isDisabled}
                    contentEditable={!field.isDisabled}>
                </div>

                { field.isActive && !field.stringValue && field.placeholderLabel &&
                    <label class="placeholder-label">{ field.placeholderLabel }</label>
                }
            </div>
        );
    }
}

export default RichTextField;
