"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TimezoneUtils = /** @class */ (function () {
    function TimezoneUtils() {
    }
    TimezoneUtils.format = function (timezoneValue) {
        switch (timezoneValue) {
            case '-11:00': {
                return 'GMT -11:00';
            }
            case '-10:00': {
                return 'GMT -10:00';
            }
            case '-09:30': {
                return 'GMT -09:30';
            }
            case '-08:00': {
                return 'GMT -08:00';
            }
            case '-07:00': {
                return 'GMT -07:00';
            }
            case '-06:00': {
                return 'GMT -06:00';
            }
            case '-05:00': {
                return 'GMT -05:00';
            }
            case '-04:00': {
                return 'GMT -04:00';
            }
            case '-03:30': {
                return 'GMT -03:30';
            }
            case '-03:00': {
                return 'GMT -03:00';
            }
            case '-02:00': {
                return 'GMT -02:00';
            }
            case '-01:00': {
                return 'GMT -01:00';
            }
            case '+00:00': {
                return 'GMT +00:00';
            }
            case '+01:00': {
                return 'GMT +01:00';
            }
            case '+02:00': {
                return 'GMT +02:00';
            }
            case '+03:00': {
                return 'GMT +03:00';
            }
            case '+03:30': {
                return 'GMT +03:30';
            }
            case '+04:00': {
                return 'GMT +04:00';
            }
            case '+04:30': {
                return 'GMT +04:30';
            }
            case '+05:00': {
                return 'GMT +05:00';
            }
            case '+05:30': {
                return 'GMT +05:30';
            }
            case '+05:45': {
                return 'GMT +05:45';
            }
            case '+06:00': {
                return 'GMT +06:00';
            }
            case '+06:30': {
                return 'GMT +06:30';
            }
            case '+07:00': {
                return 'GMT +07:00';
            }
            case '+08:00': {
                return 'GMT +08:00';
            }
            case '+08:45': {
                return 'GMT +08:45';
            }
            case '+09:00': {
                return 'GMT +09:00';
            }
            case '+09:30': {
                return 'GMT +09:30';
            }
            case '+10:00': {
                return 'GMT +10:00';
            }
            case '+10:30': {
                return 'GMT +10:30';
            }
            case '+11:00': {
                return 'GMT +11:00';
            }
            case '+12:00': {
                return 'GMT +12:00';
            }
            case '+13:00': {
                return 'GMT +13:00';
            }
            case '+13:45': {
                return 'GMT +13:45';
            }
            case '+14:00': {
                return 'GMT +14:00';
            }
        }
        return '';
    };
    return TimezoneUtils;
}());
exports.default = TimezoneUtils;
