import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import LoginPageWrapper from './loginPageWrapper';
import RequestAccessPageProps from './requestAccessPageProps';
import RequestAccessForm from './requestAccessForm';

export class RequestAccessPage extends ObservingComponent<RequestAccessPageProps> {

    public render({ page }: RequestAccessPageProps): JSX.Element {
        return (
            <LoginPageWrapper>
                <RequestAccessForm page={page} />
            </LoginPageWrapper>
        );
    }
}

export default RequestAccessPage;
