import { h, JSX } from 'preact';
import { FieldActions, ActionUtils } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import FileInputFieldProps from './fileInputFieldProps';
import Icon from '../icons/icon';

class FileInputField extends ObservingComponent<FileInputFieldProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: Event) => {
        const { field } = this.props;

        let target = e.target as HTMLInputElement;
        let files = target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e: any) => {
            field.setValue(e.target.result);
        }
    }

    private addFileButtonClicked = () => {
        document.getElementById(this.props.field.uniqueKey).click();
    }

    public render({ field, leftIcon, buttonClassName = '', className = '' }: FileInputFieldProps): JSX.Element {

        return (
            <div class={className}>

                {field.isVisible &&
                    <a
                        class={`btn button inline-flex v-center mr-1 btn-white btn-icon ${buttonClassName}`}
                        onClick={this.addFileButtonClicked}
                        disabled={field.isDisabled}>

                        <span class="btn-content">
                            {leftIcon && <Icon icon={leftIcon} />}

                            {!!field.label &&
                                <span className="fill v-center ">
                                    {field.label}
                                </span>
                            }
                        </span>
                    </a>
                }

                <input
                    type="file"
                    id={field.uniqueKey}
                    name={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    value={field.stringValue}
                    onChange={this.valueChanged}
                    className={field.isInvalid ? 'invalid' : ''}
                    disabled={field.isDisabled}
                    accept={field.acceptedFileTypes}
                    style="display:none" />

            </div>
        );
    }
}

export default FileInputField;
