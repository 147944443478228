"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TenantDetailPageActions;
(function (TenantDetailPageActions) {
    TenantDetailPageActions["DataLoading"] = "DataLoading";
    TenantDetailPageActions["DataLoaded"] = "DataLoaded";
    TenantDetailPageActions["Saving"] = "Saving";
    TenantDetailPageActions["HeaderImageSet"] = "HeaderImageSet";
})(TenantDetailPageActions || (TenantDetailPageActions = {}));
exports.default = TenantDetailPageActions;
