import { HelpButton } from 'core.frontend';
import { h, JSX } from 'preact';
import VideoModalButton from '../buttons/videoModalButton';
import ObservingComponent from '../componentBases/observingComponent';

interface LoginPageWrapperProps {
    children?: JSX.Element | string;
    helpButton?: HelpButton;
    isApp?: boolean
}

class LoginPageWrapper extends ObservingComponent<LoginPageWrapperProps> {

    public render({ children, helpButton, isApp }: LoginPageWrapperProps): JSX.Element {

        return (
            <div class="login-page">
                <header class="app-header">
                    <img src="img/logo-icon.png" />
                    <span>TEMS</span>

                    <a
                        class="btn-icon btn-white"
                        href="http://help.totaleventmanager.com/"
                        target="_blank">

                        <i class="ti ti-question"></i>
                        <span class="d-none d-md-inline"> Help</span>
                    </a>
                </header>
                <main>
                    <div class="card raised mt-1">
                        {children}
                    </div>
                </main>
            </div>
        );
    }
}

export default LoginPageWrapper;
