"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CostManagementTileActions;
(function (CostManagementTileActions) {
    CostManagementTileActions["Toggled"] = "Toggled";
    CostManagementTileActions["DataLoading"] = "DataLoading";
    CostManagementTileActions["DataLoaded"] = "DataLoaded";
    CostManagementTileActions["EditModeActivated"] = "EditModeEnabled";
    CostManagementTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    CostManagementTileActions["ItemRemoved"] = "ItemRemoved";
    CostManagementTileActions["ItemRemoveCancelled"] = "ItemRemoveCancelled";
    CostManagementTileActions["ItemRemoveError"] = "ItemRemoveError";
    CostManagementTileActions["ItemAdded"] = "ItemAdded";
    CostManagementTileActions["ItemTypeChanged"] = "ItemTypeChanged";
    CostManagementTileActions["InventoryItemLoading"] = "InventoryItemLoading";
    CostManagementTileActions["InventoryItemLoaded"] = "InventoryItemLoaded";
    CostManagementTileActions["ServiceItemRemoved"] = "ServiceItemRemoved";
    CostManagementTileActions["ServiceItemAdded"] = "ServiceItemAdded";
    CostManagementTileActions["CurrentPaneChanged"] = "CurrentPaneChanged";
    CostManagementTileActions["ShowPointOverrideModal"] = "ShowPointOverrideModal";
    CostManagementTileActions["HidePointOverrideModal"] = "HidePointOverrideModal";
    CostManagementTileActions["DeleteConfirmationPromptShown"] = "DeleteConfirmationPromptShown";
    CostManagementTileActions["DeleteConfirmationPromptHidden"] = "DeleteConfirmationPromptHidden";
    CostManagementTileActions["ServiceCostValuesChanged"] = "ServiceCostValuesChanged";
    CostManagementTileActions["ShowWeightChangeModal"] = "ShowWeightChangeModal";
    CostManagementTileActions["HideWeightChangeModal"] = "HideWeightChangeModal";
    CostManagementTileActions["ShowAreaSquareFeetChangeModal"] = "ShowAreaSquareFeetChangeModal";
    CostManagementTileActions["HideAreaSquareFeetChangeModal"] = "HideAreaSquareFeetChangeModal";
    CostManagementTileActions["ServiceChanged"] = "ServiceChanged";
})(CostManagementTileActions || (CostManagementTileActions = {}));
exports.default = CostManagementTileActions;
