"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MeetingDetailPageActions;
(function (MeetingDetailPageActions) {
    MeetingDetailPageActions["RecordDeleting"] = "RecordDeleting";
    MeetingDetailPageActions["RecordDeleted"] = "RecordDeleted";
    MeetingDetailPageActions["RecordLoading"] = "RecordLoading";
    MeetingDetailPageActions["RecordLoaded"] = "RecordLoaded";
    MeetingDetailPageActions["RecordLoadFailure"] = "RecordLoadFailure";
    MeetingDetailPageActions["Cancel"] = "Cancel";
})(MeetingDetailPageActions || (MeetingDetailPageActions = {}));
exports.default = MeetingDetailPageActions;
