import { TableActions, ListOptionSlideContainerActions } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import TableHeaderProps from './tableHeaderProps';
import ListOptionSlideContainer from '../events/rightSlider/listOptionSlideContainer';
import ButtonColors from '../buttons/buttonColors';
import Button from '../buttons/button';
import TagCollection from '../tags/tagCollection';

class TableHeader extends ObservingComponent<TableHeaderProps> {

    public componentWillMount(): void {
        const { table, listOptionSlideContainer } = this.props;

        this.registerUpdateObserver(table.observationProvider, [TableActions.RecordsPerPageChanged, TableActions.SearchPhraseChanged]);

        if (listOptionSlideContainer) {
            this.registerUpdateObserver(listOptionSlideContainer.observationProvider, [ListOptionSlideContainerActions.OptionsApplied]);
        }
    }

    private searchBoxKeyPress = (e: KeyboardEvent) => {
        if (e.keyCode == 13) {
            e.preventDefault();
        }
    }

    private recordsPerPageChanged = (e: any) => {
        const { table } = this.props;

        const recordsPerPage = parseInt(e.target.value, 10);

        table.setRecordsPerPage(recordsPerPage);
    }

    private searchChanged = (e: any) => {
        const { table } = this.props;

        const searchPhrase = e.target.value;

        table.search(searchPhrase);
    }

    public render({ table, listOptionSlideContainer, id, showSearchClearButton }: TableHeaderProps): JSX.Element {
        if (!id) {
            id = '';
        }

        id += '_tableHeader';

        return (
            <div>
                <div className="table-header">
                    { table.pagingEnabled &&
                        <div className="records-per-page-wrapper input-field">
                            <span>Show</span>

                            <select
                                onChange={this.recordsPerPageChanged}
                                value={table.recordsPerPage}
                                disabled={table.isLocked}>

                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                            </select>

                            <span>Entries</span>
                        </div>
                    }

                    {table.searchEnabled &&
                        <div className="filter-group input-field ml-auto">
                            <label>Search</label>

                            <div class="flex fill">

                                <input
                                    id={id + '_searchField'}
                                    class="fill v-center"
                                    onInput={this.searchChanged}
                                    value={table.searchPhrase}
                                    onKeyPress={this.searchBoxKeyPress}
                                    placeholder={table.searchBoxPlaceholder}
                                    disabled={table.isLocked} />

                                {showSearchClearButton &&
                                    <Button
                                        className="btn-icon v-center ml-p5"
                                        color={ButtonColors.Secondary}
                                        button={table.clearSearchButton} />
                                }

                                {listOptionSlideContainer &&
                                    <span class="v-center ml-p5">
                                        <Button
                                            className="btn-icon btn-no-inner-margin"
                                            color={ButtonColors.Secondary}
                                            button={listOptionSlideContainer.openButton} />
                                    </span>
                                }
                            </div>
                        </div>
                    }
                </div>

                {listOptionSlideContainer && listOptionSlideContainer.filterTagCollection &&
                    <TagCollection tagCollection={listOptionSlideContainer.filterTagCollection} />
                }

                {listOptionSlideContainer &&
                     <ListOptionSlideContainer listOptionSlideContainer={listOptionSlideContainer} />
                }
            </div>
        );
    }
}

export default TableHeader;
