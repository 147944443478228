import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { App, AppActions } from 'core.frontend';
import Button from '../buttons/button';
import FormattedTimespan from '../text/formattedTimespan';
import EmptyState from '../uiStates/emptyState';

export interface NotificationDropdownProps {
    app: App;
}

class NotificationDropdown extends ObservingComponent<NotificationDropdownProps> {

    public componentWillMount() {
        const { app } = this.props;

        this.registerUpdateObserver(app.observationProvider, [AppActions.NotificationsDropdownOpened, AppActions.NotificationsDropdownClosed, AppActions.NotificationsLoaded]);
        window.addEventListener('click', app.closeNotificationDropdown);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.props.app.closeNotificationDropdown);
    }

    public notificationClicked = (notification, index) => (e: Event) => {
        this.props.app.openNotification(notification, index);
    }

    private notificationDropdownScrolled = (e: Event) => {
        const { app } = this.props;

        if (app.notificationsLoading) {
            return;
        }

        const target = e.target as HTMLElement;

        if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
            app.loadNextNotificationPage();
        }
    }

    public render({ app }: NotificationDropdownProps): JSX.Element {

        return (
            <div class={"notifications-dropdown dropdown " + (app.notificationDropdownOpen ? ' open' : '')}>
                <div class="dropdown-header">
                    <b class="v-center">Notifications</b>

                    <Button
                        className="btn-link v-center"
                        stopPropagation={true}
                        button={app.markAllNotificationsAsReadButton} />
                </div>
                <ul
                    onScroll={this.notificationDropdownScrolled}
                    class="notifications-list">

                    {!app.notificationsLoading && app.notifications.map((notification, index) => (
                        <li
                            // onClick={this.notificationClicked(notification, index)}
                            class={(!notification.isRead ? 'unread' : '')}
                            key={notification.id}>

                            <div className="flex">
                                <div className="fill">
                                    <div class="title">
                                        <span>
                                            {notification.title}
                                        </span>

                                        {!notification.isRead &&
                                            <span class="red-badge">NEW</span>
                                        }
                                    </div>
                                    <div class="message">{notification.message}</div>
                                </div>

                                {!notification.isRead &&
                                    <div className="notification-list-read-button">

                                        <Button
                                            className="text-gray text-sm"
                                            stopPropagation={true}
                                            button={app.buildNotificationIsReadButton(notification, index)} />
                                    </div>
                                }

                            </div>
                        </li>
                    ))}

                    {!app.notificationsLoading && app.notifications && app.notifications.length === 0 &&
                        <EmptyState message="You don't have any notifcations yet"></EmptyState>
                    }

                    {app.notificationsLoading && [...Array(5)].map(() => (
                        <li>
                            <div class="title loading-placeholder shimmer"></div>
                            <div class="message loading-placeholder shimmer"></div>
                        </li>
                    ))}

                </ul>

                {!app.notificationsLoading &&
                    <div class="dropdown-footer">
                        <span>
                            Loaded at:
                            &nbsp;
                        </span>

                        <FormattedTimespan
                            timespan={app.notificationsLoadedTime} />
                    </div>
                }

                {app.notificationsLoading &&
                    <div class="dropdown-footer">
                        Loading...
                    </div>
                }
            </div>
        );
    }
}

export default NotificationDropdown;
