import { h, JSX } from 'preact';
import ObservingComponent from '../../../componentBases/observingComponent';
import HotelReportPageProps from '../hotelReport/hotelReportPageProps';
import { DateFormats, LodgingStatus as FrontEndLodgingStatus} from 'core.frontend';
import FormattedDate from '../../../text/formattedDate';
import ArrivalDepartureType from '../../arrivalDepartureType';
import LodgingStatus from '../../lodgingStatus';
import Avatar from '../../../avatar/avatar';
import Button from '../../../buttons/button';

class HotelReportPage extends ObservingComponent<HotelReportPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: HotelReportPageProps): JSX.Element {

        return (
            <div class="report">

                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                <h3 class="text-center pl-1 pr-1 mt-1">
                    {!page.isLoading &&
                        <span>{page.reportData.eventName}</span>
                    }
                </h3>

                <div class="text-sm flex mb-1">
                    <div class="fill">
                        Printed by: {page.currentUsersName}
                        <div><b>{page.title}</b></div>
                    </div>
                    <div class="text-right">

                        <FormattedDate
                            date={new Date()}
                            format={DateFormats.dddMMMMDhmmA} />

                        {!page.isLoading &&
                            <div>
                                <span>Total Attendees: </span>
                                {page.reportData.reportDataItems.length}
                            </div>
                        }
                    </div>
                </div>

                {!page.isLoading &&
                    <div class="report-page text-sm">
                        {page.reportData.reportDataItems.map((attendee, index) =>
                            <div>
                                {page.showStatusHeader(index) &&
                                    <div class="text-md flex ml-p5">
                                        <span>HOTEL REPORT | </span>

                                        <b class="blue">
                                            <LodgingStatus
                                                className="text-blue ml-p5"
                                                status={attendee.lodgingStatus} />
                                        </b>
                                    </div>
                                }

                                <div class="flex mb-1 hotel-report-item">
                                    {page.reportOptions.includePhotos &&
                                        <Avatar
                                            className="mb-auto mr-1"
                                            url={attendee.profileImageUrl} />
                                    }

                                    <div class="fill v-center">
                                        <div class="report-item-header">
                                            <b>{attendee.fullName}</b> - {attendee.businessAreaName} / {attendee.departmentName}
                                            <span> | </span>

                                            {!!attendee.contactPhone && attendee.contactPhone}
                                            {!attendee.contactPhone && attendee.defaultContactPhone}
                                            <span> | </span>
                                            {!!attendee.contactEmail && attendee.contactEmail}
                                            {!attendee.contactEmail && attendee.defaultContactEmail}
                                        </div>

                                        <div class="flex hotel-report-item-sub-section">
                                            <div class="fill">
                                                <b>ARRIVAL: </b>

                                                {attendee.arrivalInfoSubmitted &&
                                                    <div class="ml-1">
                                                        <div>
                                                            <span>Date: </span>

                                                            <b>
                                                                <FormattedDate
                                                                    date={attendee.arrivalDateTime}
                                                                    format={DateFormats.MMMdYYYY} />
                                                            </b>

                                                            <span> - </span>

                                                            <b>
                                                                <FormattedDate
                                                                    date={attendee.arrivalDateTime}
                                                                    format={DateFormats.hhmm} />
                                                            </b>
                                                        </div>

                                                        <div>
                                                            <span>Type: </span>
                                                            <b>
                                                                <ArrivalDepartureType type={attendee.arrivalType} />
                                                            </b>
                                                        </div>

                                                        <div>
                                                            <b>{attendee.arrivalCarrier} #{attendee.arrivalNumber} | {attendee.arrivalOrigin} {attendee.arrivalDestination}</b>
                                                        </div>

                                                        <div>Comments: <b>{attendee.arrivalComments}</b></div>
                                                    </div>
                                                }

                                                {!attendee.arrivalInfoSubmitted && <i>Unknown</i> }
                                            </div>

                                            <div class="fill">
                                                <b>DEPARTURE: </b>

                                                {attendee.departureInfoSubmitted &&
                                                    <div class="ml-1">
                                                        <div>
                                                            <span>Date: </span>

                                                            <b>
                                                                <FormattedDate
                                                                    date={attendee.departureDateTime}
                                                                    format={DateFormats.MMMdYYYY} />
                                                            </b>

                                                            <span> - </span>

                                                            <b>
                                                                <FormattedDate
                                                                    date={attendee.departureDateTime}
                                                                    format={DateFormats.hhmm} />
                                                            </b>
                                                        </div>

                                                        <div>
                                                            <span>Type: </span>

                                                            <b>
                                                                <ArrivalDepartureType type={attendee.departureType} />
                                                            </b>
                                                        </div>

                                                        <div>
                                                            <b>{attendee.departureCarrier} #{attendee.departureNumber} | {attendee.departureOrigin} {attendee.departureDestination}</b>
                                                        </div>

                                                        <div>
                                                            Comments:
                                                            <b> {attendee.departureComments}</b>
                                                        </div>
                                                    </div>
                                                }

                                                {!attendee.departureInfoSubmitted && <i>Unknown</i>}
                                            </div>
                                        </div>

                                        <div class="flex hotel-report-item-sub-section">

                                            {!!attendee.lodgingStatus &&
                                                <div class="fill">
                                                    <div class="flex">
                                                        <b class="fill">HOTEL REQUESTED INFO</b>

                                                        {attendee.hotelBookingType === 0 && <b class="text-red">OWN</b>}
                                                        {attendee.hotelBookingType === 1 && <b class="text-red">COMPANY PROVIDED</b>}
                                                    </div>

                                                    <div class="ml-1">
                                                        Date:

                                                        <b>
                                                            <FormattedDate
                                                                date={attendee.hotelCheckInDate}
                                                                format={DateFormats.MMMdYYYY} />
                                                        </b>

                                                        <span> - </span>

                                                        <b>
                                                            <FormattedDate
                                                                date={attendee.hotelCheckOutDate}
                                                                format={DateFormats.MMMdYYYY} />
                                                        </b>
                                                    </div>

                                                    <div class="ml-1">
                                                        Early Arrival:
                                                        {attendee.hotelEarlyArrivalRequired && <b class="text-green"> YES </b>}
                                                        {!attendee.hotelEarlyArrivalRequired && <b class="text-red"> NO </b>}
                                                        <span> | </span>

                                                        Late Checkout:
                                                        {attendee.hotelLateCheckoutRequired && <b class="text-green"> YES </b>}
                                                        {!attendee.hotelLateCheckoutRequired && <b class="text-red"> NO </b>}
                                                        <span> | </span>

                                                        #in Room:
                                                        <b> {attendee.hotelTotalInRoom}</b>
                                                        <span> | </span>

                                                        Rewards #:

                                                        <b> Unassigned</b>
                                                        <span> | </span>

                                                        Confirmation #:

                                                        {!!attendee.lodgingConfirmationNumber &&
                                                            <b> {attendee.lodgingConfirmationNumber}</b>
                                                        }

                                                        {!attendee.lodgingConfirmationNumber &&
                                                            <b> Unassigned</b>
                                                        }
                                                    </div>

                                                    <div class="ml-1">
                                                        Hotel:

                                                        {!!attendee.hotelName &&
                                                            <b>{attendee.hotelName}</b>
                                                        }

                                                        {!attendee.hotelName &&
                                                            <b> Unassigned</b>
                                                        }

                                                        <span> | </span>

                                                        Room Type:

                                                        {!!attendee.hotelRoomType &&
                                                            <b>{attendee.hotelRoomType}</b>
                                                        }

                                                        {!attendee.hotelRoomType &&
                                                            <b> Unassigned</b>
                                                        }

                                                    </div>

                                                    <div class="ml-1">
                                                        Hotel Comments: <b>{attendee.hotelComments}</b>
                                                    </div>

                                                    <div class="ml-1">
                                                        Hotel Vendor Comments: <b>{attendee.hotelVendorComments}</b>
                                                    </div>

                                                    <div class="ml-1">
                                                        Trans Comments: <b>{attendee.airportTransportComments}</b>
                                                    </div>

                                                    <div class="ml-1">
                                                        Trans Vendor Comments: <b>{attendee.transportationVendorComments}</b>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default HotelReportPage;
