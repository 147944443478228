"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var PhoneField = /** @class */ (function (_super) {
    __extends(PhoneField, _super);
    function PhoneField(key, label, isRequired, requiredValidationMessage, initialValue) {
        var _this = _super.call(this, key, label, isRequired, 14, requiredValidationMessage, initialValue, _1.FieldType.Phone) || this;
        _this.key = key;
        _this._invalidMessage = 'Phone is invalid (xxx) xxx-xxxx';
        _this.setPlaceholderLabel('(xxx) xxx-xxxx');
        return _this;
    }
    Object.defineProperty(PhoneField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isPhoneValid() && this.isDirty) {
                return this._invalidMessage;
            }
            return baseErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    PhoneField.prototype.isPhoneValid = function () {
        var regex = /^\([1-9][0-9][0-9]\) [1-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$/;
        return regex.test(this.value.toString());
    };
    PhoneField.prototype.setValue = function (newValue) {
        if (newValue) {
            this.setStringValue(this.formatFromPartial(newValue));
        }
        else {
            this.setStringValue(null);
        }
    };
    PhoneField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isPhoneValid()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    PhoneField.prototype.formatFromPartial = function (value) {
        var formattedStr = '';
        for (var i = 0; i < value.length; i++) {
            var char = value.charAt(i);
            if ('() -0123456789'.indexOf(char) !== -1) {
                if (formattedStr.length === 0) {
                    formattedStr += '(';
                }
                if (formattedStr.length === 4) {
                    formattedStr += ')';
                }
                if (formattedStr.length === 5) {
                    formattedStr += ' ';
                }
                if (formattedStr.length === 9) {
                    formattedStr += '-';
                }
                if ('1234567890'.indexOf(char) !== -1) {
                    formattedStr += char;
                }
            }
        }
        return formattedStr;
    };
    return PhoneField;
}(_1.TextField));
exports.default = PhoneField;
