import { h, JSX } from 'preact';
import EventInfoTile from './eventInfoTile';
import ExtrasTileProps from './extrasTileProps';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import { VendorEventLandingPageActions } from 'core.frontend';

class ExtrasTile extends ObservingComponent<ExtrasTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider, VendorEventLandingPageActions.DataLoaded);
    }

    public render({ tile }: ExtrasTileProps): JSX.Element {
        return (
            <EventInfoTile
                color="violet"
                tile={tile}
                icon="ti-extras">
                    {tile.isLoading ?
                        'Loading...'
                        : (
                            <div>
                                {tile.meetingsEnabled &&
                                    <ul class="report-tile-list">
                                        <li>
                                            <a
                                                target="_blank"
                                                href={tile.meetingPlacardUrl}>
                                                    Meeting Room Placard
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                href={tile.roomDisplayUrl}>
                                                    Room Schedule Display
                                            </a>
                                        </li>
                                        <li>
                                            <Button
                                                className="btn-link"
                                                button={tile.urlAssignerButton} />
                                        </li>
                                    </ul>
                                }
                            </div>
                        )
                    }
            </EventInfoTile>
        );
    }
}

export default ExtrasTile;
