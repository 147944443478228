import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import RequestPasswordResetForm from './requestPasswordResetForm';
import LoginPageWrapper from './loginPageWrapper';
import RequestPasswordResetPageProps from './requestPasswordResetPageProps';

export class RequestPasswordResetPage extends ObservingComponent<RequestPasswordResetPageProps> {

    public render({ page }: RequestPasswordResetPageProps): JSX.Element {
        return (
            <LoginPageWrapper>
                <RequestPasswordResetForm page={page} />
            </LoginPageWrapper>
        );
    }
}

export default RequestPasswordResetPage;
