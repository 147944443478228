import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import CustomerCompanyDetailPageProps from './customerCompanyDetailPageProps';
import Field from '../fields/field';
import FormButtonContainer from '../containers/formButtonContainer';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import VideoModalButton from '../buttons/videoModalButton';


class CustomerCompanyDetailPage extends ObservingComponent<CustomerCompanyDetailPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: CustomerCompanyDetailPageProps): JSX.Element {

        return (
            <div>
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />
                <Alert alert={page.alert} />

                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <div class="flex">

                    <Button
                        className="btn-icon btn-lg"
                        button={page.helpButton} />
                </div>

                <div class="form-container">
                    {page.form.fields.map((field) =>
                        <Field
                            className="stacked-label"
                            field={field}
                            isLoading={page.isLoading} />
                    )}
                </div>

                <div class="grid-container">
                    <div class="xs-12">
                        <b>Associated Customers</b>
                        <hr/>
                    </div>

                    {page.customers && page.customers.map((customer) =>
                        <div class="md-3">
                            {customer.name}
                        </div>
                    )}

                    {page.customers && !page.customers.length &&
                        <div class="text-center xs-12">
                            <i>There are no customers associated to this company.</i>
                        </div>
                    }
                </div>

                <FormButtonContainer
                    leftButtons={[
                        <Button
                            className="btn-icon"
                            color={ButtonColors.Secondary}
                            button={page.deactivateButton} />
                    ]}
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.cancelButton} />
                        ,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton}/>
                    ]} />
            </div>
        );
    }
}

export default CustomerCompanyDetailPage;
