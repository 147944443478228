import { createRef, h, JSX } from 'preact';
import { DateFormats, DiningTileListItem, Icons, MeetingTableAssignment, TableLayout } from 'core.frontend';
import DiningRoomTileProps from './diningRoomTileProps';
import EventInfoTile from './eventInfoTile';
import Alert from '../alerts/alert';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ObservingComponent from '../componentBases/observingComponent';
import Field from '../fields/field';
import MeetingAttendeesLabel from '../meetings/meetingAttendeesLabel';
import MeetingCreatedByLabel from '../meetings/meetingCreatedByLabel';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import FormattedDate from '../text/formattedDate';
import Modal from '../containers/modal';
import Icon from '../icons/icon';

class DiningRoomTile extends ObservingComponent<DiningRoomTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    private buildColumns = (): SmartTableColumn<DiningTileListItem>[] => {
        const { tile } = this.props;

        return [
            {
                heading: '#',
                cellClassName: 'fit-content text-center cursor-pointer',
                headingClassName: 'fit-content text-center',
                cellTemplate: (item) => (
                    <span>{item.meetingNumber}</span>
                )
            },
            {
                heading: 'Name',
                cellClassName: ' cursor-pointer',
                cellTemplate: (item) => (
                    <div>
                        {item.name}
                        <br/>
                        <div>
                            Attendee Count: <b>{item.totalAttendees}</b>
                            <span> | </span>
                            Food: <b>{item.foodNeeded ? 'Yes' : 'No'}</b>
                        </div>
                    </div>
                )
            },
            {
                heading: 'Date/Time',
                cellClassName: 'fit-content text-center cursor-pointer',
                headingClassName: 'fit-content text-center',
                cellTemplate: (item) => (
                    <div className="text-center">

                        <FormattedDate
                            date={item.startDateTime}
                            format={DateFormats.MMMd} />

                        <br/>
                        <div>

                            <FormattedDate
                                date={item.startDateTime}
                                format={DateFormats.hhmm} />

                            <span> - </span>

                            <FormattedDate
                                date={item.endDateTime}
                                format={DateFormats.hhmm} />
                        </div>
                    </div>
                )
            },
            {
                heading: 'Assigned Table',
                cellClassName: 'fit-content text-center cursor-pointer',
                headingClassName: 'fit-content text-center',
                cellTemplate: (item) => (
                    <span>
                        {item.tables.map((table) =>
                            <span>{table.name}<br/></span>
                        )}
                    </span>
                )
            }
        ];
    }

    private tableClicked = (tableIndex: number) => (e: Event) => {
        const { tile } = this.props;

        e.stopPropagation();

        if (!tile.selectedMeeting || !tile.selectedMeeting.tableAssignments.tables[tableIndex]) {
            return;
        }

        tile.tableClicked(tile.selectedMeeting.tableAssignments.tables[tableIndex]);
    }

    private meetingDetailsTemplate = (item: DiningTileListItem) => {
        if (item.expandedContent) {
            return (
                <div class="d-block text-sm">
                    <div class="d-block">
                        <b>Name: </b>{item.name}
                    </div>

                    <div class="d-block">
                        <b>Venue: </b>{item.expandedContent.assignedVenue}
                    </div>

                    <div class="flex">
                        <MeetingCreatedByLabel meeting={item.expandedContent} />
                    </div>

                    {item.expandedContent.subject &&
                        <div class="d-block">
                            <b>Subject: </b>{item.expandedContent.subject}
                        </div>
                    }

                    <div class="d-block">
                        <b>Meeting Category: </b>{item.expandedContent.defaultMeetingCategory}
                        {item.expandedContent.scheduledBy &&
                            <span> / {item.expandedContent.scheduledBy}</span>
                        }
                    </div>

                    <div class="d-block">
                        <b>Food Needed: </b>{item.expandedContent.foodNeeded ? 'Yes' : 'No' }
                    </div>

                    <div class="d-block">
                        <b>Table(s): </b> {item.expandedContent.tableAssignments.map((table, index) => <span>{table.name + ((index + 1) < item.expandedContent.tableAssignments.length ? ', ' : '')}</span>)}
                    </div>

                    <div class="d-block">
                        <b>Attendees: </b><MeetingAttendeesLabel meeting={item.expandedContent} />
                    </div>

                    <div class="d-block">
                        <b>Customers: </b>

                        <div>
                            {(!item.expandedContent.guests || !item.expandedContent.guests.length) &&
                                <span>None</span>
                            }

                            {!!item.expandedContent.guests && !!item.expandedContent.guests.length &&
                                item.expandedContent.guests.map((guest, index) => (
                                    <div>

                                        {!!guest.rank &&
                                            <span>{guest.rank} </span>
                                        }

                                        {!!guest.title &&
                                            <span> ({guest.title}) </span>
                                        }

                                        {guest.fullName}

                                        <span> - </span>
                                        {guest.companyName}

                                        {!!guest.isLead &&
                                            <span> (L)</span>
                                        }

                                        {index + 1 < item.expandedContent.guests.length &&
                                            <span>&nbsp; | &nbsp;</span>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div class="d-block">
                        <b>Comments: </b>{item.expandedContent.printedComments}
                    </div>
                </div>
            );
        } else {
            return (
                <div class="d-block">
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                </div>
            );
        }
    }

    public render({ tile }: DiningRoomTileProps): JSX.Element {

        return (
            tile.isVisible && <EventInfoTile
                color="cyan"
                tile={tile}
                icon="ti-dining">

                <div>
                    <Alert
                        className="mt-1"
                        alert={tile.alert} />

                    {tile.addMeetingModalIsVisible &&
                        <Modal
                            close={tile.closeNewMeetingModal}
                            heading="Add New Meeting"
                            footerRightButtons={[
                                <Button
                                    color={ButtonColors.Secondary}
                                    button={tile.closeNewMeetingModalButton} />,
                                <Button
                                    color={ButtonColors.Primary}
                                    button={tile.saveNewMeetingButton} />
                            ]}>

                                <div class="form-container">
                                    <div class="grid-container">

                                        <Field
                                            className="sm-12"
                                            field={tile.addMeetingForm.fields[0]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[1]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[2]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[3]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[4]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[5]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[6]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[7]} />

                                        <Field
                                            className="sm-6"
                                            field={tile.addMeetingForm.fields[8]} />

                                        <Field
                                            className="sm-12"
                                            field={tile.addMeetingForm.fields[9]} />
                                    </div>
                                </div>
                        </Modal>
                    }

                    {tile.tableLayoutModalIsVisible &&
                        <Modal
                            close={tile.closeLayoutModal}
                            heading="Dining Tables Layout"
                            footerRightButtons={[
                                <Button
                                    color={ButtonColors.Secondary}
                                    button={tile.cancelLayoutButton} />,
                                <Button
                                    color={ButtonColors.Primary}
                                    button={tile.saveLayoutButton} />
                            ]}>

                            <div style={{height: '100%'}}>

                                <div
                                    style={{
                                        height: '3rem'
                                    }}
                                    class="flex">

                                    <Button
                                        className='ml-auto v-center btn-sm'
                                        stopPropagation={true}
                                        color={ButtonColors.Primary}
                                        button={tile.addTableButton} />
                                </div>

                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: 'calc(100% - 3rem)',
                                        overflow: 'auto'
                                    }}
                                    class="bg-white">

                                    {tile.tableLayoutForms && tile.tableLayoutForms.length == 0 &&

                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            class="flex">

                                            <div class="form-container v-center ml-auto mr-auto text-center">
                                                <div class="flex">
                                                    <Field
                                                        className='fill v-center mr-1'
                                                        field={tile.autoLayoutForm.fields[0]} />

                                                    <Field
                                                        className='fill v-center mr-1'
                                                        field={tile.autoLayoutForm.fields[1]} />

                                                    <Button
                                                        color={ButtonColors.Primary}
                                                        className='v-center'
                                                        stopPropagation={true}
                                                        button={tile.autoLayoutFormSubmitButton} />
                                                </div>
                                            </div>
                                        </div>

                                    }

                                    {tile.tableLayoutForms && tile.tableLayoutForms.map((form, index) => {

                                        let startMoveX = 0;
                                        let startMoveY = 0;
                                        let startResizeX = 0;
                                        let startResizeY = 0;
                                        let startX = 0;
                                        let startY = 0;
                                        let startWidth = 0;
                                        let startHeight = 0;
                                        let parentWidth = 0;
                                        let parentHeight = 0;

                                        let tableDivRef = createRef();

                                        function startMoveDrag(e: MouseEvent) {
                                            startMoveX = e.clientX;
                                            startMoveY = e.clientY;
                                            parentWidth = parseInt(document.defaultView.getComputedStyle(tableDivRef.current.parentElement).width, 10);
                                            parentHeight = parseInt(document.defaultView.getComputedStyle(tableDivRef.current.parentElement).height, 10);
                                            startX = (parseInt(document.defaultView.getComputedStyle(tableDivRef.current).left, 10) / parentWidth) * 100;
                                            startY = (parseInt(document.defaultView.getComputedStyle(tableDivRef.current).top, 10) / parentHeight) * 100;
                                            document.documentElement.addEventListener('mousemove', doMoveDrag, false);
                                            document.documentElement.addEventListener('mouseup', stopMoveDrag, false);
                                        }

                                        function doMoveDrag(e: MouseEvent) {
                                            let newX = startX + ((e.clientX - startMoveX) / parentWidth) * 100;
                                            let newY = startY + ((e.clientY - startMoveY) / parentHeight) * 100;

                                            newX = parseInt(newX.toString(), 10);
                                            newY = parseInt(newY.toString(), 10);

                                            tableDivRef.current.style.left = newX + '%';
                                            tableDivRef.current.style.top = newY + '%';

                                            form.model.layoutX = Math.round(newX / 2) * 2;
                                            form.model.layoutY = Math.round(newY / 2) * 2;
                                        }

                                        function stopMoveDrag() {
                                            document.documentElement.removeEventListener('mousemove', doMoveDrag, false);
                                            document.documentElement.removeEventListener('mouseup', stopMoveDrag, false);
                                        }

                                        function startResizeDrag(e: MouseEvent) {
                                            startResizeX = e.clientX;
                                            startResizeY = e.clientY;
                                            parentWidth = parseInt(document.defaultView.getComputedStyle(tableDivRef.current.parentElement).width, 10);
                                            parentHeight = parseInt(document.defaultView.getComputedStyle(tableDivRef.current.parentElement).height, 10);
                                            startWidth = (parseInt(document.defaultView.getComputedStyle(tableDivRef.current).width, 10) / parentWidth) * 100;
                                            startHeight = (parseInt(document.defaultView.getComputedStyle(tableDivRef.current).height, 10) / parentHeight) * 100;
                                            document.documentElement.addEventListener('mousemove', doResizeDrag, false);
                                            document.documentElement.addEventListener('mouseup', stopResizeDrag, false);
                                        }

                                        function doResizeDrag(e) {
                                            let newWidth = startWidth + ((e.clientX - startResizeX) / parentWidth) * 100;
                                            let newHeight = startHeight + ((e.clientY - startResizeY) / parentHeight) * 100;

                                            newWidth = parseInt(newWidth.toString(), 10);
                                            newHeight = parseInt(newHeight.toString(), 10);

                                            tableDivRef.current.style.width = newWidth + '%';
                                            tableDivRef.current.style.height = newHeight + '%';

                                            form.model.layoutWidth = Math.round(newWidth / 2) * 2;
                                            form.model.layoutHeight = Math.round(newHeight / 2) * 2;
                                        }

                                        function stopResizeDrag() {
                                            document.documentElement.removeEventListener('mousemove', doResizeDrag, false);
                                            document.documentElement.removeEventListener('mouseup', stopResizeDrag, false);
                                        }

                                        return (
                                            <div
                                                ref={tableDivRef}
                                                style={{
                                                    position: 'absolute',
                                                    width: form.model.layoutWidth + '%',
                                                    height: form.model.layoutHeight + '%',
                                                    top: form.model.layoutY + '%',
                                                    left: form.model.layoutX + '%',
                                                }}
                                                className={"p-p5 dinning-table cursor-pointer " + (form.model.isRemoved ? ' deleting ' : '')}>

                                                <div
                                                    onMouseDown={startMoveDrag}
                                                    style={{
                                                        height: '100%',
                                                        cursor: 'move',
                                                        position: 'relative'
                                                    }}
                                                    class={
                                                        "text-center border flex column bg-white hover-bg-dark-gray"
                                                    }>

                                                    <div class="v-center flex">
                                                        <div class="form-container v-center text-left pr-p5 pl-p5">

                                                            <Field
                                                                stopPropagation={true}
                                                                className='text-xs input-xs'
                                                                field={form.fields[0]} />

                                                            <Field
                                                                stopPropagation={true}
                                                                className='text-xs input-xs'
                                                                field={form.fields[1]} />
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                            position: 'absolute',
                                                            top: '0.5rem',
                                                            right: '0.5rem'
                                                        }}>

                                                        <Button
                                                            className='btn-icon btn-sm'
                                                            stopPropagation={true}
                                                            button={tile.buildRemoveTableButton(form.model, index)} />
                                                    </div>
                                                </div>

                                                <div
                                                    onMouseDown={startResizeDrag}
                                                    class="resize-handle"
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '1rem',
                                                        right: '1rem',
                                                        width: '1rem',
                                                        height: '1rem',
                                                        cursor: 'nwse-resize'
                                                    }}>
                                                        <Icon icon={Icons.Compress} />
                                                    </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </Modal>
                    }

                    <div class="flex form-container">
                        <Field
                            className="mr-1"
                            field={tile.dateField} />

                        <Field
                            className="mr-1"
                            field={tile.startTimeField} />

                        <Field
                            className="mr-1"
                            field={tile.endTimeField} />

                        <div className="mr-auto mt-auto">
                            <Button
                                className="btn-sm"
                                color={ButtonColors.Primary}
                                button={tile.searchMeetingsButton} />

                            <Button
                                className="ml-1 btn-sm"
                                color={ButtonColors.Primary}
                                button={tile.addMeetingButton} />
                        </div>
                    </div>

                    <div class="mt-1 flex">
                        <div class="badging-table">
                            <SmartTable
                                tableClassName='text-sm table-small'
                                columns={this.buildColumns()}
                                table={tile.meetingsTable}
                                expandedContentTemplate={this.meetingDetailsTemplate} />

                            <div class="text-sm">
                                To assign a meeting to a table; select the "Edit" pencil, click on the meeting name from the list above, select the table (or tables) from the Dining Room Layout, select "Save".
                            </div>
                            <div class="text-sm">
                                <i>
                                    Meetings MUST be Finalized to show up in the list.
                                </i>
                            </div>
                        </div>

                        <div className="border p-1 ml-1 bg-white fill">
                            <div class="flex">
                                <div class="mr-1">
                                    <h4 class="mb-0 mt-0">
                                        Dining Room Layout
                                    </h4>

                                    {tile.configureLayoutButton.isVisible &&
                                        <div class="text-sm">
                                            You can use the "Configure" button above to define/edit the dining room table layout for this event.
                                        </div>
                                    }
                                </div>

                                <Button
                                    className="text-sm btn-icon btn-icon-stacked ml-auto v-center"
                                    button={tile.configureLayoutButton} />
                            </div>

                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '500px'
                                }}
                                class="mt-1">

                                {tile.tables && tile.tables.map((table, index) => {

                                    return (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                width: table.layoutWidth + '%',
                                                height: table.layoutHeight + '%',
                                                top: table.layoutY + '%',
                                                left: table.layoutX + '%'
                                            }}
                                            className={"p-p5" + (tile.isEditMode ? " cursor-pointer hover-border " : "")}
                                            onClick={this.tableClicked(index)}>

                                            <div
                                                style={{
                                                    height: '100%',
                                                }}
                                                class={
                                                    "text-center border flex column" +
                                                    ((tile.selectedMeeting && tile.selectedMeeting.tableAssignments.tables[index].meetingId) ? ' bg-dark-gray ' : '') +
                                                    ((tile.selectedMeeting && tile.selectedMeeting.tables.find((t) => t.meetingTableId == table.tableId)) ? ' bg-cyan text-white ' : '')
                                                }>

                                                <div class="v-center">
                                                    <div>{table.tableName}</div>
                                                    <div class="text-sm">{table.seatCount} seats</div>

                                                    {tile.selectedMeeting &&
                                                    tile.selectedMeeting.tableAssignments.tables[index].meetingNumber &&

                                                        <div>
                                                            <div>#{tile.selectedMeeting.tableAssignments.tables[index].meetingNumber}</div>
                                                            <div>
                                                                <FormattedDate
                                                                    date={tile.selectedMeeting.tableAssignments.tables[index].meetingStartDateTime}
                                                                    format={DateFormats.hhmm} />

                                                                <span> - </span>

                                                                <FormattedDate
                                                                    date={tile.selectedMeeting.tableAssignments.tables[index].meetingEndDateTime}
                                                                    format={DateFormats.hhmm} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </EventInfoTile>
        );
    }
}

export default DiningRoomTile;
