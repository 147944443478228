import { h, Component, JSX } from 'preact';
import TableRecordCountProps from './tableRecordCountProps';

class TableRecordCount extends Component<TableRecordCountProps> {

    public render({ isLoading, currentPageNumber, totalPageCount, totalRecords, className }: TableRecordCountProps): JSX.Element {

        if (isLoading) {
            return (
                <div className={className + ' record-count'}>
                    <span className="loading-placeholder shimmer"></span>
                </div>
            );
        }

        return (
            <div className={className + ' record-count'}>
                Page <b>{currentPageNumber}</b> of <b>{totalPageCount}</b> - {totalRecords} total entries
            </div>
        );
    }
}

export default TableRecordCount;
