"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var _1 = require(".");
var button_1 = require("../button/button");
var icons_1 = require("../icons");
var ImageCarousel = /** @class */ (function () {
    function ImageCarousel(imageUrls, title) {
        var _this = this;
        this.imageUrls = imageUrls;
        this.title = title;
        this.nextImage = function () {
            if (_this.imageUrls && _this.imageUrls.length > (_this._selectedIndex + 1)) {
                _this._selectedIndex++;
                _this.updateButtonsDisabledStatus();
                _this.observationProvider.notifyObservers(_1.ImageCarouselActions.CurrentImageChanged);
            }
        };
        this.previousImage = function () {
            if (_this.imageUrls && _this._selectedIndex > 0) {
                _this._selectedIndex--;
                _this.updateButtonsDisabledStatus();
                _this.observationProvider.notifyObservers(_1.ImageCarouselActions.CurrentImageChanged);
            }
        };
        this.goToImage = function (index) { return function () {
            if (_this.imageUrls && index >= 0 && _this.imageUrls.length >= (index + 1)) {
                _this._selectedIndex = index;
                _this.updateButtonsDisabledStatus();
                _this.observationProvider.notifyObservers(_1.ImageCarouselActions.CurrentImageChanged);
            }
        }; };
        this.updateButtonsDisabledStatus = function () {
            _this.previousButton.setDisabled(_this._selectedIndex <= 0);
            _this.nextButton.setDisabled(_this._selectedIndex + 1 >= _this.imageUrls.length);
        };
        this.close = function () {
            _this.observationProvider.notifyObservers(_1.ImageCarouselActions.Closed);
        };
        this.buildSelectImageButton = function (index) {
            if (_this.imageUrls.length <= 1) {
                return;
            }
            return new button_1.default({
                onClick: _this.goToImage(index)
            });
        };
        this._selectedIndex = 0;
        this.observationProvider = new __1.ObservationProvider();
        this.closeButton = new button_1.default({
            leftIcon: icons_1.Icons.ChevronLeft,
            onClick: this.close,
        });
        this.nextButton = new button_1.default({
            leftIcon: icons_1.Icons.ChevronRight,
            onClick: this.nextImage,
            isVisible: imageUrls.length > 1
        });
        this.previousButton = new button_1.default({
            leftIcon: icons_1.Icons.ChevronLeft,
            onClick: this.previousImage,
            isVisible: imageUrls.length > 1
        });
        this.updateButtonsDisabledStatus();
    }
    Object.defineProperty(ImageCarousel.prototype, "currentImageUrl", {
        get: function () {
            return this.imageUrls[this._selectedIndex];
        },
        enumerable: true,
        configurable: true
    });
    return ImageCarousel;
}());
exports.default = ImageCarousel;
