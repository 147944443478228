"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var InventoryForecastPage = /** @class */ (function (_super) {
    __extends(InventoryForecastPage, _super);
    function InventoryForecastPage(api, currentUserManager, navigator) {
        var _this = _super.call(this, __1.PageTypes.InventoryForecast, 'Inventory Forecast') || this;
        _this.api = api;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.buildForm = function () {
            var businessAreaField = new __1.BusinessAreaDropdown(_this.api, 'businessAreaId', 'Business Area', false, null, null, 'Any');
            var todaysDate = new Date();
            var defaultStartDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth());
            var defaultEndDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth() + 3);
            defaultEndDate.setDate(defaultEndDate.getDate() - 1);
            var startDateField = new __1.DateField('startDate', 'Start Date', true, 'Required');
            startDateField.observationProvider.observe(_this.startDateFieldChanged, __1.FieldActions.ValueChanged);
            _this._endDateField = new __1.DateField('endDate', 'End Date', true, 'Required');
            var crateNumberField = new __1.TextField('crateNumber', 'Crate Number', false, 25);
            var defaultFilters = {
                startDate: defaultStartDate,
                endDate: defaultEndDate
            };
            _this._filterOptionsSlider = new __1.ListOptionSlideContainer([
                startDateField,
                _this._endDateField,
                businessAreaField,
                crateNumberField
            ], defaultFilters);
            _this._filterOptionsSlider.observationProvider.observe(_this.loadData, __1.ListOptionSlideContainerActions.OptionsApplied);
            _this.loadData();
        };
        _this.startDateFieldChanged = function (newDateString) {
            if (!newDateString) {
                return;
            }
            var newDate = __1.DateUtils.getDateFromString(newDateString);
            var endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 3);
            endDate.setDate(endDate.getDate() - 1);
            _this._endDateField.setValue(endDate);
        };
        _this.validateDates = function () {
            var isValid = true;
            var startDate = _this._filterOptionsSlider.appliedFiltersModel.startDate;
            var endDate = _this._filterOptionsSlider.appliedFiltersModel.endDate;
            if (__1.DateUtils.isFirstDateGreater(startDate, endDate)) {
                isValid = false;
                _this.setErrorMessage("Start date must be prior to end date");
            }
            else {
                _this.setErrorMessage("");
            }
            return isValid;
        };
        _this.setErrorMessage = function (message) {
            if (message !== _this._errorMessage) {
                _this._errorMessage = message;
                _this.observationProvider.notifyObservers(__1.InventoryForecastPageActions.ErrorMessageChanged);
            }
        };
        _this.IsEventInDateRange = function (lookupDate, forecastEvent) {
            var preEventDate = __1.DateUtils.getDateFromString(forecastEvent.preEventInventoryReservationDate);
            var eventStartDate = __1.DateUtils.getDateFromString(forecastEvent.startDate);
            var eventEndDate = __1.DateUtils.getDateFromString(forecastEvent.endDate);
            var postEventDate = __1.DateUtils.getDateFromString(forecastEvent.postEventInventoryReleaseDate);
            if (preEventDate <= lookupDate && lookupDate < eventStartDate) {
                return __1.InventoryForecastDateStatus.PreEvent;
            }
            if (eventStartDate <= lookupDate && lookupDate <= eventEndDate) {
                return __1.InventoryForecastDateStatus.Event;
            }
            if (eventEndDate < lookupDate && lookupDate <= postEventDate) {
                return __1.InventoryForecastDateStatus.PostEvent;
            }
            return __1.InventoryForecastDateStatus.None;
        };
        _this.getDates = function (month) {
            var dates = [];
            for (var _i = 0, _a = _this._dates; _i < _a.length; _i++) {
                var date = _a[_i];
                if (__1.DateUtils.getMonth(date).monthNumber === month.monthNumber) {
                    dates.push(date);
                }
            }
            return dates;
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var newMonths, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.validateDates()) {
                            return [2 /*return*/];
                        }
                        newMonths = __1.DateUtils.getWholeMonths(this.filterOptionsSlider.appliedFiltersModel.startDate, this.filterOptionsSlider.appliedFiltersModel.endDate);
                        if (newMonths.length > 4) {
                            this.setErrorMessage("Forecast can only span 4 months");
                            return [2 /*return*/];
                        }
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.InventoryForecastPageActions.Loading);
                        this._months = newMonths;
                        this._dates = __1.DateUtils.getDateRangeArray(__1.DateUtils.getFirstDateOfMonth(this.filterOptionsSlider.appliedFiltersModel.startDate), __1.DateUtils.getLastDateOfMonth(this.filterOptionsSlider.appliedFiltersModel.endDate));
                        _a = this;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Inventory + '/Forecast', this.filterOptionsSlider.appliedFiltersModel)];
                    case 1:
                        _a._forecastEvents = _b.sent();
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.InventoryForecastPageActions.Loaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildEventOverviewButton = function (event) {
            return new button_1.default({
                onClick: _this.showEventOverview(event.eventId),
                text: event.eventName
            });
        };
        _this.showEventOverview = function (eventId) { return function () {
            _this._eventOverview.loadData(eventId);
        }; };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.InventoryForecast, null, navigator, api);
        _this.observationProvider = new __1.ObservationProvider();
        _this._errorMessage = "";
        _this._forecastEvents = [];
        _this._eventOverview = new __1.EventOverview(_this.api, _this.currentUserManager);
        _this.buildForm();
        return _this;
    }
    Object.defineProperty(InventoryForecastPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryForecastPage.prototype, "forecastEvents", {
        get: function () {
            return this._forecastEvents;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryForecastPage.prototype, "dates", {
        get: function () {
            return this._dates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryForecastPage.prototype, "months", {
        get: function () {
            return this._months;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryForecastPage.prototype, "eventOverview", {
        get: function () {
            return this._eventOverview;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryForecastPage.prototype, "errorMessage", {
        get: function () {
            return this._errorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryForecastPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryForecastPage.prototype, "filterOptionsSlider", {
        get: function () {
            return this._filterOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    return InventoryForecastPage;
}(__1.BasePage));
exports.default = InventoryForecastPage;
