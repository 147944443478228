import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import ConfirmationPromptProps from './confirmationPromptProps';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';

class ConfirmationPrompt extends ObservingComponent<ConfirmationPromptProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.confirmationPrompt.observationProvider);
    }

    public render({ confirmationPrompt }: ConfirmationPromptProps): JSX.Element {
        if (!confirmationPrompt.isVisible) {
            return;
        }

        return (
            <div class="confirmation-prompt">
                <h4>{confirmationPrompt.heading}</h4>

                <p class="text-center mt-2">
                    {confirmationPrompt.message}
                </p>

                <div class="text-center mt-2">
                    <Button
                        color={ButtonColors.Secondary}
                        button={confirmationPrompt.cancelButton} />

                    <Button
                        color={ButtonColors.Primary}
                        button={confirmationPrompt.deleteButton} />
                </div>
            </div>
        );
    }
}

export default ConfirmationPrompt;
