"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CurrentUserManagerActions;
(function (CurrentUserManagerActions) {
    CurrentUserManagerActions["RequiringPasswordChange"] = "RequiringPasswordChange";
    CurrentUserManagerActions["LoggedIn"] = "LoggedIn";
    CurrentUserManagerActions["LoggedOut"] = "LoggedOut";
    CurrentUserManagerActions["ProfileImageUrlUpdated"] = "ProfileImageUrlUpdated";
    CurrentUserManagerActions["TenantChanged"] = "TenantChanged";
    CurrentUserManagerActions["HeaderLogoImageUrlUpdated"] = "HeaderLogoImageUrlUpdated";
})(CurrentUserManagerActions || (CurrentUserManagerActions = {}));
exports.default = CurrentUserManagerActions;
