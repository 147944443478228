"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var button_1 = require("../../../components/button/button");
var landing_1 = require("../landing");
var api_1 = require("../../../api");
var EventOverview = /** @class */ (function () {
    function EventOverview(_api, currentUserManager) {
        var _this = this;
        this._api = _api;
        this.currentUserManager = currentUserManager;
        this.addAttendees = function () {
            var _loop_1 = function (selectedAttendee) {
                var matchingAttendeeIndex = _this._eventOverviewData.attendees.findIndex(function (attendee) { return attendee.userId === selectedAttendee.id; });
                var matchingNotAttendingAttendeeIndex = _this._eventOverviewData.notAttendingAttendees.findIndex(function (attendee) { return attendee.userId === selectedAttendee.id; });
                if (matchingAttendeeIndex >= 0 || matchingNotAttendingAttendeeIndex >= 0) {
                    return "continue";
                }
                var newAttendee = {
                    userId: selectedAttendee.id,
                    fullName: selectedAttendee.fullName,
                    businessAreaName: selectedAttendee.businessAreaName,
                    lineOfBusinessName: selectedAttendee.lineOfBusinessName,
                    departmentName: selectedAttendee.departmentName,
                    businessAreaAndLineOfBusiness: selectedAttendee.businessAreaName + ' / ' + (selectedAttendee.lineOfBusinessName ? selectedAttendee.lineOfBusinessName : 'LOB Unknown'),
                    isExecutive: selectedAttendee.isExecutive,
                    isRemoved: false
                };
                _this._eventOverviewData.attendees.unshift(newAttendee);
            };
            for (var _i = 0, _a = _this.userSearchField.selectedResults; _i < _a.length; _i++) {
                var selectedAttendee = _a[_i];
                _loop_1(selectedAttendee);
            }
            _this.buildAttendeeGroups();
            _this.userSearchField.clearSelected();
            _this.observationProvider.notifyObservers(__1.EventOverviewActions.AttendeeAdded);
        };
        this.refresh = function () {
            if (!_this._eventId) {
                return;
            }
            _this._isLoaded = false;
            _this.observationProvider.notifyObservers(__1.EventOverviewActions.Refreshing);
            _this.loadData(_this._eventId);
        };
        this.loadData = function (eventId) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isOpen = true;
                        this.observationProvider.notifyObservers(__1.EventOverviewActions.Opened);
                        this._eventId = eventId;
                        _a = this;
                        return [4 /*yield*/, this._api.get(__1.EndpointUrls.Events + '/Overview', { eventId: this._eventId, businessAreaId: this._businessAreaFilter.value })];
                    case 1:
                        _a._eventOverviewData = _b.sent();
                        this._isLoaded = true;
                        this._eventOverviewData.startDate = __1.DateUtils.formatDate(this._eventOverviewData.startDate);
                        this._eventOverviewData.endDate = __1.DateUtils.formatDate(this._eventOverviewData.endDate);
                        if (!this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.Inventory)) {
                            this._eventOverviewData.inventoryManagementEnabled = false;
                        }
                        this.buildArrayGroups();
                        this.userSearchField.clearSearchResults();
                        this.inventoryRequestSearchField.clearSearchResults();
                        this._isEditInventoryRequestMode = false;
                        this._isEditAttendeeMode = false;
                        this._fulfilledInventoryRequestNoteFields = this._eventOverviewData.eventInventoryRequests
                            .filter(function (inventoryRequest) { return inventoryRequest.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Fulfilled; })
                            .map(function (inventoryRequest) { return new __1.TextField('notes', 'Notes', false, 500, null, inventoryRequest.notes, __1.FieldType.TextArea); });
                        this._unfulfilledInventoryRequestNoteFields = this._eventOverviewData.eventInventoryRequests
                            .filter(function (inventoryRequest) { return inventoryRequest.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Requested; })
                            .map(function (inventoryRequest) { return new __1.TextField('notes', 'Notes', false, 500, null, inventoryRequest.notes, __1.FieldType.TextArea); });
                        this.observationProvider.notifyObservers(__1.EventOverviewActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.editAttendeesButtonClicked = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._isEditAttendeeMode = true;
                this.observationProvider.notifyObservers(__1.EventOverviewActions.EditAttendeeModeEnabled);
                return [2 /*return*/];
            });
        }); };
        this.saveAttendeesButtonClicked = function () { return __awaiter(_this, void 0, void 0, function () {
            var attendeeResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.EventOverviewActions.Saving);
                        return [4 /*yield*/, this._api.post(__1.EndpointUrls.Events + '/Overview/Attendees', {
                                eventId: this._eventId,
                                attendees: this._eventOverviewData.attendees.filter(function (attendee) { return attendee.isRemoved || !attendee.id; }),
                                notAttendingAttendees: this._eventOverviewData.notAttendingAttendees.filter(function (attendee) { return attendee.isRemoved || !attendee.id; })
                            })];
                    case 1:
                        attendeeResult = _a.sent();
                        this._eventOverviewData.attendees = attendeeResult.attendees;
                        this._eventOverviewData.notAttendingAttendees = attendeeResult.notAttendingAttendees;
                        this.buildAttendeeGroups();
                        this.saveAttendeesButton.setProcessing(false);
                        this._isEditAttendeeMode = false;
                        this._isLoaded = true;
                        this.observationProvider.notifyObservers(__1.EventOverviewActions.EditAttendeeModeDisabled);
                        return [2 /*return*/];
                }
            });
        }); };
        this.buildRemoveAttendeeButton = function (attendee) {
            return new button_1.default({
                leftIcon: __1.Icons.Trash,
                onClick: function () {
                    _this.removeAttendee(attendee);
                }
            });
        };
        this.removeAttendee = function (attendee) {
            if (attendee.id) {
                attendee.isRemoved = attendee.isRemoved !== true;
            }
            else {
                var matchingAttendeeIndex = _this._eventOverviewData.attendees.findIndex(function (existingAttendee) { return existingAttendee.userId === attendee.userId; });
                if (matchingAttendeeIndex >= 0) {
                    _this._eventOverviewData.attendees.splice(matchingAttendeeIndex, 1);
                }
                var matchingNotAttendingAttendeeIndex = _this._eventOverviewData.notAttendingAttendees.findIndex(function (existingAttendee) { return existingAttendee.userId === attendee.userId; });
                if (matchingNotAttendingAttendeeIndex >= 0) {
                    _this._eventOverviewData.notAttendingAttendees.splice(matchingNotAttendingAttendeeIndex, 1);
                }
                _this.buildAttendeeGroups();
            }
            _this.observationProvider.notifyObservers(__1.EventOverviewActions.AtteneeRemovedStatusChanged);
        };
        this.buildRemoveInventoryRequestButton = function (request) {
            return new button_1.default({
                leftIcon: __1.Icons.Trash,
                onClick: function () {
                    _this.removeInventoryRequest(request);
                }
            });
        };
        this.removeInventoryRequest = function (request) {
            if (request.eventInventoryRequestId) {
                request.isRemoved = request.isRemoved !== true;
            }
            else {
                var index = _this._eventOverviewData.eventInventoryRequests.findIndex(function (existingRequest) { return existingRequest.eventInventoryRequestId == request.eventInventoryRequestId; });
                _this._eventOverviewData.eventInventoryRequests.splice(index, 1);
                if (request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Fulfilled) {
                    var notesFieldIndex = _this._eventOverviewData.eventInventoryRequests
                        .filter(function (request) { return request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Fulfilled; })
                        .findIndex(function (existingRequest) { return existingRequest.eventInventoryRequestId == request.eventInventoryRequestId; });
                    if (notesFieldIndex >= 0) {
                        _this._fulfilledInventoryRequestNoteFields.splice(notesFieldIndex, 1);
                    }
                }
                else if (request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Requested) {
                    var notesFieldIndex = _this._eventOverviewData.eventInventoryRequests
                        .filter(function (request) { return request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Requested; })
                        .findIndex(function (existingRequest) { return existingRequest.eventInventoryRequestId == request.eventInventoryRequestId; });
                    if (notesFieldIndex >= 0) {
                        _this._unfulfilledInventoryRequestNoteFields.splice(notesFieldIndex, 1);
                    }
                }
            }
            _this.buildArrayGroups();
            _this.observationProvider.notifyObservers(__1.EventOverviewActions.InventoryRequestRemoveStatusChanged);
        };
        this.editInventoryRequestButtonClicked = function () {
            _this._isEditInventoryRequestMode = true;
            _this.observationProvider.notifyObservers(__1.EventOverviewActions.EditInventoryRequestModeEnabled);
        };
        this.saveInventoryButtonClicked = function () { return __awaiter(_this, void 0, void 0, function () {
            var unfulfilledRequests, i, fulfilledRequests, i, eventInventoryRequests;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.EventOverviewActions.Saving);
                        unfulfilledRequests = this._eventOverviewData.eventInventoryRequests.filter(function (request) { return request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Requested; });
                        for (i = 0; i < unfulfilledRequests.length; i++) {
                            unfulfilledRequests[i].notes = this._unfulfilledInventoryRequestNoteFields[i].value;
                        }
                        fulfilledRequests = this._eventOverviewData.eventInventoryRequests.filter(function (request) { return request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Fulfilled; });
                        for (i = 0; i < fulfilledRequests.length; i++) {
                            fulfilledRequests[i].notes = this._fulfilledInventoryRequestNoteFields[i].value;
                        }
                        return [4 /*yield*/, this._api.post(__1.EndpointUrls.Events + '/Overview/InventoryRequests', {
                                eventId: this._eventId,
                                inventoryRequests: this._eventOverviewData.eventInventoryRequests
                            })];
                    case 1:
                        eventInventoryRequests = _a.sent();
                        this._eventOverviewData.eventInventoryRequests = eventInventoryRequests;
                        this.saveInventoryRequestButton.setProcessing(false);
                        this._isEditInventoryRequestMode = false;
                        this.buildArrayGroups();
                        this._isLoaded = true;
                        this.observationProvider.notifyObservers(__1.EventOverviewActions.EditInventoryRequestModeDisabled);
                        return [2 /*return*/];
                }
            });
        }); };
        this.addInventoryRequest = function () { return __awaiter(_this, void 0, void 0, function () {
            var _loop_2, this_1, _i, _a, selectedInventoryGroup;
            return __generator(this, function (_b) {
                _loop_2 = function (selectedInventoryGroup) {
                    var matchingIndex = this_1._eventOverviewData.eventInventoryRequests.findIndex(function (existingRequest) { return existingRequest.inventoryItemGroupId === selectedInventoryGroup.id; });
                    if (matchingIndex >= 0) {
                        return "continue";
                    }
                    var newRequest = {
                        inventoryItemGroupId: selectedInventoryGroup.id,
                        inventoryItemGroupName: selectedInventoryGroup.name,
                        eventInventoryRequestStatus: landing_1.EventInventoryRequestStatus.Requested,
                        notes: '',
                        isRemoved: false
                    };
                    this_1._eventOverviewData.eventInventoryRequests.unshift(newRequest);
                    this_1._unfulfilledInventoryRequestNoteFields.unshift(new __1.TextField('notes', 'Notes', false, 500, null, '', __1.FieldType.TextArea));
                };
                this_1 = this;
                for (_i = 0, _a = this.inventoryRequestSearchField.selectedResults; _i < _a.length; _i++) {
                    selectedInventoryGroup = _a[_i];
                    _loop_2(selectedInventoryGroup);
                }
                this.buildArrayGroups();
                this.inventoryRequestSearchField.clearSelected();
                this.observationProvider.notifyObservers(__1.EventOverviewActions.InventoryRequestAdded);
                return [2 /*return*/];
            });
        }); };
        this.goBack = function () {
            _this._isOpen = false;
            _this.observationProvider.notifyObservers(__1.EventOverviewActions.Closed);
        };
        this._eventOverviewData = {
            eventLeads: [],
            eventInventory: [],
            attendees: [],
            webLinks: [],
            notAttendingAttendees: [],
            unfulfilledEventInventoryRequests: [],
            fulfilledEventInventoryRequests: []
        };
        this._businessAreaFilter = new __1.BusinessAreaDropdown(_api, 'businessAreaId', 'Filter by business area', false, null, null, 'All Business Areas');
        this._businessAreaFilter.observationProvider.observe(this.refresh, __1.FieldActions.ValueChanged);
        this.observationProvider = new __1.ObservationProvider();
        this.editAttendeesButton = new button_1.default({
            text: 'Add/Edit Attendees',
            onClick: this.editAttendeesButtonClicked
        });
        this.saveAttendeesButton = new button_1.default({
            text: 'Save Atendees',
            onClick: this.saveAttendeesButtonClicked,
            processingText: 'Saving...'
        });
        this.refreshButton = new button_1.default({
            onClick: this.refresh,
            leftIcon: __1.Icons.Refresh
        });
        this.userSearchField = new __1.UserSearchField(_api, 'User Search', false, null, true);
        this.addAttendeesButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Attendee(s)',
            onClick: this.addAttendees
        });
        this.inventoryRequestSearchField = new __1.InventoryItemGroupSearchField(_api, 'Search Inventory Groups', false, null, true);
        this.editInventoryRequestButton = new button_1.default({
            text: 'Add/Edit Inventory Requests',
            onClick: this.editInventoryRequestButtonClicked
        });
        this.saveInventoryRequestButton = new button_1.default({
            text: 'Save Inventory',
            processingText: 'Saving',
            onClick: this.saveInventoryButtonClicked
        });
        this.addInventoryRequestButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Request(s)',
            onClick: this.addInventoryRequest
        });
    }
    Object.defineProperty(EventOverview.prototype, "isEditAttendeeMode", {
        get: function () {
            return this._isEditAttendeeMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverview.prototype, "isEditInventoryRequestMode", {
        get: function () {
            return this._isEditInventoryRequestMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverview.prototype, "isLoaded", {
        get: function () {
            return this._isLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverview.prototype, "eventOverviewData", {
        get: function () {
            return this._eventOverviewData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverview.prototype, "businessAreaFilter", {
        get: function () {
            return this._businessAreaFilter;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverview.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverview.prototype, "fulfilledInventoryRequestNoteFields", {
        get: function () {
            return this._fulfilledInventoryRequestNoteFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverview.prototype, "unfulfilledInventoryRequestNoteFields", {
        get: function () {
            return this._unfulfilledInventoryRequestNoteFields;
        },
        enumerable: true,
        configurable: true
    });
    EventOverview.prototype.buildArrayGroups = function () {
        this._eventOverviewData.unfulfilledEventInventoryRequests = this.eventOverviewData.eventInventoryRequests.filter(function (request) { return request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Fulfilled; });
        this._eventOverviewData.fulfilledEventInventoryRequests = this.eventOverviewData.eventInventoryRequests.filter(function (request) { return request.eventInventoryRequestStatus === landing_1.EventInventoryRequestStatus.Requested; });
        this._eventOverviewData.eventInventoryArrayGroup =
            __1.ArrayUtils.groupBy({ key: 'categoryName', label: 'Capability', enabled: true, defaultHeading: 'Capability Unknown' }, this._eventOverviewData.eventInventory);
        this.buildAttendeeGroups();
    };
    EventOverview.prototype.buildAttendeeGroups = function () {
        this._eventOverviewData.attendeeArrayGroup =
            __1.ArrayUtils.groupBy([
                { key: 'businessAreaName', label: 'Business Area', enabled: true },
                { key: 'businessAreaAndLineOfBusiness', label: 'Line of Business', enabled: true }
            ], this._eventOverviewData.attendees);
        this._eventOverviewData.notAttendingAttendeesGroup =
            __1.ArrayUtils.groupBy([
                { key: 'businessAreaAndLineOfBusiness', label: 'Business Area / Line of Business', enabled: true }
            ], this._eventOverviewData.notAttendingAttendees);
    };
    return EventOverview;
}());
exports.default = EventOverview;
