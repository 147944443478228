"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MeetingScheduleActions;
(function (MeetingScheduleActions) {
    MeetingScheduleActions["MeetingsLoaded"] = "MeetingsLoaded";
    MeetingScheduleActions["MeetingsLoading"] = "MeetingsLoading";
    MeetingScheduleActions["MeetingDetailLoading"] = "MeetinigDetailLoading";
    MeetingScheduleActions["MeetingDetailLoaded"] = "MeetingDetailLoaded";
    MeetingScheduleActions["MeetingDetailCleared"] = "MeetingDetailCleared";
    MeetingScheduleActions["DateChanged"] = "DateChanged";
    MeetingScheduleActions["MeetingClicked"] = "MeetingClicked";
})(MeetingScheduleActions || (MeetingScheduleActions = {}));
exports.default = MeetingScheduleActions;
