"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InventoryForecastDateStatus;
(function (InventoryForecastDateStatus) {
    InventoryForecastDateStatus[InventoryForecastDateStatus["None"] = 0] = "None";
    InventoryForecastDateStatus[InventoryForecastDateStatus["Event"] = 1] = "Event";
    InventoryForecastDateStatus[InventoryForecastDateStatus["PreEvent"] = 2] = "PreEvent";
    InventoryForecastDateStatus[InventoryForecastDateStatus["PostEvent"] = 3] = "PostEvent";
})(InventoryForecastDateStatus || (InventoryForecastDateStatus = {}));
exports.default = InventoryForecastDateStatus;
