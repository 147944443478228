import { AuthenticatedUserDto, BadgeTilePrintSettings, ILocalStoragePlugin } from 'core.frontend';

export default class LocalStoragePlugin implements ILocalStoragePlugin {
    private userKey: string        = 'user';
    private accountCodeKey: string = 'accountCode';
    private badgePrintSettingsKey: string = 'badgePrintSettings';

    public getCurrentUser(): AuthenticatedUserDto {
        return JSON.parse(window.localStorage.getItem(this.userKey));
    }

    public setCurrentUser(user: AuthenticatedUserDto, switchingTenants: boolean): void {
        window.localStorage.setItem(this.userKey, JSON.stringify(user));

        const thisWindow = window as any;

        if (thisWindow.ReactNativeWebView) {
            if (!user) {
                thisWindow.ReactNativeWebView.postMessage('loggedOut');
            } else if (switchingTenants) {
                thisWindow.ReactNativeWebView.postMessage(JSON.stringify(user));
            }
        }
    }

    public getCurrentAccountCode(): string {
        return window.localStorage.getItem(this.accountCodeKey);
    }

    public setCurrentAccountCode(accountCode: string): void {
        window.localStorage.setItem(this.accountCodeKey, accountCode);
    }

    public clearCurrentAccountCode(): void {
        window.localStorage.removeItem(this.accountCodeKey);
    }

    public getBadgePrintSettings(): BadgeTilePrintSettings {
        const settings = window.localStorage.getItem(this.badgePrintSettingsKey);

        if (settings) {
            return JSON.parse(settings);
        } else {
            return null;
        }
    }

    public setBadgePrintSettings(settings: BadgeTilePrintSettings): void {
        window.localStorage.setItem(this.badgePrintSettingsKey, JSON.stringify(settings));
    }
}
