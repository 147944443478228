import { h, JSX } from 'preact';
import { DateFormats, HistoryNote } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import FormattedDate from '../text/formattedDate';
import HistoryTableProps from './historyTableProps';

class HistoryTable extends ObservingComponent<HistoryTableProps> {

    private buildColumns = (): SmartTableColumn<HistoryNote>[] => {
        return [
            {
                heading: 'Date/Time',
                sortExpression: 'CreatedDateTime',
                cellTemplate: (item) => (
                    <span>
                        {item.note}

                        <br/>

                        <span class="text-sm">
                            By <b>{item.usersFullName}</b> on <FormattedDate date={item.createdDateTime} format={DateFormats.dddMMMMDhmmA} /> as <FormattedDate date={item.createdDateTime} format={DateFormats.hhmm} />
                        </span>
                    </span>
                ),
            }
        ];
    }

    public render({ historyTable, headerClassName }: HistoryTableProps): JSX.Element {
        return (
                <SmartTable
                    headerClassName={headerClassName}
                    table={historyTable}
                    columns={this.buildColumns()} />
        );
    }
}

export default HistoryTable;
