import { EventLandingPage, EventListPage, AttendeeDataGridReportOptions, AttendeeReportOptions, AttendeeReportPage, CompleteAccessRequestPage, EventOverviewReportPage, EventReportOptions, HotelReportOptions, HotelReportPage, IUrlBuilderPlugin, LoginPage, MeetingsReportOptions, RequestAccessPage, RequestPasswordResetPage, ResetPasswordPage, TransportationReportOptions, TransportationReportPage, UserDetailPage, VenueDataGridReportOptions, UserListPage, CustomerDetailPage, CustomerListPage, HistoryPage, VendorUserListPage, VendorUserDetailPage, EventConfigurationPageActions, EventConfigurationPage, UserProfilePage, NewEventPage, InventoryListPage, InventoryDetailPage, UserGroupListPage, UserGroupDetailPage, InventorySchedulerPage, EventOverviewReportPageActions, DateUtils, DateFormats, InventoryForecastPage, SystemSettingsPage, EventHistoryPage, CustomerCompaniesListPage, CustomerCompanyDetailPage, VendorCompaniesPage, VendorCompanyDetailPage, MeetingsReportPage, TenantList, TenantDetailPage, VendorUserProfile, VendorEventListPage, VendorEmailTemplatePage, VendorEmailTemplateDetail, VendorCompanyInfoPage, VendorEmailTemplatesPage, EmailTemplatesPage, EmailTemplatePage, VendorEventLandingPage, UrlAssignerPage, MeetingPlacardPage, RoomStatusDisplayPage, AttendeeDataGridPage, VenueDataGridPage, GolfCartReportOptions, DiningReportOptions } from 'core.frontend';

export default class UrlBuilderPlugin implements IUrlBuilderPlugin
{
    public buildLoginPageUrl = (page: LoginPage): string => {
        return '/login';
    }
    public buildManualLoginPageUrl = (page: LoginPage): string => {
        return '/login/manual';
    }

    public buildRequestPasswordResetUrl = (page: RequestPasswordResetPage): string => {
        return '/requestPasswordReset';
    }

    public buildRequestAccessPageUrl = (page: RequestAccessPage): string => {
        return '/requestAccess';
    }

    public buildpasswordResetPageUrl = (page: ResetPasswordPage): string => {
        if (page.passwordResetKey) {
            return `/passwordReset/${page.passwordResetKey}`;
        } else {
            return '/login';
        }
    }

    public buildEventListPageUrl = (page: EventListPage): string => {
        return '/events';
    }

    public buildEventLandingPageUrl = (page: EventLandingPage): string => {
        if (page.meetingId !== null && page.meetingId !== undefined) {
            return `/events/${page.eventId}/${page.meetingId}`;
        }

        return `/events/${page.eventId}`;
    }

    public buildUserListPageUrl = (page: UserListPage): string => {
        return '/users';
    }

    public buildUserDetailPageUrl = (page: UserDetailPage): string => {
        if (page.userId) {
            return `/users/${page.userId}`;
        } else {
            return '/users/';
        }
    }

    public buildCompleteAccessRequestPageUrl = (page: CompleteAccessRequestPage): string => {
        if (page.requestKey) {
            return `/accessRequest/${page.requestKey}`;
        } else {
            return '/login';
        }
    }

    public buildCustomerDetailPageUrl = (page: CustomerDetailPage): string => {
        if (page.customerId) {
            return `/customers/${page.customerId}`;
        } else {
            return '/customers/';
        }
    }

    public buildCustomerListPageUrl = (page: CustomerListPage): string => {
        return '/customers';
    }

    public buildHistoryPageUrl = (page: HistoryPage): string => {
        return '/history';
    }

    public buildVendorUserListPageUrl = (page: VendorUserListPage) => {
        return '/vendors';
    }

    public buildVendorUserDetailPageUrl = (page: VendorUserDetailPage) => {
        if (page.recordId) {
            return `/vendors/${page.recordId}`;
        } else {
            return '/vendors/';
        }
    }

    public buildEventConfigurationPageUrl = (page: EventConfigurationPage): string => {
        if (page.eventId) {
            return `/eventConfiguration/${page.eventId}`;
        } else {
            return `/eventConfiguration/`
        }
    }

    public buildUserProfilePageUrl = (page: UserProfilePage): string => {
        return '/userProfile';
    }

    public buildNewEventPageUrl = (page: NewEventPage): string => {
        return '/newEvent';
    }

    public buildInventoryListPageUrl = (page: InventoryListPage): string => {
        return '/inventory';
    }

    public buildInventoryDetailPageUrl = (page: InventoryDetailPage): string => {
        if (page.recordId) {
            return `/inventory/${page.recordId}`;
        } else {
            return '/inventory/';
        }
    }

    public buildUserGroupListPageUrl = (page: UserGroupListPage): string => {
        return '/userGroups';
    }

    public buildUserGroupDetailPageUrl = (page: UserGroupDetailPage): string => {
        if (page.recordId) {
            return `/userGroups/${page.recordId}`;
        } else {
            return '/userGroups/';
        }
    }

    public buildInventorySchedulerPageUrl = (page: InventorySchedulerPage) => {
        return '/inventoryScheduler';
    }

    public buildEventOverviewReportPageUrl = (page: EventOverviewReportPage): string => {
        let optionsString = '?';
        optionsString += '&startDate=' + DateUtils.formatDate(page.reportOptions.startDate, DateFormats.MMDDYYYY);
        optionsString += '&endDate=' + DateUtils.formatDate(page.reportOptions.endDate, DateFormats.MMDDYYYY);
        optionsString += '&isInternational=' + page.reportOptions.isInternational;
        optionsString += '&leadBusinessAreaId=' + page.reportOptions.leadBusinessAreaId;
        optionsString += '&showInventory=' + page.reportOptions.showInventory.toString();

        return '/reports/eventOverview' + optionsString;
    }

    public buildInventoryForcastPageUrl = (page: InventoryForecastPage): string => {
        return '/inventoryForecast';
    }

    public buildSystemSettingsPageUrl = (page: SystemSettingsPage): string => {
        return '/systemSettings';
    }

    public buildEventHistoryPageUrl = (page: EventHistoryPage): string => {
        return '/eventHistory';
    }

    public buildCustomerCompaniesListPageUrl = (page: CustomerCompaniesListPage): string => {
        return '/customerCompanies';
    }

    public buildCustomerCompanyDetailPageUrl = (page: CustomerCompanyDetailPage): string => {
        if (page.customerCompanyId) {
            return `/customerCompany/${page.customerCompanyId}`;
        } else {
            return '/customerCompany/';
        }
    }

    public buildVendorCompaniesPageUrl = (page: VendorCompaniesPage): string => {
        return '/vendorCompanies';
    }

    public buildVendorCompanyDetailPageUrl = (page: VendorCompanyDetailPage): string => {
        if (page.vendorCompanyId) {
            return `/vendorCompanies/${page.vendorCompanyId}`;
        } else {
            return '/vendorCompanies/';
        }
    }

    public buildAttendeeReportPageUrl = (options: AttendeeReportOptions): string => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&eventAttendeeIds=';

        for (let i = 0; i < options.eventAttendeeIds.length; i++) {
            optionsString += options.eventAttendeeIds[i];

            if (i + 1 < options.eventAttendeeIds.length) {
                optionsString += ',';
            }
        }

        optionsString += '&includePhotos=' + (options.includePhotos === true ? 'true' : 'false');
        optionsString += '&lineOfBusinessId=' + options.lineOfBusinessId;


        return '/events/attendeeReport' + optionsString;
    }

    public buildMeetingsReportPageUrl = (options: MeetingsReportOptions): string => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&startDate=' + DateUtils.formatDate(options.startDate, DateFormats.MMDDYYYY);
        optionsString += '&startTime=' + options.startTime;
        optionsString += '&endDate=' + DateUtils.formatDate(options.endDate, DateFormats.MMDDYYYY);
        optionsString += '&endTime=' + options.endTime;
        optionsString += '&reportForType=' + options.reportForType;
        optionsString += '&cancellationStatus=' + options.cancellationStatus;

        optionsString += '&eventAttendeeIds=';
        for (let i = 0; i < options.eventAttendeeIds.length; i++) {
            optionsString += options.eventAttendeeIds[i];

            if (i + 1 < options.eventAttendeeIds.length) {
                optionsString += ',';
            }
        }

        optionsString += '&meetingCategoryId=' + options.defaultMeetingCategoryId;
        optionsString += '&attendeeBusinessAreaId=' + options.attendeeBusinessAreaId;
        optionsString += '&attendeeDepartmentId=' + options.attendeeDepartmentId;
        optionsString += '&venueId=' + options.venueId;
        optionsString += '&guestsArrived=' + options.guestsArrived;
        optionsString += '&includeTransportation=' + options.includeTransportation;
        optionsString += '&scheduledById=' + options.scheduledById;

        return '/events/meetingsReport' + optionsString;
    }

    public buildHotelReportPageUrl = (options: HotelReportOptions): string => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&startDate=' + DateUtils.formatDate(options.startDate, DateFormats.MMDDYYYY);
        optionsString += '&endDate=' + DateUtils.formatDate(options.endDate, DateFormats.MMDDYYYY);
        optionsString += '&reportForType=' + options.reportForType;

        optionsString += '&eventAttendeeIds=';
        for (let i = 0; i < options.eventAttendeeIds.length; i++) {
            optionsString += options.eventAttendeeIds[i];

            if (i + 1 < options.eventAttendeeIds.length) {
                optionsString += ',';
            }
        }

        optionsString += '&businessAreaId=' + options.businessAreaId;
        optionsString += '&departmentId=' + options.departmentId;
        optionsString += '&eventHotelId=' + options.eventHotelId;
        optionsString += '&lodgingStatus=' + options.lodgingStatus;
        optionsString += '&includePhotos=' + (options.includePhotos === true ? 'true' : 'false');

        return '/events/hotelReport' + optionsString;
    }

    public buildTransportationReportPageUrl = (options: TransportationReportOptions): string => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&startDate=' + DateUtils.formatDate(options.startDate, DateFormats.MMDDYYYY);
        optionsString += '&endDate=' + DateUtils.formatDate(options.endDate, DateFormats.MMDDYYYY);
        optionsString += '&reportForType=' + options.reportForType;

        optionsString += '&eventAttendeeIds=';
        for (let i = 0; i < options.eventAttendeeIds.length; i++) {
            optionsString += options.eventAttendeeIds[i];

            if (i + 1 < options.eventAttendeeIds.length) {
                optionsString += ',';
            }
        }

        optionsString += '&businessAreaId=' + options.businessAreaId;
        optionsString += '&departmentId=' + options.departmentId;
        optionsString += '&dailyTransportStatus=' + options.dailyTransportStatus;
        optionsString += '&reportType=' + options.reportType;
        optionsString += '&includePhotos=' + (options.includePhotos === true ? 'true' : 'false');

        return '/events/transportationReport' + optionsString;
    }

    public buildTenantListPageUrl = (page: TenantList): string => {
        return '/tenants';
    }

    public buildTenantDetailPageUrl = (page: TenantDetailPage): string => {
        if (page.tenantId) {
            return `/tenants/${page.tenantId}`;
        } else {
            return '/tenants/';
        }
    }

    public buildVendorUserProfilePageUrl = (page: VendorUserProfile) => {
        return '/vendorPortal/userProfile';
    }

    public buildVendorEventListPageUrl = (page: VendorEventListPage) => {
        return '/vendorPortal/events'
    }

    public buildVendorEmailTemplatesListPageUrl = (page: VendorEmailTemplatesPage): string => {
        return '/vendorPortal/emailTemplates';
    }

    public buildVendorEmailTemplateDetailPageUrl = (page: VendorEmailTemplatePage): string => {
        return '/vendorPortal/emailTemplates/';
    }

    public buildVendorCompanyInfoPageUrl = (page: VendorCompanyInfoPage): string => {
        return '/vendorPortal/companyAndUsers';
    }

    public buildEmailTemplatesPageUrl = (page: EmailTemplatesPage): string => {
        return '/emailTemplates';
    }

    public buildEmailTemplatePageUrl = (page: EmailTemplatePage): string => {
        if (page.recordId) {
            return `/emailTemplate/${page.recordId}`;
        } else {
            return `/emailTemplate/`;
        }
    }

    public buildVendorPortalEventLandingPage = (page: VendorEventLandingPage): string => {
        return `/vendorPortal/events/${page.eventId}/${page.tenantId}`;
    }

    public buildUrlAssignerPageUrl = (page: UrlAssignerPage): string => {
        return '/meetings/urls/' + page.eventId;
    }

    public buildMeetingPlacardPageUrl = (page: MeetingPlacardPage): string => {
        return `/displays/meetingPlacard?eventId=${page.eventId}&passKey=${page.passKey}`;
    }

    public buildRoomDisplayPageUrl = (page: RoomStatusDisplayPage): string => {
        return `/displays/roomDisplay?eventId=${page.eventId}&passKey=${page.passKey}`;
    }

    public buildAttendeeDataGridReportPageUrl = (options: AttendeeDataGridReportOptions) => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&startDate=' + DateUtils.formatDate(options.startDate, DateFormats.MMDDYYYY);
        optionsString += '&endDate=' + DateUtils.formatDate(options.endDate, DateFormats.MMDDYYYY);

        optionsString += '&eventAttendeeIds=';
        for (let i = 0; i < options.eventAttendeeIds.length; i++) {
            optionsString += options.eventAttendeeIds[i];

            if (i + 1 < options.eventAttendeeIds.length) {
                optionsString += ',';
            }
        }

        optionsString += '&startTime=' + options.startTime;
        optionsString += '&endTime=' + options.endTime;
        optionsString += '&includeWorking=' + (options.includeWorking ? 'true' : 'false');
        optionsString += '&includeMeetingTimes=' + (options.includeMeetingTimes ? 'true' : 'false');
        optionsString += '&includeVenue=' + (options.includeVenue ? 'true' : 'false');
        optionsString += '&includeRequest=' + (options.includeRequest ? 'true' : 'false');
        optionsString += '&includeMeetingNumber=' + (options.includeMeetingNumber ? 'true' : 'false');

        return '/events/attendeeDataGridReport' + optionsString;
    }

    public buildVenueDataGridReportPageUrl = (options: VenueDataGridReportOptions): string => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&startDate=' + DateUtils.formatDate(options.startDate, DateFormats.MMDDYYYY);
        optionsString += '&endDate=' + DateUtils.formatDate(options.endDate, DateFormats.MMDDYYYY);

        optionsString += '&venueIds=';
        for (let i = 0; i < options.venueIds.length; i++) {
            optionsString += options.venueIds[i];

            if (i + 1 < options.venueIds.length) {
                optionsString += ',';
            }
        }

        optionsString += '&startTime=' + options.startTime;
        optionsString += '&endTime=' + options.endTime;
        optionsString += '&includeWorking=' + (options.includeWorking ? 'true' : 'false');
        optionsString += '&showHeader=' + (options.showHeader ? 'true' : 'false');
        optionsString += '&showMeetingName=' + (options.showMeetingName ? 'true' : 'false');
        optionsString += '&showMeetingNumber=' + (options.showMeetingNumber ? 'true' : 'false');
        optionsString += '&showMeetingTime=' + (options.showMeetingTime ? 'true' : 'false');
        optionsString += '&autoRefresh=' + (options.autoRefresh ? 'true' : 'false');
        optionsString += '&showLead=' + (options.showLead ? 'true' : 'false');
        optionsString += '&includeRequest=' + (options.includeRequest ? 'true' : 'false');

        return '/events/venueDataGridReport' + optionsString;
    }

    public buildGolfCartReportPageUrl = (options: GolfCartReportOptions): string => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&startDate=' + DateUtils.formatDate(options.startDate, DateFormats.MMDDYYYY);
        optionsString += '&startTime=' + options.startTime;
        optionsString += '&endDate=' + DateUtils.formatDate(options.endDate, DateFormats.MMDDYYYY);;
        optionsString += '&endTime=' + options.endTime;

        return '/events/golfCartReport' + optionsString;
    }

    public buildDiningReportPageUrl = (options: DiningReportOptions): string => {
        let optionsString = '?';

        optionsString += 'eventId=' + options.eventId;
        optionsString += '&startDate=' + DateUtils.formatDate(options.startDate, DateFormats.MMDDYYYY);
        optionsString += '&startTime=' + options.startTime;
        optionsString += '&endDate=' + DateUtils.formatDate(options.endDate, DateFormats.MMDDYYYY);;
        optionsString += '&endTime=' + options.endTime;
        optionsString += '&includeWorking=' + (options.includeWorking === true ? 'true' : 'false');
        optionsString += '&includeRequests=' + (options.includeRequests === true ? 'true' : 'false');

        return '/events/diningReport' + optionsString;
    }

    public buildHelpUrlsPageUrl = (): string => {
        return '/system/help'
    }

    public buildDeviceRegistrationPageUrl = (): string => {
        return '/login/deviceRegistration';
    }

    public buildSsoLoginPageUrl = (): string => {
        return '/login/sso';
    }
}