"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var CheckBoxField = /** @class */ (function (_super) {
    __extends(CheckBoxField, _super);
    function CheckBoxField(key, label, isRequired, requiredValidationMessage, initialValue) {
        if (isRequired === void 0) { isRequired = false; }
        var _this = _super.call(this, _1.FieldType.CheckBox, key, label, isRequired, 5, requiredValidationMessage) || this;
        _this.key = key;
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(CheckBoxField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            return baseErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    CheckBoxField.prototype.isValid = function () {
        var isValid = this.checkRequiredStatus();
        this.setIsValid(isValid);
        return isValid;
    };
    CheckBoxField.prototype.setValue = function (newValue) {
        if (newValue === null || newValue === undefined) {
            return;
        }
        if (newValue.toString().toLocaleLowerCase() === 'true') {
            this.setStringValue('true');
        }
        else {
            this.setStringValue('false');
        }
    };
    Object.defineProperty(CheckBoxField.prototype, "value", {
        get: function () {
            return this.stringValue === 'true';
        },
        enumerable: true,
        configurable: true
    });
    return CheckBoxField;
}(baseField_1.default));
exports.default = CheckBoxField;
