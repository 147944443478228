"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LoginPageActions;
(function (LoginPageActions) {
    LoginPageActions["LoginSuccessfull"] = "LoginSuccessFull";
    LoginPageActions["LoginUnsuccessfull"] = "LoginUnsuccessfull";
    LoginPageActions["LoginFormInvalid"] = "LoginFormInvalid";
    LoginPageActions["LoginFormSubmitted"] = "LoginFormSubmitted";
})(LoginPageActions || (LoginPageActions = {}));
exports.default = LoginPageActions;
