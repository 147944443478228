import { ArrayUtils, ObservationProvider, ObservationProviderContainer } from 'core.frontend';
import { h, Component } from 'preact';

abstract class ObservingComponent<PropType, StateType = {}> extends Component<PropType, StateType> {
    private _containers: ObservationProviderContainer[];

    constructor(props: PropType) {
        super(props);

        this._containers = [];
    }

    public componentWillUnmount(): void {
        this.stopObservingAll();
    }

    public stopObservingAll(): void {
        for (const container of this._containers) {
            container.stopObservingAll();
        }
    }

    public registerObserver<T extends string>(observationProvider: ObservationProvider<T, any>, fn, actions?: T[] | T): void {
        const container = this.getContainerByObservationProivder(observationProvider);

        for (const action of ArrayUtils.arrayify(actions)) {
            container.observerIds.push(observationProvider.observe(fn, action));
        }
    }

    public registerUpdateObserver<T extends string>(observationProvider: ObservationProvider<T, any>, actions?: T[] | T): void {
        const container = this.getContainerByObservationProivder(observationProvider);

        for (const action of ArrayUtils.arrayify(actions)) {
            container.observerIds.push(observationProvider.observe(this.updateState, action));
        }
    }

    private updateState = () => {
        this.setState(() => { return {}; });
    }

    private getContainerByObservationProivder(observationProvider: ObservationProvider<any, any>): ObservationProviderContainer {
        for (const container of this._containers) {
            if (observationProvider === container.observationProvider) {
                return container;
            }
        }

        const newContainer = new ObservationProviderContainer(observationProvider);
        this._containers.push(newContainer);

        return newContainer;
    }
}

export default ObservingComponent;
