"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RequestPasswordResetTextPageActions;
(function (RequestPasswordResetTextPageActions) {
    RequestPasswordResetTextPageActions["FormInvalid"] = "FormInvalid";
    RequestPasswordResetTextPageActions["FormSubmitted"] = "FormSubmitted";
    RequestPasswordResetTextPageActions["ErrorSubmitting"] = "ErrorSubmitting";
    RequestPasswordResetTextPageActions["SubmittedSuccessfully"] = "SubmittedSuccessfully";
})(RequestPasswordResetTextPageActions || (RequestPasswordResetTextPageActions = {}));
exports.default = RequestPasswordResetTextPageActions;
