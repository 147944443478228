"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventMeetingsTileActions;
(function (EventMeetingsTileActions) {
    EventMeetingsTileActions["MeetingsLoaded"] = "MeetingsLoaded";
    EventMeetingsTileActions["GuestArrivedChanged"] = "GuestArrivedChanged";
    EventMeetingsTileActions["MeetingDuplicating"] = "MeetingDuplicating";
    EventMeetingsTileActions["MeetingDuplicated"] = "MeetingDuplicated";
    EventMeetingsTileActions["EditVenueModeChanged"] = "EditVenueModeChanged";
    EventMeetingsTileActions["EditVenuesCancelled"] = "EditVenuesCancelled";
    EventMeetingsTileActions["VenuesSaved"] = "VenuesSaved";
    EventMeetingsTileActions["VenueAssignmentModeChanged"] = "VenueAssignmentModeChanged";
    EventMeetingsTileActions["ActivePaneChanged"] = "ActivePaneChanged";
})(EventMeetingsTileActions || (EventMeetingsTileActions = {}));
exports.default = EventMeetingsTileActions;
