import { Fragment, h, JSX } from 'preact';
import { SystemSettingsPanes } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import SystemSettingsPageProps from './systemSettingsPageProps';
import SettingsPane from './settingsPane';
import Button from '../buttons/button';
import DataList from '../dataList/dataList';
import Dropdown from '../dropdown/dropdown';
import LeftPageNav from '../leftPageNav/leftPageNav';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import FormButtonContainer from '../containers/formButtonContainer';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import EventAttendeeAccessLevelDataList from '../events/eventAttendeeAccessLevelDataList';
import Field from '../fields/field';
import RichTextField from '../fields/richTextField';
import Alert from '../alerts/alert';
import VideoModalButton from '../buttons/videoModalButton';

class SystemSettingsPage extends ObservingComponent<SystemSettingsPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
        this.registerUpdateObserver(this.props.page.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt.observationProvider);
        this.registerUpdateObserver(this.props.page.eventAccessLevelUpdateEventsConfirmationPrompt.observationProvider);
        this.registerUpdateObserver(this.props.page.costManagementServicesUpdateCurrentEventsConfirmationPrompt.observationProvider);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div class="mt-1p5">
                <ConfirmationPrompt confirmationPrompt={page.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt} />
                <ConfirmationPrompt confirmationPrompt={page.eventAccessLevelUpdateEventsConfirmationPrompt} />
                <ConfirmationPrompt confirmationPrompt={page.costManagementServicesUpdateCurrentEventsConfirmationPrompt} />

                {!page.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt.isVisible &&
                 !page.eventAccessLevelUpdateEventsConfirmationPrompt.isVisible &&
                 !page.costManagementServicesUpdateCurrentEventsConfirmationPrompt.isVisible &&

                    <div>
                        <Alert
                            className="mb-2"
                            alert={page.alert} />

                        <div className="flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                        </div>

                        <Dropdown
                            className="d-md-none mb-2 full-width"
                            dropdown={page.dropdown} />

                        <div class="flex">

                            <LeftPageNav
                                className="d-none d-md-block"
                                heading="System Settings"
                                dropdownItems={page.dropdownItems} />


                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.ApprovedAccessDomains}
                                heading="Domain Whitelist"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.approvedAccessDomainDataList.addItemButton} />
                                }
                                descriptionText="Only emails that have the following domains can complete system access requests. Restricting this list ensures that only individuals from authorized organizations can request access." >

                                <DataList dataList={page.approvedAccessDomainDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.BusinessAreas}
                                heading="Business Areas"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.businessAreaDataList.addItemButton} />
                                }>

                                <DataList dataList={page.businessAreaDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.Departments}
                                heading="Operating Companies"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.departmentDataList.addItemButton} />
                                }>

                                <DataList dataList={page.departmentDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.LinesOfBusiness}
                                heading="Lines of Business"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.linesOfBusinessDataList.addItemButton} />
                                }>

                                <DataList dataList={page.linesOfBusinessDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.UserTitles}
                                heading="User Titles"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.userTitleDataList.addItemButton} />
                                }>

                                <DataList dataList={page.userTitleDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.Hotels}
                                heading="Hotels"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.hotelsDataList.addItemButton} />
                                }>

                                <DataList dataList={page.hotelsDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.EventLeadResponsibilities}
                                heading="Event Lead Responsibilities"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.eventLeadResponsibilitiesDataList.addItemButton} />
                                }>

                                <DataList dataList={page.eventLeadResponsibilitiesDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.DefaultMeetingCategories}
                                heading="Meeting Categories"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.defaultMeetingCategoriesDataList.addItemButton} />
                                }>

                                <Fragment>
                                    <div className="mb-1 flex">
                                        <Field
                                            className="ml-auto"
                                            field={page.showOnlyActiveMeetingCategoryCheckbox} />
                                    </div>

                                    <DataList dataList={page.defaultMeetingCategoriesDataList} />

                                    <div class="mt-2 flex">
                                        <div class="fill flex">
                                            <b class="v-center d-none d-md-inline">Scheduled by Options</b>
                                        </div>

                                        <Button
                                            color={ButtonColors.Primary}
                                            size={ButtonSizes.Small}
                                            button={page.meetingScheduledByDataList.addItemButton} />
                                    </div>

                                    <div>
                                        <hr/>
                                    </div>

                                    <p>If you wish to track WHO scheduled a meeting, maintain a list of options below. When a category above has the check box selected, this list will show up on the Meeting page.</p>

                                    <DataList dataList={page.meetingScheduledByDataList} />
                                </Fragment>

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.MeetingNotesDisclaimer}
                                heading="Meeting Notes Disclaimer Text">

                                <div class="form-container">
                                    <RichTextField
                                        isLoading={!page.isLoaded}
                                        className="settings-terms-field"
                                        field={page.meetingNotesDisclaimerField} />
                                </div>
                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.InventoryItemTypePane}
                                heading="Inventory Item Types"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.inventoryItemTypeDataList.addItemButton} />
                                }>

                                <DataList
                                    dataList={page.inventoryItemTypeDataList}
                                    itemTemplate={(inventoryItemType) => (
                                        <li>

                                            <div class="flex">
                                                <Field
                                                    field={inventoryItemType.form.fields[0]}
                                                    className="fill" />

                                                <Field
                                                    field={inventoryItemType.form.fields[1]}
                                                    className="ml-p5" />

                                                <Field
                                                    field={inventoryItemType.form.fields[2]}
                                                    className="ml-p5" />
                                            </div>

                                            <Field
                                                field={inventoryItemType.form.fields[3]}
                                                className="mt-0" />

                                            <div class="flex mt-1">
                                                <b class="text-sm">Item Requires: </b>

                                                <div class="fill flex">
                                                    <Field
                                                        field={inventoryItemType.form.fields[4]}
                                                        className="label-right mt-0 ml-p5" />

                                                    <Field
                                                        field={inventoryItemType.form.fields[5]}
                                                        className="label-right mt-0 ml-p5" />

                                                    <Field
                                                        field={inventoryItemType.form.fields[6]}
                                                        className="label-right mt-0 ml-p5" />

                                                    <Field
                                                        field={inventoryItemType.form.fields[7]}
                                                        className="label-right mt-0 ml-p5" />

                                                    <Field
                                                        field={inventoryItemType.form.fields[8]}
                                                        className="label-right mt-0 ml-p5" />
                                                </div>
                                            </div>
                                        </li>
                                    )} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.InventoryItemGroupPane}
                                heading="Inventory Item Groups"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.inventoryItemGroupDataList.addItemButton} />
                                }>

                                <DataList dataList={page.inventoryItemGroupDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.InventoryItemCategoryPane}
                                heading="Inventory Item Capabilities"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.inventoryItemCategoryDataList.addItemButton} />
                                }>

                                <DataList dataList={page.inventoryItemCategoryDataList} />

                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.EventAttendeeAccessLevels}
                                heading="Event Access Levels"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.eventAttendeeAccessLevelDataList.addItemButton} />
                                }>

                                <EventAttendeeAccessLevelDataList dataList={page.eventAttendeeAccessLevelDataList} />

                            </SettingsPane>


                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.TransportTermsPane}
                                heading="Transport Terms & Conditions"
                                descriptionText="These are the default terms & conditions that will be used to pre-fill transport terms & conditions when an event is created. Changes to transport terms & conditions in the configurations will override this default language and will only apply to that event.">

                                <div class="form-container">
                                    <RichTextField
                                        isLoading={!page.isLoaded}
                                        className="settings-terms-field"
                                        field={page.transportTermsField} />
                                </div>
                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.LodgingTermsPane}
                                heading="Hotel Terms & Conditions"
                                descriptionText="These are the default terms & conditions that will be used to pre-fill hotel terms & conditions when an event is created. Changes to hotel terms & conditions in the configurations will override this default language and will only apply to that event.">

                                <div class="form-container">
                                    <RichTextField
                                        isLoading={!page.isLoaded}
                                        className="settings-terms-field"
                                        field={page.lodgingTermsField} />
                                </div>
                            </SettingsPane>


                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.ProductKnowledgeOptions}
                                heading="Area of Expertise"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.productKnowledgeDataList.addItemButton} />
                                }>

                                <DataList dataList={page.productKnowledgeDataList} />
                            </SettingsPane>


                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.ApiAuthKeyPane}
                                heading="Open Api Authentication Key"
                                descriptionText="Use the following as credentials for the TEMS Open Api. You can find the Open Api documentation by clicking the link below."
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.createNewApiAuthKeyButton} />
                                }>

                                <div class="mt-1">
                                    <div>
                                        Open Api Documentation: &nbsp;<a href="https://app.swaggerhub.com/apis-docs/TEMSv3/TEMS_Open_Api/TEMSv3#/" target="_blank">https://app.swaggerhub.com/apis-docs/TEMSv3/TEMS_Open_Api/TEMSv3#/</a>
                                    </div>
                                    <div>
                                        Base Url: <b>{page.openApiBaseUrl}</b>
                                    </div>
                                    <div>
                                        Tenant Id: <b> {page.tenantId}</b>
                                    </div>
                                    <div>
                                        Open Api Authentication Key:
                                            {page.isLoaded && <b> {page.apiKey}</b>}
                                    </div>
                                </div>
                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.CostManagementServices}
                                heading="Cost Management Services"
                                headingRightContent={
                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={page.costManagementServicesDataList.addItemButton} />
                                }>

                                <DataList dataList={page.costManagementServicesDataList} />
                            </SettingsPane>

                            <SettingsPane
                                isVisible={page.activePane === SystemSettingsPanes.SystemSettingsSystemNotification}
                                heading="Login Alerts"
                                descriptionText='Login alerts can be set to present a user with a message for a specified number of times that they login. Changing the message or the enabled status of these alerts will reset the view count for each user.'
                                headingRightContent={
                                    <Field field={page.systemSettingsSystemNotificationForm.fields[0]} />
                                }>

                                <div class="form-container">
                                    <Field field={page.systemSettingsSystemNotificationForm.fields[1]} />
                                    <Field field={page.systemSettingsSystemNotificationForm.fields[2]} />
                                </div>
                            </SettingsPane>

                        </div>

                        <FormButtonContainer
                            className='sticky-button-footer mt-p5'
                            rightButtons={[
                                <Button
                                    color={ButtonColors.Primary}
                                    button={page.saveButton} />
                            ]} />
                    </div>
                }
            </div>
        );
    }
}

export default SystemSettingsPage;
