import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { VendorCompanyTableItem } from 'core.frontend';
import VendorCompaniesPageProps from './vendorCompaniesPageProps';
import SmartTableColumn from '../smartTable/smartTableColumn';
import SmartTable from '../smartTable/smartTable';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import Card from '../containers/card';
import VideoModalButton from '../buttons/videoModalButton';

class VendorCompaniesPage extends ObservingComponent<VendorCompaniesPageProps> {

    private buildColumns = (): SmartTableColumn<VendorCompanyTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'VendorCompanies.Name',
                cellTemplate: (item, index) => (
                    <span>
                        <Button
                            className="btn-link"
                            button={page.buildEditButton(item)} />
                        {!item.isActive &&
                            <span class="text-sm text-red"> - Inactive</span>
                        }
                    </span>
                ),
                appendSavingIndicator: true
            }
        ]
    }

    public render({ page }: VendorCompaniesPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    heading="All Customer Companies"
                    headingRightContent={
                        <div class="button-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.newCompanyButton} />
                        </div>
                    }>

                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()}
                        listOptionSlideContainer={page.listOptionSlideContainer} />

                </Card>
            </div>
        );
    }
}

export default VendorCompaniesPage;
