import { h, JSX } from 'preact'
import ObservingComponent from '../componentBases/observingComponent';
import FieldIconProps from './FieldIconProps';

class FieldIcon extends ObservingComponent<FieldIconProps> {

    public render(): JSX.Element {
        const { icon, invalid } = this.props;

        return (
            <div class="icon-wrapper">
                <i class={`ti ${icon} ${invalid && 'invalid'}`} ></i>
            </div>
        );
    }
}

export default FieldIcon;
