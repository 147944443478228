import { h, JSX } from 'preact';
import { Icons } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import PhoneButtonProps from './phoneButtonProps';
import Icon from '../icons/icon';

class PhoneButton extends ObservingComponent<PhoneButtonProps> {

    private buttonClicked = (): void => {
        const phoneNumber = this.props.phoneNumber
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(/\s/g, '');

        window.open(`tel:${phoneNumber}`);
    }

    public render({ className, phoneNumber}: PhoneButtonProps): JSX.Element {
        return (
            <a
                class={`btn button inline-flex v-center btn-icon ${className}`}
                onClick={this.buttonClicked}>

                <span class="btn-content">
                    <Icon icon={Icons.Phone} />
                </span>
            </a>
        );
    }
}

export default PhoneButton;
