import { h, JSX } from 'preact';
import { VendorUserTableItem } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Card from '../containers/card';
import VendorUserListPageProps from './vendorUserListPageProps';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';

class VendorUserListPage extends ObservingComponent<VendorUserListPageProps> {

    private buildColumns = (): SmartTableColumn<VendorUserTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'lastName,firstName',
                className: 'd-md-none',
                cellTemplate: (item, index) =>
                    (<span>
                        <Button
                            className="btn-link"
                            button={page.buildEditUserButton(item)} />
                        {!item.isActive &&
                            <span class="text-sm text-red"> - Inactive</span>
                        }
                        {item.isActive && item.lockedOut &&
                            <span class="text-sm text-red"> - Too many login attempts</span>
                        }

                        <span class="text-sm">
                            <br/>
                            {item.vendorCompany}
                            <br/>
                            {item.email}
                        </span>
                    </span>),
                appendSavingIndicator: true
            },
            {
                heading: '',
                className: 'd-none d-md-table-cell fit-content text-center',
                cellTemplate: (item, index) => <span>{item.id}</span>
            },
            {
                heading: 'Title',
                sortExpression: 'title',
                className: 'd-none d-md-table-cell',
                cellTemplate: (item, index) => <span>{item.title}</span>
            },
            {
                heading: 'Name',
                sortExpression: 'lastName,firstName',
                className: 'd-none d-md-table-cell',
                cellTemplate: (item, index) => (
                    <Button
                        className="btn-link"
                        button={page.buildEditUserButton(item)} />
                )
            },
            {
                heading: 'Company',
                sortExpression: 'vendorCompany',
                className: 'd-none d-md-table-cell',
                cellTemplate: (item, index) => <span>{item.vendorCompany}</span>
            },
            {
                heading: 'Phone',
                sortExpression: 'phone',
                className: 'd-none d-md-table-cell',
                cellTemplate: (item, index) => <span>{item.phone}</span>
            },
            {
                heading: 'Email',
                sortExpression: 'email',
                className: 'd-none d-md-table-cell',
                cellTemplate: (item, index) => <span>{item.email}</span>
            }
        ];
    }

    public render({ page }: VendorUserListPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    heading="All Vendor Users"
                    headingRightContent={
                        <div class="button-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.newVendorUserButton} />
                        </div>
                    }>

                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()}
                        listOptionSlideContainer={page.listOptionSlideContainer} />
                </Card>
            </div>
        );
    }
}

export default VendorUserListPage;
