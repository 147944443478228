import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { Icons } from 'core.frontend';
import IconProps from './iconProps';

class Icon extends ObservingComponent<IconProps> {

    public render({ icon, title, className }: IconProps): JSX.Element {

        switch (icon) {
            case Icons.Close:
                return <i
                    title={title}
                    class={className + ' ti ti-times '}></i>;

            case Icons.Trash:
                return <i
                    title={title}
                    class={className + ' ti ti-trash '} ></i>;

            case Icons.User:
                return <i
                    title={title}
                    class={className + ' ti ti-user '} ></i>;

            case Icons.Logout:
                return <i
                    title={title}
                    class={className + ' ti ti-sign-out-alt '} ></i>;

            case Icons.ChevronLeft:
                return <i
                    title={title}
                    class={className + ' ti ti-chevron-left '} ></i>;

            case Icons.ChevronDown:
                return <i
                    title={title}
                    class={className + ' ti ti-chevron-down '} ></i>;

            case Icons.ChevronUp:
                return <i
                    title={title}
                    class={className + ' ti ti-chevron-up '} ></i>;

            case Icons.Alert:
                return <i
                    title={title}
                    class={className + ' ti ti-exclamation-circle '} ></i>;

            case Icons.Minus:
                return <i
                    title={title}
                    class={className + ' ti ti-minus '} ></i>;

            case Icons.Plus:
                return <i
                    title={title}
                    class={className + ' ti ti-plus '} ></i>;

            case Icons.Filter:
                return <i
                    title={title}
                    class={className + ' ti ti-filter '} ></i>;

            case Icons.Phone:
                return <i
                    title={title}
                    class={className + ' ti ti-phone '} ></i>;

            case Icons.Email:
                return <i
                    title={title}
                    class={className + ' ti ti-email '} ></i>;

            case Icons.Expand:
                return <i
                    title={title}
                    class={className + ' ti ti-expand '} ></i>;

            case Icons.Compress:
                return <i
                    title={title}
                    class={className + ' ti ti-compress '} ></i>;

            case Icons.Edit:
                return <i
                    title={title}
                    class={className + ' ti ti-edit '} ></i>;

            case Icons.Refresh:
                return <i
                    title={title}
                    class={className + ' ti ti-refresh '} ></i>;

            case Icons.Camera:
                return <i
                    title={title}
                    class={className + ' ti ti-camera '} ></i>;

            case Icons.Clock:
                return <i
                    title={title}
                    class={className + ' ti ti-clock '} ></i>;

            case Icons.Gear:
                return <i
                    title={title}
                    class={className + ' ti ti-gear '} ></i>;

            case Icons.Download:
                return <i
                    title={title}
                    class={className + ' ti ti-download '} ></i>;

            case Icons.Checked:
                return <i
                    title={title}
                    class={className + ' ti ti-checked '} ></i>;

            case Icons.Unchecked:
                return <i
                    title={title}
                    class={className + ' ti ti-unchecked '} ></i>;

            case Icons.Duplicate:
                return <i
                    title={title}
                    class={className + ' ti ti-duplicate '} ></i>;

            case Icons.Key:
                return <i
                    title={title}
                    class={className + ' ti ti-key '} ></i>;

            case Icons.SortUp:
                return <i
                    title={title}
                    class={className + ' ti ti-chevron-up '} ></i>;

            case Icons.SortDown:
                return <i
                    title={title}
                    class={className + ' ti ti-chevron-down '} ></i>;

            case Icons.Hamburger:
                return <i
                    title={title}
                    class={className + ' ti ti-hamburger '} ></i>;

            case Icons.ChevronRight:
                return <i
                    title={title}
                    class={className + ' ti ti-chevron-right '} ></i>;

            case Icons.Calendar:
                return <i
                    title={title}
                    class={className + ' ti ti-calendar '} ></i>;

            case Icons.Check:
                return <i
                    title={title}
                    class={className + ' ti ti-check '} ></i>;

            case Icons.Image:
                return <i
                    title={title}
                    class={className + ' ti ti-image '} ></i>;

            case Icons.Pdf:
                return <i
                    title={title}
                    class={className + ' ti ti-file-pdf '} ></i>;

            case Icons.Word:
                return <i
                    title={title}
                    class={className + ' ti ti-file-word '} ></i>;

            case Icons.Excel:
                return <i
                    title={title}
                    class={className + ' ti ti-file-excel '} ></i>;

            case Icons.PowerPoint:
                return <i
                    title={title}
                    class={className + ' ti ti-file-powerpoint '} ></i>;

            case Icons.File:
                return <i
                    title={title}
                    class={className + ' ti ti-file '} ></i>;

            case Icons.List:
                return <i
                    title={title}
                    class={className + ' ti ti-list '}></i>;

            case Icons.UserAlt:
                return <i
                    title={title}
                    class={className + ' ti ti-user-circle'}></i>;

            case Icons.BusinessCard:
                return <i
                    title={title}
                    class={className + ' ti ti-id-card '}></i>;

            case Icons.UserGroup:
                return <i
                    title={title}
                    class={className + ' ti ti-user-group '}></i>;

            case Icons.Notification:
                return <i
                    title={title}
                    class={className + ' ti ti-bell'}></i>;

            case Icons.Info:
                return <i
                    title={title}
                    class={className + ' ti ti-info-circle'}></i>;

            case Icons.Eye:
                return <i
                    title={title}
                    class={className + ' ti ti-eye'}></i>;

            case Icons.EyeSlash:
                return <i
                    title={title}
                    class={className + ' ti ti-eye-slash'}></i>;

            case Icons.Question:
                return <i
                    title={title}
                    class={className + ' ti ti-question'}></i>;

            case Icons.Meeting:
                return <i
                    title={title}
                    class={className + ' ti ti-meeting'}></i>;

            case Icons.Report:
                return <i
                    title={title}
                    class={className + ' ti ti-report'}></i>;

            default:
                return null;
        }
    }
}

export default Icon;
