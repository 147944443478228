"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var button_1 = require("../../../components/button/button");
var helpButton_1 = require("../../../components/button/helpButton");
var helpUrls_1 = require("../../helpUrls");
var eventTypes_1 = require("../eventTypes");
var NewEventPage = /** @class */ (function (_super) {
    __extends(NewEventPage, _super);
    function NewEventPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.NewEvent, 'New Event') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.goToNewlyCreatedEvent = function (eventId) {
            _this.navigator.goToEventConfiguration(eventId);
        };
        _this._eventInfoForm = new __1.EventInfoForm(api);
        _this._eventInfoForm.setModel({
            eventType: eventTypes_1.default.Tradeshow,
            webLinks: [],
            eventLeads: [],
            exhibitSpaces: []
        });
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.NewEventPage, null, navigator, api);
        _this._eventInfoForm.observationProvider.observe(_this.goToNewlyCreatedEvent, __1.EventInfoFormActions.FormSaved);
        _this.cancelButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.navigator.goToEventList
        });
        _this.saveButton = new button_1.default({
            text: 'Save',
            onClick: _this._eventInfoForm.submit
        });
        return _this;
    }
    Object.defineProperty(NewEventPage.prototype, "eventInfoForm", {
        get: function () {
            return this._eventInfoForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEventPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    return NewEventPage;
}(__1.BasePage));
exports.default = NewEventPage;
