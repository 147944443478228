"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../..");
var button_1 = require("../../../../components/button/button");
var utils_1 = require("../../../../utils");
var AttendeeDataGridPage = /** @class */ (function (_super) {
    __extends(AttendeeDataGridPage, _super);
    function AttendeeDataGridPage(api, navigator, currentUserManager, reportOptions) {
        var _this = _super.call(this, __1.PageTypes.AttendeeDataGridReport, 'Attendee Data Grid Report') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.reportOptions = reportOptions;
        _this._rowHeightInterval = 5;
        _this.loadReport = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, i;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isLoading = true;
                        _a = this;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.EventReports + "/AttendeeDataGridReport", this.reportOptions)];
                    case 1:
                        _a._reportData = _b.sent();
                        for (i = 0; i < this._reportData.reportDataItems.length; i++) {
                            this._reportData.reportDataItems[i].startDateTime = utils_1.DateUtils.CreateDateMaintainTimezone(this._reportData.reportDataItems[i].startDateTime);
                            this._reportData.reportDataItems[i].endDateTime = utils_1.DateUtils.CreateDateMaintainTimezone(this._reportData.reportDataItems[i].endDateTime);
                        }
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.AttendeeDataGridPageActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getMeetings = function (attendee, date) {
            return _this.reportData.reportDataItems.filter(function (m) {
                return m.eventAttendeeId === attendee.eventAttendeeId &&
                    m.startDateTime.getMonth() === date.getMonth() &&
                    m.startDateTime.getDate() === date.getDate();
            });
        };
        _this.buildReportDates = function () {
            _this._reportDates = [];
            var currentDate = new Date(_this.reportOptions.startDate);
            while (currentDate <= _this.reportOptions.endDate) {
                _this._reportDates.push(new Date(currentDate));
                currentDate = utils_1.DateUtils.addDays(currentDate, 1);
            }
        };
        _this.buildGridTimes = function () {
            var endGridDateTime = new Date('2000-01-01T' + _this.endGridTime);
            var firstGridDateTime = new Date('2000-01-01T' + _this.firstGridTime);
            _this._gridTimes = [];
            for (var currentDateTime = new Date(firstGridDateTime); currentDateTime < endGridDateTime; currentDateTime = utils_1.DateUtils.addMinutes(currentDateTime, 60)) {
                var hourString = _this.getPaddedHourString(currentDateTime.getHours()) + '00';
                _this._gridTimes.push(hourString);
                hourString = _this.getPaddedHourString(currentDateTime.getHours()) + '30';
                _this._gridTimes.push(hourString);
            }
        };
        _this.getPaddedHourString = function (hour) {
            var hourString = '';
            if (hour < 10) {
                hourString += '0' + hour.toString();
            }
            else {
                hourString += hour.toString();
            }
            return hourString;
        };
        _this.backButton = new button_1.default({
            leftIcon: __1.Icons.ChevronLeft,
            onClick: _this.navigator.goBack,
            text: 'Back',
            isVisible: currentUserManager.clientChecker.isMobileApp()
        });
        _this.observationProvider = new __1.ObservationProvider();
        reportOptions.startDate = new Date(reportOptions.startDate);
        reportOptions.endDate = new Date(reportOptions.endDate);
        _this.includeVenueCheckbox = new __1.CheckBoxField('includeVenue', 'Include assigned venues', false, null, reportOptions.includeVenue);
        _this.includeVenueCheckbox.observationProvider.observe(function () { return _this.observationProvider.notifyObservers(__1.AttendeeDataGridPageActions.IncludeVenueChanged); }, __1.FieldActions.ValueChanged);
        _this.includeMeetingTimesCheckbox = new __1.CheckBoxField('includeMeetingTimes', 'Include meeting times', false, null, reportOptions.includeMeetingTimes);
        _this.includeMeetingTimesCheckbox.observationProvider.observe(function () { return _this.observationProvider.notifyObservers(__1.AttendeeDataGridPageActions.IncludeMeetingTimesChanged); }, __1.FieldActions.ValueChanged);
        _this.includeMeetingNumberCheckbox = new __1.CheckBoxField('includeMeetingNumber', 'Include meeting number', false, null, reportOptions.includeMeetingNumber);
        _this.includeMeetingNumberCheckbox.observationProvider.observe(function () { return _this.observationProvider.notifyObservers(__1.AttendeeDataGridPageActions.IncludeMeetingNumberChanged); }, __1.FieldActions.ValueChanged);
        // must be in hh:mm format
        _this.firstGridTime = reportOptions.startTime;
        _this.endGridTime = reportOptions.endTime;
        _this.buildGridTimes();
        _this.buildReportDates();
        _this.loadReport();
        _this._rowHeight = 45;
        _this.increaseRowHeightButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            onClick: function () {
                _this._rowHeight += _this._rowHeightInterval;
                _this.observationProvider.notifyObservers(__1.AttendeeDataGridPageActions.SizeChanged);
            }
        });
        _this.decreaseRowHeightButton = new button_1.default({
            leftIcon: __1.Icons.Minus,
            onClick: function () {
                if (_this._rowHeight > _this._rowHeightInterval) {
                    _this._rowHeight -= _this._rowHeightInterval;
                    _this.observationProvider.notifyObservers(__1.AttendeeDataGridPageActions.SizeChanged);
                }
            }
        });
        return _this;
    }
    Object.defineProperty(AttendeeDataGridPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "reportDates", {
        get: function () {
            return this._reportDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "reportData", {
        get: function () {
            return this._reportData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "currentUsersName", {
        get: function () {
            if (!this.currentUserManager.userFullName) {
                return 'Anonymous';
            }
            return this.currentUserManager.userFullName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "includeMeetingTimes", {
        get: function () {
            return this.includeMeetingTimesCheckbox && this.includeMeetingTimesCheckbox.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "includeVenue", {
        get: function () {
            return this.includeVenueCheckbox && this.includeVenueCheckbox.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "includeMeetingNumber", {
        get: function () {
            return this.includeMeetingNumberCheckbox && this.includeMeetingNumberCheckbox.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "rowHeight", {
        get: function () {
            return this._rowHeight;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttendeeDataGridPage.prototype, "gridTimes", {
        get: function () {
            return this._gridTimes;
        },
        enumerable: true,
        configurable: true
    });
    return AttendeeDataGridPage;
}(__1.BasePage));
exports.default = AttendeeDataGridPage;
