import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import TenantDetailPageProps from './tenantDetailPageProps';
import Field from '../fields/field';
import FileInputField from '../fields/fileInputField';
import FormButtonContainer from '../containers/formButtonContainer';
import ButtonColors from '../buttons/buttonColors';
import Button from '../buttons/button';
import Alert from '../alerts/alert';

class TenantDetailPage extends ObservingComponent<TenantDetailPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: TenantDetailPageProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Alert alert={page.alert} />

                <div class="form-container">
                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[0]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[1]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[2]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[3]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[4]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[5]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[9]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[10]} />

                    <Field
                        isLoading={page.isLoading}
                        field={page.form.fields[14]} />

                    <div class="mt-2 mb-2">
                        <div class="flex">
                            <div class="fill">
                                <b>System Administrator</b>
                            </div>
                        </div>

                        <hr/>

                        <Field
                            isLoading={page.isLoading}
                            field={page.form.fields[6]} />

                        <Field
                            isLoading={page.isLoading}
                            field={page.form.fields[7]} />

                        <Field
                            isLoading={page.isLoading}
                            field={page.form.fields[8]} />
                    </div>

                    <div class="mt-2 mb-2">
                        <div class="flex">
                            <div class="v-center">
                                <b>Login Alerts</b>
                            </div>
                            <div class="v-center ml-auto text-sm">
                                Login alerts can be set to present a user with a message for a specified number of times that they login.
                                Changing the message or the enabled status of these alerts will reset the view count for each user.
                            </div>
                        </div>

                        <hr/>

                        <Field
                            isLoading={page.isLoading}
                            field={page.form.fields[11]} />

                        <Field
                            isLoading={page.isLoading}
                            field={page.form.fields[12]} />

                        <Field
                            isLoading={page.isLoading}
                            field={page.form.fields[13]} />
                    </div>

                    {!page.isLoading &&
                        <div class="mt-2 mb-2">
                            <div class="flex">
                                <div class="v-center fill">
                                    <b>Header Logo Image</b>
                                </div>

                                <FileInputField
                                    field={page.headerLogoImageFileInput}
                                    buttonClassName="btn btn-sm btn-primary" />
                            </div>

                            <hr/>

                            <div
                                class="header-logo-image-preview"
                                style={`background-image: url(${page.form.model.headerLogoImageUrl})`}>
                            </div>
                        </div>
                    }

                </div>

                <FormButtonContainer
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.cancelButton} />,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton} />
                    ]} />
            </div>
        );
    }
}

export default TenantDetailPage;
