"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DropdownActions;
(function (DropdownActions) {
    DropdownActions["Opened"] = "Opened";
    DropdownActions["Closed"] = "Closed";
    DropdownActions["SelectedChanged"] = "SelectedChanged";
    DropdownActions["ItemsSet"] = "ItemsSet";
    DropdownActions["VisibilityChanged"] = "VisibilityChanged";
})(DropdownActions || (DropdownActions = {}));
exports.default = DropdownActions;
