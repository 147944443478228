import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import DropdownProps from './dropdownProps';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';

class Dropdown extends ObservingComponent<DropdownProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.dropdown.observationProvider);
        window.addEventListener('click', this.props.dropdown.close);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.props.dropdown.close);
    }

    public render(): JSX.Element {
        const { dropdown, className, textClassName, buttonClassName } = this.props;

        let wrapperClass = 'dropdown-nav ';
        if (className) {
            wrapperClass += className;
        }

        return (
            <div class={wrapperClass}>

                <Button
                    className={"btn-icon " + buttonClassName}
                    textClassName={textClassName}
                    stopPropagation={true}
                    color={ButtonColors.Secondary}
                    button={dropdown.dropdownButton} />

                <div class="dropdown-wrapper">
                    <ul class={"dropdown " + (dropdown.isOpen ? ' open' : '')}>
                        {dropdown.dropdownItems.map((item) => {

                            const btn = dropdown.buildItemButton(item);

                            return (
                                <li class={item.isSeparator ? 'seperator-top' : ''}>
                                    {btn && <Button button={btn} />}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Dropdown;
