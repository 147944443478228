import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import ModalProps from './modalProps';
import { Icons } from 'core.frontend';
import Icon from '../icons/icon';

class Modal extends ObservingComponent<ModalProps> {

    constructor(props: ModalProps) {
        super(props);
    }

    private modalClicked = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    private handleCloseClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.close();
    }

    public render({ heading, children, footer, className, contentClassName, footerLeftButtons, footerRightButtons, headerButtons, hideBackButton, footerClassName }: ModalProps): JSX.Element {

        return (
            <div
                onClick={this.modalClicked}
                class={'modal-container ' + className}>

                <div class="modal-content">
                    <header>
                        {!hideBackButton &&
                            <a
                                id={'modalCloseButton'}
                                class="btn button inline-flex v-center mr-1 btn-white btn-icon"
                                onClick={this.handleCloseClick}>

                                <span class="btn-content">
                                    <Icon icon={Icons.ChevronLeft} />
                                </span>
                            </a>
                        }
                        <span>{heading}</span>

                        {headerButtons && headerButtons.map((button) => button )}
                    </header>

                    <div class={'content pl-1 pr-1 pb-1 ' +
                                (!!(footer || footerLeftButtons || footerRightButtons) ? 'has-footer ' : '') +
                                contentClassName}>
                        {children}
                    </div>

                    {(footer || footerLeftButtons || footerRightButtons) &&
                        <div class={"modal-footer " + footerClassName}>
                            {footer}


                            {(footerLeftButtons || footerRightButtons) &&
                                <div class="flex fill">
                                    {footerLeftButtons &&
                                        <div class="fill flex">
                                            {footerLeftButtons.map((button) => button)}
                                        </div>
                                    }

                                    {(!footerLeftButtons || !footerLeftButtons.length) &&
                                        <div class="fill"></div>
                                    }

                                    {footerRightButtons &&
                                        <div>
                                            {footerRightButtons.map((button) => button)}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>

                <div class="modal-background"> </div>
            </div>
        )
    }
}

export default Modal;
