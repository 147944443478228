import { h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import FulfillmentFormProps from './fulfillmentFormProps';
import Modal from '../../containers/modal';
import FormattedDate from '../../text/formattedDate';
import FormattedTimespan from '../../text/formattedTimespan';
import { DateFormats, AccommodationHotelBookingType, VendorFulfillmentFormPanes } from 'core.frontend';
import ArrivalDepartureType from '../../events/arrivalDepartureType';
import Field from '../../fields/field';
import ButtonColors from '../../buttons/buttonColors';
import Button from '../../buttons/button';
import ButtonSizes from '../../buttons/buttonSizes';
import EmailButton from '../../buttons/emailButton';
import PhoneButton from '../../buttons/phoneButton';
import Alert from '../../alerts/alert';
import HistoryTable from '../../history/historyTable';


class FulfillmentForm extends ObservingComponent<FulfillmentFormProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.fulfillmentForm.observationProvider);
    }

    public render({ fulfillmentForm }: FulfillmentFormProps): JSX.Element {

        if (!fulfillmentForm.isOpen) {
            return null;
        }

        return (
            <Modal
                heading={"Hotel & Transportation Fulfillment"}
                close={fulfillmentForm.hide}
                headerButtons={[
                    <Button
                        color={ButtonColors.White}
                        className="btn-icon btn-icon-stacked"
                        button={fulfillmentForm.historyButton} />,
                ]}
                footerRightButtons={[
                    <Button
                        color={ButtonColors.Secondary}
                        button={fulfillmentForm.closeButton} />,
                    <Button
                        color={ButtonColors.Primary}
                        button={fulfillmentForm.saveButton} />
                ]} >

                <div class="pt-1">
                    {!fulfillmentForm.historyTableIsVisible &&
                        <div>

                            <Alert alert={fulfillmentForm.alert} />

                            {fulfillmentForm.isLoading && <span>Loading...</span>}

                            {!fulfillmentForm.isLoading &&
                                <div>
                                    <div class="flex">
                                        <div class="fill">
                                            <div>
                                                <b>{fulfillmentForm.fulfillmentData.fullName}</b>
                                            </div>

                                            <div class="flex">
                                                <div class="text-sm fill v-center">
                                                    <span>{fulfillmentForm.fulfillmentData.businessArea}</span>

                                                    <span>&nbsp;/&nbsp;</span>

                                                    <span><a href={'mailTo:' + fulfillmentForm.contactEmail}>{fulfillmentForm.contactEmail}</a></span>

                                                    <span>&nbsp;/&nbsp;</span>
                                                    <span>{fulfillmentForm.contactPhone}</span>
                                                </div>

                                                <div class="v-center">
                                                    <PhoneButton phoneNumber={fulfillmentForm.contactPhone} />
                                                    <EmailButton emailAddress={fulfillmentForm.contactEmail} />
                                                </div>
                                            </div>
                                        </div>

                                        {fulfillmentForm.vendorFulfillmentFormPanes === VendorFulfillmentFormPanes.Hotel &&
                                         fulfillmentForm.fulfillmentData.hotelEnteredByFullName &&
                                            <div class="ml-1 mt-auto fill">
                                                <div>
                                                    <span class="text-sm">
                                                        Entered By: {fulfillmentForm.fulfillmentData.hotelEnteredByFullName}
                                                    </span>

                                                    <div class="flex">
                                                        <div class="text-sm fill v-center">
                                                            <span>{fulfillmentForm.fulfillmentData.hotelEnteredByBusinessArea}</span>

                                                            <span>&nbsp;/&nbsp;</span>

                                                            <span>
                                                                <a href={'mailTo:' + fulfillmentForm.fulfillmentData.hotelEnteredByEmail}>{fulfillmentForm.fulfillmentData.hotelEnteredByEmail}</a>
                                                            </span>


                                                            <span>&nbsp;/&nbsp;</span>
                                                            <span>{fulfillmentForm.fulfillmentData.hotelEnteredByPhone}</span>
                                                        </div>

                                                        <div class="v-center">
                                                            <PhoneButton phoneNumber={fulfillmentForm.fulfillmentData.hotelEnteredByPhone} />
                                                            <EmailButton emailAddress={fulfillmentForm.fulfillmentData.hotelEnteredByEmail} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {fulfillmentForm.vendorFulfillmentFormPanes === VendorFulfillmentFormPanes.Transportation &&
                                         fulfillmentForm.fulfillmentData.transportationEnteredByFullName &&
                                            <div class="ml-1 mt-auto fill">
                                                <div>
                                                    <span class="text-sm">
                                                        Enterend By: {fulfillmentForm.fulfillmentData.transportationEnteredByFullName}
                                                    </span>

                                                    <div class="flex">
                                                        <div class="text-sm fill v-center">
                                                            <span>{fulfillmentForm.fulfillmentData.transportationEnteredByBusinessArea}</span>

                                                            <span>&nbsp;/&nbsp;</span>

                                                            <span>
                                                                <a href={'mailTo:' + fulfillmentForm.fulfillmentData.transportationEnteredByEmail}>{fulfillmentForm.fulfillmentData.transportationEnteredByEmail}</a>
                                                            </span>


                                                            <span>&nbsp;/&nbsp;</span>
                                                            <span>{fulfillmentForm.fulfillmentData.transportationEnteredByPhone}</span>
                                                        </div>

                                                        <div class="v-center">
                                                            <PhoneButton phoneNumber={fulfillmentForm.fulfillmentData.transportationEnteredByPhone} />
                                                            <EmailButton emailAddress={fulfillmentForm.fulfillmentData.transportationEnteredByEmail} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <hr />

                                    <div class="btn-group mt-1 flex">
                                        <Button
                                            size={ButtonSizes.Small}
                                            color={ButtonColors.Primary}
                                            button={fulfillmentForm.showHotelPaneButton}
                                            className={' fill ' + (fulfillmentForm.vendorFulfillmentFormPanes === VendorFulfillmentFormPanes.Hotel ? 'active' : '')} />
                                        <Button
                                            size={ButtonSizes.Small}
                                            color={ButtonColors.Primary}
                                            button={fulfillmentForm.showTransportationPaneButton}
                                            className={' fill ' + (fulfillmentForm.vendorFulfillmentFormPanes === VendorFulfillmentFormPanes.Transportation ? 'active' : '')} />
                                    </div>

                                    <div class="grid-container text-sm">
                                        {fulfillmentForm.fulfillmentData.arrivalInfoSubmitted &&
                                            <div class="md-6 mt-1">

                                                <div class="flex">
                                                    <div class="fill text-right bold-label">Arrival</div>
                                                    <div class="fill"></div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Date:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>
                                                            <FormattedDate
                                                                date={fulfillmentForm.fulfillmentData.arrivalDate}
                                                                format={DateFormats.MMMdYYYY} />
                                                        </b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Time:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>
                                                            <FormattedTimespan timespan={fulfillmentForm.fulfillmentData.arrivalTime} />
                                                        </b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Travel Type:&nbsp;</div>
                                                    <div class="fill">
                                                        <b><ArrivalDepartureType type={fulfillmentForm.fulfillmentData.arrivalType} /></b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Carrier/Num:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>{fulfillmentForm.fulfillmentData.arrivalCarrier} / {fulfillmentForm.fulfillmentData.arrivalNumber}</b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Origin:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>{fulfillmentForm.fulfillmentData.arrivalOrigin}</b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Destination:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>{fulfillmentForm.fulfillmentData.arrivalDestination}</b>
                                                    </div>
                                                </div>

                                                {fulfillmentForm.fulfillmentData.arrivalComments &&
                                                    <div class="flex">
                                                        <div class="fill text-right">Comments:&nbsp;</div>
                                                        <b class="fill">{fulfillmentForm.fulfillmentData.arrivalComments}</b>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {!fulfillmentForm.fulfillmentData.arrivalInfoSubmitted &&
                                            <div class="md-6 mt-2 text-center">
                                                <i>Arrival Information Not Yet Submitted</i>
                                            </div>
                                        }

                                        {fulfillmentForm.fulfillmentData.departureInfoSubmitted &&
                                            <div class="md-6 mt-1">
                                                <div class="flex">
                                                    <div class="fill text-right bold-label">Departure</div>
                                                    <div class="fill"></div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Date:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>
                                                            <FormattedDate
                                                                date={fulfillmentForm.fulfillmentData.departureDate}
                                                                format={DateFormats.MMMdYYYY} />
                                                        </b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Time:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>
                                                            <FormattedTimespan timespan={fulfillmentForm.fulfillmentData.departureTime} />
                                                        </b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Travel Type:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>
                                                            <ArrivalDepartureType type={fulfillmentForm.fulfillmentData.departureType} />
                                                        </b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Carrier/Num:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>{fulfillmentForm.fulfillmentData.departureCarrier} / {fulfillmentForm.fulfillmentData.departureNumber}</b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Origin:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>{fulfillmentForm.fulfillmentData.departureOrigin}</b>
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right">Destination:&nbsp;</div>
                                                    <div class="fill">
                                                        <b>{fulfillmentForm.fulfillmentData.departureDestination}</b>
                                                    </div>
                                                </div>

                                                {fulfillmentForm.fulfillmentData.departureComments &&
                                                    <div class="flex">
                                                        <div class="fill text-right">Comments:&nbsp;</div>
                                                        <b class="fill">{fulfillmentForm.fulfillmentData.departureComments}</b>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {!fulfillmentForm.fulfillmentData.arrivalInfoSubmitted &&
                                            <div class="md-6 mt-2 text-center">
                                                <i>Departure Information Not Yet Submitted</i>
                                            </div>
                                        }
                                    </div>

                                    {fulfillmentForm.vendorFulfillmentFormPanes === VendorFulfillmentFormPanes.Transportation &&
                                        <div>
                                            <div>
                                                <div class="flex mt-2">
                                                    <div class="fill text-right mr-1 bold-label">Transportation Info</div>
                                                    <div class="fill"></div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right mr-1 v-center">Status:</div>
                                                    <Field
                                                        className="fill no-label"
                                                        field={fulfillmentForm.transportationStatusField} />
                                                </div>

                                                {fulfillmentForm.fulfillmentData.companyProvidedDailyTransportRequired &&
                                                    <div>
                                                        <div class="flex mt-2">
                                                            <div class="fill text-right mr-1 bold-label">Type:</div>
                                                            <div class="fill">Company Provided Shuttle</div>
                                                        </div>

                                                        <div class="flex">
                                                            <div class="fill text-right mr-1 bold-label">Dates:</div>
                                                            <div class="fill">
                                                                <div>
                                                                    <FormattedDate
                                                                        date={fulfillmentForm.fulfillmentData.companyProvidedDailyTransportStartDate}
                                                                        format={DateFormats.MMMdYYYY} />

                                                                    <span> thru </span>

                                                                    <FormattedDate
                                                                        date={fulfillmentForm.fulfillmentData.companyProvidedDailyTransportEndDate}
                                                                        format={DateFormats.MMMdYYYY} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {fulfillmentForm.fulfillmentData.rentalCarRequired &&
                                                    <div>
                                                        <div class="flex mt-2">
                                                            <div class="fill text-right mr-1 bold-label">Type:</div>
                                                            <div class="fill">Individual Transport</div>
                                                        </div>

                                                        <div class="flex">
                                                            <div class="fill text-right mr-1 bold-label"></div>
                                                            <div class="fill">

                                                                {fulfillmentForm.fulfillmentData.rentalCarVehicleId &&
                                                                    <div>
                                                                        <div>
                                                                            {fulfillmentForm.fulfillmentData.rentalCarVehicle}

                                                                            {fulfillmentForm.fulfillmentData.rentalCarPrice &&
                                                                                <span> ({fulfillmentForm.fulfillmentData.rentalCarPrice})</span>
                                                                            }
                                                                        </div>

                                                                        <FormattedDate
                                                                            date={fulfillmentForm.fulfillmentData.rentalCarStartDate}
                                                                            format={DateFormats.MMMdYYYY} />

                                                                        <span> thru </span>

                                                                        <FormattedDate
                                                                            date={fulfillmentForm.fulfillmentData.rentalCarEndDate}
                                                                            format={DateFormats.MMMdYYYY} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {fulfillmentForm.fulfillmentData.rentalCarSharingWithEventAttendee &&
                                                    <div>
                                                        <div class="flex mt-2">
                                                            <div class="fill text-right mr-1">Sharing Daily Tranposrt With:</div>
                                                            <div class="fill">
                                                                {fulfillmentForm.fulfillmentData.rentalCarSharingWithEventAttendee}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div class="flex">
                                                    <div class="fill text-right mr-1">Attendee Comments:</div>
                                                    <div class="fill">
                                                        {!!fulfillmentForm.fulfillmentData.dailyTransportComments &&
                                                            <span>{fulfillmentForm.fulfillmentData.dailyTransportComments}</span>
                                                        }

                                                        {!fulfillmentForm.fulfillmentData.dailyTransportComments &&
                                                            <i>None</i>
                                                        }
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right mr-1">Arrival Transport Needed:</div>
                                                    <div class="fill">
                                                        {fulfillmentForm.fulfillmentData.airportToHotelTransportRequired === true &&
                                                            <span class="text-green">YES</span>
                                                        }

                                                        {fulfillmentForm.fulfillmentData.airportToHotelTransportRequired === false &&
                                                            <span>NO</span>
                                                        }

                                                        {(fulfillmentForm.fulfillmentData.airportToHotelTransportRequired === null ||
                                                        fulfillmentForm.fulfillmentData.airportToHotelTransportRequired === undefined ) &&

                                                        <i>Unknown</i>
                                                        }
                                                    </div>
                                                </div>

                                                <div class="flex">
                                                    <div class="fill text-right mr-1">Departure Transport Needed:</div>
                                                    <div class="fill">
                                                        {fulfillmentForm.fulfillmentData.hotelToAirportTransportRequired === true &&
                                                            <span class="text-green">YES</span>
                                                        }

                                                        {fulfillmentForm.fulfillmentData.hotelToAirportTransportRequired === false &&
                                                            <span>NO</span>
                                                        }

                                                        {(
                                                            fulfillmentForm.fulfillmentData.hotelToAirportTransportRequired === null ||
                                                            fulfillmentForm.fulfillmentData.hotelToAirportTransportRequired === undefined
                                                        ) &&

                                                            <i>Unknown</i>
                                                        }
                                                    </div>
                                                </div>

                                                {fulfillmentForm.fulfillmentData.airportTransportComments &&
                                                    <div class="flex">
                                                        <div class="fill text-right mr-1">Hotel Transport Comments:</div>
                                                        <div class="fill">
                                                            {fulfillmentForm.fulfillmentData.airportTransportComments}
                                                        </div>
                                                    </div>
                                                }

                                                <div class="flex mt-1">
                                                    <div class="fill text-right mr-1">Transportation Comments:</div>
                                                    <Field
                                                        className="fill"
                                                        field={fulfillmentForm.transportationVendorCommentsField} />
                                                </div>

                                                <div class="flex mt-2">
                                                    <div class="fill text-right mr-1">Hotel:</div>
                                                    <div class="fill">
                                                        {!!fulfillmentForm.fulfillmentData.hotelName &&
                                                            <span>{fulfillmentForm.fulfillmentData.hotelName}</span>
                                                        }

                                                        {!fulfillmentForm.fulfillmentData.hotelName &&
                                                            <i>Unknown</i>
                                                        }
                                                    </div>
                                                </div>

                                                {!!fulfillmentForm.fulfillmentData.changesForTransportationConfirmation.length &&
                                                    <div class="flex mt-2">
                                                        <div class="fill text-right mr-1 text-red">Changes:</div>
                                                        <div class="fill text-sm">
                                                            {fulfillmentForm.fulfillmentData.changesForTransportationConfirmation.map((changeHistoryNote) =>
                                                                <div>{changeHistoryNote.note}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {fulfillmentForm.vendorFulfillmentFormPanes === VendorFulfillmentFormPanes.Hotel &&
                                        <div>
                                            <div class="flex mt-2">
                                                <div class="fill text-right mr-1 bold-label">Hotel Info</div>
                                                <div class="fill"></div>
                                            </div>

                                            <div class="flex">
                                                <div class="fill text-right mr-1 v-center">Status:</div>
                                                <Field
                                                    className="fill no-label"
                                                    field={fulfillmentForm.hotelStatusField} />
                                            </div>

                                            <div class="flex mt-2">
                                                <div class="fill text-right mr-1">Type:</div>
                                                <div class="fill">
                                                    {fulfillmentForm.fulfillmentData.hotelBookingType === AccommodationHotelBookingType.Own &&
                                                        <span>OWN</span>
                                                    }

                                                    {fulfillmentForm.fulfillmentData.hotelBookingType === AccommodationHotelBookingType.CompanyProvided &&
                                                        <span>Company Provided</span>
                                                    }

                                                    {
                                                        (
                                                            fulfillmentForm.fulfillmentData.hotelBookingType === null ||
                                                            fulfillmentForm.fulfillmentData.hotelBookingType === undefined
                                                        ) &&

                                                        <i>Unknown</i>
                                                    }
                                                </div>
                                            </div>

                                            <div class="flex">
                                                <div class="fill text-right mr-1">Room Dates Requested:</div>
                                                <div class="fill">

                                                    {!fulfillmentForm.fulfillmentData.hotelCheckInDate &&
                                                        <i> Unknown</i>
                                                    }

                                                    {fulfillmentForm.fulfillmentData.hotelCheckInDate &&
                                                        <FormattedDate date={fulfillmentForm.fulfillmentData.hotelCheckInDate} />
                                                    }

                                                    <span>&nbsp;thru&nbsp;</span>

                                                    {!fulfillmentForm.fulfillmentData.hotelCheckOutDate &&
                                                        <i> Unknown</i>
                                                    }

                                                    {fulfillmentForm.fulfillmentData.hotelCheckOutDate &&
                                                        <FormattedDate date={fulfillmentForm.fulfillmentData.hotelCheckOutDate} />
                                                    }
                                                </div>
                                            </div>

                                            <div class="flex">
                                                <div class="fill text-right mr-1">Early Arrival:</div>
                                                <div class="fill">
                                                    {
                                                        fulfillmentForm.fulfillmentData.hotelEarlyArrivalRequired === true ? <span class="text-green text-bold">YES</span> :
                                                        fulfillmentForm.fulfillmentData.hotelEarlyArrivalRequired === false ? <span class="text-red text-bold">NO</span> :
                                                        <i>Unknown</i>
                                                    }
                                                </div>
                                            </div>

                                            <div class="flex">
                                                <div class="fill text-right mr-1">Late Checkout:</div>
                                                <div class="fill">
                                                    {
                                                        fulfillmentForm.fulfillmentData.hotelLateCheckoutRequired === true ? <span class="text-green text-bold">YES</span> :
                                                        fulfillmentForm.fulfillmentData.hotelLateCheckoutRequired === false ? <span class="text-red text-bold">NO</span> :
                                                        <i>Unknown</i>
                                                    }
                                                </div>
                                            </div>

                                            <div class="flex">
                                                <div class="fill text-right mr-1">Number in Room:</div>
                                                <div class="fill">
                                                    {!!fulfillmentForm.fulfillmentData.hotelTotalInRoom &&
                                                        <span>{fulfillmentForm.fulfillmentData.hotelTotalInRoom}</span>
                                                    }

                                                    {!fulfillmentForm.fulfillmentData.hotelTotalInRoom &&
                                                        <i>Unknown</i>
                                                    }
                                                </div>
                                            </div>

                                            <div class="flex">
                                                <div class="fill text-right mr-1 bold-label">Attendee Comments:</div>
                                                <div class="fill">
                                                    {!!fulfillmentForm.fulfillmentData.hotelComments &&
                                                        <span>{fulfillmentForm.fulfillmentData.hotelComments}</span>
                                                    }

                                                    {!fulfillmentForm.fulfillmentData.hotelComments &&
                                                        <i>None</i>
                                                    }
                                                </div>
                                            </div>


                                            <div class="flex mt-p5">
                                                <div class="fill v-center text-right mr-1">Hotel & Room:</div>

                                                <div class="fill">
                                                    <Field
                                                        className="no-label"
                                                        field={fulfillmentForm.roomTypeField} />

                                                    <span class="text-sm">
                                                        <span>
                                                            Reward #: &nbsp;
                                                        </span>

                                                        {fulfillmentForm.fulfillmentData.hotelRewardNumber}

                                                        {!fulfillmentForm.fulfillmentData.hotelRewardNumber &&
                                                            <i>Unknown</i>
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="flex mt-p5">
                                                <div class="fill v-center text-right mr-1">Confirmation #:</div>
                                                <Field
                                                    className="fill no-label"
                                                    field={fulfillmentForm.hotelConfirmationNumberField} />
                                            </div>

                                            <div class="flex mt-p5">
                                                <div class="fill text-right mr-1">Hotel Comments:</div>
                                                <Field
                                                    className="fill no-label"
                                                    field={fulfillmentForm.hotelVendorCommentsField} />
                                            </div>

                                            {!!fulfillmentForm.fulfillmentData.changesForHotelConfirmation.length &&
                                                <div class="flex mt-2">
                                                    <div class="fill text-right mr-1 text-red">Changes:</div>
                                                    <div class="fill text-sm">
                                                        {fulfillmentForm.fulfillmentData.changesForHotelConfirmation.map((changeHistoryNote) =>
                                                            <div>{changeHistoryNote.note}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }

                    {!!fulfillmentForm.historyTableIsVisible &&
                        <Modal
                            heading="History"
                            close={fulfillmentForm.hideHistoryTable}>

                            <div class="mt-1">
                                <HistoryTable historyTable={fulfillmentForm.historyTable} />
                            </div>
                        </Modal>
                    }
                </div>
            </Modal>
        );
    }
}

export default FulfillmentForm;
