"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FieldType;
(function (FieldType) {
    FieldType[FieldType["TextBox"] = 0] = "TextBox";
    FieldType[FieldType["Password"] = 1] = "Password";
    FieldType[FieldType["Money"] = 2] = "Money";
    FieldType[FieldType["CheckBox"] = 3] = "CheckBox";
    FieldType[FieldType["DropDown"] = 4] = "DropDown";
    FieldType[FieldType["Date"] = 5] = "Date";
    FieldType[FieldType["Phone"] = 6] = "Phone";
    FieldType[FieldType["DateTime"] = 7] = "DateTime";
    FieldType[FieldType["Time"] = 8] = "Time";
    FieldType[FieldType["TextArea"] = 9] = "TextArea";
    FieldType[FieldType["SearchField"] = 10] = "SearchField";
    FieldType[FieldType["File"] = 11] = "File";
    FieldType[FieldType["Int"] = 12] = "Int";
    FieldType[FieldType["BinaryFile"] = 13] = "BinaryFile";
    FieldType[FieldType["MultiSelectDropdown"] = 14] = "MultiSelectDropdown";
    FieldType[FieldType["MultiSelectVenueDropdown"] = 15] = "MultiSelectVenueDropdown";
    FieldType[FieldType["MultiSelectBusinessAreaDropdown"] = 16] = "MultiSelectBusinessAreaDropdown";
    FieldType[FieldType["MultipleEmailField"] = 17] = "MultipleEmailField";
    FieldType[FieldType["RadioButton"] = 18] = "RadioButton";
})(FieldType || (FieldType = {}));
exports.default = FieldType;
