import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import TenantListProps from './tenantListProps';
import Card from '../containers/card';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import { TenantTableItem } from 'core.frontend';


class TenantList extends ObservingComponent<TenantListProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private buildColumns = (): SmartTableColumn<TenantTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Tenant Name',
                sortExpression: 'name',
                cellTemplate: (item, index) => <span>
                        <Button
                            className="btn-link"
                            button={page.buildEditTenantButton(item)} />

                            {page.activeTenantId == item.id &&
                                <span class="text-red"> - Currently Active</span>
                            }
                    </span>
            },
            {
                cellClassName: 'fit-content',
                cellTemplate: (item, index) => <span>
                    {page.activeTenantId != item.id &&
                        <Button
                            className="btn-link"
                            button={page.buildSetAsActiveTenantButton(item)} />
                    }
                </span>
            }
        ];
    }


    public render({ page }: TenantListProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    headingRightContent={
                        <Button
                            className="ml-auto"
                            color={ButtonColors.Primary}
                            button={page.newTenantButton} />
                    }>

                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()} />
                </Card>
            </div>
        );
    }
}

export default TenantList;
