import { h, JSX } from 'preact';
import { InventoryTableItem } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Card from '../containers/card';
import InventoryListPageProps from './inventoryListPageProps';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import ImageCarousel from '../imageCarousel/imageCarousel';
import VideoModalButton from '../buttons/videoModalButton';

class InventoryListPage extends ObservingComponent<InventoryListPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private buildColumns = (): SmartTableColumn<InventoryTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'InventoryItems.Name',
                cellTemplate: (item, index) =>
                    (<span class="flex">
                        <div class="column flex v-center text-center pr-1">
                            <Button
                                className="btn-icon"
                                color={ButtonColors.Secondary}
                                size={ButtonSizes.XLarge}
                                button={page.buildShowImageCarouselButton(item)} />

                            { item.loadingImages &&
                                <span class="text-sm">Loading...</span>
                            }

                            { !item.loadingImages &&
                                <span class="text-sm">{item.imageCount} image{item.imageCount > 1 ? 's' : ''}</span>
                            }
                        </div>

                        <div class="fill">
                            {page.table.isIndexDeleting(index) ? (
                                <span>{item.name}</span>
                            ) : (
                                <Button
                                    className="btn-link wrap"
                                    button={page.buildEditButton(item)} />
                            )}

                            {!item.isActive && (
                                <span class="text-sm text-red"> - Inactive</span>
                            )}

                            <span class="text-sm">
                                <br/>
                                <b>Line of Business: </b> {item.lineOfBusiness} | <b>Type: </b> {item.inventoryItemType}
                            </span>

                            <span class="text-sm">
                                <br/>
                                <b>Crate#: </b> {item.crateNumbers} | <b>Scale: </b> {item.scale}
                            </span>
                        </div>
                    </span>),
                appendSavingIndicator: true
            },
        ];
    }

    public render({ page }: InventoryListPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    heading="All Inventory"
                    headingRightContent={
                        <div class="button-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.newInventoryItemButton} />
                        </div>
                    }>

                    <SmartTable
                        headerClassName="d-none d-md-table-header-group"
                        table={page.table}
                        columns={this.buildColumns()}
                        listOptionSlideContainer={page.listOptionSlideContainer} />
                </Card>

                { page.imageCarousel &&
                    <div class="modal-container">
                        <div class="modal-content">
                            <ImageCarousel imageCarousel={page.imageCarousel} />
                        </div>

                        <div
                            class="modal-background"
                            onClick={page.imageCarousel.close}>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default InventoryListPage;
