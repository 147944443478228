import { h, Component, JSX } from 'preact';
import PageFooterProps from './pageFooterProps';
import Copyright from './copyright';

class PageFooter extends Component<PageFooterProps> {
    public render({ app }: PageFooterProps): JSX.Element {
        return (
            <footer>
                <Copyright app={app}></Copyright>
            </footer>
        );
    }
}

export default PageFooter;
