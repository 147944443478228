import { h, Component, JSX } from 'preact';
import { DateFormats, EventHistoryTableItem, EventStatus } from 'core.frontend';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import FormattedDate from '../text/formattedDate';
import EventHistoryPageProps from './eventHistoryPageProps';
import Card from '../containers/card';
import EventOverview from '../events/eventOverview';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';

class EventHistoryPage extends Component<EventHistoryPageProps> {

    private buildColumns = (): SmartTableColumn<EventHistoryTableItem>[] => {
        return [
            {
                heading: 'Event',
                cellTemplate: (item) =>
                (<span class="flex">

                    <span class="fill">
                        <span>{item.name}</span>

                        {item.status === EventStatus.Archived &&
                            <span class="text-red"> - Archived</span>
                        }

                        {item.status === EventStatus.Deleted &&
                            <span class="text-red"> - Deleted</span>
                        }


                        <br/>
                        <span class="text-sm">{item.city}, {item.country}</span>

                        <span class="text-sm">
                            <br/>
                            <FormattedDate
                                date={item.startDate}
                                format={DateFormats.MMMdYYYY} />

                                <span> - </span>

                            <FormattedDate
                                date={item.endDate}
                                format={DateFormats.MMMdYYYY} />
                        </span>

                    </span>

                    <span class="text-sm v-center">
                        <Button button={this.props.page.buildOverviewButton(item)} />
                    </span>
                </span>),
            }
        ];
    }

    public render({ page }: EventHistoryPageProps): JSX.Element {
        return (
            <div>
                <Card heading={
                    <h3 class="flex">
                        <Button
                            className="btn-icon"
                            color={ButtonColors.Secondary}
                            button={page.backButton} />

                        <span class="v-center ml-p5">
                            {page.title}
                        </span>
                    </h3>
                }>
                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()} />

                </Card>

                <EventOverview eventOverview={page.eventOverview} />

            </div>
        );
    }
}

export default EventHistoryPage;
