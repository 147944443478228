import { h, JSX } from 'preact';
import TagCollectionProps from './tagCollectionProps';
import Tag from './tag';
import ObservingComponent from '../componentBases/observingComponent';

class TagCollection<T> extends ObservingComponent<TagCollectionProps<T>> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tagCollection.observationProvider);
    }

    public render({ tagCollection }: TagCollectionProps<T>): JSX.Element {
        if (!tagCollection) {
            return;
        }

        return (
            <div class="tag-wrapper fill">

                {tagCollection.tags.length > 0 &&
                    <span class="tag-heading">{tagCollection.heading} </span>
                }

                {tagCollection.tags.map((tag) =>
                    <Tag
                        key={tag.uniqueId}
                        tag={tag} />
                )}

            </div>
        );
    }
}

export default TagCollection;
