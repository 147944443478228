import { h, JSX } from 'preact';
import EventInfoTile from './eventInfoTile';
import EventFilesTileProps from './eventFilesTileProps';
import ObservingComponent from '../componentBases/observingComponent';
import DataList from '../dataList/dataList';
import BinaryFileInputField from '../fields/binaryFileInputField';
import { Icons, EventFilesTileData, DataListItem, TextField } from 'core.frontend';
import Alert from '../alerts/alert';
import DownloadButtonHandlingMethod from '../buttons/downloadButtonHandlingMethod';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import FileListItem from '../dataList/fileListItem/fileListItem';

class EventFilesTile extends ObservingComponent<EventFilesTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    private getHandlingMethod(fileType: string): DownloadButtonHandlingMethod {
        const inlineFileTypes = [
            'application/pdf'
        ];

        const carouselFileTypes = [
            'image/jpeg',
            'image/png',
            'image/bmp',
            'image/jpg',
            'image/gif',
        ];

        if (inlineFileTypes.indexOf(fileType.toLowerCase()) >= 0) {
            return DownloadButtonHandlingMethod.Inline;
        } else if (carouselFileTypes.indexOf(fileType.toLowerCase()) >=0 ) {
            return DownloadButtonHandlingMethod.Carousel;
        }

        return DownloadButtonHandlingMethod.Download;
    }

    public render({ tile }: EventFilesTileProps): JSX.Element {
        return (
            tile.isVisible && <EventInfoTile
                color="darkBlue"
                tile={tile}
                icon="ti-file">

                <ConfirmationPrompt confirmationPrompt={tile.confirmationPrompt} />

                <Alert
                    className="mt-1"
                    alert={tile.alert} />

                <div class="mb-1 text-right mt-1">
                    <BinaryFileInputField
                        field={tile.fileUploadField}
                        buttonClassName="btn btn-primary"
                        leftIcon={Icons.Plus} />
                </div>

                <DataList
                    dataList={tile.fileDataList}
                    itemTemplate={(item: DataListItem<EventFilesTileData>) => (
                        <FileListItem
                            key={item.form.model.id}
                            icon={tile.getIcon(item)}
                            downloadButton={tile.buildDownloadButton(item)}
                            fileType={item.form.model.fileType}
                            isApp={tile.isApp}
                            createdDateTime={item.form.model.createdDateTime}
                            uploadedByUsersName={item.form.model.uploadedByUsersName}
                            deleteButton={tile.buildDeleteButton(item)}
                            isDownloadable={!!item.form.model.id}
                            fileName={item.form.model.fileName}
                            fileSize={item.form.model.fileSize}
                            isEditMode={tile.isEditMode}
                            friendlyNameField={item.form.fields[0] as TextField}
                            descriptionField={item.form.fields[1] as TextField} />
                    )} />
            </EventInfoTile>
        );
    }
}

export default EventFilesTile;
