"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DeviceRegistrationPageActions;
(function (DeviceRegistrationPageActions) {
    DeviceRegistrationPageActions["FormInvalid"] = "FormInvalid";
    DeviceRegistrationPageActions["FormSubmitted"] = "FormSubmitted";
    DeviceRegistrationPageActions["ErrorSubmitting"] = "ErrorSubmitting";
    DeviceRegistrationPageActions["SubmittedSuccessfully"] = "SubmittedSuccessfully";
    DeviceRegistrationPageActions["Loading"] = "Loading";
    DeviceRegistrationPageActions["FormLoaded"] = "FormLoaded";
    DeviceRegistrationPageActions["BackButtonClicked"] = "BackButtonClicked";
})(DeviceRegistrationPageActions || (DeviceRegistrationPageActions = {}));
exports.default = DeviceRegistrationPageActions;
