import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { App, AppActions } from 'core.frontend';
import Modal from '../containers/modal';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';

export type SystemNotificationProps = {
    app: App
}

class SystemNotification extends ObservingComponent<SystemNotificationProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.app.observationProvider, AppActions.SystemNotificationsUpdated);
    }

    public render({ app }: SystemNotificationProps): JSX.Element {
        if (!app.systemNotifications || !app.systemNotifications.length) {
            return <span></span>;
        }

        const messageParts = app.systemNotifications[0].notificationMessage.split('<br/>');

        return (
            <Modal
                heading={"Important Message"}
                close={app.closeSystemNotification}
                contentClassName="flex"
                footerRightButtons={[
                    <Button
                        color={ButtonColors.Primary}
                        button={app.closeSystemNotificationButton} />
                ]}>

                <div class="v-center fill text-center">
                    {messageParts.map((messagePart, index) => (
                        <Fragment>
                            {index > 0 &&
                                <br/>
                            }

                            {messagePart}
                        </Fragment>
                    ))}
                </div>
            </Modal>
        );
    }
}

export default SystemNotification;
