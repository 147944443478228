import { FieldActions, ActionUtils, CheckBoxField as FrontEndCheckBoxField } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface CheckboxFieldProps {
    id?: string;
    field: FrontEndCheckBoxField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class CheckboxField extends ObservingComponent<CheckboxFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;

        field.setValue(field.value === true ? false : true);
    }

    private clicked = (e: Event) => {
        e.stopPropagation();
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: CheckboxFieldProps): JSX.Element {

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div
                onClick={this.clicked}
                className={`checkbox ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} />}

                <label
                    className={labelClassName}
                    for={field.uniqueKey}>

                    <span class="label left-label">
                        {field.label}
                    </span>

                    <input
                        type="checkbox"
                        id={field.uniqueKey}
                        name={field.uniqueKey}
                        onFocus={field.focus}
                        onBlur={field.blur}
                        checked={field.value === true}
                        onChange={this.valueChanged}
                        className={field.isInvalid ? 'invalid' : ''}
                        disabled={field.isDisabled} />


                    <span class="label right-label">
                        {field.label}
                    </span>

                    { field.errorMessage && (
                        <span className="ml-p5 invalid">{field.errorMessage}</span>
                    )}

                </label>

            </div>
        );
    }
}

export default CheckboxField;
