import { h, JSX } from 'preact';
import TagProps from './tagProps';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';

class Tag<T> extends ObservingComponent<TagProps<T>> {

    public render({ tag }: TagProps<T>): JSX.Element {
        return (
            <span class="tag">
                {tag.label}

                <Button
                    className="btn-remove-tag"
                    button={tag.removeButton} />
            </span>
        );
    }
}

export default Tag;
