import { h, JSX } from 'preact';
import Header from '../header/header';
import Nav from '../nav/nav';
import ObservingComponent from '../componentBases/observingComponent';
import AppRootProps from './appRootProps';
import PageContentWrapper from '../pageWrapper/pageContentWrapper';
import { PageTypes, NavigatorActions, AppActions } from 'core.frontend';
import NavBackground from './navBackground';
import PageFooter from '../footer/pageFooter';
import Alert from '../alerts/alert';
import SystemNotification from './SystemNotification';
import EmailButton from '../buttons/emailButton';
import PhoneButton from '../buttons/phoneButton';
import Modal from '../containers/modal';
import HelpListModal from '../help/helpListModal';

class AppRoot extends ObservingComponent<AppRootProps> {

    public componentWillMount() {
        const { app } = this.props;

        this.registerUpdateObserver(app.navigator.observationProvider, NavigatorActions.PageChanged);
        this.registerUpdateObserver(app.observationProvider, [ AppActions.SystemAdminContactInfoHidden, AppActions.SystemAdminContactInfoLoaded, AppActions.SystemAdminContactInfoShown ]);
    }

    public render({ app }: AppRootProps): JSX.Element {

        if (app.navigator.currentPage.pageType === PageTypes.Login ||
            app.navigator.currentPage.pageType === PageTypes.RequestPasswordReset ||
            app.navigator.currentPage.pageType === PageTypes.ResetPassword ||
            app.navigator.currentPage.pageType === PageTypes.RequestAccess ||
            app.navigator.currentPage.pageType === PageTypes.CompleteAccessRequest ||
            app.navigator.currentPage.pageType === PageTypes.PasswordResetRequestText ||
            app.navigator.currentPage.pageType === PageTypes.DeviceRegistration) {
            return (
                <div>
                    <Alert
                        className="mb-0"
                        alert={app.validClientAlert} />

                    { app.getCurrentPageComponent() }

                    <HelpListModal helpListModal={app.navigator.helpListModal} />

                    <PageFooter app= {app}></PageFooter>
                </div>
            );
        }

        if (app.navigator.currentPage.pageType === PageTypes.EventOverviewReport ||
            app.navigator.currentPage.pageType === PageTypes.AttendeeReport ||
            app.navigator.currentPage.pageType === PageTypes.MeetingsReportPage ||
            app.navigator.currentPage.pageType === PageTypes.HotelReportPage ||
            app.navigator.currentPage.pageType === PageTypes.TransportationReportPage ||
            app.navigator.currentPage.pageType === PageTypes.AttendeeDataGridReport ||
            app.navigator.currentPage.pageType === PageTypes.VenueDataGridReport ||
            app.navigator.currentPage.pageType === PageTypes.GolfCartReport ||
            app.navigator.currentPage.pageType === PageTypes.DiningReport) {
            return (
                <div class="report-wrapper">
                    { app.getCurrentPageComponent() }
                </div>
            )
        }

        if (app.navigator.currentPage.pageType === PageTypes.SsoLogin ||
            app.navigator.currentPage.pageType === PageTypes.MeetingPlacard ||
            app.navigator.currentPage.pageType === PageTypes.RoomDisplayPage) {
            return (
                <div class="blank-wrapper">
                    { app.getCurrentPageComponent() }
                </div>
            );
        }

        return (
            <div>
                <Alert
                    className="mb-0"
                    alert={app.validClientAlert} />

                <Header app={app} />

                <div className="page-wrapper">
                    <Nav nav={app.nav} />
                    <NavBackground app={app} />
                    <PageContentWrapper app={app} />

                    <SystemNotification app={app} />

                    <HelpListModal helpListModal={app.navigator.helpListModal} />

                    {app.systemAdminInfoVisible &&
                        <Modal
                            className="small"
                            contentClassName="flex"
                            heading="System Contact"
                            close={app.hideSystemAdminContactInfo}>

                            <div class="fill mt-2">
                                {!app.systemAdministrator &&

                                    <div>
                                        <div className="mb-1">
                                            <div className="width-100 h-3 loading-placeholder shimmer"></div>
                                            <div className="width-100 h-3 loading-placeholder shimmer"></div>
                                        </div>

                                        <div className="width-100 h-3 loading-placeholder shimmer"></div>
                                        <div className="width-100 h-3 loading-placeholder shimmer"></div>
                                        <div className="width-100 h-3 loading-placeholder shimmer"></div>
                                    </div>
                                }

                                {app.systemAdministrator && app.systemAdministrator.name &&
                                    <div>
                                        <div className="mb-1">
                                            Here is the contact information for your company's TEMS system administrator.
                                        </div>

                                        {app.systemAdministrator.name} <br/>

                                        {app.systemAdministrator.phone &&
                                            <div class="flex">
                                                <span class="v-center mr-p5">
                                                    {app.systemAdministrator.phone}
                                                </span>

                                                <PhoneButton phoneNumber={app.systemAdministrator.phone} />
                                            </div>
                                        }

                                        <span class="v-center mr-p5">
                                            {app.systemAdministrator.email}
                                        </span>

                                        <EmailButton emailAddress={app.systemAdministrator.email} />
                                    </div>
                                }

                                <div className="mt-2">
                                    <div className="mb-1">
                                        For issues with TEMS on your computer or application.
                                    </div>

                                    David Kocurek <br/>
                                    <div class="flex">
                                        <span class="v-center mr-p5">
                                            (417) 389-5523
                                        </span>

                                        <PhoneButton phoneNumber={"(417) 389-5523"} />
                                    </div>


                                    <span class="v-center mr-p5">
                                        david@enhancedimage.com
                                    </span>

                                    <EmailButton emailAddress={"david@enhancedimage.com"} />

                                </div>

                            </div>
                        </Modal>
                    }
                </div>
            </div>
        );
    }
}

export default AppRoot;
