"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventOverviewActions;
(function (EventOverviewActions) {
    EventOverviewActions["Opened"] = "Opened";
    EventOverviewActions["DataLoaded"] = "DataLoaded";
    EventOverviewActions["Closed"] = "BackClicked";
    EventOverviewActions["Refreshing"] = "Refreshing";
    EventOverviewActions["EditAttendeeModeEnabled"] = "EditAttendeeModeEnabled";
    EventOverviewActions["EditAttendeeModeDisabled"] = "EditAttendeeModeDisabled";
    EventOverviewActions["AtteneeRemovedStatusChanged"] = "AtteneeRemovedStatusChanged";
    EventOverviewActions["AttendeeAdded"] = "AttendeeAdded";
    EventOverviewActions["Saving"] = "Saving";
    EventOverviewActions["EditInventoryRequestModeEnabled"] = "EditInventoryRequestModeEnabled";
    EventOverviewActions["EditInventoryRequestModeDisabled"] = "EditInventoryRequestModeDisabled";
    EventOverviewActions["InventoryRequestRemoveStatusChanged"] = "InventoryRequestRemoveStatusChanged";
    EventOverviewActions["InventoryRequestAdded"] = "InventoryRequestAdded";
})(EventOverviewActions || (EventOverviewActions = {}));
exports.default = EventOverviewActions;
