import { h, JSX, Component } from 'preact';
import InteractiveVenueAssignerProps from './InteractiveVenueAssignerProps';
import MeetingCreatedByLabel from './meetingCreatedByLabel';
import MeetingAttendeesLabel from './meetingAttendeesLabel';
import ObservingComponent from '../componentBases/observingComponent';
import Field from '../fields/field';
import Button from '../buttons/button';
import FormattedTimespan from '../text/formattedTimespan';
import { DateUtils, InteractiveVenueAssignerMeeting, MeetingStatuses } from 'core.frontend';
import MeetingDimension from '../events/reports/meetingDimension';

class InteractiveVenueAssigner extends ObservingComponent<InteractiveVenueAssignerProps> {

    private readonly rowHeight: number = 48;
    private readonly columnMinWidth: number = 150;

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.interactiveVenueAssigner.observationProvider);
    }

    private dropped = (e: DragEvent) => {
        e.preventDefault();

        const dropTarget = e.target as HTMLDivElement;
        const dropColumn = dropTarget.parentElement as HTMLDivElement;

        this.removeHoverClass(dropColumn);

        var data = e.dataTransfer.getData("text");

        const meetingId = parseInt(data.replace('meeting_', ''));
        const newVenueId = parseInt(dropColumn.id.replace('venue_', ''));

        this.props.interactiveVenueAssigner.changeVenue(meetingId, newVenueId);
    }

    private draggedOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    private dragOverEntered = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const dropTarget = e.target as HTMLDivElement;
        const dropColumn = dropTarget.parentElement as HTMLDivElement;

        this.addHoverClass(dropColumn);
    }

    private dragLeave = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const dropTarget = e.target as HTMLDivElement;
        const dropColumn = dropTarget.parentElement as HTMLDivElement;

        this.removeHoverClass(dropColumn);
    }

    private addHoverClass = (target: HTMLDivElement) => {
        if (!target.classList.contains(' hover')) {
            target.className = target.className += ' hover';
        }
    }

    private removeHoverClass = (target: HTMLDivElement) => {
        target.className = target.className.replace(' hover', '');
    }

    private dragStarted = (e: DragEvent) => {
        const target = e.target as HTMLDivElement;
        e.dataTransfer.setData("text", target.id);
    }

    private getFirstGridDateTime(dateStr: string) {
        const { interactiveVenueAssigner } = this.props;
        /*const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        let monthStr = month.toString();
        let dayStr = day.toString();

        if (month < 10) {
            monthStr = '0' + monthStr;
        }

        if (day < 10) {
            dayStr = '0' + dayStr;
        }
        */

        var dateParts = dateStr.split('-');
        dateParts[2] = dateParts[2].split('T')[0];

        var year = dateParts[0];
        var monthStr = dateParts[1];
        var dayStr = dateParts[2];

        if (monthStr.length < 2) {
            monthStr = '0' + monthStr;
        }

        if (dayStr.length < 2) {
            dayStr = '0' + dayStr;
        }

        const firstDateTime = new Date(`${year}-${monthStr}-${dayStr}T${interactiveVenueAssigner.timeSlots[0].substring(0,2)}:${interactiveVenueAssigner.timeSlots[0].substring(2)}`);

        return firstDateTime;
    }

    private getMeetingOffsetMinutes = (startDateTime: Date) => {
        /* calculates the number of minutes a meeting start time is before the first time on the grid */
        var offset = DateUtils.getMinutesDifference(this.getFirstGridDateTime(startDateTime.toString()), startDateTime);
        return offset > 0 ? offset : 0;
    }

    private getMeetingBlockHeight = (meeting: InteractiveVenueAssignerMeeting) => {
        let lengthInMinutes = DateUtils.getMinutesDifference(meeting.endDateTime, meeting.startDateTime);
        lengthInMinutes -= this.getMeetingOffsetMinutes(meeting.startDateTime);

        return (lengthInMinutes / 30 * this.rowHeight) - 1;
    }

    private getMeetingBlockTop = (meeting: InteractiveVenueAssignerMeeting) => {
        let minutesFromBase = DateUtils.getMinutesDifference(meeting.startDateTime, this.getFirstGridDateTime(meeting.startDateTime.toString()));
        minutesFromBase += this.getMeetingOffsetMinutes(meeting.startDateTime);

        return minutesFromBase / 30 * this.rowHeight;
    }

    private getMeetingDimensions = () => {
        const { interactiveVenueAssigner } = this.props;

        const meetingDimensions: MeetingDimension[][] = [];

        const selectedVenue = interactiveVenueAssigner.venueCheckboxes.filter((checkbox) => checkbox.value === true);

        for(let i = 0; i < selectedVenue.length; i++) {
            const venue = selectedVenue[i];

            meetingDimensions[venue.key] = [];

            const meetings = this.props.interactiveVenueAssigner.meetings.filter((meeting) => {
                var venueId = meeting.venueId;

                if (!venueId) {
                    venueId = -1;
                }

                return venueId.toString() == venue.key;
            });

            for(let j = 0; j < meetings.length; j++) {

                const meeting = meetings[j];

                const top = this.getMeetingBlockTop(meeting) + 32; // 2rem headeroffset
                const height =  this.getMeetingBlockHeight(meeting);

                meetingDimensions[venue.key].push({
                    top: top,
                    height: height,
                    left: 0,
                    right: 0,
                    bottom: top + height,
                    rightAdjacentMeetings: 0,
                    column: 0,
                    meetingId: meeting.id
                });
            }

            // sort by top then by bottom
            meetingDimensions[venue.key] = meetingDimensions[venue.key].sort((a, b) => {
                if (a.top < b.top) {
                    return -1;
                }

                if (a.top > b.top) {
                    return 1;
                }

                if (a.bottom > b.bottom) {
                    return -1;
                }

                if (a.bottom < b.bottom) {
                    return 1;
                }

                return 0;
            });

            this.setMeetingDimensionsColumnNumber(meetingDimensions[venue.key], 0);
        }

        return meetingDimensions;
    }

    private setMeetingDimensionsColumnNumber(meetingDimensions: MeetingDimension[], seedColumnNumber: number) {

        var lastMeetingBottom = null;
        var columns = [];

        for (let i = 0; i < meetingDimensions.length; i++) {
             const meetingDimension = meetingDimensions[i];

            if (lastMeetingBottom !== null && meetingDimension.top >= lastMeetingBottom) {
                this.setColumnNumber(columns);
                columns = [];
                lastMeetingBottom = null;
            }

            var placed = false;
            for (var j = 0; i < columns.length; i++) {
                var col = columns[j];

                if (!(
                    col[col.length-1].bottom > meetingDimension.top &&
                    col[col.length-1].top < meetingDimension.bottom
                )) {
                    col.push(meetingDimension);
                    placed = true;
                    break;
                }
            }

            if (!placed) {
                columns.push([meetingDimension]);
            }

            if (lastMeetingBottom === null || meetingDimension.bottom > lastMeetingBottom) {
                lastMeetingBottom = meetingDimension.bottom;
            }
        }

        if (columns.length > 0) {
            this.setColumnNumber(columns);
        }


        // for (let i = 0; i < meetingDimensions.length; i++) {
        //     const meetingDimension = meetingDimensions[i];

        //     if (meetingDimension.column > 0) {
        //         continue;
        //     }

        //     meetingDimension.column = seedColumnNumber + 1;
        //     meetingDimension.left = this.columnMinWidth * seedColumnNumber;
        //     meetingDimension.right = this.columnMinWidth * seedColumnNumber + 1;

        //     const adjacentMeetings = meetingDimensions
        //         .filter((meeting) =>
        //             meeting.meetingId != meetingDimension.meetingId &&
        //             meeting.top >= meetingDimension.top &&
        //             meeting.top < meetingDimension.bottom
        //         );

        //     this.setMeetingDimensionsColumnNumber(adjacentMeetings, seedColumnNumber + 1);

        //     if (adjacentMeetings.length > 0) {
        //         let maxAdjacentMeetings = 0;

        //         for(let j = 0; j < adjacentMeetings.length; j++) {
        //             if (adjacentMeetings[j].rightAdjacentMeetings + 1 > maxAdjacentMeetings) {
        //                 maxAdjacentMeetings += adjacentMeetings[j].rightAdjacentMeetings + 1;
        //             }
        //         }

        //         meetingDimension.rightAdjacentMeetings = Math.max(maxAdjacentMeetings, 1);
        //     } else {
        //         meetingDimension.rightAdjacentMeetings = 0;
        //     }
        // }
    }

    private setColumnNumber(columns: MeetingDimension[][]) {
        var columnCount = columns.length;
        for (var i = 0; i < columnCount; i++) {
            var col = columns[i];
            for(var j = 0; j < col.length; j++) {
                const meetingDimension = col[j];
                meetingDimension.column = i + 1;
                meetingDimension.rightAdjacentMeetings = columnCount - meetingDimension.column;
                meetingDimension.left = this.columnMinWidth * (meetingDimension.column - 1);
            }
        }
    }

    private getColumnWidths(meetingDimensions: MeetingDimension[][]) {
        const { interactiveVenueAssigner } = this.props;

        const columnWidths: number[] = [];

        const selectedVenue = interactiveVenueAssigner.venueCheckboxes.filter((checkbox) => checkbox.value === true);

        for(let i = 0; i < selectedVenue.length; i++) {
            const venue = selectedVenue[i];

            columnWidths[venue.key] = this.columnMinWidth * Math.max(...meetingDimensions[venue.key].map((dimension) => dimension.column));
        }

        return columnWidths;
    }

    private buildMeetingStyle = (meeting: InteractiveVenueAssignerMeeting, meetingDimensions: MeetingDimension[][], columnWidths: number[]) => {
        // 1px offset top
        // -3px offset bottom
        // 2rem header
        // 3 rem for each 30 mins
/*
        let startTimeOffsetHours = parseInt(this.props.interactiveVenueAssigner.form.fields[1].value.substring(0, 2));
        let startTimeOffsetMins = parseInt(this.props.interactiveVenueAssigner.form.fields[1].value.substring(3, 5));
        let startTimeOffsetMinsCount = startTimeOffsetMins + (startTimeOffsetHours * 60)

        let hours = parseInt(meeting.startTime.substring(0, 2)) - startTimeOffsetHours;
        let mins = parseInt(meeting.startTime.substring(3, 5)) - startTimeOffsetMins;
        let minsCount = hours * 60 + mins;

        let top = (minsCount * 0.1) + 2;

        let endHours = parseInt(meeting.endTime.substring(0, 2)) - startTimeOffsetHours;
        let endMins = parseInt(meeting.endTime.substring(3, 5)) - startTimeOffsetMins;
        let endMinsCount = endHours * 60 + endMins;
*/
        let venueId = meeting.venueId;
        if (!venueId) {
            venueId = -1;
        }

        const meetingDimension = meetingDimensions[venueId].find((d) => d.meetingId === meeting.id);

        return {
            //height: `calc(${(endMinsCount - minsCount) * 0.1}rem - 3px)`,
            //top: `calc(${top}rem + 1px)`
            top: meetingDimension.top + 1,
            height: Math.min(
                meetingDimension.height - 2,
                (this.rowHeight * this.props.interactiveVenueAssigner.timeSlots.length) - meetingDimension.top - 2
            ),
            left: meetingDimension.left + 1,
            width: this.columnMinWidth - 2
        };
    }

    private buildMeeting = (meeting: InteractiveVenueAssignerMeeting, meetingDimensions: MeetingDimension[][], columnWidths: number[], venueColumnNumber: number) => {
        const { interactiveVenueAssigner } = this.props;

        const style = this.buildMeetingStyle(meeting, meetingDimensions, columnWidths);

        return (
            <div
                class={'meeting ' + (meeting.status == MeetingStatuses.Working ? ' working ' : '')}
                style={{
                    top: style.top,
                    left: style.left,
                    width: style.width
                }}
                draggable={true}
                onDragStart={this.dragStarted}
                id={'meeting_' + meeting.id}>

                {interactiveVenueAssigner.meetingDetail && interactiveVenueAssigner.meetingDetail.id === meeting.id &&
                    <div class="meeting-detail-container">
                        <div class={"meeting-detail " + ((venueColumnNumber < (interactiveVenueAssigner.venueCheckboxes.length / 2)) ? 'open-right' : '')}>

                            <div class="flex">
                                <div class="fill">
                                    <b>{interactiveVenueAssigner.meetingDetail.name} </b>

                                    <span class="text-sm">(#{interactiveVenueAssigner.meetingDetail.meetingNumber})</span>
                                </div>

                                <Button
                                    className="btn-icon"
                                    button={interactiveVenueAssigner.hideMeetingDetailButton} />
                            </div>

                            {interactiveVenueAssigner.meetingDetail.assignedVenue &&
                                <div class="texts-sm">
                                    Venue: {interactiveVenueAssigner.meetingDetail.assignedVenue}
                                </div>
                            }

                            {interactiveVenueAssigner.meetingDetail.subject &&
                                <div class="flex text-sm">
                                    Subject: {interactiveVenueAssigner.meetingDetail.subject}
                                </div>
                            }

                            <div class="flex">
                                <div class="fill d-block text-sm">
                                    Head Count: {interactiveVenueAssigner.meetingDetail.totalAttendees}
                                </div>

                                <div class="d-block text-sm">
                                    <span class="text-xs">
                                        <span>Time: </span>
                                        <FormattedTimespan timespan={interactiveVenueAssigner.meetingDetail.startTime} />
                                        <span> - </span>
                                        <FormattedTimespan timespan={interactiveVenueAssigner.meetingDetail.endTime} />
                                    </span>
                                </div>
                            </div>

                            <hr />

                            <div class="d-block text-sm">
                                <div class="flex">
                                    <MeetingCreatedByLabel meeting={interactiveVenueAssigner.meetingDetail} />
                                </div>

                                <div class="d-block">
                                    <b>Venue Preference: </b>{interactiveVenueAssigner.meetingDetail.venuePreference}
                                </div>

                                <div class="d-block">
                                    <b>Meeting Category: </b>{interactiveVenueAssigner.meetingDetail.defaultMeetingCategory}
                                    {interactiveVenueAssigner.meetingDetail.scheduledBy &&
                                        <span> / {interactiveVenueAssigner.meetingDetail.scheduledBy}</span>
                                    }
                                </div>

                                <div class="d-block">
                                    <b>Food Needed: </b>{interactiveVenueAssigner.meetingDetail.foodNeeded ? 'Yes' : 'No' }
                                </div>

                                <div class="d-block">
                                    <b>Table(s): </b> {interactiveVenueAssigner.meetingDetail.tableAssignments.map((table, index) => <span>{table.name + (index + 1 < interactiveVenueAssigner.meetingDetail.tableAssignments.length ? ', ' : '')}</span>)}
                                </div>
                                <div class="d-block">
                                    <b>Attendees: </b><MeetingAttendeesLabel meeting={interactiveVenueAssigner.meetingDetail} />
                                </div>

                                <div class="d-block">
                                    <b>Customers: </b>

                                    {interactiveVenueAssigner.meetingDetail.guests.map((guest) =>
                                        <div>
                                            {!!guest.rank &&
                                                <span>{guest.rank} </span>
                                            }

                                            {!!guest.title &&
                                                <span> ({guest.title}) </span>
                                            }

                                            {guest.fullName}

                                            <span> - </span>
                                            {guest.companyName}

                                            {!!guest.isLead &&
                                                <span> (L)</span>
                                            }
                                        </div>
                                    )}

                                    {!interactiveVenueAssigner.meetingDetail.guests.length &&
                                        <i>None</i>
                                    }
                                </div>

                                <div class="d-block">
                                    <b>Comments: </b>{interactiveVenueAssigner.meetingDetail.printedComments}

                                    {!interactiveVenueAssigner.meetingDetail.printedComments &&
                                        <i>None</i>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div class="flex">
                    <div
                        style={{height: style.height}}
                        class="fill meeting-label">

                        {meeting.name} (#{meeting.meetingNumber})
                    </div>

                    <div class="mb-auto">
                        <Button
                            className="btn-icon"
                            button={interactiveVenueAssigner.buildMeetingDetailButton(meeting.id)} />
                    </div>
                </div>
            </div>
        );
    }

    private buildMeetings = (venueId: number, meetingDimensions: MeetingDimension[][], columnWidths: number[], venueColumnNuber: number) => {
        if (venueId == -1) {
            venueId = null;
        }


        return this.props.interactiveVenueAssigner.meetings.map((meeting) => {
            if (meeting.venueId == venueId) {
                return this.buildMeeting(meeting, meetingDimensions, columnWidths, venueColumnNuber);
            } else {
                return null;
            }
        });
    }

    private buildInteractiveGrid() {
        const { interactiveVenueAssigner } = this.props;
        const meetingDimensions = this.getMeetingDimensions();
        const columnWidths = this.getColumnWidths(meetingDimensions);

        return (
            <div class="flex mt-3">
                <div class="time-column">
                    <div class="header"></div>

                    {interactiveVenueAssigner.timeSlots.map((timeSlot) => (
                        <div class="time-slot">
                            <span>{timeSlot}</span>
                        </div>
                    ))}
                </div>

                {interactiveVenueAssigner.venueCheckboxes.map((venueCheckbox, index) => {

                    if (!venueCheckbox.value) {
                        return;
                    }

                    return (
                        <div
                            style={{ minWidth: columnWidths[venueCheckbox.key]}}
                            id={`venue_${venueCheckbox.key}`}
                            class="venue">

                            <div class={'header ' + (venueCheckbox.key == '-1' ? 'invert' : '')}>
                                <span>{venueCheckbox.label}</span>
                            </div>

                            {interactiveVenueAssigner.timeSlots.map(() => (
                                <div
                                    class="time-slot"
                                    onDragEnter={this.dragOverEntered}
                                    onDragLeave={this.dragLeave}
                                    onDragOver={this.draggedOver}
                                    onDropCapture={this.dropped}>
                                </div>
                            ))}

                            {this.buildMeetings(parseInt(venueCheckbox.key), meetingDimensions, columnWidths, index)}
                        </div>
                    );
                })}
            </div>
        );
    }

    public render({ interactiveVenueAssigner }: InteractiveVenueAssignerProps): JSX.Element {
        if (!interactiveVenueAssigner.isVisible) {
            return null;
        }

        return (
            <div class="interactive-room-assigner">
                <div class="form-container grid-container">
                    <Field
                        isLoading={interactiveVenueAssigner.isLoading}
                        className="sm-2 input-xs"
                        field={interactiveVenueAssigner.form.fields[0]} />

                    <Field
                        isLoading={interactiveVenueAssigner.isLoading}
                        className="sm-2 input-xs"
                        field={interactiveVenueAssigner.form.fields[1]} />

                    <Field
                        isLoading={interactiveVenueAssigner.isLoading}
                        className="sm-2 input-xs"
                        field={interactiveVenueAssigner.form.fields[2]} />

                    <Field
                        isLoading={interactiveVenueAssigner.isLoading}
                        className="sm-2 input-xs"
                        field={interactiveVenueAssigner.form.fields[3]} />

                    <Field
                        isLoading={interactiveVenueAssigner.isLoading}
                        className="sm-2 input-xs"
                        field={interactiveVenueAssigner.form.fields[4]} />

                    <Field
                        isLoading={interactiveVenueAssigner.isLoading}
                        className="sm-2 input-xs"
                        field={interactiveVenueAssigner.form.fields[5]} />
                </div>

                <b>Venues </b>

                <Button
                    className="btn-link text-sm"
                    button={interactiveVenueAssigner.toggleAllVenuesButton} />

                <hr/>

                <div class="grid-container">
                    {interactiveVenueAssigner.venueCheckboxes.map((field) =>
                        <Field
                            isLoading={interactiveVenueAssigner.isLoading}
                            className="sm-2"
                            field={field} />
                    )}
                </div>

                {!interactiveVenueAssigner.isLoading &&
                    this.buildInteractiveGrid()
                }

                {!!interactiveVenueAssigner.isLoading &&
                    <div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                    </div>
                }
            </div>
        );
    }
}

export default InteractiveVenueAssigner;
