"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../button/button");
var customerCompanySearchField_1 = require("../fields/customerCompanySearchField");
var QuickAddCustomerForm = /** @class */ (function () {
    function QuickAddCustomerForm(api) {
        var _this = this;
        this.api = api;
        this.open = function (customerToEdit) {
            _this._customerCompanySearchField = new customerCompanySearchField_1.default(_this.api, 'Company', true, 'customerCompanyId', 'newCustomerCompanyName', 'required', false);
            _this._form = new __1.Form([
                new __1.TextField('rank', 'Rank/Position', false, 250),
                new __1.TextField('title', 'Title', false, 50),
                new __1.TextField('firstName', 'First Name', true, 100, 'required'),
                new __1.TextField('lastName', 'Last Name', true, 100, 'required'),
                new __1.TextField('phone', 'Mobile Phone', false, 14),
                new __1.EmailField('email', false),
                _this._customerCompanySearchField
            ]);
            _this._form.observationProvider.observe(_this.save, __1.FormActions.FormSubmitted);
            _this._form.observationProvider.observe(function () { _this.saveButton.setProcessing(false); }, __1.FormActions.FormSubmissionFailure);
            _this.saveButton = new button_1.default({
                text: 'Save',
                processingText: 'Saving...',
                onClick: _this.form.submit
            });
            if (customerToEdit) {
                _this._isEditMode = true;
                _this._customerCompanySearchField.setValue([{
                        id: customerToEdit.customerCompanyId,
                        name: customerToEdit.newCustomerCompanyName
                    }]);
                _this.form.setModel(customerToEdit);
            }
            else {
                _this._isEditMode = false;
                _this.form.setModel({});
            }
            _this._isVisible = true;
            _this.observationProvider.notifyObservers(__1.QuickAddCustomerFormActions.Opened);
        };
        this.close = function () {
            _this._isVisible = false;
            _this.observationProvider.notifyObservers(__1.QuickAddCustomerFormActions.Closed);
        };
        this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var customer, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.isValid()) {
                            this.saveButton.setProcessing(false);
                            this.observationProvider.notifyObservers(__1.QuickAddCustomerFormActions.FormInvalid);
                            return [2 /*return*/];
                        }
                        customer = this.form.model;
                        if (this._customerCompanySearchField.newCompanyNameField.value) {
                            customer.newCustomerCompanyName = this._customerCompanySearchField.newCompanyNameField.value;
                            customer.customerCompanyName = this._customerCompanySearchField.newCompanyNameField.value;
                            customer.customerCompanyId = null;
                        }
                        else {
                            customer.customerCompanyId = this._customerCompanySearchField.selectedResults[0].id;
                            customer.customerCompanyName = this._customerCompanySearchField.selectedResults[0].name;
                            delete customer.newCustomerCompanyName;
                        }
                        if (!this.isEditMode) return [3 /*break*/, 2];
                        this._customer = customer;
                        customer.id = customer.customerId;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Customers + '/QuickUpdate', customer)];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _a = this;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Customers + '/QuickAdd', customer)];
                    case 3:
                        _a._customer = _b.sent();
                        this._customer.customerId = this._customer.id;
                        delete this._customer.id;
                        _b.label = 4;
                    case 4:
                        this.saveButton.setProcessing(false);
                        // set name after saving to ensure the notified observers have the name
                        if (this._customerCompanySearchField.newCompanyNameField.value) {
                            customer.newCustomerCompanyName = this._customerCompanySearchField.newCompanyNameField.value;
                            customer.customerCompanyName = this._customerCompanySearchField.newCompanyNameField.value;
                        }
                        else {
                            customer.newCustomerCompanyName = this._customerCompanySearchField.selectedResults[0].name;
                            customer.customerCompanyName = this._customerCompanySearchField.selectedResults[0].name;
                        }
                        this._isVisible = false;
                        if (this._isEditMode) {
                            this.observationProvider.notifyObservers(__1.QuickAddCustomerFormActions.CustomerUpdated, this._customer);
                        }
                        else {
                            this.observationProvider.notifyObservers(__1.QuickAddCustomerFormActions.CustomerCreated, this._customer);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.isValid = function () {
            var isValid = true;
            _this._customerCompanySearchField.markAsDirty();
            if (_this._customerCompanySearchField.isVisible) {
                isValid = _this._customerCompanySearchField.isValid() && isValid;
            }
            isValid = _this.form.isValid && isValid;
            return isValid;
        };
        this.observationProvider = new __1.ObservationProvider();
        this.cancelButton = new button_1.default({
            text: 'Cancel',
            onClick: this.close
        });
    }
    Object.defineProperty(QuickAddCustomerForm.prototype, "customer", {
        get: function () {
            return this._customer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuickAddCustomerForm.prototype, "isEditMode", {
        get: function () {
            return this._isEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuickAddCustomerForm.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuickAddCustomerForm.prototype, "customerCompanySearchField", {
        get: function () {
            return this._customerCompanySearchField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuickAddCustomerForm.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuickAddCustomerForm.prototype, "heading", {
        get: function () {
            if (this.isEditMode) {
                return 'Quick Edit Customer';
            }
            else {
                return 'Quick Add Customer';
            }
        },
        enumerable: true,
        configurable: true
    });
    return QuickAddCustomerForm;
}());
exports.default = QuickAddCustomerForm;
