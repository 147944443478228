import { DateFormats, MeetingDetail, MeetingDetailFile, DataList as FrontEndDataList, DataListItem, DownloadButton, Icons, TextField, Button as FrontEndButton } from 'core.frontend';
import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import DataList from '../dataList/dataList';
import FileListItem from '../dataList/fileListItem/fileListItem';
import FormattedDate from '../text/formattedDate';
import FormattedTimespan from '../text/formattedTimespan';
import MeetingStatus from './meetingStatus';
import MeetingCreatedByLabel from './meetingCreatedByLabel';
import Button from '../buttons/button';

export interface ReadonlyMeetingDetailProps {
    isEditable?: boolean;
    className?: string;
    meetingDetail: MeetingDetail;
    meetingsDiningEnabled?: boolean;
    fileDataList?: FrontEndDataList<MeetingDetailFile>;
    isApp?: boolean;
    buildDownloadButton: (item: DataListItem<MeetingDetailFile>) => DownloadButton;
    buildFileIcon: (item: DataListItem<MeetingDetailFile>) => Icons.Image | Icons.Pdf | Icons.Word | Icons.Excel | Icons.PowerPoint | Icons.File;
    guestsArrivedButton: FrontEndButton;
}

export default class ReadonlyMeetingDetail extends ObservingComponent<ReadonlyMeetingDetailProps> {

    public componentWillMount() {}

    public render({ isEditable, className, meetingDetail, meetingsDiningEnabled, fileDataList, isApp, buildDownloadButton, buildFileIcon, guestsArrivedButton }: ReadonlyMeetingDetailProps): JSX.Element {

        return (
            <div className={className}>
                <div class="flex mt-p5">
                    <div class="fill v-center">
                        <div class="flex">
                            <span class="text-sm">
                                {meetingDetail.id > 0 &&
                                    <span>
                                        <b>Meeting #: </b>
                                        {meetingDetail.meetingNumber}
                                    </span>
                                }

                                {meetingDetail.id > 0 &&
                                meetingDetail.createdByUserId &&
                                    <span>
                                        <br/>

                                        <MeetingCreatedByLabel meeting={meetingDetail} />
                                    </span>
                                }

                                {meetingDetail.id > 0 &&
                                meetingDetail.requestedByUserId &&
                                    <span>
                                        <br/>

                                        <b>Requested By: </b>
                                        {meetingDetail.requestedByUserName}

                                        <span> - </span>
                                        {meetingDetail.requestedByDepartment}

                                        <span> - </span>
                                        <a href={'tel:' + meetingDetail.requestedByUserPhone}>
                                            {meetingDetail.requestedByUserPhone}
                                        </a>

                                        <span> - </span>
                                        <a href={'mailto:' + meetingDetail.requestedByUserEmail}>
                                            {meetingDetail.requestedByUserEmail}
                                        </a>
                                    </span>
                                }
                            </span>

                            <div class="ml-auto flex">
                                <span class="v-center">Guests Arrived: </span>

                                <Button
                                    className="btn-icon ml-1 v-center"
                                    button={guestsArrivedButton} />
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                {isEditable &&
                    <p class="mt-0 text-center text-sm">
                        Use the edit "pencil" in the upper right-hand corner to change meeting information.
                    </p>
                }

                <div class="readonly-meeting-detail-label">Info:</div>
                <div class="readonly-meeting-detail-section text-center flex">
                    <span class="text-md">
                        #{meetingDetail.meetingNumber}
                    </span>

                    <div class="v-center fill">
                        <FormattedDate
                            format={DateFormats.MMMdYYYY}
                            date={meetingDetail.date} />
                    </div>

                    <span class="text-md">
                        <FormattedTimespan timespan={meetingDetail.startTime} />
                        <span>&nbsp;-&nbsp;</span>
                        <FormattedTimespan timespan={meetingDetail.endTime} />
                    </span>
                </div>

                <div class="readonly-meeting-detail-label">Name/Subject:</div>
                <div class="readonly-meeting-detail-section">
                    <span class="text-md">
                        {meetingDetail.name}
                    </span>

                    {meetingDetail.subject &&
                        <div>
                            <hr/>
                            {meetingDetail.subject}
                        </div>
                    }
                </div>

                <div class="readonly-meeting-detail-label">Location:</div>
                <div class="readonly-meeting-detail-section">
                    <div class="pl-1 text-md">
                        {!!meetingDetail.venue && meetingDetail.venue}
                        {!meetingDetail.venue && <span>Unassigned</span>}

                        {!!meetingDetail.assignedTables &&
                            <span>Table(s): {meetingDetail.assignedTables}</span>
                        }
                    </div>
                </div>

                <div class="flex mt-p5">
                    <div class="fill h-spaced">
                        <div class="readonly-meeting-detail-label">Status:</div>
                        <div class="readonly-meeting-detail-section text-center">
                            <MeetingStatus status={meetingDetail.status} />
                        </div>
                    </div>

                    <div class="fill h-spaced">
                        <div class="readonly-meeting-detail-label">Category:</div>
                        <div class="readonly-meeting-detail-section text-center">
                            {meetingDetail.defaultMeetingCategory}
                            {meetingDetail.scheduledBy &&
                                <span> / {meetingDetail.scheduledBy}</span>
                            }
                        </div>
                    </div>

                    {meetingsDiningEnabled &&
                        <div class="fill h-spaced">
                            <div class="readonly-meeting-detail-label">Food:</div>
                            <div class="readonly-meeting-detail-section text-center">
                                {meetingDetail.foodNeeded ? "YES" : "NO"}
                            </div>
                        </div>
                    }
                </div>

                {meetingDetail.url &&
                    <div class="mt-p5">
                        <div class="readonly-meeting-detail-label">Meeting URL:</div>
                        <div class="readonly-meeting-detail-section">
                            <a
                                href={meetingDetail.url}
                                target="_blank">

                                {meetingDetail.url}
                            </a>
                        </div>
                    </div>
                }

                {fileDataList &&
                !!fileDataList.items &&
                !!fileDataList.items.length &&

                    <div class="mt-p5">
                        <div class="readonly-meeting-detail-label">Meeting Files:</div>
                        <div class="readonly-meeting-detail-section">
                            <DataList
                                dataList={fileDataList}
                                itemTemplate={(item) => (
                                    <FileListItem
                                        key={item.form.model.id}
                                        icon={buildFileIcon(item)}
                                        downloadButton={buildDownloadButton(item)}
                                        fileType={item.form.model.fileType}
                                        isApp={isApp}
                                        createdDateTime={item.form.model.createdDateTime}
                                        uploadedByUsersName={item.form.model.uploadedByUsersName}
                                        isDownloadable={!!item.form.model.id}
                                        fileName={item.form.model.fileName}
                                        fileSize={item.form.model.fileSize}
                                        friendlyNameField={item.form.fields[0] as TextField}
                                        descriptionField={item.form.fields[1] as TextField}
                                        allowManualEditMode={false}
                                        isEditMode={false}/>
                                )}/>
                        </div>
                    </div>
                }

                {(meetingDetail.preMeetingPickupAvailable ||
                 meetingDetail.postMeetingPickupAvailable) &&

                    <div class="mt-p5">
                        <div class="readonly-meeting-detail-label">Meeting Transport:</div>
                        <div class="readonly-meeting-detail-section">

                            <div class="readonly-meeting-detail-label">PRE Pick Up / Drop Off:</div>
                            <div class="pl-1">
                                {meetingDetail.preMeetingPickupAvailable &&
                                    <span>
                                        {meetingDetail.preMeetingPickupTime} ({meetingDetail.preMeetingPickupCount}) / {meetingDetail.preMeetingPickupLocation} / {meetingDetail.preMeetingDropOffLocation}
                                    </span>
                                }
                                {!meetingDetail.preMeetingPickupAvailable &&
                                    <span>Unavailable</span>
                                }
                            </div>

                            <div class="readonly-meeting-detail-label">POST Pick Up / Drop Off:</div>
                            <div class="pl-1">
                                {meetingDetail.postMeetingPickupAvailable &&
                                    <span>
                                        {meetingDetail.postMeetingPickupTime} ({meetingDetail.postMeetingPickupCount}) / {meetingDetail.postMeetingPickupLocation} / {meetingDetail.postMeetingDropOffLocation}
                                    </span>
                                }
                                {!meetingDetail.postMeetingPickupAvailable &&
                                    <span>Unavailable</span>
                                }
                            </div>
                        </div>
                    </div>
                }

                <div class="readonly-meeting-detail-label mt-p5">
                    Attendees (Total: {meetingDetail.totalAttendees})
                </div>
                <div class="readonly-meeting-detail-section">
                    <div class="readonly-meeting-detail-label">
                        Customers:
                    </div>
                    {meetingDetail.guests && meetingDetail.guests.map((customer) => (
                        <div class="pl-1">
                            {customer.customerTitle} {customer.customerName} - {customer.customerCompany}
                        </div>
                    ))}

                    {(!meetingDetail.guests || !meetingDetail.guests.length) &&
                        <div class="pl-1">
                            <i>No Customer Attendees</i>
                        </div>
                    }

                    <div class="readonly-meeting-detail-label mt-p5">
                        Company:
                    </div>
                    {meetingDetail.attendees && meetingDetail.attendees.map((attendee) => (
                        <div class="pl-1">
                            {attendee.fullName}

                            {attendee.isLead && <span>(POC)</span>}
                            {attendee.isMeetAndGreet && <span>(M&G)</span>}
                        </div>
                    ))}

                    {meetingDetail.allAttendees &&
                        <div class="pl-1">
                            All Attendees
                        </div>
                    }

                    {meetingDetail.attendeeBusinessAreaId &&
                        <div class="pl-1">
                            Business Area: {meetingDetail.attendeeBusinessAreaName}
                        </div>
                    }

                    {meetingDetail.attendeeDepartmentId &&
                        <div class="pl-1">
                            Operating Company: {meetingDetail.attendeeDepartmentName}
                        </div>
                    }

                    {(!meetingDetail.attendees || !meetingDetail.attendees.length) &&
                     !meetingDetail.allAttendees &&
                     !meetingDetail.attendeeBusinessAreaId &&
                     !meetingDetail.attendeeDepartmentId &&

                        <div class="pl-1">
                            <i>No Company Attendees</i>
                        </div>
                    }
                </div>

                {(meetingDetail.printedComments) &&
                    <Fragment>
                        <div class="readonly-meeting-detail-label">Comments:</div>
                        <div class="readonly-meeting-detail-section">
                            <div class="pl-1">
                                <div>{meetingDetail.printedComments}</div>
                            </div>
                        </div>
                    </Fragment>
                }

                {meetingDetail.additionalNotificationRecipients &&
                 !!meetingDetail.additionalNotificationRecipients.length &&
                    <Fragment>
                        <div class="readonly-meeting-detail-label">Additional Notification Recepients:</div>
                        <div class="readonly-meeting-detail-section">
                            {meetingDetail.additionalNotificationRecipients.map((recepient) => (
                                <div class="pl-1">
                                    {recepient.fullName}
                                </div>
                            ))}
                        </div>
                    </Fragment>
                }
            </div>
        );
    }
}