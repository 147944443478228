"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AttendeeDataGridPageActions;
(function (AttendeeDataGridPageActions) {
    AttendeeDataGridPageActions["DataLoaded"] = "DataLoaded";
    AttendeeDataGridPageActions["SizeChanged"] = "SizeChanged";
    AttendeeDataGridPageActions["IncludeMeetingTimesChanged"] = "IncludeMeetingTimesChanged";
    AttendeeDataGridPageActions["IncludeVenueChanged"] = "IncludeVenueChanged";
    AttendeeDataGridPageActions["IncludeMeetingNumberChanged"] = "IncludeMeetingNumberChanged";
})(AttendeeDataGridPageActions || (AttendeeDataGridPageActions = {}));
exports.default = AttendeeDataGridPageActions;
