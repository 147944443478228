import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import NavBackgroundProps from './navBackgroundProps';

class NavBackground extends ObservingComponent<NavBackgroundProps> {

    public componentWillMount() {
        const { app } = this.props;

        this.registerUpdateObserver(app.nav.observationProvider);
    }

    public render({ app }: NavBackgroundProps): JSX.Element {

        return (
            <div
                class={app.nav.isOpen ? 'nav-background active' : 'nav-background'}
                onClick={app.nav.closeMenu}>
            </div>
        );
    }
}

export default NavBackground;
