import { h, Component, JSX } from 'preact';
import FieldLoadingPlaceholderProps from './fieldLoadingPlaceholderProps';

class FieldLoadingPlaceholder extends Component<FieldLoadingPlaceholderProps> {

    public render({className}): JSX.Element {
        return (
            <div class={'input-field ' + className }>
                <label className="loading-placeholder shimmer"></label>
                <div className="text-input loading-placeholder shimmer"></div>
            </div>
        );
    }
}

export default FieldLoadingPlaceholder;
