import { FieldActions, ActionUtils, TextField as FrontEndTimeField } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface TimeFieldProps {
    id?: string;
    field: FrontEndTimeField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class TimeField extends ObservingComponent<TimeFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public blurred = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.blur();
        field.setValue(newValue);
    }

    private keyPressed = (e: KeyboardEvent) => {
        var key_codes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 0, 8];

        if (key_codes.indexOf(e.keyCode) === -1) {
            e.preventDefault();
        }
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: TimeFieldProps): JSX.Element {

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div className={`input-field ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} /> }

                <label className={labelClassName}
                        for={id}>

                    <span class="fill">{field.label}</span>

                    {field.labelButtonText && <a onClick={field.handleLabelButtonClick}>{field.labelButtonText}</a>}

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>

                    <input
                        type="text"
                        id={id}
                        name={field.uniqueKey}
                        onFocus={field.focus}
                        onBlur={this.blurred}
                        value={field.stringValue}
                        className={field.isInvalid ? 'invalid' : ''}
                        disabled={field.isDisabled}
                        maxLength={field.maxCharacters}
                        onKeyPress={this.keyPressed} />

                { field.isActive && !field.stringValue && field.placeholderLabel && <label class="placeholder-label">{ field.placeholderLabel }</label> }
            </div>
        );
    }
}

export default TimeField;
