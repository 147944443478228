"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
var _1 = require(".");
var Button = /** @class */ (function () {
    function Button(params) {
        var _this = this;
        this.click = function () {
            if (!_this._isProcessing && !_this._isDisabled) {
                if (_this.processingText) {
                    _this._isProcessing = true;
                }
                _this.onClick(_this);
                _this.observationProvider.notifyObservers(_1.ButtonActions.Clicked);
            }
        };
        this.setRightIcon = function (icon) {
            _this._rightIcon = icon;
        };
        this.setLeftIcon = function (icon) {
            _this._leftIcon = icon;
        };
        this.setIsActiveState = function (isActiveState) {
            _this._isActiveState = isActiveState;
        };
        this.setDisabled = function (isDisabled) {
            _this._isDisabled = isDisabled;
            _this.observationProvider.notifyObservers(_1.ButtonActions.DisabledChanged);
        };
        this.setProcessing = function (isProcessing) {
            _this._isProcessing = isProcessing;
            _this.observationProvider.notifyObservers(_1.ButtonActions.ProcessingChanged);
        };
        this.setIsLoading = function (isLoading) {
            _this._isLoading = isLoading;
            _this.observationProvider.notifyObservers(_1.ButtonActions.LoadingChanged);
        };
        this.setVisibility = function (isVisible) {
            _this._isVisible = isVisible;
            _this.observationProvider.notifyObservers(_1.ButtonActions.VisibilityChanged);
        };
        this.setHoverText = function (text) {
            _this._hoverText = text;
            _this.observationProvider.notifyObservers(_1.ButtonActions.HoverTextChanged);
        };
        this.setText = function (text) {
            _this._text = text;
            _this.observationProvider.notifyObservers(_1.ButtonActions.TextChanged);
        };
        this.setProcessingText = function (text) {
            _this._processingText = text;
            _this.observationProvider.notifyObservers(_1.ButtonActions.ProcessingTextChanged);
        };
        this.setSubText = function (text) {
            _this._subText = text;
            _this.observationProvider.notifyObservers(_1.ButtonActions.TextChanged);
        };
        this.setBadge = function (badge) {
            _this._badge = badge;
            _this.observationProvider.notifyObservers(_1.ButtonActions.BadgeUpdated);
        };
        this.onClick = params.onClick;
        this._text = params.text;
        this._leftIcon = params.leftIcon;
        this._rightIcon = params.rightIcon;
        this._processingText = params.processingText;
        this.buttonType = params.buttonType ? params.buttonType : _1.ButtonTypes.Anchor;
        this._isVisible = params.isVisible === false ? false : true;
        this._isDisabled = params.isDisabled;
        this._isActiveState = params.isActiveState;
        this._subText = params.subText;
        this._badge = params.badge;
        this.observationProvider = new utils_1.ObservationProvider();
    }
    Object.defineProperty(Button.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "isProcessing", {
        get: function () {
            return this._isProcessing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "isDisabled", {
        get: function () {
            return this._isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "text", {
        get: function () {
            return this._text;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "isActiveState", {
        get: function () {
            return this._isActiveState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "leftIcon", {
        get: function () {
            return this._leftIcon;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "rightIcon", {
        get: function () {
            return this._rightIcon;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "subText", {
        get: function () {
            return this._subText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "processingText", {
        get: function () {
            return this._processingText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "badge", {
        get: function () {
            return this._badge;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "hoverText", {
        get: function () {
            return this._hoverText;
        },
        enumerable: true,
        configurable: true
    });
    return Button;
}());
exports.default = Button;
