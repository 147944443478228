import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import VendorEventListProps from './vendorEventListProps';
import SmartTableColumn from '../smartTable/smartTableColumn';
import SmartTable from '../smartTable/smartTable';
import { VendorEventTableItem, EventStatus, DateFormats } from 'core.frontend';
import Button from '../buttons/button';
import FormattedDate from '../text/formattedDate';
import Card from '../containers/card';

class VendorEventList extends ObservingComponent<VendorEventListProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private buildColumns = (): SmartTableColumn<VendorEventTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'Events.name',
                cellTemplate: (item, index) =>
                    (<span class="flex">

                        <span class="fill">
                            <Button
                                className="btn-link"
                                button={page.buildEventLandingButton(item)} />

                            {item.status === EventStatus.Archived &&
                                <span class="text-red"> - Archived</span>
                            }

                            {item.status === EventStatus.Deleted &&
                                <span class="text-red"> - Deleted</span>
                            }

                            <br/>
                            <span class="text-sm">{item.city}, {item.country}</span>

                            <span class="d-md-none sm">
                                <br/>
                                <FormattedDate
                                    date={item.startDate}
                                    format={DateFormats.MMMdYYYY} />

                                    <span> - </span>

                                <FormattedDate
                                    date={item.endDate}
                                    format={DateFormats.MMMdYYYY} />
                            </span>

                            <span class="d-md-none">
                                <br/>
                                <b>{item.tenantName}</b>
                            </span>
                        </span>
                    </span>
                )
            },
            {
                className: 'd-none d-md-table-cell fit-content',
                heading: 'Company',
                sortExpression: 'Tenants.Name',
                cellTemplate: (item, index) => (
                    <span>{item.tenantName}</span>
                )
            },
            {
                className: 'd-none d-md-table-cell fit-content text-center',
                heading: 'Dates',
                sortExpression: 'Events.StartDate',
                cellTemplate: (item) => (
                    <span class="text-sm">

                        <FormattedDate
                            date={item.startDate}
                            format={DateFormats.MMMdYYYY} />

                        <br/>

                        <FormattedDate
                            date={item.endDate}
                            format={DateFormats.MMMdYYYY} />
                    </span>),
            }
        ];
    }

    public render({ page }: VendorEventListProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card>
                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()}
                        listOptionSlideContainer={page.listOptionSlideContainer} />
                </Card>
            </div>
        );
    }
}

export default VendorEventList;
