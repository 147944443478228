"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventConfigurationPageActions;
(function (EventConfigurationPageActions) {
    EventConfigurationPageActions["ConfigurationLoading"] = "ConfigurationLoading";
    EventConfigurationPageActions["ConfigurationLoaded"] = "ConfigurationLoaded";
    EventConfigurationPageActions["InventoryEnabledChanged"] = "InventoryEnabledChanged";
    EventConfigurationPageActions["AlertEnabledChanged"] = "AlertEnabledChanged";
    EventConfigurationPageActions["MeetingsEnabledChanged"] = "MeetingsEnabledChanged";
    EventConfigurationPageActions["TransportationEnabledChanged"] = "TransportationEnabledChanged";
    EventConfigurationPageActions["HotelsEnabledChanged"] = "HotelsEnabledChanged";
    EventConfigurationPageActions["ActiveHotelAccommadationsPaneChanged"] = "ActiveHotelAccommadationsPaneChange";
    EventConfigurationPageActions["ActiveTransportationPaneChanged"] = "ActiveTransportationPaneChanged";
    EventConfigurationPageActions["ActiveMeetingPaneChanged"] = "ActiveMeetingPaneChanged";
    EventConfigurationPageActions["ConfirmationPromptShown"] = "ConfirmationPromptShown";
    EventConfigurationPageActions["EventDeleting"] = "EventDeleting";
    EventConfigurationPageActions["EventDeleted"] = "EventDeleted";
    EventConfigurationPageActions["ConfirmationPromptCancelled"] = "ConfirmationPromptCancelled";
    EventConfigurationPageActions["HotelRemoved"] = "HotelRemoved";
    EventConfigurationPageActions["HotelRemovalCancelled"] = "HotelRemovalCancelled";
    EventConfigurationPageActions["EventAttendeeAccessLevelsHidden"] = "EventAttendeeAccessLevelsHidden";
    EventConfigurationPageActions["EventAttendeeAccessLevelsVisible"] = "EventAttendeeAccessLevelsVisible";
    EventConfigurationPageActions["HotelFormHidden"] = "HotelFormHidden";
    EventConfigurationPageActions["HotelFormShown"] = "HotelFormShown";
    EventConfigurationPageActions["TransportOptionsShown"] = "TransportOptionsShown";
    EventConfigurationPageActions["TransportOptionsHidden"] = "TransportOptionsHidden";
    EventConfigurationPageActions["CostManagementEnabledChanged"] = "CostManagementEnabledChanged";
    EventConfigurationPageActions["DiningEnabledChanged"] = "DiningEnabledChanged";
})(EventConfigurationPageActions || (EventConfigurationPageActions = {}));
exports.default = EventConfigurationPageActions;
