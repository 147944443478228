import { h, Component } from 'preact';
import FormattedDateProps from './formattedDateProps';
import { DateFormats, DateUtils } from 'core.frontend';

class FormattedDate extends Component<FormattedDateProps> {

    private formatDate(date: Date, format: DateFormats, adjustToCurrentTimeZone?: boolean) {
        return DateUtils.formatDate(date, format, adjustToCurrentTimeZone);
    }

    public render({date, format, className, adjustToCurrentTimeZone }: FormattedDateProps) {
        return (
            <span class={className}>
                {this.formatDate(date, format, adjustToCurrentTimeZone)}

                {adjustToCurrentTimeZone &&
                    <span className="text-xs">&nbsp;(Local Time)</span>
                }
            </span>
        )
    }
}

export default FormattedDate;
