"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../..");
var TagCollection = /** @class */ (function () {
    function TagCollection(heading, tagData, labelProperty) {
        var _this = this;
        this.heading = heading;
        this.labelProperty = labelProperty;
        this.removeTag = function (tag) { return function () {
            var tagIndex = _this._tags.findIndex(function (currentTag) { return currentTag.uniqueId === tag.uniqueId; });
            var tagData = _this._tags[tagIndex].tagData;
            _this._tags = _this._tags.filter(function (currentTag) { return currentTag.uniqueId != tag.uniqueId; });
            _this.observationProvider.notifyObservers(_1.TagCollectionActions.TagRemoved, { index: tagIndex, tagData: tagData });
        }; };
        this.addTag = function (tagData) {
            var newTag = new _1.Tag(tagData[_this.labelProperty], tagData, _this.removeTag);
            _this._tags.push(newTag);
            _this.observationProvider.notifyObservers(_1.TagCollectionActions.TagAdded, { index: _this._tags.length - 1, tagData: tagData });
        };
        this.lock = function () {
            for (var _i = 0, _a = _this._tags; _i < _a.length; _i++) {
                var tag = _a[_i];
                tag.removeButton.setDisabled(true);
            }
        };
        this.unlock = function () {
            for (var _i = 0, _a = _this._tags; _i < _a.length; _i++) {
                var tag = _a[_i];
                tag.removeButton.setDisabled(false);
            }
        };
        this._tags = tagData.map(function (tagDataItem) { return new _1.Tag(tagDataItem[labelProperty], tagDataItem, _this.removeTag); });
        this.observationProvider = new __1.ObservationProvider();
    }
    Object.defineProperty(TagCollection.prototype, "tags", {
        get: function () {
            return this._tags;
        },
        enumerable: true,
        configurable: true
    });
    return TagCollection;
}());
exports.default = TagCollection;
