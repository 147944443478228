import { h, JSX } from 'preact';
import { LodgingStatus as FrontEndLodgingStatus } from 'core.frontend';
import LodgingStatusProps from './lodgingStatusProps';
import ObservingComponent from '../componentBases/observingComponent';

class LodgingStatus extends ObservingComponent<LodgingStatusProps> {

    public render({ status, className }: LodgingStatusProps): JSX.Element {

        if (status === FrontEndLodgingStatus.Pending) {
            return <div class={'text-orange ' + className}><b>Pending</b></div>;
        }

        if (status === FrontEndLodgingStatus.ConfirmedWithChanges) {
            return <div class={'text-orange ' + className}><b>Confirmed with Changes</b></div>;
        }

        if (status === FrontEndLodgingStatus.Confirmed) {
            return <div class={'text-green ' + className}><b>Confirmed</b></div>;
        }

        if (status === FrontEndLodgingStatus.Cancelled) {
            return <div class={'text-red ' + className}><b>Cancelled</b></div>;
        }

        return (
            <div class={className}><b>Not Yet Submitted</b></div>
        );
    }
}

export default LodgingStatus;
