"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EmailTemplateActions;
(function (EmailTemplateActions) {
    EmailTemplateActions["DataLoaded"] = "DataLoaded";
    EmailTemplateActions["ErrorSaving"] = "ErrorSaving";
    EmailTemplateActions["ConfirmationPromptCancelled"] = "ConfirmationPromptCancelled";
    EmailTemplateActions["TemplateDeleting"] = "TemplateDeleting";
    EmailTemplateActions["TemplateDeleted"] = "TemplateDeleted";
    EmailTemplateActions["ConfirmationPromptShown"] = "ConfirmationPromptShown";
})(EmailTemplateActions || (EmailTemplateActions = {}));
exports.default = EmailTemplateActions;
