"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var components_1 = require("../../../../../components");
var api_1 = require("../../../../../api");
var button_1 = require("../../../../../components/button/button");
var MeetingSchedule = /** @class */ (function () {
    function MeetingSchedule(_api, _eventId, _currentUserManager, _isVisible) {
        var _this = this;
        if (_isVisible === void 0) { _isVisible = false; }
        this._api = _api;
        this._eventId = _eventId;
        this._currentUserManager = _currentUserManager;
        this._isVisible = _isVisible;
        this.previousDay = function () {
            var newDate = new Date(_this._selectedDate);
            newDate.setDate(newDate.getDate() - 1);
            _this._selectedDate = newDate;
            _this.setNavigationDateButtonsEnabledStatus();
            _this.loadMeetings();
            _this.observationProvider.notifyObservers(__1.MeetingScheduleActions.DateChanged);
        };
        this.nextDay = function () {
            var newDate = new Date(_this._selectedDate);
            newDate.setDate(newDate.getDate() + 1);
            _this._selectedDate = newDate;
            _this.setNavigationDateButtonsEnabledStatus();
            _this.loadMeetings();
            _this.observationProvider.notifyObservers(__1.MeetingScheduleActions.DateChanged);
        };
        this.setNavigationDateButtonsEnabledStatus = function () {
            if (!_this.meetingDates || !_this.meetingDates.length) {
                return;
            }
            var firstDate = _this.meetingDates[0];
            var lastDate = _this.meetingDates[_this.meetingDates.length - 1];
            if (firstDate.getFullYear() === _this.selectedDate.getFullYear() &&
                firstDate.getMonth() === _this.selectedDate.getMonth() &&
                firstDate.getDate() === _this.selectedDate.getDate()) {
                _this.previousDayButton.setDisabled(true);
            }
            else {
                _this.previousDayButton.setDisabled(false);
            }
            if (lastDate.getFullYear() === _this.selectedDate.getFullYear() &&
                lastDate.getMonth() === _this.selectedDate.getMonth() &&
                lastDate.getDate() === _this.selectedDate.getDate()) {
                _this.nextDayButton.setDisabled(true);
            }
            else {
                _this.nextDayButton.setDisabled(false);
            }
        };
        this.loadMeetings = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, currentDate, i, dateOption, _b, timeOptions, i_1, timeOption, startTime, endTime, j, meetingStartTime, meetingEndTime;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.attendeeDropdown.value) {
                            return [2 /*return*/];
                        }
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.MeetingScheduleActions.MeetingsLoaded);
                        if (!(!this.meetingDates || !this.meetingDates.length)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this._api.getMeetingDates(this._eventId)];
                    case 1:
                        _a.meetingDates = (_c.sent())
                            .map(function (date) { return new Date(date); });
                        if (!this._selectedDate) {
                            currentDate = new Date();
                            for (i = 0; i < this.meetingDates.length; i++) {
                                dateOption = this.meetingDates[i];
                                if (currentDate.getFullYear() == dateOption.getFullYear() &&
                                    currentDate.getMonth() == dateOption.getMonth() &&
                                    currentDate.getDate() == dateOption.getDate()) {
                                    this._selectedDate = dateOption;
                                    break;
                                }
                            }
                            if (!this._selectedDate) {
                                this._selectedDate = this.meetingDates[0];
                            }
                        }
                        _c.label = 2;
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this._api.get(api_1.EndpointUrls.Events + '/' + this._eventId + '/MeetingSchedulerMeetings', {
                                eventAttendeeId: this.attendeeDropdown.value,
                                date: this._selectedDate
                            })];
                    case 3:
                        _b._meetings = _c.sent();
                        this.setNavigationDateButtonsEnabledStatus();
                        timeOptions = [
                            { label: '0000', value: '00:00' },
                            { label: '0030', value: '00:30' },
                            { label: '0100', value: '01:00' },
                            { label: '0130', value: '01:30' },
                            { label: '0200', value: '02:00' },
                            { label: '0230', value: '02:30' },
                            { label: '0300', value: '03:00' },
                            { label: '0330', value: '03:30' },
                            { label: '0400', value: '04:00' },
                            { label: '0430', value: '04:30' },
                            { label: '0500', value: '05:00' },
                            { label: '0530', value: '05:30' },
                            { label: '0600', value: '06:00' },
                            { label: '0630', value: '06:30' },
                            { label: '0700', value: '07:00' },
                            { label: '0730', value: '07:30' },
                            { label: '0800', value: '08:00' },
                            { label: '0830', value: '08:30' },
                            { label: '0900', value: '09:00' },
                            { label: '0930', value: '09:30' },
                            { label: '1000', value: '10:00' },
                            { label: '1030', value: '10:30' },
                            { label: '1100', value: '11:00' },
                            { label: '1130', value: '11:30' },
                            { label: '1200', value: '12:00' },
                            { label: '1230', value: '12:30' },
                            { label: '1300', value: '13:00' },
                            { label: '1330', value: '13:30' },
                            { label: '1400', value: '14:00' },
                            { label: '1430', value: '14:30' },
                            { label: '1500', value: '15:00' },
                            { label: '1530', value: '15:30' },
                            { label: '1600', value: '16:00' },
                            { label: '1630', value: '16:30' },
                            { label: '1700', value: '17:00' },
                            { label: '1730', value: '17:30' },
                            { label: '1800', value: '18:00' },
                            { label: '1830', value: '18:30' },
                            { label: '1900', value: '19:00' },
                            { label: '1930', value: '19:30' },
                            { label: '2000', value: '20:00' },
                            { label: '2030', value: '20:30' },
                            { label: '2100', value: '21:00' },
                            { label: '2130', value: '21:30' },
                            { label: '2200', value: '22:00' },
                            { label: '2230', value: '22:30' },
                            { label: '2300', value: '23:00' },
                            { label: '2330', value: '23:30' },
                            { label: '2400', value: '23:59' }
                        ];
                        this._timeSlots = [];
                        for (i_1 = 0; i_1 < timeOptions.length; i_1++) {
                            timeOption = timeOptions[i_1].label;
                            startTime = '0800';
                            endTime = '1800';
                            if (this._meetings.length) {
                                for (j = 0; j < this._meetings.length; j++) {
                                    meetingStartTime = this._meetings[j].startTime.substring(0, 5).replace(':', '');
                                    meetingEndTime = this._meetings[j].endTime.substring(0, 5).replace(':', '');
                                    if (meetingStartTime < startTime) {
                                        startTime = meetingStartTime;
                                    }
                                    if (meetingEndTime > endTime) {
                                        endTime = meetingEndTime;
                                    }
                                }
                            }
                            // round start time down to nearest 30 minutes
                            startTime = startTime.substring(0, 2) + (startTime.substring(2, 4) < '30' ? '00' : '30');
                            // round end time up to nearest 30 minutes
                            endTime = endTime.substring(0, 2) + (endTime.substring(2, 4) < '30' ? '30' : '00');
                            if (timeOption === startTime || this._timeSlots.length > 0) {
                                if (timeOption > endTime) {
                                    break;
                                }
                                this._timeSlots.push(timeOption);
                            }
                        }
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.MeetingScheduleActions.MeetingsLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.meetingClicked = function (meeting) {
            _this.observationProvider.notifyObservers(__1.MeetingScheduleActions.MeetingClicked, meeting.id);
        };
        this.observationProvider = new __1.ObservationProvider();
        this._isLoading = true;
        this.attendeeDropdown = new components_1.EventAttendeesAssignableToMeetingsDropdown(_api, _eventId, 'attendee', false);
        this.attendeeDropdown
            .loadingPromise
            .then(function () {
            return _api.get(api_1.EndpointUrls.Events + '/' + _this._eventId + '/EventAttendeeIdByUserId/' + _currentUserManager.userId)
                .then(function (eventAttendeeId) {
                if (!eventAttendeeId ||
                    !_this.attendeeDropdown.options ||
                    _this.attendeeDropdown.options.findIndex(function (option) { return option.value == eventAttendeeId; }) < 0) {
                    _this.attendeeDropdown.setValue(_this.attendeeDropdown.options[0].value);
                }
                else {
                    _this.attendeeDropdown.setValue(eventAttendeeId);
                }
            });
        })
            .then(this.loadMeetings);
        this.attendeeDropdown.observationProvider.observe(function () {
            _this.loadMeetings();
        }, components_1.FieldActions.ValueChanged);
        this.previousDayButton = new button_1.default({
            leftIcon: components_1.Icons.ChevronLeft,
            onClick: this.previousDay
        });
        this.nextDayButton = new button_1.default({
            leftIcon: components_1.Icons.ChevronRight,
            onClick: this.nextDay
        });
    }
    Object.defineProperty(MeetingSchedule.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingSchedule.prototype, "selectedDate", {
        get: function () {
            return this._selectedDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingSchedule.prototype, "meetings", {
        get: function () {
            return this._meetings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingSchedule.prototype, "timeSlots", {
        get: function () {
            return this._timeSlots;
        },
        enumerable: true,
        configurable: true
    });
    return MeetingSchedule;
}());
exports.default = MeetingSchedule;
