"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SystemSettingsPanes;
(function (SystemSettingsPanes) {
    SystemSettingsPanes[SystemSettingsPanes["ApprovedAccessDomains"] = 1] = "ApprovedAccessDomains";
    SystemSettingsPanes[SystemSettingsPanes["Departments"] = 2] = "Departments";
    SystemSettingsPanes[SystemSettingsPanes["BusinessAreas"] = 3] = "BusinessAreas";
    SystemSettingsPanes[SystemSettingsPanes["LinesOfBusiness"] = 4] = "LinesOfBusiness";
    SystemSettingsPanes[SystemSettingsPanes["UserTitles"] = 5] = "UserTitles";
    SystemSettingsPanes[SystemSettingsPanes["Hotels"] = 6] = "Hotels";
    SystemSettingsPanes[SystemSettingsPanes["EventLeadResponsibilities"] = 7] = "EventLeadResponsibilities";
    SystemSettingsPanes[SystemSettingsPanes["DefaultMeetingCategories"] = 8] = "DefaultMeetingCategories";
    SystemSettingsPanes[SystemSettingsPanes["InventoryItemTypePane"] = 9] = "InventoryItemTypePane";
    SystemSettingsPanes[SystemSettingsPanes["InventoryItemCategoryPane"] = 10] = "InventoryItemCategoryPane";
    SystemSettingsPanes[SystemSettingsPanes["InventoryItemGroupPane"] = 11] = "InventoryItemGroupPane";
    SystemSettingsPanes[SystemSettingsPanes["EventAttendeeAccessLevels"] = 12] = "EventAttendeeAccessLevels";
    SystemSettingsPanes[SystemSettingsPanes["TransportTermsPane"] = 13] = "TransportTermsPane";
    SystemSettingsPanes[SystemSettingsPanes["LodgingTermsPane"] = 14] = "LodgingTermsPane";
    SystemSettingsPanes[SystemSettingsPanes["ProductKnowledgeOptions"] = 15] = "ProductKnowledgeOptions";
    SystemSettingsPanes[SystemSettingsPanes["ApiAuthKeyPane"] = 16] = "ApiAuthKeyPane";
    SystemSettingsPanes[SystemSettingsPanes["CostManagementServices"] = 17] = "CostManagementServices";
    SystemSettingsPanes[SystemSettingsPanes["SystemSettingsSystemNotification"] = 18] = "SystemSettingsSystemNotification";
    SystemSettingsPanes[SystemSettingsPanes["MeetingNotesDisclaimer"] = 19] = "MeetingNotesDisclaimer";
})(SystemSettingsPanes || (SystemSettingsPanes = {}));
exports.default = SystemSettingsPanes;
