"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MeetingNotesFormActions;
(function (MeetingNotesFormActions) {
    MeetingNotesFormActions["Shown"] = "Shown";
    MeetingNotesFormActions["Hidden"] = "Hidden";
    MeetingNotesFormActions["Loading"] = "Loading";
    MeetingNotesFormActions["Loaded"] = "Loaded";
    MeetingNotesFormActions["AttachmentAdded"] = "AttachmentAdded";
    MeetingNotesFormActions["AttachmentRemoved"] = "AttachmentRemoved";
    MeetingNotesFormActions["AttachmentRemoveCancelled"] = "AttachmentRemoveCancelled";
    MeetingNotesFormActions["Saving"] = "Saving";
    MeetingNotesFormActions["Saved"] = "Saved";
    MeetingNotesFormActions["DeleteNoteClicked"] = "DeleteNoteClicked";
    MeetingNotesFormActions["EditNoteClicked"] = "EditNoteClicked";
    MeetingNotesFormActions["CancelEditNoteClicked"] = "CancelEditNoteClicked";
    MeetingNotesFormActions["NoteContentInvalid"] = "NoteContentInvalid";
})(MeetingNotesFormActions || (MeetingNotesFormActions = {}));
exports.default = MeetingNotesFormActions;
