import { h, Component, JSX } from 'preact';
import EmptyStateProps from './emptyStateProps';

class EmptyState extends Component<EmptyStateProps> {

    public render({ title, message }: EmptyStateProps): JSX.Element {
        title = title === null || title === undefined ? 'No records found' :  title;

        return (
            <div className="empty-state">
                <i className="ti ti-inbox"/>
                <br/>

                {title &&
                    <span>
                        <b>{ title }</b>
                        <br/>
                    </span>
                }

                { message ? message : 'Either your search returned no results or there haven\'t been any records created yet'}
            </div>
        );
    }
}

export default EmptyState;
