"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DailyTransportStatus;
(function (DailyTransportStatus) {
    DailyTransportStatus[DailyTransportStatus["NotSubmitted"] = 0] = "NotSubmitted";
    DailyTransportStatus[DailyTransportStatus["Pending"] = 1] = "Pending";
    DailyTransportStatus[DailyTransportStatus["Confirmed"] = 2] = "Confirmed";
    DailyTransportStatus[DailyTransportStatus["ConfirmedWithChanges"] = 3] = "ConfirmedWithChanges";
    DailyTransportStatus[DailyTransportStatus["Cancelled"] = 4] = "Cancelled";
})(DailyTransportStatus || (DailyTransportStatus = {}));
exports.default = DailyTransportStatus;
