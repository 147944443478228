import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import UserProfilePageProps from './userProfilePageProps';
import Field from '../fields/field';
import Button from '../buttons/button';
import UserProfileImageField from '../fields/userProfileImageField';
import { FileInputField } from 'core.frontend';
import Alert from '../alerts/alert';
import ButtonColors from '../buttons/buttonColors';
import FormButtonContainer from '../containers/formButtonContainer';
import VideoModalButton from '../buttons/videoModalButton';
import InternationalPhoneField from '../fields/internationalPhoneField';

class UserDetailPage extends ObservingComponent<UserProfilePageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: UserProfilePageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{ page.title }</h3>

                    <div class="btn-container flex">

                        <Button
                            className="btn-icon btn-lg"
                            button={page.helpButton} />


                        <Button
                            color={ButtonColors.Secondary}
                            className="ml-1 btn-icon"
                            button={page.historyButton} />
                    </div>
                </div>

                <Alert alert={page.alert} />

                <div class="form-container">
                    <div class="grid-container">

                        <div class="sm-4 mt-1">
                            <UserProfileImageField
                                field={page.form.fields[0] as FileInputField} />
                        </div>

                        {page.form.fields.map((field, index) => {
                            if (index > 0 && field.key !== 'contactPhone') {
                                return <Field
                                            className={'stacked-label ' +
                                                    (index === 1 || index === 2 ? 'md-mt-0 ' : '' ) +
                                                    ([
                                                        'firstName',
                                                        'lastName',
                                                        'email',
                                                        'contactEmail',
                                                        'contactPhone',
                                                        'productKnowledgeOptionId',
                                                        'departmentId',
                                                        'lineOfBusinessId'
                                                    ].indexOf(field.key) !== -1 ? 'sm-4' :
                                                    [
                                                        'newTitle',
                                                        'userTitleId',
                                                    ].indexOf(field.key) !== -1 ? 'sm-8' :
                                                    'sm-12'
                                                )}
                                            field={field}
                                            isLoading={page.isLoading} />
                            } else if (field.key === 'contactPhone') {
                                return (
                                    <InternationalPhoneField
                                        className="sm-4"
                                        field={field} />
                                )}
                        })}
                    </div>
                </div>

                <div class="form-container">
                    <h4 class="section-header">Hotel Reward Numbers</h4>

                    <div class="grid-container">
                        {page.hotelRewardsForm.map((field, index) =>
                            <Field
                                id={'hotelReward_' + index.toString()}
                                field={field}
                                className="sm-4"/>
                        )}
                    </div>
                </div>

                <div class="form-container">
                    <h4 class="section-header">User Preferences</h4>

                    <div class="grid-container">
                        {page.userPreferencesForm.fields.map((field, index) =>
                            <Field
                                id={'userPreferences_' + index.toString()}
                                field={field}
                                className="sm-4" />
                        )}
                    </div>
                </div>

                <FormButtonContainer
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.backButton} />,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton} />
                    ]} />
            </div>
        );
    }
}

export default UserDetailPage;
