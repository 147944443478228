"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var UsersListPage = /** @class */ (function (_super) {
    __extends(UsersListPage, _super);
    function UsersListPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.UserList, 'User List') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.loadData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.Users, _this._table.recordsPerPage, _this._table.currentPage, _this._table.sortExpression, _this._table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.goToNewUser = function () {
            _this.navigator.goToUserDetail();
            var userDetailPage = _this.navigator.currentPage;
            userDetailPage.observationProvider.observeOnce(_this.addRecordToTable, __1.UserDetailPageActions.RecordAdding);
            userDetailPage.observationProvider.observeOnce(_this.recordAddedSuccessfully, __1.UserDetailPageActions.RecordAdded);
        };
        _this.recordAddedSuccessfully = function (record) {
            _this._newlyAddedRecord.id = record.id;
            _this._table.recordAddedSuccessfully();
        };
        _this.addRecordToTable = function (record) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this._newlyAddedRecord = {
                            email: record.email,
                            firstName: record.firstName,
                            lastName: record.lastName,
                            isActive: record.isActive
                        };
                        this._table.insertAddingRecord(this._newlyAddedRecord);
                        _a = this._newlyAddedRecord;
                        return [4 /*yield*/, this.api.getLineOfBusinessName(record.lineOfBusinessId)];
                    case 1:
                        _a.lineOfBusinessName = _d.sent();
                        _b = this._newlyAddedRecord;
                        return [4 /*yield*/, this.api.getBusinessAreaNameByDepartmentId(record.departmentId)];
                    case 2:
                        _b.businessAreaName = _d.sent();
                        _c = this._newlyAddedRecord;
                        return [4 /*yield*/, this.api.getDepartmentName(record.departmentId)];
                    case 3:
                        _c.departmentName = _d.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.goToEditUser = function (userId) { return function () {
            _this.navigator.goToUserDetail(userId);
            var userDetailPage = _this.navigator.currentPage;
            userDetailPage.observationProvider.observeOnce(_this.markRecordAsEdited, __1.UserDetailPageActions.RecordEdited);
            userDetailPage.observationProvider.observeOnce(_this.markRecordAsEditing, __1.UserDetailPageActions.RecordEditing);
            userDetailPage.observationProvider.observeOnce(_this.markRecordAsDeactivated, __1.UserDetailPageActions.RecordDeactivated);
            userDetailPage.observationProvider.observeOnce(_this.markRecordAsActivated, __1.UserDetailPageActions.RecordActivated);
        }; };
        _this.markRecordAsEditing = function (updatedRecord) { return __awaiter(_this, void 0, void 0, function () {
            var updatedTableItem, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this._table.markRecordAsSaving(updatedRecord.id);
                        updatedTableItem = {
                            id: updatedRecord.id,
                            email: updatedRecord.email,
                            firstName: updatedRecord.firstName,
                            lastName: updatedRecord.lastName,
                            isActive: updatedRecord.isActive,
                        };
                        _a = updatedTableItem;
                        return [4 /*yield*/, this.api.getLineOfBusinessName(updatedRecord.lineOfBusinessId)];
                    case 1:
                        _a.lineOfBusinessName = _d.sent();
                        _b = updatedTableItem;
                        return [4 /*yield*/, this.api.getBusinessAreaNameByDepartmentId(updatedRecord.departmentId)];
                    case 2:
                        _b.businessAreaName = _d.sent();
                        _c = updatedTableItem;
                        return [4 /*yield*/, this.api.getDepartmentName(updatedRecord.departmentId)];
                    case 3:
                        _c.departmentName = _d.sent();
                        this._table.updateRecord(updatedRecord.id, updatedTableItem);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.markRecordAsEdited = function (record) {
            _this._table.markRecordAsNotSaving(record.id);
        };
        _this.markRecordAsDeactivated = function (record) {
            var updatedTableItem = {
                isActive: false
            };
            _this._table.updateRecord(record.id, updatedTableItem);
        };
        _this.markRecordAsActivated = function (record) {
            var updatedTableItem = {
                isActive: true
            };
            _this._table.updateRecord(record.id, updatedTableItem);
        };
        _this.buildEditUserButton = function (user) {
            return new button_1.default({
                text: user.firstName + " " + user.lastName,
                onClick: _this.goToEditUser(user.id),
                isDisabled: !_this.currentUserManager.hasPermission(__1.PermissionType.EditUsers)
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.UsersListPage, null, navigator, api);
        _this._table = new __1.Table(_this.loadData, api);
        _this._table.searchBoxPlaceholder = 'By name, BA, operating company or LOB';
        var filters = [
            new __1.BooleanDropdownField('isActive', 'Is Active', false),
            new __1.TextField('name', 'Name', false, 200),
            new __1.TextField('email', 'Email', false, 200),
            new __1.BusinessAreaDropdown(api, 'businessAreaId', 'Business Area', false, null)
        ];
        var defaultFilters = { isActive: true };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, defaultFilters);
        _this.listOptionSlideContainer.observationProvider.observe(function () { return _this._table.load(); }, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this._table.load();
        _this.newUserButton = new button_1.default({
            text: 'New User',
            leftIcon: __1.Icons.Plus,
            onClick: _this.goToNewUser
        });
        return _this;
    }
    Object.defineProperty(UsersListPage.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersListPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    return UsersListPage;
}(__1.BasePage));
exports.default = UsersListPage;
