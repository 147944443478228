import { h, JSX } from 'preact';
import ListOptionSlideContainerProps from './listOptionSlideContainerProps';
import RightSlider from './rightSlider';
import ObservingComponent from '../../componentBases/observingComponent';
import Button from '../../buttons/button';
import Field from '../../fields/field';
import ButtonColors from '../../buttons/buttonColors';

class ListOptionSlideContainer<T> extends ObservingComponent<ListOptionSlideContainerProps<T>> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.listOptionSlideContainer.observationProvider);
    }

    public render({ listOptionSlideContainer, headerText }: ListOptionSlideContainerProps<T>): JSX.Element {
        return (
            <form>
                <RightSlider
                    slideContainer={listOptionSlideContainer.optionsSlider}

                    footer={(
                        <Button
                            color={ButtonColors.Primary}
                            button={listOptionSlideContainer.applyButton} />)} >

                    <div>
                        { listOptionSlideContainer.groupingEnabled &&
                            <div>
                                <h4 class="text-center">{headerText}</h4>

                                <div>
                                    <h6 class="mt-1 mb-0">
                                        <span class="flex">
                                            <span class="fill">
                                                Group by
                                            </span>

                                            <Button
                                                className="text-sm btn-icon"
                                                button={listOptionSlideContainer.clearAllGroupByButton} />

                                        </span>
                                        <hr />
                                    </h6>

                                    { listOptionSlideContainer.groupByOptions.map(groupOption =>
                                        <div>
                                            <Button
                                                className="btn-icon"
                                                button={listOptionSlideContainer.buildEnableGroupButton(groupOption)} />

                                            <Button
                                                className="btn-remove-tag"
                                                button={listOptionSlideContainer.buildDisableGroupButton(groupOption)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                        { listOptionSlideContainer.filteringEnabled &&
                            <div>
                                <h6 class="mt-1 mb-0">
                                    <span class="flex">
                                        <span class="fill">
                                            Filters
                                        </span>

                                        <Button
                                            className="text-sm btn-icon"
                                            button={ listOptionSlideContainer.clearAllFiltersButton }/>
                                    </span>
                                    <hr/>
                                </h6>

                                <div class="form-container">
                                    { listOptionSlideContainer.filterForm.fields.map((field) =>
                                        <Field field={field} />
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </RightSlider>
            </form>
        )
    }
}

export default ListOptionSlideContainer;