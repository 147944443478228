"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dateFormats_1 = require("./dateFormats");
var moment = require("moment");
var DateUtils = /** @class */ (function () {
    function DateUtils() {
    }
    DateUtils.formatTime = function (time, dateFormat) {
        if (!time || typeof time !== 'string' || (!this.isFormatValid(time, dateFormats_1.default.hhmmss) && !this.isFormatValid(time, dateFormats_1.default.hhCmm) && !this.isFormatValid(time, dateFormats_1.default.hhmm))) {
            return '';
        }
        switch (dateFormat) {
            case dateFormats_1.default.hhCmm: {
                var timeParts = time.split(':');
                var hour = timeParts[0];
                var minutes = timeParts[1];
                return hour + ":" + minutes;
            }
            case dateFormats_1.default.hhmm: {
                return time;
            }
            case dateFormats_1.default.hhmmss: {
                var timeParts = time.split(':');
                var hour = timeParts[0];
                var minutes = timeParts[1];
                var seconds = timeParts[2];
                return hour + ":" + minutes + ":" + seconds;
            }
            case dateFormats_1.default.hmmA: {
                var timeParts = time.split(':');
                var hour = timeParts[0];
                var minutes = timeParts[1];
                var amPm = parseInt(hour, 10) >= 12 ? 'pm' : 'am';
                var formattedHour = hour;
                if (amPm === 'pm' && hour !== '12') {
                    formattedHour = (parseInt(hour, 10) - 12).toString();
                }
                return formattedHour + ":" + minutes + " " + amPm;
            }
        }
        return '';
    };
    DateUtils.formatDate = function (date, dateFormat, adjustToCurrentTimeZone) {
        if (!date) {
            return '';
        }
        if (adjustToCurrentTimeZone) {
            date = new Date(date);
        }
        var dateParts = this.getDateParts(date);
        switch (dateFormat) {
            case dateFormats_1.default.MMMMd: {
                return this.getMonthName(dateParts.monthNumberString) + " " + dateParts.day.toString();
            }
            case dateFormats_1.default.MMMd: {
                return this.getMonthAbbreviation(dateParts.monthNumberString) + " " + dateParts.day.toString();
            }
            case dateFormats_1.default.MMMdYYYY: {
                return this.getMonthAbbreviation(dateParts.monthNumberString) + " " + dateParts.day.toString() + ", " + dateParts.year.toString();
            }
            case dateFormats_1.default.MMMM: {
                return "" + this.getMonthName(dateParts.monthNumberString);
            }
            case dateFormats_1.default.hhmm: {
                return "" + dateParts.hour + dateParts.minute;
            }
            case dateFormats_1.default.dddMMMMDhmmA: {
                return date.toString();
            }
            case dateFormats_1.default.hhCmm: {
                return dateParts.hour + ":" + dateParts.minute;
            }
        }
        return dateParts.monthNumberString + "/" + dateParts.dayNumberString + "/" + dateParts.yearString;
    };
    DateUtils.formatTimespan = function (timespan) {
        var formattedStr = '';
        for (var i = 0; i < Math.min(timespan.length, 5); i++) {
            var char = timespan.charAt(i);
            if ('0123456789'.indexOf(char) !== -1) {
                formattedStr += char;
            }
        }
        if (formattedStr.length === 3) {
            formattedStr = '0' + formattedStr;
        }
        return formattedStr;
    };
    DateUtils.isDateObject = function (obj) {
        return obj && typeof obj.getMonth === 'function';
    };
    DateUtils.hasDatePast = function (date) {
        var now = new Date();
        date = new Date(date);
        return now > date;
    };
    DateUtils.isValidTime = function (timeStr) {
        return this.isFormatValid(timeStr, dateFormats_1.default.hhmm);
    };
    DateUtils.isValid = function (dateStr, format) {
        if (!this.isFormatValid(dateStr, format)) {
            return false;
        }
        var dateParts = this.getDateParts(dateStr);
        return this.areDatePartsValid(dateParts);
    };
    DateUtils.getDateParts = function (date) {
        var dateString;
        if (typeof date === 'string') {
            dateString = date;
        }
        else {
            var dateParts = {
                month: (date.getMonth() + 1),
                day: date.getDate(),
                monthNumberString: ('0' + (date.getMonth() + 1)).slice(-2),
                dayNumberString: ('0' + date.getDate()).slice(-2),
                dayOfWeek: date.getDay(),
                dayOfWeekString: this.getDayOfWeek(date.getDay()),
                dayOfWeekStringAbbreviation: this.getDayOfWeekAbbreviation(date.getDay()),
                year: date.getFullYear(),
                yearString: date.getFullYear().toString(),
                hour: '00' + date.getHours().toString(),
                minute: '00' + date.getMinutes().toString()
            };
            dateParts.hour = dateParts.hour.substr(dateParts.hour.length - 2, 2);
            dateParts.minute = dateParts.minute.substr(dateParts.minute.length - 2, 2);
            return dateParts;
        }
        var format = dateString.indexOf('/') === 2 ? dateFormats_1.default.MMDDYYYY : dateFormats_1.default.YYYYMMDD;
        switch (format) {
            case dateFormats_1.default.MMDDYYYY: {
                var dateParts = dateString.split('/');
                var monthNumber_1 = parseInt(dateParts[0], 10);
                var dayNumber_1 = parseInt(dateParts[1], 10);
                var yearNumber_1 = parseInt(dateParts[2], 10);
                return {
                    month: monthNumber_1,
                    monthNumberString: dateParts[0],
                    day: dayNumber_1,
                    dayNumberString: dateParts[1],
                    year: yearNumber_1,
                    yearString: dateParts[2]
                };
            }
            case dateFormats_1.default.YYYYMMDD:
                var monthNumber = dateString.substr(5, 2);
                var dayNumber = dateString.substr(8, 2);
                var yearNumber = dateString.substr(0, 4);
                var hourNumber = dateString.substr(11, 2);
                var minuteNumber = dateString.substr(14, 2);
                return {
                    month: parseInt(monthNumber, 10),
                    monthNumberString: monthNumber,
                    day: parseInt(dayNumber, 10),
                    dayNumberString: dayNumber,
                    year: parseInt(yearNumber, 10),
                    yearString: yearNumber,
                    hour: hourNumber,
                    minute: minuteNumber
                };
        }
    };
    DateUtils.areDatePartsValid = function (dateParts) {
        return this.isMonthDatePartValid(dateParts) && this.isDayDatePartValid(dateParts);
    };
    DateUtils.isMonthDatePartValid = function (dateParts) {
        if (dateParts.month < 1 || dateParts.month > 12) {
            return false;
        }
        return true;
    };
    DateUtils.isDayDatePartValid = function (dateParts) {
        var _31DayMonths = [1, 3, 5, 7, 8, 10, 12];
        var _30DayMonths = [4, 6, 9, 11];
        if (_31DayMonths.indexOf(dateParts.month) > -1) {
            return dateParts.day >= 1 && dateParts.day <= 31;
        }
        if (_30DayMonths.indexOf(dateParts.month) > -1) {
            return dateParts.day >= 1 && dateParts.day <= 30;
        }
        if (dateParts.month === 2) {
            return this.isValidFebruaryDay(dateParts);
        }
        return false;
    };
    DateUtils.isValidFebruaryDay = function (dateParts) {
        if (dateParts.month !== 2) {
            return false;
        }
        if (!this.isLeapYear(dateParts.year) && (dateParts.day < 1 || dateParts.day > 28)) {
            return false;
        }
        if (this.isLeapYear(dateParts.year) && (dateParts.day < 1 || dateParts.day > 29)) {
            return false;
        }
        return true;
    };
    DateUtils.isLeapYear = function (year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    };
    DateUtils.isFormatValid = function (dateStr, format) {
        var regex;
        var isValid = false;
        switch (format) {
            case dateFormats_1.default.MMDDYYYY:
                regex = /^[0-3][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/;
                isValid = regex.test(dateStr);
                break;
            case dateFormats_1.default.hhCmm: {
                regex = /^[0-9][0-9]:[0-9][0-9]$/;
                if (regex.test(dateStr)) {
                    var timeParts = dateStr.split(':');
                    if (timeParts.length === 2) {
                        var hour = parseInt(timeParts[0], 10);
                        var minutes = parseInt(timeParts[1], 10);
                        if (hour > 0 && hour <= 23 && minutes >= 0 && minutes < 60) {
                            isValid = true;
                        }
                    }
                }
                break;
            }
            case dateFormats_1.default.hhmm: {
                if (dateStr.length !== 4) {
                    return false;
                }
                regex = /^[0-9][0-9][0-9][0-9]$/;
                if (!regex.test(dateStr)) {
                    return false;
                }
                if (parseInt(dateStr, 10) <= 0 || parseInt(dateStr, 10) > 2359) {
                    return false;
                }
                var minutes = parseInt(dateStr.substr(2, 2), 10);
                if (minutes >= 0 && minutes < 60) {
                    return true;
                }
                return false;
            }
            case dateFormats_1.default.hhmmss: {
                regex = /^[0-9][0-9]:[0-9][0-9]:[0-9][0-9]$/;
                if (regex.test(dateStr)) {
                    var timeParts = dateStr.split(':');
                    if (timeParts.length === 3) {
                        var hour = parseInt(timeParts[0], 10);
                        var minutes = parseInt(timeParts[1], 10);
                        var seconds = parseInt(timeParts[2], 10);
                        if (hour > 0 && hour <= 23 && minutes >= 0 && minutes < 60 && seconds >= 0 && seconds < 60) {
                            isValid = true;
                        }
                    }
                }
                break;
            }
        }
        return isValid;
    };
    DateUtils.getMonthName = function (monthNumber) {
        if (!monthNumber) {
            return '';
        }
        if (typeof monthNumber === 'string') {
            monthNumber = parseInt(monthNumber, 10);
        }
        switch (monthNumber) {
            case 1:
                return 'January';
            case 2:
                return 'February';
            case 3:
                return 'March';
            case 4:
                return 'April';
            case 5:
                return 'May';
            case 6:
                return 'June';
            case 7:
                return 'July';
            case 8:
                return 'August';
            case 9:
                return 'September';
            case 10:
                return 'October';
            case 11:
                return 'November';
            case 12:
                return 'December';
        }
        return '';
    };
    DateUtils.getMonthAbbreviation = function (monthNumber) {
        if (!monthNumber) {
            return '';
        }
        if (typeof monthNumber === 'string') {
            monthNumber = parseInt(monthNumber, 10);
        }
        switch (monthNumber) {
            case 1:
                return 'Jan.';
            case 2:
                return 'Feb.';
            case 3:
                return 'Mar.';
            case 4:
                return 'Apr.';
            case 5:
                return 'May';
            case 6:
                return 'Jun.';
            case 7:
                return 'Jul.';
            case 8:
                return 'Aug.';
            case 9:
                return 'Sept.';
            case 10:
                return 'Oct.';
            case 11:
                return 'Nov.';
            case 12:
                return 'Dec.';
        }
        return '';
    };
    DateUtils.getDayOfWeek = function (dayNumber) {
        if (!dayNumber && dayNumber != 0) {
            return '';
        }
        if (typeof dayNumber === 'string') {
            dayNumber = parseInt(dayNumber, 10);
        }
        switch (dayNumber) {
            case 0:
                return 'Sunday';
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
        }
        return '';
    };
    DateUtils.getDayOfWeekAbbreviation = function (dayNumber) {
        if (!dayNumber && dayNumber != 0) {
            return '';
        }
        if (typeof dayNumber === 'string') {
            dayNumber = parseInt(dayNumber, 10);
        }
        switch (dayNumber) {
            case 0:
                return 'Sun';
            case 1:
                return 'Mon';
            case 2:
                return 'Tues';
            case 3:
                return 'Wed';
            case 4:
                return 'Thur';
            case 5:
                return 'Fri';
            case 6:
                return 'Sat';
        }
        return '';
    };
    DateUtils.getMonth = function (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        var dateParts = this.getDateParts(date);
        var daysInMonth;
        switch (dateParts.month) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                daysInMonth = 31;
                break;
            case 4:
            case 6:
            case 9:
            case 10:
                daysInMonth = 30;
                break;
            case 2:
                if (this.isLeapYear(dateParts.year)) {
                    daysInMonth = 29;
                }
                else {
                    daysInMonth = 28;
                }
                break;
        }
        var days = [];
        for (var i = 0; i < daysInMonth; i++) {
            days.push(i + 1);
        }
        return {
            name: this.getMonthName(dateParts.monthNumberString),
            abbreviation: this.getMonthAbbreviation(dateParts.monthNumberString),
            days: days,
            monthNumber: dateParts.month
        };
    };
    DateUtils.getWholeMonths = function (startDate, endDate) {
        if (typeof startDate === 'string') {
            startDate = this.getDateFromString(startDate);
        }
        if (typeof endDate === 'string') {
            endDate = this.getDateFromString(endDate);
        }
        var lastDateOfEndMonth = this.getLastDateOfMonth(endDate);
        var dateHolder = new Date(startDate);
        var months = [];
        do {
            months.push(this.getMonth(dateHolder));
            dateHolder = this.addMonths(dateHolder, 1);
        } while (dateHolder <= lastDateOfEndMonth);
        return months;
    };
    DateUtils.addMonths = function (date, monthCount) {
        return new Date(date.setMonth(date.getMonth() + monthCount));
    };
    DateUtils.addDays = function (date, dayCount) {
        var momentDate = moment(date);
        momentDate.add(dayCount, 'days');
        return momentDate.toDate();
    };
    DateUtils.addMinutes = function (date, minuteCount) {
        var momentDate = moment(date);
        momentDate.add(minuteCount, 'minutes');
        return momentDate.toDate();
    };
    DateUtils.getFirstDateOfMonth = function (date) {
        if (typeof date === 'string') {
            date = this.getDateFromString(date);
        }
        return (new Date(date.getFullYear(), date.getMonth(), 1));
    };
    DateUtils.getLastDateOfMonth = function (date) {
        if (typeof date === 'string') {
            date = this.getDateFromString(date);
        }
        var lastDateOfMonth = (new Date(date.getFullYear(), date.getMonth() + 1, 1));
        lastDateOfMonth.setDate(lastDateOfMonth.getDate() - 1);
        return lastDateOfMonth;
    };
    DateUtils.getDateFromString = function (dateString, dateFormat) {
        if (dateFormat === void 0) { dateFormat = dateFormats_1.default.YYYYMMDD; }
        var dateParts = this.getDateParts(dateString);
        return new Date(dateParts.year, dateParts.month - 1, dateParts.day);
    };
    DateUtils.getDateRangeArray = function (startDate, endDate) {
        if (typeof startDate === 'string') {
            startDate = this.getDateFromString(startDate);
        }
        if (typeof endDate === 'string') {
            endDate = this.getDateFromString(endDate);
        }
        var dates = [];
        var dateHolder = new Date(startDate);
        while (dateHolder <= endDate) {
            dates.push(new Date(dateHolder));
            dateHolder = new Date(dateHolder.setDate(dateHolder.getDate() + 1));
        }
        return dates;
    };
    DateUtils.areDatesEqual = function (date1, date2) {
        var dateParts1 = this.getDateParts(date1);
        var dateParts2 = this.getDateParts(date2);
        return dateParts1.year === dateParts2.year &&
            dateParts1.month === dateParts2.month &&
            dateParts1.day === dateParts2.day;
    };
    DateUtils.isFirstDateGreater = function (date1, date2) {
        var dateParts1 = this.getDateParts(date1);
        var dateParts2 = this.getDateParts(date2);
        var date1Number = parseInt("" + dateParts1.year + dateParts1.monthNumberString + dateParts1.dayNumberString);
        var date2Number = parseInt("" + dateParts2.year + dateParts2.monthNumberString + dateParts2.dayNumberString);
        return date1Number > date2Number;
    };
    DateUtils.isFirstDateGreaterOrEqual = function (date1, date2) {
        return this.isFirstDateGreater(date1, date2) || this.areDatesEqual(date1, date2);
    };
    DateUtils.getMinutesDifference = function (date1, date2) {
        var difMilliseconds = DateUtils.CreateDateMaintainTimezone(date1) - DateUtils.CreateDateMaintainTimezone(date2);
        var diffMins = Math.floor(difMilliseconds / 1000 / 60); // minutes
        return diffMins;
    };
    DateUtils.CreateDateMaintainTimezone = function (dateStr) {
        return new Date(moment.parseZone(dateStr).format('YYYY-MM-DDTHH:mm'));
    };
    DateUtils.getDateWithUTCOffset = function (timezoneOffsetMinutes) {
        var now = new Date(); // get the current time
        var currentTzOffset = -now.getTimezoneOffset();
        var deltaTzOffset = timezoneOffsetMinutes - currentTzOffset; // timezone diff
        var nowTimestamp = now.getTime(); // get the number of milliseconds since unix epoch
        var deltaTzOffsetMilli = deltaTzOffset * 1000 * 60; // convert hours to milliseconds (tzOffsetMilli*1000*60*60)
        var outputDate = new Date(nowTimestamp + deltaTzOffsetMilli); // your new Date object with the timezone offset applied.
        return outputDate;
    };
    return DateUtils;
}());
exports.default = DateUtils;
