import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import CopyrightProps from './copyrightProps';

class Copyright extends ObservingComponent<CopyrightProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.app.observationProvider);
    }

    public render({ app }: CopyrightProps): JSX.Element {

        if (app.copyrightText) {
            return (
                <div class="copy-right-container">
                    <img
                        src="img/logo-blue.png"
                        alt="Total Event Management System" />

                    <span>
                        {app.copyrightText}
                    </span>
                </div>
            )
        }

        return <span class="loading-placeholder shimmer"></span>;
    }
}

export default Copyright;
