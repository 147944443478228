import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import CustomerDetailPageProps from './customerDetailPageProps';
import Button from '../buttons/button';
import Field from '../fields/field';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import FormButtonContainer from '../containers/formButtonContainer';
import Alert from '../alerts/alert';
import ButtonColors from '../buttons/buttonColors';
import CustomerCompanySearchField from '../fields/customerCompanySearchField';
import { CustomerCompanySearchField as FrontEndCustomerCompanySearchField } from 'core.frontend';

class CustomerDetailPage extends ObservingComponent<CustomerDetailPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: CustomerDetailPageProps): JSX.Element {
        return (
            <div>
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />
                <Alert alert={page.alert} />

                <div>
                    <div class="page-heading">
                        <h3>{page.title}</h3>
                        <div class="btn-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-icon"
                                button={page.eventHistoryButton} />
                        </div>
                    </div>

                    <div class="form-container">
                        {page.form.fields.map((field, index) => {

                            if (index === 5) {
                                return <div className="mt-1">
                                            <CustomerCompanySearchField
                                                    className="stacked-label"
                                                    companySearchField={field as FrontEndCustomerCompanySearchField}
                                                    isLoading={page.isLoading} />
                                        </div>
                            } else {
                                return <Field
                                            className="stacked-label"
                                            field={field}
                                            isLoading={page.isLoading} />;
                            }
                        }

                        )}
                    </div>

                    <FormButtonContainer
                        className='sticky-button-footer'
                        leftButtons={[
                            <Button
                                className="btn-icon"
                                color={ButtonColors.Secondary}
                                button={page.deactivateButton} />
                        ]}
                        rightButtons={[

                            <Button
                                color={ButtonColors.Secondary}
                                button={page.cancelButton} />
                            ,
                            <Button
                                color={ButtonColors.Primary}
                                button={page.saveButton}/>
                        ]} />
                    </div>
            </div>
        );
    }
}

export default CustomerDetailPage;
