import { App } from 'core.frontend';
import { JSX } from 'preact';
import ApiPlugin from '../plugins/apiPlugin';
import LocalStoragePlugin from '../plugins/localStoragePlugin';
import BrowserClientChecker from '../plugins/browserClientChecker';
import Router from '../routing/router';
import UrlBuilderPlugin from '../routing/urlBuilderPlugin';

class PreactApp extends App {
    private router: Router;

    constructor(){
        super (
                new ApiPlugin(),
                new LocalStoragePlugin(),
                new BrowserClientChecker(),
                new UrlBuilderPlugin()
        );

        this.router = new Router(this);
    }

    public getCurrentPageComponent(): JSX.Element  {
        return this.router.getCurrentPageComponent();
    }
}

export default PreactApp;
