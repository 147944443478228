import { h, JSX } from 'preact';
import GolfCartReportPageProps from './golfCartReportPageProps';
import ObservingComponent from '../../../componentBases/observingComponent';
import Button from '../../../buttons/button';
import FormattedDate from '../../../text/formattedDate';
import FormattedTimespan from '../../../text/formattedTimespan';
import { DateFormats } from 'core.frontend';
import MeetingAttendeesLabel from '../../../meetings/meetingAttendeesLabel';

class GolfCartReportPage extends ObservingComponent<GolfCartReportPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: GolfCartReportPageProps): JSX.Element {
        return (
            <div class="report">
                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                <h3 class="text-center pl-1 pr-1 mt-1">
                    {!page.isLoading &&
                        <span>{page.reportData.eventName}</span>
                    }
                </h3>

                <div class="text-sm flex mb-1">
                    <div class="fill">
                        Printed by: {page.currentUsersName}
                        <div><b>{page.title}</b></div>
                    </div>
                    <div class="ml-2 text-right">

                        <FormattedDate
                            date={new Date()}
                            format={DateFormats.dddMMMMDhmmA} />

                        {!page.isLoading &&
                            <div>
                                <span>Total Rows: </span>
                                {page.reportData.reportDataItems.length}
                            </div>
                        }
                    </div>
                </div>

                {!page.isLoading && !!page.reportData.reportDataItems.length &&
                    <div class="report-page text-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="text-center">Pickup Date</th>
                                    <th class="text-center">Pickup Time</th>
                                    <th>Meeting</th>
                                    <th>Pickup Location</th>
                                    <th>Dropoff Location</th>
                                    <th class="text-center">POC/#</th>
                                    <th class="text-center">
                                        Attendees
                                    </th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {page.reportData.reportDataItems.map((meeting,index) =>
                                    <tr key={index}>
                                        <td class="text-center">{meeting.type}</td>
                                        <td class="text-center"><FormattedDate date={meeting.date} /></td>
                                        <td class="text-center"><FormattedTimespan timespan={meeting.time} /></td>
                                        <td>{meeting.meetingName} (#{meeting.meetingNumber})</td>
                                        <td>{meeting.pickupLocation}</td>
                                        <td>{meeting.dropoffLocation}</td>
                                        <td class="text-center">
                                            {!!meeting.pointOfContact &&
                                                <span>{meeting.pointOfContact}</span>
                                            }
                                            {!meeting.pointOfContact &&
                                                <span>POC Unassigned</span>
                                            }
                                            <span> / </span>
                                            {meeting.passangerCount}
                                        </td>
                                        <td>
                                            <MeetingAttendeesLabel meeting={meeting} />
                                        </td>
                                        <td class="text-normal">
                                            {meeting.comments}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }

                {!page.isLoading && !page.reportData.reportDataItems.length &&
                    <div className="text-center mt-2">
                        <b>No Pickup/Dropoff information found</b>
                    </div>
                }

                {page.isLoading &&
                    <div className="text-center mt-2">
                        <b>Loading...</b>
                    </div>
                }
            </div>
        );
    }
}

export default GolfCartReportPage;