"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var stringDropdownField_1 = require("./stringDropdownField");
var StateField = /** @class */ (function (_super) {
    __extends(StateField, _super);
    function StateField(key, isRequired, initialValue) {
        var _this = _super.call(this, key, 'State', isRequired, [
            new _1.FieldOption('Alabama', 'Alabama'),
            new _1.FieldOption('Alaska', 'Alaska'),
            new _1.FieldOption('Arizona', 'Arizona'),
            new _1.FieldOption('Arkansas', 'Arkansas'),
            new _1.FieldOption('California', 'California'),
            new _1.FieldOption('Colorado', 'Colorado'),
            new _1.FieldOption('Connecticut', 'Connecticut'),
            new _1.FieldOption('Delaware', 'Delaware'),
            new _1.FieldOption('Florida', 'Florida'),
            new _1.FieldOption('Georgia', 'Georgia'),
            new _1.FieldOption('Hawaii', 'Hawaii'),
            new _1.FieldOption('Idaho', 'Idaho'),
            new _1.FieldOption('Illinois', 'Illinois'),
            new _1.FieldOption('Indiana', 'Indiana'),
            new _1.FieldOption('Iowa', 'Iowa'),
            new _1.FieldOption('Kansas', 'Kansas'),
            new _1.FieldOption('Kentucky', 'Kentucky'),
            new _1.FieldOption('Louisiana', 'Louisiana'),
            new _1.FieldOption('Maine', 'Maine'),
            new _1.FieldOption('Maryland', 'Maryland'),
            new _1.FieldOption('Massachusetts', 'Massachusetts'),
            new _1.FieldOption('Michigan', 'Michigan'),
            new _1.FieldOption('Minnesota', 'Minnesota'),
            new _1.FieldOption('Mississippi', 'Mississippi'),
            new _1.FieldOption('Missouri', 'Missouri'),
            new _1.FieldOption('Montana', 'Montana'),
            new _1.FieldOption('Nebraska', 'Nebraska'),
            new _1.FieldOption('Nevada', 'Nevada'),
            new _1.FieldOption('New Hampshire', 'New Hampshire'),
            new _1.FieldOption('New Jersey', 'New Jersey'),
            new _1.FieldOption('New Mexico', 'New Mexico'),
            new _1.FieldOption('New York', 'New York'),
            new _1.FieldOption('North Carolina', 'North Carolina'),
            new _1.FieldOption('North Dakota', 'North Dakota'),
            new _1.FieldOption('Ohio', 'Ohio'),
            new _1.FieldOption('Oklahoma', 'Oklahoma'),
            new _1.FieldOption('Oregon', 'Oregon'),
            new _1.FieldOption('Pennsylvania', 'Pennsylvania'),
            new _1.FieldOption('Rhode Island', 'Rhode Island'),
            new _1.FieldOption('South Carolina', 'South Carolina'),
            new _1.FieldOption('South Dakota', 'South Dakota'),
            new _1.FieldOption('Tennessee', 'Tennessee'),
            new _1.FieldOption('Texas', 'Texas'),
            new _1.FieldOption('Utah', 'Utah'),
            new _1.FieldOption('Vermont', 'Vermont'),
            new _1.FieldOption('Virginia', 'Virginia'),
            new _1.FieldOption('Washington', 'Washington'),
            new _1.FieldOption('Washington D.C.', 'Washington D.C.'),
            new _1.FieldOption('West Virginia', 'West Virginia'),
            new _1.FieldOption('Wisconsin', 'Wisconsin'),
            new _1.FieldOption('Wyoming', 'Wyoming')
        ], 'State is required', initialValue, _1.FieldType.DropDown) || this;
        _this.key = key;
        return _this;
    }
    return StateField;
}(stringDropdownField_1.default));
exports.default = StateField;
