import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import LabeledDivider from '../text/labeledDivider';
import RequestPasswordResetPageProps from './requestPasswordResetPageProps';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import { AdministratorType } from 'core.frontend';

class RequestPasswordResetForm extends ObservingComponent<RequestPasswordResetPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: RequestPasswordResetPageProps): JSX.Element {

        return (
            <div>

                <div class="card-header text-center">
                    <img src="img/logo-blue.png" class="header-logo-blue" />
                    <LabeledDivider text="Request Password Reset" />
                </div>

                <div class="card-main">
                    <Alert alert={page.alert} />

                    {!page.requestSent && (
                        <div>
                            <p>
                                To reset your password, start by entering the email address that you use to login.
                            </p>

                            <Field
                                field={page.emailField}
                                className="icon-field input-lg mt-1 floating-label"
                                leftIcon="ti-user"/>

                            <hr class="mt-1"/>

                            <p>
                                How would would you like the access code to be sent to you?
                            </p>

                            <div class="flex mt-1">
                                <Button
                                    color={ButtonColors.Primary}
                                    className="fill mr-1"
                                    button={page.sendEmailButton} />

                                <Button
                                    id="deviceRegistrationSendTextButton"
                                    className="fill ml-1"
                                    color={ButtonColors.Primary}
                                    button={page.sendSmsButton} />
                            </div>

                            <hr class="mt-1"/>

                            <div className="flex">
                                <Button
                                    className="mt-2 ml-auto btn-icon"
                                    button={page.loginButton} />
                            </div>
                        </div>
                    )}

                    {page.requestSent &&

                        <form noValidate>
                            <p>
                                An access code has been sent to reset your password. Check your email or text message for the code and enter it below.
                            </p>

                            <div class="flex">
                                {page.sendAccessCodeResponse &&
                                (!page.sendAccessCodeResponse.businessAreaAdministrators || !page.sendAccessCodeResponse.businessAreaAdministrators.length) &&
                                page.sendAccessCodeResponse.administratorName &&
                                    <div className="fill mt-1 mb-1">
                                        <b>System Administrator</b>
                                        <div>{ page.sendAccessCodeResponse.administratorName }</div>
                                        <div>{ page.sendAccessCodeResponse.administratorPhone }</div>
                                        <div>{ page.sendAccessCodeResponse.administratorEmail }</div>
                                    </div>
                                }

                                {page.sendAccessCodeResponse &&
                                page.sendAccessCodeResponse.businessAreaAdministrators &&
                                page.sendAccessCodeResponse.businessAreaAdministrators.map(admin => (
                                    <div className="fill mt-1 mb-1">

                                        {admin.administratorType === AdministratorType.Domestic &&
                                            <b>Domestic User Administrator</b>
                                        }

                                        {admin.administratorType === AdministratorType.International &&
                                            <b>International User Administrator</b>
                                        }

                                        <div>{ admin.name }</div>
                                        <div>{ admin.phone }</div>
                                        <div>{ admin.email }</div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-1">
                                <Field
                                    field={page.accessCodeField}
                                    className="icon-field input-lg floating-label"
                                    leftIcon="ti-key" />
                            </div>

                            <div className="mt-1 flex">
                                <Button
                                    color={ButtonColors.Primary}
                                    className="ml-auto"
                                    button={page.submitButton} />
                            </div>
                        </form>
                    }
                </div>

            </div>
        );
    }
}

export default RequestPasswordResetForm;
