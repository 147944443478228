"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var helpButton_1 = require("../../../../../components/button/helpButton");
var helpUrls_1 = require("../../../../helpUrls");
var EventInventoryTile = /** @class */ (function (_super) {
    __extends(EventInventoryTile, _super);
    function EventInventoryTile(eventId, api, currentUserManager, navigator) {
        var _this = _super.call(this, 'Inventory', api, currentUserManager.clientChecker.isMobileApp(), false, false) || this;
        _this.eventId = eventId;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.editModeActivated = function () {
            _this.inventoryRequestDataList.setIsEditable(true);
        };
        _this.editModeDeactivated = function () {
            _this.inventoryRequestDataList.setIsEditable(false);
            for (var _i = 0, _a = _this._eventInventory; _i < _a.length; _i++) {
                var inventoryItem = _a[_i];
                inventoryItem.isRemoved = false;
            }
        };
        _this.showEventInventoryRequestPane = function () {
            _this._activePane = __1.EventInventoryPanes.EventInventoryRequests;
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.ActivePaneChanged);
        };
        _this.showEventInventoryPane = function () {
            _this._activePane = __1.EventInventoryPanes.EventInventory;
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.ActivePaneChanged);
        };
        _this.showNotesPane = function () {
            _this._activePane = __1.EventInventoryPanes.Notes;
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.ActivePaneChanged);
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 1:
                        _a._eventManager = _b.sent();
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Events + "/Inventory", this.listOptionSlideContainer.appliedFiltersModel)];
                    case 2:
                        response = _b.sent();
                        this._eventInventory = response.eventInventory;
                        this.inventoryRequestDataList.setModels(response.eventInventoryRequests);
                        this._isEditModeLocked = response.isEditModeLocked;
                        this._eventProducerName = response.eventProducerFullName;
                        this._inventoryNotes = response.inventoryNotes;
                        this.inventoryNotesField.setValue(this._inventoryNotes);
                        if (this._isEditModeLocked) {
                            this.alert.show({
                                alertType: __1.AlertType.Error,
                                heading: 'Inventory Locked',
                                message: 'Only the Event Producer (' + this._eventProducerName + ') can edit inventory after the inventory modification cutoff date.'
                            });
                        }
                        if (!this._activePane) {
                            this.setDefaultPane();
                        }
                        this.groupEventInventory();
                        this.setEditability();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setDefaultPane = function () {
            _this._activePane = __1.EventInventoryPanes.EventInventory;
        };
        _this.addInventoryItemGroupRequest = function () {
            var _loop_1 = function (selectedGroup) {
                var matchingGroupIndex = _this.inventoryRequestDataList.findIndex(function (request) { return request.inventoryItemGroupId === selectedGroup.id; });
                if (matchingGroupIndex >= 0) {
                    __1.ArrayUtils.move(_this.inventoryRequestDataList.items, matchingGroupIndex, 0);
                    return "continue";
                }
                var newRequest = {
                    inventoryItemGroupId: selectedGroup.id,
                    inventoryItemGroupName: selectedGroup.name,
                    eventId: _this.eventId,
                    requestedByUserFullName: _this.currentUserManager.userFullName,
                    userId: _this.currentUserManager.userId,
                    status: __1.EventInventoryRequestStatus.Requested
                };
                _this.inventoryRequestDataList.addModel(newRequest, true);
            };
            for (var _i = 0, _a = _this.inventoryItemGroupSearchField.selectedResults; _i < _a.length; _i++) {
                var selectedGroup = _a[_i];
                _loop_1(selectedGroup);
            }
            _this.inventoryItemGroupSearchField.clearSearchResults();
            _this.inventoryItemGroupSearchField.clearSelected();
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.RequestAdded);
        };
        _this.addEventInventoryItem = function () {
            var _loop_2 = function (selectedInventoryItem) {
                var matchingInventoryItemIndex = _this._eventInventory.findIndex(function (inventoryItem) { return inventoryItem.inventoryItemId === selectedInventoryItem.id; });
                if (matchingInventoryItemIndex >= 0) {
                    __1.ArrayUtils.move(_this._eventInventory, matchingInventoryItemIndex, 0);
                    return "continue";
                }
                var newEventInventory = {
                    eventId: _this.eventId,
                    inventoryItemId: selectedInventoryItem.id,
                    inventoryItemName: selectedInventoryItem.name,
                    isRemoved: false,
                    status: 'Requested',
                    crates: selectedInventoryItem.crates,
                    serialNumber: selectedInventoryItem.serialNumber
                };
                _this._eventInventory.push(newEventInventory);
            };
            for (var _i = 0, _a = _this.inventorySearchField.selectedResults; _i < _a.length; _i++) {
                var selectedInventoryItem = _a[_i];
                _loop_2(selectedInventoryItem);
            }
            _this.groupEventInventory();
            _this.inventorySearchField.clearSearchResults();
            _this.inventorySearchField.clearSelected();
        };
        _this.removeEventInventoryItem = function (itemId) { return function () {
            var index = _this._eventInventory.findIndex(function (item) { return item.id === itemId; });
            _this._eventInventory[index].isRemoved = true;
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.ItemRemoved);
        }; };
        _this.cancelRemoveItem = function (itemId) { return function () {
            var index = _this._eventInventory.findIndex(function (item) { return item.id === itemId; });
            _this._eventInventory[index].isRemoved = false;
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.ItemRemovedCancelled);
        }; };
        _this.removeGroupSeletion = function (groupBy) {
            groupBy.enabled = false;
            _this.groupEventInventory();
        };
        _this.addGroupSelection = function (groupBy) {
            groupBy.enabled = true;
            _this.groupEventInventory();
        };
        _this.groupEventInventory = function () {
            _this._tileData = __1.ArrayUtils.groupBy(_this.listOptionSlideContainer.enabledGroups, _this._eventInventory);
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.GroupingChanged);
        };
        _this.showImageCarousel = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var imgUrls;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        item.loadingImages = true;
                        this.observationProvider.notifyObservers(__1.EventInventoryTileActions.LoadingImages);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Inventory + '/' + item.inventoryItemId + '/Images')];
                    case 1:
                        imgUrls = _a.sent();
                        this._imageCarousel = new __1.ImageCarousel(imgUrls, item.inventoryItemName);
                        this._imageCarousel.observationProvider.observe(this.closeImageCarousel, __1.ImageCarouselActions.Closed);
                        item.loadingImages = false;
                        this.observationProvider.notifyObservers(__1.EventInventoryTileActions.ImageCarouselLoaded);
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.closeImageCarousel = function () {
            _this._imageCarousel = null;
            _this.observationProvider.notifyObservers(__1.EventInventoryTileActions.ImageCarouselClosed);
        };
        _this.update = function () { return __awaiter(_this, void 0, void 0, function () {
            var eventInventoryPayload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._inventoryNotes = this.inventoryNotesField.value;
                        eventInventoryPayload = {
                            eventInventory: this._eventInventory,
                            eventInventoryRequests: this.inventoryRequestDataList.getModels(),
                            inventoryNotes: this._inventoryNotes
                        };
                        this.setEditFormSaving();
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Events + '/Inventory', this.eventId, eventInventoryPayload)];
                    case 1:
                        _a.sent();
                        this.deactivateEditMode();
                        this.processLoadData();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildShowImageCarousel = function (item) {
            var btn = new button_1.default({
                leftIcon: __1.Icons.Camera,
                onClick: _this.showImageCarousel(item)
            });
            btn.setDisabled(!item.imageCount);
            return btn;
        };
        _this.buildRemoveItemButton = function (item) {
            if (!_this.isEditMode) {
                return null;
            }
            if (item.isRemoved) {
                return new button_1.default({
                    leftIcon: __1.Icons.Plus,
                    onClick: _this.cancelRemoveItem(item.id)
                });
            }
            return new button_1.default({
                leftIcon: __1.Icons.Minus,
                onClick: _this.removeEventInventoryItem(item.id)
            });
        };
        _this.alert = new __1.Alert();
        _this._helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.InventoryTile, null, navigator, api);
        _this.inventoryNotesField = new __1.TextField('inventoryNotes', 'Notes', false, 2000, null, null, __1.FieldType.TextArea);
        _this.inventorySearchField = new __1.InventorySearchField(api, 'Search Inventory', false, null, false, null, 'text');
        _this.inventoryItemGroupSearchField = new __1.InventoryItemGroupSearchField(api, 'Search Inventory Groups', false, null, true);
        var groupByOptions = [
            { key: 'businessAreaName', label: 'Business area', enabled: false },
            { key: 'inventoryItemType', label: 'Type', enabled: false },
            { key: 'lineOfBusinessName', label: 'Line of business', defaultHeading: 'Unknown', enabled: false }
        ];
        var filterFields = [
            new __1.TextField('name', 'Inventory Item Name', false, 200),
            new __1.BusinessAreaDropdown(_this.api, 'businessAreaId', 'Business area', false, null),
            new __1.InventoryItemTypeDropdown(_this.api, 'inventoryItemTypeId', 'Type', false, null),
            new __1.InventoryItemGroupDropdown(_this.api, 'inventoryItemGroupId', 'Group', false),
            new __1.InventoryItemCategoryDropdown(_this.api, 'inventoryItemCategoryId', 'Capability', false),
            new __1.LinesOfBusinessDropdown(_this.api, 'lineOfBusinessId', false)
        ];
        var defaultFilters = { eventId: eventId };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filterFields, defaultFilters, groupByOptions, ['eventId']);
        _this.showEventInventoryPaneButton = new button_1.default({
            text: 'Assigned',
            onClick: _this.showEventInventoryPane
        });
        _this.showEventInventoryRequestPaneButton = new button_1.default({
            text: 'Requested',
            onClick: _this.showEventInventoryRequestPane
        });
        _this.showNotesPaneButton = new button_1.default({
            text: 'Notes',
            onClick: _this.showNotesPane
        });
        _this.addEventInventoryItemButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Inventory',
            onClick: _this.addEventInventoryItem
        });
        _this.inventoryRequestDataList = new __1.DataList(function () { return [
            new __1.TextField('notes', 'Notes', false, 1000, null, '', __1.FieldType.TextArea)
        ]; }, false, 'No active inventory requests');
        _this.addInventoryRequestButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Request',
            onClick: _this.addInventoryItemGroupRequest
        });
        _this.listOptionSlideContainer.observationProvider.observe(_this.groupEventInventory, __1.ListOptionSlideContainerActions.GroupSelectionRemoved);
        _this.listOptionSlideContainer.observationProvider.observe(_this.processLoadData, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this.observationProvider.observe(_this.editModeActivated, __1.EventInventoryTileActions.EditModeActivated);
        _this.observationProvider.observe(_this.editModeDeactivated, __1.EventInventoryTileActions.EditModeDeactivated);
        return _this;
    }
    Object.defineProperty(EventInventoryTile.prototype, "inventoryNotes", {
        get: function () {
            return this._inventoryNotes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventInventoryTile.prototype, "imageCarousel", {
        get: function () {
            return this._imageCarousel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventInventoryTile.prototype, "activePane", {
        get: function () {
            return this._activePane;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventInventoryTile.prototype, "eventManager", {
        get: function () {
            return this._eventManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventInventoryTile.prototype, "noEventInventory", {
        get: function () {
            return this._eventInventory.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    EventInventoryTile.prototype.buildCrateNumberString = function (item) {
        if (!item || !item.crates || !item.crates.length) {
            return;
        }
        var crateString = '';
        for (var _i = 0, _a = item.crates; _i < _a.length; _i++) {
            var crate = _a[_i];
            crateString += crate.crateNumber + ', ';
        }
        crateString = crateString.slice(0, -2);
        return crateString;
    };
    EventInventoryTile.prototype.setEditability = function () {
        this.setIsEditable(this.eventManager.isEventInventoryTileEditable);
    };
    Object.defineProperty(EventInventoryTile.prototype, "tileData", {
        get: function () {
            return this._tileData;
        },
        enumerable: true,
        configurable: true
    });
    return EventInventoryTile;
}(__1.EventLandingTile));
exports.default = EventInventoryTile;
