"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VendorDetailPageActions;
(function (VendorDetailPageActions) {
    VendorDetailPageActions["RecordLoading"] = "RecordLoading";
    VendorDetailPageActions["RecordLoaded"] = "RecordLoaded";
    VendorDetailPageActions["RecordLoadFailure"] = "RecordLoadFailure";
    VendorDetailPageActions["Cancel"] = "Cancel";
    VendorDetailPageActions["Unlocking"] = "Unlocking";
    VendorDetailPageActions["Unlocked"] = "Unlocked";
    VendorDetailPageActions["RecordDeactivated"] = "RecordDeactivated";
    VendorDetailPageActions["RecordActivated"] = "RecordActivated";
})(VendorDetailPageActions || (VendorDetailPageActions = {}));
exports.default = VendorDetailPageActions;
