import { h, JSX } from 'preact';
import AccordianProps from './accordianProps';
import Button from '../buttons/button';
import ObservingComponent from '../componentBases/observingComponent';

class Accordian extends ObservingComponent<AccordianProps> {

    public componentWillMount(): void {
        if (this.props.accordian) {
            this.registerUpdateObserver(this.props.accordian.observationProvider);
        }
    }

    public render({ accordian, header, children, className, headerClassName }: AccordianProps): JSX.Element {

        return (
            <div className={className}>
                <div className={'flex ' + headerClassName}>
                    <Button
                        className="btn-icon mr-1"
                        button={accordian.toggleButton} />

                    {!!header &&
                        <div className="fill">
                            {header}
                        </div>
                    }
                </div>

                {!!accordian.isOpen && children}
            </div>
        );
    }
}

export default Accordian;