import { h, JSX } from 'preact';
import ReportsTileProps from './reportsTileProps';
import EventInfoTile from './eventInfoTile';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import ReportOptionSlideContainer from './rightSlider/reportOptionSlideContainer';
import { VendorEventLandingPageActions } from 'core.frontend';

class ReportsTile extends ObservingComponent<ReportsTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider, VendorEventLandingPageActions.DataLoaded);
    }

    public render({ tile }: ReportsTileProps): JSX.Element {
        return (
            <EventInfoTile
                color="orange"
                tile={tile}
                icon="ti-report">
                    {tile.isLoading ?
                        'Loading...'
                        : (
                            <ul class="report-tile-list">

                                {tile.meetingsEnabled &&
                                    <li class="report-header">
                                        Meetings
                                    </li>
                                }

                                {tile.meetingsEnabled &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.meetingsReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.meetingsReportOptionsSlider} />
                                    </li>
                                }

                                {tile.meetingsEnabled &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.meetingAttendeeReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.meetingAttendeeReportOptionsSlider} />
                                    </li>
                                }

                                {tile.meetingsEnabled &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.attendeeDataGridReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.attendeeDataGridReportOptionsSlider} />
                                    </li>
                                }

                                {tile.meetingsEnabled &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.venueDataGridReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.venueDataGridReportOptionsSlider} />
                                    </li>
                                }

                                {tile.meetingsEnabled &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.golfCartReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.golfCartReportOptionsSlider} />
                                    </li>
                                }

                                {tile.meetingsEnabled &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.diningReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.diningReportOptionsSlider} />
                                    </li>
                                }


                                <li class="report-header">
                                    Attendee
                                </li>

                                <li>
                                    <Button
                                        className="btn-link"
                                        button={tile.attendeeReportOptionsSlider.openButton} />

                                    <ReportOptionSlideContainer reportOptionSlideContainer={tile.attendeeReportOptionsSlider} />
                                </li>

                                {tile.showHotelReports &&
                                    <li class="report-header">
                                        Hotel &amp; Travel
                                    </li>
                                }

                                {tile.showHotelReports &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.hotelReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.hotelReportOptionsSlider} />
                                    </li>
                                }

                                {tile.showTransportationReports &&
                                    <li class="report-header">
                                        Transportation
                                    </li>
                                }

                                {tile.showTransportationReports &&
                                    <li>
                                        <Button
                                            className="btn-link"
                                            button={tile.transportationReportOptionsSlider.openButton} />

                                        <ReportOptionSlideContainer reportOptionSlideContainer={tile.transportationReportOptionsSlider} />
                                    </li>
                                }
                            </ul>
                        )
                    }
            </EventInfoTile>
        );
    }
}

export default ReportsTile;
