"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var observationProvider_1 = require("../../utils/observationProvider");
var fields_1 = require("../fields");
var _1 = require("./");
var Form = /** @class */ (function () {
    function Form(fields, _model) {
        var _this = this;
        if (fields === void 0) { fields = []; }
        if (_model === void 0) { _model = {}; }
        this._model = _model;
        this.setModel = function (model) {
            _this._model = model;
            _this.clearModelObservation();
            _this.setFieldValues();
            _this.setModelObservation();
            _this.observationProvider.notifyObservers(_1.FormActions.ModelLoadedToForm);
        };
        this.setFields = function (fields) {
            _this._fields = fields;
            _this.clearModelObservation();
            _this.setModelObservation();
        };
        this.setModelObservation = function () {
            _this._fieldObserverIds = [];
            _this._fields.map(function (field) {
                var observerId = field.observationProvider.observe(function () { return _this.observationProvider.notifyObservers(_1.FormActions.ModelChanged, field); }, fields_1.FieldActions.ValueChanged);
                _this._fieldObserverIds[field.key] = observerId;
            });
        };
        this.clearModelObservation = function () {
            if (!_this._fieldObserverIds || !_this._fieldObserverIds.length) {
                return;
            }
            _this._fields.map(function (field) {
                if (!_this._fieldObserverIds[field.key]) {
                }
                field.observationProvider.stopObserving(_this._fieldObserverIds[field.key]);
            });
        };
        this.setFieldValues = function () {
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (field.key in _this._model) {
                    if (field.fieldType === fields_1.FieldType.SearchField) {
                        _this.setSearchFieldValue(field);
                    }
                    else if (field.fieldType === fields_1.FieldType.CheckBox) {
                        field.setValue(_this._model[field.key] === true || _this._model[field.key] === 'true');
                    }
                    else {
                        field.setValue(_this._model[field.key]);
                    }
                }
                else {
                    field.clearValue();
                }
            }
        };
        this.getFieldByKey = function (key) {
            return _this._fields.filter(function (field) { return field.key === key; })[0];
        };
        this.setSearchFieldValue = function (searchField) {
            var _a;
            var textKey = searchField.textKey;
            if (!_this._model[searchField.key]) {
                searchField.setValue(null);
            }
            else {
                searchField.setValue([(_a = {},
                        _a[searchField.selectedResultIdKey] = _this._model[searchField.key],
                        _a[searchField.selectedResultLabelKey] = _this._model[textKey],
                        _a)]);
            }
        };
        this.submit = function () {
            if (_this.isValid()) {
                _this.observationProvider.notifyObservers(_1.FormActions.FormSubmitted);
            }
            else {
                _this.observationProvider.notifyObservers(_1.FormActions.FormSubmissionFailure, new _1.FormSubmissionFailure(_1.FormSubmissionFailureTypes.InValidForm, 'Form is not valid'));
            }
        };
        this.markAllFieldsAsDirty = function () {
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                field.markAsDirty();
            }
        };
        this.setModelValues = function () {
            _this.clearModelObservation();
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (field.isValid) {
                    _this.setFieldValue(field);
                }
            }
            _this.clearModelObservation();
            _this.observationProvider.notifyObservers(_1.FormActions.ModelChanged);
            _this.setModelObservation();
        };
        this.setFieldValue = function (field) {
            if (field.fieldType === fields_1.FieldType.Time) {
                _this.setTimeModelValue(field);
            }
            else if (field.fieldType === fields_1.FieldType.SearchField) {
                _this.setSearchFieldModelValue(field);
            }
            else {
                _this._model[field.key] = field.value;
            }
        };
        this.setTimeModelValue = function (field) {
            var hour = field.value.toString().substr(0, 2);
            var minutes = field.value.toString().substr(2, 2);
            if (hour && minutes) {
                _this._model[field.key] = hour + ':' + minutes;
            }
            else {
                _this._model[field.key] = null;
            }
        };
        this.setSearchFieldModelValue = function (searchField) {
            var userSearchField = searchField;
            if (userSearchField.key) {
                if (userSearchField.value && userSearchField.value[0]) {
                    _this._model[userSearchField.key] = userSearchField.value[0][userSearchField.selectedResultIdKey];
                    if (userSearchField.textKey) {
                        _this._model[userSearchField.textKey] = userSearchField.value[0][userSearchField.selectedResultLabelKey];
                    }
                }
                else {
                    _this._model[userSearchField.key] = null;
                }
            }
        };
        this.isValid = function () {
            _this.markAllFieldsAsDirty();
            _this.setModelValues();
            var valid = true;
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (!field.isValid()) {
                    valid = false;
                }
            }
            return valid;
        };
        this.observationProvider = new observationProvider_1.default();
        Form._systemWideFormCount++;
        this._uniqueKey = Form._systemWideFormCount.toString();
        this.setFields(fields);
        if (Object.keys(this._model).length) {
            this.setFieldValues();
        }
    }
    Object.defineProperty(Form.prototype, "uniqueKey", {
        get: function () {
            return this._uniqueKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Form.prototype, "model", {
        get: function () {
            return this._model;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Form.prototype, "fields", {
        get: function () {
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Form.prototype, "isDisabled", {
        set: function (isDisabled) {
            for (var _i = 0, _a = this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (field.isDisabled !== isDisabled) {
                    field.isDisabled = isDisabled;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Form._systemWideFormCount = 0;
    return Form;
}());
exports.default = Form;
