import { h, JSX } from 'preact';
import RightSlider from './rightSlider';
import ReportOptionSlideContainerProps from './reportOptionSlideContainerProps';
import ObservingComponent from '../../componentBases/observingComponent';
import Button from '../../buttons/button';
import Field from '../../fields/field';
import ButtonColors from '../../buttons/buttonColors';
import DownloadButton from '../../buttons/downloadButton';
import { ReportOptionSlideContainerPane } from 'core.frontend';

class ReportOptionSlideContainer<T> extends ObservingComponent<ReportOptionSlideContainerProps<T>> {

    public componentWillMount(): void {
        const { reportOptionSlideContainer } = this.props;

        this.registerUpdateObserver(reportOptionSlideContainer.observationProvider);
    }

    public render({ reportOptionSlideContainer }: ReportOptionSlideContainerProps<T>): JSX.Element {
        return (
            <form>
                <RightSlider
                    slideContainer={reportOptionSlideContainer.optionsSlider}
                    footer={(
                        <span>

                            <Button
                                color={ButtonColors.Primary}
                                button={reportOptionSlideContainer.sendEmailButton} />

                            {reportOptionSlideContainer.emailButton.isVisible &&
                                <div class="ml-0 mr-0 mb-1">
                                    <Button
                                        color={ButtonColors.Primary}
                                        button={reportOptionSlideContainer.emailButton} />
                                </div>
                            }

                            {reportOptionSlideContainer.downloadButton.isVisible &&
                                <div class="ml-0 mr-0 mb-1">
                                    <DownloadButton
                                        color={ButtonColors.Primary}
                                        button={reportOptionSlideContainer.downloadButton} />
                                </div>
                            }

                            <Button
                                color={ButtonColors.Primary}
                                button={reportOptionSlideContainer.submitButton} />
                        </span>
                    )}>

                    <div>

                        {reportOptionSlideContainer.activePane == ReportOptionSlideContainerPane.OptionForm &&
                            <div>
                                <h6 class="mt-1 mb-0">
                                    { reportOptionSlideContainer.heading }
                                    <hr/>
                                </h6>

                                <div class="form-container">
                                    { reportOptionSlideContainer.form.fields.map((field, index) =>
                                        <Field
                                            key={field.uniqueKey}
                                            field={field} />
                                    )}
                                </div>
                            </div>
                        }

                        {reportOptionSlideContainer.activePane == ReportOptionSlideContainerPane.EmailOptions &&
                            <div>
                                <h6 class="mt-1 mb-0">
                                    Email Options
                                    <hr/>
                                </h6>

                                <div class="form-container">
                                    { reportOptionSlideContainer.emailForm.fields.map((field, index) =>
                                        <Field
                                            className={index === 2 ? 'email-report-message-textarea' : ''}
                                            key={field.uniqueKey}
                                            field={field} />
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </RightSlider>
            </form>
        )
    }
}

export default ReportOptionSlideContainer;