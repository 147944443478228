"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventInfoFormActions;
(function (EventInfoFormActions) {
    EventInfoFormActions["WebLinkAdded"] = "WebLinkAdded";
    EventInfoFormActions["WebLinkRemoved"] = "WebLinkRemoved";
    EventInfoFormActions["EventLeadAdded"] = "EventLeadAdded";
    EventInfoFormActions["EventLeadRemoved"] = "EventLeadRemoved";
    EventInfoFormActions["Submitted"] = "Submitted";
    EventInfoFormActions["FormSaved"] = "FormSaved";
    EventInfoFormActions["ModelSet"] = "ModelSet";
    EventInfoFormActions["Loaded"] = "Loaded";
    EventInfoFormActions["WebLinkRemoveCancelled"] = "WebLinkRemoveCancelled";
    EventInfoFormActions["EventLeadRemoveCancelled"] = "EventLeadRemoveCancelled";
})(EventInfoFormActions || (EventInfoFormActions = {}));
exports.default = EventInfoFormActions;
