import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import VendorUserProfileProps from './vendorUserProfileProps';
import FormButtonContainer from '../containers/formButtonContainer';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import Field from '../fields/field';
import Alert from '../alerts/alert';

class VendorUserProfile extends ObservingComponent<VendorUserProfileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: VendorUserProfileProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Alert alert={page.alert} />

                <div class="form-container">
                    {page.form.fields.map((field) =>
                        <Field
                            className="stacked-label"
                            field={field}
                            isLoading={page.isLoading} />
                    )}
                </div>

                <FormButtonContainer
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.backButton} />,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton} />
                    ]} />
            </div>
        );
    }
}

export default VendorUserProfile;
