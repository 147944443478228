"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var button_1 = require("../button/button");
var _1 = require(".");
var __2 = require("../..");
var fields_1 = require("../fields");
var icons_1 = require("../icons");
var ListOptionSlideContainer = /** @class */ (function () {
    function ListOptionSlideContainer(filterFields, defaultFilters, _groupByOptions, filterCountExcludedProperties) {
        var _this = this;
        if (filterFields === void 0) { filterFields = []; }
        if (defaultFilters === void 0) { defaultFilters = {}; }
        if (_groupByOptions === void 0) { _groupByOptions = []; }
        if (filterCountExcludedProperties === void 0) { filterCountExcludedProperties = []; }
        this.defaultFilters = defaultFilters;
        this._groupByOptions = _groupByOptions;
        this.filterCountExcludedProperties = filterCountExcludedProperties;
        this.lock = function () {
            _this.openButton.setDisabled(true);
            _this.optionsSlider.close();
            _this.applyButton.setDisabled(true);
            _this.groupByTagCollection.lock();
            _this.filterTagCollection.lock();
        };
        this.applyFilterClicked = function () {
            _this.filterForm.submit();
        };
        this.unlock = function () {
            _this._loadingPromise.then(function () {
                _this.openButton.setDisabled(false);
                _this.applyButton.setDisabled(false);
                _this.groupByTagCollection.unlock();
                _this.filterTagCollection.unlock();
            });
        };
        this.setDisabled = function (isDisabled) {
            _this._isDisabled = isDisabled;
            if (_this._isDisabled) {
                _this._optionsSlider.close();
                _this.openButton.setDisabled(true);
            }
            else {
                _this.openButton.setDisabled(false);
            }
        };
        this.setOpenButtonText = function () {
            var text = '';
            if (_this.filterCount > 0) {
                text = "(" + _this.filterCount + ")";
            }
            _this.openButton.setText(text);
        };
        this.sliderShown = function () {
            _this.setFilterFormModel();
            _this.setGroupOptions();
            _this.observationProvider.notifyObservers(_1.ListOptionSlideContainerActions.Shown);
        };
        this.sliderHidden = function () {
            _this.observationProvider.notifyObservers(_1.ListOptionSlideContainerActions.Hidden);
        };
        this.buildFilterForm = function (filterFields) {
            _this._filterForm = new __1.Form(filterFields);
            _this.setFilterFormModel();
            _this.setAppliedFilters();
            _this._filterForm.observationProvider.observe(_this.filterFormSubmitted, __1.FormActions.FormSubmitted);
        };
        this.clearFilter = function (filter) {
            var newFilterModel = __assign({}, _this._filterForm.model);
            newFilterModel[filter.key] = null;
            _this._filterForm.setModel(newFilterModel);
            _this._filterForm.submit();
        };
        this.clearFilters = function () {
            _this._filterForm.setModel(__assign({}, _this.defaultFilters));
        };
        this.filterFormSubmitted = function () {
            _this._appliedFiltersModel = __assign({}, _this._filterForm.model);
            _this.setAppliedFilters();
            _this.setGroupBySelection();
            _this.optionsSlider.close();
            _this.setOpenButtonText();
            _this.observationProvider.notifyObservers(_1.ListOptionSlideContainerActions.OptionsApplied);
        };
        this.removeGroupSelection = function (groupBy) { return function () {
            groupBy.enabled = false;
            _this.observationProvider.notifyObservers(_1.ListOptionSlideContainerActions.GroupSelectionRemoved);
        }; };
        this.addGroupSelection = function (groupBy) { return function () {
            groupBy.enabled = true;
            _this.observationProvider.notifyObservers(_1.ListOptionSlideContainerActions.GroupSelectionChanged);
        }; };
        this.clearGroup = function () {
            _this._groupByOptions.map(function (groupBy) { groupBy.enabled = false; });
            _this.observationProvider.notifyObservers(_1.ListOptionSlideContainerActions.GroupSelectionChanged);
        };
        this.setGroupBySelection = function () {
            _this._groupBySelection = [];
            for (var _i = 0, _a = _this._groupByOptions; _i < _a.length; _i++) {
                var groupByOption = _a[_i];
                _this._groupBySelection.push(__assign({}, groupByOption));
            }
            _this.setGroupByTagCollection();
        };
        this.setGroupByTagCollection = function () {
            _this._groupByTagCollection = new __1.TagCollection('Grouped by:', _this._groupBySelection.filter(function (groupSelection) { return !!groupSelection.enabled; }), 'label');
            _this._groupByTagCollection.observationProvider.observe(_this.removeGroupTagClicked, __1.TagCollectionActions.TagRemoved);
        };
        this.removeGroupTagClicked = function (tagParams) {
            for (var _i = 0, _a = _this._groupByOptions; _i < _a.length; _i++) {
                var groupByOption = _a[_i];
                if (tagParams.tagData.key === groupByOption.key) {
                    groupByOption.enabled = false;
                }
            }
            _this.removeGroupSelection(tagParams.tagData)();
        };
        this.setFilterTagCollection = function () {
            _this._filterTagCollection = new __1.TagCollection('Filtered By:', _this._appliedFilters, 'label');
            if (_this.filterForm) {
                _this._filterTagCollection.observationProvider.observe(_this.removeFilterTagClicked, __1.TagCollectionActions.TagRemoved);
            }
            _this._filterTagCollection.observationProvider.notifyObservers(__1.TagCollectionActions.FilterTagsSet);
        };
        this.removeFilterTagClicked = function (tagParams) {
            _this.clearFilter(tagParams.tagData);
        };
        this.setAppliedFilters = function () {
            _this._appliedFilters = [];
            for (var i = 0; i < _this._filterForm.fields.length; i++) {
                var key = _this._filterForm.fields[i].key;
                if (_this.filterCountExcludedProperties.indexOf(key) >= 0) {
                    continue;
                }
                var value = _this.filterForm.fields[i].value;
                var label = _this.filterForm.fields[i].label.replace('*', '');
                var textValue = _this.getTextValueFromField(_this.filterForm.fields[i]);
                if (_this._appliedFiltersModel.hasOwnProperty(key)) {
                    if ((value && Array.isArray(value) && value.length > 0) ||
                        (value && !Array.isArray(value)) ||
                        value === 0) {
                        _this._appliedFilters.push({
                            key: key,
                            value: value,
                            label: label,
                            textValue: textValue
                        });
                    }
                }
            }
            _this.setFilterTagCollection();
        };
        this.getTextValueFromField = function (field) {
            if (field.fieldType === fields_1.FieldType.CheckBox ||
                field.fieldType === fields_1.FieldType.DropDown) {
                var dropdown = field;
                return dropdown.selectedOptionLabel;
            }
            return field.stringValue;
        };
        this.setFilterFormModel = function () {
            _this._filterForm.setModel(__assign({}, _this._appliedFiltersModel));
        };
        this.setGroupOptions = function () {
            _this._groupByOptions = [];
            for (var _i = 0, _a = _this._groupBySelection; _i < _a.length; _i++) {
                var groupBySelection = _a[_i];
                _this._groupByOptions.push(__assign({}, groupBySelection));
            }
        };
        this.buildEnableGroupButton = function (groupOption) {
            return new button_1.default({
                text: groupOption.label,
                isDisabled: groupOption.enabled,
                onClick: _this.addGroupSelection(groupOption)
            });
        };
        this.buildDisableGroupButton = function (groupOption) {
            return new button_1.default({
                text: 'X',
                onClick: _this.removeGroupSelection(groupOption),
                isVisible: groupOption.enabled
            });
        };
        this.observationProvider = new __2.ObservationProvider();
        this._optionsSlider = new _1.SlideContainer();
        this._optionsSlider.observationProvider.observe(this.sliderShown, _1.SlideContainerActions.Shown);
        this._optionsSlider.observationProvider.observe(this.sliderHidden, _1.SlideContainerActions.Hidden);
        this.setGroupBySelection();
        this.setGroupOptions();
        this.applyButton = new button_1.default({
            text: 'Apply',
            onClick: this.applyFilterClicked
        });
        this.clearAllGroupByButton = new button_1.default({
            text: 'Clear',
            onClick: this.clearGroup
        });
        this.clearAllFiltersButton = new button_1.default({
            text: 'Clear',
            onClick: this.clearFilters
        });
        this.openButton = new button_1.default({
            leftIcon: icons_1.Icons.Filter,
            onClick: this.optionsSlider.open
        });
        this._appliedFilters = [];
        this._appliedFiltersModel = __assign({}, defaultFilters);
        this.setFilterTagCollection();
        this._loadingPromise = Promise.all(filterFields.map(function (field) { return field.loadingPromise; }))
            .then(function () {
            _this.buildFilterForm(filterFields);
            _this.setOpenButtonText();
        });
    }
    Object.defineProperty(ListOptionSlideContainer.prototype, "loadingPromise", {
        get: function () {
            return this._loadingPromise;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "appliedFiltersModel", {
        get: function () {
            return this._appliedFiltersModel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "appliedFilters", {
        get: function () {
            return this._appliedFilters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "filterForm", {
        get: function () {
            return this._filterForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "optionsSlider", {
        get: function () {
            return this._optionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "groupBySelection", {
        get: function () {
            return this._groupBySelection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "groupByTagCollection", {
        get: function () {
            return this._groupByTagCollection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "filterTagCollection", {
        get: function () {
            return this._filterTagCollection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "isDisabled", {
        get: function () {
            return this._isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "filterCount", {
        get: function () {
            return this._appliedFilters.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "groupingEnabled", {
        get: function () {
            return this._groupByOptions.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "filteringEnabled", {
        get: function () {
            return this._filterForm && this._filterForm.fields.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "groupByOptions", {
        get: function () {
            return this._groupByOptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListOptionSlideContainer.prototype, "enabledGroups", {
        get: function () {
            return this._groupBySelection.filter(function (groupOption) { return groupOption.enabled; });
        },
        enumerable: true,
        configurable: true
    });
    return ListOptionSlideContainer;
}());
exports.default = ListOptionSlideContainer;
