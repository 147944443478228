import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import LabeledDivider from '../text/labeledDivider';
import LoginPageProps from './LoginPageProps';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';

class LoginForm extends ObservingComponent<LoginPageProps> {

    public componentWillMount() {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider);
    }

    public render({ page }: LoginPageProps): JSX.Element {

        return (
            <form noValidate autocomplete="off">

                <div class="card-header text-center">
                    <img src="img/logo-blue.png" class="header-logo-blue" />
                    <LabeledDivider text="Login to TEMS" />
                </div>

                <div class="card-main">
                    <Alert alert={page.alert} />

                    <Field
                        id="emailField"
                        field={page.emailField}
                        className="icon-field input-lg floating-label mt-2"
                        leftIcon="ti-user"/>

                    <Field
                        id="passwordField"
                        field={page.passwordField}
                        className="icon-field input-lg floating-label mt-1"
                        leftIcon="ti-key"/>

                    <div class="text-right mb-2">
                        <Button
                            id="forgetPasswordButton"
                            className="btn-icon"
                            button={page.forgotPasswordButton} />
                    </div>

                    <Button
                        id="loginButton"
                        color={ButtonColors.Danger}
                        className="btn-full-width"
                        button={page.loginButton} />
                </div>

                <div class="card-footer">
                    <LabeledDivider text="New to TEMS?" />

                    <Button
                        id="accessRequestButton"
                        color={ButtonColors.Primary}
                        button={page.accessRequestButton}
                        className="btn btn-full-width mt-3"/>
                </div>

            </form>
        );
    }
}

export default LoginForm;
