"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var components_1 = require("../../components");
var __1 = require("../..");
var HistoryTable = /** @class */ (function (_super) {
    __extends(HistoryTable, _super);
    function HistoryTable(historyType, recordId, api) {
        var _this = _super.call(this, function () {
            return _this.api.getTablePage(__1.EndpointUrls.Histories, _this.recordsPerPage, _this.currentPage, _this.buildSortExpression(), _this.searchPhrase, { historyType: historyType, recordId: recordId });
        }) || this;
        _this.api = api;
        return _this;
    }
    return HistoryTable;
}(components_1.Table));
exports.default = HistoryTable;
