import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import EventTransportationConfigurationFormProps from './eventTransportationConfigurationFormProps';
import Modal from '../../containers/modal';
import Button from '../../buttons/button';
import ButtonColors from '../../buttons/buttonColors';
import ButtonSizes from '../../buttons/buttonSizes';
import RichTextField from '../../fields/richTextField';
import Field from '../../fields/field';
import DataList from '../../dataList/dataList';
import { EventConfigurationPageTransportationPanes } from 'core.frontend';
import Accordian from '../../accordian/accordian';
import ToggleSwitchField from '../../fields/toggleSwitchField';

class EventTransportationConfigurationForm<T> extends ObservingComponent<EventTransportationConfigurationFormProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.form.observationProvider);
    }

    private getFormContents = () => {
        const { form } = this.props;

        return (
            <Fragment>
                {form.transportationFields[0].value &&
                    <div>
                        <div class="btn-group mt-1 flex">
                            <Button
                                size={ButtonSizes.Small}
                                color={ButtonColors.Primary}
                                className={'fill btn ' + (form.activeTransportationPane === EventConfigurationPageTransportationPanes.VendorAccess ? 'active ' : '' )}
                                button={form.showTransportationVendorAccessPaneButton} />
                            <Button
                                size={ButtonSizes.Small}
                                color={ButtonColors.Primary}
                                className={'fill btn ' + (form.activeTransportationPane === EventConfigurationPageTransportationPanes.TransportOptions ? 'active ' : '')}
                                button={form.showTransportOptionsPaneButton} />
                            <Button
                                size={ButtonSizes.Small}
                                color={ButtonColors.Primary}
                                className={'fill btn ' + (form.activeTransportationPane === EventConfigurationPageTransportationPanes.TermsAndConditions ? 'active ' : '' )}
                                button={form.showTransportationTermsAndConditionsPaneButton} />
                        </div>

                        <div>
                            {form.activeTransportationPane === EventConfigurationPageTransportationPanes.VendorAccess &&
                                <div class="mt-1">
                                    <p class="text-sm">
                                        <b>Note: </b> By providing a vendor company access to this this event they will be able to login and setup vehicle options for this event through their vendor login.
                                        You can also setup vehicle options for vendors in the vehicle options tab.
                                    </p>

                                    <div class="flex mt-1">
                                        <Field
                                            className="fill inline-label mr-p5 input-sm"
                                            field={form.transportationVendorCompanyDropdown} />

                                        <Button
                                            size={ButtonSizes.Small}
                                            color={ButtonColors.Primary}
                                            button={form.addTransportationVendorButton} />
                                    </div>

                                    <DataList
                                        dataList={form.eventTransportationVendorCompaniesDataList}
                                        itemTemplate={(item) => <span>{item.form.model.vendorCompany}</span>} />
                                </div>
                            }

                            {form.activeTransportationPane === EventConfigurationPageTransportationPanes.TransportOptions &&
                                <div>
                                    <div class="text-right mt-1">
                                        <Button
                                            color={ButtonColors.Primary}
                                            size={ButtonSizes.Small}
                                            button={form.addTransportationOptionButton} />
                                    </div>

                                    <DataList
                                        dataList={form.eventTransportationOptionDataList}
                                        itemTemplate={(item) =>
                                            <div class="grid-container">
                                                <div class="sm-12 mb-p5">
                                                    <Button
                                                        className="btn-link"
                                                        button={form.buildEditTransportationOptionsButton(item)} />
                                                </div>

                                                <div class="mb-p5 grid-container sm-12">
                                                    <div class="lg-6">
                                                        Allow transport from arrival destination to hotel: <b>{item.form.model.hotelToAirportTransportEnabled ? 'Yes' : 'No'}</b>
                                                    </div>

                                                    <div class="lg-6">
                                                        Allow transport from hotel to departure destination: <b>{item.form.model.hotelToAirportTransportEnabled ? 'Yes' : 'No'}</b>
                                                    </div>

                                                    {(item.form.model.hotelToAirportTransportEnabled || item.form.model.airportToHotelTransportEnabled) &&
                                                        <div class="sm-12 text-sm">
                                                            Comments: {item.form.model.arrivalDepartureTransportComments}
                                                        </div>
                                                    }
                                                </div>

                                                <div class="sm-12">
                                                    Provide daily shuttle available: <b>{item.form.model.dailyHotelEventTransportEnabled ? 'Yes' : 'No'}</b>
                                                </div>

                                                {item.form.model.dailyHotelEventTransportEnabled &&
                                                    <div class="sm-12 grid-container">
                                                        <div class="sm-12 text-sm">
                                                            Daily shuttle schedule: {item.form.model.dailyHotelEventTransportSchedule}
                                                        </div>
                                                        <div class="sm-12 text-sm">
                                                            Comments: {item.form.model.dailyHotelEventTransportComments}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }/>
                                </div>
                            }

                            {form.activeTransportationPane === EventConfigurationPageTransportationPanes.TermsAndConditions &&
                                <div class="form-container">
                                    <RichTextField
                                        isLoading={!form.isLoaded}
                                        className="settings-term-field"
                                        field={form.transportTermsAndConditionsField} />
                                </div>
                            }
                        </div>
                    </div>
                }
            </Fragment>
        );
    }

    public render({ form, helpButton, isApp }: EventTransportationConfigurationFormProps): JSX.Element {
        return (
            <div>
                {form.transportationOptionsFormIsVisible &&
                    <Modal
                        heading={form.transportationOptionsForm.model.id ? 'Edit Transport Options' : 'New Transport Options'}
                        close={form.hideTransportOptionsForm}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={form.hideTransportOptionsButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={form.updateTransportOptionsButton} />,

                        ]}>

                        <div class="pt-1">
                            <div class="flex v-center">
                                <h4 class="v-center fill mb-0">
                                    Transport Options
                                </h4>

                                <Button
                                    className="btn-icon btn-lg"
                                    button={helpButton} />
                            </div>

                            <hr/>

                            <div class="form-container">
                                <div class="grid-container">
                                    {form.transportationOptionsForm.fields.map((field, index) =>
                                        <Field
                                            className={[1,2].indexOf(index) >= 0 ? 'md-6' : 'sm-12'}
                                            field={field} />
                                    )}
                                </div>

                                <div class="flex v-center">
                                    <h4 class="v-center fill mb-0">
                                        Individual Transportation Types
                                    </h4>

                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={form.individualTransportTypeDataList.addItemButton} />
                                </div>

                                <hr/>

                                <DataList
                                    itemTemplate={(item, index) =>
                                        <li>
                                            <div class="grid-container">
                                                <Field
                                                    className="sm-8 mt-0 mb-0"
                                                    field={item.form.fields[0]} />

                                                <Field
                                                    className="sm-2 mt-0 mb-0"
                                                    field={item.form.fields[1]} />

                                                <Field
                                                    className="sm-2 mt-0 mb-0"
                                                    field={item.form.fields[2]} />
                                            </div>
                                        </li>
                                    }
                                    dataList={form.individualTransportTypeDataList} />

                            </div>
                        </div>
                    </Modal>
                }

                <div>
                    {!form.isVendorPortalMode &&
                        <Accordian
                            className="mt-3"
                            headerClassName="tool-accordion-header"
                            accordian={form.accordian}
                            header={(
                                <div class="flex">
                                    <h4 class="mb-0 mt-0 v-center">
                                        Offer Transportation
                                    </h4>

                                    <div class="v-center flex ml-auto">

                                        <ToggleSwitchField
                                            field={form.transportationFields[0]}
                                            className="v-center" />
                                    </div>
                                </div>
                            )}>

                            {this.getFormContents()}
                        </Accordian>
                    }

                    {!!form.isVendorPortalMode && this.getFormContents()}
                </div>
            </div>
        )
    }
}

export default EventTransportationConfigurationForm;