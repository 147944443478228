import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { CostManagementCostFactor, CostManagementServicesTileItem, ExpenseType, Form, Button as FrontEndButton } from 'core.frontend';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import Field from '../fields/field';
import FormattedMoney from '../text/formattedMoney';

export interface CostManagementServiceItemTableRowFormProps {
    form: Form<CostManagementServicesTileItem>;
    deleteButton: FrontEndButton
}

class CostManagementServiceItemTableRowForm extends ObservingComponent<CostManagementServiceItemTableRowFormProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.form.observationProvider);
    }

    public render({ form, deleteButton }: CostManagementServiceItemTableRowFormProps): JSX.Element {

        return (
            <Fragment>
                <tr class={form.model.isRemoved === true ? 'deleting' : ''}>
                    <td>
                        <span>
                            <Field
                                className="width-100 inline-label input-xs"
                                field={form.fields[0]} />

                            <div class="d-block">
                                <Field
                                    className="inline-label input-xs mt-p5"
                                    field={form.fields[4]} />
                            </div>
                        </span>
                    </td>
                    <td class="fit-content">
                        {form.model.primaryCostFactor === CostManagementCostFactor.BusinessArea &&
                            <Field
                                key={form.fields[1].uniqueKey}
                                field={form.fields[1]} />
                        }
                    </td>
                    <td>
                        <Field
                            className="no-label mt-p5"
                            key={form.fields[2].uniqueKey}
                            field={form.fields[2]} />
                    </td>
                    <td>
                        <Field
                            className="no-label mt-p5"
                            field={form.fields[3]} />
                    </td>
                    <td class="fit-content text-right">
                        <Field
                            className="text-right no-label mt-p5"
                            field={form.fields[5]} />
                    </td>
                    <td class="fit-content text-right">
                        <Field
                            className="text-right no-label mt-p5"
                            field={form.fields[6]} />
                    </td>
                    <td class="fit-content text-right">

                        {form.model.expenseType == ExpenseType.Expense &&
                            <FormattedMoney
                                amount={form.model.costDifference}
                                className={
                                    form.model.costDifference > 0 ? 'text-red ' :
                                    form.model.costDifference < 0 ? 'text-green ' : ''
                                } />
                        }

                        {form.model.expenseType == ExpenseType.Credit &&
                            <FormattedMoney
                                amount={form.model.costDifference}
                                className={
                                    form.model.costDifference < 0 ? 'text-red ' :
                                    form.model.costDifference > 0 ? 'text-green ' : ''
                                } />
                        }
                    </td>
                    <td class="fit-content text-center">
                        <Field
                            className="no-label digit-field"
                            field={form.fields[7]} />
                    </td>

                    <td class="fit-content">
                        <Button
                            className="btn-icon"
                            size={ButtonSizes.Small}
                            color={ButtonColors.Primary}
                            button={deleteButton} />
                    </td>
                </tr>
            </Fragment>
        );
    }
}

export default CostManagementServiceItemTableRowForm;
