"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var button_1 = require("../button/button");
var Tag = /** @class */ (function () {
    function Tag(label, tagData, onRemove) {
        this.label = label;
        this.tagData = tagData;
        this.onRemove = onRemove;
        this.setUniqueId();
        this.removeButton = new button_1.default({
            text: 'X',
            onClick: onRemove(this)
        });
    }
    Object.defineProperty(Tag.prototype, "uniqueId", {
        get: function () {
            return this._uniqueId;
        },
        enumerable: true,
        configurable: true
    });
    Tag.prototype.setUniqueId = function () {
        this._uniqueId = ++Tag.tagIdCounter;
    };
    Tag.tagIdCounter = 0;
    return Tag;
}());
exports.default = Tag;
