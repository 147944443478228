import { FieldActions, ActionUtils, MoneyField as FrontEndField } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface MoneyFieldProps {
    id?: string;
    field: FrontEndField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class MoneyField extends ObservingComponent<MoneyFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        var actions = ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]);
        this.registerUpdateObserver(field.observationProvider, actions);
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: MoneyFieldProps): JSX.Element {

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div className={`input-field ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} />}

                <label
                    className={labelClassName}
                    for={id}>


                    <span class="fill">
                        {field.label}
                    </span>


                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>

                <input
                    type="number"
                    step="0.01"
                    min="0.00"
                    max="999999.99"
                    id={id}
                    name={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    value={field.value}
                    onChange={this.valueChanged}
                    className={field.isInvalid ? 'invalid' : ''}
                    disabled={field.isDisabled} />

            </div>
        );
    }
}

export default MoneyField;
