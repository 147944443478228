"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PageTypes;
(function (PageTypes) {
    PageTypes[PageTypes["Login"] = 0] = "Login";
    PageTypes[PageTypes["EventList"] = 1] = "EventList";
    PageTypes[PageTypes["UserList"] = 2] = "UserList";
    PageTypes[PageTypes["CustomerList"] = 3] = "CustomerList";
    PageTypes[PageTypes["CustomerDetail"] = 4] = "CustomerDetail";
    PageTypes[PageTypes["RequestPasswordReset"] = 5] = "RequestPasswordReset";
    PageTypes[PageTypes["PasswordResetRequestText"] = 6] = "PasswordResetRequestText";
    PageTypes[PageTypes["ResetPassword"] = 7] = "ResetPassword";
    PageTypes[PageTypes["UserDetail"] = 8] = "UserDetail";
    PageTypes[PageTypes["RequestAccess"] = 9] = "RequestAccess";
    PageTypes[PageTypes["CompleteAccessRequest"] = 10] = "CompleteAccessRequest";
    PageTypes[PageTypes["EventLanding"] = 11] = "EventLanding";
    PageTypes[PageTypes["History"] = 12] = "History";
    PageTypes[PageTypes["VendorUserList"] = 13] = "VendorUserList";
    PageTypes[PageTypes["VendorUserDetail"] = 14] = "VendorUserDetail";
    PageTypes[PageTypes["EventConfiguration"] = 15] = "EventConfiguration";
    PageTypes[PageTypes["UserProfile"] = 16] = "UserProfile";
    PageTypes[PageTypes["NewEvent"] = 17] = "NewEvent";
    PageTypes[PageTypes["InventoryList"] = 18] = "InventoryList";
    PageTypes[PageTypes["InventoryDetailPage"] = 19] = "InventoryDetailPage";
    PageTypes[PageTypes["UserGroupList"] = 20] = "UserGroupList";
    PageTypes[PageTypes["UserGroupDetail"] = 21] = "UserGroupDetail";
    PageTypes[PageTypes["InventorySchedulerPage"] = 22] = "InventorySchedulerPage";
    PageTypes[PageTypes["EventOverviewReport"] = 23] = "EventOverviewReport";
    PageTypes[PageTypes["InventoryForecast"] = 24] = "InventoryForecast";
    PageTypes[PageTypes["SystemSettings"] = 25] = "SystemSettings";
    PageTypes[PageTypes["EventHistory"] = 26] = "EventHistory";
    PageTypes[PageTypes["CustomerCompanyList"] = 27] = "CustomerCompanyList";
    PageTypes[PageTypes["CustomerCompanyDetail"] = 28] = "CustomerCompanyDetail";
    PageTypes[PageTypes["VendorCompaniesPage"] = 29] = "VendorCompaniesPage";
    PageTypes[PageTypes["VendorCompanyDetailPage"] = 30] = "VendorCompanyDetailPage";
    PageTypes[PageTypes["AttendeeReport"] = 31] = "AttendeeReport";
    PageTypes[PageTypes["MeetingsReportPage"] = 32] = "MeetingsReportPage";
    PageTypes[PageTypes["TenantList"] = 33] = "TenantList";
    PageTypes[PageTypes["TenantDetailPage"] = 34] = "TenantDetailPage";
    PageTypes[PageTypes["HotelReportPage"] = 35] = "HotelReportPage";
    PageTypes[PageTypes["TransportationReportPage"] = 36] = "TransportationReportPage";
    PageTypes[PageTypes["VendorUserProfile"] = 37] = "VendorUserProfile";
    PageTypes[PageTypes["VendorEventList"] = 38] = "VendorEventList";
    PageTypes[PageTypes["VendorEmailTemplates"] = 39] = "VendorEmailTemplates";
    PageTypes[PageTypes["VendorEmailTemplate"] = 40] = "VendorEmailTemplate";
    PageTypes[PageTypes["VendorEventLandingPage"] = 41] = "VendorEventLandingPage";
    PageTypes[PageTypes["UrlAssignerPage"] = 42] = "UrlAssignerPage";
    PageTypes[PageTypes["MeetingPlacard"] = 43] = "MeetingPlacard";
    PageTypes[PageTypes["RoomDisplayPage"] = 44] = "RoomDisplayPage";
    PageTypes[PageTypes["AttendeeDataGridReport"] = 45] = "AttendeeDataGridReport";
    PageTypes[PageTypes["VenueDataGridReport"] = 46] = "VenueDataGridReport";
    PageTypes[PageTypes["EmailTemplates"] = 47] = "EmailTemplates";
    PageTypes[PageTypes["EmailTemplate"] = 48] = "EmailTemplate";
    PageTypes[PageTypes["VendorCompanyInfo"] = 49] = "VendorCompanyInfo";
    PageTypes[PageTypes["GolfCartReport"] = 50] = "GolfCartReport";
    PageTypes[PageTypes["DiningReport"] = 51] = "DiningReport";
    PageTypes[PageTypes["HelpUrls"] = 52] = "HelpUrls";
    PageTypes[PageTypes["DeviceRegistration"] = 53] = "DeviceRegistration";
    PageTypes[PageTypes["SsoLogin"] = 54] = "SsoLogin";
    //<NEXT>
})(PageTypes || (PageTypes = {}));
exports.default = PageTypes;
