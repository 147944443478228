import { h, JSX } from 'preact';
import DiningReportPageProps from './diningReportPageProps';
import ObservingComponent from '../../../componentBases/observingComponent';
import Button from '../../../buttons/button';
import FormattedDate from '../../../text/formattedDate';
import { DateFormats, MeetingStatuses } from 'core.frontend';
import MeetingAttendeesLabel from '../../../meetings/meetingAttendeesLabel';

class DiningReportPage extends ObservingComponent<DiningReportPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: DiningReportPageProps): JSX.Element {
        return (
            <div class="report">
                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                <h3 class="text-center pl-1 pr-1 mt-1">
                    {!page.isLoading &&
                        <span>{page.reportData.eventName}</span>
                    }
                </h3>

                <div class="text-sm flex mb-1">
                    <div class="fill">
                        Printed by: {page.currentUsersName}
                        <div><b>{page.title}</b></div>
                    </div>
                    <div class="ml-2 text-right">

                        <FormattedDate
                            date={new Date()}
                            format={DateFormats.dddMMMMDhmmA} />

                        {!page.isLoading &&
                            <div>
                                <span>Total Rows: </span>
                                {page.reportData.reportDataItems.length}
                            </div>
                        }
                    </div>
                </div>

                {!page.isLoading && !!page.reportData.reportDataItems.length &&
                    <div class="report-page text-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th class="text-center">Food Needed</th>
                                    <th class="text-center">#</th>
                                    <th>Date/Time</th>
                                    <th class="text-center">Count</th>
                                    <th>Meeting Name</th>
                                    <th>Guests</th>
                                    <th>Company Attendees</th>
                                    <th>Assigned Venue</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {page.reportData.reportDataItems.map((meeting,index) =>
                                    <tr class={meeting.status === MeetingStatuses.Working ? 'bg-gray' :
                                            meeting.status === MeetingStatuses.Requested ? 'bg-black' : ''}>
                                        <td class="text-center">{meeting.foodNeeded && <span>Yes</span>}</td>
                                        <td class="text-center">{meeting.meetingNumber}</td>
                                        <td class="text-center">
                                            <FormattedDate
                                                date={meeting.startDateTime}
                                                format={DateFormats.MMMd} />

                                            <br/>

                                            <FormattedDate
                                                date={meeting.startDateTime}
                                                format={DateFormats.hhmm} />

                                                <span> - </span>

                                            <FormattedDate
                                                date={meeting.endDateTime}
                                                format={DateFormats.hhmm} />
                                        </td>
                                        <td class="text-center">
                                            {meeting.totalAttendees}
                                        </td>
                                        <td>{meeting.name}</td>
                                        <td>
                                            {meeting.guestsArrived &&
                                                <div class="text-red">
                                                    Guests Arrived
                                                </div>
                                            }

                                            {meeting.guests.map((guest) =>
                                                <div>
                                                    {guest.title} {guest.firstName} {guest.lastName}
                                                    {guest.isLead &&
                                                        <span> (L)</span>
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <MeetingAttendeesLabel meeting={meeting} />
                                        </td>
                                        <td>
                                            {meeting.venue}

                                            {meeting.assignedTables &&
                                                <span class='text-sm'> / {meeting.assignedTables}</span>
                                            }
                                        </td>
                                        <td>{meeting.printedComments}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div class="mt-2">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Meeting Status Legand</th>
                                    </tr>
                                </thead>
                                <tr class="bg-gray">
                                    <td>Working</td>
                                </tr>
                                <tr class="bg-black">
                                    <td>Requested</td>
                                </tr>
                                <tr>
                                    <td>Finalized</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                }

                {!page.isLoading && !page.reportData.reportDataItems.length &&
                    <div className="text-center mt-2">
                        <b>No dining information found</b>
                    </div>
                }

                {page.isLoading &&
                    <div className="text-center mt-2">
                        <b>Loading...</b>
                    </div>
                }
            </div>
        );
    }
}

export default DiningReportPage;