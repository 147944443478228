import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import LoginPageWrapper from './loginPageWrapper';
import CompleteAccessRequestForm from './completeAccessRequestForm';
import CompleteAccessRequestPageProps from './completeAccessRequestPageProps';

export class CompleteAccessRequestPage extends ObservingComponent<CompleteAccessRequestPageProps> {

    public render({ page }: CompleteAccessRequestPageProps): JSX.Element {
        return (
            <LoginPageWrapper>
                <CompleteAccessRequestForm page={page} />
            </LoginPageWrapper>
        );
    }
}

export default CompleteAccessRequestPage;
