import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import DataListProps from './dataListProps';
import Field from '../fields/field';
import Alert from '../alerts/alert';
import { DataListItemTypeBase, FieldType, spaceCamelCase } from 'core.frontend';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import EmptyState from '../uiStates/emptyState';

class DataList<T extends DataListItemTypeBase> extends ObservingComponent<DataListProps<T>> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.dataList.observationProvider);
    }

    public render(): JSX.Element {
        const { dataList, itemTemplate, className } = this.props;

        if (!dataList.isVisible) {
            return null;
        }

        return (
            <div class={className + ' form-container data-list-wrapper'}>
                {!dataList.isLoading &&
                    <div class="mt-p5">
                        {!dataList.getEmptyStateIsVisible() &&
                            <ul class="data-list mt-p5">
                                {dataList.items.filter(item => item.isVislble).map((item, index) =>
                                    <li
                                        class={item.form.model.isRemoved ? 'deleting' : ''}
                                        key={item.form.uniqueKey}>

                                        <Alert
                                            className="mb-p5"
                                            alert={item.alert}>
                                            <div class="text-sm mt-p5">
                                                {item.form.model.references && item.form.model.references.map((reference) => {
                                                    if (reference.referenceCount > 0) {
                                                        return <div>
                                                            Referenced to: {spaceCamelCase(reference.tableName)} | Number of references: {reference.referenceCount}
                                                        </div>
                                                    }
                                                })}
                                            </div>
                                        </Alert>

                                        <div class="flex">
                                            {dataList.isSortable &&
                                                <div class="flex column mr-p5">
                                                    <Button
                                                        className="btn-icon m-auto"
                                                        color={ButtonColors.Secondary}
                                                        button={dataList.buildMoveUpButton(index)} />

                                                    <Button
                                                        className="btn-icon m-auto"
                                                        color={ButtonColors.Secondary}
                                                        button={dataList.buildMoveDownButton(index)} />
                                                </div>
                                            }

                                            <div class="fill">

                                                {itemTemplate && itemTemplate(item, index)}

                                                {!itemTemplate &&
                                                    <div class="flex">
                                                        {item.form.fields.map((field, index) => {
                                                            let wrapperClass = 'fill mt-0 mb-0';
                                                            if (index > 0) {
                                                                wrapperClass += ' ml-1 ';
                                                            }
                                                            if (field.fieldType === FieldType.CheckBox) {
                                                                wrapperClass += ' mt-1p5 ';
                                                            }

                                                            return <Field
                                                                className={wrapperClass}
                                                                field={field}
                                                                key={field.uniqueKey} />
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <Button
                                            className="btn-remove-data-item"
                                            button={dataList.buildDeleteButton(item)} />
                                    </li>
                                )}
                            </ul>
                        }

                        {dataList.getEmptyStateIsVisible() &&
                            <EmptyState
                                title={dataList.emptyStateHeading}
                                message={dataList.emptyStateMessage} />
                        }
                    </div>
                }

                {dataList.isLoading &&
                    [0,1,2,3,4].map(() =>
                            <div class="loading-placeholder shimmer"></div>
                    )
                }
            </div>
        );
    }
}

export default DataList;
