"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DateFormats;
(function (DateFormats) {
    DateFormats["MMDDYYYY"] = "MM/DD/YYYY";
    DateFormats["YYYYMMDD"] = "YYYY-MM-DD";
    DateFormats["hmmA"] = "h:mm A";
    DateFormats["hhCmm"] = "hh:mm";
    DateFormats["hhmm"] = "hhmm";
    DateFormats["hhmmss"] = "hhmmss";
    DateFormats["dddMMMMDhmmA"] = "ddd, MMMM D h:mm A";
    DateFormats["dddMMMMD"] = "ddd, MMMM D";
    DateFormats["MMMMd"] = "MMMMd";
    DateFormats["MMMd"] = "MMMd";
    DateFormats["MMMM"] = "MMMM";
    DateFormats["MMMdYYYY"] = "MMMdYYYY";
})(DateFormats || (DateFormats = {}));
exports.default = DateFormats;
