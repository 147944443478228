import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import LabeledDivider from '../text/labeledDivider';
import ResetPasswordPageProps from './resetPasswordPageProps';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';

class ResetPasswordForm extends ObservingComponent<ResetPasswordPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: ResetPasswordPageProps): JSX.Element {

        return (
            <form noValidate>

                <div class="card-header text-center">
                    <img src="img/logo-blue.png" class="header-logo-blue" />
                    <LabeledDivider text="Request Password" />
                </div>

                <div class="card-main">
                    <Alert alert={page.alert} />

                    { page.form.fields.map((field) => {
                        return <Field
                                    field={field}
                                    className="icon-field input-lg floating-label mt-1"
                                    leftIcon="ti-key" />
                    })}

                    <p>
                        Password must be at least 8 characters, contain 1 upper-case, 1 lower-case, 1 special character and not be one of your previous 12 passwords.
                    </p>

                    <Button
                        color={ButtonColors.Primary}
                        className="btn-full-width mt-2"
                        button={page.submitButton} />

                </div>

            </form>
        );
    }
}

export default ResetPasswordForm;
