"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var numberDropdownField_1 = require("./numberDropdownField");
var api_1 = require("../../api");
var EventStatusDropdown = /** @class */ (function (_super) {
    __extends(EventStatusDropdown, _super);
    function EventStatusDropdown(key, isRequired) {
        var _this = _super.call(this, key, 'Event Status', isRequired, [
            { value: api_1.EventStatus.Active, label: 'Upcoming' },
            { value: api_1.EventStatus.Archived, label: 'Archived' },
            { value: api_1.EventStatus.Deleted, label: 'Deleted' }
        ], 'Event status is required', null, _1.FieldType.DropDown) || this;
        _this.key = key;
        return _this;
    }
    EventStatusDropdown.prototype.setValue = function (value) {
        _super.prototype.setValue.call(this, value);
    };
    return EventStatusDropdown;
}(numberDropdownField_1.default));
exports.default = EventStatusDropdown;
