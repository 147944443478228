import { h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import Button from '../../buttons/button';
import DownloadButton from '../../buttons/downloadButton';
import DownloadButtonHandlingMethod from '../../buttons/downloadButtonHandlingMethod';
import FormattedDate from '../../text/formattedDate';
import Icon from '../../icons/icon';
import { Icons as FrontEndIcon, Button as FrontEndButton, DownloadButton as FrontEndDownloadButton, TextField, Icons, FieldActions} from 'core.frontend';
import Field from '../../fields/field';

export interface FileListItemProps {
    deleteButton?: FrontEndButton;
    downloadButton: FrontEndDownloadButton;
    isApp: boolean;
    fileType: string;
    createdDateTime?: Date;
    uploadedByUsersName?: string;
    icon: FrontEndIcon;
    isEditMode?: boolean;
    isDownloadable: boolean;
    friendlyNameField: TextField;
    descriptionField: TextField;
    fileName: string;
    fileSize: number;
    allowManualEditMode?: boolean;
}

export interface FileListItemState {
    manualEditMode: boolean;
}

class FileListItem extends ObservingComponent<FileListItemProps, FileListItemState> {

    public componentWillMount(): void {
        if (this.props.friendlyNameField) {
            this.registerUpdateObserver(this.props.friendlyNameField.observationProvider, FieldActions.Blured);
        }

        if (this.props.descriptionField) {
            this.registerUpdateObserver(this.props.descriptionField.observationProvider, FieldActions.Blured);
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            manualEditMode: false
        };
    }

    private toggleEditMode = (event: MouseEvent) => {
        this.setState({manualEditMode: !this.state.manualEditMode});
    }

    private getHandlingMethod(fileType: string): DownloadButtonHandlingMethod {
        const inlineFileTypes = [
            'application/pdf'
        ];

        const carouselFileTypes = [
            'image/jpeg',
            'image/png',
            'image/bmp',
            'image/jpg',
            'image/gif',
        ];

        if (inlineFileTypes.indexOf(fileType.toLowerCase()) >= 0) {
            return DownloadButtonHandlingMethod.Inline;
        } else if (carouselFileTypes.indexOf(fileType.toLowerCase()) >=0 ) {
            return DownloadButtonHandlingMethod.Carousel;
        }

        return DownloadButtonHandlingMethod.Download;
    }


    public render({ fileName, fileSize, deleteButton, allowManualEditMode, downloadButton, isApp, isDownloadable, friendlyNameField, descriptionField, isEditMode, icon, fileType, createdDateTime, uploadedByUsersName }: FileListItemProps): JSX.Element {
        const showEditMode = isEditMode || this.state.manualEditMode;

        return (
            <div class="flex">

                <Icon
                    className="v-center text-lg ml-p5 mr-1"
                    icon={icon} />

                {!showEditMode &&
                    <div class="fill v-center">

                        {isDownloadable &&
                            <DownloadButton
                                className="btn-link"
                                button={downloadButton}
                                stopPropagation={true}
                                handlingMethod={this.getHandlingMethod(fileType)}
                                target={isApp ? '_self' : '_blank'} />
                        }

                        {!isDownloadable &&
                            <span>{fileName} - {fileSize}</span>
                        }

                        <br />

                        {!!descriptionField && !!descriptionField.value &&
                            <div class="text-sm">
                                {descriptionField.value}
                                <br/>
                            </div>
                        }

                        <span class="text-sm">
                            Uploaded on <FormattedDate date={createdDateTime} /> by {uploadedByUsersName}
                        </span>
                    </div>
                }

                {showEditMode &&
                    <div class="fill">
                        <div class="flex">
                            {friendlyNameField &&
                                <Field
                                    className="v-center"
                                    field={friendlyNameField} />
                            }

                            {descriptionField &&
                                <Field
                                    className="v-center ml-1 fill"
                                    field={descriptionField} />
                            }
                        </div>

                        <div class="flex text-sm">
                            <div>
                                <span>{fileName} - {fileSize}</span>
                            </div>

                            {uploadedByUsersName && createdDateTime &&
                                <div class="ml-auto">
                                    Uploaded on <FormattedDate date={createdDateTime} /> by {uploadedByUsersName}
                                </div>
                            }
                        </div>
                    </div>
                }

                {allowManualEditMode &&
                    <a
                        class="btn-edit-data-item"
                        onClick={this.toggleEditMode}>

                        <Icon icon={Icons.Edit} />
                    </a>
                }

                {!!deleteButton &&
                    <Button
                        className="btn-icon"
                        button={deleteButton} />
                }

            </div>
        );
    }
}

export default FileListItem;