"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var EventOverviewReportPage = /** @class */ (function (_super) {
    __extends(EventOverviewReportPage, _super);
    function EventOverviewReportPage(api, currentUserManager, reportOptions) {
        var _this = _super.call(this, __1.PageTypes.EventOverviewReport, 'Event Overview Report') || this;
        _this.api = api;
        _this.currentUserManager = currentUserManager;
        _this.reportOptions = reportOptions;
        _this.observationProvider = new __1.ObservationProvider();
        _this._loadingLabel = 'Initializing Report';
        _this._reportData = [];
        _this.loadData();
        return _this;
    }
    Object.defineProperty(EventOverviewReportPage.prototype, "loadingLabel", {
        get: function () {
            return this._loadingLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewReportPage.prototype, "eventIds", {
        get: function () {
            return this._eventIds;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewReportPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewReportPage.prototype, "reportData", {
        get: function () {
            return this._reportData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventOverviewReportPage.prototype, "currentUsersName", {
        get: function () {
            return this.currentUserManager.userFullName;
        },
        enumerable: true,
        configurable: true
    });
    EventOverviewReportPage.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, i, events, _i, _b, event_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._isLoading = true;
                        _a = this;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Events + '/GetEventIdsBySearchParams', this.reportOptions)];
                    case 1:
                        _a._eventIds = _c.sent();
                        this._reportData = [];
                        i = 0;
                        _c.label = 2;
                    case 2:
                        if (!(i < this._eventIds.length)) return [3 /*break*/, 5];
                        this._loadingLabel = "Loading report " + (i + 1) + " of " + this._eventIds.length;
                        this.reportOptions.eventId = this._eventIds[i];
                        this.observationProvider.notifyObservers(__1.EventOverviewReportPageActions.LoadingNextReport);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Events + '/Reports/EventOverview', this.reportOptions)];
                    case 3:
                        events = _c.sent();
                        if (events && events[0]) {
                            this._reportData.push(events[0]);
                        }
                        _c.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5:
                        this._isLoading = false;
                        for (_i = 0, _b = this._reportData; _i < _b.length; _i++) {
                            event_1 = _b[_i];
                            if (!this.reportOptions.showInventory) {
                                event_1.inventoryManagementEnabled = false;
                            }
                            event_1.startDate = __1.DateUtils.formatDate(event_1.startDate);
                            event_1.endDate = __1.DateUtils.formatDate(event_1.endDate);
                            this.buildArrayGroups(event_1);
                        }
                        this.observationProvider.notifyObservers(__1.EventOverviewReportPageActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    EventOverviewReportPage.prototype.buildArrayGroups = function (event) {
        if (event.eventInventoryRequests) {
            event.unfulfilledEventInventoryRequests = event.eventInventoryRequests.filter(function (request) { return request.eventInventoryRequestStatus === __1.EventInventoryRequestStatus.Fulfilled; });
            event.fulfilledEventInventoryRequests = event.eventInventoryRequests.filter(function (request) { return request.eventInventoryRequestStatus === __1.EventInventoryRequestStatus.Requested; });
        }
        else {
            event.unfulfilledEventInventoryRequests = [];
            event.fulfilledEventInventoryRequests = [];
        }
        event.eventInventoryArrayGroup =
            __1.ArrayUtils.groupBy({ key: 'categoryName', label: 'Capability', enabled: true, defaultHeading: 'Capability Unknown' }, event.eventInventory);
        event.attendeeArrayGroup =
            __1.ArrayUtils.groupBy([
                { key: 'businessAreaName', label: 'Business Area', enabled: true },
                { key: 'businessAreaAndLineOfBusiness', label: 'Line of Business', enabled: true }
            ], event.attendees);
        event.notAttendingAttendeesGroup =
            __1.ArrayUtils.groupBy([
                { key: 'businessAreaAndLineOfBusiness', label: 'Business Area / Line of Business', enabled: true }
            ], event.notAttendingAttendees);
    };
    return EventOverviewReportPage;
}(__1.BasePage));
exports.default = EventOverviewReportPage;
