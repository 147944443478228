import { Fragment, h, JSX } from 'preact';
import AlertProps from './alertProps';
import Button from '../buttons/button';
import ButtonSizes from '../buttons/buttonSizes';
import ButtonColors from '../buttons/buttonColors';
import { AlertType } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';

class Alert extends ObservingComponent<AlertProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.alert.observationProvider);
    }

    public render({ alert, className, children}: AlertProps): JSX.Element {
        const { alertType, heading, message, button, isVisible } = alert;

        if (!isVisible) {
            return;
        }

        let wrapperClass = 'alert ';
        let innerWrapperClass = '';
        let messageClass = '';

        if (alertType === AlertType.Success) {
            wrapperClass += ' alert-success ';
        } else {
            wrapperClass += ' alert-danger ';
        }

        if (className) {
            wrapperClass += ' ' + className + ' ';
        }

        if (button) {
            wrapperClass += ' flex '
            innerWrapperClass += ' fill '
        }

        if (heading) {
            wrapperClass += ' text-left '
            messageClass += ' text-sm';
        }

        const messageParts = message.split('<br/>');

        return (
            <div class={wrapperClass}>
                <div class={innerWrapperClass}>
                    {!!heading &&
                        <b>{heading}</b>
                    }

                    <div class={messageClass}>
                        {messageParts.map((messagePart, index) => (
                            <Fragment>
                                {index > 0 &&
                                    <br/>
                                }

                                {messagePart}
                            </Fragment>
                        ))}
                    </div>

                    {!!children && children}
                </div>

                {!!button &&
                    <Button
                        className="ml-1"
                        color={ButtonColors.Secondary}
                        size={ButtonSizes.Small}
                        button={button} /> }
            </div>
        );
    }
}

export default Alert;
