import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import EventHotelConfigurationFormProps from './eventHotelConfigurationFormProps';
import Modal from '../../containers/modal';
import Button from '../../buttons/button';
import ButtonColors from '../../buttons/buttonColors';
import ButtonSizes from '../../buttons/buttonSizes';
import Field from '../../fields/field';
import RichTextField from '../../fields/richTextField';
import DataList from '../../dataList/dataList';
import FormattedDate from '../../text/formattedDate';
import { EventConfigurationPageHotelPanes } from 'core.frontend';
import Accordian from '../../accordian/accordian';
import ToggleSwitchField from '../../fields/toggleSwitchField';

class EventHotelConfigurationForm<T> extends ObservingComponent<EventHotelConfigurationFormProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.form.observationProvider);
    }

    private getFormContents = () => {
        const { form } = this.props;

        return (
            <Fragment>
                {form.hotelFields[0].value &&
                        <div class="form-container">
                            <div class="btn-group mt-1 flex">
                                <Button
                                    size={ButtonSizes.Small}
                                    color={ButtonColors.Primary}
                                    button={form.showVendorAccessPaneButton}
                                    className={'fill ' + (form.activeHotelPane === EventConfigurationPageHotelPanes.VendorAccess ? 'active ' : '' )} />
                                <Button
                                    size={ButtonSizes.Small}
                                    color={ButtonColors.Primary}
                                    button={form.showLodgingOptionsButton}
                                    className={'fill ' + (form.activeHotelPane === EventConfigurationPageHotelPanes.LodgingOptions ? 'active ' : '' )} />
                                <Button
                                    color={ButtonColors.Primary}
                                    size={ButtonSizes.Small}
                                    button={form.showHotelTermsAndConditionsPaneButton}
                                    className={' fill ' + (form.activeHotelPane === EventConfigurationPageHotelPanes.TermsAndConditions ? 'active ' : '' )} />
                            </div>

                            {form.activeHotelPane === EventConfigurationPageHotelPanes.VendorAccess &&
                                <div class="mt-1">
                                    <p class="text-sm">
                                        <b>Note: </b> By providing a vendor company access to this this event they will be able to login and setup hotel options for this event through their vendor login.
                                        You can also setup these options for vendors in the hotel Vendor/Options tab.
                                    </p>

                                    <div class="flex mt-2">
                                        <Field
                                            className="fill inline-label mr-p5 input-sm"
                                            field={form.hotelVendorCompanyDropdown} />

                                        <Button
                                            size={ButtonSizes.Small}
                                            color={ButtonColors.Primary}
                                            button={form.addHotelVendorButton} />
                                    </div>

                                    <DataList
                                        dataList={form.hotelVendorDataList}
                                        itemTemplate={(item) => <span>{item.form.model.vendorCompany}</span>} />
                                </div>
                            }

                            {form.activeHotelPane === EventConfigurationPageHotelPanes.LodgingOptions &&
                                <div class="mt-1">

                                    <div class="text-right">
                                        <Button
                                            color={ButtonColors.Primary}
                                            size={ButtonSizes.Small}
                                            button={form.addLodgingButton} />
                                    </div>

                                    <DataList
                                        dataList={form.hotelDataList}
                                        itemTemplate={(item) =>
                                            <div>
                                                <div class="flex">
                                                    <div class="fill">
                                                        <Button
                                                            className="btn-link"
                                                            button={form.buildEditHotelButton(item)} />

                                                        {item.form.model.hotelId &&
                                                            <span class="text-sm"> ({item.form.model.rewardsChainName})</span>
                                                        }

                                                        <div class="text-sm">
                                                            Cut-off date: <FormattedDate date={item.form.model.cutOffDate} /> | Check-In: {item.form.model.checkInTime} | Check-Out: {item.form.model.checkOutTime}
                                                        </div>
                                                    </div>

                                                    <div class="mt-1 text-right">
                                                        {item.form.model.vendorCompanyName}
                                                        <br/>
                                                        <span class="text-sm">{item.form.model.contactName} | {item.form.model.phone}</span>
                                                    </div>
                                                </div>

                                                <div class="flex text-sm">
                                                    <div class="mr-2">
                                                        {item.form.model.address}
                                                        <br/>
                                                        {item.form.model.city} {item.form.model.state} {item.form.model.zip}
                                                        <br/> {item.form.model.country}
                                                    </div>

                                                    <div class="text-sm">
                                                        <b>Hotel Type Options</b>
                                                        <br/>

                                                        {item.form.model.eventHotelRooms.map((room) =>
                                                            <span>
                                                                {room.name}
                                                                <br/>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div class="text-sm">
                                                    Comments: {item.form.model.comments}
                                                </div>
                                            </div>
                                        }/>
                                </div>
                            }

                            {form.activeHotelPane === EventConfigurationPageHotelPanes.TermsAndConditions &&
                                <RichTextField
                                    isLoading={!form.isLoaded}
                                    className="settings-term-field"
                                    field={form.hotelTermsAndConditionsField} />
                            }

                        </div>
                    }
            </Fragment>
        )
    }

    public render({ form, helpButton, isApp }: EventHotelConfigurationFormProps): JSX.Element {
        return (
            <div>
                {form.hotelFormIsVisible &&
                    <Modal
                        heading={form.hotelForm.model.id ? 'Edit Hotel Type' : 'New Hotel Type'}
                        close={form.hideHotelForm}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={form.hideHotelFormButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={form.updateHotelFormButton} />,

                        ]}>

                        <div class="pt-1">
                            <div class="flex v-center">
                                <h4 class="v-center fill mb-0">
                                    Hotel Options
                                </h4>

                                <Button
                                    className="btn-icon btn-lg"
                                    button={helpButton} />
                            </div>

                            <hr/>

                            <div class="form-container">
                                <div class="grid-container">
                                    {form.hotelForm.fields.map((field, index) => {
                                        if (index === 0 && form.isVendorPortalMode) {
                                            return <div class="sm-7">&nbsp;</div>
                                        }

                                        return (
                                            <Field
                                                className={[0].indexOf(index) !== -1 ? 'sm-7' :
                                                        [2,3,12,13].indexOf(index) !== -1 ? 'sm-6' :
                                                        [7,8,9,10].indexOf(index) !== -1 ? 'sm-3' :
                                                        [4,5,6].indexOf(index) !== -1 ? 'sm-4' :
                                                        [1].indexOf(index) !== -1 ? 'sm-5' :
                                                        [14].indexOf(index) !== -1 ? 'sm-12 mb-0' : 'sm-12' }
                                                field={field} />
                                        )}
                                    )}
                                </div>

                                <div class="text-red text-sm mb-1">
                                    The information below will be specific to this event. The Contact info will be used by Attendees to communicate questions concerning this event
                                </div>

                                <div class="flex v-center">
                                    <h4 class="v-center fill mb-0">
                                        Hotel Types
                                    </h4>

                                    <Button
                                        color={ButtonColors.Primary}
                                        size={ButtonSizes.Small}
                                        button={form.hotelRoomDataList.addItemButton} />
                                </div>

                                <hr/>

                                <DataList dataList={form.hotelRoomDataList} />

                            </div>
                        </div>
                    </Modal>
                }

                <div>
                    {!form.isVendorPortalMode &&

                        <Accordian
                            className="mt-3"
                            headerClassName="tool-accordion-header"
                            accordian={form.accordian}
                            header={(
                                <div class="flex">
                                    <h4 class="mb-0 mt-0 v-center">
                                        Offer Hotel
                                    </h4>

                                    <div class="v-center flex ml-auto">

                                        <ToggleSwitchField
                                            field={form.hotelFields[0]}
                                            className="v-center" />
                                    </div>
                                </div>
                            )}>

                            {this.getFormContents()}
                        </Accordian>
                    }

                    {!!form.isVendorPortalMode && this.getFormContents()}
                </div>
            </div>
        )
    }
}

export default EventHotelConfigurationForm;