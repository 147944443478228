"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var components_1 = require("../../components");
var button_1 = require("../../components/button/button");
var MeetingPlacardPage = /** @class */ (function (_super) {
    __extends(MeetingPlacardPage, _super);
    function MeetingPlacardPage(api, eventId, passKey) {
        var _this = _super.call(this, __1.PageTypes.MeetingPlacard, 'Meeting Placard') || this;
        _this.api = api;
        _this.eventId = eventId;
        _this.passKey = passKey;
        _this.loadPlacard = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, i, _b, err_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.MeetingPlacardPageActions.MeetingLoading);
                        _a = this;
                        return [4 /*yield*/, this.api.getVenuesByPasskey(this.eventId, this.passKey)];
                    case 1:
                        _a._venues = _c.sent();
                        for (i = 0; i < this._venues.length; i++) {
                            if (this._venues[i].id == this.form.model.venueId) {
                                this._venueName = this._venues[i].name;
                            }
                        }
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, 5, 6]);
                        this.alert.hide();
                        _b = this;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Public + '/MeetingPlacard', this.form.model)];
                    case 3:
                        _b._meetingPlacardData = _c.sent();
                        if (!this._meetingPlacardData) {
                            return [2 /*return*/];
                        }
                        /*
                        if (this._meetingPlacardData.startDateTime) {
                            this._meetingPlacardData.startDateTime = DateUtils.CreateDateMaintainTimezone(this._meetingPlacardData.startDateTime);
                            this._meetingPlacardData.endDateTime = DateUtils.CreateDateMaintainTimezone(this._meetingPlacardData.endDateTime);
                        }
            
                        if (this._meetingPlacardData.nextMeetingDateTime) {
                            this._meetingPlacardData.nextMeetingDateTime = DateUtils.CreateDateMaintainTimezone(this._meetingPlacardData.nextMeetingDateTime);
                        }
                        */
                        if (!this._intervalId) {
                            this._intervalId = setInterval(this.loadPlacard, 60000);
                        }
                        return [3 /*break*/, 6];
                    case 4:
                        err_1 = _c.sent();
                        clearInterval(this._intervalId);
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Load Meeting',
                            message: 'TEMS was not able to load meeting data for this placard. Refresh this page to try again.'
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        this._settingsVisible = false;
                        this.observationProvider.notifyObservers(__1.MeetingPlacardPageActions.MeetingLoaded);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new __1.ObservationProvider();
        _this._settingsVisible = true;
        _this._form = new __1.Form([
            new components_1.VenueField(api, eventId, 'venueId', 'Room', true, 'Room selection required', null, null, passKey),
            new components_1.CheckBoxField('displayVenueName', 'Assigned Venue Name'),
            new components_1.CheckBoxField('displayMeetingNumber', 'Meeting Number'),
            new components_1.CheckBoxField('displayMeetingName', 'Meeting Name'),
            new components_1.CheckBoxField('displayStartEndTime', 'Start Time - End Time'),
            new components_1.CheckBoxField('displayNextMeetingStartTime', 'Next Meeting Start Time'),
        ], {
            eventId: eventId,
            passKey: passKey,
            venueId: null,
            displayVenueName: true,
            displayMeetingNumber: true,
            displayMeetingName: true,
            displayStartEndTime: true,
            displayNextMeetingStartTime: true
        });
        _this.form.observationProvider.observe(_this.loadPlacard, components_1.FormActions.FormSubmitted);
        _this.submitButton = new button_1.default({
            text: 'Show Placard',
            onClick: _this.form.submit,
            processingText: 'Loading...'
        });
        return _this;
    }
    Object.defineProperty(MeetingPlacardPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingPlacardPage.prototype, "settingsVisible", {
        get: function () {
            return this._settingsVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingPlacardPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingPlacardPage.prototype, "meetingPlacardData", {
        get: function () {
            return this._meetingPlacardData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingPlacardPage.prototype, "venueName", {
        get: function () {
            return this._venueName;
        },
        enumerable: true,
        configurable: true
    });
    return MeetingPlacardPage;
}(__1.BasePage));
exports.default = MeetingPlacardPage;
