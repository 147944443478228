import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import CurrentUserAvatarProps from './currentUserAvatarProps';
import Avatar from './avatar';

class CurrentUserAvatar extends ObservingComponent<CurrentUserAvatarProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.currentUserManager.observationProvider);
    }

    public render({ currentUserManager, className }): JSX.Element {
            return <Avatar
                        className={className}
                        url={currentUserManager.profileImageUrl} />
    }
}

export default CurrentUserAvatar;
