import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import DateField from './dateField';
import FieldProps from './fieldProps';
import SearchField from './searchField';
import TextField from './textField';
import PasswordField from './passwordField';
import MoneyField from './moneyField';
import FieldLoadingPlaceholder from './fieldLoadingPlaceholder';
import DropdownField from'./dropdownField';
import VenueMultiselectField from './venueMultiselectField';
import CheckboxField from './checkboxField';
import FileInputField from './fileInputField';
import IntField from './intField';
import TimeField from'./timeField';
import BinaryFileInputField from './binaryFileInputField';
import MultipleEmailField from './multipleEmailField';
import {
    BaseDropdownField as FrontEndDropdownField,
    TimeField as FrontEndTimeField,
    CheckBoxField as FrontEndCheckBoxField,
    FieldType,
    MoneyField as FrontEndMoneyField,
    PasswordField as FrontEndPasswordField,
    TextField as FrontEndTextField,
    FileInputField as FrontEndFileInputField,
    IntField as FrontEndIntField,
    BaseSearchField,
    DateField as FrontEndDateField,
    BinaryFileInputField as FrontEndBinaryFileInputField,
    EventAttendeeMultiselectField as FrontendEventAttendeeMultiselectField,
    VenueMultiselectField as FrontEndVenueMultiselectField,
    BusinessAreaMultiselectField as FrontEndBusinessAreaMultiselectField,
    MultipleEmailField as FrontEndMultipleEmailField,
    RadioButtonField as FrontEndRadioButonField
} from 'core.frontend';
import EventAttendeeMultiselectField from './eventAttendeeMultiselectField';
import BusinessAreaMultiselectField from './businessAreaMultiselectField';
import RadioButtonField from './radioButtonField';


export default class Field extends ObservingComponent<FieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider);
    }

    public render({ field, isLoading, className, leftIcon, id, stopPropagation }: FieldProps): JSX.Element {

        let labelClassName = field.isActive ? 'active' : '';
            labelClassName += field.errorMessage ? ' invalid' : '';

        if (!field.isVisible) {
            return null;
        }

        if (isLoading) {
            return <FieldLoadingPlaceholder className={className} />;
        }

        switch (field.fieldType) {
            case FieldType.SearchField:
                return <SearchField
                            id={id}
                            searchField={field as BaseSearchField<any>}
                            className={className}
                            stopPropagation={stopPropagation} />;

            case FieldType.CheckBox:
                return <CheckboxField
                            id={id}
                            field={field as FrontEndCheckBoxField}
                            labelClassName={labelClassName}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;

            case FieldType.DropDown:
                return <DropdownField
                            id={id}
                            field={field as FrontEndDropdownField}
                            labelClassName={labelClassName}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;

            case FieldType.Password:
                return <PasswordField
                            id={id}
                            field={field as FrontEndPasswordField}
                            labelClassName={labelClassName}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;

            case FieldType.Money:
                return <MoneyField
                            id={id}
                            field={field as FrontEndMoneyField}
                            labelClassName={labelClassName}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;

            case FieldType.Int:
                return <IntField
                            id={id}
                            field={field as FrontEndIntField}
                            labelClassName={labelClassName}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;

            case FieldType.File:
                return <FileInputField
                            id={id}
                            field={field as FrontEndFileInputField}
                            className={className}
                            stopPropagation={stopPropagation} />;

            case FieldType.BinaryFile:
                return <BinaryFileInputField
                            id={id}
                            field={field as FrontEndBinaryFileInputField}
                            className={className}
                            stopPropagation={stopPropagation} />;

            case FieldType.Time:
                return <TimeField
                            id={id}
                            field={field as FrontEndTimeField}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;

            case FieldType.Date:
                return <DateField
                            id={id}
                            field={field as FrontEndDateField}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;

            case FieldType.MultiSelectDropdown:
                return <EventAttendeeMultiselectField
                            id={id}
                            className={className}
                            eventAttendeeMultiselectField={field as FrontendEventAttendeeMultiselectField}
                            stopPropagation={stopPropagation} />;

            case FieldType.MultiSelectVenueDropdown:
                return <VenueMultiselectField
                            id={id}
                            className={className}
                            venueMultiselectField={field as FrontEndVenueMultiselectField}
                            stopPropagation={stopPropagation} />;

            case FieldType.MultipleEmailField:
                return <MultipleEmailField
                            id={id}
                            className={className}
                            field={field as FrontEndMultipleEmailField}
                            stopPropagation={stopPropagation} />;

            case FieldType.MultiSelectBusinessAreaDropdown:
                return <BusinessAreaMultiselectField
                            id={id}
                            businessAreaMultiSelectField={field as FrontEndBusinessAreaMultiselectField}
                            className={className}
                            stopPropagation={stopPropagation} />

            case FieldType.RadioButton:
                return <RadioButtonField
                            id={id}
                            labelClassName={labelClassName}
                            field={field as FrontEndRadioButonField}
                            className={className} />

            default:
                return <TextField
                            id={id}
                            field={field as FrontEndTextField}
                            labelClassName={labelClassName}
                            className={className}
                            leftIcon={leftIcon}
                            stopPropagation={stopPropagation} />;
        }
    }
}
