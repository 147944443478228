import { h, JSX } from 'preact';
import EventInfoFormProps from './eventInfoFormProps';
import ObservingComponent from '../componentBases/observingComponent';
import Field from '../fields/field';
import SearchField from '../fields/searchField';
import Button from '../buttons/button';
import ButtonSizes from '../buttons/buttonSizes';
import ButtonColors from '../buttons/buttonColors';
import DataList from '../dataList/dataList';
import { EventAttendeeDataListItem, DataListItem, EventExhibitSpaceDataListItem } from 'core.frontend';

class EventInfoForm extends ObservingComponent<EventInfoFormProps> {

    public componentWillMount(): void {
        const { eventInfoForm } = this.props;

        this.registerUpdateObserver(eventInfoForm.observationProvider);
    }

    public render({ eventInfoForm }: EventInfoFormProps): JSX.Element {
        return (
            <div class="form-container">
                <fieldset>
                    <legend>Event Overview</legend>

                    <div className="grid-container">
                        <div className="md-6">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[0]} />
                        </div>
                    </div>

                    <div className="grid-container">
                        <div className="md-6">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[3]} />
                        </div>

                        <div className="md-6">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[2]} />

                            <div class="text-sm">
                                Indicate the Time Zone where the Event will be held. VERY IMPORTANT, it will affect the Meeting List information!
                            </div>
                        </div>
                    </div>

                    <div className="grid-container">
                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[4]} />
                        </div>

                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[5]} />
                        </div>

                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[6]} />
                        </div>

                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[7]} />
                        </div>

                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[8]} />
                        </div>
                    </div>

                    <div className="grid-container">
                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[9]} />
                        </div>

                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[10]} />
                        </div>

                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[11]} />
                        </div>

                        <div className="md-3">
                            <Field
                                className="mb-0"
                                field={eventInfoForm.editForm.fields[12]} />
                        </div>
                    </div>

                    <div class="mt-1">
                        <div class="flex">
                            <b class="fill v-center">Web Links</b>

                            <Button
                                color={ButtonColors.Primary}
                                size={ButtonSizes.Small}
                                button={eventInfoForm.addWebLinkButton} />
                        </div>

                        <DataList dataList={eventInfoForm.webLinksDataList} />
                    </div>
                </fieldset>

                <fieldset className="mt-1">
                    <legend>Coordination</legend>

                    <div class="form-container">

                        <div className="grid-container">
                            <div className="md-4">
                                <Field
                                    className="mt-0"
                                    field={eventInfoForm.editForm.fields[13]} />
                            </div>

                            <div className="md-4">
                                <Field
                                    className="mt-0"
                                    field={eventInfoForm.editForm.fields[14]} />
                            </div>

                            <div className="md-4">
                                <div className="search-field-wrapper">
                                    <SearchField
                                        className='no-label'
                                        searchField={eventInfoForm.addEventLeadSearchField} />

                                    <Button
                                        size={ButtonSizes.Small}
                                        color={ButtonColors.Primary}
                                        button={eventInfoForm.addEventLeadButton} />
                                </div>
                            </div>
                    </div>
                </div>
                    <DataList
                        itemTemplate={(item: DataListItem<EventAttendeeDataListItem>) =>
                            <div class="flex">
                                <div class="v-center text-sm mr-1">
                                    <b>{item.form.model.fullName}</b>
                                </div>

                                <Field
                                    className="v-center fill"
                                    field={item.form.fields[0]} />
                            </div>
                        }
                        dataList={eventInfoForm.eventLeadDataList} />
                </fieldset>

                <fieldset className="mt-1">
                    <legend>Strategy</legend>

                    <div>
                        <Field
                            className="mt-0"
                            field={eventInfoForm.editForm.fields[15]} />
                    </div>
                </fieldset>

                <fieldset className="mt-1">
                    <legend>Exhibit Spaces</legend>

                    <div class="flex">
                        <div className="ml-auto">
                            <Button
                                color={ButtonColors.Primary}
                                size={ButtonSizes.Small}
                                button={eventInfoForm.addExhibitSpaceButton} />
                        </div>
                    </div>

                    <DataList
                        itemTemplate={(item: DataListItem<EventExhibitSpaceDataListItem>, index: number) =>
                            <div>

                                <Field
                                    className="mt-0"
                                    field={item.form.fields[5]} />

                                <div class="flex mt-1">
                                    <Field
                                        className="v-center fill"
                                        field={item.form.fields[0]} />

                                    <Field
                                        className="v-center fill ml-1"
                                        field={item.form.fields[1]} />

                                    <Field
                                        className="v-center fill ml-1"
                                        field={item.form.fields[2]} />

                                    <Field
                                        className="v-center fill ml-1"
                                        field={item.form.fields[3]} />
                                </div>
                                <div class="flex mt-1">
                                    <Field
                                        className="v-center fill"
                                        field={item.form.fields[4]} />
                                </div>
                            </div>
                        }
                        dataList={eventInfoForm.eventExhibitSpacesDataList} />
                </fieldset>
            </div>
        );
    }
}

export default EventInfoForm;
