"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
var slideContainerActions_1 = require("./slideContainerActions");
var button_1 = require("../button/button");
var SlideContainer = /** @class */ (function () {
    function SlideContainer() {
        var _this = this;
        this.open = function () {
            _this._isOpen = true;
            _this.observationProvider.notifyObservers(slideContainerActions_1.default.Shown);
        };
        this.close = function () {
            _this._isOpen = false;
            _this.observationProvider.notifyObservers(slideContainerActions_1.default.Hidden);
        };
        this.observationProvider = new utils_1.ObservationProvider();
        this.closeButton = new button_1.default({
            text: 'X',
            onClick: this.close
        });
    }
    Object.defineProperty(SlideContainer.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    return SlideContainer;
}());
exports.default = SlideContainer;
