import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import LabeledDivider from '../text/labeledDivider';
import RequestAccessPageProps from './requestAccessPageProps';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';

class RequestAccessForm extends ObservingComponent<RequestAccessPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: RequestAccessPageProps): JSX.Element {

        return (
            <form noValidate>

                <div class="card-header text-center">
                    <img src="img/logo-blue.png" class="header-logo-blue" />
                    <LabeledDivider text="Provide your email for access request" />
                </div>

                <div class="card-main">
                    <Alert alert={page.alert} />

                    {!page.emailSent && (
                        <div>
                            <p class="mb-1">
                                To register as a user for this system you must have a business issued email address
                            </p>

                            <Field
                                field={page.emailField}
                                className="icon-field input-lg floating-label mt-1"
                                leftIcon="ti-user"/>

                            <div class="flex mt-1">
                                <Button
                                    color={ButtonColors.Secondary}
                                    className="fill mt-2 mr-p5"
                                    button={page.loginButton} />

                                <Button
                                    color={ButtonColors.Primary}
                                    className="fill mt-2 ml-p5"
                                    button={page.submitButton} />
                            </div>
                        </div>
                    )}
                </div>

            </form>
        );
    }
}

export default RequestAccessForm;
