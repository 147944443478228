import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import EmailTemplatesPageProps from './emailTemplatesPageProps';
import Card from '../containers/card';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import { EmailTemplateTableItem } from 'core.frontend';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';


class EmailTemplatesPage extends ObservingComponent<EmailTemplatesPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private buildColumns = (): SmartTableColumn<EmailTemplateTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'name',
                cellTemplate: (item, index) => (
                    <Button
                        className="btn-link"
                        button={page.buildEmailTemplateButton(item)} />
                )
            },
            {
                heading: '',
                cellClassName: 'fit-content',
                cellTemplate: (item, index) => (
                    <Button
                        className="btn-icon"
                        button={page.buildDuplicateTemplateButton(item)} />
                )
            }
        ];
    }

    public render({ page }: EmailTemplatesPageProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    headingRightContent={
                        <div class="button-container fill flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.newEmailTemplateButton} />
                        </div>
                    }>

                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()} />
                </Card>
            </div>
        );
    }
}

export default EmailTemplatesPage;
