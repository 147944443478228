import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import UrlAssignerPageProps from './urlAssignerPageProps';
import FormButtonContainer from '../containers/formButtonContainer';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import EmailButton from '../buttons/emailButton';
import EmptyState from '../uiStates/emptyState';
import Field from '../fields/field';
import Alert from '../alerts/alert';
import FormattedDate from '../text/formattedDate';
import { DateFormats } from 'core.frontend';


class UrlAssignerPage extends ObservingComponent<UrlAssignerPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: UrlAssignerPageProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Alert alert={page.alert} />

                {page.isLoading &&
                    <div class="form-container">
                        <div className="loading-placeholder shimmer d-block mt-1"></div>
                        <div className="loading-placeholder shimmer d-block mt-1"></div>
                        <div className="loading-placeholder shimmer d-block mt-1"></div>
                        <div className="loading-placeholder shimmer d-block mt-1"></div>
                        <div className="loading-placeholder shimmer d-block mt-1"></div>
                        <div className="loading-placeholder shimmer d-block mt-1"></div>
                    </div>
                }

                {!page.isLoading && !page.data.meetings.length &&
                    <EmptyState message="This event has no active meetings" />
                }

                {!page.isLoading && page.data.meetings.map((meeting, index) =>
                    <div class="form-container">
                        <div class="grid-container">

                            <div class="md-6">
                                {meeting.name} - {meeting.meetingNumber}

                                <div class="text-sm">
                                    <FormattedDate
                                        date={meeting.startDateTime}
                                        format={DateFormats.MMMd} />

                                    <span> | </span>

                                    <FormattedDate
                                        date={meeting.startDateTime}
                                        format={DateFormats.hhmm} />

                                    <span> - </span>

                                    <FormattedDate
                                        date={meeting.endDateTime}
                                        format={DateFormats.hhmm} />

                                    <span> | </span>

                                    Point of Contact:

                                    {meeting.leadAttendee &&
                                        <span>
                                            <span> {meeting.leadAttendee} </span>
                                            <EmailButton emailAddress={meeting.leadAttendeeEmail} />
                                        </span>
                                    }

                                    {!meeting.leadAttendee &&
                                        <i> Unknown</i>
                                    }
                                </div>
                            </div>

                            <Field
                                className="md-6 mb-0 mt-0 text-sm"
                                field={page.meetingUrlFields[index]} />
                        </div>
                    </div>
                )}

                <FormButtonContainer
                    leftButtons={[]}
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.backButton} />,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton} />
                    ]} />
            </div>
        );
    }
}

export default UrlAssignerPage;
