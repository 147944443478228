import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import ImageCarouselProps from './imageCarouselProps';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import Modal from '../containers/modal';

class ImageCarousel extends ObservingComponent<ImageCarouselProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.imageCarousel.observationProvider);
    }

    public render({ imageCarousel }: ImageCarouselProps): JSX.Element {
        return (
            <Modal
                heading={imageCarousel.title}
                close={imageCarousel.close}>

                <div class="image-carousel">
                    <div class="active-image">
                        <div class="nav">
                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-nav btn-icon"
                                button={imageCarousel.previousButton} />
                        </div>

                        <div class="image-wrapper">
                            <img src={imageCarousel.currentImageUrl} />
                        </div>

                        <div class="nav">
                            <Button
                                className="btn-nav btn-icon"
                                button={imageCarousel.nextButton} />
                        </div>
                    </div>

                    <div class="image-carousel-thumbnails">
                        { imageCarousel.imageUrls.map((image, index) =>
                            <Button button={imageCarousel.buildSelectImageButton(index)}>
                                <img src={image} />
                            </Button>
                        ) }
                    </div>

                </div>
            </Modal>
        );
    }
}

export default ImageCarousel;
