"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EndpointUrls;
(function (EndpointUrls) {
    EndpointUrls["Users"] = "Users";
    EndpointUrls["Authentication"] = "Authentication";
    EndpointUrls["Login"] = "Authentication/Login";
    EndpointUrls["ForgotPassword"] = "Authentication/ForgotPassword";
    EndpointUrls["ResetPassword"] = "Authentication/ResetPassword";
    EndpointUrls["ValidatePasswordResetAccessCode"] = "Authentication/ValidatePasswordResetAccessCode";
    EndpointUrls["RequestAccess"] = "Authentication/RequestAccess";
    EndpointUrls["CompleteAccessRequest"] = "Authentication/CompleteAccessRequest";
    EndpointUrls["GetAccessRequest"] = "Authentication/GetAccessRequest";
    EndpointUrls["Events"] = "Events";
    EndpointUrls["EventReports"] = "Events/Reports";
    EndpointUrls["Customers"] = "Customers";
    EndpointUrls["Meetings"] = "Meetings";
    EndpointUrls["BusinessAreasWithDepartments"] = "BusinessAreas/IncludeDepartments";
    EndpointUrls["LinesOfBusiness"] = "LinesOfBusiness";
    EndpointUrls["UserGroups"] = "UserGroups";
    EndpointUrls["Histories"] = "Histories";
    EndpointUrls["Hotels"] = "Hotels";
    EndpointUrls["Vendors"] = "Vendors";
    EndpointUrls["Public"] = "Public";
    EndpointUrls["Inventory"] = "Inventory";
    EndpointUrls["EventConfiguration"] = "Events/EventConfiguration";
    EndpointUrls["UpdateEventAttendees"] = "Events/EventAttendees";
    EndpointUrls["GetNonRemovableAttendees"] = "Events/NonRemovableAttendees";
    EndpointUrls["Accommodations"] = "Accommodations";
    EndpointUrls["Settings"] = "Settings";
    EndpointUrls["UserTitles"] = "UserTitles";
    EndpointUrls["EventHistories"] = "EventHistories";
    EndpointUrls["CustomerCompanies"] = "CustomerCompanies";
    EndpointUrls["VendorCompanies"] = "VendorCompanies";
    EndpointUrls["Tenants"] = "Tenants";
    EndpointUrls["VendorEmailTemplateOptions"] = "Vendors/VendorPortal/EmailTemplateOptions";
    EndpointUrls["EmailTemplateOptions"] = "EmailTemplates/EmailTemplateOptions";
    EndpointUrls["EmailTemplates"] = "EmailTemplates";
    EndpointUrls["HelpUrls"] = "HelpUrls";
    EndpointUrls["Notifications"] = "Notifications";
})(EndpointUrls || (EndpointUrls = {}));
exports.default = EndpointUrls;
