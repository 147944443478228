import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import InventoryForecastPageProps from './inventoryForecastPageProps';
import Card from '../containers/card';
import EventOverview from '../events/eventOverview';
import { Month, InventoryForecastDateStatus } from 'core.frontend';
import EmptyState from '../uiStates/emptyState';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';
import ListOptionSlideContainer from '../events/rightSlider/listOptionSlideContainer';

class InventoryForecastPage extends ObservingComponent<InventoryForecastPageProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider);
    }

    public render({ page }: InventoryForecastPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading flex">
                    <h3 class="fill v-center">{page.title}</h3>

                    <Button
                        className="btn-icon btn-lg"
                        button={page.helpButton} />
                </div>

                <Card>
                    <div>
                        <div class="flex mb-1">

                            <Button
                                className="ml-auto btn-icon btn-no-inner-margin"
                                color={ButtonColors.Secondary}
                                button={page.filterOptionsSlider.openButton} />

                            {!!page.errorMessage &&
                                <div class="fill text-center text-red">
                                    {page.errorMessage}
                                </div>
                            }
                        </div>

                        <div class="flex scheduler-timeline">
                            {page.months.map((month: Month) =>

                                <div class="fill text-center text-sm scheduler-month-wrapper">
                                    <div class="inventory-scheduler-top-row">
                                        <b>{month.name}</b>

                                        <div class="flex mt-p5">
                                            {page.getDates(month).map((date: Date) =>
                                                <div class="fill">
                                                    {date.getDate()}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {page.forecastEvents.map((event) => {

                            return (
                                <div class="mb-2">
                                    <div class="flex scheduler-timeline">
                                        {page.months.map((month: Month) =>
                                            <div class="fill text-center text-sm scheduler-month-wrapper">
                                                <div class="flex inventory-scheduler-row-wrapper">
                                                    {page.getDates(month).map((date: Date) => {

                                                        const status = page.IsEventInDateRange(date, event);

                                                        return (
                                                            <div class={'fill inventory-scheduler-item-block ' + (
                                                                status === InventoryForecastDateStatus.PreEvent ? ' pre-event ' :
                                                                status === InventoryForecastDateStatus.Event ? ' event ' :
                                                                status === InventoryForecastDateStatus.PostEvent ? ' post-event ' : ''
                                                            )}>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        <Button
                                            className="btn-icon"
                                            button={page.buildEventOverviewButton(event)} />
                                    </div>

                                    {event.inventoryItems.map((inventoryItem) =>
                                        <div class={'text-sm ' +
                                                    (!!inventoryItem.conflictingEvents.length ? 'text-red' : '') }>

                                            <b>{inventoryItem.inventoryItemName} </b>
                                            <span> - Serial/Item # {inventoryItem.serialNumber}</span>

                                            {!!inventoryItem.conflictingEvents.length &&
                                                <b>
                                                    - CONFLICT &nbsp;
                                                    {inventoryItem.conflictingEvents.map((conflictingEvent, index) =>
                                                        <span>
                                                            {conflictingEvent.eventName}
                                                            {(inventoryItem.conflictingEvents.length - 1) > index &&
                                                                <span> | </span>
                                                            }
                                                        </span>
                                                    )}
                                                </b>
                                            }

                                            {inventoryItem.crates.map((crate) =>
                                                <div>
                                                    <span>Crate# {crate.number} </span>
                                                    <span> | L: {crate.length}in., W: {crate.width}in., H: {crate.height}in., W: {crate.weight}lbs.</span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {!event.inventoryItems.length &&
                                        <div class="text-sm">
                                            <i>
                                                No inventory items assigned

                                                {!!page.filterOptionsSlider.appliedFiltersModel.businessAreaId &&
                                                    <b> for the selected business area</b>
                                                }
                                            </i>
                                        </div>
                                    }
                                </div>
                            );
                        })}

                        {!page.forecastEvents.length &&
                            <div>
                                <div class="inventory-scheduler-empty-row">&nbsp;</div>

                                <EmptyState
                                    title="No Events Found"
                                    message="This could be because there are no events set to manage inventory for this date period or other filters are in place." />
                            </div>
                        }
                    </div>
                </Card>

                <EventOverview eventOverview={page.eventOverview} />

                <ListOptionSlideContainer
                    headerText = "Inventory Forecast Filters"
                    listOptionSlideContainer = { page.filterOptionsSlider } />
            </div>
        );
    }
}

export default InventoryForecastPage;
