import { h, Component, JSX } from 'preact';
import Button from '../buttons/button';
import TablePagerProps from './tablePagerProps';
import ButtonColors from '../buttons/buttonColors';

class TablePager extends Component<TablePagerProps> {

    public render({ table }: TablePagerProps): JSX.Element {

        if (table.isLoading) {
            return (
                <span className="pagination">
                    <span className="loading-placeholder shimmer"></span>
                    <span className="loading-placeholder shimmer"></span>
                </span>
            );
        }

        return (
            <div>
                <ul className="pagination d-none d-md-flex">
                    <li className={`page-item ${!table.isPreviousPageAvailable && 'disabled'}`}>
                        <Button
                            button={table.previousPageButton}
                            className="page-link" />
                    </li>

                    {table.getPageButtonOptions().map((pageNumber) => (
                        <li
                            key={pageNumber}
                            className={`page-item ${pageNumber === table.currentPage && 'active'}`}>

                            <Button
                                button={table.buildPageNumberButton(pageNumber)}
                                className="page-link" />
                        </li>
                    ))}

                    <li className={`page-item ${!table.isNextPageAvailable && 'disabled'}`}>
                        <Button
                            button={table.nextPageButton}
                            className="page-link" />
                    </li>
                </ul>
                <div className="pagination d-md-none">
                    <Button
                        color={ButtonColors.Secondary}
                        className={`${!table.isPreviousPageAvailable && 'disabled'}`}
                        button={table.previousPageButton} />

                    <Button
                        color={ButtonColors.Primary}
                        className={`${!table.isNextPageAvailable && 'disabled'}`}
                        button={table.nextPageButton} />
                </div>
            </div>
        );
    }
}

export default TablePager;
