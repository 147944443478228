import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import VenueMultiselectFieldProps from './venueMultiselectFieldProps';
import { VenueMultiselectFieldOption } from 'core.frontend';

class VenueMultiselectField extends ObservingComponent<VenueMultiselectFieldProps> {
    private isFocusing: boolean;

    public componentWillMount(): void {
        const { venueMultiselectField } = this.props;

        this.registerUpdateObserver(venueMultiselectField.observationProvider);
        window.addEventListener('mousedown', this.blurred);
    }

    public componentWillUnmount() {
        window.removeEventListener('mousedown', this.blurred);
    }

    private attendeeOptionClicked = (venueOption: VenueMultiselectFieldOption) => (e: Event) => {
        e.preventDefault();

        this.props.venueMultiselectField.toggleSelection(venueOption);
    }

    public focused = (e: Event) => {
        const { venueMultiselectField } = this.props;

        // set isFocusing because propogation will fire window.mousedown
        // which fires blurred. Otherwise focus would immediately blur
        this.isFocusing = true;
        venueMultiselectField.focus();
    }

    public blurred = (e: Event) => {
        const { venueMultiselectField } = this.props;

        if (!this.isFocusing && venueMultiselectField.dropdownOpen) {
            venueMultiselectField.blur();
        }

        this.isFocusing = false;
    }

    public searchPhraseChanged = (e: any) => {
        const { venueMultiselectField } = this.props;
        const newValue = e.target.value;

        venueMultiselectField.search(newValue);
    }

    public render({ venueMultiselectField, className, id }: VenueMultiselectFieldProps): JSX.Element {

        if (!id)
        {
            id = venueMultiselectField.uniqueKey;
        }

        return (
            <div
                class={
                    `search-field search-field ${className}
                    ${venueMultiselectField.isFocused ? ' focused ' : ''}
                    ${venueMultiselectField.dropdownOpen ? ' active ' : ''}`
                }
                onMouseDown={this.focused}>

                <div class="input-field">
                    <label>
                        <span class="fill v-center mr-p5">
                            {venueMultiselectField.label}
                        </span>

                        {venueMultiselectField.errorMessage && (
                            <span className="v-center invalid">{venueMultiselectField.errorMessage}</span>
                        )}
                    </label>

                    <input
                        placeholder="Search by name..."
                        type="text"
                        className={venueMultiselectField.isInvalid ? 'invalid' : ''}
                        id={venueMultiselectField.uniqueKey}
                        name={venueMultiselectField.uniqueKey}
                        maxLength={250}
                        value={venueMultiselectField.searchBoxValue}
                        onKeyUp={this.searchPhraseChanged}
                        disabled={venueMultiselectField.isDisabled} />
                </div>

                <div class="search-dropdown">
                    {!!venueMultiselectField.options.length &&
                        <div class="search-results">
                            <ul>
                                { venueMultiselectField.options.map((venueOption) => {
                                    return (
                                        <li
                                            onClick={this.attendeeOptionClicked(venueOption)}
                                            class={venueOption.selected ? 'selected' : ''}>

                                            {venueOption.selected &&
                                                <i class="ti ti-check"></i>
                                            }

                                            {venueOption.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    }

                    {!venueMultiselectField.options.length &&
                        <div>
                            {venueMultiselectField.searchBoxValue &&
                                <div class="empty-state">
                                        No matching attendees
                                </div>
                            }

                            {!venueMultiselectField.searchBoxValue &&
                                <div class="empty-state">
                                        No attendees available
                                </div>
                            }
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default VenueMultiselectField;
