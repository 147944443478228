import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import EventConfigurationPageProps from './eventConfigurationPageProps';
import EventAttendeeAccessLevelDataList from './eventAttendeeAccessLevelDataList';
import EventTransportationConfigurationForm from './eventTransportationConfigurationForm/eventTransportationConfigurationForm';
import Field from '../fields/field';
import Button from '../buttons/button';
import { RichTextField as FrontEndRichTextField, EventConfigurationPageTransportationPanes, EventConfigurationPageMeetingPanes, EventTransportationConfigurationFormActions, EventHotelConfigurationFormActions, spaceCamelCase, Icons } from 'core.frontend';
import FormButtonContainer from '../containers/formButtonContainer';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import Modal from '../containers/modal';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import DataList from '../dataList/dataList';
import Alert from '../alerts/alert';
import RichTextField from '../fields/richTextField';
import EventHotelConfigurationForm from './eventHotelConfigurationForm/eventHotelConfigurationForm';
import Accordian from '../accordian/accordian';
import ToggleSwitchField from '../fields/toggleSwitchField';
import FormattedDate from '../text/formattedDate';
import Icon from '../icons/icon';
class EventConfigurationPage extends ObservingComponent<EventConfigurationPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: EventConfigurationPageProps): JSX.Element {
        return (
            <div className="configuration-page">
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />
                <Alert alert={page.alert} />

                {page.isLoaded &&
                    <div>
                        {page.eventAttendeeAccessLevelsVisible &&
                            <Modal
                                heading="Attendee Access Levels"
                                close={page.hideEventAttendeeAccessLevels} >

                                <div class="pt-1">
                                    <Alert
                                        className="mt-1"
                                        alert={page.eventAttendeeAccessLevelAlert} />

                                    <div class="flex v-center">
                                        <h4 class="v-center fill mb-0">
                                            User Right Options
                                        </h4>

                                        <Button
                                            size={ButtonSizes.Small}
                                            color={ButtonColors.Primary}
                                            button={page.eventAttendeeAccessLevelDataList.addItemButton} />
                                    </div>

                                    <hr/>

                                    <EventAttendeeAccessLevelDataList dataList={page.eventAttendeeAccessLevelDataList} />
                                </div>
                            </Modal>
                        }

                        <div>
                            <div class="page-heading flex">
                                <h3>{page.title}</h3>

                                <div class="ml-auto">
                                    <b className="text-lg">{page.form.model.eventName}</b>
                                    <div className="text-sm">
                                        <FormattedDate date={page.form.model.eventStartDate} /> - <FormattedDate date={page.form.model.eventEndDate} />
                                    </div>

                                    <div class="btn-container">
                                        <Button
                                            className="btn-link"
                                            button={page.showEventAttendeeAccessLevelButton} />
                                    </div>
                                </div>
                            </div>

                            <div className='text-sm mb-2'>
                                Each event has customizable tools to assist with management. These tools can be enabled/disabled now or can be accessed at any time by using the <Icon icon={Icons.Gear} /> symbol in the upper right-hand corner of the event. Select the <Icon icon={Icons.Question} /> next to each tool for additional information.
                            </div>

                            <div class="mt-3">
                                <div class="flex">
                                    <h4 class="mt-0 mb-0 v-center">
                                        File Management
                                    </h4>

                                    <Button
                                        className="btn-icon ml-p5 v-center"
                                        button={page.fileManagementHelpButton} />

                                    <div class="v-center flex ml-auto">
                                        <ToggleSwitchField
                                            field={page.fileManagementFields[0]}
                                            className="v-center" />
                                    </div>
                                </div>

                                <hr />
                            </div>


                            <div class="mt-3">
                                <div class="flex">
                                    <h4 class="mt-0 mb-0 v-center">
                                        Badging
                                    </h4>

                                    <div class="v-center flex ml-auto">
                                        <ToggleSwitchField
                                            field={page.badgingEnabledCheckbox}
                                            className="v-center" />
                                    </div>
                                </div>

                                <hr />
                            </div>

                            <Accordian
                                className="mt-3"
                                headerClassName="tool-accordion-header"
                                accordian={page.alertAccordian}
                                header={(
                                    <div class="flex">
                                        <h4 class="mb-0 mt-0 v-center">
                                            Alert Message
                                        </h4>

                                        <Button
                                                className="btn-icon ml-p5 v-center"
                                                button={page.alertMessageHelpButton} />

                                        <div class="v-center flex ml-auto">
                                            <ToggleSwitchField
                                                field={page.alertMessageFields[0]}
                                                className="v-center" />
                                        </div>
                                    </div>
                                )}>

                                    <div class="form-container">
                                        {page.alertMessageFields[0].value &&
                                            <div>
                                                <p class="mt-1 text-sm">
                                                    <b>Enabling alerts</b> displays an alert message at the top of the event landing page to all users. This allows you to communicate important messages to each attendee.
                                                </p>

                                                <Field field={page.alertMessageFields[1]} />
                                                <RichTextField field={page.alertMessageFields[2] as FrontEndRichTextField} />
                                            </div>
                                        }
                                    </div>
                            </Accordian>

                            {page.enableAttendeeHeadCount.isVisible &&
                                <div class="mt-3">
                                    <div class="flex">
                                        <h4 class="mt-0 mb-0 v-center">
                                            Attendee Head Count Tracking
                                        </h4>

                                        <div class="v-center flex ml-auto">
                                            <ToggleSwitchField
                                                field={page.enableAttendeeHeadCount}
                                                className="v-center" />
                                        </div>
                                    </div>

                                    <hr />
                                </div>
                            }

                            <Accordian
                                className="mt-3"
                                headerClassName="tool-accordion-header"
                                accordian={page.meetingsAccordian}
                                header={(
                                    <div class="flex">
                                        <h4 class="mb-0 mt-0 v-center">
                                            Meetings
                                        </h4>

                                        <Button
                                                className="btn-icon ml-p5 v-center"
                                                button={page.meetingsHelpButton} />

                                        <div class="v-center flex ml-auto">
                                            <ToggleSwitchField
                                                field={page.meetingsFields[0]}
                                                className="v-center" />
                                        </div>
                                    </div>
                                )}>

                                <Fragment>
                                    <div class="form-container">
                                        { page.meetingsFields[0].value &&
                                            <div>
                                                <div class="btn-group mt-1 flex">
                                                    <Button
                                                        className={'btn-sm btn-primary fill btn ' + (page.activeMeetingPane === EventConfigurationPageMeetingPanes.Settings ? 'active ' : '' )}
                                                        button={page.showMeetingSettingsPaneButton} />

                                                    <Button
                                                        className={'btn-sm btn-primary fill btn ' + (page.activeMeetingPane === EventConfigurationPageMeetingPanes.Venues ? 'active ' : '' )}
                                                        button={page.showMeetingVenuesPaneButton} />

                                                    <Button
                                                        className={'btn-sm btn-primary fill btn ' + (page.activeMeetingPane === EventConfigurationPageMeetingPanes.MeetingRequests ? 'active ' : '' )}
                                                        button={page.showMeetingRequestPaneButton} />
                                                </div>

                                                {page.activeMeetingPane === EventConfigurationPageMeetingPanes.Settings &&
                                                    <div class="grid-container">
                                                        <p class="sm-12 mt-1 text-sm">
                                                            <b>Enabling meetings</b> turns on the meetings tile and meeting functionality for this event. The settings below define the dates, times and options available for meetings at this event.
                                                        </p>

                                                        <Field
                                                            field={page.meetingsFields[1]}
                                                            className="sm-6" />

                                                        <Field
                                                            field={page.meetingsFields[2]}
                                                            className="sm-6 mb-0" />

                                                        <div class="sm-6">
                                                            <Field
                                                                field={page.meetingsFields[3]}
                                                                className="mb-0" />

                                                            <p class="text-sm">
                                                                After this date the <b>Pre-Show Schedulers</b> will no longer be able to edit meetings
                                                            </p>
                                                        </div>

                                                        <div class="sm-6">
                                                            <Field
                                                                field={page.meetingsFields[4]}
                                                                className="mb-0" />

                                                            <p class="text-sm">
                                                                This will determine the default <b>Meeting Status</b> when a meeting is added to this event
                                                            </p>
                                                        </div>

                                                        <Field
                                                            field={page.meetingsFields[6]}
                                                            className="sm-6" />
                                                    </div>
                                                }

                                                {page.activeMeetingPane === EventConfigurationPageMeetingPanes.Venues &&
                                                    <div>
                                                        <p class="text-sm">
                                                            Meeting <b>venue preferences</b> are the options that the creator of the meeting must choose from to help explain what type of room will be needed for the meeting.
                                                            <b> Onsite venues</b> are the rooms that meetings actually occur in. Meetings will be scheduled and assigned to the onsite venues based on availability.
                                                        </p>

                                                        <div class="d-md-flex mt-1">
                                                            <div class="fill">
                                                                <div class="flex">

                                                                    <div class="fill flex">
                                                                        <b class="v-center">Venue Preferences</b>
                                                                    </div>

                                                                    <Button
                                                                        size={ButtonSizes.Small}
                                                                        color={ButtonColors.Primary}
                                                                        button={page.addVenuePreferenceButton} />
                                                                </div>

                                                                <DataList dataList={page.venuePreferenceDataList} />
                                                            </div>

                                                            <div class="text-center v-center">
                                                                <Button
                                                                    className="btn-icon btn-icon-stacked ml-1 mr-1"
                                                                    color={ButtonColors.Secondary}
                                                                    button={page.copyVenuePreferencesButton} />
                                                            </div>

                                                            <div class="fill">
                                                                <div class="flex">

                                                                    <div class="fill flex">
                                                                        <b class="v-center">Onsite Venues</b>
                                                                    </div>

                                                                    <Button
                                                                        color={ButtonColors.Primary}
                                                                        button={page.addVenueButton}
                                                                        size={ButtonSizes.Small} />
                                                                </div>

                                                                <DataList dataList={page.venueDataList} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {page.activeMeetingPane === EventConfigurationPageMeetingPanes.MeetingRequests &&
                                                    <div class="mt-1">
                                                        <p class="text-sm">
                                                            <b>Meeting requests</b> allow anyone to request a meeting. When enabled you'll receive a link that you can send to individuals to access the meeting request page. You can configure representatives per business area that will be notified when a meeting is requested.
                                                            You can use the link below to directly access a blank meeting request for this event.
                                                        </p>

                                                        <div class="text-center">
                                                            <a
                                                                target="_blank"
                                                                href={page.meetingRequestUrl} >

                                                                {page.meetingRequestUrl}
                                                            </a>
                                                        </div>

                                                        <Field field={page.meetingRequestEnabledCheckbox} />

                                                        <div class="grid-container">
                                                            <Field
                                                                className="sm-6"
                                                                field={page.meetingRequestCutoffField} />
                                                        </div>

                                                        <div class="flex">
                                                            <Button
                                                                className="ml-auto"
                                                                color={ButtonColors.Primary}
                                                                size={ButtonSizes.Small}
                                                                button={page.meetingRequestRepresentativeDataList.addItemButton} />
                                                        </div>

                                                        <DataList dataList={page.meetingRequestRepresentativeDataList} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>

                                    {page.diningFeatureEnabled && page.meetingsFields[0].value &&
                                        <div>
                                            <h4 class="mt-3 mb-0">
                                                <div class="flex">
                                                    <div class="fill v-center">
                                                        Dining
                                                    </div>

                                                    <ToggleSwitchField
                                                        field={page.meetingsFields[5]}
                                                        className="v-center" />
                                                </div>

                                                <hr/>
                                            </h4>

                                            {page.meetingsFields[5].value &&
                                                <div className="text-sm">
                                                    <b>Enabling Dining: </b>
                                                    If this section is enabled you will be adding a “Dining” tile. This tile is only necessary if you will be having a dedicated Dining area where you wish to manage the table assignment. Once enabled, you will need to select the “Dining Venue” option next to any “Onsite Venue” (located under the Meetings/Venue section above) that will have tables that you wish to manage.
                                                </div>
                                            }
                                        </div>
                                    }
                                </Fragment>
                            </Accordian>

                            {(
                                (
                                    page.transportationFeatureIsEnabled &&
                                    page.eventTransportationConfigurationForm.transportationFields[0].value
                                ) || (
                                    page.lodgingFeatureIsEnabled &&
                                    page.eventHotelConfigurationForm.hotelFields[0].value
                                )
                            ) &&
                                <Fragment>
                                    <div className="mt-3">
                                        <div class="flex">
                                            <h4 class="mb-0 mt-0 v-center">
                                                Hotel & Transportation
                                            </h4>
                                        </div>

                                        <hr />
                                    </div>

                                    <div className="form-container">

                                        <div className="grid-container">
                                            <div class="sm-6">
                                                <Field
                                                    field={page.accommodationCutoffDateField}
                                                    className="v-center" />

                                                <span class="text-sm">
                                                    Once this cutoff date has past, attendees will no longer be allowed to update their hotel and daily transport information.
                                                </span>
                                            </div>

                                            <div className="sm-6">
                                                <Field
                                                    field={page.requireArrivalDepartureInformationField}
                                                    className="mt-1p5" />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {page.transportationFeatureIsEnabled &&
                                <EventTransportationConfigurationForm
                                    form={page.eventTransportationConfigurationForm}
                                    helpButton={page.transportationHelpButton}
                                    isApp={page.isApp} />
                            }

                            {page.lodgingFeatureIsEnabled &&
                                <EventHotelConfigurationForm
                                    form={page.eventHotelConfigurationForm}
                                    helpButton={page.hotelHelpButton}
                                    isApp={page.isApp} />
                            }

                            {page.inventoryFeatureIsEnabled &&
                                <Accordian
                                    className="mt-3"
                                    headerClassName="tool-accordion-header"
                                    accordian={page.inventoryAccordian}
                                    header={(
                                        <div class="flex">
                                            <h4 class="mb-0 mt-0 v-center">
                                                Event Inventory
                                            </h4>

                                            <Button
                                                className="btn-icon ml-p5 v-center"
                                                button={page.inventoryHelpButton} />

                                            <div class="v-center flex ml-auto">
                                                <ToggleSwitchField
                                                    field={page.inventoryFields[0]}
                                                    className="v-center" />
                                            </div>
                                        </div>
                                    )}>

                                    <div class="form-container">
                                        { page.inventoryFields[0].value &&
                                            <div>
                                                <Field field={page.inventoryFields[1]} />

                                                <p class="text-sm">After this date ONLY the Event Producer can update event inventory</p>

                                                <div class="grid-container">
                                                    <Field
                                                        field={page.inventoryFields[2]}
                                                        className="sm-6" />

                                                    <Field
                                                        field={page.inventoryFields[3]}
                                                        className="sm-6 mb-0" />
                                                </div>
                                                <p class="text-sm">The dates above limit the availability of all event inventory that has been added to this event to allow for travel time.</p>
                                            </div>
                                        }
                                    </div>
                                </Accordian>
                            }

                            {page.costManagementFeatureIsEnabled &&
                                <Accordian
                                    className="mt-3"
                                    headerClassName="tool-accordion-header"
                                    accordian={page.costManagementAccordian}
                                    header={(
                                        <div class="flex">
                                            <h4 class="mb-0 mt-0 v-center">
                                                Cost Management
                                            </h4>

                                            <Button
                                                    className="btn-icon ml-p5 v-center"
                                                    button={page.costManagementHelpButton} />

                                            <div class="v-center flex ml-auto">
                                                <ToggleSwitchField
                                                    field={page.costManagementFields[0]}
                                                    className="v-center" />
                                            </div>
                                        </div>
                                    )}>

                                    <div>
                                        <div class="form-container">
                                            { page.costManagementFields[0].value &&
                                                <div>
                                                    <Field field={page.costManagementFields[1]} />

                                                    <p class="text-sm">After this date ONLY the Event Producer can update cost management</p>

                                                    <div class="mt-1">
                                                        <b class="text-sm">Billable Business Areas</b>

                                                        {page.billableBusinessAreaCheckboxes.map((field, index) => (
                                                            <div key={field.uniqueKey}>
                                                                {page.billableBusinessAreaAlerts && page.billableBusinessAreaAlerts[index] &&
                                                                    <Alert
                                                                        className="mb-p5 mt-1"
                                                                        alert={page.billableBusinessAreaAlerts[index]}>
                                                                        <div class="text-sm mt-p5">
                                                                            {page.billableBusinessAreaReferences[index] && page.billableBusinessAreaReferences[index].map((reference) => {
                                                                                if (reference.referenceCount > 0) {
                                                                                    return <div>
                                                                                        Referenced to: {spaceCamelCase(reference.tableName)} | Number of references: {reference.referenceCount}
                                                                                    </div>
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    </Alert>
                                                                }

                                                                <div class="flex">
                                                                    <Field
                                                                        field={field}
                                                                        className="label-right v-center"/>

                                                                    <Field
                                                                        field={page.billableBusinessAreaEstimateFields[index]}
                                                                        className="ml-1 inline-label fill" />

                                                                    <Button
                                                                        className="v-center btn-icon ml-p5"
                                                                        button={page.buildBillableBusinessAreaReferenceButton(index)} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Accordian>
                            }

                            <FormButtonContainer
                                className="mt-2 sticky-button-footer"
                                leftButtons={[
                                    <Button
                                        className="btn-icon"
                                        color={ButtonColors.Secondary}
                                        button={page.showConfirmationPromptButton} />
                                ]}
                                rightButtons={[
                                    <Button
                                        color={ButtonColors.Secondary}
                                        button={page.cancelButton} />,
                                    <Button
                                        color={ButtonColors.Primary}
                                        button={page.saveButton} />
                                ]} />
                        </div>
                    </div>
                }

                {!page.isLoaded &&
                    <p>Loading...</p>
                }
            </div>
        );
    }
}

export default EventConfigurationPage;
