import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { BusinessAreaMultiselectFieldOption } from 'core.frontend';
import BusinessAreaMultiSelectFieldProps from './businessAreaMultiselectFieldProps';

class BusinessAreaMultiselectField extends ObservingComponent<BusinessAreaMultiSelectFieldProps> {
    private isFocusing: boolean;

    public componentWillMount(): void {
        const { businessAreaMultiSelectField } = this.props;

        this.registerUpdateObserver(businessAreaMultiSelectField.observationProvider);
        window.addEventListener('mousedown', this.blurred);
    }

    public componentWillUnmount() {
        window.removeEventListener('mousedown', this.blurred);
    }

    private businessAreaOptionClicked = (businessAreaOption: BusinessAreaMultiselectFieldOption) => (e: Event) => {
        e.preventDefault();

        this.props.businessAreaMultiSelectField.toggleSelection(businessAreaOption);
    }

    public focused = (e: Event) => {
        const { businessAreaMultiSelectField } = this.props;

        e.stopPropagation();
        // // set isFocusing because propogation will fire window.mousedown
        // // which fires blurred. Otherwise focus would immediately blur
        // this.isFocusing = true;
        businessAreaMultiSelectField.focus();
    }

    public blurred = (e: Event) => {
        const { businessAreaMultiSelectField } = this.props;

        e.stopPropagation();
       businessAreaMultiSelectField.blur();

        // if (!this.isFocusing && businessAreaMultiSelectField.dropdownOpen) {
        //     businessAreaMultiSelectField.blur();
        // }

        //this.isFocusing = false;
    }

    public searchPhraseChanged = (e: any) => {
        const { businessAreaMultiSelectField } = this.props;
        const newValue = e.target.value;

        businessAreaMultiSelectField.search(newValue);
    }

    public render({ businessAreaMultiSelectField, className }: BusinessAreaMultiSelectFieldProps): JSX.Element {

        return (
            <div
                class={
                    `search-field search-field ${className}
                    ${businessAreaMultiSelectField.isFocused ? ' focused ' : ''}
                    ${businessAreaMultiSelectField.dropdownOpen ? ' active ' : ''}`
                }
                onMouseDown={this.focused}>

                <div class="input-field">
                    <label>
                        <span class="fill v-center mr-p5">
                            {businessAreaMultiSelectField.label}
                        </span>

                        {businessAreaMultiSelectField.errorMessage && (
                            <span className="v-center invalid">{businessAreaMultiSelectField.errorMessage}</span>
                        )}
                    </label>

                    <input
                        placeholder="Search by name..."
                        type="text"
                        className={businessAreaMultiSelectField.isInvalid ? 'invalid' : ''}
                        id={businessAreaMultiSelectField.uniqueKey}
                        name={businessAreaMultiSelectField.uniqueKey}
                        maxLength={250}
                        value={businessAreaMultiSelectField.searchBoxValue}
                        onKeyUp={this.searchPhraseChanged}
                        disabled={businessAreaMultiSelectField.isDisabled} />
                </div>

                <div class="search-dropdown">
                    {!!businessAreaMultiSelectField.options.length &&
                        <div class="search-results">
                            <ul>
                                { businessAreaMultiSelectField.options.map((businessAreaOption) => {
                                    return (
                                        <li
                                            onClick={this.businessAreaOptionClicked(businessAreaOption)}
                                            class={businessAreaOption.selected ? 'selected' : ''}>

                                            {businessAreaOption.selected &&
                                                <i class="ti ti-check"></i>
                                            }

                                            {businessAreaOption.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    }

                    {!businessAreaMultiSelectField.options.length &&
                        <div>
                            {businessAreaMultiSelectField.searchBoxValue &&
                                <div class="empty-state">
                                        No matching business area
                                </div>
                            }

                            {!businessAreaMultiSelectField.searchBoxValue &&
                                <div class="empty-state">
                                        No business areas available
                                </div>
                            }
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default BusinessAreaMultiselectField;
