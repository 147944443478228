"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var _1 = require(".");
var __2 = require("../..");
var button_1 = require("../button");
var button_2 = require("../button/button");
var fields_1 = require("../fields");
var ReportOptionSlideContainer = /** @class */ (function () {
    function ReportOptionSlideContainer(api, heading, fields, defaultOptions, hideDownloadButton, hideEmailButton, hideSubmitButton) {
        var _this = this;
        if (fields === void 0) { fields = []; }
        if (defaultOptions === void 0) { defaultOptions = {}; }
        if (hideDownloadButton === void 0) { hideDownloadButton = true; }
        if (hideEmailButton === void 0) { hideEmailButton = false; }
        if (hideSubmitButton === void 0) { hideSubmitButton = false; }
        this.api = api;
        this.heading = heading;
        this.defaultOptions = defaultOptions;
        this.hideDownloadButton = hideDownloadButton;
        this.hideEmailButton = hideEmailButton;
        this.hideSubmitButton = hideSubmitButton;
        this.sendEmailButtonClicked = function () {
            _this._emailForm.submit();
            if (_this._emailForm.isValid()) {
                _this.optionsSlider.close();
                _this.observationProvider.notifyObservers(_1.ReportOptionSlideContainerActions.EmailSent);
            }
        };
        this.buildEmailOptionsForm = function () {
            var initialEmailMessage = 'This email has been sent to allow you access to a report for an upcoming event in TEMS. Please click the link below to view the report.';
            _this._emailForm = new __1.Form([
                new __1.TextField('emailSubject', 'Subject', true, 250, 'required', 'TEMS Report'),
                new __1.MultipleEmailField('emailAddresses', true, 'Email To'),
                new __1.TextField('message', 'Message', true, 1000, 'required', initialEmailMessage, fields_1.FieldType.TextArea)
            ]);
        };
        this.openButtonClicked = function () {
            _this._activePane = _1.ReportOptionSlideContainerPane.OptionForm;
            _this._optionsSlider.open();
            _this.sendEmailButton.setVisibility(false);
            _this.submitButton.setVisibility(true);
            _this.emailButton.setVisibility(!_this.hideEmailButton);
            _this.downloadButton.setVisibility(!_this.hideDownloadButton);
        };
        this.runReportClicked = function () {
            _this.form.submit();
            if (_this.form.isValid()) {
                _this.optionsSlider.close();
                _this.observationProvider.notifyObservers(_1.ReportOptionSlideContainerActions.RunReport);
            }
        };
        this.emailButtonClicked = function () {
            _this._form.submit();
            if (_this.form.isValid()) {
                _this._activePane = _1.ReportOptionSlideContainerPane.EmailOptions;
                _this.submitButton.setVisibility(false);
                _this.emailButton.setVisibility(false);
                _this.sendEmailButton.setVisibility(true);
                _this.downloadButton.setVisibility(false);
                _this.observationProvider.notifyObservers(_1.ReportOptionSlideContainerActions.EmailOptionsShown);
            }
        };
        this.sliderShown = function () {
            _this.clearOptions();
            _this.observationProvider.notifyObservers(_1.ReportOptionSlideContainerActions.Shown);
        };
        this.sliderHidden = function () {
            _this.observationProvider.notifyObservers(_1.ReportOptionSlideContainerActions.Hidden);
        };
        this.buildForm = function (filterFields) {
            _this._form = new __1.Form(filterFields);
            _this._form.setModel(_this.defaultOptions);
        };
        this.clearOptions = function () {
            _this._form.setModel(__assign({}, _this.defaultOptions));
        };
        this.observationProvider = new __2.ObservationProvider();
        this._activePane = _1.ReportOptionSlideContainerPane.OptionForm;
        this.buildForm(fields);
        this.buildEmailOptionsForm();
        this._optionsSlider = new _1.SlideContainer();
        this._optionsSlider.observationProvider.observe(this.sliderShown, _1.SlideContainerActions.Shown);
        this._optionsSlider.observationProvider.observe(this.sliderHidden, _1.SlideContainerActions.Hidden);
        this.emailButton = new button_2.default({
            text: 'Email',
            onClick: this.emailButtonClicked,
            isVisible: !this.hideEmailButton
        });
        this.sendEmailButton = new button_2.default({
            text: 'Send Email',
            onClick: this.sendEmailButtonClicked,
            isVisible: false
        });
        this.submitButton = new button_2.default({
            text: 'Run Report',
            onClick: this.runReportClicked,
            isVisible: !this.hideSubmitButton
        });
        this.downloadButton = new button_1.DownloadButton({
            api: this.api,
            text: 'Download',
            url: '',
            onClick: function () { return _this.optionsSlider.close(); },
            isVisible: !this.hideDownloadButton
        });
        this.openButton = new button_2.default({
            onClick: this.openButtonClicked
        });
    }
    Object.defineProperty(ReportOptionSlideContainer.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportOptionSlideContainer.prototype, "emailForm", {
        get: function () {
            return this._emailForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportOptionSlideContainer.prototype, "optionsSlider", {
        get: function () {
            return this._optionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportOptionSlideContainer.prototype, "activePane", {
        get: function () {
            return this._activePane;
        },
        enumerable: true,
        configurable: true
    });
    return ReportOptionSlideContainer;
}());
exports.default = ReportOptionSlideContainer;
