import { h, Component, JSX} from 'preact';
import RecordNotFoundStateProps from './recordNotFoundStateProps';

class RecordNotFoundState extends Component<RecordNotFoundStateProps> {

    public render({ title, message }: RecordNotFoundStateProps): JSX.Element {
        return (
            <div className="record-not-found-state">
                <i className="ti t i-search"/>
                <br/>
                <b>{ title ? title : 'Hmmmm....'}</b>
                <br/>
                { message ? message : 'We couldn\'t find anything matching your search.'}
            </div>
        );
    }
}

export default RecordNotFoundState;
