import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Table from '../table/table';
import TableColumn from '../table/tableColumn';
import TablePagerRow from '../table/tablePagerRow';
import TableHeader from '../table/tableHeader';
import TableSortButton from '../table/tableSortButton';
import EmptyState from '../uiStates/emptyState';
import RecordNotFoundState from '../uiStates/recordNotFoundState';
import SmartTableProps from './smartTableProps';

class SmartTable<T extends{id: number}> extends ObservingComponent<SmartTableProps<T>> {

    public componentWillMount(): void {
        const { table } = this.props;

        this.registerUpdateObserver(table.observationProvider);
    }

    private buildColumns = (): TableColumn<T>[] => {
        const { columns, table } = this.props;
        const dumbColumns: TableColumn<T>[] = [];

        for (const column of columns) {
            dumbColumns.push({
                className: column.className,
                width: column.width,
                heading: !column.sortExpression ? column.heading : (
                    <TableSortButton
                        table={table}
                        label={column.heading}
                        sortExpression={column.sortExpression} />
                ) ,
                headingClassName: column.headingClassName,
                cellClassName: column.cellClassName,
                cellTemplate: (item: T, rowIndex: number) =>
                    <span>
                        {(!column.hideWhenSaving || (!table.isIndexSaving(rowIndex) && !table.isIndexDeleting(rowIndex))) && (
                            <span>{column.cellTemplate(item, rowIndex)}</span>
                        )}

                        {column.appendSavingIndicator && table.isIndexSaving(rowIndex) && <span className="saving-indicator text-sm"> Saving...</span>}
                    </span>
            });
        }

        return dumbColumns;
    }

    private buildTable(): JSX.Element {
        const { expandedContentTemplate, table, headerClassName, tableClassName, id } = this.props;

        if (table.isEmptyState) {
            return <EmptyState />;
        }

        if (table.isNoRecordFoundState) {
            return <RecordNotFoundState />;
        }

        return (
            <Table
                id={id}
                className={tableClassName}
                headerClassName={headerClassName}
                columns={this.buildColumns()}
                isLoading={table.isLoading}
                items={table.items}
                rowClassName={(rowIndex: number) =>
                    (table.isIndexDeleting(rowIndex) ? ' deleting ' : '' ) +
                    (table.isIndexSaving(rowIndex) ? ' saving ' : '' )}
                loadingRowCount={table.recordsPerPage}
                expandedContentTemplate={expandedContentTemplate}
                table={table} />
        );
    }

    public render({ table, listOptionSlideContainer, id, showSearchClearButton }: SmartTableProps<T>): JSX.Element {
        if (!table.isVisible) {
            return null;
        }

        if (!id) {
            id = 'table';
        }

        return (
            <div>
                <TableHeader
                    id={id}
                    table={table}
                    listOptionSlideContainer={listOptionSlideContainer}
                    showSearchClearButton={showSearchClearButton} />

                {table.showPager &&
                    <TablePagerRow
                        className="d-md-flex d-none"
                        table={table} />
                }

                {this.buildTable()}

                {table.showPager &&
                    <TablePagerRow table={table} />
                }
            </div>
        );
    }
}

export default SmartTable;
