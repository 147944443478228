import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import EventInfoTileProps from './eventInfoTileProps';
import Button from '../buttons/button';
import VideoModalButton from '../buttons/videoModalButton';

class EventInfoTile extends ObservingComponent<EventInfoTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    public render({ color, tile, icon, editModeButtons, children }: EventInfoTileProps): JSX.Element {
        return (
            <div class={'event-info-tile ' + color +
                        (tile.isFullScreen ? ' full-screen' : '')} >

                { tile.isHeaderHidden === false &&
                    <header onClick={ tile.toggleOpen }>

                        <i class={'tile-icon mr-1 ti ' + icon}></i>

                        <span>{ tile.title }</span>

                        { tile.isEditMode ?
                            (
                                <div class="edit-mode-buttons flex">
                                    {editModeButtons &&
                                        <div class="flex v-center">
                                            {editModeButtons.map((button) => button)}
                                        </div>
                                    }

                                    {!editModeButtons &&
                                        <div class="flex v-center">
                                            <Button
                                                stopPropagation={true}
                                                button={tile.exitEditModeButton}
                                                className="cancel-button v-center" />

                                            <Button
                                                stopPropagation={true}
                                                button={tile.saveButton}
                                                className="update-button v-center" />
                                        </div>
                                    }
                                </div>
                            ) : (
                                tile.isOpen && (
                                    <div class="view-mode-buttons flex">

                                        <Button
                                            className="btn-icon btn-lg"
                                            button={tile.helpButton} />

                                        <Button
                                            stopPropagation={true}
                                            className="btn-icon"
                                            button={tile.fullScreenButton} />
                                        <Button
                                            stopPropagation={true}
                                            className="btn-icon"
                                            button={tile.editButton} />
                                        <Button
                                            stopPropagation={true}
                                            className="btn-icon"
                                            button={tile.refreshButton} />
                                    </div>
                                )
                            )
                        }

                    </header>
                }

                <div class={'content ' + (tile.isOpen ? 'active' : '')}>
                    { children }
                </div>
            </div>
        );
    }
}

export default EventInfoTile;
