import { FieldActions, ActionUtils, TextField as FrontEndTextField, FieldType } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface TextFieldProps {
    id?: string;
    field: FrontEndTextField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class TextField extends ObservingComponent<TextFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    public mouseDown = (e: any) => {
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: TextFieldProps): JSX.Element {

        if (!id)
        {
            id = field.uniqueKey;
        }

        return (
            <div className={`input-field ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} /> }

                <label
                    className={labelClassName}
                    for={id}>

                    <span class="fill">{field.label}</span>

                    {field.labelButtonText && <a onClick={field.handleLabelButtonClick}>{field.labelButtonText}</a>}

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>

                { field.fieldType !== FieldType.TextArea &&
                    <input
                        type="text"
                        id={id}
                        name={field.uniqueKey}
                        onFocus={field.focus}
                        onBlur={field.blur}
                        value={field.stringValue}
                        onChange={this.valueChanged}
                        className={field.isInvalid ? 'invalid' : ''}
                        disabled={field.isDisabled}
                        placeholder={field.placeHolderText}
                        maxLength={field.maxCharacters}
                        onMouseDown={this.mouseDown} />
                }

                { field.fieldType === FieldType.TextArea &&
                    <textarea
                        rows={3}
                        id={id}
                        name={field.uniqueKey}
                        onFocus={field.focus}
                        onBlur={field.blur}
                        onChange={this.valueChanged}
                        className={field.isInvalid ? 'invalid' : ''}
                        disabled={field.isDisabled}
                        placeholder={field.placeHolderText}
                        maxLength={field.maxCharacters}
                        onMouseDown={this.mouseDown}>
                            {field.stringValue}
                        </textarea>
                }

                { field.isActive && !field.stringValue && field.placeholderLabel && <label class="placeholder-label">{ field.placeholderLabel }</label> }

            </div>
        );
    }
}

export default TextField;
