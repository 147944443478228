import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import LoginPageWrapper from './loginPageWrapper';
import PasswordResetPageProps from './resetPasswordPageProps';
import ResetPasswordForm from './resetPasswordForm';

export class ResetPasswordPage extends ObservingComponent<PasswordResetPageProps> {

    public render({ page }: PasswordResetPageProps): JSX.Element {
        return (
            <LoginPageWrapper>
                <ResetPasswordForm page={page} />
            </LoginPageWrapper>
        );
    }
}

export default ResetPasswordPage;
