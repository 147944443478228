import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import CustomerCompaniesListPageProps from './customerCompaniesListPageProps';
import Card from '../containers/card';
import { CustomerCompanyTableItem } from 'core.frontend';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import VideoModalButton from '../buttons/videoModalButton';

class CustomerCompaniesListPage extends ObservingComponent<CustomerCompaniesListPageProps> {

    public componentWillMount() {}

    private buildColumns = (): SmartTableColumn<CustomerCompanyTableItem>[] => {
        const { page } =  this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'CustomerCompanies.Name',
                cellTemplate: (item, index) =>
                    (<span>
                        <Button
                            className="btn-link"
                            button={page.buildCustomerCompanyEditButton(item)} />
                        {!item.isActive &&
                            <span class="text-sm text-red"> - Inactive</span>
                        }
                    </span>),
                appendSavingIndicator: true
            }
        ];
    }

    public render({page}): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    heading="All Customer Companies"
                    headingRightContent={
                        <div class="button-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.newCustomerCompanyButton} />
                        </div>
                    }>

                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()}
                        listOptionSlideContainer={page.listOptionSlideContainer} />

                </Card>
            </div>
        );
    }
}

export default CustomerCompaniesListPage;
