import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Icon from '../icons/icon';
import DownloadButtonProps from './downloadButtonProps';
import DownloadButtonHandlingMethod from './downloadButtonHandlingMethod';
import ImageCarousel from '../imageCarousel/imageCarousel';

class DownloadButton extends ObservingComponent<DownloadButtonProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.button.observationProvider);
    }

    public handleClick = (e: Event) => {
        const { click } = this.props.button;

        if (this.props.stopPropagation) {
            e.stopPropagation();
        }

        if (typeof click === 'function') {
            click();
        }
    }

    private showCarousel = (e: Event) => {
        this.handleClick(e);

        this.props.button.loadCarousel();
    }


    private getButtonContent(): JSX.Element {
        const { children, iconClassName } = this.props;
        const { leftIcon, rightIcon, text, isProcessing, processingText } = this.props.button;

        const leftIconEnabled = !!leftIcon;
        const rightIconEnabled = !!rightIcon;

        return <span class="btn-content">
            {!isProcessing &&
            leftIconEnabled &&

                <Icon
                    className={iconClassName}
                    icon={leftIcon} />
            }

            {isProcessing && <span class="fill v-center">{processingText}</span>}

            {!isProcessing && !!text &&
                <span class="fill v-center">
                    {text}
                </span>
            }

            {!isProcessing && !!children && children}
            {!isProcessing && rightIconEnabled && <Icon icon={rightIcon} />}
        </span>;
    }

    private buildInlineUrl(url: string): string {
        if (url.indexOf('?') >= 0) {
            url += '&handlingMethod=Inline';
        } else {
            url += '?handlingMethod=Inline';
        }

        return url;
    }

    private buildDownloadUrl(url: string): string {
        if (url.indexOf('?') >= 0) {
            url += '&handlingMethod=Download';
        } else {
            url += '?handlingMethod=Download';
        }

        return url;
    }

    public render({ className, iconClassName, button, size, color, handlingMethod, target }: DownloadButtonProps): JSX.Element {
        if (!button || !button.isVisible) {
            return;
        }

        const { isLoading, isDisabled } = button;

        if (!target) {
            target = '_self';
        }

        className += ' btn button inline-flex v-center ';
        className += ` ${size} `;
        className += ` ${color} `;

        if (isLoading) {
            return <div class="loading-placeholder shimmer button"></div>
        }

        if (handlingMethod === DownloadButtonHandlingMethod.Inline) {
            return (
                <a
                    class={className}
                    href={this.buildInlineUrl(button.url)}
                    disabled={isDisabled}
                    onClick={this.handleClick}
                    target={target}>
                        {this.getButtonContent()}
                </a>
            );
        }

        if (handlingMethod === DownloadButtonHandlingMethod.Carousel) {
            return (
                <span>
                    <a
                        class={className}
                        disabled={isDisabled}
                        onClick={this.showCarousel}>
                            {this.getButtonContent()}
                    </a>

                    {button.imageCarousel &&
                        <ImageCarousel imageCarousel={button.imageCarousel} />
                    }
                </span>
            );
        }

        return (
            <a
                class={className}
                href={this.buildDownloadUrl(button.url)}
                disabled={isDisabled}
                onClick={this.handleClick}>
                    {this.getButtonContent()}
            </a>
        );
    }
}

export default DownloadButton;
