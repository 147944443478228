import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import TablePager from './tablePager';
import TableRecordCount from './tableRecordCount';
import TablePagerRowProps from './tablePagerRowProps';

class TablePagerRow extends ObservingComponent<TablePagerRowProps> {

    public componentWillMount(): void {
        const { table } = this.props;

        this.registerUpdateObserver(table.observationProvider);
    }

    public render({ table, className }: TablePagerRowProps): JSX.Element {
        return (
            <div className={className + ' table-pager-row'}>
                <TableRecordCount
                    isLoading={table.isLoading}
                    currentPageNumber={table.currentPage}
                    totalPageCount={table.pageCount}
                    totalRecords={table.totalRecordCount} />

                <TablePager table={table} />
            </div>
        );
    }
}

export default TablePagerRow;
