import { h, JSX } from 'preact';
import { UserGroupTableItem } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Card from '../containers/card';
import UserGroupListPageProps from './userGroupListPageProps';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';

class UserGroupListPage extends ObservingComponent<UserGroupListPageProps> {

    private buildColumns = (): SmartTableColumn<UserGroupTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'name',
                cellTemplate: (item, index) =>
                    (<span>
                        {page.table.isIndexDeleting(index) &&
                            <span>{item.name}</span>
                        }

                        {!page.table.isIndexDeleting(index) &&
                            <Button
                                className="btn-link"
                                button={page.buildEditButton(item)} />
                        }
                    </span>),
                appendSavingIndicator: true
            }
        ];
    }

    public render({ page }: UserGroupListPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    heading="All Users Groups"
                    headingRightContent={
                        <div class="button-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.newUserGroupButton} />
                        </div>
                    }>

                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()} />
                </Card>
            </div>
        );
    }
}

export default UserGroupListPage;
