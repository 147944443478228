import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import CardProps from './cardProps';

class Card extends ObservingComponent<CardProps> {

    public render({ heading, headingRightContent, footerContent, children, headingLeftContent, className, headingClassName }: CardProps): JSX.Element {

        return (
            <div class={"card " + className}>

                {(heading || headingRightContent || headingLeftContent) &&
                    <div class={"card-header flex " + headingClassName }>
                        {heading && <h4>{heading}</h4>}

                        { headingLeftContent }

                        { headingRightContent && headingRightContent}
                    </div>
                }

                <div class="card-main">
                    {children}
                </div>

                {footerContent && (
                    <div class="card-footer">
                        {footerContent}
                    </div>
                )}
            </div>
        );
    }
}

export default Card;
