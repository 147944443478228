import { h, JSX } from 'preact';
import { TravelAndAccommodationTilePanes, DateFormats, DailyTransportStatus as FrontEndDailyTransportStatus, LodgingStatus as FrontEndLodgingStatus, Icons } from 'core.frontend';
import TravelAndAccommodationsTileProps from './travelAndAccommodationsTileProps';
import EventInfoTile from './eventInfoTile';
import DailyTransportStatus from './dailyTransportStatus';
import LodgingStatus from './lodgingStatus';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import FormattedDate from '../text/formattedDate';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import PhoneButton from '../buttons/phoneButton';
import EmailButton from '../buttons/emailButton';
import FormButtonContainer from '../containers/formButtonContainer';
import Modal from '../containers/modal';
import DataList from '../dataList/dataList';
import ArrivalDepartureType from './arrivalDepartureType';
import SubmittedStatus from './submittedStatus';
import HistoryTable from '../history/historyTable';
import Alert from '../alerts/alert';
import Icon from '../icons/icon';
import SearchField from '../fields/searchField';
import VideoModalButton from '../buttons/videoModalButton';

class TravelAndAccommodationsTile extends ObservingComponent<TravelAndAccommodationsTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    public render({ tile }: TravelAndAccommodationsTileProps): JSX.Element {
        return (
            tile.isVisible && <EventInfoTile
                color="red"
                tile={tile}
                icon="ti-accommodation">

                <div>
                    {
                        tile.isEditable &&
                        tile.isAnyInfoSubmitted &&
                        !tile.isEditMode &&

                        <p class="text-center text-sm">
                            Use the Edit pencil (<Icon icon={Icons.Edit} />) in the upper right-hand corner of this tile to enter or edit event Travel & Hotel requirements
                        </p>
                    }

                    <div class="flex mt-1">
                        <div class="fill">
                            <Field
                                className="no-label"
                                field={tile.attendeeDropdown} />
                        </div>

                        {!!tile.attendeeContactPhone &&
                            <PhoneButton
                                className="ml-p5"
                                phoneNumber={tile.attendeeContactPhone} />
                        }

                        {!!tile.attendeeContactEmail &&
                            <EmailButton
                                className="ml-p5"
                                emailAddress={tile.attendeeContactEmail} />
                        }

                        {tile.canViewHistory &&
                            <Button
                                className="btn-icon ml-p5"
                                button={tile.showHistoryButton} />
                        }
                    </div>

                    <Alert
                        className="mt-1"
                        alert={tile.alert} />

                    {!!tile.attendeeDropdown.value &&
                        <div>

                            {!tile.isEditMode && tile.isAnyInfoSubmitted &&
                                <div>
                                    <div>
                                        <div class="sub-header">
                                            Attendee Guest Info
                                        </div>

                                        {tile.usersAccommodation.attendeeInfoSubmitted &&
                                            <div class="flex">
                                                <div class="fill text-sm">
                                                    <div>
                                                        Mobile Phone: <b>{tile.usersAccommodation.contactPhone}</b>
                                                    </div>

                                                    <div>
                                                        Contact Email: <b>{tile.usersAccommodation.contactEmail}</b>
                                                    </div>

                                                    <div>
                                                        Guest(s):

                                                        {tile.usersAccommodation.guests.map((guest, index) =>
                                                            <b>&nbsp;{guest.name}</b>
                                                        )}

                                                        {!tile.usersAccommodation.guests.length &&
                                                            <span>None</span>
                                                        }
                                                    </div>

                                                    <div>
                                                        Total in Party: <b>{tile.usersAccommodation.totalInParty}</b>
                                                    </div>

                                                    <div>
                                                        Attendee Comments:

                                                        {tile.usersAccommodation.guestComments &&
                                                            <b> {tile.usersAccommodation.guestComments}</b>
                                                        }

                                                        {!tile.usersAccommodation.guestComments &&
                                                            <i> None</i>
                                                        }
                                                    </div>
                                                </div>

                                                <SubmittedStatus isSubmitted={tile.usersAccommodation.attendeeInfoSubmitted} />
                                            </div>
                                        }

                                        {!tile.usersAccommodation.attendeeInfoSubmitted &&
                                            <p class="text-center text-sm">
                                                Use the Edit pencil (<Icon icon={Icons.Edit} />) above to enter the selected Attendee & Guest info for this event
                                            </p>
                                        }
                                    </div>

                                    <div>
                                        <div class="sub-header">
                                            Arrival / Departure
                                        </div>

                                        {tile.usersAccommodation.arrivalInfoSubmitted &&
                                            <div class="grid-container">
                                                <div class="sm-12 md-6">

                                                    <div class="flex">
                                                        <div class="text-sm">
                                                            <div>
                                                                Arrival
                                                                <hr/>
                                                            </div>

                                                            <b>
                                                                <FormattedDate
                                                                    date={tile.usersAccommodation.arrivalDateTime}
                                                                    format={DateFormats.MMMdYYYY} />

                                                                <span> - </span>

                                                                <FormattedDate
                                                                    date={tile.usersAccommodation.arrivalDateTime}
                                                                    format={DateFormats.hhmm} />
                                                            </b>

                                                            <div>
                                                                Travel Type: <b> <ArrivalDepartureType type={tile.usersAccommodation.arrivalType} /></b>
                                                            </div>

                                                            <div>
                                                                Carrier/Num: <b>{tile.usersAccommodation.arrivalCarrier} / {tile.usersAccommodation.arrivalNumber}</b>
                                                            </div>

                                                            <div>
                                                                Origin: <b>{tile.usersAccommodation.arrivalOrigin}</b>
                                                            </div>

                                                            <div>
                                                                Destination: <b>{tile.usersAccommodation.arrivalDestination}</b>
                                                            </div>

                                                            <div>
                                                                Attendee Comments:

                                                                {tile.usersAccommodation.arrivalComments &&
                                                                     <b> {tile.usersAccommodation.arrivalComments}</b>
                                                                }

                                                                {!tile.usersAccommodation.arrivalComments &&
                                                                     <i> None</i>
                                                                }
                                                            </div>
                                                        </div>

                                                        <SubmittedStatus
                                                            className="text-right ml-3 fill md-fill-0"
                                                            isSubmitted={tile.usersAccommodation.arrivalInfoSubmitted} />
                                                    </div>
                                                </div>

                                                <div class="mt-1 sm-12 md-mt-0 md-6">
                                                    <div class="flex">
                                                        <div class="text-sm">
                                                            <div>
                                                                Departure
                                                                <hr/>
                                                            </div>
                                                            <b>
                                                                <FormattedDate
                                                                    date={tile.usersAccommodation.departureDateTime}
                                                                    format={DateFormats.MMMdYYYY}  />

                                                                <span> - </span>

                                                                <FormattedDate
                                                                    date={tile.usersAccommodation.departureDateTime}
                                                                    format={DateFormats.hhmm} />
                                                            </b>

                                                            <div>
                                                                Travel Type: <b> <ArrivalDepartureType type={tile.usersAccommodation.departureType} /></b>
                                                            </div>

                                                            <div>
                                                                Carrier/Num: <b>{tile.usersAccommodation.departureCarrier} / {tile.usersAccommodation.departureNumber}</b>
                                                            </div>

                                                            <div>
                                                                Origin: <b>{tile.usersAccommodation.departureOrigin}</b>
                                                            </div>

                                                            <div>
                                                                Destination: <b>{tile.usersAccommodation.departureDestination}</b>
                                                            </div>

                                                            <div>
                                                                Attendee Comments:

                                                                {tile.usersAccommodation.departureComments &&
                                                                    <b> {tile.usersAccommodation.departureComments}</b>
                                                                }

                                                                {!tile.usersAccommodation.departureComments &&
                                                                    <i> None</i>
                                                                }
                                                            </div>

                                                        </div>

                                                        <SubmittedStatus
                                                            className="text-right ml-3 fill md-fill-0"
                                                            isSubmitted={tile.usersAccommodation.departureInfoSubmitted} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {!tile.usersAccommodation.arrivalInfoSubmitted &&
                                            <p class="text-center text-sm">
                                                Use the Edit pencil (<Icon icon={Icons.Edit} />) above to enter the selected Attendee’s arrival and departure info for this event.
                                            </p>
                                        }
                                    </div>

                                    {(tile.isHotelToAirportTransportEnabled || tile.isAirportToHotelTransportEnabled) &&
                                        <div>
                                            <div class="sub-header">
                                                Arrival / Departure Transport
                                            </div>

                                            {(tile.usersAccommodation.airportToHotelTransportRequired === true ||
                                             tile.usersAccommodation.airportToHotelTransportRequired === false) &&

                                                <div class="text-sm">
                                                    {tile.isAirportToHotelTransportEnabled &&
                                                        <div>
                                                            <span>Arrival Transport </span>

                                                            {!!tile.usersAccommodation.airportToHotelTransportRequired &&
                                                                <b class="text-green">Yes</b>
                                                            }

                                                            {!tile.usersAccommodation.airportToHotelTransportRequired &&
                                                                <b>No</b>
                                                            }
                                                        </div>
                                                    }

                                                    {tile.isHotelToAirportTransportEnabled &&
                                                        <div>
                                                            <span>Departure Transport </span>

                                                            {!!tile.usersAccommodation.hotelToAirportTransportRequired &&
                                                                <b class="text-green">Yes</b>
                                                            }

                                                            {!tile.usersAccommodation.hotelToAirportTransportRequired &&
                                                                <b>No</b>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {tile.usersAccommodation.airportToHotelTransportRequired !== true &&
                                             tile.usersAccommodation.airportToHotelTransportRequired !== false &&
                                                <p class="text-center text-sm">
                                                    Use the Edit pencil (<Icon icon={Icons.Edit} />) above to specify if the selected Attendee needs transportation to and from their hotel.
                                                </p>
                                            }
                                        </div>
                                    }

                                    {!!tile.configurations.isTransportationEnabled && (
                                            tile.isDailyHotelEventTransportEnabled ||
                                            tile.isDailyIndividualTransportAvailable
                                        ) &&

                                        <div>
                                            <div class="sub-header flex">
                                                Daily Transportation

                                                <Button
                                                    className="ml-auto btn-link text-sm text-white"
                                                    button={tile.viewTransportTermsAndConditionsButton} />
                                            </div>

                                            {tile.usersAccommodation.dailyTransportStatus != FrontEndDailyTransportStatus.NotSubmitted &&
                                                <div>
                                                    <div class="flex mt-1">

                                                        <div class="fill text-sm">
                                                            {!!tile.usersAccommodation.companyProvidedDailyTransportRequired && tile.isDailyHotelEventTransportEnabled &&
                                                                <div class="mb-1">
                                                                    <b class="text-green">
                                                                        Company Provided Shuttle
                                                                    </b>

                                                                    <div>
                                                                        <b>
                                                                            <FormattedDate
                                                                                date={tile.usersAccommodation.companyProvidedDailyTransportStartDate}
                                                                                format={DateFormats.MMMdYYYY} />

                                                                            <span> thru </span>

                                                                            <FormattedDate
                                                                                date={tile.usersAccommodation.companyProvidedDailyTransportEndDate}
                                                                                format={DateFormats.MMMdYYYY} />
                                                                        </b>
                                                                    </div>

                                                                    {tile.configurations.eventTransportationOptions.map((option) => {
                                                                        if (!option.dailyHotelEventTransportSchedule) {
                                                                            return null;
                                                                        }

                                                                        return (
                                                                            <div class="mt-1">
                                                                                Schedule: <b>{option.dailyHotelEventTransportSchedule}</b>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            }

                                                            {tile.isDailyIndividualTransportAvailable &&
                                                                (!!tile.usersAccommodation.rentalCarRequired ||
                                                                 !!tile.usersAccommodation.rentalCarSharingWithEventAttendeeId ) &&

                                                                <div class="mb-1">
                                                                    <b class="text-green">
                                                                        Individual Transport
                                                                    </b>

                                                                    <div>
                                                                        {tile.usersAccommodation.rentalCarVehicleId &&
                                                                            <div>
                                                                                <div>
                                                                                    <b>{tile.usersAccommodation.rentalCarVehicle}</b>
                                                                                </div>

                                                                                <b>
                                                                                    <FormattedDate
                                                                                        date={tile.usersAccommodation.rentalCarStartDate}
                                                                                        format={DateFormats.MMMdYYYY} />

                                                                                    <span> thru </span>

                                                                                    <FormattedDate
                                                                                        date={tile.usersAccommodation.rentalCarEndDate}
                                                                                        format={DateFormats.MMMdYYYY} />
                                                                                </b>
                                                                            </div>
                                                                        }

                                                                        {tile.usersAccommodation.rentalCarSharingWithEventAttendeeId &&
                                                                            <div>
                                                                                Sharing with <b>{tile.usersAccommodation.rentalCarSharingWithEventAttendee}</b>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }

                                                            {!tile.usersAccommodation.rentalCarRequired &&
                                                            !tile.usersAccommodation.companyProvidedDailyTransportRequired &&
                                                                <b>Daily Transport not Required</b>
                                                            }

                                                            <div>
                                                                Attendee Comments:

                                                                {tile.usersAccommodation.dailyTransportComments &&
                                                                    <b> {tile.usersAccommodation.dailyTransportComments}</b>
                                                                }

                                                                {!tile.usersAccommodation.dailyTransportComments &&
                                                                    <i> None</i>
                                                                }
                                                            </div>

                                                            <div>
                                                                Vendor Comments:

                                                                {tile.usersAccommodation.transportationVendorComments &&
                                                                    <i> {tile.usersAccommodation.transportationVendorComments}</i>
                                                                }

                                                                {!tile.usersAccommodation.transportationVendorComments &&
                                                                    <i> None</i>
                                                                }
                                                            </div>

                                                        </div>

                                                        <DailyTransportStatus
                                                            className="text-right"
                                                            status={tile.usersAccommodation.dailyTransportStatus} />
                                                    </div>
                                                </div>
                                            }

                                            {tile.usersAccommodation.dailyTransportStatus == FrontEndDailyTransportStatus.NotSubmitted &&
                                                <p class="text-center text-sm">
                                                    Use the Edit pencil (<Icon icon={Icons.Edit} />) above to specify which form of Daily Transportation the selected Attendee will need for this event.
                                                </p>
                                            }
                                        </div>
                                    }

                                    {!!tile.configurations.isHotelsEnabled &&
                                        <div>
                                            <div class="sub-header flex">
                                                Hotel

                                                <Button
                                                    className="ml-auto btn-link text-sm text-white"
                                                    button={tile.viewLodgingTermsAndConditionsButton} />
                                            </div>

                                                {tile.usersAccommodation.lodgingStatus != FrontEndLodgingStatus.NotSubmitted &&
                                                    <div>
                                                        <div class="flex">
                                                            <div class="text-sm">
                                                            <div>
                                                                    <span>Assigned Hotel: </span>

                                                                    {!tile.usersAccommodation.assignedHotel &&
                                                                        <i>Unassigned</i>
                                                                    }

                                                                    {!!tile.usersAccommodation.assignedHotel &&
                                                                        <b>{tile.usersAccommodation.assignedHotel}</b>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span>Confirmation#: </span>

                                                                    {!tile.usersAccommodation.lodgingConfirmationNumber &&
                                                                        <i>Unassigned</i>
                                                                    }

                                                                    {!!tile.usersAccommodation.lodgingConfirmationNumber &&
                                                                        <b>{tile.usersAccommodation.lodgingConfirmationNumber}</b>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span>Check-In </span>

                                                                    {!tile.usersAccommodation.hotelCheckInDate &&
                                                                        <i> Unknown</i>
                                                                    }

                                                                    {tile.usersAccommodation.hotelCheckInDate &&
                                                                        <b> <FormattedDate date={tile.usersAccommodation.hotelCheckInDate} /></b>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span>Early Check-In </span>

                                                                    {!!tile.usersAccommodation.hotelEarlyArrivalRequired &&
                                                                        <b class="text-green">
                                                                            Yes
                                                                        </b>
                                                                    }

                                                                    {!tile.usersAccommodation.hotelEarlyArrivalRequired &&
                                                                        <b class="text-red">
                                                                            No
                                                                        </b>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <span>Check-Out </span>

                                                                    {!tile.usersAccommodation.hotelCheckOutDate &&
                                                                        <i> Unknown</i>
                                                                    }

                                                                    <b> <FormattedDate date={tile.usersAccommodation.hotelCheckOutDate} /></b>
                                                                </div>
                                                                <div>
                                                                    <span>Late Check-Out </span>

                                                                    {!!tile.usersAccommodation.hotelLateCheckoutRequired &&
                                                                        <b class="text-green">
                                                                            Yes
                                                                        </b>
                                                                    }

                                                                    {!tile.usersAccommodation.hotelLateCheckoutRequired &&
                                                                        <b class="text-red">
                                                                            No
                                                                        </b>
                                                                    }
                                                                </div>

                                                                <div>
                                                                    Total in Room: <b>{tile.usersAccommodation.hotelTotalInRoom}</b>
                                                                </div>

                                                                <div>
                                                                    Attendee Comments:

                                                                    {tile.usersAccommodation.hotelComments &&
                                                                        <b> {tile.usersAccommodation.hotelComments}</b>
                                                                    }

                                                                    {!tile.usersAccommodation.hotelComments &&
                                                                        <i> None</i>
                                                                    }
                                                                </div>

                                                                <div>
                                                                    Vendor Comments:

                                                                    {tile.usersAccommodation.hotelVendorComments &&
                                                                        <b> {tile.usersAccommodation.hotelVendorComments}</b>
                                                                    }

                                                                    {!tile.usersAccommodation.hotelVendorComments &&
                                                                        <i> None</i>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <LodgingStatus
                                                                className="fill text-right"
                                                                status={tile.usersAccommodation.lodgingStatus} />
                                                        </div>
                                                    </div>
                                                }

                                                {tile.usersAccommodation.lodgingStatus == FrontEndLodgingStatus.NotSubmitted &&
                                                    <p class="text-center text-sm">
                                                        Use the Edit pencil (<Icon icon={Icons.Edit} />) above to enter the selected Attendee Hotel requirements for this event.
                                                    </p>
                                                }
                                        </div>
                                    }
                                </div>
                            }

                            {!tile.isEditMode && !tile.isAnyInfoSubmitted &&
                                <div>
                                    <div class="mt-2 mb-2 text-center text-sm">
                                        There is currently no “Travel & Hotel” information for the Attendee that you have chosen!
                                        <br/>
                                        <br/>
                                        Use the Edit pencil (<Icon icon={Icons.Edit} />) in the upper right-hand corner of this tile to enter or edit event Travel & Hotel requirements
                                        <br/>
                                        <br/>
                                        If you do not see the Edit Pencil above, then you do not have the rights to edit this Attendees information.
                                    </div>
                                </div>
                            }

                            {tile.isEditMode &&
                                <div>
                                    {tile.activePane === TravelAndAccommodationTilePanes.AttendeeInfo &&
                                        <div>
                                            <div class="sub-header">
                                                Attendee / Guest Info

                                                <Button
                                                    className="btn-icon btn-lg"
                                                    button={tile.attendeeInfoHelpButton} />
                                            </div>

                                            <SubmittedStatus
                                                className="text-right mt-1"
                                                isSubmitted={tile.usersAccommodation.attendeeInfoSubmitted} />

                                            <div class="form-container grid-container">
                                                <Field
                                                    className="sm-4"
                                                    field={tile.contactPhoneField} />

                                                <Field
                                                    className="sm-4"
                                                    field={tile.emailField} />

                                                <Field
                                                    className="sm-4"
                                                    field={tile.contactEmailField} />

                                                <div class="text-right mt-1 xs-12">
                                                    <Button
                                                        size={ButtonSizes.Small}
                                                        color={ButtonColors.Primary}
                                                        button={tile.guestDataList.addItemButton} />
                                                </div>

                                                <DataList
                                                    className="xs-12"
                                                    dataList={tile.guestDataList} />

                                                <div class="xs-12">
                                                    <span class="text-red text-sm">
                                                        <b>NOTE:</b> If any person traveling with you is under the age of 18 years old,  please contact your Trade Show Manager. They will provide you with any occupancy restrictions for the hotels used for this event
                                                    </span>
                                                </div>

                                                <Field
                                                    className="xs-12"
                                                    field={tile.totalInPartyField} />

                                                <Field
                                                    className="xs-12"
                                                    field={tile.guestCommentsField} />
                                            </div>
                                        </div>
                                    }

                                    {tile.activePane === TravelAndAccommodationTilePanes.Arrival &&
                                        <div>
                                            <div class="sub-header">
                                                Arrival

                                                <Button
                                                    className="btn-icon btn-lg"
                                                    button={tile.arrivalHelpButton} />
                                            </div>

                                            <SubmittedStatus
                                                className="text-right mt-1"
                                                isSubmitted={tile.usersAccommodation.arrivalInfoSubmitted} />

                                            <div class="form-container grid-container">

                                                <Field
                                                    className="sm-4"
                                                    field={tile.arrivalTypeField} />

                                                <Field
                                                    className="sm-4"
                                                    field={tile.arrivalDateField} />

                                                <Field
                                                    className="sm-4"
                                                    field={tile.arrivalTimeField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.arrivalCarrierField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.arrivalNumberField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.arrivalOriginField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.arrivalDestinationField} />

                                                <Field
                                                    className="sm-12"
                                                    field={tile.arrivalCommentsField} />

                                                <Field
                                                    className="sm-12 mb-1"
                                                    field={tile.airportToHotelTransportRequiredField} />

                                                <div className="p-1 mb-1">
                                                    Arrival/Departure Comments:

                                                    <div>
                                                        {tile.arrivalDepartureComments}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {tile.activePane === TravelAndAccommodationTilePanes.Departure &&
                                        <div>
                                            <div class="sub-header">
                                                Departure

                                                <Button
                                                    className="btn-icon btn-lg"
                                                    button={tile.departureHelpButton} />
                                            </div>

                                            <SubmittedStatus
                                                className="text-right mt-1"
                                                isSubmitted={tile.usersAccommodation.departureInfoSubmitted} />

                                            <div class="form-container grid-container">

                                                <Field
                                                    className="sm-4"
                                                    field={tile.departureTypeField} />

                                                <div className="sm-4">

                                                    <Field
                                                        field={tile.departureDateField} />

                                                    {tile.isArrivalDateGreaterThanDepartureDate &&
                                                        <div class="text-sm invalid">
                                                            Departure date must be greater than arrival date
                                                        </div>
                                                    }
                                                </div>

                                                <Field
                                                    className="sm-4"
                                                    field={tile.departureTimeField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.departureCarrierField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.departureNumberField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.departureOriginField} />

                                                <Field
                                                    className="sm-6"
                                                    field={tile.departureDestinationField} />

                                                <Field
                                                    className="sm-12"
                                                    field={tile.departureCommentsField} />

                                                <Field
                                                    className="sm-12 mb-1"
                                                    field={tile.hotelToAirportTransportRequiredField} />

                                                <div className="p-1 mb-1 sm-12">
                                                    Arrival/Departure Comments:

                                                    <div>
                                                        {tile.arrivalDepartureComments}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {tile.activePane === TravelAndAccommodationTilePanes.DailyTransport &&
                                        <div class="mb-1">
                                            <div class="sub-header">
                                                Daily Transportation

                                                <Button
                                                    className="btn-icon btn-lg"
                                                    button={tile.dailyTransportHelpButton} />
                                            </div>

                                            <div class="text-right mt-1">
                                                <DailyTransportStatus status={tile.usersAccommodation.dailyTransportStatus} />
                                            </div>

                                            <div class="form-container">

                                                {tile.isDailyHotelEventTransportEnabled &&
                                                    <div>
                                                        <Field
                                                            className="bold-label"
                                                            field={tile.companyProvidedDailyTransportRequiredField} />

                                                        <div class="grid-container">
                                                            <Field
                                                                className="sm-6"
                                                                field={tile.companyProvidedDailyTransportStartDateField} />
                                                            <Field
                                                                className="sm-6"
                                                                field={tile.companyProvidedDailyTransportEndDateField} />
                                                        </div>

                                                        {tile.companyProvidedDailyTransportRequiredField.value &&
                                                            <div>
                                                                Shuttle Comments:

                                                                <div>
                                                                    {tile.dailyShuttleComments}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                {tile.isDailyIndividualTransportAvailable &&
                                                    <div>
                                                        <hr/>

                                                        <Field
                                                            className="bold-label mb-p5"
                                                            field={tile.rentalCarRequiredField} />

                                                        <Field field={tile.rentalCarVehicleIdField} />

                                                        <div class="grid-container">
                                                            <Field
                                                                className="sm-6"
                                                                field={tile.rentalCarStartDateField} />
                                                            <Field
                                                                className="sm-6"
                                                                field={tile.rentalCarEndDateField} />
                                                        </div>

                                                        <hr/>

                                                        <Field
                                                            className="bold-label"
                                                            field={tile.sharingRentalCarField} />

                                                        <SearchField
                                                            className="mt-1"
                                                            searchField={tile.rentalCarSharingWithEventAttendeeIdField} />

                                                        <div class="mt-p5 mb-1 text-sm text-red">
                                                            <b>Please Note: </b> When sharing individual transport, you will automatically be marked as Airport to Hotel transport and hotel to airport transport.
                                                        </div>
                                                    </div>
                                                }

                                                <hr/>

                                                <Field
                                                    className="bold-label"
                                                    field={tile.dailyTransportOtherField} />

                                                <Field field={tile.dailyTransportCommentsField} />

                                                {!tile.isDailyTransportTermsLabelVisible &&
                                                    <div class="text-sm mt-1">
                                                        <div>
                                                            Please read these &nbsp;

                                                            <Button
                                                                className="btn-link"
                                                                button={tile.viewTransportTermsAndConditionsButton} />
                                                        </div>

                                                        <Field field={tile.transportationTermsAndConditionsAcceptedField} />
                                                    </div>
                                                }

                                                {tile.isDailyTransportTermsLabelVisible &&
                                                    <div class="text-sm mt-1">
                                                        <span>
                                                            Aggreed to
                                                            &nbsp;

                                                            <Button
                                                                className="btn-link"
                                                                button={tile.viewTransportTermsAndConditionsButton} />

                                                            &nbsp;
                                                        </span>

                                                        <FormattedDate date={tile.usersAccommodation.transportationTermsAndConditionsAcceptedDateTime} />
                                                        <span> from IP: </span>
                                                        {tile.usersAccommodation.transportationTermsAndConditionsAcceptedIpAddress}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {tile.activePane === TravelAndAccommodationTilePanes.HotelInfo &&
                                        <div class="mb-1">
                                            <div class="sub-header">
                                                Hotel

                                                <Button
                                                    className="btn-icon btn-lg"
                                                    button={tile.hotelHelpButton} />
                                            </div>

                                            <div class="text-right mt-1">
                                                <LodgingStatus status={tile.usersAccommodation.lodgingStatus} />
                                            </div>

                                            <div class="grid-container form-container">
                                                <Field
                                                    className="xs-12 md-8"
                                                    field={tile.hotelBookingTypeField} />

                                                <Field
                                                    className="xs-12 md-4"
                                                    field={tile.hotelTotalInRoomField} />

                                                <Field
                                                    className="xs-6"
                                                    field={tile.hotelCheckInDateField} />

                                                <Field
                                                    className="xs-6"
                                                    field={tile.hotelCheckOutDateField} />

                                                <Field
                                                    className="xs-12 sm-6 inline-checkbox"
                                                    field={tile.hotelLateCheckoutRequiredField} />

                                                <Field
                                                    className="xs-12 sm-6"
                                                    field={tile.hotelEarlyArrivalRequiredField} />

                                                <div class="xs-12 text-sm text-red mt-1">
                                                    <b>Note: </b> If you will be arriving prior to the hotel’s posted check-in time, your room will not be available. In order for a room to be available prior to the posted check-in time you must check “Yes" on the “Hold Room for Early Arrival.“  - Please note that your room will then be reserved for the previous night and you will be automatically charged, by the hotel, for that night.
                                                </div>

                                                <Field
                                                    className="xs-12"
                                                    field={tile.hotelComments} />

                                                <div class="xs-12">
                                                    {!tile.isLodgingTermsLabelVisible &&
                                                        <div class="text-sm mt-1">
                                                            <div>
                                                                Please read these &nbsp;

                                                                <Button
                                                                    className="btn-link"
                                                                    button={tile.viewLodgingTermsAndConditionsButton} />
                                                            </div>

                                                            <Field field={tile.hotelTermsAndConditionsAcceptedField} />
                                                        </div>
                                                    }

                                                    {tile.isLodgingTermsLabelVisible &&
                                                        <div class="text-sm mt-1">
                                                            <span>
                                                                Aggreed to
                                                                &nbsp;

                                                                <Button
                                                                    className="btn-link"
                                                                    button={tile.viewLodgingTermsAndConditionsButton} />

                                                                &nbsp;
                                                            </span>

                                                            <FormattedDate date={tile.usersAccommodation.hotelTermsAndConditionsAcceptedDateTime} />
                                                            <span> from IP: </span>
                                                            {tile.usersAccommodation.hotelTermsAndConditionsAcceptedIpAddress}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {tile.activePane === TravelAndAccommodationTilePanes.Completed &&
                                        <div class="text-center mt-2 mt-2">

                                            <Icon
                                                className="text-green text-super"
                                                icon={Icons.Check} />

                                            <div class="mt-1">
                                                <b>Your accommodation information has been submitted</b>
                                                <p class="mt-0 text-sm">Click the exit button above to return to view your accommodation information.</p>
                                            </div>
                                        </div>
                                    }

                                    <FormButtonContainer
                                        rightButtons={[
                                            <Button
                                                className=""
                                                color={ButtonColors.Secondary}
                                                button={tile.previousButton} />,

                                            <Button
                                                color={ButtonColors.Primary}
                                                button={tile.nextButton} />,

                                            <Button
                                                color={ButtonColors.Primary}
                                                button={tile.saveLastPageButton} />
                                        ]} />
                                </div>
                            }
                        </div>
                    }

                    {!tile.attendeeDropdown.value &&
                        <div>
                            <hr/>

                            <div class="mt-2 mb-2 text-center">
                                Use the dropdown above to choose an event Attendee name from the list provided<br/>
                                After choosing an Attendee you will be able to add/edit/view their requirements for this event
                            </div>
                        </div>
                    }

                    {tile.isLodingTermsAndConditionsVisible &&
                        <Modal
                            close={tile.hideLodgingTermsAndConditions}
                            heading="Hotel Terms & Conditions" >

                            <div
                                class="mt-1"
                                dangerouslySetInnerHTML={{ __html: tile.configurations.hotelTermsAndConditions}}>
                            </div>
                        </Modal>
                    }

                    {tile.isTransportTermsAndConditionsVisible &&
                        <Modal
                            close={tile.hideTransportTermsAndConditions}
                            heading="Transport Terms & Conditions">

                            <div
                                class="mt-1"
                                dangerouslySetInnerHTML={{ __html: tile.configurations.transportationTermsAndConditions}}>
                            </div>
                        </Modal>
                    }

                    {!!tile.historyTableIsVisible &&
                        <Modal
                            heading="Travel & Hotel History"
                            close={tile.hideHistory}>

                            <div class="mt-1">
                                <HistoryTable
                                    headerClassName="d-none"
                                    historyTable={tile.historyTable} />
                            </div>
                        </Modal>
                    }
                </div>
            </EventInfoTile>
        );
    }
}

export default TravelAndAccommodationsTile;
