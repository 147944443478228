import { h, JSX } from 'preact';
import NewEventPageProps from './newEventPageProps';
import EventInfoForm from './eventInfoForm';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import FormButtonContainer from '../containers/formButtonContainer';
import VideoModalButton from '../buttons/videoModalButton';

class NewEventPage extends ObservingComponent<NewEventPageProps> {

    public render({ page }: NewEventPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <div class="flex">
                    <Button
                        className="btn-icon btn-lg"
                        button={page.helpButton} />
                </div>

                <EventInfoForm eventInfoForm={page.eventInfoForm}></EventInfoForm>

                <FormButtonContainer
                    className='sticky-button-footer'
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.cancelButton} />,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton} />
                    ]} />
            </div>
        );
    }
}

export default NewEventPage;
