import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { SsoLoginPage as FrontEndSsoLoginPage} from 'core.frontend';

interface SsoLoginPageProps {
    page: FrontEndSsoLoginPage;
}

class SsoLoginPage extends ObservingComponent<SsoLoginPageProps> {

    public render({ page }: SsoLoginPageProps): JSX.Element {
        return (
            <div>
                Loading your user information from your organization. Please wait...
            </div>
        );
    }
}

export default SsoLoginPage;
