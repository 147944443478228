
import { FieldActions, ActionUtils, BaseDropdownField as FrontEndField, FieldOption, DateUtils, DateFormats } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface DropdownFieldProps {
    id?: string;
    field: FrontEndField<any>;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class DropdownField extends ObservingComponent<DropdownFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown, FieldActions.ValueChanged]));
    }

    public getOptionValue = (option: FieldOption<any>) => {

        if (option.value instanceof Date) {
            return DateUtils.formatDate(option.value, DateFormats.MMDDYYYY);
        }

        return option.value || option.value === 0 ? option.value.toString() : null;
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    private buildOptions = (): JSX.Element[] => {
        const { field } = this.props;

        const optionGroups: JSX.Element[] = [];
        let options: JSX.Element[] = [];

        if (!field.noSelectionLabel) {
            options.push(<option
                            key={0}
                            value=""
                            disabled
                            selected>
                         </option>);
        } else {
            options.push(<option
                            key={0}
                            value=""
                            selected>
                                {field.noSelectionLabel}
                         </option>);
        }

        let i: number = 1;
        let seperatorLabel: string = '';

        for (const option of field.options) {

            if (option.value === -2) {
                seperatorLabel = option.label;

                if (optionGroups.length > 0) {
                    optionGroups.push(<optgroup label={seperatorLabel}>{options}</optgroup>);
                } else {
                    optionGroups.push(<optgroup>{options}</optgroup>);
                }

                options = [];

                continue;
            }

            if (option.disabled === true) {
                options.push(
                    <option
                        key={i++}
                        value={this.getOptionValue(option)}
                        disabled>

                        {option.label}
                    </option>
                );
            } else {
                options.push(
                    <option
                        key={i++}
                        value={this.getOptionValue(option)}>

                        {option.label}
                    </option>
                )
            }
        }

        if (optionGroups.length > 0) {
            optionGroups.push(<optgroup label={seperatorLabel}>{options}</optgroup>);
            return optionGroups;
        } else {
            return options;
        }
    }

    private getFieldValue = (): string | number | string => {
        if (this.props.field.value instanceof Date) {
            return DateUtils.formatDate(this.props.field.value);
        }

        return this.props.field.value;
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: DropdownFieldProps): JSX.Element {
        const wrapperClassName = `${className} ${field.isActive && 'active'} input-field select-wrapper`;

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div className={wrapperClassName}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} />}

                <label
                    className={labelClassName}
                    for={id}>

                    <span class="fill">
                        {field.label}
                    </span>

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>

                <select
                    id={id}
                    name={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    value={this.getFieldValue()}
                    onChange={this.valueChanged}
                    className={field.isInvalid ? 'invalid' : ''}
                    disabled={field.isDisabled}
                    key={field.uniqueKey + '_' + field.value}>

                    { this.buildOptions() }

                </select>
            </div>
        );
    }
}

export default DropdownField;
