"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CalendarActions;
(function (CalendarActions) {
    CalendarActions["Shown"] = "Shown";
    CalendarActions["Hidden"] = "Hidden";
    CalendarActions["FocusDateChanged"] = "FocusDateChanged";
    CalendarActions["DateSelected"] = "DateSelected";
})(CalendarActions || (CalendarActions = {}));
exports.default = CalendarActions;
