import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import { FieldType } from 'core.frontend';
import UserGroupDetailPageProps from './userGroupDetailPageProps';
import FormButtonContainer from '../containers/formButtonContainer';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import VideoModalButton from '../buttons/videoModalButton';

class UserGroupDetailPage extends ObservingComponent<UserGroupDetailPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: UserGroupDetailPageProps): JSX.Element {
        return (
            <form>
                <div class="page-heading">
                    <h3 class="flex">
                        <span class="fill">
                            {page.title}
                        </span>
                    </h3>
                </div>

                <Alert alert={page.alert} />
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />

                <div class="form-container">

                    <Button
                        className="btn-icon btn-lg"
                        button={page.helpButton} />


                    <Field
                        className="stacked-label"
                        field={page.form.fields[0]}
                        isLoading={!page.isLoaded} />
                </div>

                <div class="form-container">
                    <h4 class="section-header">System Permissions</h4>

                    <div class="grid-container">
                        {page.form.fields.map((field, index) => {
                            if (index === 0) return;

                            return <div class="md-3">
                                        <Field
                                            className={field.fieldType != FieldType.CheckBox ? 'stacked-label' : ''}
                                            field={field}
                                            isLoading={!page.isLoaded} />
                                    </div>
                            }
                        )}
                    </div>

                    <h4 class="section-header">Member Users</h4>

                    {page.isLoaded &&
                        <div class="grid-container text-sm mb-1 mt-p5">
                            {page.form.model.users.map((user) =>
                                <div class="md-3">
                                    <Button
                                        className="btn-link"
                                        button={page.buildUserLinkButton(user)} />
                                </div>
                            )}

                            {page.form.model.users.length === 0 &&
                                <div class="text-center">This user group has no member users</div>
                            }
                        </div>
                    }

                    {!page.isLoaded &&
                        <div class="grid-container">
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                            <div class="md-3">
                                <div class="loading-placeholder shimmer"></div>
                            </div>
                        </div>
                    }

                    <FormButtonContainer
                        leftButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-icon v-center"
                                button={page.deleteButton} />
                        ]}
                        rightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={page.cancelButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={page.saveButton} />
                        ]} />

                </div>
            </form>
        );
    }
}

export default UserGroupDetailPage;
