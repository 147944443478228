import { h, JSX } from 'preact'
import ObservingComponent from '../componentBases/observingComponent';
import CustomerSearchFieldProps from './customerSearchFieldProps'
import FieldLoadingPlaceholder from './fieldLoadingPlaceholder';
import SearchField from './searchField';
import Button from '../buttons/button';
import ButtonSizes from '../buttons/buttonSizes';
import QuickAddCustomerForm from '../customers/quickAddCustomerForm';

class CustomerSearchField extends ObservingComponent<CustomerSearchFieldProps> {

    public render({ customerSearchField, className, isLoading }: CustomerSearchFieldProps): JSX.Element {

        if (isLoading) {
            return <FieldLoadingPlaceholder className={className} />;
        }

        let searchFieldClassName: string = '';
        if (className.indexOf('no-label')) {
            searchFieldClassName += ' no-label';
        }

        return (
            <div class={className + ' v-center flex customer-search'}>
                <SearchField
                    className={searchFieldClassName + ' v-center fill'}
                    searchField={customerSearchField}
                    dropdownHeaderTemplate={
                        customerSearchField.showQuickAddCustomerButton.isVisible &&
                            <div class="search-dropdown-header-container">
                                <Button
                                    className="btn-link"
                                    size={ButtonSizes.Small}
                                    button={customerSearchField.showQuickAddCustomerButton} />
                            </div>
                    }
                    emptyStateTemplate={
                        <div class="text-center p-1">
                            <div>
                                No matching customers found
                            </div>
                        </div>
                    } />

                    <QuickAddCustomerForm
                        quickAddCustomerForm={customerSearchField.quickAddCustomerForm} />
            </div>
        );
    }
}

export default CustomerSearchField;
