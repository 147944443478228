"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ResetPasswordPageActions;
(function (ResetPasswordPageActions) {
    ResetPasswordPageActions["FormInvalid"] = "FormInvalid";
    ResetPasswordPageActions["FormSubmitted"] = "FormSubmitted";
    ResetPasswordPageActions["ErrorSubmitting"] = "ErrorSubmitting";
    ResetPasswordPageActions["SubmittedSuccessfully"] = "SubmittedSuccessfully";
})(ResetPasswordPageActions || (ResetPasswordPageActions = {}));
exports.default = ResetPasswordPageActions;
