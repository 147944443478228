import { h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import EventInfoTile from '../../events/eventInfoTile';
import EventTransportationConfigurationForm from '../../events/eventTransportationConfigurationForm/eventTransportationConfigurationForm';
import Button from '../../buttons/button';
import Alert from '../../alerts/alert';
import TransportationConfigurationTileProps from './transportationConfigurationTileProps';

class HotelConfigurationsTile extends ObservingComponent<TransportationConfigurationTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    public render({ tile }: TransportationConfigurationTileProps): JSX.Element {

        return (
            <EventInfoTile
                color="dark-orange"
                tile={tile}
                icon="ti-daily-transport"
                editModeButtons={[
                    <Button
                        stopPropagation={true}
                        button={tile.exitEditModeButton}
                        className="cancel-button v-center"  />,
                    <Button
                        stopPropagation={true}
                        button={tile.saveButton}
                        className="update-button v-center" />
                ]}>

                {tile.isLoading ?
                    'Loading...'
                    :
                    (
                        <div>
                            <Alert
                                className="mt-1"
                                alert={tile.alert} />

                            <EventTransportationConfigurationForm form={tile.transportationConfigurationForm} />
                        </div>
                    )
                }

            </EventInfoTile>
        );
    }
}

export default HotelConfigurationsTile;
