"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HelpUrlType;
(function (HelpUrlType) {
    HelpUrlType[HelpUrlType["TravelAndAccommodationsTile"] = 1] = "TravelAndAccommodationsTile";
    HelpUrlType[HelpUrlType["EventLandingPage"] = 2] = "EventLandingPage";
    HelpUrlType[HelpUrlType["EventInfoTile"] = 3] = "EventInfoTile";
    HelpUrlType[HelpUrlType["MeetingsTile"] = 4] = "MeetingsTile";
    HelpUrlType[HelpUrlType["EmailAndServicesTile"] = 5] = "EmailAndServicesTile";
    HelpUrlType[HelpUrlType["AttendeesTile"] = 6] = "AttendeesTile";
    HelpUrlType[HelpUrlType["BadgingTile"] = 7] = "BadgingTile";
    HelpUrlType[HelpUrlType["ReportsTile"] = 8] = "ReportsTile";
    HelpUrlType[HelpUrlType["InventoryTile"] = 9] = "InventoryTile";
    HelpUrlType[HelpUrlType["FilesTile"] = 10] = "FilesTile";
    HelpUrlType[HelpUrlType["CostManagementTile"] = 11] = "CostManagementTile";
    HelpUrlType[HelpUrlType["ExtrasTile"] = 12] = "ExtrasTile";
    HelpUrlType[HelpUrlType["EventListPage"] = 13] = "EventListPage";
    HelpUrlType[HelpUrlType["InventoryListPage"] = 14] = "InventoryListPage";
    HelpUrlType[HelpUrlType["InventorySchedulerPage"] = 15] = "InventorySchedulerPage";
    HelpUrlType[HelpUrlType["InventoryForecast"] = 16] = "InventoryForecast";
    HelpUrlType[HelpUrlType["UsersListPage"] = 17] = "UsersListPage";
    HelpUrlType[HelpUrlType["CustomerListPage"] = 18] = "CustomerListPage";
    HelpUrlType[HelpUrlType["VendorsListPage"] = 19] = "VendorsListPage";
    HelpUrlType[HelpUrlType["UserGroupsListPage"] = 20] = "UserGroupsListPage";
    HelpUrlType[HelpUrlType["SystemSettingsPage"] = 21] = "SystemSettingsPage";
    HelpUrlType[HelpUrlType["CustomerCompanyListPage"] = 22] = "CustomerCompanyListPage";
    HelpUrlType[HelpUrlType["VendorCompanyListPage"] = 23] = "VendorCompanyListPage";
    HelpUrlType[HelpUrlType["EmailTemplatesListPage"] = 24] = "EmailTemplatesListPage";
    HelpUrlType[HelpUrlType["EventConfigurationFileManagement"] = 25] = "EventConfigurationFileManagement";
    HelpUrlType[HelpUrlType["EventConfigurationAlertMessage"] = 26] = "EventConfigurationAlertMessage";
    HelpUrlType[HelpUrlType["EventConfigurationMeetings"] = 27] = "EventConfigurationMeetings";
    HelpUrlType[HelpUrlType["EventConfigurationHotel"] = 28] = "EventConfigurationHotel";
    HelpUrlType[HelpUrlType["EventConfigurationTrasnportation"] = 29] = "EventConfigurationTrasnportation";
    HelpUrlType[HelpUrlType["EventConfigurationInventory"] = 30] = "EventConfigurationInventory";
    HelpUrlType[HelpUrlType["EventConfigurationCostManagement"] = 31] = "EventConfigurationCostManagement";
    HelpUrlType[HelpUrlType["InventoryDetailPage"] = 32] = "InventoryDetailPage";
    HelpUrlType[HelpUrlType["UserDetailPage"] = 33] = "UserDetailPage";
    HelpUrlType[HelpUrlType["CustomerDetailPage"] = 34] = "CustomerDetailPage";
    HelpUrlType[HelpUrlType["VendorDetailPage"] = 35] = "VendorDetailPage";
    HelpUrlType[HelpUrlType["UserGroupDetailPage"] = 36] = "UserGroupDetailPage";
    HelpUrlType[HelpUrlType["CustomerCompanyDetailPage"] = 37] = "CustomerCompanyDetailPage";
    HelpUrlType[HelpUrlType["VendorCompanyDetailPage"] = 38] = "VendorCompanyDetailPage";
    HelpUrlType[HelpUrlType["EmailTemplateDetailPage"] = 39] = "EmailTemplateDetailPage";
    HelpUrlType[HelpUrlType["NewEventPage"] = 40] = "NewEventPage";
    HelpUrlType[HelpUrlType["MeetingDetail"] = 41] = "MeetingDetail";
    HelpUrlType[HelpUrlType["LoginPage"] = 42] = "LoginPage";
    HelpUrlType[HelpUrlType["UserProfile"] = 43] = "UserProfile";
    HelpUrlType[HelpUrlType["ApplicationHeader"] = 44] = "ApplicationHeader";
    HelpUrlType[HelpUrlType["TravelAndAccommodationTileAttendeeInfoPane"] = 45] = "TravelAndAccommodationTileAttendeeInfoPane";
    HelpUrlType[HelpUrlType["TravelAndAccommodationTileArrivalPane"] = 46] = "TravelAndAccommodationTileArrivalPane";
    HelpUrlType[HelpUrlType["TravelAndAccommodationTileDeparturePane"] = 47] = "TravelAndAccommodationTileDeparturePane";
    HelpUrlType[HelpUrlType["TravelAndAccommodationTileDailyTransportPane"] = 48] = "TravelAndAccommodationTileDailyTransportPane";
    HelpUrlType[HelpUrlType["TravelAndAccommodationTileHotelInfo"] = 49] = "TravelAndAccommodationTileHotelInfo";
})(HelpUrlType || (HelpUrlType = {}));
exports.default = HelpUrlType;
