"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TransportationConfigurationTileActions;
(function (TransportationConfigurationTileActions) {
    TransportationConfigurationTileActions["Toggled"] = "Toggled";
    TransportationConfigurationTileActions["DataLoading"] = "DataLoading";
    TransportationConfigurationTileActions["DataLoaded"] = "DataLoaded";
    TransportationConfigurationTileActions["EditModeActivated"] = "EditModeEnabled";
    TransportationConfigurationTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    TransportationConfigurationTileActions["VendorTypeChanged"] = "VendorTypeChanged";
})(TransportationConfigurationTileActions || (TransportationConfigurationTileActions = {}));
exports.default = TransportationConfigurationTileActions;
