import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import SearchFieldProps from './searchFieldProps';

class SearchField<T> extends ObservingComponent<SearchFieldProps<T>> {

    public componentWillMount(): void {
        const { searchField } = this.props;

        this.registerUpdateObserver(searchField.observationProvider);
        window.addEventListener('mousedown', this.blurred);
    }

    public componentWillUnmount() {
        window.removeEventListener('mousedown', this.blurred);
    }

    public onKeyDown = (e: KeyboardEvent) => {
        const { searchField } = this.props;

        if (e.keyCode === 9) {
            searchField.blur();
        }
    }

    public searchPhraseChanged = (e) => {
        const { searchField } = this.props;

        const newValue = e.target.value;
        searchField.search(newValue);
    }

    private selectionCheckboxChanged = (selectedItem: T) => (e: any) => {
        const { searchField } = this.props;

        e.preventDefault();

        if (this.props.searchField &&
            this.props.searchField.selectedResults &&
            this.props.searchField.selectedResults.some(
                (item) => item[this.props.searchField.selectedResultIdKey] == selectedItem[this.props.searchField.selectedResultIdKey]
            )
        ) {
            searchField.deselectItem(selectedItem);
        } else {
            searchField.selectItem(selectedItem);
        }
    }

    private clicked = (e: Event) => {
        const { searchField } = this.props;

        e.stopPropagation();

        searchField.focus();
    }

    public focused = (e: Event) => {
        const { searchField } = this.props;
        searchField.focus();
    }

    public blurred = (e: Event) => {
        const { searchField } = this.props;

        searchField.blur();
    }

    private searchResultScroll = (event: JSX.TargetedUIEvent<HTMLDivElement>) => {
        try {
            if (!this.props.searchField.enableDynamicLoading ||
                this.props.searchField.searching) {
                return;
            }

            var target: any = event.target;
            var scrollPercent = target.scrollTop / (target.scrollHeight - target.clientHeight) * 100;
            if (scrollPercent > 80) {
                this.props.searchField.loadNextPage();
            }
        } catch (err) {}
    };

    public render({ className, searchField, emptyStateTemplate, dropdownHeaderTemplate, inputClassName }: SearchFieldProps<T>): JSX.Element {

        return (
            searchField.isVisible &&
                <div class={
                        `search-field search-field ${className}
                        ${searchField.isFocused ? ' focused ' : ''}
                        ${searchField.dropdownOpen ? ' active ' : ''}
                        ${searchField.isInvalid ? ' invalid ' : ''}`
                    }
                    onMouseDown={this.clicked}>
                    <div class={"input-field search-input-field " + inputClassName}>
                        <label for={searchField.uniqueKey}>
                            <span class="fill">
                                {searchField.label}
                            </span>

                            { searchField.errorMessage && (
                                <span class="invalid">{searchField.errorMessage}</span>
                            )}

                            <span>
                                {searchField.isFocused}
                            </span>
                        </label>

                        <input
                            placeholder={searchField.placeholderLabel}
                            type="text"
                            id={searchField.uniqueKey}
                            name={searchField.uniqueKey}
                            maxLength={250}
                            value={searchField.searchBoxValue}
                            onKeyUp={this.searchPhraseChanged}
                            disabled={searchField.isDisabled}
                            onFocus={this.focused}
                            onKeyDown={this.onKeyDown}
                            autocomplete="off" />
                    </div>

                    {searchField.searching &&
                        <div class="searching-label loader"></div>
                    }

                    <div class="search-dropdown">
                        {!!dropdownHeaderTemplate &&
                            dropdownHeaderTemplate
                        }

                        <div
                            class="search-results"
                            onScroll={this.searchResultScroll}>
                            <ul>
                                {searchField.selectedResultsNotInSearchResults.map((selectedResult) => {
                                    return (
                                        <li
                                            onClick={this.selectionCheckboxChanged(selectedResult)}
                                            class={(searchField.disabledOptionIds.indexOf(selectedResult[searchField.key]) >= 0 ? 'disabled ' : '' ) + 'flex selected'}>

                                            <i class="ti ti-check"></i>

                                            <div class="fill">
                                                <span class={searchField.textKey ? 'text-bold' : ''}>
                                                    {selectedResult[searchField.searchResultLabelKey]}
                                                </span>

                                                {searchField.textKey && selectedResult[searchField.textKey] &&
                                                    <div class="d-block">{selectedResult[searchField.textKey]}</div>
                                                }
                                            </div>
                                        </li>
                                    );
                                })}

                                {searchField.searchResults.map((searchResult) => {
                                    return (
                                        <li
                                            onClick={this.selectionCheckboxChanged(searchResult)}
                                            class={'flex ' +
                                                    (searchField.disabledOptionIds.indexOf(searchResult[searchField.selectedResultIdKey]) >= 0 ? 'disabled ' : '' )  +
                                                    (searchField.selectedResults.findIndex((selectedItem) => searchResult[searchField.selectedResultIdKey] === selectedItem[searchField.selectedResultIdKey]) !== -1 ? 'selected' : '')}>

                                            <i class="ti ti-check"></i>

                                            <div class="fill">
                                                <span class={searchField.textKey ? 'text-bold' : ''}>
                                                    {searchResult[searchField.searchResultLabelKey]}
                                                </span>

                                                {searchField.textKey && searchResult[searchField.textKey] &&
                                                    <div class="d-block">{searchResult[searchField.textKey]}</div>
                                                }
                                            </div>
                                        </li>
                                    );
                                })}

                                {searchField.searching && searchField.enableDynamicLoading &&
                                    <li class="flex">
                                    <div class="fill text-bold text-center">Loading...</div>
                                    </li>
                                }


                                {searchField.showNoMoreSearchResults &&
                                    <li class="flex">
                                        <div class="fill text-italic text-center text-gray">No additional matches</div>
                                    </li>
                                }
                            </ul>
                        </div>

                        {searchField.showEmptyState && !emptyStateTemplate &&
                            <div class="empty-state">
                                    No matches found
                            </div>
                        }

                        {searchField.showEmptyState && !!emptyStateTemplate &&
                            emptyStateTemplate
                        }
                    </div>
                </div>
        );
    }
}

export default SearchField;
