"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("../../api");
var _1 = require(".");
var textField_1 = require("./textField");
var CustomerCompanySearchField = /** @class */ (function (_super) {
    __extends(CustomerCompanySearchField, _super);
    function CustomerCompanySearchField(api, label, isRequired, key, textKey, requiredValidationMessage, allowMultipleSelect) {
        if (isRequired === void 0) { isRequired = false; }
        if (allowMultipleSelect === void 0) { allowMultipleSelect = false; }
        var _this = _super.call(this, api, label, api_1.EndpointUrls.CustomerCompanies + '/SearchForDropdown', 'id', 'name', 'name', isRequired, requiredValidationMessage, allowMultipleSelect, key, textKey) || this;
        _this.toggleVisibility = function () {
            _this.newCompanyNameField.setVisibility(_this.newCompanyNameField.isVisible !== true);
            if (!_this.newCompanyNameField.isVisible) {
                _this.newCompanyNameField.setValue('');
            }
            _this.setVisibility(!_this.newCompanyNameField.isVisible);
        };
        _this.newCompanyNameField = new textField_1.default('companyName', _this.label, isRequired, 250, 'required');
        _this.newCompanyNameField.setVisibility(false);
        _this.clearSearchPhraseOnBlur = false;
        _this.openOnNoResultsFound = false;
        _this.observationProvider.observe(function (searchValue) {
            _this.newCompanyNameField.setValue(searchValue);
        }, _1.FieldActions.SearchValueChanged);
        _this.observationProvider.observe(function () {
            _this.newCompanyNameField.setValue('');
        }, _1.FieldActions.ValueChanged);
        return _this;
    }
    CustomerCompanySearchField.prototype.isValid = function () {
        return !this.isRequired || this.selectedResults.length > 0 || this.newCompanyNameField.isValid();
    };
    return CustomerCompanySearchField;
}(_1.BaseSearchField));
exports.default = CustomerCompanySearchField;
