import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import EventOverviewReportPageProps from './eventOverviewReportPageProps';
import EventOverviewLayout from './eventOverviewLayout';
import FormattedDate from '../text/formattedDate';
import { DateFormats } from 'core.frontend';

class EventOverviewReportPage extends ObservingComponent<EventOverviewReportPageProps> {

    public componentWillMount() {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider);
    }

    public render({ page }: EventOverviewReportPageProps): JSX.Element {
        return (
            <div class="report">
                <h3 class="text-center pl-1 pr-1 mt-1">
                    {page.title}
                </h3>

                <div class="flex mb-1">
                    <div class="fill">
                        Printed by: {page.currentUsersName}
                        &nbsp;
                    </div>
                    <div>
                        <FormattedDate date={new Date()} format={DateFormats.dddMMMMDhmmA} />
                    </div>
                </div>

                {page.isLoading &&
                    <h5 class="mt-2 text-center">{page.loadingLabel}</h5>
                }

                { page.reportData.map((event) =>
                    <div class="report-page">
                        <EventOverviewLayout
                            isLoaded={true}
                            eventOverviewData={event} />
                    </div>
                )}
            </div>
        );
    }
}

export default EventOverviewReportPage;
