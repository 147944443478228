"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var observationProvider_1 = require("../../utils/observationProvider");
var tableActions_1 = require("./tableActions");
var button_1 = require("../button/button");
var icons_1 = require("../icons");
var Table = /** @class */ (function () {
    /*
        Allow for custom data loading function to be passed in
        or an endpoint and api to be used to load data based on
        default standard for fetching table data from api
    */
    function Table(param1, api, _isVisible, _loadExandedContent, _searchEnabled, _pagingEnabled) {
        var _this = this;
        if (_isVisible === void 0) { _isVisible = true; }
        if (_loadExandedContent === void 0) { _loadExandedContent = null; }
        if (_searchEnabled === void 0) { _searchEnabled = true; }
        if (_pagingEnabled === void 0) { _pagingEnabled = true; }
        this._isVisible = _isVisible;
        this._loadExandedContent = _loadExandedContent;
        this._searchEnabled = _searchEnabled;
        this._pagingEnabled = _pagingEnabled;
        this.selectRow = function (rowIndex) {
            _this._selectedIndex = rowIndex;
            _this.observationProvider.notifyObservers(tableActions_1.default.RowSelected);
        };
        this.loadExpandedContent = function (item) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._loadExandedContent) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadExandedContent(item)];
                    case 1:
                        _a.sent();
                        this.observationProvider.notifyObservers(tableActions_1.default.ExpandedContentLoaded);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        this.lock = function () {
            _this._isLocked = true;
            _this.observationProvider.notifyObservers(tableActions_1.default.Locked);
        };
        this.unlock = function () {
            _this._isLocked = false;
            _this.observationProvider.notifyObservers(tableActions_1.default.Unlocked);
        };
        this.getData = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isLocked) {
                            return [2 /*return*/];
                        }
                        this._isLoading = true;
                        this._selectedIndex = null;
                        this.observationProvider.notifyObservers(tableActions_1.default.Loading);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.callApi()];
                    case 2:
                        response = _a.sent();
                        this._totalRecordCount = response.totalRecordCount;
                        this.data = response.items;
                        this.finishedGettingData();
                        this._isErrorState = false;
                        this.nextPageButton.setDisabled((!this.isNextPageAvailable) || this.isLocked);
                        this.previousPageButton.setDisabled((!this.isPreviousPageAvailable) || this.isLocked);
                        this.observationProvider.notifyObservers(tableActions_1.default.Loaded);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.finishedGettingData();
                        this._isErrorState = true;
                        this.observationProvider.notifyObservers(tableActions_1.default.ErrorLoading);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.finishedGettingData = function () {
            _this._isLoading = false;
            _this._savingIndices = [];
            _this._deletingIndices = [];
        };
        this.buildSortExpression = function () {
            if (!_this.sortBy || !_this.isSortDescending) {
                return _this.sortBy;
            }
            return _this.sortBy.replace(/,/g, ' desc,') + ' desc';
        };
        this.load = function (preservePagingLocation) {
            if (!preservePagingLocation) {
                _this.offset = 0;
            }
            return _this.getData();
        };
        this.sort = function (sortBy) {
            if (_this._isLocked) {
                return;
            }
            if (sortBy === _this.sortBy) {
                _this.isSortDescending = !_this.isSortDescending;
            }
            _this.sortBy = sortBy;
            _this.observationProvider.notifyObservers(tableActions_1.default.SortChanged);
            return _this.getData();
        };
        this.isSortedAscendingBy = function (testExpression) {
            return testExpression === _this.sortExpression;
        };
        this.isSortedDescendingBy = function (testExpression) {
            return testExpression === _this.sortExpression.replace(new RegExp(' desc', 'g'), '');
        };
        this.buildSortButton = function (label, sortExpression) {
            var icon;
            if (_this.isSortedAscendingBy(sortExpression)) {
                icon = icons_1.Icons.SortUp;
            }
            else if (_this.isSortedDescendingBy(sortExpression)) {
                icon = icons_1.Icons.SortDown;
            }
            return new button_1.default({
                text: label,
                rightIcon: icon,
                onClick: _this.changeSortExpression(sortExpression)
            });
        };
        this.changeSortExpression = function (sortExpression) { return function () {
            _this.sort(sortExpression);
        }; };
        this.search = function (searchString, enableDebounce) {
            if (enableDebounce === void 0) { enableDebounce = true; }
            if (_this._isLocked) {
                return;
            }
            _this.offset = 0;
            _this._searchPhrase = searchString;
            if (!enableDebounce) {
                _this.getData();
            }
            else {
                _this.debouncedGetData();
            }
            _this.observationProvider.notifyObservers(tableActions_1.default.SearchPhraseChanged);
        };
        this.getPageButtonOptions = function (maxButtonCount) {
            if (maxButtonCount === void 0) { maxButtonCount = Table.MAX_PAGER_NUMBER_OPTIONS; }
            if (!_this.totalRecordCount) {
                return [];
            }
            else if (_this.pageCount === 1) {
                return [1];
            }
            var options = [_this.currentPage];
            var reachedLastPage;
            var reachedFirstPage;
            var isAscending;
            for (var i = 1; i < maxButtonCount; i++) {
                if (i % 2 !== 0) {
                    isAscending = true;
                }
                else {
                    isAscending = false;
                }
                if (i !== 1 && options[0] === 1) {
                    reachedFirstPage = true;
                }
                if (options[options.length - 1] === _this.pageCount) {
                    reachedLastPage = true;
                }
                if (!reachedFirstPage && (!isAscending || reachedLastPage)) {
                    options.unshift(options[0] - 1);
                }
                else if (!reachedLastPage && (isAscending || reachedFirstPage)) {
                    options.push(options[options.length - 1] + 1);
                }
            }
            return options;
        };
        this.goToPage = function (pageNumber) {
            if (_this._isLocked) {
                return;
            }
            if (_this._totalRecordCount === undefined) {
                throw new Error('load must be called before paging data');
            }
            if (pageNumber < 1) {
                _this.offset = 0;
            }
            else if (pageNumber > _this.pageCount) {
                _this.offset = (_this.pageCount - 1) * _this._recordsPerPage;
            }
            else {
                _this.offset = (pageNumber - 1) * _this._recordsPerPage;
            }
            return _this.getData();
        };
        this.pageNumberButtonClicked = function (pageNumber) { return function () {
            _this.goToPage(pageNumber);
        }; };
        this.setRecordsPerPage = function (recordsPerPage) {
            if (_this._isLocked) {
                return;
            }
            _this._recordsPerPage = recordsPerPage;
            _this.offset = 0;
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordsPerPageChanged);
            return _this.getData();
        };
        this.goToNextPage = function () {
            if (_this._isLocked) {
                return;
            }
            return _this.goToPage(_this.currentPage + 1);
        };
        this.goToPreviousPage = function () {
            if (_this._isLocked) {
                return;
            }
            return _this.goToPage(_this.currentPage - 1);
        };
        this.insertAddingRecord = function (addingRecord, newIndex) {
            if (newIndex === void 0) { newIndex = 0; }
            _this._totalRecordCount++;
            _this.data.splice(newIndex, 0, addingRecord);
            _this._savingIndices.push(newIndex);
            _this.observationProvider.notifyObservers(tableActions_1.default.AddingRecord);
        };
        this.recordAddedSuccessfully = function () {
            _this._savingIndices = _this._savingIndices.filter(function (index) { return index !== 0; });
            _this.observationProvider.notifyObservers(tableActions_1.default.AddedRecord);
        };
        this.isIndexSaving = function (index) {
            return _this._savingIndices.filter(function (item) { return item === index; }).length > 0;
        };
        this.markRecordAsSaving = function (id) {
            var savingRecordIndex = _this.getIndexById(id);
            _this._savingIndices.push(savingRecordIndex);
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordSaving);
        };
        this.markRecordAsNotSaving = function (id) {
            var recordIndex = _this.getIndexById(id);
            _this._savingIndices = _this._savingIndices.filter(function (index) { return index !== recordIndex; });
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordSaved);
        };
        this.isIndexDeleting = function (index) {
            return _this._deletingIndices.filter(function (item) { return item === index; }).length > 0;
        };
        this.markRecordAsDeleting = function (id) {
            var recordIndex = _this.getIndexById(id);
            _this._deletingIndices.push(recordIndex);
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordDeleting);
        };
        this.markRecordAsNotDeleting = function (id) {
            var recordIndex = _this.getIndexById(id);
            _this._deletingIndices = _this._deletingIndices.filter(function (index) { return index !== recordIndex; });
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordNotDeleting);
        };
        this.getIndexById = function (id) {
            return _this.data.findIndex(function (item) { return item.id === id; });
        };
        this.getRecordById = function (id) {
            return _this.data.find(function (r) { return r.id === id; });
        };
        this.updateRecord = function (id, recordToMapFrom) {
            var tableRecord = _this.getRecordById(id);
            if (!tableRecord) {
                return null;
            }
            for (var key in recordToMapFrom) {
                if (recordToMapFrom.hasOwnProperty(key) && tableRecord.hasOwnProperty(key)) {
                    tableRecord[key] = recordToMapFrom[key];
                }
            }
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordUpdated);
            return tableRecord;
        };
        this.buildPageNumberButton = function (pageNumber) {
            return new button_1.default({
                text: pageNumber.toString(),
                onClick: _this.pageNumberButtonClicked(pageNumber),
                isDisabled: _this.isLocked
            });
        };
        if (typeof param1 === 'function') {
            this.callApi = param1;
        }
        else {
            var endpointUrl_1 = param1;
            this.callApi = function () { return api.getTablePage(endpointUrl_1, _this._recordsPerPage, _this.currentPage, _this.buildSortExpression(), _this._searchPhrase); };
        }
        this._recordsPerPage = 10;
        this.offset = 0;
        this.sortBy = '';
        this._searchPhrase = '';
        this.data = [];
        this.isSortDescending = false;
        this._isLoading = false;
        this._savingIndices = [];
        this._deletingIndices = [];
        this.debouncedGetData = lodash_1.debounce(this.getData.bind(this), 400);
        this.observationProvider = new observationProvider_1.default();
        this.searchBoxPlaceholder = 'Quick Search';
        this.clearSearchButton = new button_1.default({
            onClick: function () {
                _this.search('');
            },
            text: 'Clear'
        });
        this.nextPageButton = new button_1.default({
            onClick: this.goToNextPage,
            rightIcon: icons_1.Icons.ChevronRight,
            text: 'Next'
        });
        this.previousPageButton = new button_1.default({
            onClick: this.goToPreviousPage,
            leftIcon: icons_1.Icons.ChevronLeft,
            text: 'Previous'
        });
    }
    Object.defineProperty(Table.prototype, "selectedIndex", {
        get: function () {
            return this._selectedIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "pagingEnabled", {
        get: function () {
            return this._pagingEnabled;
        },
        set: function (value) {
            this._pagingEnabled = value;
            this.observationProvider.notifyObservers(tableActions_1.default.PagingEnabledChanged);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "searchEnabled", {
        get: function () {
            return this._searchEnabled;
        },
        set: function (value) {
            this._searchEnabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        set: function (value) {
            this._isVisible = value;
            this.observationProvider.notifyObservers(tableActions_1.default.SearchEnabledChanged);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isLocked", {
        get: function () {
            return this._isLocked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isErrorState", {
        get: function () {
            return this._isErrorState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isNoRecordFoundState", {
        get: function () {
            return !this._isLoading &&
                this.searchPhrase.length !== 0 &&
                this._totalRecordCount === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isEmptyState", {
        get: function () {
            return !this._isLoading &&
                !this.searchPhrase &&
                this._totalRecordCount === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "savingIndices", {
        get: function () {
            return this._savingIndices;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "totalRecordCount", {
        get: function () {
            return this._totalRecordCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "currentPage", {
        get: function () {
            return (this.offset / this._recordsPerPage) + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "sortExpression", {
        get: function () {
            return this.buildSortExpression();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "searchPhrase", {
        get: function () {
            return this._searchPhrase;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "pageCount", {
        get: function () {
            return Math.ceil(this._totalRecordCount / this._recordsPerPage);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "items", {
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isPreviousPageAvailable", {
        get: function () {
            return this.currentPage > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isNextPageAvailable", {
        get: function () {
            return this.currentPage < this.pageCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "recordsPerPage", {
        get: function () {
            return this._recordsPerPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "showPager", {
        get: function () {
            return this._pagingEnabled && !this.isEmptyState && !this.isNoRecordFoundState;
        },
        enumerable: true,
        configurable: true
    });
    Table.MAX_PAGER_NUMBER_OPTIONS = 7;
    return Table;
}());
exports.default = Table;
