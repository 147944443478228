"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var button_1 = require("../../../components/button/button");
var components_1 = require("../../../components");
var api_1 = require("../../../api");
var helpUrls_1 = require("../../helpUrls");
var helpButton_1 = require("../../../components/button/helpButton");
var EventListPage = /** @class */ (function (_super) {
    __extends(EventListPage, _super);
    function EventListPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.EventList, 'Event List') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.goToEventLanding = function (eventId) { return function () {
            _this.navigator.goToEventLanding(eventId);
        }; };
        _this.goToNewEvent = function () {
            if (_this.currentUserManager.hasPermission(__1.PermissionType.AddEvents)) {
                _this.navigator.goToNewEventPage();
            }
        };
        _this.loadData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.Events, _this._table.recordsPerPage, _this._table.currentPage, _this._table.sortExpression, _this._table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.showAccessRequestPrompt = function (event) { return function () {
            _this.accessRequestAlert.hide();
            _this._accessRequestForm = new __1.Form([
                new __1.CheckBoxField('isAttending', 'I will be attending this event'),
                new __1.CheckBoxField('inMeetings', 'I will be attending meetings'),
                new __1.CheckBoxField('requiresAccommodations', 'I will require accommodations'),
                new __1.EventAccessLevelDropdown(_this.api, event.id, 'eventAttendeeAccessLevelId', true, null, 'Requested Access Level')
            ]);
            _this._submitAccessRequestButton = new button_1.default({
                text: 'Submit Request',
                processingText: 'Submitting...',
                onClick: _this.accessRequestForm.submit
            });
            _this._accessRequestForm.setModel({ eventId: event.id });
            _this._accessRequestEvent = event;
            _this._accessRequestForm.model.eventId = event.id;
            _this._accessRequestPromptVisible = true;
            _this.observationProvider.notifyObservers(__1.EventListPageActions.AccessRequestPromptShown);
            _this._accessRequestForm.observationProvider.observe(_this.submitAccessRequest, __1.FormActions.FormSubmitted);
        }; };
        _this.submitAccessRequest = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.submitAccessRequestButton.setProcessing(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Events + '/RequestAccess', this._accessRequestForm.model)];
                    case 2:
                        _a.sent();
                        this._sentAccessRequests.push(this._accessRequestForm.model.eventId);
                        this.submitAccessRequestButton.setProcessing(false);
                        this._accessRequestPromptVisible = false;
                        this.observationProvider.notifyObservers(__1.EventListPageActions.AccessRequestSent);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.submitAccessRequestButton.setProcessing(false);
                        this.accessRequestAlert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Send Request',
                            message: 'An error occurred while trying to submit the access request'
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.cancelAccessRequest = function () {
            _this._accessRequestPromptVisible = false;
            _this._accessRequestEvent = null;
            _this.observationProvider.notifyObservers(__1.EventListPageActions.AccessRequestPromptHidden);
        };
        _this.runReport = function () {
            switch (_this.reportOptionSlideContainer.form.model.reportType) {
                case 1:
                    _this.navigator.goToEventOverviewReport(_this.reportOptionSlideContainer.form.model);
                    break;
            }
        };
        _this.buildEventOverviewButton = function (eventId) {
            return new button_1.default({
                onClick: _this.showEventOverview(eventId),
                text: 'Overview'
            });
        };
        _this.showEventOverview = function (eventId) { return function () {
            _this.eventOverview.loadData(eventId);
        }; };
        _this.buildEventLandingButton = function (event) {
            return new button_1.default({
                onClick: _this.goToEventLanding(event.id),
                text: event.name
            });
        };
        _this.buildAccessRequestButton = function (event) {
            return new button_1.default({
                onClick: _this.showAccessRequestPrompt(event),
                text: 'Request Access'
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventListPage, null, navigator, api);
        _this.observationProvider = new __1.ObservationProvider();
        _this._sentAccessRequests = [];
        _this._table = new __1.Table(_this.loadData, api);
        _this._table.searchBoxPlaceholder = 'By name or lead BA';
        _this._eventOverview = new __1.EventOverview(_this.api, _this.currentUserManager);
        var filters = [
            new __1.EventStatusDropdown('status', false),
            new __1.DateField('minimumStartDate', 'Minimum Start Date', false),
            new __1.DateField('maximumStartDate', 'Maximum Start Date', false),
            new __1.BooleanDropdownField('isInternational', 'Is international', false),
            new __1.BusinessAreaDropdown(_this.api, 'leadBusinessAreaId', 'Lead Business Area', false, null),
            new __1.BusinessAreaDropdown(_this.api, 'attendeeBusinessAreaId', 'Attendees in Business Area', false, null)
        ];
        var defaultFilters = { status: __1.EventStatus.Active };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, defaultFilters);
        var showInventoryReportOptionCheckbox = new __1.CheckBoxField('showInventory', 'Show Inventory');
        showInventoryReportOptionCheckbox.setVisibility(_this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.Inventory));
        var reportOptionFields = [
            new __1.NumberDropdownField('reportType', 'Report Type', true, [{ label: 'Event Overview', value: 1 }], 'Report type is required'),
            new __1.DateField('startDate', 'Report start date', true),
            new __1.DateField('endDate', 'Report end date', true),
            new __1.BooleanDropdownField('isInternational', 'Is international', false),
            new __1.BusinessAreaDropdown(_this.api, 'leadBusinessAreaId', 'Lead business area', false, null, null, 'All Business Areas'),
            showInventoryReportOptionCheckbox
        ];
        _this.listOptionSlideContainer.observationProvider.observe(function () { return _this._table.load(); }, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this.reportOptionSlideContainer = new __1.ReportOptionSlideContainer(_this.api, 'Events Report Options', reportOptionFields, {
            reportType: 1,
            isInternational: '',
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(new Date().getFullYear(), 11, 31),
            showInventory: true
        }, true, true);
        _this.newEventButton = new button_1.default({
            onClick: _this.goToNewEvent,
            text: 'New Event',
            leftIcon: __1.Icons.Plus,
            isVisible: _this.currentUserManager.hasPermission(__1.PermissionType.AddEvents),
        });
        _this.reportsButton = new button_1.default({
            onClick: _this.reportOptionSlideContainer.optionsSlider.open,
            text: 'Reports',
            leftIcon: __1.Icons.Download
        });
        _this.cancelRequestButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.cancelAccessRequest
        });
        _this.reportOptionSlideContainer.observationProvider.observe(_this.runReport, __1.ReportOptionSlideContainerActions.RunReport);
        _this._eventOverview = new __1.EventOverview(_this.api, _this.currentUserManager);
        _this.accessRequestAlert = new __1.Alert();
        _this._table.load();
        return _this;
    }
    Object.defineProperty(EventListPage.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListPage.prototype, "sentAccessRequests", {
        get: function () {
            return this._sentAccessRequests;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListPage.prototype, "accessRequestPromptVisible", {
        get: function () {
            return this._accessRequestPromptVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListPage.prototype, "accessRequestForm", {
        get: function () {
            return this._accessRequestForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListPage.prototype, "accessRequestEvent", {
        get: function () {
            return this._accessRequestEvent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListPage.prototype, "eventOverview", {
        get: function () {
            return this._eventOverview;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListPage.prototype, "submitAccessRequestButton", {
        get: function () {
            return this._submitAccessRequestButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventListPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    return EventListPage;
}(__1.BasePage));
exports.default = EventListPage;
