"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TableActions;
(function (TableActions) {
    TableActions["RecordsPerPageChanged"] = "RecordsPerPageChanged";
    TableActions["SearchPhraseChanged"] = "SearchPhraseChanged";
    TableActions["Loading"] = "Loading";
    TableActions["Loaded"] = "Loaded";
    TableActions["RecordSaved"] = "RecordSaved";
    TableActions["RecordSaving"] = "RecordSaving";
    TableActions["RecordDeleting"] = "RecordDeleting";
    TableActions["AddedRecord"] = "AddedRecord";
    TableActions["AddingRecord"] = "AddingRecord";
    TableActions["SortChanged"] = "SortChanged";
    TableActions["ErrorLoading"] = "ErrorLoading";
    TableActions["RecordNotDeleting"] = "RecordNotDeleting";
    TableActions["Locked"] = "Locked";
    TableActions["Unlocked"] = "Unlocked";
    TableActions["RecordUpdated"] = "RecordUpdated";
    TableActions["VisibilityChanged"] = "VisibilityChanged";
    TableActions["ExpandedContentLoaded"] = "ExpandedContentLoaded";
    TableActions["PagingEnabledChanged"] = "PagingEnabledChanged";
    TableActions["SearchEnabledChanged"] = "SearchEnabledChanged";
    TableActions["RowSelected"] = "RowSelected";
})(TableActions || (TableActions = {}));
exports.default = TableActions;
