"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var api_1 = require("../../../../../api");
var components_1 = require("../../../../../components");
var helpButton_1 = require("../../../../../components/button/helpButton");
var scheduledByField_1 = require("../../../../../components/fields/scheduledByField");
var meetings_1 = require("../../../../meetings");
var accommodations_1 = require("../accommodations");
var ReportSliderTypes;
(function (ReportSliderTypes) {
    ReportSliderTypes[ReportSliderTypes["Meetings"] = 1] = "Meetings";
    ReportSliderTypes[ReportSliderTypes["MeetingAttendees"] = 2] = "MeetingAttendees";
    ReportSliderTypes[ReportSliderTypes["Attendees"] = 3] = "Attendees";
    ReportSliderTypes[ReportSliderTypes["Hotels"] = 4] = "Hotels";
    ReportSliderTypes[ReportSliderTypes["Transportation"] = 5] = "Transportation";
    ReportSliderTypes[ReportSliderTypes["AttendeeDataGrid"] = 6] = "AttendeeDataGrid";
    ReportSliderTypes[ReportSliderTypes["VenueDataGrid"] = 7] = "VenueDataGrid";
    ReportSliderTypes[ReportSliderTypes["GolfCart"] = 7] = "GolfCart";
    ReportSliderTypes[ReportSliderTypes["Dining"] = 8] = "Dining";
})(ReportSliderTypes || (ReportSliderTypes = {}));
var ReportsTile = /** @class */ (function (_super) {
    __extends(ReportsTile, _super);
    function ReportsTile(eventId, api, navigator, currentUserManager, isVendorUser) {
        var _this = _super.call(this, 'Reports', api, currentUserManager.clientChecker.isMobileApp(), true, false) || this;
        _this.eventId = eventId;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.isVendorUser = isVendorUser;
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.isVendorUser) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 1:
                        _a._eventManager = _b.sent();
                        this.buildAttendeeReportOptionsSlideContainer();
                        this.buildMeetingReportOptionsSlideContaier();
                        this.buildAttendeeDataGridReportOptionsSliderContainer();
                        this.buildVenueDataGridReportOptionsSliderContainer();
                        return [4 /*yield*/, this.buildHotelReportOptionsSlideContainer()];
                    case 2:
                        _b.sent();
                        this.buildTransportationReportOptionsSlideContainer();
                        this.buildMeetingAtendeeReportOptionsSlideContainer();
                        this.buildGolfCartReportOptionsSlideContainer();
                        this.buildDiningReportOptionsSlideContainer();
                        if (this._eventManager.isMeetingsReportVisible) {
                            this._meetingAttendeeReportOptionsSlider.openButton.setVisibility(true);
                            this._meetingsReportOptionsSlider.openButton.setVisibility(true);
                            this._attendeeDataGridReportOptionsSlider.openButton.setVisibility(true);
                            this._venueDataGridReportOptionsSlider.openButton.setVisibility(true);
                            this._golfCartReportOptionsSlider.openButton.setVisibility(this._eventManager.isGolfCartsEnabled);
                            this._diningReportOptionsSlider.openButton.setVisibility(this._eventManager.isDiningEnabled);
                            this.setMeetingsReportDefaults();
                        }
                        if (this._eventManager.isHotelReportVisible) {
                            this._hotelReportOptionsSlider.openButton.setVisibility(true);
                        }
                        if (this._eventManager.isTransportationReportVisible) {
                            this._transportationReportOptionsSlider.openButton.setVisibility(true);
                        }
                        _b.label = 3;
                    case 3:
                        this.buildAttendeeReportOptionsSlideContainer();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildAttendeeReportOptionsSlideContainer = function () {
            _this._attendeeReportAttendeeField = new __1.EventAttendeeMultiselectField(_this.api, _this.eventId, 'eventAttendeeIds', 'Attendees', 'All Attendees', __1.EventAttendeeMultiselectFieldQueryType.IsAttendingOnly),
                _this._attendeeReportIncludePhotosField = new __1.CheckBoxField('includePhotos', 'Include Attendee Photo');
            _this._attendeeReportLineOfBusinessField = new components_1.LinesOfBusinessDropdown(_this.api, 'lineOfBusinessId', false, null, 'All');
            var reportOptions = [
                _this._attendeeReportAttendeeField,
                _this._attendeeReportIncludePhotosField,
                _this._attendeeReportLineOfBusinessField
            ];
            var reportOptionDefaults = {
                eventId: _this.eventId
            };
            _this._attendeeReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Attendee Report Options', reportOptions, reportOptionDefaults, false);
            _this._attendeeReportOptionsSlider.openButton.setText('Attendee Info');
            _this._attendeeReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.Attendees);
            }, __1.ReportOptionSlideContainerActions.Shown);
            _this._attendeeReportOptionsSlider.observationProvider.observe(_this.runAttendeeReport, __1.ReportOptionSlideContainerActions.RunReport);
            _this._attendeeReportOptionsSlider.observationProvider.observe(_this.emailAttendeeReport, __1.ReportOptionSlideContainerActions.EmailSent);
            _this._attendeeReportAttendeeField.observationProvider.observe(_this.buildAttendeeReportExcelUrl, __1.FieldActions.ValueChanged);
            _this._attendeeReportIncludePhotosField.observationProvider.observe(_this.buildAttendeeReportExcelUrl, __1.FieldActions.ValueChanged);
            _this._attendeeReportLineOfBusinessField.observationProvider.observe(_this.buildAttendeeReportExcelUrl, __1.FieldActions.ValueChanged);
        };
        _this.buildAttendeeReportExcelUrl = function () {
            if (!_this._attendeeReportOptionsSlider) {
                return;
            }
            var url = _this.api.getAttendeeExcelReportUrl(_this.eventId);
            if (_this._attendeeReportAttendeeField.selectedAttendees.length) {
                for (var i = 0; i < _this._attendeeReportAttendeeField.selectedAttendees.length; i++) {
                    url += '&eventAttendeeIds=' + _this._attendeeReportAttendeeField.selectedAttendees[i].eventAttendeeId.toString();
                }
            }
            url += '&includePhotos=' + _this._attendeeReportIncludePhotosField.value;
            url += '&lineOfBusinessId=' + _this._attendeeReportLineOfBusinessField.value;
            url += '&token=' + _this.currentUserManager.authenticationToken;
            _this._attendeeReportOptionsSlider.downloadButton.setUrl(url);
        };
        _this.buildAttendeeDataGridReportOptionsSliderContainer = function () {
            var reportOptionFields = [
                new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'startDate', 'Start Date', true, 'Required'),
                new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'endDate', 'End Date', true, 'Required'),
                new __1.EventAttendeeMultiselectField(_this.api, _this.eventId, 'eventAttendeeIds', 'Attendees', null, __1.EventAttendeeMultiselectFieldQueryType.InMeetingsOnly, true, 'Required'),
                new __1.TimeField('startTime', 'Start Time', true),
                new __1.TimeField('endTime', 'End Time', true),
                new __1.CheckBoxField('includeWorking', 'Include Working Meetings'),
                new __1.CheckBoxField('includeRequest', 'Include Requested Meetings'),
                new __1.CheckBoxField('includeMeetingTimes', 'Include meeting times for each meeting'),
                new __1.CheckBoxField('includeVenue', 'Include the assigned venue for each meeting'),
                new __1.CheckBoxField('includeMeetingNumber', 'Include Meeting Number')
            ];
            var currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
            var meetingStartDate = new Date(_this._eventManager.meetingConfigurations.meetingsStartDate);
            var meetingEndDate = new Date(_this._eventManager.meetingConfigurations.meetingsEndDate);
            var defaultStartDate;
            if (currentDate > meetingStartDate && currentDate < meetingEndDate) {
                defaultStartDate = currentDate;
            }
            else {
                defaultStartDate = meetingStartDate;
            }
            var reportOptionDefaults = {
                eventId: _this.eventId,
                startDate: defaultStartDate,
                endDate: _this._eventManager.meetingConfigurations.meetingsEndDate,
                startTime: '0800',
                endTime: '1800',
                includeWorking: true,
                includeMeetingTimes: true,
                includeVenue: true,
                includeRequest: true,
                includeMeetingNumber: true
            };
            _this._attendeeDataGridReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Datagrid Report Options', reportOptionFields, reportOptionDefaults);
            _this._attendeeDataGridReportOptionsSlider.openButton.setText('Meeting Attendee Datagrid');
            _this._attendeeDataGridReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.AttendeeDataGrid);
            }, __1.ReportOptionSlideContainerActions.Shown);
            _this._attendeeDataGridReportOptionsSlider.observationProvider.observe(_this.runAttendeeDataGridReport, __1.ReportOptionSlideContainerActions.RunReport);
            _this._attendeeDataGridReportOptionsSlider.observationProvider.observe(_this.emailAttendeeDataGridReport, __1.ReportOptionSlideContainerActions.EmailSent);
        };
        _this.buildVenueDataGridReportOptionsSliderContainer = function () {
            var reportOptionFields = [
                new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'startDate', 'Start Date', true, 'Required'),
                new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'endDate', 'End Date', true, 'Required'),
                new components_1.VenueMultiselectField(_this.api, _this.eventId, 'venueIds', 'Venues', null, true, 'Required'),
                new __1.TimeField('startTime', 'Start Time', true),
                new __1.TimeField('endTime', 'End Time', true),
                new __1.CheckBoxField('includeWorking', 'Include Working Meetings'),
                new __1.CheckBoxField('includeRequest', 'Include Requested Meetings'),
                new __1.CheckBoxField('showHeader', 'Show Report Header'),
                new __1.CheckBoxField('showMeetingName', 'Show Meeting Name'),
                new __1.CheckBoxField('showMeetingNumber', 'Show Meeting Number'),
                new __1.CheckBoxField('showMeetingTime', 'Show Meeting Time'),
                new __1.CheckBoxField('showLead', 'Show Meeting POC'),
                new __1.CheckBoxField('autoRefresh', 'Auto-Refresh')
            ];
            var currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
            var meetingStartDate = new Date(_this._eventManager.meetingConfigurations.meetingsStartDate);
            var meetingEndDate = new Date(_this._eventManager.meetingConfigurations.meetingsEndDate);
            var defaultStartDate;
            if (currentDate > meetingStartDate && currentDate < meetingEndDate) {
                defaultStartDate = currentDate;
            }
            else {
                defaultStartDate = meetingStartDate;
            }
            var reportOptionDefaults = {
                eventId: _this.eventId,
                startDate: defaultStartDate,
                endDate: _this._eventManager.meetingConfigurations.meetingsEndDate,
                startTime: '0800',
                endTime: '1800',
                includeWorking: true,
                showHeader: true,
                showMeetingName: true,
                showMeetingNumber: true,
                showMeetingTime: true,
                showLead: false,
                autoRefresh: false,
                includeRequest: true
            };
            _this._venueDataGridReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Datagrid Report Options', reportOptionFields, reportOptionDefaults);
            _this._venueDataGridReportOptionsSlider.openButton.setText('Meeting Venue Datagrid');
            _this._venueDataGridReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.VenueDataGrid);
            }, __1.ReportOptionSlideContainerActions.Shown);
            _this._venueDataGridReportOptionsSlider.observationProvider.observe(_this.runVenueDataGridReport, __1.ReportOptionSlideContainerActions.RunReport);
            _this._venueDataGridReportOptionsSlider.observationProvider.observe(_this.emailVenueDataGridReport, __1.ReportOptionSlideContainerActions.EmailSent);
        };
        _this.closeMeetingSlider = function () {
            if (_this._meetingsReportOptionsSlider) {
                _this._meetingsReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeHotelSlider = function () {
            if (_this._hotelReportOptionsSlider) {
                _this._hotelReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeTransportationSlider = function () {
            if (_this._transportationReportOptionsSlider) {
                _this._transportationReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeAttendeeSlider = function () {
            if (_this._attendeeReportOptionsSlider) {
                _this._attendeeReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeMeetingAttendeeOptionSlider = function () {
            if (_this._meetingAttendeeReportOptionsSlider) {
                _this._meetingAttendeeReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeAttendeeDataGridSlider = function () {
            if (_this._attendeeDataGridReportOptionsSlider) {
                _this._attendeeDataGridReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeVenueDataGridSlider = function () {
            if (_this._venueDataGridReportOptionsSlider) {
                _this._venueDataGridReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeGolfCartSlider = function () {
            if (_this._golfCartReportOptionsSlider) {
                _this._golfCartReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.closeDiningReportSlider = function () {
            if (_this._diningReportOptionsSlider) {
                _this._diningReportOptionsSlider.optionsSlider.close();
            }
        };
        _this.runAttendeeReport = function () {
            _this.navigator.goToEventAttendeeReport(_this._attendeeReportOptionsSlider.form.model);
        };
        _this.emailAttendeeReport = function () {
            var emailModel = _this._attendeeReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildAttendeeReportPageUrl(_this._attendeeReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.buildDiningReportOptionsSlideContainer = function () {
            var startDate = new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'startDate', 'Start Date', true, 'required');
            var startTime = new __1.TimeField('startTime', 'Start Time', true, 'required');
            var endDate = new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'endDate', 'End Date', true, 'required');
            var endTime = new __1.TimeField('endTime', 'End Time', true, 'required');
            var includeWorking = new __1.CheckBoxField('includeWorking', 'Include Working');
            var includeRequests = new __1.CheckBoxField('includeRequests', 'Include Requests');
            var reportOptionFields = [
                startDate,
                startTime,
                endDate,
                endTime,
                includeWorking,
                includeRequests
            ];
            var defaults = {
                eventId: _this.eventId,
                startTime: '0600',
                endTime: '2200'
            };
            _this._diningReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Dining Report Options', reportOptionFields, defaults, true, false, false);
            _this._diningReportOptionsSlider.openButton.setText('Dining Report');
            _this._diningReportOptionsSlider.openButton.setVisibility(false);
            _this._diningReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.Dining);
            }, __1.ReportOptionSlideContainerActions.Shown);
            _this._diningReportOptionsSlider.observationProvider.observe(_this.runDiningReport, __1.ReportOptionSlideContainerActions.RunReport);
            _this._diningReportOptionsSlider.observationProvider.observe(_this.emailDiningReport, __1.ReportOptionSlideContainerActions.EmailSent);
        };
        _this.buildGolfCartReportOptionsSlideContainer = function () {
            var startDate = new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'startDate', 'Start Date', true, 'required');
            var startTime = new __1.TimeField('startTime', 'Start Time', true, 'required');
            var endDate = new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'endDate', 'End Date', true, 'required');
            var endTime = new __1.TimeField('endTime', 'End Time', true, 'required');
            var reportOptionFields = [
                startDate,
                startTime,
                endDate,
                endTime
            ];
            var defaults = {
                eventId: _this.eventId,
                startTime: '0600',
                endTime: '2200'
            };
            _this._golfCartReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Golf Cart Report Options', reportOptionFields, defaults, true, false, false);
            _this._golfCartReportOptionsSlider.openButton.setText('Golf Cart Report');
            _this._golfCartReportOptionsSlider.openButton.setVisibility(false);
            _this._golfCartReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.GolfCart);
            }, __1.ReportOptionSlideContainerActions.Shown);
            _this._golfCartReportOptionsSlider.observationProvider.observe(_this.runGolfCartReport, __1.ReportOptionSlideContainerActions.RunReport);
            _this._golfCartReportOptionsSlider.observationProvider.observe(_this.emailGolfCartReport, __1.ReportOptionSlideContainerActions.EmailSent);
        };
        _this.buildMeetingReportOptionsSlideContaier = function () {
            _this._meetingsReportStartDateField = new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'startDate', 'Start Date', true, 'required');
            _this._meetingsReportEndDateField = new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'endDate', 'End Date', true, 'required');
            var attendeeMultselectField = new __1.EventAttendeeMultiselectField(_this.api, _this.eventId, 'eventAttendeeIds', 'Attendees', 'All Attendees', __1.EventAttendeeMultiselectFieldQueryType.InMeetingsOnly);
            attendeeMultselectField.setVisibility(false);
            var businessAreaField = new __1.BusinessAreaDropdown(_this.api, 'attendeeBusinessAreaId', 'Business Area', true, 'required');
            businessAreaField.setVisibility(false);
            var departmentField = new __1.DepartmentDropdown(_this.api, 'attendeeDepartmentId', true);
            departmentField.setVisibility(false);
            var meetingCategoryField = new __1.MeetingCategoryField(_this.api, 'defaultMeetingCategoryId', 'Meeting Category', true, 'required');
            meetingCategoryField.setVisibility(false);
            var scheduledByField = new scheduledByField_1.default(_this.api, 'scheduledById', 'Scheduled By', true, 'required');
            scheduledByField.setVisibility(false);
            var venueField = new __1.VenueField(_this.api, _this.eventId, 'venueId', 'Venue', true, 'required');
            venueField.setVisibility(false);
            _this._meetingsReportForField = new __1.NumberDropdownField('reportForType', 'Run Report For', true, _this._reportForTypeOptions, 'required');
            _this._meetingsReportForField.observationProvider.observe(function () {
                attendeeMultselectField.setVisibility(_this._meetingsReportForField.value === __1.ReportForType.SpecificAttendees);
                businessAreaField.setVisibility(_this._meetingsReportForField.value === __1.ReportForType.BusienssArea);
                departmentField.setVisibility(_this._meetingsReportForField.value === __1.ReportForType.Department);
                meetingCategoryField.setVisibility(_this._meetingsReportForField.value === __1.ReportForType.MeetingCategory);
                scheduledByField.setVisibility(_this._meetingsReportForField.value === __1.ReportForType.ScheduledBy);
                venueField.setVisibility(_this._meetingsReportForField.value === __1.ReportForType.Venue);
            }, __1.FieldActions.ValueChanged);
            var cancellationStatusOptions = [
                { label: 'Active', value: __1.MeetingsReportCancellationStatus.Active },
                { label: 'Canceled Before Event', value: __1.MeetingsReportCancellationStatus.CanceledBeforeEvent },
                { label: 'Canceled During Event', value: __1.MeetingsReportCancellationStatus.CanceledDuringEvent },
            ];
            var canceledStatusDropdown = new __1.NumberDropdownField('cancellationStatus', 'Status', false, cancellationStatusOptions, null, __1.MeetingsReportCancellationStatus.Active, __1.FieldType.DropDown, 'All');
            _this._meetingsReportIncludeGolfCartField = new __1.CheckBoxField('includeTransportation', 'Include Golf Cart Requirements');
            var guestsArrivedField = new __1.StringDropdownField('guestsArrived', 'Guests Arrived', false, [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }], null, null, __1.FieldType.DropDown, 'All');
            var startTimeField = new __1.TimeField('startTime', 'Start Time', true, 'required');
            var endTimeField = new __1.TimeField('endTime', 'End Time', true, 'required');
            var reportOptions = [
                _this._meetingsReportStartDateField,
                startTimeField,
                _this._meetingsReportEndDateField,
                endTimeField,
                _this._meetingsReportForField,
                attendeeMultselectField,
                businessAreaField,
                departmentField,
                meetingCategoryField,
                scheduledByField,
                venueField,
                guestsArrivedField,
                canceledStatusDropdown,
                _this._meetingsReportIncludeGolfCartField
            ];
            _this._meetingsReportOptionDefaults = {
                eventId: _this.eventId,
                startTime: '0600',
                endTime: '2200',
                reportForType: __1.ReportForType.All,
                guestsArrived: null,
                cancellationStatus: __1.MeetingsReportCancellationStatus.Active
            };
            _this._meetingsReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Meetings Report Options', reportOptions, _this._meetingsReportOptionDefaults, false);
            _this._meetingsReportOptionsSlider.openButton.setText('Meetings Report');
            _this._meetingsReportOptionsSlider.openButton.setVisibility(false);
            _this._meetingsReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.Meetings);
            }, __1.ReportOptionSlideContainerActions.Shown);
            _this._meetingsReportOptionsSlider.observationProvider.observe(_this.runMeetingsReport, __1.ReportOptionSlideContainerActions.RunReport);
            _this._meetingsReportOptionsSlider.observationProvider.observe(_this.emailMeetingsReport, __1.ReportOptionSlideContainerActions.EmailSent);
            _this._meetingsReportStartDateField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            startTimeField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            _this._meetingsReportEndDateField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            endTimeField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            _this._meetingsReportForField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            attendeeMultselectField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            businessAreaField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            departmentField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            meetingCategoryField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            venueField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            guestsArrivedField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            canceledStatusDropdown.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
            _this._meetingsReportIncludeGolfCartField.observationProvider.observe(_this.buildMeetingsReportExcelUrl, __1.FieldActions.ValueChanged);
        };
        _this.buildMeetingsReportExcelUrl = function () {
            if (!_this._meetingsReportOptionsSlider) {
                return;
            }
            var url = _this.api.getMeetingsExcelReportUrl(_this.eventId);
            _this._meetingsReportOptionsSlider.form.submit();
            if (!_this._meetingsReportOptionsSlider.form.isValid) {
                _this._meetingsReportOptionsSlider.downloadButton.setDisabled(true);
                return;
            }
            else {
                _this._meetingsReportOptionsSlider.downloadButton.setDisabled(false);
            }
            var reportOptions = _this._meetingsReportOptionsSlider.form.model;
            var propertyNames = Object.getOwnPropertyNames(reportOptions);
            for (var i = 0; i < propertyNames.length; i++) {
                if (Array.isArray(reportOptions[propertyNames[i]])) {
                    for (var j = 0; j < reportOptions[propertyNames[i]].length; j++) {
                        url += '&' + propertyNames[i] + '=' + reportOptions[propertyNames[i]][j];
                    }
                }
                else {
                    url += '&' + propertyNames[i] + '=' + reportOptions[propertyNames[i]];
                }
            }
            _this._meetingsReportOptionsSlider.downloadButton.setUrl(url);
        };
        _this.setMeetingsReportDefaults = function () {
            _this._meetingsReportOptionDefaults.startDate = _this._eventManager.meetingConfigurations.meetingsStartDate;
            _this._meetingsReportOptionDefaults.endDate = _this._eventManager.meetingConfigurations.meetingsEndDate;
            _this._meetingsReportIncludeGolfCartField.setVisibility(_this._eventManager.meetingConfigurations.golfCartsEnabled);
            _this._meetingsReportOptionsSlider.clearOptions();
        };
        _this.runMeetingsReport = function () {
            _this.navigator.goToMeetingsReportPage(_this._meetingsReportOptionsSlider.form.model);
        };
        _this.emailMeetingsReport = function () {
            var emailModel = _this._meetingsReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildMeetingsReportPageUrl(_this._meetingsReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.buildHotelReportOptionsSlideContainer = function () { return __awaiter(_this, void 0, void 0, function () {
            var startDateField, endDateField, attendeeMultselectField, businessAreaField, departmentField, hotelOptions, eventHotelField, hotelStatusField, reportForTypeOptions, reportForField, reportOptionFields, reportOptionDefaults;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        startDateField = new components_1.DateField('startDate', 'Start Date', false, 'required');
                        endDateField = new components_1.DateField('endDate', 'End Date', false, 'required');
                        attendeeMultselectField = new __1.EventAttendeeMultiselectField(this.api, this.eventId, 'eventAttendeeIds', 'Attendees', 'All Attendees', __1.EventAttendeeMultiselectFieldQueryType.All);
                        attendeeMultselectField.setVisibility(false);
                        businessAreaField = new __1.BusinessAreaDropdown(this.api, 'businessAreaId', 'Business Area', true, 'required');
                        businessAreaField.setVisibility(false);
                        departmentField = new __1.DepartmentDropdown(this.api, 'departmentId', true);
                        departmentField.setVisibility(false);
                        return [4 /*yield*/, this.api.getEventHotels(this.eventId)];
                    case 1:
                        hotelOptions = _a.sent();
                        eventHotelField = new __1.NumberDropdownField('eventHotelId', 'Hotel', false, hotelOptions, null, null, __1.FieldType.DropDown, 'All');
                        hotelStatusField = new __1.NumberDropdownField('lodgingStatus', 'Hotel Status', false, this._hotelStatusOptions, null, null, __1.FieldType.DropDown, 'All');
                        reportForTypeOptions = [
                            { label: 'All Event Attendees', value: __1.ReportForType.All },
                            { label: 'Specific Attendees', value: __1.ReportForType.SpecificAttendees },
                            { label: 'Business Area', value: __1.ReportForType.BusienssArea },
                            { label: 'Operating Company', value: __1.ReportForType.Department }
                        ];
                        reportForField = new __1.NumberDropdownField('reportForType', 'Run Report For', true, reportForTypeOptions, 'required');
                        reportForField.observationProvider.observe(function () {
                            attendeeMultselectField.setVisibility(reportForField.value === __1.ReportForType.SpecificAttendees);
                            businessAreaField.setVisibility(reportForField.value === __1.ReportForType.BusienssArea);
                            departmentField.setVisibility(reportForField.value === __1.ReportForType.Department);
                        }, __1.FieldActions.ValueChanged);
                        reportOptionFields = [
                            startDateField,
                            endDateField,
                            reportForField,
                            attendeeMultselectField,
                            businessAreaField,
                            departmentField,
                            eventHotelField,
                            hotelStatusField,
                            new __1.CheckBoxField('includePhotos', 'Include Attendee Photo')
                        ];
                        reportOptionDefaults = {
                            eventId: this.eventId,
                            reportForType: __1.ReportForType.All,
                        };
                        this._hotelReportOptionsSlider = new __1.ReportOptionSlideContainer(this.api, 'Hotel Report Options', reportOptionFields, reportOptionDefaults, false);
                        this._hotelReportOptionsSlider.openButton.setText('Hotel');
                        this._hotelReportOptionsSlider.openButton.setVisibility(false);
                        this._hotelReportOptionsSlider.observationProvider.observe(function () {
                            _this.closeSlidersOtherThan(ReportSliderTypes.Hotels);
                        }, __1.ReportOptionSlideContainerActions.Shown);
                        this._hotelReportOptionsSlider.observationProvider.observe(this.runHotelReport, __1.ReportOptionSlideContainerActions.RunReport);
                        this._hotelReportOptionsSlider.observationProvider.observe(this.emailHotelReport, __1.ReportOptionSlideContainerActions.EmailSent);
                        this._hotelReportOptionsSlider.downloadButton.setUrl(this.api.getHotelExcelReportUrl(this.eventId));
                        startDateField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        endDateField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        reportForField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        attendeeMultselectField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        businessAreaField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        departmentField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        eventHotelField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        hotelStatusField.observationProvider.observe(this.setHotelExcelReportUrl, __1.FieldActions.ValueChanged);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setHotelExcelReportUrl = function () {
            if (!_this._hotelReportOptionsSlider) {
                return;
            }
            var url;
            _this._hotelReportOptionsSlider.form.submit();
            if (!_this._hotelReportOptionsSlider.form.isValid()) {
                url = '';
            }
            else {
                url = _this.api.getHotelExcelReportUrl(_this.eventId);
                var options = _this._hotelReportOptionsSlider.form.model;
                if (options.businessAreaId) {
                    url += "&businessAreaId=" + options.businessAreaId;
                }
                if (options.lodgingStatus) {
                    url += "&lodgingStatus=" + options.lodgingStatus;
                }
                if (options.departmentId) {
                    url += "&departmentId=" + options.departmentId;
                }
                if (options.endDate) {
                    url += "&endDate=" + options.endDate;
                }
                if (options.startDate) {
                    url += "&startDate=" + options.startDate;
                }
                if (options.reportForType) {
                    url += "&reportForType=" + options.reportForType;
                }
                if (options.eventHotelId) {
                    url += "&eventHotelId=" + options.eventHotelId;
                }
                if (options &&
                    options.eventAttendeeIds &&
                    options.eventAttendeeIds.length) {
                    for (var i = 0; i < options.eventAttendeeIds.length; i++) {
                        url += '&eventAttendeeIds=' + options.eventAttendeeIds[i].toString();
                    }
                }
            }
            _this._hotelReportOptionsSlider.downloadButton.setUrl(url);
        };
        _this.runHotelReport = function () {
            _this.navigator.goToHotelReport(_this._hotelReportOptionsSlider.form.model);
        };
        _this.emailHotelReport = function () {
            var emailModel = _this._hotelReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildHotelReportPageUrl(_this._hotelReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.buildTransportationReportOptionsSlideContainer = function () {
            var startDateField = new components_1.DateField('startDate', 'Start Date', false, 'required');
            var endDateField = new components_1.DateField('endDate', 'End Date', false, 'required');
            var attendeeMultselectField = new __1.EventAttendeeMultiselectField(_this.api, _this.eventId, 'eventAttendeeIds', 'Attendees', 'All Attendees', __1.EventAttendeeMultiselectFieldQueryType.All);
            attendeeMultselectField.setVisibility(false);
            var businessAreaField = new __1.BusinessAreaDropdown(_this.api, 'businessAreaId', 'Business Area', true, 'required');
            businessAreaField.setVisibility(false);
            var departmentField = new __1.DepartmentDropdown(_this.api, 'departmentId', true);
            departmentField.setVisibility(false);
            var reportForTypeOptions = [
                { label: 'All Event Attendees', value: __1.ReportForType.All },
                { label: 'Specific Attendees', value: __1.ReportForType.SpecificAttendees },
                { label: 'Business Area', value: __1.ReportForType.BusienssArea },
                { label: 'Operating Company', value: __1.ReportForType.Department }
            ];
            var transportationStatusField = new __1.NumberDropdownField('dailyTransportStatus', 'Transportation Status', false, _this._transportationStatusOptions, null, null, __1.FieldType.DropDown, 'All');
            var reportForField = new __1.NumberDropdownField('reportForType', 'Run Report For', true, reportForTypeOptions, 'required');
            reportForField.observationProvider.observe(function () {
                attendeeMultselectField.setVisibility(reportForField.value === __1.ReportForType.SpecificAttendees);
                businessAreaField.setVisibility(reportForField.value === __1.ReportForType.BusienssArea);
                departmentField.setVisibility(reportForField.value === __1.ReportForType.Department);
            }, __1.FieldActions.ValueChanged);
            var transportTypeFieldOptions = [
                { label: 'All', value: __1.TrasportationReportTransportType.All },
                { label: 'Company Provided', value: __1.TrasportationReportTransportType.CompanyProvided },
                { label: 'Chauffer', value: __1.TrasportationReportTransportType.Individual },
                { label: 'Other', value: __1.TrasportationReportTransportType.Other },
                { label: 'Airport to Hotel', value: __1.TrasportationReportTransportType.AirportToHotel },
                { label: 'Hotel to Airport', value: __1.TrasportationReportTransportType.HotelToAirport }
            ];
            var transportTypeField = new __1.NumberDropdownField('reportType', 'Transportation Request Type', true, transportTypeFieldOptions, 'required');
            var reportOptionFields = [
                startDateField,
                endDateField,
                reportForField,
                attendeeMultselectField,
                businessAreaField,
                departmentField,
                transportationStatusField,
                transportTypeField,
                new __1.CheckBoxField('includePhotos', 'Include Attendee Photo')
            ];
            var reportOptionDefaults = {
                eventId: _this.eventId,
                reportForType: __1.ReportForType.All,
                reportType: __1.TrasportationReportTransportType.All
            };
            _this._transportationReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Transportation Report Options', reportOptionFields, reportOptionDefaults, false);
            _this._transportationReportOptionsSlider.openButton.setText('Transportation');
            _this._transportationReportOptionsSlider.openButton.setVisibility(false);
            _this._transportationReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.Transportation);
            }, __1.ReportOptionSlideContainerActions.Shown);
            _this._transportationReportOptionsSlider.observationProvider.observe(_this.runTransportationReport, __1.ReportOptionSlideContainerActions.RunReport);
            _this._transportationReportOptionsSlider.observationProvider.observe(_this.emailTransportationReport, __1.ReportOptionSlideContainerActions.EmailSent);
            _this._transportationReportOptionsSlider.downloadButton.setUrl(_this.api.getTransportationExcelReportUrl(_this.eventId));
            startDateField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
            endDateField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
            reportForField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
            attendeeMultselectField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
            businessAreaField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
            departmentField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
            transportationStatusField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
            transportTypeField.observationProvider.observe(_this.setTransportationExcelReportUrl, __1.FieldActions.ValueChanged);
        };
        _this.setTransportationExcelReportUrl = function () {
            if (!_this._transportationReportOptionsSlider) {
                return;
            }
            var url;
            if (!_this._transportationReportOptionsSlider.form.isValid()) {
                url = '';
            }
            else {
                url = _this.api.getTransportationExcelReportUrl(_this.eventId);
                var options = _this._transportationReportOptionsSlider.form.model;
                if (options.businessAreaId) {
                    url += "&businessAreaId=" + options.businessAreaId;
                }
                if (options.dailyTransportStatus) {
                    url += "&dailyTransportStatus=" + options.dailyTransportStatus;
                }
                if (options.departmentId) {
                    url += "&departmentId=" + options.departmentId;
                }
                if (options.endDate) {
                    url += "&endDate=" + options.endDate;
                }
                if (options.startDate) {
                    url += "&startDate=" + options.startDate;
                }
                if (options.reportForType) {
                    url += "&reportForType=" + options.reportForType;
                }
                if (options.reportType) {
                    url += "&reportType=" + options.reportForType;
                }
                if (options &&
                    options.eventAttendeeIds &&
                    options.eventAttendeeIds.length) {
                    for (var i = 0; i < options.eventAttendeeIds.length; i++) {
                        url += '&eventAttendeeIds=' + options.eventAttendeeIds[i].toString();
                    }
                }
            }
            _this._transportationReportOptionsSlider.downloadButton.setUrl(url);
        };
        _this.emailTransportationReport = function () {
            var emailModel = _this._transportationReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildTransportationReportPageUrl(_this._transportationReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.buildMeetingAttendeeCsvUrl = function () {
            if (!_this._meetingAttendeeCsvVenueField) {
                return;
            }
            var url = _this.api.getMeetingAttendeeCsvUrl(_this.eventId);
            if (_this._meetingAttendeeCsvVenueField.selectedVenues.length) {
                for (var i = 0; i < _this._meetingAttendeeCsvVenueField.selectedVenues.length; i++) {
                    url += '&venueIds=' + _this._meetingAttendeeCsvVenueField.selectedVenues[i].venueId.toString();
                }
            }
            url += '&meetingStatus=' + _this._meetingStatusDropdownField.value;
            _this._meetingAttendeeReportOptionsSlider.downloadButton.setUrl(url);
        };
        _this.buildMeetingAtendeeReportOptionsSlideContainer = function () {
            _this._meetingAttendeeCsvVenueField = new components_1.VenueMultiselectField(_this.api, _this.eventId, 'venueIds', 'Venues', 'All Venues');
            _this._meetingStatusDropdownField = new components_1.MeetingStatusField('status', 'Meeting Status', false, meetings_1.MeetingStatuses.Finalized, 'All');
            var reportOptionFields = [
                _this._meetingAttendeeCsvVenueField,
                _this._meetingStatusDropdownField
            ];
            var reportOptionDefaults = {
                eventId: _this.eventId
            };
            _this._meetingAttendeeReportOptionsSlider = new __1.ReportOptionSlideContainer(_this.api, 'Meeting Attendee CSV Download Options', reportOptionFields, reportOptionDefaults, false, false, true);
            _this._meetingAttendeeCsvVenueField.observationProvider.observe(_this.buildMeetingAttendeeCsvUrl, __1.FieldActions.ValueChanged);
            _this._meetingStatusDropdownField.observationProvider.observe(_this.buildMeetingAttendeeCsvUrl, __1.FieldActions.ValueChanged);
            _this.buildMeetingAttendeeCsvUrl();
            _this._meetingAttendeeReportOptionsSlider.openButton.setVisibility(false);
            _this._meetingAttendeeReportOptionsSlider.openButton.setText('Meeting Attendee CSV');
            _this._meetingAttendeeReportOptionsSlider.observationProvider.observe(function () {
                _this.closeSlidersOtherThan(ReportSliderTypes.MeetingAttendees);
            }, __1.ReportOptionSlideContainerActions.Shown);
        };
        _this.runTransportationReport = function () {
            _this.navigator.goToTransportationReport(_this._transportationReportOptionsSlider.form.model);
        };
        _this.runAttendeeDataGridReport = function () {
            _this.navigator.goToAttendeeDataGridReportPage(_this._attendeeDataGridReportOptionsSlider.form.model);
        };
        _this.emailAttendeeDataGridReport = function () {
            var emailModel = _this._attendeeDataGridReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildAttendeeDataGridReportPageUrl(_this._attendeeDataGridReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.emailGolfCartReport = function () {
            var emailModel = _this._golfCartReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildGolfCartReportPageUrl(_this._golfCartReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.emailDiningReport = function () {
            var emailModel = _this._diningReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildDiningReportPageUrl(_this._diningReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.runVenueDataGridReport = function () {
            _this.navigator.goToVenueDataGridReportPage(_this._venueDataGridReportOptionsSlider.form.model);
        };
        _this.runGolfCartReport = function () {
            _this.navigator.goToGolfCartReportPage(_this._golfCartReportOptionsSlider.form.model);
        };
        _this.runDiningReport = function () {
            _this.navigator.goToDiningReportPage(_this._diningReportOptionsSlider.form.model);
        };
        _this.emailVenueDataGridReport = function () {
            var emailModel = _this._venueDataGridReportOptionsSlider.emailForm.model;
            emailModel.url = _this.navigator.urlBuilder.buildVenueDataGridReportPageUrl(_this._venueDataGridReportOptionsSlider.form.model);
            _this.api.post(api_1.EndpointUrls.Events + '/EmailReport', emailModel);
        };
        _this.update = function () { };
        _this._helpButton = new helpButton_1.default(__1.HelpUrlType.ReportsTile, null, navigator, api);
        _this.refreshButton.setVisibility(false);
        _this._transportationStatusOptions = [
            { label: 'Not Yet Submitted', value: __1.DailyTransportStatus.NotSubmitted },
            { label: 'Pending', value: __1.DailyTransportStatus.Pending },
            { label: 'Confirmed', value: __1.DailyTransportStatus.Confirmed },
            { label: 'Confirmed with Changes', value: __1.DailyTransportStatus.ConfirmedWithChanges },
            { label: 'Cancelled', value: __1.DailyTransportStatus.Cancelled },
        ];
        _this._hotelStatusOptions = [
            { label: 'Not Yet Submitted', value: accommodations_1.LodgingStatus.NotSubmitted },
            { label: 'Pending', value: accommodations_1.LodgingStatus.Pending },
            { label: 'Confirmed', value: accommodations_1.LodgingStatus.Confirmed },
            { label: 'Confirmed with Changes', value: accommodations_1.LodgingStatus.ConfirmedWithChanges },
            { label: 'Cancelled', value: accommodations_1.LodgingStatus.Cancelled },
        ];
        _this._reportForTypeOptions = [
            { label: 'All Event Attendees', value: __1.ReportForType.All },
            { label: 'Specific Attendees', value: __1.ReportForType.SpecificAttendees },
            { label: 'Business Area', value: __1.ReportForType.BusienssArea },
            { label: 'Operating Company', value: __1.ReportForType.Department },
            { label: 'Meeting Category', value: __1.ReportForType.MeetingCategory },
            { label: 'Scheduled By', value: __1.ReportForType.ScheduledBy },
            { label: 'Meeting Venue', value: __1.ReportForType.Venue },
        ];
        _this.processLoadData();
        return _this;
    }
    Object.defineProperty(ReportsTile.prototype, "attendeeReportOptionsSlider", {
        get: function () {
            return this._attendeeReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "meetingsReportOptionsSlider", {
        get: function () {
            return this._meetingsReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "meetingAttendeeReportOptionsSlider", {
        get: function () {
            return this._meetingAttendeeReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "hotelReportOptionsSlider", {
        get: function () {
            return this._hotelReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "transportationReportOptionsSlider", {
        get: function () {
            return this._transportationReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "attendeeDataGridReportOptionsSlider", {
        get: function () {
            return this._attendeeDataGridReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "venueDataGridReportOptionsSlider", {
        get: function () {
            return this._venueDataGridReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "golfCartReportOptionsSlider", {
        get: function () {
            return this._golfCartReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "diningReportOptionsSlider", {
        get: function () {
            return this._diningReportOptionsSlider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "meetingsEnabled", {
        get: function () {
            return this._eventManager && this._eventManager.isMeetingsReportVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "showHotelReports", {
        get: function () {
            return this._isHotelVendor || (this._eventManager && this._eventManager.isHotelReportVisible);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "showTransportationReports", {
        get: function () {
            return this._isTransportationVendor || (this._eventManager && this._eventManager.isTransportationReportVisible);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "isTransportationVendor", {
        get: function () {
            return this._isTransportationVendor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsTile.prototype, "isHotelVendor", {
        get: function () {
            return this._isHotelVendor;
        },
        enumerable: true,
        configurable: true
    });
    ReportsTile.prototype.setIsTransportationVendor = function (isTransportationVendor) {
        this._isTransportationVendor = isTransportationVendor;
        if (this._isTransportationVendor) {
            this.buildTransportationReportOptionsSlideContainer();
            this._transportationReportOptionsSlider.openButton.setVisibility(true);
        }
        else if (this._transportationReportOptionsSlider) {
            this._transportationReportOptionsSlider.openButton.setVisibility(false);
        }
        this.observationProvider.notifyObservers(__1.ReportOptionSlideContainerActions.VendorTypeChanged);
    };
    ReportsTile.prototype.setIsHotelVendor = function (isHotelVendor) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isHotelVendor = isHotelVendor;
                        if (!this._isHotelVendor) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.buildHotelReportOptionsSlideContainer()];
                    case 1:
                        _a.sent();
                        this._hotelReportOptionsSlider.openButton.setVisibility(true);
                        return [3 /*break*/, 3];
                    case 2:
                        if (this._hotelReportOptionsSlider) {
                            this._hotelReportOptionsSlider.openButton.setVisibility(false);
                        }
                        _a.label = 3;
                    case 3:
                        this.observationProvider.notifyObservers(__1.ReportOptionSlideContainerActions.VendorTypeChanged);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportsTile.prototype.closeSlidersOtherThan = function (openSlider) {
        if (openSlider != ReportSliderTypes.Meetings) {
            this.closeMeetingSlider();
        }
        if (openSlider != ReportSliderTypes.MeetingAttendees) {
            this.closeMeetingAttendeeOptionSlider();
        }
        if (openSlider != ReportSliderTypes.Attendees) {
            this.closeAttendeeSlider();
        }
        if (openSlider != ReportSliderTypes.Hotels) {
            this.closeHotelSlider();
        }
        if (openSlider != ReportSliderTypes.Transportation) {
            this.closeTransportationSlider();
        }
        if (openSlider != ReportSliderTypes.AttendeeDataGrid) {
            this.closeAttendeeDataGridSlider();
        }
        if (openSlider != ReportSliderTypes.VenueDataGrid) {
            this.closeVenueDataGridSlider();
        }
        if (openSlider != ReportSliderTypes.GolfCart) {
            this.closeGolfCartSlider();
        }
        if (openSlider != ReportSliderTypes.Dining) {
            this.closeDiningReportSlider();
        }
    };
    return ReportsTile;
}(__1.EventLandingTile));
exports.default = ReportsTile;
