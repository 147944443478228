import { h, JSX } from 'preact';
import EventLandingPageProps from './eventLandingPageProps';
import EventInfoDetailTile from './eventInfoDetailTile';
import EventAttendeesTile from './eventAttendeesTile';
import TravelAndAccommodationsTile from './travelAndAccommodationsTile';
import EventInventoryTile from './eventInventoryTile';
import MeetingsTile from './meetingsTile';
import DiningRoomTile from './diningRoomTile';
import EventFilesTile from './eventFilesTile';
import ReportsTile from './reportsTile';
import ExtrasTile from './extrasTile';
import CostManagementTile from './costManagementTile';
import ObservingComponent from '../componentBases/observingComponent';
import BadgingTile from './badgingTile';
import Button from '../buttons/button';
import Alert from '../alerts/alert';
import ButtonColors from '../buttons/buttonColors';
import Dropdown from '../dropdown/dropdown';
import EmailAndServicesTile from '../vendorPortal/tiles/emailAndServicesTile';

class EventLandingPage extends ObservingComponent<EventLandingPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: EventLandingPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <Dropdown
                        textClassName="overflow-hidden max-width-20 md-max-width-100"
                        dropdown={page.dropdown} />

                    <div class="btn-container">

                        <div class="ml-auto flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton}
                                textClassName="d-none d-md-inline"/>

                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-lg ml-p5 v-center btn-icon"
                                button={page.historyButton}
                                textClassName="d-none d-md-inline"/>

                            <Button
                                color={ButtonColors.Secondary}
                                button={page.configurationButtton}
                                className="btn-lg ml-p5 v-center btn-icon"
                                textClassName="d-none d-md-inline"/>
                        </div>
                    </div>
                </div>

                <Alert alert={page.alert} />

                {!!page.eventAlertEnabled &&
                    <div
                        class="mb-1 p-p5"
                        style="background-color: #fffccb;">

                        {page.eventAlertTitle}

                        {!page.eventAlertExpanded &&
                            <div>
                                <Button
                                    className="btn-link"
                                    button={page.alertExpandButton} />
                            </div>
                        }

                        {!!page.eventAlertMessage && page.eventAlertExpanded &&
                            <div class="mt-p5">

                                <div dangerouslySetInnerHTML={{ __html: page.eventAlertMessage}}></div>

                                <div class="mt-p5">
                                    <Button
                                        className="btn-link"
                                        button={page.alertCollapseButton} />
                                </div>
                            </div>
                        }
                    </div>
                }

                <EventInfoDetailTile tile={page.eventInfoTile}></EventInfoDetailTile>
                <MeetingsTile tile={page.meetingsTile} ></MeetingsTile>
                <TravelAndAccommodationsTile tile={page.travelAndAccommodationsTile}></TravelAndAccommodationsTile>
                <EmailAndServicesTile tile={page.emailAndServicesTile}></EmailAndServicesTile>
                <EventAttendeesTile tile={page.eventAttendeesTile}></EventAttendeesTile>
                <ReportsTile tile={page.reportsTile}></ReportsTile>
                <DiningRoomTile tile={page.diningRoomTile}></DiningRoomTile>
                <EventInventoryTile tile={page.eventInventoryTile}></EventInventoryTile>
                <BadgingTile tile={page.badgingTile}></BadgingTile>
                <EventFilesTile tile={page.eventFilesTile}></EventFilesTile>
                <CostManagementTile tile={page.costManagementTile}></CostManagementTile>
                <ExtrasTile tile={page.extrasTile}></ExtrasTile>
            </div>
        );
    }
}

export default EventLandingPage;
