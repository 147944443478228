import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import AvatarProps from './avatarProps';

class Avatar extends ObservingComponent<AvatarProps> {

    public render({ url, className }): JSX.Element {
            if (url) {
                return <img
                    src={url}
                    className={'user-profile-image ' + className} />;
            }

            return <i class={'ti ti-user-alt ' + className}></i>;
    }
}

export default Avatar;
