import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import HistoryPageProps from './historyPageProps';
import HistoryTable from './historyTable';
import Card from '../containers/card';
import ButtonColors from '../buttons/buttonColors';
import Button from '../buttons/button';

class HistoryPage extends ObservingComponent<HistoryPageProps> {

    public render({ page }: HistoryPageProps): JSX.Element {
        return (
            <Card heading={
                <h3 class="flex">
                    <Button
                        className="btn-icon"
                        color={ButtonColors.Secondary}
                        button={page.backButton} />

                    <span class="v-center ml-p5">
                        {page.title}
                    </span>
                </h3>
            }>

                <HistoryTable historyTable={page.table} />
            </Card>
        );
    }
}

export default HistoryPage;
