import { h, JSX } from 'preact';
import { CustomerTableItem } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Card from '../containers/card';
import CustomerListPageProps from './customerListPageProps';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import VideoModalButton from '../buttons/videoModalButton';

class CustomerListPage extends ObservingComponent<CustomerListPageProps> {

    private buildColumns = (): SmartTableColumn<CustomerTableItem>[] => {
        const { page } = this.props;

        return [
            {
                className: 'd-none d-md-table-cell',
                cellTemplate: (item, index) => (<span>{item.id}</span>)
            },
            {
                className: 'd-none d-md-table-cell',
                heading: 'Rank/Position',
                sortExpression: 'rank',
                cellTemplate: (item, index) => (
                    <span>
                        {item.rank}
                    </span>
                )
            },
            {
                className: 'd-none d-md-table-cell',
                heading: 'Title',
                sortExpression: 'title',
                cellTemplate: (item, index) => (
                    <span>
                        {item.title}
                    </span>
                )
            },
            {
                heading: 'Name',
                sortExpression: 'lastName,firstName',
                cellTemplate: (item, index) =>
                    (<span>


                        <span class="d-md-none text-sm">
                            {item.rank}

                            {!!item.rank && !!item.title &&
                                <span> | </span>
                            }

                            {item.title}
                            <br/>
                        </span>

                        {page.table.isIndexDeleting(index) ? (
                            <span>{item.firstName} {item.lastName}</span>
                        ) : (
                            <Button
                                className="btn-link"
                                button={page.buildCustomerEditButton(item)} />
                        )}

                        {!item.isActive && (
                            <span class="text-sm text-red"> - Inactive</span>
                        )}

                        <span class="d-md-none text-sm">
                            <br/>
                            {item.company}
                        </span>

                        <span class="d-md-none text-sm">
                            <br/>
                            Email: {!!item.email ? item.email : <i>Unknown</i>} | Phone {!!item.phone ? item.phone : <i>Unknown</i>}
                        </span>
                    </span>),
                appendSavingIndicator: true
            },
            {
                className: 'd-none d-md-table-cell',
                heading: 'Company',
                sortExpression: 'company',
                cellTemplate: (item, index) => (
                    <span>
                        {item.company}
                    </span>
                )
            },
            {
                className: 'd-none d-md-table-cell',
                heading: 'Phone',
                sortExpression: 'phone',
                cellTemplate: (item) => (
                    <span>
                        {!!item.phone ? item.phone : <i>Unknown</i>}
                    </span>
                )
            },
            {
                className: 'd-none d-md-table-cell',
                heading: 'Email',
                sortExpression: 'email',
                cellTemplate: (item) => (
                    <span>
                        {!!item.email ? item.email : <i>Unknown</i>}
                    </span>
                )
            }
        ];
    }

    public render({ page }: CustomerListPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <Card
                    heading="All Customers"
                    headingRightContent={
                        <div class="button-container">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                color={ButtonColors.Primary}
                                button={page.newCustomerButton} />
                        </div>
                    }>

                    <SmartTable
                        table={page.table}
                        columns={this.buildColumns()}
                        listOptionSlideContainer={page.listOptionSlideContainer} />
                </Card>
            </div>
        );
    }
}

export default CustomerListPage;
