"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VendorFulfillmentFormActions;
(function (VendorFulfillmentFormActions) {
    VendorFulfillmentFormActions["VisibilityChanged"] = "VisibilityChanged";
    VendorFulfillmentFormActions["DataLoaded"] = "DataLoaded";
    VendorFulfillmentFormActions["Saving"] = "Saving";
    VendorFulfillmentFormActions["Saved"] = "Saved";
    VendorFulfillmentFormActions["HistoryTableShown"] = "HistoryTableShown";
    VendorFulfillmentFormActions["HistoryTableHidden"] = "HistoryTableHidden";
    VendorFulfillmentFormActions["ActivePaneChanged"] = "ActivePaneChanged";
})(VendorFulfillmentFormActions || (VendorFulfillmentFormActions = {}));
exports.default = VendorFulfillmentFormActions;
