import { h, Component } from 'preact';
import FormattedMoneyProps from './formattedMoneyProps';

class FormattedMoney extends Component<FormattedMoneyProps> {

    private formatAmount(amount: number) {

        if (Number.isNaN(amount) ||
            amount === undefined ||
            amount === null) {
            return '';
        }

        let minusSymbol = '';
        if (amount < 0) {
            minusSymbol = '-'
        }

        const localeOptions = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };

        let amountString = Math.abs(amount).toLocaleString('en', localeOptions);

        return minusSymbol + '$' + amountString;
    }

    public render({amount, className }: FormattedMoneyProps) {
        return (
            <span class={className}>
                {this.formatAmount(amount)}
            </span>
        )
    }
}

export default FormattedMoney;
