"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ObservationProviderContainer = /** @class */ (function () {
    function ObservationProviderContainer(observationProvider) {
        this.observationProvider = observationProvider;
        this.observerIds = [];
    }
    ObservationProviderContainer.prototype.stopObservingAll = function () {
        for (var _i = 0, _a = this.observerIds; _i < _a.length; _i++) {
            var observerId = _a[_i];
            this.observationProvider.stopObserving(observerId);
        }
        this.observerIds = [];
    };
    return ObservationProviderContainer;
}());
exports.default = ObservationProviderContainer;
