"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BadgingTileActions;
(function (BadgingTileActions) {
    BadgingTileActions["Toggled"] = "Toggled";
    BadgingTileActions["DataLoading"] = "DataLoading";
    BadgingTileActions["DataLoaded"] = "DataLoaded";
    BadgingTileActions["EditModeActivated"] = "EditModeEnabled";
    BadgingTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    BadgingTileActions["BadgesPrinted"] = "BadgesPrinted";
    BadgingTileActions["SearchingNewCustomerMeetingsStarted"] = "SearchingNewCustomerMeetingsStarted";
    BadgingTileActions["SearchingNewCustomerMeetingsComplete"] = "SearchingNewCustomerMeetingsComplete";
    BadgingTileActions["NewCustomersAdded"] = "NewCustomersAdded";
    BadgingTileActions["AddNewCustomerToMeetingClosed"] = "AddNewCustomerToMeetingClosed";
    BadgingTileActions["NewCustomersMeetingSelected"] = "NewCustomersMeetingSelected";
    BadgingTileActions["GuestBadgePrinted"] = "GuestBadgePrinted";
})(BadgingTileActions || (BadgingTileActions = {}));
exports.default = BadgingTileActions;
