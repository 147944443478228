import { FieldActions, ActionUtils, MultipleEmailField as FrontEndMultipleField, FieldType } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface MultipleEmailFieldProps {
    id?: string;
    field: FrontEndMultipleField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class MultipleEmailField extends ObservingComponent<MultipleEmailFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        var actions = ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]) as FieldActions[];
        this.registerUpdateObserver(field.observationProvider, actions);
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setStringValue(newValue);
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: MultipleEmailFieldProps): JSX.Element {
        if (!id)
        {
            id = field.uniqueKey;
        }

        return (
            <div className={`input-field ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={!!field.errorMessage} /> }

                <label
                    className={labelClassName}
                    for={id}>

                    <span class="fill">{field.label}</span>

                    {field.labelButtonText && <a onClick={field.handleLabelButtonClick}>{field.labelButtonText}</a>}

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>

                { field.fieldType !== FieldType.TextArea &&
                    <input
                        type="text"
                        id={id}
                        name={field.uniqueKey}
                        onFocus={field.focus}
                        onBlur={field.blur}
                        value={field.stringValue}
                        onChange={this.valueChanged}
                        className={field.errorMessage ? 'invalid' : ''}
                        disabled={field.isDisabled}
                        maxLength={field.maxCharacters}
                        placeholder="Separate multiple emails with a comma" />
                }

                { field.isActive && !field.stringValue && field.placeholderLabel && <label class="placeholder-label">{ field.placeholderLabel }</label> }

            </div>
        );
    }
}

export default MultipleEmailField;
