import { h, Fragment, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import NavProps from './navProps';
import Button from '../buttons/button';
import HelpListModal from '../help/helpListModal';

class Nav extends ObservingComponent<NavProps> {

    public componentWillMount() {
        const { nav } = this.props;

        this.registerUpdateObserver(nav.observationProvider);
    }

    public render({ nav }: NavProps): JSX.Element {
        if (nav.isHidden) {
            return null;
        }

        return (
            <Fragment>
                <nav className={nav.isOpen ? 'active': ''}>
                    <ul>
                        <li class="navigation-header">
                        </li>

                        <li>
                            <Button
                                className="btn-link"
                                button={nav.eventListButton} />
                        </li>

                        { nav.showDirectoriesHeader &&
                            <li class="navigation-header">Directories</li>
                        }


                        <li>
                            <Button
                                className="btn-link"
                                button={nav.userListButton} />
                        </li>


                        <li>
                            <Button
                                className="btn-link"
                                button={nav.customerListButton} />
                        </li>

                        { nav.showInventoryNavHeader &&
                            <li class="navigation-header pl-0">
                                <Button
                                    className="btn-link"
                                    button={nav.inventoryHeaderButton} />
                            </li>
                        }

                        { nav.inventoryExpanded &&
                            <Fragment>
                                <li>
                                    <Button
                                        className="btn-link"
                                        button={nav.inventoryListButton} />
                                </li>

                                <li>
                                    <Button
                                        className="btn-link"
                                        button={nav.inventorySchedulerButton} />
                                </li>

                                <li>
                                    <Button
                                        className="btn-link"
                                        button={nav.inventoryForecastButon} />
                                </li>
                            </Fragment>
                        }

                        { nav.showSettingsHeader &&
                            <li class="navigation-header pl-0">
                                <Button
                                    className="btn-link"
                                    button={nav.settingsHeaderButton} />
                            </li>
                        }


                        { nav.settingsExpanded &&
                            <Fragment>
                                <li>
                                    <Button
                                        className="btn-link"
                                        button={nav.userGroupListButton} />
                                </li>

                                <li>
                                    <Button
                                        className="btn-link"
                                        button={nav.systemSettingsButton} />
                                </li>

                                <li>
                                    <Button
                                        className="btn-link"
                                        button={nav.vendorUserListButton} />
                                </li>

                                <li>
                                    <Button
                                        className="btn-link"
                                        button={nav.emailTemplatesButton} />
                                </li>
                            </Fragment>
                        }

                        {nav.vendorEventsListButton.isVisible &&
                            <li class="navigation-header">Navigation</li>
                        }

                        <li>
                            <Button
                                className="btn-link"
                                button={nav.vendorEventsListButton} />
                        </li>
                        <li>
                            <Button
                                className="btn-link"
                                button={nav.vendorEmailTemplatesButton} />
                        </li>
                        <li>
                            <Button
                                className="btn-link"
                                button={nav.vendorCompanyInfoButton} />
                        </li>
                    </ul>

                    <div class="nav-footer">
                        <ul>
                            <li>
                                <Button
                                    className="btn-link"
                                    button={nav.helpButton} />
                            </li>

                            {nav.showTemsConfigurationsHeader &&
                                <li class="navigation-header">Configuration</li>
                            }

                            <li>
                                <Button
                                    className="btn-link"
                                    button={nav.tenantsButton} />
                            </li>

                            <li>
                                <Button
                                    className="btn-link"
                                    button={nav.helpUrlsButton} />
                            </li>
                        </ul>

                        <div class="version">
                            TEMS v3.0.3
                        </div>
                    </div>
                </nav>
            </Fragment>
        );
    }
}

export default Nav;
