import { h, JSX } from 'preact';
import { CroppieOptions } from 'croppie';
import { FieldActions, ActionUtils, FileInputField as FrontEndFileInputField, FileInputFileTypes } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import Croppie from 'croppie';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';

export interface UserProfileImageFieldProps {
    field: FrontEndFileInputField;
    className?: string;
}

export interface UserProfileImageFieldState {
    newImageUrl?: string;
}

class UserProfileImageField extends ObservingComponent<UserProfileImageFieldProps, UserProfileImageFieldState> {
    private croppie: Croppie;

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));

        field.observationProvider.observe(this.fieldValueChanged, FieldActions.ValueChanged);
    }

    public componentWillUnmount(): void {
        this.destoryCroppie();
    }

    public componentDidUpdate(): void  {
        if (this.croppie || !this.state.newImageUrl) {
            return;
        }

        const croppieElement = document.getElementById(this.props.field.uniqueKey);

        const croppieOptions = {
            viewport: { width: 210, height: 210, type: 'square' }
        };

        this.croppie = new Croppie(croppieElement, croppieOptions as CroppieOptions);

        this.croppie.bind({
            url: this.state.newImageUrl,
            zoom: 0
        });

        (this.croppie as any).options.update = this.croppieUpdated;
    }

    private destoryCroppie = () => {
        if (this.croppie) {
            this.croppie.destroy();
            this.croppie = null;
        }
    }

    private fieldValueChanged = () => {
        if (!this.props.field.value) {
            this.setState({ newImageUrl: null });
        }
    }

    private croppieUpdated = (): void => {
        this.croppie.result({
                type: 'base64',
                size: { width: 208, height: 208 }
            })
            .then((dataUrl) => {
                this.props.field.setValue(dataUrl);
            });
    }

    public uploadNewImageClicked = () => {
        const uploader = document.getElementById(this.props.field.uniqueKey + '_userProfileImageField');
        uploader.click();
    }

    public fileUploaderChanged = (e: Event) => {
        let target = e.target as HTMLInputElement;
        let files = target.files;

        if (!files || !files[0]) {
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e: any) => {
            this.destoryCroppie();
            this.setState({ newImageUrl: e.target.result });
        }
    }

    public render({ field, className = '' }: UserProfileImageFieldProps, { newImageUrl }: UserProfileImageFieldState): JSX.Element {

        const element = (
            <div class={'user-profile-image-field ' + className}>

                {field.value && !newImageUrl &&
                    <div class="image-outer-wrapper">
                        <Button
                            className="btn-remove-data-item"
                            button={field.removeButton} />

                        <div class="image-wrapper">
                            <img
                                class="profile-image"
                                src={field.value} />
                        </div>
                    </div>
                }

                {!field.value && !newImageUrl &&
                    <i class="profile-image ti ti-user-alt"></i>
                }

                <div class={'croppie-wrapper ' + (!newImageUrl ? 'd-none' : '')}>
                    <div id={field.uniqueKey}></div>
                </div>

                <div class="text-center">
                    <div>
                        <a
                            class={`btn button inline-flex v-center mr-1 ${ButtonSizes.Small} ${ButtonColors.Primary}`}
                            onClick={this.uploadNewImageClicked}>

                            <span class="btn-content">
                                <span className="fill v-center ">
                                    Upload new profile image
                                </span>
                            </span>
                        </a>

                        <input
                            id={field.uniqueKey + '_userProfileImageField'}
                            type="file"
                            accept={FileInputFileTypes.Images}
                            class="d-none"
                            onChange={this.fileUploaderChanged} />
                    </div>
                </div>
            </div>
        );

        return element;
    }
}

export default UserProfileImageField;
