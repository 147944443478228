"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var fieldActions_1 = require("./fieldActions");
var DateTimeField = /** @class */ (function (_super) {
    __extends(DateTimeField, _super);
    function DateTimeField(key, label, isRequired, requiredValidationMessage, initialValue) {
        var _this = _super.call(this, _1.FieldType.DateTime, key, label, isRequired, 10, requiredValidationMessage) || this;
        _this.key = key;
        _this.focusDate = function () {
            _this._dateField.focus();
        };
        _this.blurDate = function () {
            _this._dateField.blur();
        };
        _this.focusTime = function () {
            _this._timeField.focus();
        };
        _this.blurTime = function () {
            _this._timeField.blur();
        };
        _this.focusApPm = function () {
            _this._amPmField.focus();
        };
        _this.blurAmPm = function () {
            _this._amPmField.blur();
        };
        _this.focusTimezone = function () {
            _this._timezoneField.focus();
        };
        _this.blurTimezone = function () {
            _this._timezoneField.blur();
        };
        _this._dateField = new _1.DateField(_this.dateKey, '', isRequired, requiredValidationMessage);
        _this._timeField = new _1.TimeField(_this.timeKey, '', isRequired, 'Time is required');
        _this._amPmField = new _1.AmPmField(_this.amPmKey, isRequired);
        _this._timezoneField = new _1.TimezoneField(_this.timezoneKey, isRequired);
        _this.wireUpObservers();
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    DateTimeField.prototype.wireUpObservers = function () {
        var _this = this;
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Blured); }, fieldActions_1.default.Blured);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Disabled); }, fieldActions_1.default.Disabled);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Enabled); }, fieldActions_1.default.Enabled);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Focus); }, fieldActions_1.default.Focus);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Hidden); }, fieldActions_1.default.Hidden);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Invalid); }, fieldActions_1.default.Invalid);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.RequiredStatusChanged); }, fieldActions_1.default.RequiredStatusChanged);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Shown); }, fieldActions_1.default.Shown);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Valid); }, fieldActions_1.default.Valid);
        this._dateField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged); }, fieldActions_1.default.ValueChanged);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Blured); }, fieldActions_1.default.Blured);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Disabled); }, fieldActions_1.default.Disabled);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Enabled); }, fieldActions_1.default.Enabled);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Focus); }, fieldActions_1.default.Focus);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Hidden); }, fieldActions_1.default.Hidden);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Invalid); }, fieldActions_1.default.Invalid);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.RequiredStatusChanged); }, fieldActions_1.default.RequiredStatusChanged);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Shown); }, fieldActions_1.default.Shown);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Valid); }, fieldActions_1.default.Valid);
        this._timeField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged); }, fieldActions_1.default.ValueChanged);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Blured); }, fieldActions_1.default.Blured);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Disabled); }, fieldActions_1.default.Disabled);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Enabled); }, fieldActions_1.default.Enabled);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Focus); }, fieldActions_1.default.Focus);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Hidden); }, fieldActions_1.default.Hidden);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Invalid); }, fieldActions_1.default.Invalid);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.RequiredStatusChanged); }, fieldActions_1.default.RequiredStatusChanged);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Shown); }, fieldActions_1.default.Shown);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Valid); }, fieldActions_1.default.Valid);
        this._amPmField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged); }, fieldActions_1.default.ValueChanged);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Blured); }, fieldActions_1.default.Blured);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Disabled); }, fieldActions_1.default.Disabled);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Enabled); }, fieldActions_1.default.Enabled);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Focus); }, fieldActions_1.default.Focus);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Hidden); }, fieldActions_1.default.Hidden);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Invalid); }, fieldActions_1.default.Invalid);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.RequiredStatusChanged); }, fieldActions_1.default.RequiredStatusChanged);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Shown); }, fieldActions_1.default.Shown);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.Valid); }, fieldActions_1.default.Valid);
        this._timezoneField.observationProvider.observe(function () { _this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged); }, fieldActions_1.default.ValueChanged);
    };
    Object.defineProperty(DateTimeField.prototype, "dateFieldPlaceHolderLabel", {
        get: function () {
            return this._dateField.placeholderLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timeFieldPlaceHolderLabel", {
        get: function () {
            return this._timeField.placeholderLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "amPmFieldPlaceHolderLabel", {
        get: function () {
            return this._amPmField.placeholderLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timezoneFieldPlaceHolderLabel", {
        get: function () {
            return this._timezoneField.placeholderLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "isActive", {
        get: function () {
            return this._dateField.isActive || this._timeField.isActive || this._amPmField.isActive || this._timezoneField.isActive;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "dateKey", {
        get: function () {
            return this.key + '_date';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timeKey", {
        get: function () {
            return this.key + '_time';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "amPmKey", {
        get: function () {
            return this.key + '_amPm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timezoneKey", {
        get: function () {
            return this.key + '_timezone';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "dateStringValue", {
        get: function () {
            return this._dateField.stringValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timeStringValue", {
        get: function () {
            return this._timeField.stringValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "amPmStringValue", {
        get: function () {
            return this._amPmField.stringValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timezoneStringValue", {
        get: function () {
            return this._timezoneField.stringValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "amPmOptions", {
        get: function () {
            return this._amPmField.options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timezoneOptions", {
        get: function () {
            return this._timezoneField.options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "errorMessage", {
        get: function () {
            if (this._dateField.errorMessage) {
                return this._dateField.errorMessage;
            }
            if (this._timeField.errorMessage) {
                return this._timeField.errorMessage;
            }
            if (this._amPmField.errorMessage) {
                return this._amPmField.errorMessage;
            }
            if (this._timezoneField.errorMessage) {
                return this._timezoneField.errorMessage;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "dateErrorMessage", {
        get: function () {
            return this._dateField.errorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timeErrorMessage", {
        get: function () {
            return this._timeField.errorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "amPmErrorMessage", {
        get: function () {
            return this._amPmField.errorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeField.prototype, "timezoneErrorMessage", {
        get: function () {
            return this._timezoneField.errorMessage;
        },
        enumerable: true,
        configurable: true
    });
    DateTimeField.prototype.markAsDirty = function () {
        _super.prototype.markAsDirty.call(this);
        this._dateField.markAsDirty();
        this._timeField.markAsDirty();
        this._amPmField.markAsDirty();
        this._timezoneField.markAsDirty();
    };
    DateTimeField.prototype.isValid = function () {
        var isValid = true;
        isValid = isValid && this._dateField.isValid();
        isValid = isValid && this._timeField.isValid();
        isValid = isValid && this._amPmField.isValid();
        isValid = isValid && this._timezoneField.isValid();
        return isValid;
    };
    DateTimeField.prototype.setDateValue = function (newValue) {
        this._dateField.setValue(newValue);
    };
    DateTimeField.prototype.setTimeValue = function (newValue) {
        this._timeField.setValue(newValue);
    };
    DateTimeField.prototype.setAmPmValue = function (newValue) {
        this._amPmField.setValue(newValue);
    };
    DateTimeField.prototype.setTimezoneValue = function (newValue) {
        this._timezoneField.setValue(newValue);
    };
    DateTimeField.prototype.setValue = function (newValue) {
        // parse from ISO 8601 format + timezone offset
        // 2018-11-30T04:00:00-06:00
        // 2018-11-28T06:00:00+00:00
        var dateString = newValue.toString();
        this._dateField.setValue(dateString.substring(5, 7) + "/" + dateString.substring(8, 10) + "/" + dateString.substring(0, 4));
        var hourStr = dateString.substring(11, 13);
        var hour = parseInt(hourStr, 10);
        var adjustedHourStr = hourStr;
        if (hour > 12) {
            // am
            adjustedHourStr = (hour - 12).toString();
            this._amPmField.setValue(this.amPmOptions[1].value);
        }
        else {
            // pm
            this._amPmField.setValue(this.amPmOptions[0].value);
        }
        this._timeField.setValue(adjustedHourStr + ":" + dateString.substring(14, 16));
        this._timezoneField.setValue("" + dateString.substring(19, 25));
    };
    Object.defineProperty(DateTimeField.prototype, "value", {
        get: function () {
            var dateParts = this._dateField.stringValue.split('/');
            var timeParts = this._timeField.stringValue.split(':');
            var month = dateParts[0];
            var day = dateParts[1];
            var year = dateParts[2];
            var hourStr = timeParts[0];
            var minutes = timeParts[1];
            var offset = this._timezoneField.value;
            var hour = parseInt(hourStr, 10);
            if (hour !== 12 && this._amPmField.stringValue === this._amPmField.options[1].value) {
                hour += 12;
            }
            var adjustedHour = hour.toString().length === 1 ? "0" + hour.toString() : hour.toString();
            this.setStringValue(year + "-" + month + "-" + day + "T" + adjustedHour + ":" + minutes + ":00" + offset);
            return this.stringValue;
        },
        enumerable: true,
        configurable: true
    });
    return DateTimeField;
}(baseField_1.default));
exports.default = DateTimeField;
