"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var stringDropdownField_1 = require("./stringDropdownField");
var GenderField = /** @class */ (function (_super) {
    __extends(GenderField, _super);
    function GenderField(key, isRequired, initialValue) {
        var _this = _super.call(this, key, 'Gender', isRequired, [
            new _1.FieldOption('Male', 'Male'),
            new _1.FieldOption('Female', 'Female')
        ], 'Gender is required', initialValue, _1.FieldType.DropDown) || this;
        _this.key = key;
        return _this;
    }
    return GenderField;
}(stringDropdownField_1.default));
exports.default = GenderField;
