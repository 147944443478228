"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var button_1 = require("../components/button/button");
var components_1 = require("../components");
var navActions_1 = require("./navActions");
var currentUserManagerActions_1 = require("./currentUserManagerActions");
var api_1 = require("../api");
var Nav = /** @class */ (function () {
    function Nav(currentUserManager, navigator, api) {
        var _this = this;
        this.currentUserManager = currentUserManager;
        this.navigator = navigator;
        this.api = api;
        this.toggleMenu = function () {
            _this._isOpen = !_this._isOpen;
            _this.notifyOpenChanged();
        };
        this.closeMenu = function () {
            if (_this._isOpen) {
                _this._isOpen = false;
                _this.notifyOpenChanged();
            }
        };
        this.openMenu = function () {
            if (!_this._isOpen) {
                _this._isOpen = true;
                _this.notifyOpenChanged();
            }
        };
        this.notifyOpenChanged = function () {
            if (_this._isOpen) {
                _this.observationProvider.notifyObservers(navActions_1.default.MenuOpened);
            }
            else {
                _this.observationProvider.notifyObservers(navActions_1.default.MenuClosed);
            }
        };
        this.updateNavVisibility = function () {
            _this.eventListButton.setVisibility(_this.showEventListNav);
            _this.inventoryListButton.setVisibility(_this.showInventoryListNav);
            _this.inventorySchedulerButton.setVisibility(_this.showInventorySchedulerNav);
            _this.inventoryForecastButon.setVisibility(_this.showInventoryForecastNav);
            _this.userListButton.setVisibility(_this.showUsersListNav);
            _this.vendorUserListButton.setVisibility(_this.showVendorUsersNav);
            _this.customerListButton.setVisibility(_this.showCustomersListNav);
            _this.userGroupListButton.setVisibility(_this.showUserGroupsListNav);
            _this.systemSettingsButton.setVisibility(_this.showSystemSettingsNav);
            _this.customerCompanyListButton.setVisibility(_this.showCustomerCompaniesListNav);
            _this.vendorCompanyListButton.setVisibility(_this.showVendorCompaniesNav);
            _this.openButton.setVisibility(!_this.isHidden);
            _this.tenantsButton.setVisibility(_this.showTenantsListNav);
            _this.emailTemplatesButton.setVisibility(_this.showEmailTemplateNav);
            _this.vendorEmailTemplatesButton.setVisibility(_this.showVendorEmailTemplatesNav);
            _this.vendorEventsListButton.setVisibility(_this.showVendorEventListNav);
            _this.vendorCompanyInfoButton.setVisibility(_this.showVendorCompanyInfoNav);
            _this.helpUrlsButton.setVisibility(_this.showHelpUrlsNav);
            _this.observationProvider.notifyObservers(navActions_1.default.NavVisibilityUpdated);
        };
        this.eventListButton = new button_1.default({
            leftIcon: components_1.Icons.List,
            text: 'All Events',
            onClick: this.navigator.goToEventList
        });
        this.inventoryListButton = new button_1.default({
            leftIcon: components_1.Icons.List,
            text: 'Exhibit Items',
            onClick: this.navigator.goToInventoryListPage
        });
        this.inventorySchedulerButton = new button_1.default({
            leftIcon: components_1.Icons.Calendar,
            text: 'Inventory Scheduler',
            onClick: this.navigator.goToInventoryScheduler
        });
        this.inventoryForecastButon = new button_1.default({
            leftIcon: components_1.Icons.Calendar,
            text: 'Inventory Forecast',
            onClick: this.navigator.goToInventoryForecast
        });
        this.userListButton = new button_1.default({
            leftIcon: components_1.Icons.User,
            text: 'Company User',
            onClick: this.navigator.goToUserList
        });
        this.vendorUserListButton = new button_1.default({
            leftIcon: components_1.Icons.BusinessCard,
            text: 'Vendors',
            onClick: this.navigator.goToVendorUserList
        });
        this.customerListButton = new button_1.default({
            leftIcon: components_1.Icons.UserAlt,
            text: 'Customer',
            onClick: this.navigator.goToCustomerList
        });
        this.userGroupListButton = new button_1.default({
            leftIcon: components_1.Icons.UserGroup,
            text: 'User Groups',
            onClick: this.navigator.goToUserGroupList
        });
        this.systemSettingsButton = new button_1.default({
            leftIcon: components_1.Icons.Gear,
            text: 'System Settings',
            onClick: this.navigator.goToSystemSettingsPage
        });
        this.customerCompanyListButton = new button_1.default({
            leftIcon: components_1.Icons.List,
            text: 'Customer Companies',
            onClick: this.navigator.goToCustomerCustomerCompanyList,
        });
        this.vendorCompanyListButton = new button_1.default({
            leftIcon: components_1.Icons.List,
            text: 'Vendor Companies',
            onClick: this.navigator.goToVendorCompaniesPage,
        });
        this.openButton = new button_1.default({
            leftIcon: components_1.Icons.Hamburger,
            onClick: this.toggleMenu,
        });
        this.tenantsButton = new button_1.default({
            leftIcon: components_1.Icons.Gear,
            text: 'TEMS Tenants',
            onClick: this.navigator.goToTenantList
        });
        this.vendorEmailTemplatesButton = new button_1.default({
            leftIcon: components_1.Icons.Email,
            text: 'Email Templates',
            onClick: this.navigator.goToVendorEmailTemplates
        });
        this.vendorEventsListButton = new button_1.default({
            leftIcon: components_1.Icons.List,
            text: 'Events',
            onClick: this.navigator.goToVendorEventList
        });
        this.vendorCompanyInfoButton = new button_1.default({
            leftIcon: components_1.Icons.UserGroup,
            text: 'Company & Users',
            onClick: this.navigator.goToVendorCompanyInfoPage
        });
        this.emailTemplatesButton = new button_1.default({
            leftIcon: components_1.Icons.Email,
            text: 'Email Templates',
            onClick: this.navigator.goToEmailTemplates
        });
        this.helpButton = new button_1.default({
            leftIcon: components_1.Icons.Question,
            text: 'Help/Support',
            onClick: function () { return _this.navigator.helpListModal.open(); }
        });
        this.inventoryHeaderButton = new button_1.default({
            rightIcon: components_1.Icons.ChevronRight,
            text: 'Inventory',
            onClick: function () {
                _this.inventoryExpanded = _this.inventoryExpanded !== true;
                if (_this.inventoryExpanded) {
                    _this.inventoryHeaderButton.setRightIcon(components_1.Icons.ChevronDown);
                }
                else {
                    _this.inventoryHeaderButton.setRightIcon(components_1.Icons.ChevronRight);
                }
                _this.observationProvider.notifyObservers(navActions_1.default.InventoryExpanded);
            }
        });
        this.settingsHeaderButton = new button_1.default({
            rightIcon: components_1.Icons.ChevronRight,
            text: 'Admin',
            onClick: function () {
                _this.settingsExpanded = _this.settingsExpanded !== true;
                if (_this.settingsExpanded) {
                    _this.settingsHeaderButton.setRightIcon(components_1.Icons.ChevronDown);
                }
                else {
                    _this.settingsHeaderButton.setRightIcon(components_1.Icons.ChevronRight);
                }
                _this.observationProvider.notifyObservers(navActions_1.default.SettingsExpanded);
            }
        });
        this.helpUrlsButton = new button_1.default({
            leftIcon: components_1.Icons.Question,
            text: 'Help Urls',
            onClick: this.navigator.goToHelpUrlsPage
        });
        this.observationProvider = new __1.ObservationProvider();
        this.currentUserManager.observationProvider.observeMany(this.updateNavVisibility, [currentUserManagerActions_1.default.LoggedIn, currentUserManagerActions_1.default.TenantChanged]);
        this.updateNavVisibility();
    }
    Object.defineProperty(Nav.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "isHidden", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showEventListNav", {
        get: function () {
            return this.currentUserManager.userType == api_1.UserTypes.User;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showVendorEventListNav", {
        get: function () {
            return this.currentUserManager.userType == api_1.UserTypes.VendorUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showVendorCompanyInfoNav", {
        get: function () {
            return this.currentUserManager.userType == api_1.UserTypes.VendorUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showVendorEmailTemplatesNav", {
        get: function () {
            return this.currentUserManager.userType == api_1.UserTypes.VendorUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showEmailTemplateNav", {
        get: function () {
            return this.currentUserManager.userType == api_1.UserTypes.User;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showVendorCompaniesNav", {
        get: function () {
            return this.currentUserManager.hasPermission(__1.PermissionType.EditVendors);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showVendorUsersNav", {
        get: function () {
            return this.currentUserManager.hasPermission(__1.PermissionType.EditVendors);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showHelpUrlsNav", {
        get: function () {
            return this.currentUserManager.isSuperUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showSettingsHeader", {
        get: function () {
            return this.currentUserManager.hasPermission(__1.PermissionType.EditUserGroups) ||
                this.currentUserManager.hasPermission(__1.PermissionType.EditSystemSettings) ||
                this.currentUserManager.hasPermission(__1.PermissionType.EditVendors) ||
                this.showVendorCompaniesNav;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showDirectoriesHeader", {
        get: function () {
            return this.currentUserManager.hasPermission(__1.PermissionType.EditUsers) ||
                this.currentUserManager.hasPermission(__1.PermissionType.EditCustomers);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showInventoryNavHeader", {
        get: function () {
            return this.showInventoryListNav ||
                this.showInventorySchedulerNav ||
                this.showInventoryForecastNav;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showInventoryListNav", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.Inventory) &&
                this.currentUserManager.hasPermission(__1.PermissionType.EditInventory);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showInventorySchedulerNav", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.Inventory) &&
                this.currentUserManager.hasPermission(__1.PermissionType.ScheduleInventory);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showInventoryForecastNav", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.Inventory) &&
                this.currentUserManager.hasPermission(__1.PermissionType.ViewInventoryForecast);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showUsersListNav", {
        get: function () {
            return this.currentUserManager.userType !== api_1.UserTypes.VendorUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showUserGroupsListNav", {
        get: function () {
            return this.currentUserManager.hasPermission(__1.PermissionType.EditUserGroups);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showCustomersListNav", {
        get: function () {
            return this.currentUserManager.userType !== api_1.UserTypes.VendorUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showSystemSettingsNav", {
        get: function () {
            return this.currentUserManager.hasPermission(__1.PermissionType.EditSystemSettings);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showCustomerCompaniesListNav", {
        get: function () {
            return this.currentUserManager.hasPermission(__1.PermissionType.EditCustomerCompanies);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showTenantsListNav", {
        get: function () {
            return this.currentUserManager.isSuperUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Nav.prototype, "showTemsConfigurationsHeader", {
        get: function () {
            return this.currentUserManager.isSuperUser;
        },
        enumerable: true,
        configurable: true
    });
    return Nav;
}());
exports.default = Nav;
