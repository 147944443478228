import { h, JSX } from 'preact';
import EventAttendeeAccessLevelDataListProps from './eventAttendeeAccessLevelDataListProps';
import ObservingComponent from '../componentBases/observingComponent';
import DataList from '../dataList/dataList';
import Field from '../fields/field';
import Button from '../buttons/button';
import Alert from '../alerts/alert';

class EventAttendeeAccessLevelDataList extends ObservingComponent<EventAttendeeAccessLevelDataListProps> {

    public render({ dataList }: EventAttendeeAccessLevelDataListProps): JSX.Element {
        return (
            <div>
                <Alert alert={dataList.alert} />

                <DataList
                    dataList={dataList}
                    itemTemplate={(item) =>
                        <div>
                            <div class="flex mb-p5">
                                <Field
                                    className="fill"
                                    field={item.form.fields[0]} />

                                <Field
                                    className="inline-checkbox ml-1"
                                    field={item.form.fields[1]} />
                            </div>

                            <Button
                                className={'collapsable-button text-sm ' + (item.form.model.permissionsVisible ? 'active' : '')}
                                button={dataList.buildPermissionVisibilityButton(item.form.model)} />

                            <div class={'collapsable grid-container text-sm mb-1 ' + (item.form.model.permissionsVisible ? 'active' : '')}>
                                <div class="sm-12">
                                    <hr/>
                                </div>

                                {item.form.fields.map((field, index) => {
                                    if (index > 1) {
                                        return <Field className="xs-12 sm-6"
                                                        field={field} />
                                    }
                                })}
                            </div>
                        </div>
                }/>
            </div>
        );
    }
}

export default EventAttendeeAccessLevelDataList;
