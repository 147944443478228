"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../..");
var MeasurementTypeDropdownField = /** @class */ (function (_super) {
    __extends(MeasurementTypeDropdownField, _super);
    function MeasurementTypeDropdownField(key, label, isRequired, requiredMessage, initialValue, noSelectionLabel) {
        var _this = _super.call(this, key, label, isRequired, [
            { label: 'Sq ft', value: _1.MeasurementType.SqFt },
            { label: 'Sq Meters', value: _1.MeasurementType.SqMeters }
        ], requiredMessage, initialValue, __1.FieldType.DropDown, noSelectionLabel) || this;
        _this.key = key;
        return _this;
    }
    return MeasurementTypeDropdownField;
}(_1.BaseDropdownField));
exports.default = MeasurementTypeDropdownField;
