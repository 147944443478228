import { h, JSX } from 'preact';
import ArrivalDepartureTypeProps from './arrivalDepartureTypeProps';
import ObservingComponent from '../componentBases/observingComponent';
import { AccommodationArrivalType } from 'core.frontend';

class ArrivalDepartureType extends ObservingComponent<ArrivalDepartureTypeProps> {

    public render({ type, className }: ArrivalDepartureTypeProps): JSX.Element {
        return (
            <span class={className}>

                { type === AccommodationArrivalType.Air &&
                    <span>Air</span>
                }

                { type === AccommodationArrivalType.Rail &&
                    <span>Rail</span>
                }

                { type === AccommodationArrivalType.Bus &&
                    <span>Bus</span>
                }

                { type === AccommodationArrivalType.Other &&
                    <span>Other</span>
                }

            </span>
        );
    }
}

export default ArrivalDepartureType;
