"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
var _1 = require(".");
var Alert = /** @class */ (function () {
    function Alert() {
        var _this = this;
        this.show = function (params) {
            _this._message = params.message;
            _this._heading = params.heading;
            _this._button = params.button;
            _this._alertType = params.alertType;
            _this._isVisible = true;
            _this.observationProvider.notifyObservers(_1.AlertActions.Shown);
        };
        this.hide = function () {
            _this._isVisible = false;
            _this.observationProvider.notifyObservers(_1.AlertActions.Hidden);
        };
        this.observationProvider = new utils_1.ObservationProvider();
    }
    Object.defineProperty(Alert.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "message", {
        get: function () {
            return this._message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "heading", {
        get: function () {
            return this._heading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "button", {
        get: function () {
            return this._button;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "alertType", {
        get: function () {
            return this._alertType;
        },
        enumerable: true,
        configurable: true
    });
    return Alert;
}());
exports.default = Alert;
