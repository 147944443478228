import { IClientChecker } from 'core.frontend';

export default class BrowserClientChecker implements IClientChecker {
    private readonly isOpera: boolean;
    private readonly isFirefox: boolean;
    private readonly isSafari: boolean;
    private readonly isIE: boolean;
    private readonly isEdge: boolean;
    private readonly isChrome: boolean;
    private readonly isEdgeChromium: boolean;
    private readonly isBlink: boolean;

    public readonly alertHeading: string;
    public readonly alertMessage: string;

    constructor() {

        // local window settings are set in index.html
        const localWindow = window as any;

        this.isOpera = localWindow.isOpera;
        this.isFirefox = localWindow.isFirefox;
        this.isSafari = localWindow.isSafari;
        this.isIE = localWindow.isIE;
        this.isEdge = localWindow.isEdge;
        this.isChrome = localWindow.isChrome;
        this.isEdgeChromium = localWindow.isEdgeChromium;
        this.isBlink = localWindow.isBlink;



        this.alertHeading = localWindow.alertHeading;
        this.alertMessage = localWindow.alertMessage;
    }

    public isMobileApp(): boolean {
        const localWindow = window as any;
        return !!localWindow.ReactNativeWebView;
    }

    public isValidClient(): boolean {
        const localWindow = window as any;
        return localWindow.isValidBrowser();
    }

    public isPreferredClient(): boolean {
        return this.isChrome;
    }
}
