import { JSX, h, Fragment } from 'preact';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ObservingComponent from '../componentBases/observingComponent';
import FormButtonContainer from '../containers/formButtonContainer';
import Field from '../fields/field';
import HelpUrlsPageProps from './helpUrlsPageProps';
import SmartTable from '../smartTable/smartTable';

export default class HelpUrlsPage extends ObservingComponent<HelpUrlsPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private buildColumns = () => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                cellTemplate: (item, index) => (
                    <span>

                        <Field
                            className="inline-label input-xs mt-0 mb-0 mr-0 ml-0 no-label"
                            field={page.forms[index].fields[0]} />

                        <div className="text-sm">
                            {item.searchPhrase}
                        </div>
                    </span>
                )
            }, {
                heading: 'Keywords',
                cellTemplate: (item, index) => (

                    <Field
                        className="inline-label input-xs mt-0 mb-0 mr-0 ml-0 no-label"
                        field={page.forms[index].fields[1]} />
                )
            }, {
                heading: 'Hover Text',
                cellTemplate: (item, index) => (

                    <Field
                        className="inline-label input-xs mt-0 mb-0 mr-0 ml-0 no-label"
                        field={page.forms[index].fields[2]} />
                )
            }, {
                heading: 'PDF URL',
                cellTemplate: (item, index) => (

                    <Field
                        className="inline-label input-xs mt-0 mb-0 mr-0 ml-0 no-label"
                        field={page.forms[index].fields[3]} />
                )
            }
        ];
    }

    public render({ page }: HelpUrlsPageProps): JSX.Element {
        return (
            <div>
                <div class="flex">
                    <Button
                        className="ml-auto btn-sm"
                        color={ButtonColors.Primary}
                        button={page.newButton} />
                </div>

                <div class="form-container mt-1">

                    <SmartTable
                        id="helpUrlTable"
                        table={page.table}
                        columns={this.buildColumns()} />
                </div>

                <FormButtonContainer
                    className='sticky-button-footer'
                    rightButtons={[
                        <Button
                            id="saveHelpUrlsPageButton"
                            color={ButtonColors.Primary}
                            button={page.saveButton} />
                    ]} />
            </div>
        );
    }
}