import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import SettingsPaneProps from './settingsPaneProps';

class SettingsPane extends ObservingComponent<SettingsPaneProps> {

    public render(): JSX.Element {
        const { isVisible, heading, descriptionText, children, headingRightContent } = this.props;

        if (!isVisible) {
            return null;
        }

        return (
            <div class="fill">

                <div class="flex">

                    <div class="fill flex">
                        <b class="v-center d-none d-md-inline">{heading}</b>
                    </div>

                    {headingRightContent}
                </div>

                <div>
                    <hr/>

                    {descriptionText &&
                        <p class="text-sm">
                            {descriptionText}
                        </p>
                    }
                </div>

                {children}
            </div>
        );
    }
}

export default SettingsPane;
