import { Fragment, h, JSX } from 'preact';
import EventInfoTile from './eventInfoTile';
import CostManagementTileProps from './costManagementTileProps';
import CostManagementServiceItemTableRowForm from './costManagementServiceItemTableRowForm';
import ObservingComponent from '../componentBases/observingComponent';
import { CostManagementCostFactor, CostManagementItemType, CostManagementTilePane, ExpenseType } from 'core.frontend';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import Field from '../fields/field';
import EmptyState from '../uiStates/emptyState';
import Alert from '../alerts/alert';
import FormattedDate from '../text/formattedDate';
import FormattedMoney from '../text/formattedMoney'
import ConfirmationPrompt from '../containers/confirmationPrompt';
import Modal from '../containers/modal';

class CostManagementTile extends ObservingComponent<CostManagementTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    public render({ tile }: CostManagementTileProps): JSX.Element {

        return (
            tile.isVisible && <EventInfoTile
                color="green"
                tile={tile}
                icon="ti-cost-management">
                    {tile.isLoading ?
                        'Loading...'
                        : (
                            <div>

                                {tile.confirmationPrompt.isVisible &&
                                    <Modal
                                        className="small"
                                        heading=""
                                        close={tile.confirmationPrompt.hide}>

                                        <ConfirmationPrompt confirmationPrompt={tile.confirmationPrompt} />
                                    </Modal>
                                }

                                <Alert
                                    className="mb-0 mt-1"
                                    alert={tile.alert} />

                                <div class="btn-group mt-1 mb-1 flex">
                                    <Button
                                        size={ButtonSizes.Small}
                                        color={ButtonColors.Primary}
                                        button={tile.showInventoryItemsPaneButton}
                                        className={' fill ' + (tile.currentPane === CostManagementTilePane.InventoryItemsPane ? 'active' : '')} />
                                    <Button
                                        size={ButtonSizes.Small}
                                        color={ButtonColors.Primary}
                                        button={tile.showServiceItemsPaneButton}
                                        className={' fill ' + (tile.currentPane === CostManagementTilePane.ServiceItemsPane ? 'active' : '')}/>
                                    <Button
                                        size={ButtonSizes.Small}
                                        color={ButtonColors.Primary}
                                        button={tile.showCostDistributionPaneButon}
                                        className={' fill ' + (tile.currentPane === CostManagementTilePane.CostDistribution ? 'active' : '')}/>
                                </div>

                                {tile.currentPane === CostManagementTilePane.ServiceItemsPane &&
                                    <div>
                                        <div class="flex">
                                            <Button
                                                className=" mt-1 ml-auto"
                                                size={ButtonSizes.Small}
                                                color={ButtonColors.Primary}
                                                button={tile.addNewServiceItemButton} />
                                        </div>

                                        {(!!tile.serviceForms.length || !!tile.newServiceItemForms.length) &&
                                            <table class="table table-striped text-sm mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>Service/Item*</th>
                                                        <th>BA</th>
                                                        <th>Ex. Loc.*</th>
                                                        <th>Provider*</th>
                                                        <th class="fit-content text-center">Est. Cost*</th>
                                                        <th class="fit-content text-center">Actual Cost</th>
                                                        <th class="fit-content text-center"><span class="text-red">Over</span>/<span class="text-green">Under</span></th>
                                                        <th class="fit-content text-center">% A*</th>

                                                        {tile.isEditMode &&
                                                            <th></th>
                                                        }
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {tile.isEditMode && tile.newServiceItemForms.map((form, index) => (
                                                        <CostManagementServiceItemTableRowForm
                                                            key={index}
                                                            form={form}
                                                            deleteButton={tile.buildDeleteNewServiceButton(form.model, index)} />
                                                    ))}

                                                    {tile.isEditMode && tile.serviceForms.map((form, index) => (
                                                        <Fragment key={form.model.id}>
                                                            {index == 0 && form.model.expenseType == ExpenseType.Expense &&
                                                                <tr>
                                                                    <td
                                                                        class="table-row-sub-head"
                                                                        colSpan={100}>

                                                                        EXPENSES
                                                                    </td>
                                                                </tr>
                                                            }

                                                            {
                                                            (
                                                                (index == 0 && form.model.expenseType == ExpenseType.Credit) ||
                                                                (index > 0 && tile.serviceForms[index - 1].model.expenseType != form.model.expenseType)
                                                            ) &&
                                                                <tr>
                                                                    <td
                                                                        class="table-row-sub-head"
                                                                        colSpan={100}>

                                                                        CREDITS
                                                                    </td>
                                                                </tr>
                                                            }

                                                            <CostManagementServiceItemTableRowForm
                                                                form={form}
                                                                deleteButton={tile.buildDeleteServiceButton(form.model, index)} />
                                                        </Fragment>
                                                    ))}

                                                    {!tile.isEditMode && tile.serviceForms.map((form, index) => (
                                                        <Fragment key={form.model.id}>
                                                            {index == 0 && form.model.expenseType == ExpenseType.Expense &&
                                                                <tr>
                                                                    <td
                                                                        class="table-row-sub-head"
                                                                        colSpan={100}>

                                                                        EXPENSES
                                                                    </td>
                                                                </tr>
                                                            }

                                                            {
                                                            (
                                                                (index == 0 && form.model.expenseType == ExpenseType.Credit) ||
                                                                (index > 0 && tile.serviceForms[index - 1].model.expenseType != form.model.expenseType)
                                                            ) &&
                                                                <tr>
                                                                    <td
                                                                        class="table-row-sub-head"
                                                                        colSpan={100}>

                                                                        CREDITS
                                                                    </td>
                                                                </tr>
                                                            }



                                                            <tr>
                                                                <td>
                                                                    <span>
                                                                        <b>{form.model.name}</b>
                                                                        <div class="d-block">{form.model.description}</div>
                                                                    </span>
                                                                </td>

                                                                <td class="fit-content">
                                                                    {
                                                                    form.model.billedBusinessAreas.length == tile.billableBusinessAreas.length &&
                                                                    form.model.primaryCostFactor === CostManagementCostFactor.BusinessArea &&

                                                                        <span>All Business Areas</span>
                                                                    }

                                                                    {
                                                                    form.model.billedBusinessAreas.length !== tile.billableBusinessAreas.length &&
                                                                    form.model.primaryCostFactor === CostManagementCostFactor.BusinessArea &&
                                                                    form.model.billedBusinessAreas.map((billedBusinessArea) => (

                                                                        <div class="d-block">
                                                                            {billedBusinessArea.businessArea}
                                                                        </div>
                                                                    ))}
                                                                </td>

                                                                <td>
                                                                    <div class="d-block">
                                                                        {form.model.exhibitSpace}
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <span>
                                                                        {form.model.provider}
                                                                    </span>
                                                                </td>

                                                                <td class="fit-content text-right">
                                                                    <span>
                                                                        {form.model.expenseType == ExpenseType.Credit && <span>-</span>}
                                                                        <FormattedMoney amount={form.model.estimatedCost} />
                                                                    </span>
                                                                </td>

                                                                <td class="fit-content text-right">
                                                                    <span>
                                                                        {form.model.expenseType == ExpenseType.Credit && <span>-</span>}
                                                                        <FormattedMoney amount={form.model.actualCost} />
                                                                    </span>
                                                                </td>

                                                                <td class="fit-content text-right">

                                                                    {form.model.expenseType == ExpenseType.Expense &&
                                                                        <FormattedMoney
                                                                            amount={form.model.costDifference}
                                                                            className={
                                                                                form.model.costDifference > 0 ? 'text-red ' :
                                                                                form.model.costDifference < 0 ? 'text-green ' : ''
                                                                            } />
                                                                    }

                                                                    {form.model.expenseType == ExpenseType.Credit &&
                                                                        <FormattedMoney
                                                                            amount={form.model.costDifference}
                                                                            className={
                                                                                form.model.costDifference < 0 ? 'text-red ' :
                                                                                form.model.costDifference > 0 ? 'text-green ' : ''
                                                                            } />
                                                                    }
                                                                </td>

                                                                <td class="fit-content text-center">
                                                                    <span>
                                                                        {form.model.allowablePercent}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ))}

                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-bold text-right">Total:</td>
                                                        <td class="text-bold text-right">
                                                            <FormattedMoney amount={tile.totalEstimatedCost} />
                                                        </td>
                                                        <td class="text-bold text-right">
                                                            <FormattedMoney amount={tile.totalActualCost} />
                                                        </td>
                                                        <td class="text-bold text-right">
                                                            <FormattedMoney
                                                                amount={tile.totalCostDifference}
                                                                className={
                                                                    tile.totalCostDifference > 0 ? 'text-red ' :
                                                                    tile.totalCostDifference < 0 ? 'text-green ' : ''
                                                                } />
                                                        </td>
                                                        <td></td>

                                                        {tile.isEditMode &&
                                                            <td></td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>
                                        }

                                        {!tile.serviceForms.length && !tile.newServiceItemForms.length &&
                                            <EmptyState
                                                message="No service items are assigned to this event" />
                                        }
                                    </div>
                                }

                                {tile.currentPane === CostManagementTilePane.InventoryItemsPane &&
                                    <div>
                                        <div class="flex">
                                            <Button
                                                className=" mt-1 ml-auto"
                                                size={ButtonSizes.Small}
                                                color={ButtonColors.Primary}
                                                button={tile.addNewItemButton} />
                                        </div>

                                        {!!tile.forms.length &&
                                            <div>
                                                <table class="table table-striped text-sm mt-1">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">I/R</th>
                                                        <th>Item</th>
                                                        <th>BA</th>
                                                        <th>Area Sqft.</th>
                                                        <th class="text-center">Weight</th>
                                                        <th>Ex. Loc.</th>
                                                        <th class="text-center">Stack</th>
                                                        <th class="text-center">Qty</th>
                                                        <th class="text-center">Pts</th>
                                                        <th class="text-center">Tot</th>
                                                        {tile.isEditMode &&
                                                            <th></th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tile.forms.map((form, index) => (
                                                        <tr class={form.model.isRemoved === true ? 'deleting' : ''}>
                                                            <td class="fit-content text-center">
                                                                {form.model.itemType &&
                                                                    <span>
                                                                        {form.model.itemType == CostManagementItemType.Inventory && <span>I</span> }
                                                                        {form.model.itemType == CostManagementItemType.Rented && <span>R</span> }
                                                                    </span>
                                                                }

                                                                {!form.model.itemType &&
                                                                    <Field
                                                                        className="field-fit-content"
                                                                        field={form.fields[0]} />
                                                                }
                                                            </td>
                                                            <td>
                                                                {form.model.itemType == CostManagementItemType.Inventory && form.model.inventoryItemId &&
                                                                    <span>
                                                                        <b>{form.model.name}</b>
                                                                        <div class="d-block">
                                                                            Type: {form.model.inventoryItemType} |&nbsp;
                                                                            Serial #: {form.model.serialNumber || <i>Unknown</i>} |&nbsp;
                                                                            Crate #: {form.model.crateNumbers || <i>Unknown</i>}|&nbsp;
                                                                            Scale: {form.model.scale || <i>Unknown</i>}
                                                                        </div>
                                                                    </span>
                                                                }

                                                                {form.model.itemType == CostManagementItemType.Inventory && !form.model.inventoryItemId &&
                                                                    <Field
                                                                        className="width-100"
                                                                        field={form.fields[1]} />
                                                                }

                                                                {form.model.itemType == CostManagementItemType.Rented && !tile.isEditMode &&
                                                                    <span>
                                                                        <b>{form.model.name}</b>
                                                                        <div class="d-block">{form.model.description}</div>
                                                                    </span>
                                                                }

                                                                {form.model.itemType == CostManagementItemType.Rented && tile.isEditMode &&
                                                                    <span>
                                                                        <Field
                                                                            className="inline-label input-xs mt-p5"
                                                                            field={form.fields[3]} />

                                                                        <div class="d-block">
                                                                            <Field
                                                                                className="inline-label input-xs mt-p5"
                                                                                field={form.fields[2]} />
                                                                        </div>
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {!tile.isEditMode && form.model.billedBusinessAreas.length == tile.billableBusinessAreas.length &&
                                                                    <span>All Business Areas</span>
                                                                }

                                                                {!tile.isEditMode && form.model.billedBusinessAreas.length !== tile.billableBusinessAreas.length && form.model.billedBusinessAreas.map((billedBusinessArea) => (
                                                                    <div class="d-block">
                                                                        {billedBusinessArea.businessArea}
                                                                    </div>
                                                                ))}

                                                                {tile.isEditMode && (form.model.itemType === CostManagementItemType.Rented || form.model.inventoryItemId) &&
                                                                    <Field
                                                                        key={form.fields[11].uniqueKey}
                                                                        field={form.fields[11]} />
                                                                }
                                                            </td>
                                                            <td class="fit-content text-center">
                                                                {!tile.isEditMode &&
                                                                    <span>
                                                                        {form.model.areaSquareFeet}
                                                                        <span>&nbsp;</span>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            button={tile.buildAreaSquareFeetAdjustmentCommentsButton(form)} />
                                                                    </span>

                                                                }

                                                                {tile.isEditMode &&
                                                                    <Field
                                                                        className="no-label digit-field"
                                                                        field={form.fields[4]} />
                                                                }
                                                            </td>
                                                            <td class="fit-content text-center">
                                                                {(!tile.isEditMode || form.model.itemType === CostManagementItemType.Inventory)  &&
                                                                    <span>
                                                                        {form.model.weight}
                                                                        <span>&nbsp;</span>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            button={tile.buildWeightAdjustmentCommentsButton(form)} />
                                                                    </span>
                                                                }

                                                                {tile.isEditMode && form.model.itemType === CostManagementItemType.Rented &&
                                                                    <Field
                                                                        className="no-label digit-field"
                                                                        field={form.fields[5]} />
                                                                }
                                                            </td>
                                                            <td class="fit-content text-center">
                                                                {!tile.isEditMode && (form.model.itemType === CostManagementItemType.Rented || form.model.inventoryItemId) &&
                                                                    <span>{form.model.exhibitSpace}</span>
                                                                }

                                                                {tile.isEditMode && (form.model.itemType === CostManagementItemType.Rented || form.model.inventoryItemId) &&
                                                                    <Field
                                                                        className="no-label field-fit-content"
                                                                        field={form.fields[10]} />
                                                                }
                                                            </td>
                                                            <td class="fit-content text-center">
                                                                {!tile.isEditMode &&
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={form.model.ignoreDimensions}
                                                                        disabled={true} />
                                                                }

                                                                {tile.isEditMode && (form.model.itemType === CostManagementItemType.Rented || form.model.inventoryItemId) &&
                                                                    <Field
                                                                        className="no-label"
                                                                        field={form.fields[9]} />
                                                                }
                                                            </td>
                                                            <td class="fit-content text-center">
                                                                {(!tile.isEditMode || form.model.itemType === CostManagementItemType.Inventory)  &&
                                                                    <span>{form.model.quantity}</span>
                                                                }

                                                                {tile.isEditMode && form.model.itemType === CostManagementItemType.Rented &&
                                                                    <Field
                                                                        className="no-label digit-field"
                                                                        field={form.fields[6]} />
                                                                }
                                                            </td>
                                                            <td class="fit-content text-center">

                                                                {!tile.isEditMode &&
                                                                    <span>
                                                                        {form.model.points}
                                                                        <span>&nbsp;</span>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            button={tile.buildPointAdjustmentCommentsButton(form)} />
                                                                    </span>
                                                                }

                                                                {tile.isEditMode && (form.model.itemType === CostManagementItemType.Rented || form.model.inventoryItemId) &&
                                                                    <Field
                                                                        className="no-label digit-field"
                                                                        field={form.fields[7]} />
                                                                }
                                                            </td>
                                                            <td class="fit-content text-center">
                                                                {form.model.totalPoints}
                                                            </td>
                                                            {tile.isEditMode &&
                                                                <td class="fit-content">
                                                                    <Button
                                                                        className="btn-icon"
                                                                        size={ButtonSizes.Small}
                                                                        color={ButtonColors.Primary}
                                                                        button={tile.buildDeleteItemButton(form.model, index)} />
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                        }

                                        {!tile.forms.length &&
                                            <EmptyState
                                                message="No Inventory or rented items are assigned to this event" />
                                        }
                                    </div>
                                }

                                {tile.currentPane === CostManagementTilePane.CostDistribution &&
                                    <div>
                                        <div class="flex space-around">
                                            <div>
                                                <div class="text-sm">
                                                    Total Budgeted Cost
                                                </div>

                                                <div class="text-xl text-center">
                                                    <FormattedMoney amount={tile.distributionTotalBudgetedCost} />
                                                </div>
                                            </div>

                                            <div>
                                                <div class="text-sm">
                                                    Total Actual Cost
                                                </div>

                                                <div class="text-xl text-center">
                                                    <FormattedMoney amount={tile.distributionTotalActualCost} />
                                                </div>
                                            </div>

                                            <div>
                                                <div class="text-sm">
                                                    Credits
                                                </div>

                                                <div class="text-xl text-center text-red">
                                                    <FormattedMoney amount={tile.distributionTotalCredits} />
                                                </div>
                                            </div>

                                            <div>
                                                <div class="text-sm">
                                                    <span class="text-red">Over</span>/<span class="text-green">Under</span>
                                                </div>

                                                <div
                                                    class={"text-xl text-center " +
                                                        (tile.distributionTotalDifference < 0 ? 'text-green ' : '') +
                                                        (tile.distributionTotalDifference > 0 ? 'text-red ' : '')
                                                    }>

                                                    <FormattedMoney amount={tile.distributionTotalDifference} />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex">
                                            <table class="table table-striped table-small text-sm mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>BA</th>
                                                        <th>Budgeted</th>
                                                        <th>Allowed</th>
                                                        <th>Unallowed</th>
                                                        <th>Total Actuals</th>
                                                        <th>Credits</th>
                                                        <th>Final</th>
                                                        <th>
                                                            <span class="text-red">Over</span>/<span class="text-green">Under</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tile.businessAreaDistributions.map((businessAreaDistribution) =>
                                                        <tr>
                                                            <td>
                                                                {businessAreaDistribution.businessAreaName}</td>
                                                            <td>
                                                                <FormattedMoney amount={businessAreaDistribution.budgetedCost} />
                                                            </td>
                                                            <td>
                                                                <FormattedMoney amount={businessAreaDistribution.allowedCost} />
                                                            </td>
                                                            <td>
                                                                <FormattedMoney amount={businessAreaDistribution.unallowedCost} />
                                                            </td>
                                                            <td>
                                                                <FormattedMoney amount={businessAreaDistribution.actualCost} />
                                                            </td>
                                                            <td>
                                                                <FormattedMoney amount={businessAreaDistribution.credits} />
                                                            </td>
                                                            <td>
                                                                <FormattedMoney amount={businessAreaDistribution.finalCost} />
                                                            </td>
                                                            <td class={
                                                                (businessAreaDistribution.difference < 0 ? 'text-green': '') +
                                                                (businessAreaDistribution.difference > 0 ? 'text-red': '')
                                                            }>

                                                                <FormattedMoney amount={businessAreaDistribution.difference} />
                                                            </td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>

                                        {tile.exhibitSpaceBreakdowns.map((exhibitSpace) => (
                                            <div class="p-1 mt-1 bg-white">
                                                <b>{exhibitSpace.eventExhibitSpace}</b>

                                                <div class="flex text-sm">
                                                    <div class="fill flex">
                                                        <div>
                                                            <div>Available Area:</div>
                                                            <div>Utilized Area: </div>
                                                        </div>
                                                        <div class="fill ml-p5">
                                                            <div>{exhibitSpace.squareFeet} sqft.</div>
                                                            <div>{exhibitSpace.utilizedSquareFeet} sqft.</div>
                                                        </div>
                                                    </div>

                                                    <div class="fill flex">
                                                        <div>
                                                            <div>Allowed Cost:</div>
                                                            <div>Unallowed Cost: </div>
                                                        </div>
                                                        <div class="fill ml-p5">
                                                            <div><FormattedMoney amount={exhibitSpace.totalAllowedCost} /></div>
                                                            <div><FormattedMoney amount={exhibitSpace.totalUnallowedCost} /></div>
                                                        </div>
                                                    </div>

                                                    <div class="fill flex">
                                                        <div>
                                                            <div>Total Cost: </div>
                                                            <div>Percentage: </div>
                                                        </div>
                                                        <div class="fill ml-p5">
                                                            <div><FormattedMoney amount={exhibitSpace.totalCost} /></div>
                                                            <div>{exhibitSpace.costPercentage}% of all booth space</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <table class="mt-1 table table-striped table-small text-sm">
                                                    <thead class="bg-gray">
                                                        <tr>
                                                            <th>Business Area</th>
                                                            <th>Allowed Cost</th>
                                                            <th>Unallowed Cost</th>
                                                            <th>Total</th>
                                                            <th>Percent</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {exhibitSpace.businessAreas.map((businessArea) =>
                                                            <tr>
                                                                <td>{businessArea.businessAreaName}</td>
                                                                <td><FormattedMoney amount={businessArea.allowedCost}/></td>
                                                                <td><FormattedMoney amount={businessArea.unallowedCost}/></td>
                                                                <td><FormattedMoney amount={businessArea.totalCost}/></td>
                                                                <td>{businessArea.costPercentage}%</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>

                                                <div class="cost-management-factor-breakdown grid-container text-sm">
                                                    {exhibitSpace.factorBreakdowns.map((factorBreakdown, factorIndex) =>
                                                        <div class="mt-1 xs-12">
                                                            <div class="heading">{factorBreakdown.heading}</div>

                                                            <div class="flex factor-breakdown-row">
                                                                <div class="md-3 text-bold text-center">Bus. Area</div>
                                                                <div class="md-1 text-bold text-center">Val</div>
                                                                <div class="md-1 text-bold text-center">%</div>
                                                                <div class="md-1 text-bold text-center">Allow</div>
                                                                <div class="md-1p5 text-bold text-center">Unallow</div>
                                                                <div class="md-1p5 text-bold text-center">Actual</div>
                                                                <div class="md-1p5 text-bold text-center">Credits</div>
                                                                <div class="md-1p5 text-bold text-center">Final</div>
                                                            </div>

                                                            {factorBreakdown.items.map((factorBreakdownItem) =>
                                                                <div class="flex factor-breakdown-row">
                                                                    <div class="md-3">{factorBreakdownItem.businessArea}</div>
                                                                    <div class="md-1 text-right">
                                                                        {factorBreakdown.factor !== CostManagementCostFactor.BusinessArea &&
                                                                            <span>{factorBreakdownItem.value}</span>
                                                                        }
                                                                    </div>
                                                                    <div class="md-1 text-right">
                                                                        {factorBreakdownItem.percentage}%
                                                                    </div>
                                                                    <div class="md-1 text-right">
                                                                        <FormattedMoney amount={factorBreakdownItem.allowedCost} />
                                                                    </div>
                                                                    <div class="md-1p5 text-right">
                                                                        <FormattedMoney amount={factorBreakdownItem.unallowedCost} />
                                                                    </div>
                                                                    <div class="md-1p5 text-right">
                                                                        <FormattedMoney amount={factorBreakdownItem.cost} />
                                                                    </div>
                                                                    <div class="md-1p5 text-right">
                                                                        <FormattedMoney amount={factorBreakdownItem.credit} />
                                                                    </div>
                                                                    <div class="md-1p5 text-right">
                                                                        <FormattedMoney amount={factorBreakdownItem.cost - (-factorBreakdownItem.credit)} />
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div class="flex factor-breakdown-row">
                                                                <div class="md-3 text-right">Total</div>

                                                                <div class="md-1 text-right">
                                                                    {factorBreakdown.factor !== CostManagementCostFactor.BusinessArea &&
                                                                        <span>{factorBreakdown.total}</span>
                                                                    }
                                                                </div>

                                                                <div class="md-2 text-right">
                                                                    <FormattedMoney amount={factorBreakdown.totalAllowedCost} />
                                                                </div>

                                                                <div class="md-1p5 text-right">
                                                                    <FormattedMoney amount={factorBreakdown.totalUnallowedCost} />
                                                                </div>

                                                                <div class="md-1p5 text-right">
                                                                    <FormattedMoney amount={factorBreakdown.totalCost} />
                                                                </div>

                                                                <div class="md-1p5 text-right">
                                                                    <FormattedMoney amount={factorBreakdown.totalCredit} />
                                                                </div>

                                                                <div class="md-1p5 text-right">
                                                                    <FormattedMoney amount={factorBreakdown.totalCost - (-factorBreakdown.totalCredit)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                                {tile.pointOverrideModalIsVisible &&
                                    <Modal
                                        className="small"
                                        heading="Points Override"
                                        close={tile.hidePointOverrideModal}
                                        footerRightButtons={[
                                            <Button
                                                color={ButtonColors.Secondary}
                                                button={tile.cancelPointOverrideModalButton} />,
                                            <Button
                                                color={ButtonColors.Primary}
                                                button={tile.applyPointOverrideModalButton} />
                                        ]}>

                                        <div class="mt-1">

                                            <Alert alert={tile.pointsOverrideAlert} />

                                            {!tile.isEditMode &&
                                                <p>
                                                    The points for this item has been modified from its default value.


                                                        <div class="mt-1">
                                                            Modified By: <b>{tile.pointOverrideForm.model.pointsAdjustmentByUser}</b>
                                                            <br/>
                                                            On: <b><FormattedDate date={tile.pointOverrideForm.model.pointsAdjustmentDateTime} /></b>
                                                            <br/>
                                                            Original Value: <b>{tile.pointOverrideForm.model.originalPoints}</b>
                                                        </div>
                                                </p>
                                            }

                                            {tile.isEditMode &&
                                                <p>You are modifying the default points value. Please provide a brief explanation for this change in the comments box below.</p>
                                            }

                                            <div class="form-container">
                                                <Field field={tile.pointOverrideCommentField} />
                                            </div>
                                        </div>
                                    </Modal>
                                }

                                {tile.weightChangeModalIsVisible &&
                                    <Modal
                                        className="small"
                                        heading="Weight Change"
                                        close={tile.hideWeightChangeModal}
                                        footerRightButtons={[
                                            <Button
                                                color={ButtonColors.Secondary}
                                                button={tile.hideWeightChangeModalButton} />
                                        ]}>

                                        <div class="mt-1">
                                            <p>
                                                The weight for this inventory item was modified after it was added to the event.

                                                <div class="mt-1">
                                                    Modified By: <b>{tile.weightChangeModalTableItem.inventoryItemAdjustmentWeightUser}</b>
                                                    <br/>
                                                    On: <b><FormattedDate date={tile.weightChangeModalTableItem.inventoryItemAdjustmentWeightDateTime} /></b>
                                                    <br/>
                                                    Original Value: <b>{tile.weightChangeModalTableItem.inventoryItemAdjustmentOriginalWeight}</b>
                                                    <br/>
                                                    Comments: {tile.weightChangeModalTableItem.inventoryItemAdjustmentWeightComments}
                                                </div>
                                            </p>
                                        </div>
                                    </Modal>
                                }

                                {tile.areaSquareFootChangeModalIsVisible &&
                                    <Modal
                                        className="small"
                                        heading="Area Square Footage Change"
                                        close={tile.hideAreaSquareFootChangeModal}
                                        footerRightButtons={[
                                            <Button
                                                color={ButtonColors.Secondary}
                                                button={tile.hideAreaSquareFootChangeModalButton} />
                                        ]}>

                                        <div class="mt-1">
                                            <p>
                                                The area square footage for this inventory item was modified after it was added to the event.

                                                <div class="mt-1">
                                                    Modified By: <b>{tile.areaSquareFootChangeModalTableItem.inventoryItemAdjustmentAreaSquareFeetUser}</b>
                                                    <br/>
                                                    On: <b><FormattedDate date={tile.areaSquareFootChangeModalTableItem.inventoryItemAdjustmentAreaSquareFeetDateTime} /></b>
                                                    <br/>
                                                    Original Value: <b>{tile.areaSquareFootChangeModalTableItem.inventoryItemAdjustmentOriginalAreaSquareFeet}</b>
                                                    <br/>
                                                    Comments: {tile.areaSquareFootChangeModalTableItem.inventoryItemAdjustmentAreaSquareFeetComments}
                                                </div>
                                            </p>
                                        </div>
                                    </Modal>
                                }
                            </div>
                        )
                    }
            </EventInfoTile>
        );
    }
}

export default CostManagementTile;
