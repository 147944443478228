"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HelpUrlsPageActions;
(function (HelpUrlsPageActions) {
    HelpUrlsPageActions["Loaded"] = "Loaded";
    HelpUrlsPageActions["Saving"] = "Saving";
    HelpUrlsPageActions["Saved"] = "Saved";
    HelpUrlsPageActions["New"] = "New";
})(HelpUrlsPageActions || (HelpUrlsPageActions = {}));
exports.default = HelpUrlsPageActions;
