import { h, Component, JSX } from 'preact';
import TableLoadingPlaceholderRowProps from './tableLoadingPlaceholderRowProps';

class TableLoadingPlaceholderRow extends Component<TableLoadingPlaceholderRowProps> {

    public render({ columnCount }: TableLoadingPlaceholderRowProps): JSX.Element {
        return (
            <tr className="loading">
                <td colSpan={columnCount} style={{height: '3.575rem'}}>
                    <span className="loading-placeholder shimmer"></span>
                </td>
            </tr>
        );
    }
}

export default TableLoadingPlaceholderRow;
