import AppRoot from './root/appRoot';
import PreactApp from './root/preactApp';
import { h, render } from 'preact';
import '../node_modules/croppie/croppie.css';
import './styles/header.css';
import './styles/main.css';
import './styles/nav.css';
import './styles/login.css';
import './styles/components.css';
import './styles/positionAndSpacing.css';
import './styles/eventLanding.css';
import './styles/fonts.css';

const app = new PreactApp();

render(<AppRoot app={app} />, document.getElementById('root'));
