"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var helpButton_1 = require("../../components/button/helpButton");
var utils_1 = require("../../utils");
var helpUrls_1 = require("../helpUrls");
var InventoryListPage = /** @class */ (function (_super) {
    __extends(InventoryListPage, _super);
    function InventoryListPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.InventoryList, 'Inventory List') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.loadData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.Inventory, _this._table.recordsPerPage, _this._table.currentPage, _this._table.sortExpression, _this._table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.goToNewInventoryItem = function () {
            _this.navigator.goToInventoryDetailPage();
            var inventoryDetailPage = _this.navigator.currentPage;
            inventoryDetailPage.observationProvider.observe(_this.addRecordToTable, __1.InventoryDetailPageActions.RecordAdding);
            inventoryDetailPage.observationProvider.observe(_this.updateAddedRecord, __1.InventoryDetailPageActions.RecordAdded);
        };
        _this.addRecordToTable = function (record) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        _b = {
                            name: record.name,
                            scale: record.scale
                        };
                        return [4 /*yield*/, this.api.getLineOfBusinessName(record.lineOfBusinessId)];
                    case 1:
                        _b.lineOfBusiness = _c.sent();
                        return [4 /*yield*/, this.api.getInventoryItemTypeName(record.inventoryItemTypeId)];
                    case 2:
                        _a._newTableRecord = (_b.inventoryItemType = _c.sent(),
                            _b.businessAreaId = record.businessAreaId,
                            _b.lineOfBusinessId = record.lineOfBusinessId,
                            _b.isActive = true,
                            _b);
                        this._table.insertAddingRecord(this._newTableRecord);
                        this.navigator.goToInventoryListPage();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.updateAddedRecord = function (record) {
            _this._newTableRecord.id = record.id;
            _this._table.recordAddedSuccessfully();
        };
        _this.goToEditInventoryItem = function (id) { return function () {
            _this.navigator.goToInventoryDetailPage(id);
            var inventoryDetailPage = _this.navigator.currentPage;
            inventoryDetailPage.observationProvider.observe(_this.markRecordAsEditing, __1.InventoryDetailPageActions.RecordUpdating);
            inventoryDetailPage.observationProvider.observe(_this.markRecordAsEdited, __1.InventoryDetailPageActions.RecordUpdated);
            inventoryDetailPage.observationProvider.observe(_this.markAsDeactivated, __1.InventoryDetailPageActions.RecordDeactivated);
            inventoryDetailPage.observationProvider.observe(_this.markAsActivated, __1.InventoryDetailPageActions.RecordActivated);
        }; };
        _this.markRecordAsEditing = function (updatedRecord) {
            _this._table.markRecordAsSaving(updatedRecord.id);
            _this._table.updateRecord(updatedRecord.id, updatedRecord);
        };
        _this.markRecordAsEdited = function (record) {
            _this._table.markRecordAsNotSaving(record.id);
        };
        _this.markAsDeactivated = function (record) {
            _this._table.updateRecord(record.id, record);
        };
        _this.markAsActivated = function (record) {
            _this._table.updateRecord(record.id, record);
        };
        _this.buildEditButton = function (inventoryItem) {
            return new button_1.default({
                text: inventoryItem.name,
                onClick: _this.goToEditInventoryItem(inventoryItem.id)
            });
        };
        _this.buildShowImageCarouselButton = function (item) {
            var btn = new button_1.default({
                leftIcon: __1.Icons.Camera,
                onClick: _this.showImageCarousel(item)
            });
            btn.setDisabled(!item.imageCount);
            return btn;
        };
        _this.showImageCarousel = function (item) { return function () { return __awaiter(_this, void 0, void 0, function () {
            var imgUrls;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        item.loadingImages = true;
                        this.observationProvider.notifyObservers(__1.InventoryListPageActions.LoadingImages);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Inventory + '/' + item.id + '/Images')];
                    case 1:
                        imgUrls = _a.sent();
                        this._imageCarousel = new __1.ImageCarousel(imgUrls, item.name);
                        this._imageCarousel.observationProvider.observe(this.closeImageCarousel, __1.ImageCarouselActions.Closed);
                        item.loadingImages = false;
                        this.observationProvider.notifyObservers(__1.InventoryListPageActions.ImageCarouselLoaded);
                        return [2 /*return*/];
                }
            });
        }); }; };
        _this.closeImageCarousel = function () {
            _this._imageCarousel = null;
            _this.observationProvider.notifyObservers(__1.InventoryListPageActions.ImageCarouselClosed);
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.InventoryListPage, null, navigator, api);
        _this.observationProvider = new utils_1.ObservationProvider();
        _this._table = new __1.Table(_this.loadData, api);
        _this._table.searchBoxPlaceholder = 'By name, serial # or crate #';
        var filters = [
            new __1.TextField('name', 'Item Name', false, 200),
            new __1.StringDropdownField('isActive', 'Is Active', false, [{ label: 'All', value: '' }, { label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]),
            new __1.LinesOfBusinessDropdown(api, 'lineOfBusinessId', false),
            new __1.InventoryItemTypeDropdown(api, 'inventoryItemTypeId', 'Item Type', false, null),
            new __1.InventoryItemGroupDropdown(api, 'inventoryItemGroupId', 'Group', false),
            new __1.InventoryItemCategoryDropdown(api, 'inventoryItemCategoryId', 'Capability', false),
            new __1.TextField('scale', 'Scale', false, 200),
            new __1.BusinessAreaDropdown(api, 'businessAreaId', 'Business Area', false, null),
            new __1.TextField('serialNumber', 'Serial/Item #', false, 200),
            new __1.TextField('crateNumber', 'Crate Number', false, 200),
            new __1.TextField('crateUSMLCategory', 'USML Category', false, 200),
            new __1.TextField('crateExportAuthorizationType', 'Export Authorization Type', false, 200),
        ];
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, { isActive: true });
        _this.listOptionSlideContainer.observationProvider.observe(function () { return _this._table.load(); }, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this._table.load();
        _this.newInventoryItemButton = new button_1.default({
            text: 'New Inventory Item',
            leftIcon: __1.Icons.Plus,
            onClick: _this.goToNewInventoryItem
        });
        return _this;
    }
    Object.defineProperty(InventoryListPage.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryListPage.prototype, "imageCarousel", {
        get: function () {
            return this._imageCarousel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryListPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    return InventoryListPage;
}(__1.BasePage));
exports.default = InventoryListPage;
