import { h, JSX } from 'preact';
import { DailyTransportStatus as FrontEndDailyTransportStatus } from 'core.frontend';
import DailyTransportStatusProps from './dailyTransportStatusProps';
import ObservingComponent from '../componentBases/observingComponent';

class DailyTransportStatus extends ObservingComponent<DailyTransportStatusProps> {

    public render({ status, className }: DailyTransportStatusProps): JSX.Element {

        if (status === FrontEndDailyTransportStatus.Pending) {
            return <div class={'text-orange ' + className}><b>Pending</b></div>;
        }

        if (status === FrontEndDailyTransportStatus.ConfirmedWithChanges) {
            return <div class={'text-orange ' + className}><b>Confirmed with Changes</b></div>;
        }

        if (status === FrontEndDailyTransportStatus.Confirmed) {
            return <div class={'text-green ' + className}><b>Confirmed</b></div>;
        }

        if (status === FrontEndDailyTransportStatus.Cancelled) {
            return <div class={'text-red ' + className}><b>Cancelled</b></div>;
        }

        return (
            <div class={className}><b>Not Yet Submitted</b></div>
        );
    }
}

export default DailyTransportStatus;
