import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import MeetingStatusProps from './meetingStatusProps';
import { MeetingStatuses } from 'core.frontend';

class MeetingStatus extends ObservingComponent<MeetingStatusProps> {

    private getStatusText = () => {
        const { status } = this.props;

        if (status === MeetingStatuses.Finalized) {
            return 'Finalized';
        }

        if (status === MeetingStatuses.Working) {
            return 'Working';
        }

        if (status === MeetingStatuses.Requested) {
            return 'Requested';
        }

        return '';
    }

    public render({ className }: MeetingStatusProps): JSX.Element {
        return <span class={className}>{this.getStatusText()}</span>;
    }
}

export default MeetingStatus;
