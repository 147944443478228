"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var helpButton_1 = require("../../../../../components/button/helpButton");
var helpUrls_1 = require("../../../../helpUrls");
var EventFilesTile = /** @class */ (function (_super) {
    __extends(EventFilesTile, _super);
    function EventFilesTile(eventId, api, currentUserManager, canEdit, navigator) {
        var _this = _super.call(this, 'Files', api, currentUserManager.clientChecker.isMobileApp(), false, false) || this;
        _this.eventId = eventId;
        _this.currentUserManager = currentUserManager;
        _this.canEdit = canEdit;
        _this.navigator = navigator;
        _this.setIsEditable = function (isEditable) {
            _this.fileUploadField.setVisibility(isEditable);
            _this.canEdit = isEditable;
        };
        _this.uploadFile = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.fileUploadField.value) {
                            return [2 /*return*/];
                        }
                        this.alert.hide();
                        if (!this.fileUploadField.isValid) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.observationProvider.notifyObservers(__1.EventFilesTileActions.FileUploading);
                        return [4 /*yield*/, this.api.uploadFile(__1.EndpointUrls.Events + "/" + this.eventId + "/Files", this.fileUploadField.value)];
                    case 2:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.EventFilesTileActions.FileUploaded);
                        this.processLoadData();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to Upload',
                            message: 'An error occurred while trying to upload the file.'
                        });
                        this.observationProvider.notifyObservers(__1.EventFilesTileActions.FileUploadError);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'File Invalid',
                            message: this.fileUploadField.errorMessage
                        });
                        this.observationProvider.notifyObservers(__1.EventFilesTileActions.FileUploadError);
                        _a.label = 6;
                    case 6:
                        this.fileUploadField.clearValue();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.alert.hide();
                        _b = (_a = this.fileDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Events + "/" + this.eventId + "/Files")];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildDownloadButton = function (item) {
            if (!_this.downloadButtons[item.form.model.id]) {
                var buttonText = __1.StringUtils.truncate(item.form.model.fileName, 45, true);
                _this.downloadButtons[item.form.model.id] = new __1.DownloadButton({
                    api: _this.api,
                    text: buttonText,
                    url: _this.api.getEventFileDownloadUrl(item.form.model.eventId, item.form.model.id)
                });
            }
            return _this.downloadButtons[item.form.model.id];
        };
        _this.buildDeleteButton = function (item) {
            if (!_this.canEdit) {
                return null;
            }
            return new button_1.default({
                leftIcon: __1.Icons.Minus,
                onClick: function () { return _this.promptToConfirmDelete(item); }
            });
        };
        _this.getIcon = function (item) {
            if (!item) {
                return null;
            }
            switch (item.form.model.fileType) {
                case 'image/jpeg':
                case 'image/png':
                case 'image/bmp':
                case 'image/jpg':
                case 'image/gif':
                    return __1.Icons.Image;
                case 'application/pdf':
                    return __1.Icons.Pdf;
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.ms-word':
                    return __1.Icons.Word;
                case 'text/csv':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.ms-excel':
                    return __1.Icons.Excel;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                case 'application/vnd.ms-powerpoint':
                    return __1.Icons.PowerPoint;
                default:
                    return __1.Icons.File;
            }
        };
        _this.cancelDeletePrompt = function () {
            _this.fileItemToDelete = null;
        };
        _this.promptToConfirmDelete = function (item) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.fileItemToDelete = item;
                this.confirmationPrompt.setMessage('Are you sure you want to delete the file ' + item.form.model.fileName + '?');
                this.confirmationPrompt.show();
                return [2 /*return*/];
            });
        }); };
        _this.deleteFile = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.confirmationPrompt.hide();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.delete(__1.EndpointUrls.Events + "/" + this.eventId + "/Files", this.fileItemToDelete.form.model.id)];
                    case 2:
                        _a.sent();
                        this.fileItemToDelete.form.model.isRemoved = true;
                        this.processLoadData();
                        this.observationProvider.notifyObservers(__1.EventFilesTileActions.FileDeleted);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to Delete',
                            message: 'An error occurred while trying to delete the file'
                        });
                        this.observationProvider.notifyObservers(__1.EventFilesTileActions.FileDeleteError);
                        return [3 /*break*/, 4];
                    case 4:
                        this.fileItemToDelete = null;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.update = function () { };
        _this.downloadButtons = [];
        _this._helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.FilesTile, null, navigator, api);
        _this.fileDataList = new __1.DataList(function () { return []; }, canEdit, 'Upload File', 'There are now files uploaded to this event', 'No Files Found');
        _this.fileUploadField = new __1.BinaryFileInputField('fileToUpload', 'Upload File', false);
        _this.fileUploadField.setVisibility(canEdit);
        _this.fileUploadField.observationProvider.observe(_this.uploadFile, __1.FieldActions.ValueChanged);
        _this.alert = new __1.Alert();
        _this.confirmationPrompt = new __1.ConfirmationPrompt(true, 'Delete File', 'Are you sure you want to delete this file?', 'Delete', 'Cancel');
        _this.confirmationPrompt.observationProvider.observe(_this.deleteFile, __1.ConfirmationPromptActions.Confirmed);
        _this.confirmationPrompt.observationProvider.observe(_this.cancelDeletePrompt, __1.ConfirmationPromptActions.Cancelled);
        return _this;
    }
    return EventFilesTile;
}(__1.EventLandingTile));
exports.default = EventFilesTile;
