import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import EventOverviewProps from './eventOverviewProps'
import Field from '../fields/field';
import Modal from '../containers/modal';
import EventOverviewLayout from './eventOverviewLayout';
import ButtonColors from '../buttons/buttonColors';
import Button from '../buttons/button';

class EventOverview extends ObservingComponent<EventOverviewProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.eventOverview.observationProvider);
    }

    public render({ eventOverview }: EventOverviewProps): JSX.Element {
        return eventOverview && eventOverview.isOpen &&
            <Modal
                close={eventOverview.goBack}
                contentClassName="bg-white"
                heading="Event Overview"
                headerButtons={[
                    <Field
                            className="mr-1 v-center no-label"
                            field={eventOverview.businessAreaFilter} />,

                    <Button
                        className="btn-icon"
                        color={ButtonColors.White}
                        button={eventOverview.refreshButton} />
                ]}>

                <EventOverviewLayout
                    className="mt-1"
                    isLoaded={eventOverview.isLoaded}
                    eventOverviewData={eventOverview.eventOverviewData}
                    isEditAttendeeMode={eventOverview.isEditAttendeeMode}
                    editAttendeesButton={eventOverview.editAttendeesButton}
                    saveAttendeesButton={eventOverview.saveAttendeesButton}
                    addAttendeesButton={eventOverview.addAttendeesButton}
                    userSearchField={eventOverview.userSearchField}
                    buildRemoveAttendeeButton={eventOverview.buildRemoveAttendeeButton}
                    isEditInventoryRequestMode={eventOverview.isEditInventoryRequestMode}
                    editInventoryRequestButton={eventOverview.editInventoryRequestButton}
                    saveInventoryRequestButton={eventOverview.saveInventoryRequestButton}
                    addInventoryRequestButton={eventOverview.addInventoryRequestButton}
                    inventoryRequestSearchField={eventOverview.inventoryRequestSearchField}
                    buildRemoveInventoryRequestButton={eventOverview.buildRemoveInventoryRequestButton}
                    unfulfilledInventoryRequestNoteFields={eventOverview.unfulfilledInventoryRequestNoteFields}
                    fulfilledInventoryRequestNoteFields={eventOverview.fulfilledInventoryRequestNoteFields} />
            </Modal>
        }
}

export default EventOverview;
