"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var components_1 = require("../../components");
var TenantDetailPage = /** @class */ (function (_super) {
    __extends(TenantDetailPage, _super);
    function TenantDetailPage(api, navigator, tenantId) {
        var _this = _super.call(this, __1.PageTypes.TenantDetailPage, tenantId ? 'Edit Tenant' : 'New Tenant') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.tenantId = tenantId;
        _this.buildForm = function () {
            _this._headerLogoImageFileInput = new components_1.FileInputField('inputField', 'Set Header Image', false, components_1.FileInputFileTypes.Images);
            _this._headerLogoImageFileInput.observationProvider.observe(_this.setHeaderImage, components_1.FieldActions.ValueChanged);
            var notificationEnabledField = new __1.CheckBoxField('systemNotificationEnabled', 'Alert Enabled');
            var notificationMessageField = new __1.TextField('systemNotificationMessage', 'Message', true, 2000, 'required', null, components_1.FieldType.TextArea);
            var notificationCountField = new components_1.IntField('systemNotificationDisplayCount', 'Number of times to display alert', true, 'required');
            _this._form = new __1.Form([
                new __1.TextField('name', 'Name', true, 250, 'required'),
                new __1.CheckBoxField('inventoryEnabled', 'Inventory Enabled'),
                new __1.CheckBoxField('travelAndLodgingEnabled', 'Travel & Hotel Enabled'),
                new __1.CheckBoxField('meetingNotesEnabled', 'Meeting Notes Enabled'),
                new __1.CheckBoxField('costManagementEnabled', 'Cost Management Enabled'),
                new __1.CheckBoxField('diningEnabled', 'Dining Enabled'),
                new __1.TextField('administratorName', 'System Admin Name', false, 100, 'required'),
                new components_1.PhoneField('administratorPhone', 'Admin Phone', false, 'required'),
                new components_1.EmailField('administratorEmail', false, 'Admin  Email'),
                new __1.CheckBoxField('productKnowledgeRequired', 'User Area of Expertise Required'),
                new __1.CheckBoxField('titleRequired', 'User Title Required'),
                notificationEnabledField,
                notificationMessageField,
                notificationCountField,
                new __1.CheckBoxField('enableAttendeeHeadCount', 'Enable Attendee Head Count Tracking')
            ]);
            notificationEnabledField.observationProvider.observe(function () {
                notificationMessageField.setVisibility(notificationEnabledField.value === true);
                notificationCountField.setVisibility(notificationEnabledField.value === true);
            }, components_1.FieldActions.ValueChanged);
        };
        _this.setHeaderImage = function () {
            var imageUrl = _this._headerLogoImageFileInput.value;
            if (!imageUrl) {
                return;
            }
            _this._form.model.headerLogoImageUrl = imageUrl;
            _this._headerLogoImageFileInput.clearValue();
            _this.observationProvider.notifyObservers(__1.TenantDetailPageActions.HeaderImageSet);
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var tenant;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.TenantDetailPageActions.DataLoading);
                        if (!this.tenantId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Tenants + '/' + this.tenantId)];
                    case 1:
                        tenant = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.api.get(__1.EndpointUrls.Tenants + '/default')];
                    case 3:
                        tenant = _a.sent();
                        _a.label = 4;
                    case 4:
                        this._form.setModel(tenant);
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.TenantDetailPageActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._form.submit();
                        this.alert.hide();
                        if (!this._form.isValid()) {
                            this.saveButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this._form.isDisabled = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!this.tenantId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Tenants, this.tenantId, this._form.model)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.api.post(__1.EndpointUrls.Tenants, this._form.model)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.navigator.goToTenantList();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        this._form.isDisabled = false;
                        this.saveButton.setProcessing(false);
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Save',
                            message: 'An error occurred while trying to save the tenant record'
                        });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new __1.ObservationProvider();
        _this.cancelButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.navigator.goToTenantList
        });
        _this.saveButton = new button_1.default({
            text: 'Save',
            onClick: _this.save,
            processingText: 'Saving...'
        });
        _this.buildForm();
        _this.loadData();
        return _this;
    }
    Object.defineProperty(TenantDetailPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TenantDetailPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TenantDetailPage.prototype, "headerLogoImageFileInput", {
        get: function () {
            return this._headerLogoImageFileInput;
        },
        enumerable: true,
        configurable: true
    });
    return TenantDetailPage;
}(__1.BasePage));
exports.default = TenantDetailPage;
