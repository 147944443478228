"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Icons;
(function (Icons) {
    Icons[Icons["None"] = 0] = "None";
    Icons[Icons["Trash"] = 1] = "Trash";
    Icons[Icons["User"] = 2] = "User";
    Icons[Icons["List"] = 3] = "List";
    Icons[Icons["Logout"] = 4] = "Logout";
    Icons[Icons["ChevronLeft"] = 5] = "ChevronLeft";
    Icons[Icons["Alert"] = 6] = "Alert";
    Icons[Icons["Minus"] = 7] = "Minus";
    Icons[Icons["Plus"] = 8] = "Plus";
    Icons[Icons["ChevronDown"] = 9] = "ChevronDown";
    Icons[Icons["ChevronUp"] = 10] = "ChevronUp";
    Icons[Icons["Filter"] = 11] = "Filter";
    Icons[Icons["Phone"] = 12] = "Phone";
    Icons[Icons["Email"] = 13] = "Email";
    Icons[Icons["Expand"] = 14] = "Expand";
    Icons[Icons["Compress"] = 15] = "Compress";
    Icons[Icons["Edit"] = 16] = "Edit";
    Icons[Icons["Refresh"] = 17] = "Refresh";
    Icons[Icons["Camera"] = 18] = "Camera";
    Icons[Icons["Clock"] = 19] = "Clock";
    Icons[Icons["Gear"] = 20] = "Gear";
    Icons[Icons["Download"] = 21] = "Download";
    Icons[Icons["Checked"] = 22] = "Checked";
    Icons[Icons["Unchecked"] = 23] = "Unchecked";
    Icons[Icons["Duplicate"] = 24] = "Duplicate";
    Icons[Icons["Key"] = 25] = "Key";
    Icons[Icons["SortUp"] = 26] = "SortUp";
    Icons[Icons["SortDown"] = 27] = "SortDown";
    Icons[Icons["Hamburger"] = 28] = "Hamburger";
    Icons[Icons["ChevronRight"] = 29] = "ChevronRight";
    Icons[Icons["Calendar"] = 30] = "Calendar";
    Icons[Icons["Check"] = 31] = "Check";
    Icons[Icons["Image"] = 32] = "Image";
    Icons[Icons["Pdf"] = 33] = "Pdf";
    Icons[Icons["Word"] = 34] = "Word";
    Icons[Icons["Excel"] = 35] = "Excel";
    Icons[Icons["PowerPoint"] = 36] = "PowerPoint";
    Icons[Icons["File"] = 37] = "File";
    Icons[Icons["UserGroup"] = 38] = "UserGroup";
    Icons[Icons["UserAlt"] = 39] = "UserAlt";
    Icons[Icons["BusinessCard"] = 40] = "BusinessCard";
    Icons[Icons["Notification"] = 41] = "Notification";
    Icons[Icons["Info"] = 42] = "Info";
    Icons[Icons["Close"] = 43] = "Close";
    Icons[Icons["Eye"] = 44] = "Eye";
    Icons[Icons["EyeSlash"] = 45] = "EyeSlash";
    Icons[Icons["Question"] = 46] = "Question";
    Icons[Icons["Meeting"] = 47] = "Meeting";
    Icons[Icons["Report"] = 48] = "Report";
})(Icons || (Icons = {}));
exports.default = Icons;
