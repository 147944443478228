"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserGroupDetailPageActions;
(function (UserGroupDetailPageActions) {
    UserGroupDetailPageActions["RecordAdding"] = "RecordAdding";
    UserGroupDetailPageActions["RecordAdded"] = "RecordAdded";
    UserGroupDetailPageActions["RecordEdited"] = "RecordEdited";
    UserGroupDetailPageActions["RecordEditing"] = "RecordEditing";
    UserGroupDetailPageActions["RecordLoaded"] = "RecordLoaded";
    UserGroupDetailPageActions["RecordDeleted"] = "RecordDeleted";
})(UserGroupDetailPageActions || (UserGroupDetailPageActions = {}));
exports.default = UserGroupDetailPageActions;
