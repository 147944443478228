"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var numberDropdownField_1 = require("./numberDropdownField");
var pages_1 = require("../../pages");
var MeetingStatusField = /** @class */ (function (_super) {
    __extends(MeetingStatusField, _super);
    function MeetingStatusField(key, label, isRequired, initialValue, noSelectionLabel) {
        var _this = _super.call(this, key, label, isRequired, [], 'Meeting satus is required', initialValue, _1.FieldType.DropDown, noSelectionLabel) || this;
        _this.key = key;
        _this.setOptions([
            { label: 'Finalized', value: pages_1.MeetingStatuses.Finalized },
            { label: 'Working', value: pages_1.MeetingStatuses.Working },
            { label: 'Requested', value: pages_1.MeetingStatuses.Requested, disabled: true }
        ]);
        return _this;
    }
    MeetingStatusField.prototype.setValue = function (value) {
        _super.prototype.setValue.call(this, value);
    };
    Object.defineProperty(MeetingStatusField.prototype, "isConfirmDisabled", {
        set: function (isDisabled) {
            this.options[0].disabled = isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingStatusField.prototype, "isWorkingDisabled", {
        set: function (isDisabled) {
            this.options[1].disabled = isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MeetingStatusField.prototype, "isRequestDisabled", {
        set: function (isDisabled) {
            this.options[2].disabled = isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    return MeetingStatusField;
}(numberDropdownField_1.default));
exports.default = MeetingStatusField;
