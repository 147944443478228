"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserDetailPageActions;
(function (UserDetailPageActions) {
    UserDetailPageActions["RecordDeleting"] = "RecordDeleting";
    UserDetailPageActions["RecordDeactivated"] = "RecordDeleted";
    UserDetailPageActions["RecordActivated"] = "RecordActivated";
    UserDetailPageActions["RecordLoading"] = "RecordLoading";
    UserDetailPageActions["RecordLoaded"] = "RecordLoaded";
    UserDetailPageActions["RecordLoadFailure"] = "RecordLoadFailure";
    UserDetailPageActions["Cancel"] = "Cancel";
    UserDetailPageActions["RecordAdding"] = "RecordAdding";
    UserDetailPageActions["RecordAdded"] = "RecordAdded";
    UserDetailPageActions["RecordEditing"] = "RecordEditing";
    UserDetailPageActions["RecordEdited"] = "RecordEdited";
    UserDetailPageActions["Unlocked"] = "Unlocked";
    UserDetailPageActions["Unlocking"] = "Unlocking";
    UserDetailPageActions["ResettingToDefaultPassword"] = "ResettingToDefaultPassword";
    UserDetailPageActions["ResetToDefaultPassword"] = "ResetToDefaultPassword";
    UserDetailPageActions["ResetPasswordPromptChanged"] = "ResetPasswordPromptChanged";
    UserDetailPageActions["SubmissionError"] = "SubmissionError";
    UserDetailPageActions["Saving"] = "Saving";
})(UserDetailPageActions || (UserDetailPageActions = {}));
exports.default = UserDetailPageActions;
