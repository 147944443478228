import { h, Component } from 'preact';
import FormattedTimespanProps from './formattedTimespanProps';
import { DateUtils } from 'core.frontend';

class FormattedTimespan extends Component<FormattedTimespanProps> {

    private format(timespan: string) {
        return DateUtils.formatTimespan(timespan);
    }

    public render({timespan, className }: FormattedTimespanProps) {
        if (!timespan) {
            return <span></span>
        }

        return (
            <span class={className}>
                {this.format(timespan)}
            </span>
        )
    }
}

export default FormattedTimespan;
