import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import GroupContainerProps from './groupContainerProps';

class GroupContainer<T> extends ObservingComponent<GroupContainerProps<T>> {

    public render({ headingTemplate, itemTemplate, arrayGroups, itemWrapperTemplate, subgroupLevel, hideHeaderOnNoGroup, className }: GroupContainerProps<T>): JSX.Element {
        if (!subgroupLevel) {
            subgroupLevel = 1;
        }

        return (
            <div class={className}>
                {arrayGroups && arrayGroups.map((group, index) =>
                    <div class={'sub-' + subgroupLevel}>

                        {(group.groupHeading || !hideHeaderOnNoGroup) && headingTemplate(group, index)}

                        {group.subGroup && group.subGroup.length &&
                            <GroupContainer
                                headingTemplate={headingTemplate}
                                itemTemplate={itemTemplate}
                                arrayGroups={group.subGroup}
                                itemWrapperTemplate={itemWrapperTemplate}
                                subgroupLevel={subgroupLevel + 1}>
                            </GroupContainer>
                        }

                        {(!group.subGroup || !group.subGroup.length) && itemWrapperTemplate(group, (
                            group.data.map((item, index) =>
                                itemTemplate(item, index)
                            )
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default GroupContainer;
