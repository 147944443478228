"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var api_1 = require("../api");
var config_1 = require("../config");
var navigator_1 = require("../navigator");
var pages_1 = require("../pages");
var currentUserManager_1 = require("./currentUserManager");
var __1 = require("..");
var components_1 = require("../components");
var button_1 = require("../components/button/button");
var App = /** @class */ (function () {
    function App(apiPlugin, localStoragePlugin, clientChecker, urlBuilderPlugin) {
        var _this = this;
        this.clientChecker = clientChecker;
        this.urlBuilderPlugin = urlBuilderPlugin;
        this.markAllNotificationsAsRead = function () {
            _this.api.markAllNotificationsAsRead();
            _this._unreadNotificationsCount = 0;
            _this.notifications.forEach(function (notification) { return notification.isRead = true; });
            _this.observationProvider.notifyObservers(_1.AppActions.NotificationsLoaded);
        };
        this.buildNotificationIsReadButton = function (notification, index) {
            return new button_1.default({
                leftIcon: components_1.Icons.Check,
                onClick: function () { return _this.markNotificationAsRead(notification.id, index); }
            });
        };
        this.markNotificationAsRead = function (notificationId, index) {
            if (!_this.notifications[index].isRead) {
                _this.api.post(api_1.EndpointUrls.Notifications + "/MarkAsRead/" + notificationId, null)
                    .then(function () {
                    _this._unreadNotificationsCount--;
                    _this.notifications[index].isRead = true;
                    _this.observationProvider.notifyObservers(_1.AppActions.NotificationsLoaded);
                });
            }
        };
        this.toggleNotificationsDropdown = function () {
            if (!_this._notificationDropdownOpen) {
                _this.openNotificationDropdown();
            }
            else {
                _this.closeNotificationDropdown();
            }
        };
        this.closeNotificationDropdown = function () {
            if (_this._notificationDropdownOpen) {
                _this._notificationDropdownOpen = false;
                _this.notifyOfNotificationsDropdownChange();
            }
        };
        this.openNotificationDropdown = function () {
            if (!_this._notificationDropdownOpen) {
                _this._notifications = [];
                _this._notificationsLoading = true;
                _this._notificationDropdownPage = 1;
                _this._allNotificationsLoaded = false;
                _this.loadNotifications();
                _this._notificationDropdownOpen = true;
                _this.notifyOfNotificationsDropdownChange();
            }
        };
        this.loadNextNotificationPage = function () {
            if (!_this._allNotificationsLoaded) {
                _this._notificationDropdownPage++;
                _this.loadNotifications(_this._notificationDropdownPage);
            }
        };
        this.loadNotifications = function (page) {
            if (page === void 0) { page = 1; }
            if (!_this.currentUserManager.isLoggedIn) {
                return;
            }
            _this.api.get(api_1.EndpointUrls.Notifications + "/GetNotifications/" + page)
                .then(function (pageResponse) {
                _this._notificationsLoading = false;
                for (var i = 0; i < pageResponse.items.length; i++) {
                    _this._notifications.push(pageResponse.items[i]);
                }
                if (!pageResponse.items.length) {
                    _this._allNotificationsLoaded = true;
                }
                var now = new Date();
                _this._notificationsLoadedTime = now.getHours() + ":" + now.getMinutes() + ":00";
                _this._unreadNotificationsCount = pageResponse.totalRecordCount;
                _this.observationProvider.notifyObservers(_1.AppActions.NotificationsLoaded);
            });
        };
        this.notifyOfNotificationsDropdownChange = function () {
            if (_this._notificationDropdownOpen) {
                _this.observationProvider.notifyObservers(_1.AppActions.NotificationsDropdownOpened);
            }
            else {
                _this.observationProvider.notifyObservers(_1.AppActions.NotificationsDropdownClosed);
            }
        };
        this.setProfileButtonVisibility = function () {
            _this.userProfileButton.setVisibility(_this.currentUserManager.userType == api_1.UserTypes.User);
            _this.vendorUserProfileButton.setVisibility(_this.currentUserManager.userType == api_1.UserTypes.VendorUser);
        };
        this.userLoggedOut = function () {
            _this.setProfileButtonVisibility();
            _this.navigator.setDefaultEventId();
            if (_this.navigator.currentPage.pageType !== pages_1.PageTypes.Login) {
                _this.navigator.goToLogin();
            }
        };
        this.goToPasswordReset = function (passwordResetKey) {
            _this.navigator.goToResetPassword({ passwordResetKey: passwordResetKey, passwordExpired: true });
        };
        this.loadCopyrightText = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.observationProvider.notifyObservers(_1.AppActions.CopyrightTextLoading);
                        _a = this;
                        return [4 /*yield*/, this.api.getCopyrightText()];
                    case 1:
                        _a._copyrightText = _b.sent();
                        this.observationProvider.notifyObservers(_1.AppActions.CopyrightTextLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.userLoggedIn = function (upcomingEventId) {
            _this.setProfileButtonVisibility();
            _this.navigator.setUserType(_this.currentUserManager.userType);
            _this.navigator.setDefaultEventId(upcomingEventId);
            _this.navigator.goToDefaultPage();
            _this.loadSystemNotifications();
            _this.logoutButton.setVisibility(!_this.currentUserManager.isSso);
            _this.api.getProfileImageByUserId(_this.currentUserManager.userId)
                .then(function (profileImageUrl) {
                _this.currentUserManager.updateProfileImageUrl(profileImageUrl);
            });
            _this.api.getHeaderLogoImageUrl(_this.currentUserManager.tenantId)
                .then(function (headerLogoImageUrl) {
                _this.currentUserManager.updateHeaderLogoImageUrl(headerLogoImageUrl);
            });
        };
        this.pageChanging = function (pageType) {
            if (_this.currentUserManager.isLoggedIn && (pageType === pages_1.PageTypes.Login ||
                pageType === pages_1.PageTypes.RequestAccess ||
                pageType === pages_1.PageTypes.CompleteAccessRequest ||
                pageType === pages_1.PageTypes.ResetPassword ||
                pageType === pages_1.PageTypes.RequestPasswordReset)) {
                _this.navigator.cancelPageChange();
            }
        };
        this.loadSystemNotifications = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getSystemNotifications()];
                    case 1:
                        _a._systemNotifications = _b.sent();
                        if (this.currentUserManager.swapReplacementEmail) {
                            this._systemNotifications.push({
                                notificationMessage: 'You have logged in with your new email address. Your old email address has been deactivated and has been replaced. Please use ' + this.currentUserManager.email + ' when logging in.',
                                notificationDisplayCount: 1,
                                systemNotificationType: _1.SystemNotificationType.SystemNotification,
                                userId: this.currentUserManager.userId
                            });
                        }
                        this.observationProvider.notifyObservers(_1.AppActions.SystemNotificationsUpdated);
                        return [2 /*return*/];
                }
            });
        }); };
        this.closeSystemNotification = function () {
            if (!_this._systemNotifications.length) {
                return;
            }
            _this._systemNotifications.splice(0, 1);
            _this.observationProvider.notifyObservers(_1.AppActions.SystemNotificationsUpdated);
        };
        this.showNotifications = function () {
            _this.toggleNotificationsDropdown();
        };
        this.showSystemAdminContactInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._systemAdminInfoVisible = true;
                        this.observationProvider.notifyObservers(_1.AppActions.SystemAdminContactInfoShown);
                        if (!!this._systemAdministrator) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.api.getSystemAdminContactInfo()];
                    case 1:
                        _a._systemAdministrator = _b.sent();
                        this.observationProvider.notifyObservers(_1.AppActions.SystemAdminContactInfoLoaded);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        this.hideSystemAdminContactInfo = function () {
            _this._systemAdminInfoVisible = false;
            _this.observationProvider.notifyObservers(_1.AppActions.SystemAdminContactInfoHidden);
        };
        this.openNotification = function (notification, index) {
            _this.markNotificationAsRead(notification.id, index);
            if (notification.returnUrl) {
                _this.navigator.goToUrl(notification.returnUrl);
            }
        };
        this.observationProvider = new __1.ObservationProvider();
        this.config = new config_1.Config();
        this.currentUserManager = new currentUserManager_1.default(localStoragePlugin, clientChecker);
        this.api = new api_1.Api(apiPlugin, this.config, this.currentUserManager);
        this.pageFactory = new pages_1.PageFactory(this.api, this.currentUserManager);
        this.navigator = new navigator_1.Navigator(this.pageFactory, this.config, this.urlBuilderPlugin, this.api);
        this.nav = new _1.Nav(this.currentUserManager, this.navigator, this.api);
        this.navigator.observationProvider.observe(this.nav.closeMenu, navigator_1.NavigatorActions.PageChanged);
        this.navigator.observationProvider.observe(this.nav.closeMenu, navigator_1.NavigatorActions.NavigateToSamePageCanceled);
        this.navigator.observationProvider.observe(this.pageChanging, navigator_1.NavigatorActions.PageChanging);
        this.currentUserManager.observationProvider.observe(this.userLoggedOut, _1.CurrentUserManagerActions.LoggedOut);
        this.currentUserManager.observationProvider.observe(this.goToPasswordReset, _1.CurrentUserManagerActions.RequiringPasswordChange);
        this.currentUserManager.observationProvider.observe(this.userLoggedIn, _1.CurrentUserManagerActions.LoggedIn);
        this._notificationDropdownOpen = false;
        this._notificationsLoading = true;
        this._notificationDropdownPage = 1;
        this._allNotificationsLoaded = false;
        this._notifications = [];
        this.markAllNotificationsAsReadButton = new button_1.default({
            text: 'Mark All as Read',
            onClick: this.markAllNotificationsAsRead
        });
        this.loadCopyrightText();
        this.userMenuButton = new button_1.default({
            onClick: this.navigator.toggleUserDropdown
        });
        this.logoutButton = new button_1.default({
            onClick: this.currentUserManager.logout,
            text: 'Logout',
            leftIcon: components_1.Icons.Logout
        });
        this.userProfileButton = new button_1.default({
            onClick: this.navigator.goToUserProfile,
            leftIcon: components_1.Icons.User,
            text: 'User Profile',
            isVisible: false
        });
        this.notificationsButton = new button_1.default({
            onClick: this.showNotifications,
            leftIcon: components_1.Icons.Notification,
        });
        this.vendorUserProfileButton = new button_1.default({
            onClick: this.navigator.goToVendorUserProfile,
            leftIcon: components_1.Icons.User,
            text: 'User Profile',
            isVisible: false
        });
        this.validClientAlert = new components_1.Alert();
        if (!this.clientChecker.isValidClient()) {
            this.validClientAlert.show({
                alertType: components_1.AlertType.Error,
                heading: this.clientChecker.alertHeading,
                message: this.clientChecker.alertMessage
            });
        }
        if (this.currentUserManager.isLoggedIn) {
            this.setProfileButtonVisibility();
            this.logoutButton.setVisibility(!this.currentUserManager.isSso);
            this.navigator.setUserType(this.currentUserManager.userType);
            this.api.getProfileImageByUserId(this.currentUserManager.userId)
                .then(function (profileImageUrl) {
                _this.currentUserManager.updateProfileImageUrl(profileImageUrl);
            });
            this.api.getHeaderLogoImageUrl(this.currentUserManager.tenantId)
                .then(function (headerLogoImageUrl) {
                _this.currentUserManager.updateHeaderLogoImageUrl(headerLogoImageUrl);
            });
        }
        this.headerHelpButton = new button_1.default({
            leftIcon: components_1.Icons.Question,
            onClick: function () { return _this.navigator.helpListModal.open(pages_1.HelpUrlType.ApplicationHeader); }
        });
        this.closeSystemNotificationButton = new button_1.default({
            text: 'Close',
            onClick: this.closeSystemNotification,
            buttonType: components_1.ButtonTypes.Submit
        });
        try {
            if (window.isNativeApp) {
                this.loadSystemNotifications();
            }
        }
        catch (err) { }
        this.showSystemAdminButton = new button_1.default({
            leftIcon: components_1.Icons.Question,
            onClick: function () { return _this.showSystemAdminContactInfo(); }
        });
        this.loadNotifications();
    }
    Object.defineProperty(App.prototype, "systemAdministrator", {
        get: function () {
            return this._systemAdministrator;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "systemAdminInfoVisible", {
        get: function () {
            return this._systemAdminInfoVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "copyrightText", {
        get: function () {
            return this._copyrightText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "notifications", {
        get: function () {
            return this._notifications;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "notificationsLoadedTime", {
        get: function () {
            return this._notificationsLoadedTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "systemNotifications", {
        get: function () {
            return this._systemNotifications;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "notificationDropdownPage", {
        get: function () {
            return this._notificationDropdownPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "unreadNotificationsCount", {
        get: function () {
            return this._unreadNotificationsCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "notificationsLoading", {
        get: function () {
            return this._notificationsLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(App.prototype, "notificationDropdownOpen", {
        get: function () {
            return this._notificationDropdownOpen;
        },
        enumerable: true,
        configurable: true
    });
    return App;
}());
exports.default = App;
