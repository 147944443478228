"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var components_1 = require("../../components");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var VendorUserListPage = /** @class */ (function (_super) {
    __extends(VendorUserListPage, _super);
    function VendorUserListPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.VendorUserList, 'Vendor User List') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.loadData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.Vendors, _this.table.recordsPerPage, _this.table.currentPage, _this.table.sortExpression, _this.table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.goToNewVendorUser = function () {
            _this.navigator.goToVendorUserDetailPage();
            var detailPage = _this.navigator.currentPage;
            detailPage.form.observationProvider.observe(_this.addRecordToTable, __1.SubmittableFormActions.RecordAdding);
            detailPage.form.observationProvider.observe(_this.recordAddedSuccessfully, __1.SubmittableFormActions.RecordAdded);
        };
        _this.recordAddedSuccessfully = function (record) {
            _this._newlyAddedRecord.id = record.id;
            _this.table.recordAddedSuccessfully();
        };
        _this.addRecordToTable = function (record) {
            _this._newlyAddedRecord = __assign(__assign({}, record), { vendorCompany: '', isActive: true });
            _this.api.getVendorCompanies().then(function (vendorCompanies) {
                _this._newlyAddedRecord.vendorCompany = vendorCompanies.find(function (vc) { return vc.id === _this._newlyAddedRecord.vendorCompanyId; }).name;
                _this.table.insertAddingRecord(_this._newlyAddedRecord);
                _this.navigator.goToVendorUserList();
            });
        };
        _this.goToEditVendorUser = function (vendorUserId) { return function () {
            _this.navigator.goToVendorUserDetailPage(vendorUserId);
            var currentPage = _this.navigator.currentPage;
            currentPage.form.observationProvider.observeOnce(_this.markRecordAsEditing, __1.SubmittableFormActions.RecordEditing);
            currentPage.form.observationProvider.observeOnce(_this.markRecordAsEdited, __1.SubmittableFormActions.RecordEdited);
            currentPage.observationProvider.observeOnce(_this.markRecordAsDeactivated, __1.VendorUserDetailPageActions.RecordDeactivated);
            currentPage.observationProvider.observeOnce(_this.markRecordAsActivated, __1.VendorUserDetailPageActions.RecordActivated);
        }; };
        _this.markRecordAsActivated = function (updatedRecord) {
            _this.table.updateRecord(updatedRecord.id, { isActive: true });
        };
        _this.markRecordAsDeactivated = function (updatedRecord) {
            _this.table.updateRecord(updatedRecord.id, { isActive: false });
        };
        _this.markRecordAsEditing = function (updatedRecord) {
            _this.table.markRecordAsSaving(updatedRecord.id);
            _this.table.updateRecord(updatedRecord.id, updatedRecord);
            _this.navigator.goToVendorUserList();
        };
        _this.markRecordAsEdited = function (record) {
            _this.table.markRecordAsNotSaving(record.id);
            _this.navigator.goToVendorUserList();
        };
        _this.buildEditUserButton = function (vendorUser) {
            return new button_1.default({
                text: vendorUser.firstName + " " + vendorUser.lastName,
                onClick: _this.goToEditVendorUser(vendorUser.id)
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.VendorDetailPage, null, navigator, api);
        _this.table = new __1.Table(_this.loadData, api);
        _this.table.searchBoxPlaceholder = 'By individual\'s name, email or company';
        var filters = [
            new components_1.BooleanDropdownField('isActive', 'Is Active', false),
            new components_1.TextField('name', 'Vendor users name', false, 200),
            new components_1.VendorCompanyDropdown(_this.api, 'vendorCompanyId', false, components_1.VendorCompanyDropdownOptionType.All, null, 'Any'),
            new components_1.BooleanDropdownField('offersHotels', 'Offers Hotels', false),
            new components_1.BooleanDropdownField('offersAuto', 'Offers Transportation', false)
        ];
        var defaultFilters = { isActive: true };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, defaultFilters);
        _this.listOptionSlideContainer.observationProvider.observe(function () { return _this.table.load(); }, components_1.ListOptionSlideContainerActions.OptionsApplied);
        _this.table.load();
        _this.newVendorUserButton = new button_1.default({
            text: 'New Vendor User',
            onClick: _this.goToNewVendorUser,
            leftIcon: __1.Icons.Plus
        });
        return _this;
    }
    Object.defineProperty(VendorUserListPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    return VendorUserListPage;
}(__1.BasePage));
exports.default = VendorUserListPage;
