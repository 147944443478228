import { h, JSX } from 'preact'
import ObservingComponent from '../componentBases/observingComponent';
import CustomerCompanySearchFieldProps from './customerCompanySearchFieldProps';
import FieldLoadingPlaceholder from './fieldLoadingPlaceholder';
import SearchField from './searchField';
import Field from './field';

class CustomerCompanySearchField extends ObservingComponent<CustomerCompanySearchFieldProps> {

    public render({ companySearchField, className, isLoading }: CustomerCompanySearchFieldProps): JSX.Element {

        if(isLoading) {
            return <FieldLoadingPlaceholder className={className} />;
        }

        let searchFieldClassName: string = '';

        if (className && className.indexOf('no-label') >= 0) {
            searchFieldClassName += ' no-label';
        }

        if (className && className.indexOf('stacked-label') >= 0) {
            searchFieldClassName += ' stacked-label';
        }

        if (className && className.indexOf('inline-label') >= 0) {
            searchFieldClassName += ' inline-label';
        }

        return (
            <div class={className + ' v-center flex customer-search'}>
                <SearchField
                    className='v-center fill '
                    inputClassName={searchFieldClassName}
                    searchField={companySearchField} />

                <Field
                    className={searchFieldClassName + ' v-center fill'}
                    field={companySearchField.newCompanyNameField} />
            </div>
        );
    }
}

export default CustomerCompanySearchField;
