import { h, JSX, Fragment}from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import { HeaderProps } from './headerProps';
import UserDropdown from './userDropdown';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import CurrentUserAvatar from '../avatar/currentUserAvatar';
import NotificationDropdown from './notificationDropdown';
import { AppActions } from 'core.frontend';

class Header extends ObservingComponent<HeaderProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.app.currentUserManager.observationProvider);
        this.registerUpdateObserver(this.props.app.observationProvider, AppActions.NotificationsLoaded);
    }

    public render({ app }: HeaderProps): JSX.Element {

        return (
            <header
                class="app-header"
                style={`background-image: url(${app.currentUserManager.headerLogoImageUrl})`}>

                <Button
                    className="btn-icon nav-menu-button"
                    color={ButtonColors.Secondary}
                    button={app.nav.openButton} />

                <ul class="right-nav">
                    {/* <li class="flex">
                        <Button
                            className="btn-icon btn-lg"
                            button={app.headerHelpButton} />

                    </li> */}
                    <li class="notifications-menu-wrapper">
                        <Button
                            id="notifications-button"
                            className={"notifications-button mr-p5 btn-icon " + (app.unreadNotificationsCount > 0 ? 'active' : '')}
                            color={ButtonColors.Secondary}
                            button={app.notificationsButton}
                            stopPropagation={true}
                            children={app.unreadNotificationsCount ? <span class="badge">{app.unreadNotificationsCount}</span> : <Fragment></Fragment> }/>

                        <NotificationDropdown app={app} />
                    </li>
                    <li>
                        <Button
                            id="loggedInUserDropdownButton"
                            className="btn-icon p-0"
                            color={ButtonColors.Secondary}
                            button={app.userMenuButton}
                            stopPropagation={true}>
                                <span>
                                    <CurrentUserAvatar currentUserManager={app.currentUserManager} />
                                    <span class="ml-p5 d-none d-md-inline">{app.currentUserManager.userFullName}</span>
                                </span>
                        </Button>
                    </li>
                </ul>

                <UserDropdown app={app} />
            </header>
        );
    }
}

export default Header;
