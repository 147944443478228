"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var components_1 = require("../../../../../components");
var api_1 = require("../../../../../api");
var button_1 = require("../../../../../components/button/button");
var InteractiveVenueAssigner = /** @class */ (function () {
    function InteractiveVenueAssigner(_api, _eventId, _isVisible) {
        var _this = this;
        if (_isVisible === void 0) { _isVisible = true; }
        this._api = _api;
        this._eventId = _eventId;
        this._isVisible = _isVisible;
        this.hideMeetingDetail = function () {
            _this._meetingDetail = null;
            _this.observationProvider.notifyObservers(__1.InteractiveVenueAssignerActions.MeetingDetailCleared);
        };
        this.buildForm = function () { return __awaiter(_this, void 0, void 0, function () {
            var startTimeField, endTimeField, statusField, foodField, venues, _i, venues_1, venue;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._timeOptions = [
                            { label: '0000', value: '00:00' },
                            { label: '0030', value: '00:30' },
                            { label: '0100', value: '01:00' },
                            { label: '0130', value: '01:30' },
                            { label: '0200', value: '02:00' },
                            { label: '0230', value: '02:30' },
                            { label: '0300', value: '03:00' },
                            { label: '0330', value: '03:30' },
                            { label: '0400', value: '04:00' },
                            { label: '0430', value: '04:30' },
                            { label: '0500', value: '05:00' },
                            { label: '0530', value: '05:30' },
                            { label: '0600', value: '06:00' },
                            { label: '0630', value: '06:30' },
                            { label: '0700', value: '07:00' },
                            { label: '0730', value: '07:30' },
                            { label: '0800', value: '08:00' },
                            { label: '0830', value: '08:30' },
                            { label: '0900', value: '09:00' },
                            { label: '0930', value: '09:30' },
                            { label: '1000', value: '10:00' },
                            { label: '1030', value: '10:30' },
                            { label: '1100', value: '11:00' },
                            { label: '1130', value: '11:30' },
                            { label: '1200', value: '12:00' },
                            { label: '1230', value: '12:30' },
                            { label: '1300', value: '13:00' },
                            { label: '1330', value: '13:30' },
                            { label: '1400', value: '14:00' },
                            { label: '1430', value: '14:30' },
                            { label: '1500', value: '15:00' },
                            { label: '1530', value: '15:30' },
                            { label: '1600', value: '16:00' },
                            { label: '1630', value: '16:30' },
                            { label: '1700', value: '17:00' },
                            { label: '1730', value: '17:30' },
                            { label: '1800', value: '18:00' },
                            { label: '1830', value: '18:30' },
                            { label: '1900', value: '19:00' },
                            { label: '1930', value: '19:30' },
                            { label: '2000', value: '20:00' },
                            { label: '2030', value: '20:30' },
                            { label: '2100', value: '21:00' },
                            { label: '2130', value: '21:30' },
                            { label: '2200', value: '22:00' },
                            { label: '2230', value: '22:30' },
                            { label: '2300', value: '23:00' },
                            { label: '2330', value: '23:30' },
                            { label: '2400', value: '23:59' }
                        ];
                        this._dateFieldDropdown = new components_1.MeetingDateDropdownField(this._api, this._eventId, 'date', 'Date', true, 'required');
                        this._dateFieldDropdown.observationProvider.observe(function () {
                            if (_this._dateFieldDropdown.options.length > 0) {
                                _this._dateFieldDropdown.setValue(_this._dateFieldDropdown.options[0].value);
                            }
                        }, components_1.FieldActions.OptionsLoaded);
                        this._dateFieldDropdown.observationProvider.observeOnce(this.checkInitializationComplete, components_1.FieldActions.ValueChanged);
                        startTimeField = new components_1.StringDropdownField('startTime', 'Start Time', false, this._timeOptions, 'required', '08:00');
                        endTimeField = new components_1.StringDropdownField('endTime', 'End Time', false, this._timeOptions, 'required', '22:00');
                        statusField = new components_1.MeetingStatusField('status', 'Status', false, null, 'All');
                        foodField = new components_1.StringDropdownField('foodNeeded', 'Food', false, [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }], 'required', null, components_1.FieldType.DropDown, 'All');
                        startTimeField.observationProvider.observe(this.checkInitializationComplete, components_1.FieldActions.ValueChanged);
                        endTimeField.observationProvider.observe(this.checkInitializationComplete, components_1.FieldActions.ValueChanged);
                        statusField.observationProvider.observe(this.checkInitializationComplete, components_1.FieldActions.ValueChanged);
                        foodField.observationProvider.observe(this.checkInitializationComplete, components_1.FieldActions.ValueChanged);
                        this._meetingCategoryDropdown = new components_1.MeetingCategoryField(this._api, 'meetingCategoryId', 'Category', false, 'required', null, 'All');
                        this._meetingCategoryDropdown.observationProvider.observe(this.checkInitializationComplete, components_1.FieldActions.ValueChanged);
                        this._form = new __1.Form([
                            this._dateFieldDropdown,
                            startTimeField,
                            endTimeField,
                            statusField,
                            foodField,
                            this._meetingCategoryDropdown
                        ]);
                        this._venueCheckboxes = [];
                        return [4 /*yield*/, this._api.getVenues(this._eventId)];
                    case 1:
                        venues = _a.sent();
                        for (_i = 0, venues_1 = venues; _i < venues_1.length; _i++) {
                            venue = venues_1[_i];
                            this._venueCheckboxes.push(new components_1.CheckBoxField(venue.id.toString(), venue.name, false, null, true));
                        }
                        this._venueCheckboxes.push(new components_1.CheckBoxField('-1', 'Unassigned', false, null, true));
                        this.checkInitializationComplete();
                        return [2 /*return*/];
                }
            });
        }); };
        this.checkInitializationComplete = function () {
            if (_this._venueCheckboxes.length &&
                _this._dateFieldDropdown.options.length &&
                _this._dateFieldDropdown.value &&
                _this._meetingCategoryDropdown.options.length) {
                _this._isInitialized = true;
            }
            if (_this._isInitialized) {
                for (var _i = 0, _a = _this._venueCheckboxes; _i < _a.length; _i++) {
                    var checkbox = _a[_i];
                    checkbox.observationProvider.observe(_this.loadMeetings, components_1.FieldActions.ValueChanged);
                }
                _this._dateFieldDropdown.observationProvider.observe(_this.loadMeetings, components_1.FieldActions.ValueChanged);
                _this._meetingCategoryDropdown.observationProvider.observe(_this.loadMeetings, components_1.FieldActions.ValueChanged);
                _this.loadMeetings();
            }
        };
        this.loadMeetingDetail = function (meetingId) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.InteractiveVenueAssignerActions.MeetingDetailLoading);
                        _a = this;
                        return [4 /*yield*/, this._api.get(api_1.EndpointUrls.Events + "/" + this._eventId + "/MeetingTableItemDetail/" + meetingId)];
                    case 1:
                        _a._meetingDetail = _b.sent();
                        this.observationProvider.notifyObservers(__1.InteractiveVenueAssignerActions.MeetingDetailLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadMeetings = function () { return __awaiter(_this, void 0, void 0, function () {
            var options, _a, i, timeOption, startTime, endTime;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._form.submit();
                        options = this._form.model;
                        options.venueIds = this._venueCheckboxes
                            .filter(function (checkbox) { return checkbox.value === true; })
                            .map(function (checkbox) { return parseInt(checkbox.key); });
                        this._meetingDetail = null;
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.InteractiveVenueAssignerActions.MeetingsLoaded);
                        _a = this;
                        return [4 /*yield*/, this._api.get(api_1.EndpointUrls.Events + '/' + this._eventId + '/InteractiveVenueAssignerMeetings', options)];
                    case 1:
                        _a._meetings = _b.sent();
                        this._timeSlots = [];
                        for (i = 0; i < this.timeOptions.length; i++) {
                            timeOption = this.timeOptions[i].label;
                            startTime = this._form.fields[1].value.replace(':', '');
                            endTime = this._form.fields[2].value.replace(':', '');
                            if (timeOption === startTime || this._timeSlots.length > 0) {
                                if (timeOption > endTime) {
                                    break;
                                }
                                this._timeSlots.push(timeOption);
                            }
                        }
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.InteractiveVenueAssignerActions.MeetingsLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.toggleAllVenues = function () {
            var allChecked = true;
            for (var i = 0; i < _this.venueCheckboxes.length; i++) {
                if (!_this.venueCheckboxes[i].value) {
                    allChecked = false;
                    break;
                }
            }
            _this.venueCheckboxes.map(function (venueCheckbox) {
                venueCheckbox.setValue(!allChecked);
            });
        };
        this.changeVenue = function (meetingId, venueId) {
            if (venueId < 0) {
                venueId = null;
            }
            var meeting = _this._meetings.find(function (meeting) { return meeting.id == meetingId; });
            if (meeting) {
                meeting.venueId = venueId;
                _this.observationProvider.notifyObservers(__1.InteractiveVenueAssignerActions.VenueChanged);
            }
        };
        this.buildMeetingDetailButton = function (meetingId) {
            return new button_1.default({
                leftIcon: components_1.Icons.Info,
                onClick: function () { return _this.loadMeetingDetail(meetingId); }
            });
        };
        this.observationProvider = new __1.ObservationProvider();
        this._isLoading = true;
        this.toggleAllVenuesButton = new button_1.default({
            text: 'Toggle All',
            onClick: this.toggleAllVenues
        });
        this.hideMeetingDetailButton = new button_1.default({
            leftIcon: components_1.Icons.Close,
            onClick: this.hideMeetingDetail
        });
    }
    Object.defineProperty(InteractiveVenueAssigner.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "dateFieldDropdown", {
        get: function () {
            return this._dateFieldDropdown;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "meetingCategoryDropdown", {
        get: function () {
            return this._meetingCategoryDropdown;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        set: function (value) {
            this._isVisible = value;
            if (value && !this._isInitialized) {
                this.buildForm();
            }
            this.observationProvider.notifyObservers(__1.InteractiveVenueAssignerActions.VisibilityChanged);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "meetingDetail", {
        get: function () {
            return this._meetingDetail;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "venueCheckboxes", {
        get: function () {
            return this._venueCheckboxes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "meetings", {
        get: function () {
            return this._meetings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "timeSlots", {
        get: function () {
            return this._timeSlots;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InteractiveVenueAssigner.prototype, "timeOptions", {
        get: function () {
            return this._timeOptions;
        },
        enumerable: true,
        configurable: true
    });
    return InteractiveVenueAssigner;
}());
exports.default = InteractiveVenueAssigner;
