import { h, JSX } from 'preact';
import ObservingComponent from '../../../componentBases/observingComponent';
import TransportationReportPageProps from '../transportationReport/transportationReportPageProps';
import { DateFormats, DailyTransportStatus as FrontEndDailyTransportationStatus} from 'core.frontend';
import FormattedDate from '../../../text/formattedDate';
import ArrivalDepartureType from '../../arrivalDepartureType';
import DailyTransportStatus from '../../dailyTransportStatus';
import Avatar from '../../../avatar/avatar';
import Button from '../../../buttons/button';

class TransportationReportPage extends ObservingComponent<TransportationReportPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: TransportationReportPageProps): JSX.Element {

        return (
            <div class="report">

                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                <h3 class="text-center pl-1 pr-1 mt-1">
                    {!page.isLoading &&
                        <span>{page.reportData.eventName}</span>
                    }
                </h3>

                <div class="text-sm flex mb-1">
                    <div class="fill">
                        Printed by: {page.currentUsersName}
                        <div><b>{page.title}</b></div>
                    </div>
                    <div class="text-right">

                        <FormattedDate
                            date={new Date()}
                            format={DateFormats.dddMMMMDhmmA} />

                        {!page.isLoading &&
                            <div>
                                <span>Total Attendees: </span>
                                {page.reportData.reportDataItems.length}
                            </div>
                        }
                    </div>
                </div>

                {!page.isLoading &&
                    <div class="report-page text-sm">
                        {page.reportData.reportDataItems.map((attendee, index) =>
                            <div>
                                {page.showStatusHeader(index) &&
                                    <div class="text-md flex ml-p5">
                                        <span>TRANSPORTATION REPORT | </span>

                                        <b class="blue">
                                            <DailyTransportStatus
                                                className="text-blue ml-p5"
                                                status={attendee.dailyTransportStatus} />
                                        </b>
                                    </div>
                                }

                                <div class="mb-1 hotel-report-item">

                                    {page.reportOptions.includePhotos &&
                                        <div class="report-item-header flex">
                                            <Avatar
                                                className="profile-pic mb-auto mb-auto mr-p5 mt-0"
                                                url={attendee.profileImageUrl} />

                                            <div class="fill">
                                                <div>
                                                    <b>{attendee.fullName}</b>
                                                </div>
                                                <div>
                                                    {!!attendee.contactPhone && attendee.contactPhone}
                                                    {!attendee.contactPhone && attendee.defaultContactPhone}
                                                </div>
                                                <div>
                                                    {!!attendee.contactEmail && attendee.contactEmail}
                                                    {!attendee.contactEmail && attendee.defaultContactEmail}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {!page.reportOptions.includePhotos &&
                                        <div class="report-item-header">
                                            <b>{attendee.fullName}</b> - {attendee.businessAreaName} / {attendee.departmentName}
                                            <span> | </span>

                                            {!!attendee.contactPhone && attendee.contactPhone}
                                            {!attendee.contactPhone && attendee.defaultContactPhone}
                                            <span> | </span>
                                            {!!attendee.contactEmail && attendee.contactEmail}
                                            {!attendee.contactEmail && attendee.defaultContactEmail}
                                        </div>
                                    }

                                    <div class="flex hotel-report-item-sub-section">
                                        <div class="fill">
                                            <b>ARRIVAL: </b>

                                            {attendee.arrivalInfoSubmitted &&
                                                <div class="ml-1">
                                                    <div>
                                                        <span>Date: </span>

                                                        <b>
                                                            <FormattedDate
                                                                date={attendee.arrivalDateTime}
                                                                format={DateFormats.MMMdYYYY} />
                                                        </b>

                                                        <span> - </span>

                                                        <b>
                                                            <FormattedDate
                                                                date={attendee.arrivalDateTime}
                                                                format={DateFormats.hhmm} />
                                                        </b>
                                                    </div>

                                                    <div>
                                                        <span>Type: </span>
                                                        <b>
                                                            <ArrivalDepartureType type={attendee.arrivalType} />
                                                        </b>
                                                    </div>

                                                    <div>
                                                        <b>{attendee.arrivalCarrier} #{attendee.arrivalNumber} | {attendee.arrivalOrigin} {attendee.arrivalDestination}</b>
                                                    </div>

                                                    <div>Comments: <b>{attendee.arrivalComments}</b></div>
                                                </div>
                                            }

                                            {!attendee.arrivalInfoSubmitted && <i>Unknown</i> }
                                        </div>

                                        <div class="fill">
                                            <b>DEPARTURE: </b>

                                            {attendee.departureInfoSubmitted &&
                                                <div class="ml-1">
                                                    <div>
                                                        <span>Date: </span>

                                                        <b>
                                                            <FormattedDate
                                                                date={attendee.departureDateTime}
                                                                format={DateFormats.MMMdYYYY} />
                                                        </b>

                                                        <span> - </span>

                                                        <b>
                                                            <FormattedDate
                                                                date={attendee.departureDateTime}
                                                                format={DateFormats.hhmm} />
                                                        </b>
                                                    </div>

                                                    <div>
                                                        <span>Type: </span>

                                                        <b>
                                                            <ArrivalDepartureType type={attendee.departureType} />
                                                        </b>
                                                    </div>

                                                    <div>
                                                        <b>{attendee.departureCarrier} #{attendee.departureNumber} | {attendee.departureOrigin} {attendee.departureDestination}</b>
                                                    </div>

                                                    <div>
                                                        Comments:
                                                        <b> {attendee.departureComments}</b>
                                                    </div>
                                                </div>
                                            }

                                            {!attendee.departureInfoSubmitted && <i>Unknown</i>}
                                        </div>
                                    </div>

                                    {attendee.dailyTransportStatus != FrontEndDailyTransportationStatus.NotSubmitted &&
                                        <div class="hotel-report-item-sub-section">
                                            <div>
                                                <b class="fill">DAILY TRANSPORT INFO</b>

                                                {!!attendee.companyProvidedDailyTransportRequired &&
                                                    <div class="flex pl-p5">
                                                        <div class="fill">

                                                            <span>Date: </span>

                                                            <b>
                                                                <FormattedDate
                                                                    date={attendee.companyProvidedDailyTransportStartDate}
                                                                    format={DateFormats.MMMdYYYY} />
                                                            </b>

                                                            <span> - </span>

                                                            <b>
                                                                <FormattedDate
                                                                    date={attendee.companyProvidedDailyTransportEndDate}
                                                                    format={DateFormats.MMMdYYYY} />
                                                            </b>
                                                        </div>

                                                        <b class="text-red">COMPANY PROVIDED SHUTTLE</b>
                                                    </div>
                                                }


                                                {attendee.rentalCarRequired &&
                                                    <div class="pl-p5">
                                                        <div class="flex">
                                                            <div class="fill">
                                                                Dates:
                                                                <b>
                                                                    <FormattedDate
                                                                        date={attendee.rentalCarStartDate}
                                                                        format={DateFormats.MMMdYYYY} />
                                                                </b>

                                                                <span> - </span>

                                                                <b>
                                                                    <FormattedDate
                                                                        date={attendee.rentalCarEndDate}
                                                                        format={DateFormats.MMMdYYYY} />
                                                                </b>
                                                            </div>

                                                            <div>
                                                                <b class="text-red">Individual Transport</b>
                                                                <div>{attendee.rentalCarVehicle}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {attendee.rentalCarSharingWithEventAttendeeId &&
                                                    <div class="pl-p5">
                                                        <span>
                                                            Sharing with &nsbp;
                                                        </span>
                                                        <b>
                                                            {attendee.rentalCarSharingWithEventAttendee}
                                                        </b>
                                                    </div>
                                                }

                                                {!attendee.rentalCarRequired &&
                                                 !attendee.companyProvidedDailyTransportRequired &&
                                                 !attendee.rentalCarSharingWithEventAttendeeId &&

                                                    <div class="pl-p5">
                                                        <b>Daily Transport not Required</b>
                                                    </div>
                                                }

                                                <div class="pl-p5">
                                                    Comments: <b>{attendee.dailyTransportComments}</b>
                                                </div>
                                            </div>

                                            <div class="mt-p5">
                                                <div class="flex">
                                                    <b class="fill">ARRIVAL TRANSPORT NEEDED</b>

                                                    {attendee.airportToHotelTransportRequired && <b class="text-green">YES</b>}
                                                    {!attendee.airportToHotelTransportRequired && <b class="text-red">NO</b>}
                                                </div>

                                                <div class="pl-p5">
                                                    <div>
                                                        <span>Pick-up Location: </span>
                                                        <b>
                                                            {!!attendee.airportToHotelTransportRequired &&
                                                                <span>{attendee.arrivalDestination}</span>
                                                            }

                                                            {!attendee.airportToHotelTransportRequired &&
                                                                <span>None</span>
                                                            }
                                                        </b>
                                                    </div>
                                                    {!!attendee.airportToHotelTransportRequired &&
                                                        <div>
                                                            <span>Hotel:</span>
                                                                {!!attendee.hotelName &&
                                                                    <span>{attendee.hotelName}</span>
                                                                }

                                                                {!attendee.hotelName &&
                                                                    <i>Unknown</i>
                                                                }
                                                        </div>
                                                    }
                                                    <div>
                                                        Comments:
                                                        <b> {attendee.arrivalComments}</b>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-p5">
                                                <div class="flex">
                                                    <b class="fill">DEPARTURE TRANSPORT NEEDED</b>
                                                    {attendee.hotelToAirportTransportRequired && <b class="text-green">YES</b>}
                                                    {!attendee.hotelToAirportTransportRequired && <b class="text-red">NO</b>}
                                                </div>

                                                <div class="pl-p5">
                                                    <div>
                                                        <span>Hotel: </span>
                                                        <b>
                                                            {!!attendee.hotelName &&
                                                                <span>{attendee.hotelName}</span>
                                                            }

                                                            {!attendee.hotelToAirportTransportRequired &&
                                                                <span>None</span>
                                                            }
                                                        </b>
                                                    </div>
                                                    {!!attendee.hotelToAirportTransportRequired &&
                                                        <div>
                                                            <span>Hotel:</span>
                                                            {!!attendee.hotelToAirportTransportRequired &&
                                                                <span>{attendee.departureOrigin}</span>
                                                            }

                                                            {!attendee.hotelToAirportTransportRequired &&
                                                                <i>Unknown</i>
                                                            }
                                                        </div>
                                                    }
                                                    <div>
                                                        Comments:
                                                        <b> {attendee.departureComments}</b>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-p5">
                                                <div class="pl-p5">
                                                    Trans Vendor Comments:
                                                    <b> {attendee.transportationVendorComments}</b>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default TransportationReportPage;
