"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InventorySchedulerPageActions;
(function (InventorySchedulerPageActions) {
    InventorySchedulerPageActions["InventoryRequestedLoaded"] = "InventoryRequestsLoaded";
    InventorySchedulerPageActions["SchedulerDataLoaded"] = "AssignedInventoryItemsLoaded";
    InventorySchedulerPageActions["InventoryRequestCleared"] = "InventoryRequestCleared";
    InventorySchedulerPageActions["InventoryItemSelectionChanged"] = "InventoryItemSelected";
    InventorySchedulerPageActions["ErrorMessageChanged"] = "ErrorMessageChanged";
})(InventorySchedulerPageActions || (InventorySchedulerPageActions = {}));
exports.default = InventorySchedulerPageActions;
