import { TableActions } from 'core.frontend';
import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import TableSortButtonProps from './tableSortButtonProps';
import Button from '../buttons/button';

class TableSortButton extends ObservingComponent<TableSortButtonProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.table.observationProvider, TableActions.SortChanged);
    }

    public render({ label, sortExpression, table }: TableSortButtonProps): JSX.Element {
        if (sortExpression && typeof label === 'string') {
            return (
                <Button button={table.buildSortButton(label, sortExpression)} />
            );
        } else {
            return <span>{label}</span>;
        }
    }
}

export default TableSortButton;
