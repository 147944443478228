import { h, JSX, Component } from 'preact';
import MeetingCreatedByLabelProps from './meetingCreatedByLabelProps';
import EmailButton from '../buttons/emailButton';
import PhoneButton from '../buttons/phoneButton';

class MeetingCreatedByLabel extends Component<MeetingCreatedByLabelProps> {

    public render({ meeting }: MeetingCreatedByLabelProps): JSX.Element {
        return (
            <span class="flex">
                <span class="v-center flex">
                    <span class="v-center">
                        <b>Created By: </b>
                        {meeting.createdByUserName}
                    </span>
                </span>

                <span class="v-center flex">
                    <span class="v-center">
                        <span> - </span>
                        {meeting.createdByDepartment}
                    </span>
                </span>

                <span class="v-center flex">
                    <span class="v-center">&nbsp; - &nbsp;</span>

                    <PhoneButton
                        className="v-center"
                        phoneNumber={meeting.createdByUserPhone} />
                </span>

                <span class="v-center flex">
                    <EmailButton
                        className="v-center"
                        emailAddress={meeting.createdByUserEmail} />
                </span>
            </span>
        );
    }
}

export default MeetingCreatedByLabel;
