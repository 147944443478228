"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var fields_1 = require("../fields");
var VenueMultiselectField = /** @class */ (function (_super) {
    __extends(VenueMultiselectField, _super);
    function VenueMultiselectField(api, eventId, key, label, noSelectionLabel, required, requiredValidationMessage) {
        var _this = _super.call(this, fields_1.FieldType.MultiSelectVenueDropdown, key, label, required, null, requiredValidationMessage) || this;
        _this.api = api;
        _this.eventId = eventId;
        _this.noSelectionLabel = noSelectionLabel;
        _this.proceedSetValue = function (newValue) {
            if (Array.isArray(newValue)) {
                _this._options.map(function (venue) {
                    return venue.selected = newValue.indexOf(venue.venueId) >= 0;
                });
            }
            else {
                var numberValue = parseInt(newValue, 10);
                if (!Number.isNaN(numberValue)) {
                    _this._options.map(function (venue) {
                        return venue.selected = venue.venueId === venue.venueId;
                    });
                }
            }
            _this.setSearchBoxValue();
            _this.observationProvider.notifyObservers(fields_1.FieldActions.ValueChanged);
        };
        _this.search = function (searchPhrase) {
            _this._searchPhrase = searchPhrase;
            _this._searchBoxValue = searchPhrase;
            _this.observationProvider.notifyObservers(fields_1.FieldActions.SearchCompleted);
        };
        _this.focused = function () {
            if (_this.isDisabled) {
                return;
            }
            _this._dropdownOpen = true;
            _this._searchBoxValue = '';
            _this.observationProvider.notifyObservers(fields_1.FieldActions.DropdownOpened);
        };
        _this.blurred = function () {
            _this._dropdownOpen = false;
            _this._searchPhrase = '';
            _this.setSearchBoxValue();
            _this.observationProvider.notifyObservers(fields_1.FieldActions.DropdownClosed);
        };
        _this.setSearchBoxValue = function () {
            var selectedCount = _this.selectedVenues.length;
            if (selectedCount > 1) {
                _this._searchBoxValue = selectedCount + ' venues selected';
            }
            else if (selectedCount === 1) {
                _this._searchBoxValue = _this.selectedVenues[0].name;
            }
            else if (_this.noSelectionLabel) {
                _this._searchBoxValue = _this._options[0].name;
            }
            else {
                _this._searchBoxValue = '';
            }
        };
        _this.loadOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var venues, _i, venues_1, venue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.getVenues(this.eventId)];
                    case 1:
                        venues = (_a.sent()).map(function (venue) {
                            return {
                                venueId: venue.id,
                                name: venue.name
                            };
                        });
                        if (this.noSelectionLabel) {
                            this._options.push({
                                venueId: -1,
                                name: this.noSelectionLabel,
                                selected: true
                            });
                        }
                        for (_i = 0, venues_1 = venues; _i < venues_1.length; _i++) {
                            venue = venues_1[_i];
                            this._options.push(venue);
                        }
                        this.setSearchBoxValue();
                        this._optionsLoaded = true;
                        this.observationProvider.notifyObservers(fields_1.FieldActions.OptionsLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider.observe(_this.focused, fields_1.FieldActions.Focus);
        _this.observationProvider.observe(_this.blurred, fields_1.FieldActions.Blured);
        _this._options = [];
        _this._loadingPromise = _this.loadOptions();
        return _this;
    }
    Object.defineProperty(VenueMultiselectField.prototype, "dropdownOpen", {
        get: function () {
            return this._dropdownOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VenueMultiselectField.prototype, "options", {
        get: function () {
            return this._options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VenueMultiselectField.prototype, "searchPhrase", {
        get: function () {
            return this._searchPhrase;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VenueMultiselectField.prototype, "searchBoxValue", {
        get: function () {
            return this._searchBoxValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VenueMultiselectField.prototype, "selectedVenues", {
        get: function () {
            return this._options.filter(function (option) { return option.selected === true && option.venueId > 0; });
        },
        enumerable: true,
        configurable: true
    });
    VenueMultiselectField.prototype.isValid = function () {
        var isValid = !this.isRequired || this.selectedVenues.length > 0;
        this.setIsValid(isValid);
        return isValid;
    };
    Object.defineProperty(VenueMultiselectField.prototype, "value", {
        get: function () {
            return this.selectedVenues.map(function (venue) { return venue.venueId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VenueMultiselectField.prototype, "errorMessage", {
        get: function () {
            return this.getErrorMessage();
        },
        enumerable: true,
        configurable: true
    });
    VenueMultiselectField.prototype.setValue = function (newValue) {
        var _this = this;
        if (this._optionsLoaded) {
            this.proceedSetValue(newValue);
        }
        else {
            this._loadingPromise.then(function () {
                _this.proceedSetValue(newValue);
            });
        }
    };
    VenueMultiselectField.prototype.toggleSelection = function (option) {
        if (this.isDisabled) {
            return;
        }
        if (option.venueId === -1) {
            this.clearValue();
            return;
        }
        if (this.noSelectionLabel) {
            this._options[0].selected = false;
        }
        if (option.selected) {
            option.selected = false;
        }
        else {
            option.selected = true;
        }
        this.observationProvider.notifyObservers(fields_1.FieldActions.ValueChanged);
    };
    VenueMultiselectField.prototype.clearValue = function () {
        if (this.isDisabled || !this._options.length) {
            return;
        }
        for (var _i = 0, _a = this._options; _i < _a.length; _i++) {
            var option = _a[_i];
            option.selected = false;
        }
        if (this.noSelectionLabel) {
            this._options[0].selected = true;
        }
        this._searchPhrase = '';
        this.setSearchBoxValue();
        this.observationProvider.notifyObservers(fields_1.FieldActions.ValueChanged);
    };
    return VenueMultiselectField;
}(fields_1.BaseField));
exports.default = VenueMultiselectField;
