import { h, JSX } from 'preact';
import { Icons } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import EmailButtonProps from './emailButtonProps';
import Icon from '../icons/icon';

class EmailButton extends ObservingComponent<EmailButtonProps> {

    private emailClicked = (): void => {
        window.open(`mailTo:${this.props.emailAddress}`);
    }

    public render({ className, emailAddress }: EmailButtonProps): JSX.Element {
        return (
            <a
                class={`btn button inline-flex v-center btn-icon ${className}`}
                onClick={this.emailClicked}>

                <span class="btn-content">
                    <Icon icon={Icons.Email} />
                </span>
            </a>
        );
    }
}

export default EmailButton;
