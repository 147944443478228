import UrlAssignerPage from '../meetings/urlAssignerPage';
import MeetingPlacardPage from '../meetingPlacard/meetingPlacardPage';
import VendorEventLandingPage from '../vendorPortal/vendorEventLandingPage';
import VendorEmailTemplatePage from '../vendorPortal/vendorEmailTemplatePage';
import VendorEmailTemplatesPage from '../vendorPortal/vendorEmailTemplatesPage';
import VendorEventList from '../vendorPortal/vendorEventList';
import VendorUserProfile from '../vendorPortal/vendorUserProfile';
import TenantDetailPage from '../tenants/tenantDetailPage';
import TenantList from '../tenants/tenantList';
import MeetingsReportPage from '../events/reports/meetingsReportPage';
import AttendeeReportPage from '../events/reports/attendeeReportPage';
import VendorCompanyDetailPage from '../vendorCompanies/vendorCompanyDetailPage';
import VendorCompaniesPage from '../vendorCompanies/vendorCompaniesPage';
import CustomerCompanyDetailPage from '../customerCompanies/customerCompanyDetailPage';
import { INavigator,
         PageTypes,
         LoginPage as FrontEndLoginPage,
         RequestPasswordResetTextPage as FrontEndRequestPasswordResetTextPage,
         ResetPasswordPage as FrontEndResetPasswordPage,
         UserDetailPage as FrontEndUserDetailPage,
         RequestAccessPage as FrontEndRequestAccessPage,
         CompleteAccessRequestPage as FrontEndCompleteAccessRequestPage,
         EventOverviewReportPage as FrontEndEventOverviewReportPage,
         RequestPasswordResetPage as FrontEndRequestPasswordResetPage,
         DateUtils,
         DateFormats,
         EventReportOptions,
         AttendeeReportPage as FrontEndAttendeeReportPage,
         AttendeeReportOptions,
         MeetingsReportOptions,
         HotelReportOptions,
         TransportationReportOptions,
         AttendeeDataGridReportOptions,
         VenueDataGridReportOptions,
         HotelReportPage as FrontEndHotelReportPage,
         TransportationReportPage as FrontEndTransportationReportPage,
         MeetingsReportPage as FrontEndMeetingsReportPage,
         AttendeeDataGridPage as FrontEndAttendeeDataGridReport,
         VenueDataGridPage as FrontEndVenueDataGridPage,
         GolfCartReportOptions,
         DiningReportOptions,
         SsoLoginPage as FrontEndSsoLoginPage
} from 'core.frontend';
import { h } from 'preact';
import EventListPage from '../events/eventListPage';
import UserListPage from '../users/userListPage';
import LoginPage from '../login/loginPage';
import DeviceRegistrationPage from '../login/DeviceRegistrationPage';
import CustomerListPage from '../customers/customerListPage';
import CustomerDetailPage from '../customers/customerDetailPage';
import RequestPasswordResetPage from '../login/requestPasswordResetPage';
import ResetPasswordPage from '../login/resetPasswordPage';
import UserDetailPage from '../users/userDetailPage';
import RequestAccessPage from '../login/requestAccessPage';
import CompleteAccessRequestPage from '../login/completeAccessRequestPage';
import CustomerCompanyListPage from '../customerCompanies/customerCompaniesListPage';
import NewEventPage from '../events/newEventPage';
import EventLandingPage from '../events/eventLandingPage';
import EventConfigurationPage from '../events/eventConfigurationPage';
import EventOverviewReportPage from '../events/eventOverviewReportPage';
import HotelReportPage from '../events/reports/hotelReport/hotelReportPage';
import TransportationReportPage from '../events/reports/transportationReport/transportationReportPage';
import AttendeeDataGridReportPage from '../events/reports/attendeeDataGridReport/attendeeDataGridReportPage';
import VenueDataGridReportPage from '../events/reports/venueDataGridReport/venueDataGridReportPage';
import HistoryPage from '../history/historyPage';
import VendorUserListPage from '../vendors/vendorUserListPage';
import VendorUserDetailPage from '../vendors/vendorUserDetailPage';
import UserProfilePage from '../users/userProfilePage';
import InventoryListPage from '../inventory/inventoryListPage';
import InventoryDetailPage from '../inventory/inventoryDetailPage';
import InventorySchedulerPage from '../inventory/inventorySchedulerPage';
import InventoryForecastPage from '../inventory/inventoryForecastPage';
import UserGroupListPage from '../userGroups/userGroupListPage';
import UserGroupDetailPage from '../userGroups/userGroupDetailPage';
import SystemSettingsPage from '../settings/systemSettingsPage';
import EventHistoryPage from '../eventHistory/eventHistoryPage';
import TargetType from './targetType';
import RoomStatusDisplayPage from '../roomStatusDisplay/roomStatusDisplayPage';
import EmailTemplatePage from '../emailTemplates/emailTemplatePage';
import EmailTemplatesPage from '../emailTemplates/emailTemplatesPage';
import VendorCompanyInfoPage from '../vendorPortal/companyInfo/vendorCompanyInfoPage';
import UrlBuilderPlugin from './urlBuilderPlugin';
import GolfCartReportPage from '../events/reports/golfCartReport/golfCartReportPage';
import { GolfCartReportPage as FrontEndGolfCartReportPage } from 'core.frontend';
import DiningReportPage from '../events/reports/diningReport/diningReportPage';
import { DiningReportPage as FrontEndDiningReportPage } from 'core.frontend';
import HelpUrlsPage from '../help/helpUrlsPage';
import { HelpUrlsPage as FrontEndHelpUrls } from 'core.frontend';
import { DeviceRegistrationPage as FrontEndDeviceRegistrationPage } from 'core.frontend';
import SsoLoginPage from '../login/ssoLoginPage';

const urlBuilder = new UrlBuilderPlugin();

export default [
    {
        hash: '/login',
        pageType: PageTypes.Login,
        getComponent: (page: FrontEndLoginPage) => <LoginPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToLogin(); },
        buildUrlHash: urlBuilder.buildLoginPageUrl
    },
    {
        hash: '/login/manual',
        pageType: PageTypes.Login,
        getComponent: (page: FrontEndLoginPage) => <LoginPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            navigator.goToLogin();
        },
        buildUrlHash: urlBuilder.buildManualLoginPageUrl
    },
    {
        hash: '/requestPasswordReset',
        pageType: PageTypes.RequestPasswordReset,
        getComponent: (page: FrontEndRequestPasswordResetPage) => <RequestPasswordResetPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToPasswordResetRequest(); },
        buildUrlHash: urlBuilder.buildRequestPasswordResetUrl
    },
    //{
    //     hash: '/requestPasswordReset',
    //     pageType: PageTypes.PasswordResetRequestText,
    //     getComponent: (page: FrontEndRequestPasswordResetTextPage) => <RequestPasswordResetTextPage page={page} />,
    //     navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToPasswordResetRequestText(); },
    //     buildUrlHash: urlBuilder.buildRequestPasswordResetUrl
    // },
    {
        hash: '/requestAccess',
        pageType: PageTypes.RequestAccess,
        getComponent: (page: FrontEndRequestAccessPage) => <RequestAccessPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToRequestAccessPage(); },
        buildUrlHash: urlBuilder.buildRequestAccessPageUrl
    },
    {
        hash: '/passwordReset/',
        pageType: PageTypes.ResetPassword,
        getComponent: (page: FrontEndResetPasswordPage) => <ResetPasswordPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const passwordResetKey = urlHash.replace('#/passwordReset/', '');

            if (passwordResetKey) {
                navigator.goToResetPassword({ passwordResetKey });
            } else {
                navigator.goToLogin();
            }
        },
        buildUrlHash: urlBuilder.buildpasswordResetPageUrl
    },
    {
        hash: '/accessRequest/',
        pageType: PageTypes.CompleteAccessRequest,
        getComponent: (page: FrontEndCompleteAccessRequestPage) => <CompleteAccessRequestPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const requestKey = urlHash.replace('#/accessRequest/', '');

            if (requestKey) {
                navigator.goToCompleteAccessRequestPage(requestKey);
            } else {
                navigator.goToLogin();
            }
        },
        buildUrlHash: urlBuilder.buildCompleteAccessRequestPageUrl
    },
    {
        hash: '/events',
        pageType: PageTypes.EventList,
        getComponent: (page) => <EventListPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToEventList(); },
        buildUrlHash: urlBuilder.buildEventListPageUrl
    },
    {
        hash: '/events/',
        pageType: PageTypes.EventLanding,
        getComponent: (page) => <EventLandingPage page={page} key={page.eventId}/>,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            const eventParams = urlHash
                .replace('#', '')
                .replace('/events/', '')
                .split('/');

            const recordIdStr = eventParams[0];
            const meetingIdStr = eventParams[1];

            if (recordIdStr) {
                const recordId = parseInt(recordIdStr, 10);
                let meetingId: number = null;

                if (meetingIdStr !== null && meetingIdStr !== undefined) {
                    meetingId = parseInt(meetingIdStr);
                }

                navigator.goToEventLanding(recordId, meetingId);
            } else {
                navigator.goToEventList();
            }
        },
        buildUrlHash: urlBuilder.buildEventLandingPageUrl
    },
    {
        hash: '/users',
        pageType: PageTypes.UserList,
        getComponent: (page) => <UserListPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToUserList(); },
        buildUrlHash: urlBuilder.buildUserListPageUrl
    },
    {
        hash: '/users/',
        pageType: PageTypes.UserDetail,
        getComponent: (page) => <UserDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const recordIdStr = urlHash.replace('#/users/', '');

            if (recordIdStr) {
                const recordId = parseInt(recordIdStr, 10);
                navigator.goToUserDetail(recordId);
            } else {
                navigator.goToUserDetail();
            }
        },
        buildUrlHash: urlBuilder.buildUserDetailPageUrl
    },
    {
        hash: '/customers/',
        pageType: PageTypes.CustomerDetail,
        getComponent: (page) => <CustomerDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const userIdStr = urlHash.replace('#/customers/', '');

            if (userIdStr) {
                const userId = parseInt(userIdStr, 10);
                navigator.goToCustomerDetail(userId);
            } else {
                navigator.goToCustomerDetail();
            }
        },
        buildUrlHash: urlBuilder.buildCustomerDetailPageUrl
    },
    {
        hash: '/customers',
        pageType: PageTypes.CustomerList,
        getComponent: (page) => <CustomerListPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToCustomerList(); },
        buildUrlHash: urlBuilder.buildCustomerListPageUrl
    },
    {
        hash: '/history',
        pageType: PageTypes.History,
        getComponent: (page) => <HistoryPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {  },
        buildUrlHash: urlBuilder.buildHistoryPageUrl
    },
    {
        hash: '/vendors',
        pageType: PageTypes.VendorUserList,
        getComponent: (page) => <VendorUserListPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToVendorUserList(); },
        buildUrlHash: urlBuilder.buildVendorUserListPageUrl
    },
    {
        hash: '/vendors/',
        pageType: PageTypes.VendorUserDetail,
        getComponent: (page) => <VendorUserDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/vendors/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToVendorUserDetailPage(id);
            } else {
                navigator.goToVendorUserDetailPage();
            }
        },
        buildUrlHash: urlBuilder.buildVendorUserDetailPageUrl
    },
    {
        hash: '/eventConfiguration/',
        pageType: PageTypes.EventConfiguration,
        getComponent: (page) => <EventConfigurationPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/eventConfiguration/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToEventConfiguration(id);
            } else {
                navigator.goToEventList();
            }
        },
        buildUrlHash: urlBuilder.buildEventConfigurationPageUrl
    },
    {
        hash: '/userProfile',
        pageType: PageTypes.UserProfile,
        getComponent: (page) => <UserProfilePage page={page}></UserProfilePage>,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToUserProfile(); },
        buildUrlHash: urlBuilder.buildUserProfilePageUrl
    },
    {
        hash: '/newEvent',
        pageType: PageTypes.NewEvent,
        getComponent: (page) => <NewEventPage page={page}></NewEventPage>,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToNewEventPage(); },
        buildUrlHash: urlBuilder.buildNewEventPageUrl
    },
    {
        hash: '/inventory',
        pageType: PageTypes.InventoryList,
        getComponent: (page) => <InventoryListPage page={page}></InventoryListPage>,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToInventoryListPage(); },
        buildUrlHash: urlBuilder.buildInventoryListPageUrl
    },
    {
        hash: '/inventory/',
        pageType: PageTypes.InventoryDetailPage,
        getComponent: (page) => <InventoryDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/inventory/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToInventoryDetailPage(id);
            } else {
                navigator.goToInventoryDetailPage();
            }
        },
        buildUrlHash: urlBuilder.buildInventoryDetailPageUrl
    },
    {
        hash: '/userGroups',
        pageType: PageTypes.UserGroupList,
        getComponent: (page) => <UserGroupListPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToUserGroupList() },
        buildUrlHash: urlBuilder.buildUserGroupListPageUrl
    },
    {
        hash: '/userGroups/',
        pageType: PageTypes.UserGroupDetail,
        getComponent: (page) => <UserGroupDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/userGroups/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToUserGroupDetail(id);
            } else {
                navigator.goToUserGroupDetail();
            }
        },
        buildUrlHash: urlBuilder.buildUserGroupDetailPageUrl
    },
    {
        hash: '/inventoryScheduler',
        pageType: PageTypes.InventorySchedulerPage,
        getComponent: (page) => <InventorySchedulerPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToInventoryScheduler() },
        buildUrlHash: urlBuilder.buildInventorySchedulerPageUrl
    },
    {
        target: TargetType.NewTab,
        hash: '/reports/eventOverview',
        pageType: PageTypes.EventOverviewReport,
        getComponent: (page) => <EventOverviewReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/reports/eventOverview', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: EventReportOptions = {
                startDate: DateUtils.getDateFromString(optionParts[0].split('=')[1], DateFormats.MMDDYYYY),
                endDate: DateUtils.getDateFromString(optionParts[1].split('=')[1], DateFormats.MMDDYYYY),
                isInternational: optionParts[2].split('=')[1],
                leadBusinessAreaId: parseInt(optionParts[3].split('=')[1], 10),
                showInventory: optionParts[4].split('=')[1] == 'true',
            } as EventReportOptions;

            navigator.goToEventOverviewReport(reportOptions);
        },
        buildUrlHash: urlBuilder.buildEventOverviewReportPageUrl
    },
    {
        hash: '/inventoryForecast',
        pageType: PageTypes.InventoryForecast,
        getComponent: (page) => <InventoryForecastPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToInventoryForecast() },
        buildUrlHash: urlBuilder.buildInventoryForcastPageUrl
    },
    {
        hash: '/systemSettings',
        pageType: PageTypes.SystemSettings,
        getComponent: (page) => <SystemSettingsPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToSystemSettingsPage() },
        buildUrlHash: urlBuilder.buildSystemSettingsPageUrl
    },
    {
        hash: '/eventHistory',
        pageType: PageTypes.EventHistory,
        getComponent: (page) => <EventHistoryPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { },
        buildUrlHash: urlBuilder.buildEventHistoryPageUrl
    },
    {
        hash: '/customerCompanies',
        pageType: PageTypes.CustomerCompanyList,
        getComponent: (page) => <CustomerCompanyListPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToCustomerCustomerCompanyList()  },
        buildUrlHash: urlBuilder.buildCustomerCompaniesListPageUrl
    },
    {
        hash: '/customerCompany/',
        pageType: PageTypes.CustomerCompanyDetail,
        getComponent: (page) => <CustomerCompanyDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/customerCompany/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToCustomerCompanyDetailPage(id);
            } else {
                navigator.goToCustomerCompanyDetailPage();
            }
        },
        buildUrlHash: urlBuilder.buildCustomerCompanyDetailPageUrl
    },
    {
        hash: '/vendorCompanies',
        pageType: PageTypes.VendorCompaniesPage,
        getComponent: (page) => <VendorCompaniesPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToVendorCompaniesPage() },
        buildUrlHash: urlBuilder.buildVendorCompaniesPageUrl
    },
    {
        hash: '/vendorCompanies/',
        pageType: PageTypes.VendorCompanyDetailPage,
        getComponent: (page) => <VendorCompanyDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/vendorCompany/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToVendorCompanyDetailPage(id);
            } else {
                navigator.goToVendorCompanyDetailPage();
            }
        },
        buildUrlHash: urlBuilder.buildVendorCompanyDetailPageUrl
    },
    {
        hash: '/events/attendeeReport',
        pageType: PageTypes.AttendeeReport,
        getComponent: (page) => <AttendeeReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/attendeeReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: AttendeeReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                eventAttendeeIds: [],
                includePhotos: optionParts[2].split('=')[1] == 'true',
                lineOfBusinessId: parseInt(optionParts[3].split('=')[1], 10)
            } as AttendeeReportOptions;

            reportOptions.eventAttendeeIds = optionParts[1].split('=')[1].split(',').map((eventAttendeeId) => parseInt(eventAttendeeId, 10));

            navigator.goToEventAttendeeReport(reportOptions);
        },
        buildUrlHash: (page: FrontEndAttendeeReportPage) => urlBuilder.buildAttendeeReportPageUrl(page.reportOptions)
    },
    {
        hash: '/events/meetingsReport',
        pageType: PageTypes.MeetingsReportPage,
        getComponent: (page) => <MeetingsReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/meetingsReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: MeetingsReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                startDate: optionParts[1].split('=')[1],
                startTime: optionParts[2].split('=')[1],
                endDate: optionParts[3].split('=')[1],
                endTime: optionParts[4].split('=')[1],
                reportForType: parseInt(optionParts[5].split('=')[1], 10),
                cancellationStatus: parseInt(optionParts[6].split('=')[1], 10),
                eventAttendeeIds: optionParts[7].split('=')[1].split(',').map((eventAttendeeId) => parseInt(eventAttendeeId, 10)),
                defaultMeetingCategoryId: parseInt(optionParts[8].split('=')[1], 10),
                attendeeBusinessAreaId: parseInt(optionParts[9].split('=')[1], 10),
                attendeeDepartmentId: parseInt(optionParts[10].split('=')[1], 10),
                venueId: parseInt(optionParts[11].split('=')[1], 10),
                guestsArrived: optionParts[12].split('=')[1] === 'true' ? true : optionParts[12].split('=')[1] === 'false' ? false : null,
                includeTransportation: optionParts[13].split('=')[1] === 'true',
                scheduledById: parseInt(optionParts[14].split('=')[1], 10),
            } as MeetingsReportOptions;

            navigator.goToMeetingsReportPage(reportOptions);
        },
        buildUrlHash: (page: FrontEndMeetingsReportPage) => urlBuilder.buildMeetingsReportPageUrl(page.reportOptions)
    },
    {
        hash: '/events/hotelReport',
        pageType: PageTypes.HotelReportPage,
        getComponent: (page) => <HotelReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/hotelReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: HotelReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                startDate: optionParts[1].split('=')[1],
                endDate: optionParts[2].split('=')[1],
                reportForType: parseInt(optionParts[3].split('=')[1], 10),
                eventAttendeeIds: optionParts[4].split('=')[1].split(',').map((eventAttendeeId) => parseInt(eventAttendeeId, 10)),
                businessAreaId: parseInt(optionParts[5].split('=')[1], 10),
                departmentId: parseInt(optionParts[6].split('=')[1], 10),
                eventHotelId: parseInt(optionParts[7].split('=')[1], 10),
                lodgingStatus: parseInt(optionParts[8].split('=')[1], 10),
                includePhotos: optionParts[9].split('=')[1] == 'true'
            } as HotelReportOptions;

            navigator.goToHotelReport(reportOptions);
        },
        buildUrlHash: (page: FrontEndHotelReportPage) => urlBuilder.buildHotelReportPageUrl(page.reportOptions)
    },
    {
        hash: '/events/transportationReport',
        pageType: PageTypes.TransportationReportPage,
        getComponent: (page: FrontEndTransportationReportPage) => <TransportationReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/transportationReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: TransportationReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                startDate: optionParts[1].split('=')[1],
                endDate: optionParts[2].split('=')[1],
                reportForType: parseInt(optionParts[3].split('=')[1], 10),
                eventAttendeeIds: optionParts[4].split('=')[1].split(',').map((eventAttendeeId) => parseInt(eventAttendeeId, 10)),
                businessAreaId: parseInt(optionParts[5].split('=')[1], 10),
                departmentId: parseInt(optionParts[6].split('=')[1], 10),
                dailyTransportStatus: parseInt(optionParts[7].split('=')[1], 10),
                reportType: parseInt(optionParts[8].split('=')[1], 10),
                includePhotos: optionParts[9].split('=')[1] == 'true'
            };

            navigator.goToTransportationReport(reportOptions);
        },
        buildUrlHash: (page: FrontEndTransportationReportPage) => urlBuilder.buildTransportationReportPageUrl(page.reportOptions)
    },
    {
        hash: '/tenants',
        pageType: PageTypes.TenantList,
        getComponent: (page) => <TenantList page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToTenantList() },
        buildUrlHash: urlBuilder.buildTenantListPageUrl
    },
    {
        hash: '/tenants/',
        pageType: PageTypes.TenantDetailPage,
        getComponent: (page) => <TenantDetailPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/tenants/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToTenantDetailPage(id);
            } else {
                navigator.goToTenantDetailPage();
            }
        },
        buildUrlHash: urlBuilder.buildTenantDetailPageUrl
    },
    {
        hash: '/vendorPortal/userProfile',
        pageType: PageTypes.VendorUserProfile,
        getComponent: (page) => <VendorUserProfile page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToVendorUserProfile() },
        buildUrlHash: urlBuilder.buildUserProfilePageUrl
    },
    {
        hash: '/vendorPortal/events',
        pageType: PageTypes.VendorEventList,
        getComponent: (page) => <VendorEventList page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToVendorEventList() },
        buildUrlHash: urlBuilder.buildVendorEventListPageUrl
    },
    {
        hash: '/vendorPortal/emailTemplates',
        pageType: PageTypes.VendorEmailTemplates,
        getComponent: (page) => <VendorEmailTemplatesPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToVendorEmailTemplates() },
        buildUrlHash: urlBuilder.buildVendorEmailTemplatesListPageUrl
    },
    {
        hash: '/vendorPortal/emailTemplates/',
        pageType: PageTypes.VendorEmailTemplate,
        getComponent: (page) => <VendorEmailTemplatePage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToVendorEmailTemplate() },
        buildUrlHash: urlBuilder.buildVendorEmailTemplateDetailPageUrl
    },
    {
        hash: '/vendorPortal/companyAndUsers',
        pageType: PageTypes.VendorCompanyInfo,
        getComponent: (page) => <VendorCompanyInfoPage page={page}></VendorCompanyInfoPage>,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToVendorCompanyInfoPage() },
        buildUrlHash: urlBuilder.buildVendorCompanyInfoPageUrl
    },
    {
        hash: '/emailTemplates',
        pageType: PageTypes.EmailTemplates,
        getComponent: (page) => <EmailTemplatesPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToEmailTemplates() },
        buildUrlHash: urlBuilder.buildEmailTemplatesPageUrl
    },
    {
        hash: '/emailTemplates/',
        pageType: PageTypes.EmailTemplate,
        getComponent: (page) => <EmailTemplatePage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/emailTemplate/','');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToEmailTemplate(id);
            } else {
                navigator.goToEmailTemplate();
            }
        },
        buildUrlHash: urlBuilder.buildEmailTemplatePageUrl
    },
    {
        hash: '/vendorPortal/events/',
        pageType: PageTypes.VendorEventLandingPage,
        getComponent: (page) => <VendorEventLandingPage key={page.eventId} page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/vendorPortal/events/', '').split('/');
            const eventId = parseInt(idStr[0], 10);
            const tenantId = parseInt(idStr[1], 10);

            navigator.goToVendorEventLandingPage(eventId, tenantId);
        },
        buildUrlHash: urlBuilder.buildVendorPortalEventLandingPage
    },
    {
        hash: '/meetings/urls/',
        pageType: PageTypes.UrlAssignerPage,
        getComponent: (page) => <UrlAssignerPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/meetings/urls/', '');
            const eventId = parseInt(idStr, 10);

            navigator.goToUrlAssignerPage(eventId);
        },
        buildUrlHash: urlBuilder.buildUrlAssignerPageUrl
    },
    {
        hash: '/displays/meetingPlacard',
        pageType: PageTypes.MeetingPlacard,
        getComponent: (page) => <MeetingPlacardPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            let optionsString = urlHash.replace('#/displays/meetingPlacard', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            const eventId = parseInt(optionParts[0].split('=')[1], 10);
            const passKey = optionParts[1].split('=')[1];

            navigator.goToMeetingPlacardPage(eventId, passKey);
        },
        buildUrlHash: urlBuilder.buildMeetingPlacardPageUrl
    },
    {
        hash: '/displays/roomDisplay',
        pageType: PageTypes.RoomDisplayPage,
        getComponent: (page) => <RoomStatusDisplayPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            let optionsString = urlHash.replace('#/displays/roomDisplay', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            const eventId = parseInt(optionParts[0].split('=')[1], 10);
            const passKey = optionParts[1].split('=')[1];

            navigator.goToRoomDisplayPage(eventId, passKey);
        },
        buildUrlHash: urlBuilder.buildRoomDisplayPageUrl
    },
    {
        hash: '/events/attendeeDataGridReport',
        pageType: PageTypes.AttendeeDataGridReport,
        getComponent: (page: FrontEndAttendeeDataGridReport) => <AttendeeDataGridReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/attendeeDataGridReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: AttendeeDataGridReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                startDate: new Date(optionParts[1].split('=')[1]),
                endDate: new Date(optionParts[2].split('=')[1]),
                eventAttendeeIds: [],
                startTime: optionParts[4].split('=')[1],
                endTime: optionParts[5].split('=')[1],
                includeWorking: optionParts[6].split('=')[1] === 'true',
                includeMeetingTimes: optionParts[7].split('=')[1] === 'true',
                includeVenue: optionParts[8].split('=')[1] === 'true',
                includeRequest: optionParts[9].split('=')[1] === 'true',
                includeMeetingNumber: optionParts[10].split('=')[1] === 'true'
            } as AttendeeDataGridReportOptions;

            reportOptions.eventAttendeeIds = optionParts[3].split('=')[1].split(',').map((userId) => parseInt(userId, 10));

            navigator.goToAttendeeDataGridReportPage(reportOptions);
        },
        buildUrlHash: (page: FrontEndAttendeeDataGridReport) => urlBuilder.buildAttendeeDataGridReportPageUrl(page.reportOptions)
    },
    {
        hash: '/events/venueDataGridReport',
        pageType: PageTypes.VenueDataGridReport,
        getComponent: (page: FrontEndVenueDataGridPage) => <VenueDataGridReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/venueDataGridReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: VenueDataGridReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                startDate: new Date(optionParts[1].split('=')[1]),
                endDate: new Date(optionParts[2].split('=')[1]),
                venueIds: [],
                startTime: optionParts[4].split('=')[1],
                endTime: optionParts[5].split('=')[1],
                includeWorking: optionParts[6].split('=')[1] === 'true',
                showHeader: optionParts[7].split('=')[1] === 'true',
                showMeetingName: optionParts[8].split('=')[1] === 'true',
                showMeetingNumber: optionParts[9].split('=')[1] === 'true',
                showMeetingTime: optionParts[10].split('=')[1] === 'true',
                autoRefresh: optionParts[11].split('=')[1] === 'true',
                showLead: optionParts[12].split('=')[1] === 'true',
                includeRequest: optionParts[13].split('=')[1] === 'true'
            } as VenueDataGridReportOptions;

            reportOptions.venueIds = optionParts[3].split('=')[1].split(',').map((venueId) => parseInt(venueId, 10));

            navigator.goToVenueDataGridReportPage(reportOptions);
        },
        buildUrlHash: (page: FrontEndVenueDataGridPage) => urlBuilder.buildVenueDataGridReportPageUrl(page.reportOptions)
    },
    {
        hash: '/events/golfCartReport',
        pageType: PageTypes.GolfCartReport,
        getComponent: (page: FrontEndGolfCartReportPage) => <GolfCartReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/golfCartReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: GolfCartReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                startDate: new Date(optionParts[1].split('=')[1]),
                startTime: optionParts[2].split('=')[1],
                endDate: new Date(optionParts[3].split('=')[1]),
                endTime: optionParts[4].split('=')[1]
            } as GolfCartReportOptions;

            navigator.goToGolfCartReportPage(reportOptions);
        },
        buildUrlHash: (page: FrontEndGolfCartReportPage) => urlBuilder.buildGolfCartReportPageUrl(page.reportOptions)
    },
    {
        hash: '/events/diningReport',
        pageType: PageTypes.DiningReport,
        getComponent: (page: FrontEndDiningReportPage) => <DiningReportPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {

            let optionsString = urlHash.replace('#/events/diningReport', '');
            optionsString = optionsString.replace('?&', '');
            optionsString = optionsString.replace('?', '');

            const optionParts = optionsString.split('&');

            let reportOptions: DiningReportOptions = {
                eventId: parseInt(optionParts[0].split('=')[1], 10),
                startDate: new Date(optionParts[1].split('=')[1]),
                startTime: optionParts[2].split('=')[1],
                endDate: new Date(optionParts[3].split('=')[1]),
                endTime: optionParts[4].split('=')[1],
                includeWorking: optionParts[5].split('=')[1] === 'true',
                includeRequests: optionParts[6].split('=')[1] === 'true'
            } as DiningReportOptions;

            navigator.goToDiningReportPage(reportOptions);
        },
        buildUrlHash: (page: FrontEndDiningReportPage) => urlBuilder.buildDiningReportPageUrl(page.reportOptions)
    },
    {
        hash: '/system/help',
        pageType: PageTypes.HelpUrls,
        getComponent: (page: FrontEndHelpUrls) => <HelpUrlsPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            navigator.goToHelpUrlsPage();
        },
        buildUrlHash: (page: FrontEndHelpUrls) => urlBuilder.buildHelpUrlsPageUrl()
    },
    {
        hash: '/login/deviceRegistration',
        pageType: PageTypes.DeviceRegistration,
        getComponent: (page: FrontEndDeviceRegistrationPage) => <DeviceRegistrationPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            navigator.goToLogin();
        },
        buildUrlHash: (page: FrontEndDeviceRegistrationPage) => urlBuilder.buildDeviceRegistrationPageUrl()
    },
    {
        hash: '/login/sso',
        pageType: PageTypes.SsoLogin,
        getComponent: (page: FrontEndSsoLoginPage) => <SsoLoginPage page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            navigator.goToSsoLoginPage();
        },
        buildUrlHash: (page: FrontEndSsoLoginPage) => urlBuilder.buildSsoLoginPageUrl()
    }
    //<NEXT>
];
