import { FieldActions, ActionUtils, RadioButtonField as FrontEndRadioButtonField } from 'core.frontend';
import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';

export interface RadioButtonFieldProps {
    id?: string;
    field: FrontEndRadioButtonField;
    labelClassName?: string;
    className?: string;
}

class RadioButtonField extends ObservingComponent<RadioButtonFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        e.stopPropagation();

        field.setValue(newValue);
    }

    public render({ field, labelClassName, className = '', id }: RadioButtonFieldProps): JSX.Element {

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div className={`input-field radio ${className}`}>

                <label className={labelClassName}>
                    {!!field.label &&
                        <span class="fill">{field.label}</span>
                    }

                    {!!field.errorMessage &&
                        <span className="invalid">{field.errorMessage}</span>
                    }
                </label>

                {field.options && field.options.map((option, index) => (
                    <Fragment key={index}>
                        <label
                            for={id + "_" + index}
                            className={(index > 0 ? ' ml-1' : '') + (field.isDisabled ? ' disabled' : '')}>

                            {option}
                        </label>

                        <input
                            type="radio"
                            id={id + "_" + index}
                            name={field.key}
                            onClick={this.valueChanged}
                            className={(field.isInvalid ? ' invalid' : '')}
                            disabled={field.isDisabled}
                            value={option} />
                    </Fragment>
                ))}
            </div>
        );
    }
}

export default RadioButtonField;
