"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var components_1 = require("../../components");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var InventoryDetailPage = /** @class */ (function (_super) {
    __extends(InventoryDetailPage, _super);
    function InventoryDetailPage(api, navigator, currentUserManager, _recordId) {
        var _this = _super.call(this, __1.PageTypes.InventoryDetailPage, 'Inventory Item Detail') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this._recordId = _recordId;
        _this.getIcon = function (item) {
            if (!item) {
                return null;
            }
            switch (item.form.model.fileType) {
                case 'image/jpeg':
                case 'image/png':
                case 'image/bmp':
                case 'image/jpg':
                case 'image/gif':
                    return __1.Icons.Image;
                case 'application/pdf':
                    return __1.Icons.Pdf;
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.ms-word':
                    return __1.Icons.Word;
                case 'text/csv':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.ms-excel':
                    return __1.Icons.Excel;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                case 'application/vnd.ms-powerpoint':
                    return __1.Icons.PowerPoint;
                default:
                    return __1.Icons.File;
            }
        };
        _this.addFile = function () {
            var file = _this._addFileField.value;
            if (!file) {
                return;
            }
            file.friendlyName = file.fileName;
            file.description = '';
            file.isDeletable = true;
            _this._fileDataList.addModel(file);
            _this._addFileField.clearValue();
            _this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.FileAdded);
        };
        _this.setFormFileModelsToSubmit = function () {
            _this._form.model.files = [];
            for (var i = 0; i < _this._fileDataList.items.length; i++) {
                var file = _this._fileDataList.items[i].form.model;
                if (file.id) {
                    var inventoryFile = __assign(__assign({}, file), { buffer: '', fileUrl: '' });
                    delete inventoryFile.formFile;
                    _this._form.model.files.push(inventoryFile);
                }
            }
        };
        _this.uploadNewFiles = function () { return __awaiter(_this, void 0, void 0, function () {
            var i, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        i = 0;
                        _b.label = 1;
                    case 1:
                        if (!(i < this._fileDataList.items.length)) return [3 /*break*/, 4];
                        this._fileDataList.items[i].form.model.inventoryItemId = this._form.model.id;
                        if (!!this._fileDataList.items[i].form.model.id) return [3 /*break*/, 3];
                        _a = this._fileDataList.items[i].form.model;
                        return [4 /*yield*/, this.api.uploadFile(__1.EndpointUrls.Inventory + "/Files", this._fileDataList.items[i].form.model)];
                    case 2:
                        _a.id = _b.sent();
                        _b.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.buildDownloadButton = function (item) {
            if (!_this.downloadButtons[item.form.model.id]) {
                var buttonText = item.form.model.friendlyName;
                _this.downloadButtons[item.form.model.id] = new components_1.DownloadButton({
                    api: _this.api,
                    text: buttonText,
                    url: _this.api.getInventoryFileDownloadUrl(item.form.model.id)
                });
            }
            return _this.downloadButtons[item.form.model.id];
        };
        _this.loadInventoryItemTypes = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getInventoryItemTypes()];
                    case 1:
                        _a._inventoryItemTypes = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.goToInventoryList = function () {
            _this.navigator.goToInventoryListPage();
        };
        _this.deactivateItem = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.post(__1.EndpointUrls.Inventory + '/' + this.recordId + '/deactivate', null)];
                    case 1:
                        _a.sent();
                        this.showDeactivateAlert();
                        this.confirmationPrompt.hide();
                        this._form.model.isActive = false;
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordDeactivated, this._form.model);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.activateItem = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.post(__1.EndpointUrls.Inventory + '/' + this.recordId + '/activate', null)];
                    case 1:
                        _a.sent();
                        this._form.model.isActive = true;
                        this.alert.show({
                            alertType: components_1.AlertType.Success,
                            heading: 'Inventory Item Reactivated',
                            message: 'The inventory item has been reactivated'
                        });
                        this.deactivateButton.setVisibility(true);
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordActivated, this._form.model);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.showDeactivateAlert = function () {
            _this.deactivateButton.setVisibility(false);
            _this.alert.show({
                alertType: components_1.AlertType.Error,
                heading: 'Inventory Item Inactive',
                message: 'This inventory item has been deactivated.',
                button: new button_1.default({
                    text: 'Reactivate',
                    onClick: _this.activateItem,
                    processingText: 'Activating...'
                })
            });
        };
        _this.addImage = function () {
            var imageUrl = _this._addImageField.value;
            if (!imageUrl) {
                return;
            }
            _this._form.model.images.unshift({ imageUrl: imageUrl });
            _this._addImageField.clearValue();
            _this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.ImageAdded);
        };
        _this.removeImage = function (index) { return function () {
            if (_this._form.model.images[index].id) {
                _this._form.model.images[index].isRemoved = true;
            }
            else {
                _this._form.model.images.splice(index, 1);
            }
            _this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.ImageRemoved);
        }; };
        _this.cancelRemoveImage = function (index) { return function () {
            _this._form.model.images[index].isRemoved = false;
            _this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.ImageRemoveCancelled);
        }; };
        _this.hasInventoryItemDimensionsChanged = function () {
            if (!_this._recordId) {
                return false;
            }
            if (_this.originalWidth != _this._widthField.value) {
                return true;
            }
            if (_this.originalLength != _this._legnthField.value) {
                return true;
            }
            return false;
        };
        _this.hasInventoryWeightChanged = function () {
            if (!_this._recordId) {
                return false;
            }
            if (_this.originalWeight != _this._weightField.value) {
                return true;
            }
            return false;
        };
        _this.hasInventoryItemTypeChanged = function () {
            if (!_this._recordId) {
                return false;
            }
            if (_this.originalItemTypeId != _this._inventoryItemTypeField.value) {
                return true;
            }
            return false;
        };
        _this.submit = function () {
            _this._form.submit();
            var isValid = _this._form.isValid();
            isValid = isValid && _this._fileDataList.areAllValid();
            if (_this.crateDataList.isVisible) {
                isValid = isValid && _this.crateDataList.areAllValid();
            }
            if (!isValid) {
                _this.saveButton.setProcessing(false);
                return;
            }
            if (_this.hasInventoryItemDimensionsChanged() || _this.hasInventoryWeightChanged() || _this.hasInventoryItemTypeChanged()) {
                _this.updateCurrentEventsCostManagementValuesConfirmationPrompt.show();
                return;
            }
            _this.save();
        };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.form.model.crates = this.crateDataList.getModels();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        if (!this._recordId) return [3 /*break*/, 3];
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordUpdating, this._form.model);
                        this.setFormFileModelsToSubmit();
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Inventory, this.form.model.id, this.form.model)];
                    case 2:
                        _b.sent();
                        this.uploadNewFiles();
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordUpdated, this._form.model);
                        return [3 /*break*/, 5];
                    case 3:
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordAdding, this.form.model);
                        this.setFormFileModelsToSubmit();
                        _a = this.form.model;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Inventory, this.form.model)];
                    case 4:
                        _a.id = _b.sent();
                        this.uploadNewFiles();
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordAdded, this._form.model);
                        _b.label = 5;
                    case 5:
                        this.navigator.goToInventoryListPage();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _b.sent();
                        this.saveButton.setProcessing(false);
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Save',
                            message: 'An error occurred and the inventory was not saved.'
                        });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        _this.deleteRecord = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.delete(__1.EndpointUrls.Inventory, this._recordId)];
                    case 1:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordDeleting, this.form.model);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.goToHistoryPage = function () {
            _this.navigator.goToHistoryPage({ historyType: __1.HistoryTypes.InventoryHistory, recordId: _this.recordId });
        };
        _this.goToEventHistoryPage = function () {
            _this.navigator.goToEventHistoryPage({ eventHistoryType: __1.EventHistoryType.InventoryItems, recordId: _this.recordId });
        };
        _this.buildRemoveImageButton = function (image, index) {
            return new button_1.default({
                leftIcon: __1.Icons.Minus,
                onClick: _this.removeImage(index),
                isVisible: !image.isRemoved
            });
        };
        _this.buildCancelRemoveImageButton = function (image, index) {
            return new button_1.default({
                leftIcon: __1.Icons.Plus,
                onClick: _this.cancelRemoveImage(index),
                isVisible: image.isRemoved === true
            });
        };
        _this.prepareDuplicateForm = function () {
            var duplicateInventoryItem = __assign({}, _this._form.model);
            duplicateInventoryItem.name += ' - Copy';
            delete duplicateInventoryItem.id;
            delete duplicateInventoryItem.serialNumber;
            duplicateInventoryItem.images = _this._form.model.images.map(function (image) { return (__assign(__assign({}, image), { id: 0 })); });
            duplicateInventoryItem.crates = _this._form.model.crates.map(function (crate) { return (__assign(__assign({}, crate), { number: '', id: 0, notes: '' })); });
            _this.crateDataList.setModels(duplicateInventoryItem.crates);
            _this._form.setModel(duplicateInventoryItem);
            _this._recordId = null;
            _this.alert.hide();
            _this.eventHistoryButton.setVisibility(false);
            _this.historyButton.setVisibility(false);
            _this.deactivateButton.setVisibility(false);
            _this.duplicateButton.setVisibility(false);
            _this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.RecordDuplicated);
        };
        _this.getAddCrateText = function () {
            var crateCount = _this.crateDataList.items.length;
            var text = 'Add ';
            switch (crateCount) {
                case 1:
                    text += '2nd';
                    break;
                case 2:
                    text += '3rd';
                    break;
                case 3:
                    text += '4th';
                    break;
                case 4:
                    text += '5th';
                    break;
                case 5:
                    text += '6th';
                    break;
                case 6:
                    text += '7th';
                    break;
                case 7:
                    text += '7th';
                    break;
                case 8:
                    text += '9th';
                    break;
                case 9:
                    text += '10th';
                    break;
                default:
                    text += 'Additional';
                    break;
            }
            text += ' Crate';
            return text;
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.InventoryDetailPage, null, navigator, api);
        _this.observationProvider = new __1.ObservationProvider();
        _this.buildForm();
        _this.crateDataList = new __1.DataList(function () { return [
            new __1.TextField('number', 'Crate Number', true, 250, 'Crate number is required'),
            new __1.TextField('storageLocation', 'Storage Location', false, 250),
            new components_1.IntField('length', 'Length (in.)', false),
            new components_1.IntField('width', 'Width (in.)', false),
            new components_1.IntField('height', 'Height (in.)', false),
            new components_1.IntField('weight', 'Weight (lbs.)', false),
            new __1.MoneyField('value', 'Value ($)', false),
            new __1.TextField('exportAuthorizationType', 'ExportAuthorizationType', false, 250),
            new __1.TextField('usmlCategory', 'USMLCategory', false, 250),
            new __1.TextField('notes', 'Notes', false, 2000, null, '', __1.FieldType.TextArea)
        ]; });
        _this.historyButton = new button_1.default({
            leftIcon: __1.Icons.Clock,
            onClick: _this.goToHistoryPage,
            isVisible: false
        });
        _this.addCrateButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: _this.getAddCrateText(),
            onClick: _this.crateDataList.addItem
        });
        _this.cancelButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.goToInventoryList
        });
        _this.saveButton = new button_1.default({
            text: 'Save',
            onClick: _this.submit,
            processingText: 'Saving...'
        });
        _this.duplicateButton = new button_1.default({
            leftIcon: __1.Icons.Duplicate,
            onClick: _this.prepareDuplicateForm,
            isVisible: false
        });
        _this.confirmationPrompt = new components_1.ConfirmationPrompt(true, 'Deactivate Inventory Item', 'Are you sure you want to deactivate this inventory item?', 'Deactivate', 'Cancel');
        _this.confirmationPrompt.observationProvider.observe(_this.deactivateItem, components_1.ConfirmationPromptActions.Confirmed);
        _this.updateCurrentEventsCostManagementValuesConfirmationPrompt = new components_1.ConfirmationPrompt(true, 'Update Active Events', 'You have made changes to this inventory item that effects the cost management calculations for events that use this item. Would you like to update these values for active events? Only upcomming or active events will can be updated.', 'Update Active Events', 'Do Not Update Events');
        _this.updateCurrentEventsCostManagementValuesConfirmationPrompt.observationProvider.observe(function () {
            if (_this.hasInventoryItemDimensionsChanged()) {
                _this.form.model.updateEventCostManagementAreaSquareFeet = true;
            }
            if (_this.hasInventoryWeightChanged()) {
                _this.form.model.updateEventCostManagementWeight = true;
            }
            if (_this.hasInventoryItemTypeChanged()) {
                _this.form.model.updateEventCostManagementTypePoints = true;
            }
            _this.save();
        }, components_1.ConfirmationPromptActions.Confirmed);
        _this.updateCurrentEventsCostManagementValuesConfirmationPrompt.observationProvider.observe(function () {
            _this.form.model.updateEventCostManagementAreaSquareFeet = false;
            _this.form.model.updateEventCostManagementWeight = false;
            _this.form.model.updateEventCostManagementTypePoints = false;
            _this.save();
        }, components_1.ConfirmationPromptActions.Cancelled);
        _this.deactivateButton = new button_1.default({
            leftIcon: __1.Icons.Trash,
            onClick: _this.confirmationPrompt.show,
            isVisible: !!_this.recordId
        });
        _this.eventHistoryButton = new button_1.default({
            isVisible: false,
            onClick: _this.goToEventHistoryPage,
            leftIcon: __1.Icons.Calendar
        });
        _this._addFileField = new components_1.BinaryFileInputField('fieldField', 'Add new file', false, __1.FileInputFileTypes.All);
        _this._addFileField.observationProvider.observe(_this.addFile, __1.FieldActions.ValueChanged);
        _this._fileDataList = new __1.DataList(function () { return [
            new __1.TextField('friendlyName', 'Short Name', false, 250),
            new __1.TextField('description', 'Description', false, 250)
        ]; }, true, '', 'No Files');
        _this._fileDataList.addItemButton.setVisibility(false);
        if (_recordId) {
            _this.loadRecord();
            _this.eventHistoryButton.setVisibility(true);
            _this.historyButton.setVisibility(true);
            _this.duplicateButton.setVisibility(true);
        }
        else {
            _this.fileDataList.setModels([]);
            _this.downloadButtons = [];
            _this.crateDataList.setModels([{}]);
        }
        return _this;
    }
    Object.defineProperty(InventoryDetailPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "crateForms", {
        get: function () {
            return this._crateForms;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "addImageField", {
        get: function () {
            return this._addImageField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "nameField", {
        get: function () {
            return this._nameField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "scaleField", {
        get: function () {
            return this._scaleField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "inventoryItemTypeField", {
        get: function () {
            return this._inventoryItemTypeField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "businessAreaField", {
        get: function () {
            return this._businessAreaField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "lineOfBusinessField", {
        get: function () {
            return this._lineOfBusinessField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "descriptionField", {
        get: function () {
            return this._descriptionField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "inventoryItemGroupField", {
        get: function () {
            return this._inventoryItemGroupField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "legnthField", {
        get: function () {
            return this._legnthField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "widthField", {
        get: function () {
            return this._widthField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "heightField", {
        get: function () {
            return this._heightField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "weightField", {
        get: function () {
            return this._weightField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "serialNumberField", {
        get: function () {
            return this._serialNumberField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "valueField", {
        get: function () {
            return this._valueField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "inventoryItemTypes", {
        get: function () {
            return this._inventoryItemTypes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "addFileField", {
        get: function () {
            return this._addFileField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "fileDataList", {
        get: function () {
            return this._fileDataList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "recordId", {
        get: function () {
            return this._recordId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "isDeletable", {
        get: function () {
            return this._recordId > 0 && !this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InventoryDetailPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: true,
        configurable: true
    });
    InventoryDetailPage.prototype.buildForm = function () {
        var _this = this;
        this._addImageField = new __1.FileInputField('inputField', 'Add new image', false, __1.FileInputFileTypes.Images);
        this._addImageField.observationProvider.observe(this.addImage, __1.FieldActions.ValueChanged);
        this._nameField = new __1.TextField('name', 'Item Name', true, 250, 'Item name is required');
        this._scaleField = new __1.TextField('scale', 'Scale', false, 100);
        this._inventoryItemTypeField = new __1.InventoryItemTypeDropdown(this.api, 'inventoryItemTypeId', 'Type', true, 'Type is required');
        this._businessAreaField = this._businessAreaField = new __1.BusinessAreaDropdown(this.api, 'businessAreaId', 'Business Area', true, 'Business area is required');
        this._lineOfBusinessField = new __1.LinesOfBusinessDropdown(this.api, 'lineOfBusinessId', true);
        this._legnthField = new components_1.IntField('length', 'Length (in. - Footprint)', true);
        this._widthField = new components_1.IntField('width', 'Width (in. - Footprint)', true);
        this._heightField = new components_1.IntField('height', 'Height (in. - Footprint)', true);
        this._weightField = new components_1.IntField('weight', 'Weight (lbs.)', true);
        this._serialNumberField = new __1.TextField('serialNumber', 'Serial/Item #', true, 25);
        this._valueField = new __1.MoneyField('value', 'Value ($)', true);
        this._inventoryItemGroupField = new __1.InventoryItemGroupDropdown(this.api, 'inventoryItemGroupId', 'Group', false);
        this._descriptionField = new __1.TextField('description', 'Description', false, 2000, null, '', __1.FieldType.TextArea);
        this._inventoryItemTypeField.observationProvider.observe(function () {
            _this.loadInventoryItemTypes().then(function () {
                var inventoryItemType = null;
                for (var i = 0; i < _this.inventoryItemTypes.length; i++) {
                    if (_this.inventoryItemTypes[i].id == _this.inventoryItemTypeField.value) {
                        inventoryItemType = _this.inventoryItemTypes[i];
                        break;
                    }
                }
                if (!inventoryItemType) {
                    return;
                }
                _this._businessAreaField.setVisibility(inventoryItemType.requireBusinessArea);
                _this.crateDataList.setVisibility(inventoryItemType.requireCrateInformation);
                _this._legnthField.setVisibility(inventoryItemType.requireDimensions);
                _this._widthField.setVisibility(inventoryItemType.requireDimensions);
                _this._heightField.setVisibility(inventoryItemType.requireDimensions);
                _this._weightField.setVisibility(inventoryItemType.requireDimensions);
                _this._lineOfBusinessField.setVisibility(inventoryItemType.requireLineOfBusiness);
                _this._weightField.setVisibility(inventoryItemType.requireWeight);
                _this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.InventoryItemTypeChanged);
            });
        }, __1.FieldActions.ValueChanged);
        var fields = [
            this._nameField,
            this._scaleField,
            this._inventoryItemTypeField,
            this._businessAreaField,
            this._lineOfBusinessField,
            this._inventoryItemGroupField,
            this._legnthField,
            this._widthField,
            this._heightField,
            this._weightField,
            this._serialNumberField,
            this._valueField,
            this._descriptionField
        ];
        this._form = new __1.Form(fields);
        this._form.setModel({ isActive: true });
        this._form.model.images = [];
    };
    InventoryDetailPage.prototype.loadRecord = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._isLoading = true;
                        this.downloadButtons = [];
                        _b = (_a = this._form).setModel;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Inventory + '/' + this._recordId)];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this.originalWidth = this._form.model.width;
                        this.originalLength = this._form.model.length;
                        this.originalWeight = this._form.model.weight;
                        this.originalItemTypeId = this._form.model.inventoryItemTypeId;
                        this._fileDataList.setModels(this._form.model.files);
                        this._isLoading = false;
                        this.crateDataList.setModels(this._form.model.crates);
                        if (!this._form.model.isActive) {
                            this.showDeactivateAlert();
                        }
                        this.observationProvider.notifyObservers(__1.InventoryDetailPageActions.Loaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    return InventoryDetailPage;
}(__1.BasePage));
exports.default = InventoryDetailPage;
