import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import LoginPageProps from './LoginPageProps';
import LoginForm from './loginForm';
import LoginPageWrapper from './loginPageWrapper';

class LoginPage extends ObservingComponent<LoginPageProps> {

    public render({ page }: LoginPageProps): JSX.Element {

        return (
            <LoginPageWrapper
                helpButton={page.helpButton}
                isApp={false}>

                <div>
                    <LoginForm page={page} />

                    <div class="text-sm pt-1 pl-1 pr-1">
                        <p class="text-justify">
                            This system is the property of this Corporation/organization, and is intended for the use of authorized users only. All activities of individuals using this computing system with or without authority, or in excess or their authority, may be monitored and recorded by system personnel. If any such monitoring reveals evidence of criminal activity or is in violation of foreign or U.S. state or federal law, such evidence may be provided to law enforcement officials and/or used for further legal action by this Corporation and/or the organization's Information Protection group. Unauthorized use of this system is prohibited and may result in revocation of access, disciplinary action and/or legal action. The company reserves the right to monitor and review user activity, files and electronic messages.
                        </p>
                        <p>
                            Reminder: Information transmitted to a foreign person on this network may be subject to applicable Export Control laws.
                        </p>
                    </div>
                </div>
            </LoginPageWrapper>
        );
    }
}

export default LoginPage;
