"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var components_1 = require("../../components");
var CompleteAccessRequestPage = /** @class */ (function (_super) {
    __extends(CompleteAccessRequestPage, _super);
    function CompleteAccessRequestPage(api, navigator, currentUserManager, requestKey) {
        var _this = _super.call(this, __1.PageTypes.CompleteAccessRequest, 'Register New Account') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.requestKey = requestKey;
        _this.submitButtonClicked = function () {
            _this.form.submit();
        };
        _this.loadAccessRequest = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.get(__1.EndpointUrls.GetAccessRequest + '/' + this.requestKey)
                        .then(function (accessRequest) {
                        _this._accessRequest = accessRequest;
                        _this._isSso = accessRequest.isSso;
                        _this.api.setTenantIdOverride(accessRequest.tenantId);
                        _this.buildForm();
                    })
                        .catch(function (err) {
                        _this._isLoading = false;
                        _this._invalidAccessRequest = true;
                        _this.handleError(err);
                        _this.observationProvider.notifyObservers(__1.CompleteAccessRequestPageActions.InvalidAccessRequest);
                    })];
            });
        }); };
        _this.notifyFormInvalid = function () {
            _this.submitButton.setProcessing(false);
            _this.observationProvider.notifyObservers(__1.CompleteAccessRequestPageActions.FormInvalid);
        };
        _this.formSubmitted = function () { return __awaiter(_this, void 0, void 0, function () {
            var authenticatedUserDto, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.CompleteAccessRequestPageActions.FormSubmitted);
                        this._form.model.requestKey = this.requestKey;
                        this._form.model.hotelRewardNumbers = [];
                        this._form.model.tenantId = this._accessRequest.tenantId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.CompleteAccessRequest, this._form.model)];
                    case 2:
                        authenticatedUserDto = _a.sent();
                        this.submitButton.setProcessing(false);
                        this.currentUserManager.login(authenticatedUserDto);
                        this.api.setTenantIdOverride(null);
                        this.observationProvider.notifyObservers(__1.CompleteAccessRequestPageActions.SubmittedSuccessfully);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.handleError(err_1);
                        this.observationProvider.notifyObservers(__1.CompleteAccessRequestPageActions.ErrorSubmitting);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleError = function (err) {
            _this.submitButton.setProcessing(false);
            var errorMessage;
            if (err && err.message) {
                errorMessage = err.message;
            }
            else {
                errorMessage = 'An error occurred trying to create your account. Please contact your system administrator.';
            }
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Unable to complete request',
                message: errorMessage
            });
        };
        _this.buildForm = function () {
            _this._passwordField = new __1.PasswordField('password', true);
            _this._confirmPasswordField = new __1.ConfirmPasswordField('confirmPassword', true, _this._passwordField);
            _this._loginEmailField = new __1.EmailField('email', true, 'Login Email');
            _this._loginEmailField.setValue(_this._accessRequest.email);
            _this._loginEmailField.observationProvider.observe(_this.loginFieldChanged, __1.FieldActions.ValueChanged);
            _this._firstNameField = new __1.TextField('firstName', 'First Name', true, 100, 'First name is required');
            _this._lastNameField = new __1.TextField('lastName', 'Last Name', true, 100, 'Last name is required');
            _this._departmentField = new __1.DepartmentDropdown(_this.api, 'departmentId', true);
            _this._contactEmailField = new __1.EmailField('contactEmail', true, 'Contact Email');
            _this._contactEmailField.setValue(_this._accessRequest.email);
            _this._contactPhoneField = new __1.TextField('contactPhone', 'Mobile Phone', true, 50);
            _this._titleField = new __1.UserTitlesDropdown(_this.api, 'userTitleId', 'Title', false, 'Title is required');
            _this._productKnowledgeField = new components_1.ProductKnowledgeDropdownField(_this.api, 'productKnowledgeOptionId', 'Area of Expertise', false);
            _this._lineOfBusinessField = new __1.LinesOfBusinessDropdown(_this.api, 'lineOfBusinessId', false);
            _this._loginEmailField.isDisabled = true;
            var fields = [
                _this._loginEmailField,
                _this._passwordField,
                _this._confirmPasswordField,
                _this._firstNameField,
                _this._lastNameField,
                _this._departmentField,
                _this._contactEmailField,
                _this._contactPhoneField,
                _this._titleField,
                _this._productKnowledgeField,
                _this._lineOfBusinessField
            ];
            _this._form = new __1.Form(fields);
            _this._form.observationProvider.observe(_this.notifyFormInvalid, __1.FormActions.FormSubmissionFailure);
            _this._form.observationProvider.observe(_this.formSubmitted, __1.FormActions.FormSubmitted);
            _this._isLoading = false;
            _this.observationProvider.notifyObservers(__1.CompleteAccessRequestPageActions.FormLoaded);
        };
        _this.loginFieldChanged = function () {
            if (!_this._contactEmailField.value) {
                _this._contactEmailField.setValue(_this._loginEmailField.value);
            }
        };
        _this._isLoading = true;
        _this.observationProvider = new __1.ObservationProvider();
        if (!_this.requestKey) {
            _this.navigator.goToLogin();
        }
        else if (_this.currentUserManager.isLoggedIn) {
            _this.navigator.goToEventList();
        }
        else {
            _this.loadAccessRequest();
        }
        _this.resubmitButton = new button_1.default({
            text: 'Send Another Request',
            onClick: _this.navigator.goToRequestAccessPage
        });
        _this.submitButton = new button_1.default({
            text: 'Submit',
            onClick: _this.submitButtonClicked,
            processingText: 'Submitting...',
            buttonType: components_1.ButtonTypes.Submit
        });
        return _this;
    }
    Object.defineProperty(CompleteAccessRequestPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "isSubmitting", {
        get: function () {
            return this._isSubmitting;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "invalidAccessRequest", {
        get: function () {
            return this._invalidAccessRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "passwordField", {
        get: function () {
            return this._passwordField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "confirmPasswordField", {
        get: function () {
            return this._confirmPasswordField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "loginField", {
        get: function () {
            return this._loginEmailField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "firstNameField", {
        get: function () {
            return this._firstNameField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "lastNameField", {
        get: function () {
            return this._lastNameField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "departmentField", {
        get: function () {
            return this._departmentField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "contactEmailField", {
        get: function () {
            return this._contactEmailField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "contactPhoneField", {
        get: function () {
            return this._contactPhoneField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "titleField", {
        get: function () {
            return this._titleField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "productKnowledgeField", {
        get: function () {
            return this._productKnowledgeField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "lineOfBusinessField", {
        get: function () {
            return this._lineOfBusinessField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompleteAccessRequestPage.prototype, "isSso", {
        get: function () {
            return this._isSso;
        },
        enumerable: true,
        configurable: true
    });
    return CompleteAccessRequestPage;
}(__1.BasePage));
exports.default = CompleteAccessRequestPage;
