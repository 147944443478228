import { h, JSX } from 'preact';
import { FieldActions, ActionUtils, IntField as FrontEndField } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';

export interface IntFieldProps {
    id?: string;
    field: FrontEndField;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    stopPropagation?: boolean;
}

class IntField extends ObservingComponent<IntFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    public mouseDown = (e: any) => {
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id = '' }: IntFieldProps): JSX.Element {

        if (!id) {
            id = field.uniqueKey;
        }

        return (
            <div className={`input-field ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} />}

                <label
                    className={labelClassName}
                    for={id}>


                    <span class="fill">
                        {field.label}
                    </span>


                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>

                <input
                    type="number"
                    step="1"
                    min="0"
                    max="999999"
                    id={id}
                    name={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    value={field.value}
                    onChange={this.valueChanged}
                    className={field.isInvalid ? 'invalid' : ''}
                    disabled={field.isDisabled}
                    onMouseDown={this.mouseDown}/>

            </div>
        );
    }
}

export default IntField;
