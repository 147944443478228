import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../../componentBases/observingComponent';
import MeetingsReportPageProps from './meetingsReportPageProps';
import FormattedDate from '../../text/formattedDate';
import { DateFormats, MeetingStatuses } from 'core.frontend';
import { attendeeHeader } from '../../styles/eventLanding.css';
import FormattedTimespan from '../../text/formattedTimespan';
import MeetingAttendeesLabel from '../../meetings/meetingAttendeesLabel';
import Button from '../../buttons/button';

class MeetingsReportPage extends ObservingComponent<MeetingsReportPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: MeetingsReportPageProps): JSX.Element {

        return (
            <div class="report">

                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                <h3 class="text-center pl-1 pr-1 mt-1">
                    {!page.isLoading &&
                        <span>{page.reportData.eventName}</span>
                    }
                </h3>

                <div class="text-sm flex">
                    <div class="fill">
                        Printed by: {page.currentUsersName}
                        <div><b>{page.title}</b></div>
                    </div>
                    <div class="text-right">

                        <FormattedDate
                            date={new Date()}
                            format={DateFormats.dddMMMMDhmmA} />

                        {!page.isLoading &&
                            <div>
                                <span>Total Meetings: </span>
                                {page.reportData.reportDataItems.length}
                            </div>
                        }
                    </div>
                </div>

                <div class="text-sm mb-1">
                    {!page.isLoading &&
                        <div class="text-right">
                            For: {page.reportData.attendeeSelection}
                        </div>
                    }
                </div>

                {!page.isLoading &&
                    <div class="report-page text-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th className="no-style">&nbsp;</th>
                                    <th class="text-center">#</th>
                                    <th>Date/Time</th>
                                    <th>Meeting Name</th>
                                    <th>Guests</th>
                                    <th>Company Attendees</th>
                                    <th>Assigned Venue</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>

                            { page.reportData.reportDataItems.map((meeting) =>
                                <tbody>

                                    {meeting.preMeetingPickupAvailable &&
                                        <tr>
                                            <td class="bg-black"></td>
                                            <td colSpan={7}>

                                                <span class="text-underline">Pre-meeting pick-up location: </span>
                                                <span>{meeting.preMeetingPickupLocation} </span>

                                                <span class="text-underline">Pre-meeting drop-off location: </span>
                                                <span>{meeting.preMeetingDropOffLocation} </span>

                                                <span class="text-underline">Pre-meeting pick-up time: </span>
                                                <span><FormattedTimespan timespan={meeting.preMeetingPickupTime} /> </span>

                                                <span class="text-underline">Passengers: </span>
                                                <span>{meeting.preMeetingPickupCount} </span>

                                                <div>
                                                    <span class="text-underline">Comments:</span>
                                                    <span>{meeting.preMeetingPickupComments} </span>
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    <tr class={meeting.status === MeetingStatuses.Working ? 'bg-yellow' :
                                            meeting.status === MeetingStatuses.Requested ? 'bg-baby-blue' : ''}>
                                        <td class="text-vertical text-bold text-center no-style">
                                            {meeting.status === MeetingStatuses.Requested &&
                                                <Fragment>REQ</Fragment>
                                            }

                                            {meeting.status === MeetingStatuses.Working &&
                                                <Fragment>WORK</Fragment>
                                            }
                                        </td>
                                        <td class="text-center">{meeting.meetingNumber}</td>
                                        <td class="text-center">
                                            <FormattedDate
                                                date={meeting.startDateTime}
                                                format={DateFormats.MMMd} />

                                            <br/>

                                            <FormattedDate
                                                date={meeting.startDateTime}
                                                format={DateFormats.hhmm} />

                                                <span> - </span>

                                            <FormattedDate
                                                date={meeting.endDateTime}
                                                format={DateFormats.hhmm} />
                                        </td>
                                        <td>
                                            {meeting.name}

                                            <div>
                                                {meeting.subject}
                                            </div>
                                        </td>
                                        <td>
                                            {meeting.guestsArrived &&
                                                <div class="text-red">
                                                    Guests Arrived
                                                </div>
                                            }

                                            {meeting.guests.map((guest) =>
                                                <div>
                                                    {guest.title} {guest.firstName} {guest.lastName}
                                                    {guest.isLead &&
                                                        <span> (L)</span>
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <MeetingAttendeesLabel meeting={meeting} />
                                        </td>
                                        <td>
                                            {meeting.venue}

                                            {meeting.assignedTables &&
                                                <span class='text-sm'> / {meeting.assignedTables}</span>
                                            }
                                        </td>
                                        <td>{meeting.printedComments}</td>
                                    </tr>

                                    {meeting.postMeetingPickupAvailable &&
                                        <tr>
                                            <td class="bg-black"></td>
                                            <td colSpan={7}>

                                                <span class="text-underline">Post-meeting pick-up location: </span>
                                                <span>{meeting.postMeetingPickupLocation} </span>

                                                <span class="text-underline">Post-meeting drop-off location: </span>
                                                <span>{meeting.postMeetingDropOffLocation} </span>

                                                <span class="text-underline">Post-meeting pick-up time: </span>
                                                <span><FormattedTimespan timespan={meeting.postMeetingPickupTime} /> </span>

                                                <span class="text-underline">Passengers: </span>
                                                <span>{meeting.postMeetingPickupCount} </span>

                                                <div>
                                                    <span class="text-underline">Comments:</span>
                                                    <span>{meeting.postMeetingPickupComments} </span>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            )}
                        </table>

                        <div class="mt-2">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Meeting Status Legand</th>
                                    </tr>
                                </thead>
                                <tr class="bg-yellow">
                                    <td>Working</td>
                                </tr>
                                <tr class="bg-baby-blue">
                                    <td>Requested</td>
                                </tr>
                                <tr>
                                    <td>Finalized</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default MeetingsReportPage;
