import { h, JSX } from 'preact';
import ObservingComponent from '../../../componentBases/observingComponent';
import { AttendeeDataGridReportItem, DateFormats, DateUtils, MeetingStatuses } from 'core.frontend';
import FormattedDate from '../../../text/formattedDate';
import AttendeeDataGridReportPageProps from './attendeeDataGridReportPageProps';
import Button from '../../../buttons/button';
import MeetingDimension from '../meetingDimension';
import Field from '../../../fields/field';

class AttendeeDataGridReportPage extends ObservingComponent<AttendeeDataGridReportPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    private getFirstGridDateTime(date: Date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        let monthStr = month.toString();
        let dayStr = day.toString();

        if (month < 10) {
            monthStr = '0' + monthStr;
        }

        if (day < 10) {
            dayStr = '0' + dayStr;
        }

        const firstDateTime = new Date(`${year}-${monthStr}-${dayStr}T${this.props.page.firstGridTime}`);

        return firstDateTime;
    }

    private getMeetingOffsetMinutes = (startDateTime: Date) => {
        /* calculates the number of minutes a meeting start time is before the first time on the grid */
        var offset = DateUtils.getMinutesDifference(this.getFirstGridDateTime(startDateTime), startDateTime);
        return offset > 0 ? offset : 0;
    }

    private getMeetingBlockHeight = (meeting: AttendeeDataGridReportItem) => {
        let lengthInMinutes = DateUtils.getMinutesDifference(meeting.endDateTime, meeting.startDateTime);
        lengthInMinutes -= this.getMeetingOffsetMinutes(meeting.startDateTime);

        return (lengthInMinutes / 30 * this.props.page.rowHeight) - 1;
    }

    private getMeetingBlockTop = (meeting: AttendeeDataGridReportItem) => {
        let minutesFromBase = DateUtils.getMinutesDifference(meeting.startDateTime, this.getFirstGridDateTime(meeting.startDateTime));
        minutesFromBase += this.getMeetingOffsetMinutes(meeting.startDateTime);

        return minutesFromBase / 30 * this.props.page.rowHeight;
    }

    private getMeetingDimensionsForDate = (date: Date) => {
        const { page } = this.props;

        const meetingDimensions: MeetingDimension[][] = [];

        for(let i = 0; i < page.reportData.attendees.length; i++) {
            const attendee = page.reportData.attendees[i];

            meetingDimensions[attendee.eventAttendeeId] = [];

            const meetings = page.getMeetings(attendee, date);

            for(let j = 0; j < meetings.length; j++) {

                const meeting = meetings[j];

                const top = this.getMeetingBlockTop(meeting);
                const height = this.getMeetingBlockHeight(meeting);

                meetingDimensions[attendee.eventAttendeeId].push({
                    top: top,
                    height: height,
                    left: 0,
                    right: 0,
                    bottom: top + height,
                    rightAdjacentMeetings: 0,
                    column: 0,
                    meetingId: meeting.meetingId,
                    meetingMinutes: DateUtils.getMinutesDifference(meeting.endDateTime, meeting.startDateTime)
                });
            }

            // sort by top then by bottom
            meetingDimensions[attendee.eventAttendeeId] = meetingDimensions[attendee.eventAttendeeId].sort((a, b) => {
                if (a.top < b.top) {
                    return -1;
                }

                if (a.top > b.top) {
                    return 1;
                }

                if (a.bottom > b.bottom) {
                    return -1;
                }

                if (a.bottom < b.bottom) {
                    return 1;
                }

                return 0;
            });

            this.setMeetingDimensionsColumnNumber(meetingDimensions[attendee.eventAttendeeId], 0);
            this.setMeetingDimensionsTotalColumnCount(meetingDimensions[attendee.eventAttendeeId]);
        }

        for(let i = 0; i < page.reportData.attendees.length; i++) {
            console.table(meetingDimensions[page.reportData.attendees[i].eventAttendeeId]);
        }

        return meetingDimensions;
    }

    private setMeetingDimensionsColumnNumber(meetingDimensions: MeetingDimension[], seedColumnNumber: number) {

        for (let i = 0; i < meetingDimensions.length; i++) {
            const meetingDimension = meetingDimensions[i];

            if (meetingDimension.column > 0) {
                continue;
            }

            meetingDimension.column = seedColumnNumber + 1;
            //meetingDimension.left = this.props.page.columnMinWidth * seedColumnNumber;
            //meetingDimension.right = this.props.page.columnMinWidth * seedColumnNumber + 1;

            const adjacentMeetings = meetingDimensions
                .filter((meeting) =>
                    meeting.meetingId != meetingDimension.meetingId &&
                    meeting.top >= meetingDimension.top &&
                    meeting.top < meetingDimension.bottom
                );

            this.setMeetingDimensionsColumnNumber(adjacentMeetings, seedColumnNumber + 1);

            if (adjacentMeetings.length > 0) {
                let maxAdjacentMeetings = 0;

                for(let j = 0; j < adjacentMeetings.length; j++) {
                    if (adjacentMeetings[j].rightAdjacentMeetings + 1 > maxAdjacentMeetings) {
                        maxAdjacentMeetings += adjacentMeetings[j].rightAdjacentMeetings + 1;
                    }
                }

                meetingDimension.rightAdjacentMeetings = Math.max(maxAdjacentMeetings, 1);
            } else {
                meetingDimension.rightAdjacentMeetings = 0;
            }
        }
    }

    private setMeetingDimensionsTotalColumnCount(meetingDimensions: MeetingDimension[]) {
        /*
            BUG:
                totalColumns is not being set properly when the layout is as follows.
                it is being set to 1 when it should be 2

                Actual Layout

                ----------------    ----------------
                ----------------    ----------------
                ----------------    ----------------
                ----------------
                ----------------                        ----------------  <- totalColumns set to 1 when it should be 2
                ----------------                        ----------------



                Expected Layout
                ----------------    ----------------
                ----------------    ----------------
                ----------------    ----------------
                ----------------
                ----------------    ----------------
                ----------------    ----------------

        */

        // loop through each meeting within a single column
        for (let i = 0; i < meetingDimensions.length; i++) {

            if (meetingDimensions[i].rightAdjacentMeetings > 0) {
                const totalColumns = meetingDimensions[i].rightAdjacentMeetings + 1;
                meetingDimensions[i].totalColumns = totalColumns;

                // loop through the following items in the array until rightAdjacentMeetings === 0
                // then reset the i loop to resume where the j loop ends
                for (let j = Math.min(i + 1, meetingDimensions.length); j < meetingDimensions.length; j++) {
                    meetingDimensions[j].totalColumns = totalColumns;

                    if (meetingDimensions[j].rightAdjacentMeetings === 0) {
                        i = j; // -1 because i will be incremented when finishing the i loop cycle. This ensures one doesn't get skipped
                        break;
                    }
                }
            }
            else {
                meetingDimensions[i].totalColumns = 1;
            }

            /*
                Bug Fix

                Ensure that totalColumns isn't less than the meeting column that is specified
            */
            meetingDimensions[i].totalColumns = Math.max(meetingDimensions[i].totalColumns, meetingDimensions[i].column);
        }
    }

    private getColumnWidths(meetingDimensions: MeetingDimension[][]) {
        const { page } = this.props;

        const columnWidths: number[] = [];
        let totalMeetingColumns: number = 0;

        for(let i = 0; i < page.reportData.attendees.length; i++) {
            const attendee = page.reportData.attendees[i];

            //columnWidths[attendee.eventAttendeeId] = this.props.page.columnMinWidth * Math.max(...meetingDimensions[attendee.eventAttendeeId].map((dimension) => dimension.column));
            columnWidths[attendee.eventAttendeeId] = Math.max(...meetingDimensions[attendee.eventAttendeeId].map((dimension) => dimension.column));
            totalMeetingColumns += columnWidths[attendee.eventAttendeeId];
        }

        for(let i = 0; i < page.reportData.attendees.length; i++) {
            const attendee = page.reportData.attendees[i];

            //attendee meeting columns / all attendee meeting columns
            columnWidths[attendee.eventAttendeeId] = columnWidths[attendee.eventAttendeeId] / totalMeetingColumns * 100;
        }

        return columnWidths;
    }

    private getReportHeader() {
        const page = this.props.page;

        if (page.isLoading) {
            return;
        }

        return (
            <div className="reportHeader">

                <h3 class="text-center mb-p5">
                    {page.reportData.eventName}
                </h3>

                <div class="text-sm flex">
                    <div class="fill">
                        Printed by: {page.currentUsersName}

                        {!page.isLoading &&
                            <div><b>{page.title}</b></div>
                        }
                    </div>

                    <div class="text-right">
                        <FormattedDate
                            date={new Date()}
                            format={DateFormats.dddMMMMDhmmA} />

                        <div className="text-sm flex no-print">

                            <Field
                                className="ml-auto mr-1 v-center"
                                field={this.props.page.includeMeetingNumberCheckbox} />

                            <Field
                                className="mr-1 v-center"
                                field={this.props.page.includeMeetingTimesCheckbox} />

                            <Field
                                className="mr-1 v-center"
                                field={this.props.page.includeVenueCheckbox} />

                            <div className="v-center flex">
                                <span className="v-center">Row Height: </span>
                                <Button
                                    className="btn-icon ml-p5 v-center"
                                    button={this.props.page.decreaseRowHeightButton} />
                                <Button
                                    className="btn-icon ml-p5 v-center"
                                    button={this.props.page.increaseRowHeightButton} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getMeetingBlock(meetingDimensions: MeetingDimension[][], columnWidths: number[], eventAttendeeId: number, meeting: AttendeeDataGridReportItem) {
        const meetingDimension = meetingDimensions[eventAttendeeId].find((d) => d.meetingId === meeting.meetingId);
        const meetingBlockTop = meetingDimension.top + 1;

        const meetingBlockLeft = `calc(${(meetingDimension.column - 1)/meetingDimension.totalColumns * 100}% + 1px)`;
        const meetingWidth = `calc(${(1 / meetingDimension.totalColumns * 100).toString()}% - 2px)`;


        const meetingBlockHeight = Math.min(
            meetingDimension.height - 2,
            (this.props.page.rowHeight * this.props.page.gridTimes.length) - meetingDimension.top - 2
        );

        let meetingBlockClassName = '';

        if (meetingDimension.meetingMinutes <= 15) {
            meetingBlockClassName = 'x-small';
        } else if (meetingDimension.meetingMinutes <= 59) {
            meetingBlockClassName = 'small';
        }

        return (
            <div
                style={{
                    top: meetingBlockTop,
                    height: meetingBlockHeight,
                    left: meetingBlockLeft,
                    width: meetingWidth
                }}
                class="datagrid-report-item">

                <div className={"datagrid-report-item-content " + meetingBlockClassName}>
                    <div className="datagrid-report-item-inner-content">
                        <div className="meeting-name">
                            <b>
                                {meeting.status == MeetingStatuses.Working &&
                                    <span class='invalid'>(Working) </span>
                                }

                                {meeting.status == MeetingStatuses.Requested &&
                                    <span class='invalid'>(R) </span>
                                }

                                {!!meeting.isLead && meetingDimension.meetingMinutes > 15 &&
                                    <span> (POC) </span>
                                }

                                {!!meeting.isMeetAndGreet && meetingDimension.meetingMinutes > 15 &&
                                    <span> (M&G) </span>
                                }

                                {meeting.meetingName}

                                {this.props.page.includeMeetingNumber &&
                                    <span> ({meeting.meetingNumber}) </span>
                                }
                            </b>
                        </div>

                        {(
                            (!!meeting.venueName && this.props.page.includeVenue) ||
                            (this.props.page.includeMeetingTimes)
                        ) &&

                            <div className="meeting-details">

                                {!!meeting.venueName && this.props.page.includeVenue &&
                                    <span>
                                        {meeting.venueName}

                                        {meeting.assignedTables &&
                                            <span class='text-sm'> / {meeting.assignedTables}</span>
                                        }
                                    </span>
                                }

                                {!!meeting.venueName && this.props.page.includeVenue && this.props.page.includeMeetingTimes &&
                                    <span>&nbsp;|&nbsp;</span>
                                }

                                {this.props.page.includeMeetingTimes &&
                                    <span>
                                        <FormattedDate
                                            date={meeting.startDateTime}
                                            format={DateFormats.hhmm} />

                                        <span> - </span>

                                        <FormattedDate
                                            date={meeting.endDateTime}
                                            format={DateFormats.hhmm} />
                                    </span>
                                }
                            </div>
                        }

                        {!!meeting.dataGridComments &&
                            <div>{meeting.dataGridComments}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    private getCell(eventAttendeeId: number) {
        return this.props.page.gridTimes.map((time, index) =>
            <span
                key={time.toString() + '_' + eventAttendeeId.toString()}
                style={{
                    top: (this.props.page.rowHeight * index),
                    height: this.props.page.rowHeight
                }}
                class="datagrid-report-items-cell"></span>
        )
    }

    private getTimeColumn() {
        return (
            <div class="datagrid-report-time-cell-column">
                {this.props.page.gridTimes.map((time) =>
                        <div
                            key={time}
                            class="datagrid-report-time-cell"
                            style={{
                                height: this.props.page.rowHeight
                            }}>

                            <span>
                                {time}
                            </span>
                        </div>
                )}
            </div>
        );
    }

    public render({ page }: AttendeeDataGridReportPageProps): JSX.Element {

        return (
            <div class="report allow-overflow width-100 print-zoom-95">

                <div class="flex">
                    <Button
                        className="mr-auto btn-icon"
                        button={page.backButton} />
                </div>

                <div className="no-print">
                    {this.getReportHeader()}
                </div>

                {!page.isLoading &&
                    <div>
                        {page.reportDates.map((reportDate) => {

                            const meetingDimensions = this.getMeetingDimensionsForDate(reportDate);
                            const columnWidths = this.getColumnWidths(meetingDimensions);

                            return (
                                <div class="report-page text-sm">
                                    <div className="print-block">
                                        {this.getReportHeader()}
                                    </div>

                                    <div className="report-page-content">

                                        <div class="text-center mb-1">
                                            <b>
                                                <FormattedDate
                                                    date={reportDate}
                                                    format={DateFormats.MMMdYYYY} />
                                            </b>
                                        </div>

                                        <div class="datagrid">
                                            <div class="datagrid-header">
                                                <div class="datagrid-report-time-cell-column">
                                                    <div class="datagrid-report-time-cell">&nbsp;</div>
                                                </div>

                                                <div className='flex fill'>
                                                    {page.reportData.attendees.map((attendee) =>
                                                        <div
                                                            class="datagrid-column-header"
                                                            style={{
                                                                width: columnWidths[attendee.eventAttendeeId] + '%'
                                                            }}>

                                                                {attendee.fullName}
                                                            </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div class="datagrid-content">
                                                {this.getTimeColumn()}

                                                <div class="flex fill">
                                                    {page.reportData.attendees.map((attendee) => (
                                                        <div
                                                            className="datagrid-report-items-column"
                                                            key={attendee.eventAttendeeId}
                                                            style={{
                                                                height: this.props.page.rowHeight * page.gridTimes.length,
                                                                width: columnWidths[attendee.eventAttendeeId] + '%',
                                                            }}>

                                                            {this.getCell(attendee.eventAttendeeId)}

                                                            {page.getMeetings(attendee, reportDate).map((meeting) =>
                                                                this.getMeetingBlock(meetingDimensions, columnWidths, attendee.eventAttendeeId, meeting)
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        );
    }
}

export default AttendeeDataGridReportPage;
