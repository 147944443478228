"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HistoryTypes;
(function (HistoryTypes) {
    HistoryTypes[HistoryTypes["UserHistory"] = 1] = "UserHistory";
    HistoryTypes[HistoryTypes["EventHistory"] = 2] = "EventHistory";
    HistoryTypes[HistoryTypes["InventoryHistory"] = 3] = "InventoryHistory";
    HistoryTypes[HistoryTypes["MeetingHistory"] = 4] = "MeetingHistory";
    HistoryTypes[HistoryTypes["VendorUserHistory"] = 5] = "VendorUserHistory";
    HistoryTypes[HistoryTypes["AccommodationHistory"] = 6] = "AccommodationHistory";
})(HistoryTypes || (HistoryTypes = {}));
exports.default = HistoryTypes;
