"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DiningTileActions;
(function (DiningTileActions) {
    DiningTileActions["Toggled"] = "Toggled";
    DiningTileActions["DataLoading"] = "DataLoading";
    DiningTileActions["DataLoaded"] = "DataLoaded";
    DiningTileActions["EditModeActivated"] = "EditModeEnabled";
    DiningTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    DiningTileActions["MeetingSelected"] = "MeetingSelected";
    DiningTileActions["TableAssignmentChanged"] = "TableAssignmentChanged";
    DiningTileActions["TableLayoutVisible"] = "TableLayoutVisible";
    DiningTileActions["TableLayoutHidden"] = "TableLayoutHidden";
    DiningTileActions["TableAdded"] = "TableAdded";
    DiningTileActions["TableRemovedToggled"] = "TableRemovedToggled";
    DiningTileActions["AddMeetingModalHidden"] = "AddMeetingModalHidden";
    DiningTileActions["AddMeetingModalShown"] = "AddMeetingModalShown";
    DiningTileActions["NewMeetingCategoryChanged"] = "NewMeetingCategoryChanged";
})(DiningTileActions || (DiningTileActions = {}));
exports.default = DiningTileActions;
