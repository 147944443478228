import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import MeetingPlacardPageProps from './meetingPlacardPageProps';
import Modal from '../containers/modal';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import Field from '../fields/field';
import Alert from '../alerts/alert';

class MeetingPlacardPage extends ObservingComponent<MeetingPlacardPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: MeetingPlacardPageProps): JSX.Element {
        return (
            <div class="display-page-wrapper">
                <Alert
                    className="mb-0"
                    alert={page.alert} />

                {page.settingsVisible &&
                    <Modal
                        heading="Meeting Room Placard Settings"
                        close={() => {}}
                        hideBackButton={true}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Primary}
                                button={page.submitButton} />
                        ]}>

                        <div class="form-container">
                            <p>
                                <b>Note: </b> Only finalized meetings will be displayed. Refresh the page to access these settings again.
                            </p>

                            <hr />

                            <Field field={page.form.fields[0]} />

                            <h4 class="section-header">Display Options</h4>

                            <div>
                                <Field
                                    field={page.form.fields[1]} />

                                <Field
                                    field={page.form.fields[2]} />

                                <Field
                                    field={page.form.fields[3]} />

                                <Field
                                    field={page.form.fields[4]} />

                                <Field
                                    field={page.form.fields[5]} />
                            </div>
                        </div>
                    </Modal>
                }

                {!page.settingsVisible &&
                    <div class="meeting-placard">

                        {page.form.model.displayVenueName &&
                            <div class="venue-name">
                                <span>{page.venueName}</span>
                                <hr/>
                            </div>
                        }

                        <div class="meeting-data">
                            {(!page.meetingPlacardData || !page.meetingPlacardData.number) &&
                                <span>Available</span>
                            }

                            {page.meetingPlacardData && !!page.meetingPlacardData.number &&
                                <div>
                                    {page.form.model.displayMeetingNumber &&
                                        <div class="meeting-number">#{page.meetingPlacardData.number}</div>
                                    }

                                    {page.form.model.displayStartEndTime &&
                                        <div class="meeting-time">
                                            {page.meetingPlacardData.startTimeString}

                                            <span> - </span>

                                            {page.meetingPlacardData.endTimeString}
                                        </div>
                                    }

                                    {page.form.model.displayMeetingName &&
                                        <div class="meeting-name">{page.meetingPlacardData.name}</div>
                                    }
                                </div>
                            }
                        </div>


                        {page.form.model.displayNextMeetingStartTime &&
                            <div class="next-meeting-info">
                                <hr/>
                                Next Meeting Start:

                                {(!page.meetingPlacardData || !page.meetingPlacardData.nextMeetingStartTimeString) &&
                                    <span> Unknown</span>
                                }

                                {page.meetingPlacardData && page.meetingPlacardData.nextMeetingStartTimeString &&
                                    <span>
                                        &nbsp;

                                        {page.meetingPlacardData.nextMeetingStartTimeString}
                                    </span>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default MeetingPlacardPage;
