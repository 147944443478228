import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Modal from '../containers/modal';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import { HelpListModal as FrontendHelpListModal, HelpUrls } from 'core.frontend';
import DownloadButtonHandlingMethod from '../buttons/downloadButtonHandlingMethod';
import DownloadButton from '../buttons/downloadButton';

export interface HelpListModalProps {
    helpListModal: FrontendHelpListModal
}

class HelpListModal extends ObservingComponent<HelpListModalProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.helpListModal.observationProvider);
    }

    private videoButtonClicked = (item: HelpUrls) => (e: Event) => {
        this.props.helpListModal.videoLinkClicked(item);
        e.stopPropagation();
    }

    private documentButtonClicked = (item: HelpUrls) => (e: Event) => {
        e.stopPropagation();
    }

    private buildColumns(): SmartTableColumn<HelpUrls>[] {
        return [
            {
                heading: 'Name',
                sortExpression: 'Name',
                headingClassName: 'text-left',
                className: 'text-left',
                cellTemplate: (item) => <span>{item.name}</span>
            }, {
                heading: 'Document',
                cellTemplate: (item) => <span>
                    {!!item.pdfUrl &&
                        <DownloadButton
                            className="btn-link"
                            button={this.props.helpListModal.buildDownloadButton(item)}
                            stopPropagation={true}
                            handlingMethod={DownloadButtonHandlingMethod.Inline}
                            target={this.props.helpListModal.isApp ? '_self' : '_blank'} />
                    }
                </span>
            }
        ];
    }

    public render({ helpListModal }: HelpListModalProps): JSX.Element {
        return helpListModal && helpListModal.isOpen &&
            <Fragment>

                {(!helpListModal.helpModal || !helpListModal.helpModal.isOpen) &&
                    <Modal
                        close={helpListModal.close}
                        contentClassName="bg-white"
                        heading="Help/Support"
                        headerButtons={[]}>

                        <Fragment>
                            <div class="mt-1">

                                {!!helpListModal.administratorName &&
                                    <div>
                                        <b>TEMS Company Admin: </b>
                                        {helpListModal.administratorName}
                                        <span> | </span>
                                        {helpListModal.administratorPhone}
                                        <span> | </span>
                                        <a href={"mailTo:" + helpListModal.administratorEmail}>{helpListModal.administratorEmail}</a>
                                    </div>
                                }

                                <div>
                                    <b>TEMS Technical Contact: </b>
                                    David Kocurek
                                    <span> | </span>
                                    (417) 389-5523
                                    <span> | </span>
                                    <a href="mailTo:david@enhancedimage.com">david@enhancedimage.com</a>
                                </div>
                            </div>

                            <div class="mt-1">
                                <SmartTable
                                    tableClassName="table-small"
                                    headerClassName="d-none d-md-table-header-group text-sm"
                                    table={helpListModal.table}
                                    columns={this.buildColumns()}
                                    showSearchClearButton={true} />
                            </div>
                        </Fragment>
                    </Modal>
                }

                {helpListModal.helpModal && helpListModal.helpModal.isOpen &&
                    <Modal
                        className="small"
                        contentClassName="flex"
                        heading="Help Video"
                        close={helpListModal.helpModal.close}>

                            <iframe
                                class="modal-video"
                                src={helpListModal.selectedVideoUrl}
                                title="YouTube video player"
                                frameBorder="0">
                            </iframe>
                    </Modal>
                }
            </Fragment>
        }
}

export default HelpListModal;
