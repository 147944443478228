"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TravelAndAccommodationsTileActions;
(function (TravelAndAccommodationsTileActions) {
    TravelAndAccommodationsTileActions["AccommodationsLoaded"] = "AccommodationsLoaded";
    TravelAndAccommodationsTileActions["ActivePaneChanged"] = "ActivePaneChanged";
    TravelAndAccommodationsTileActions["EventRequirementsSaved"] = "EventRequirementsSaved";
    TravelAndAccommodationsTileActions["GuestInfoSubmitted"] = "GuestInfoSubmitted";
    TravelAndAccommodationsTileActions["ArrivalInfoSubmitted"] = "ArrivalInfoSubmitted";
    TravelAndAccommodationsTileActions["DepartureInfoSubmitted"] = "DepartureInfoSubmitted";
    TravelAndAccommodationsTileActions["TravelTermsAndConditionsAccepted"] = "TravelTermsAndConditionsAccepted";
    TravelAndAccommodationsTileActions["DailyTransportInfoSubmitted"] = "DailyTransportInfoSubmitted";
    TravelAndAccommodationsTileActions["LodingInfoSubmitted"] = "LodingInfoSubmitted";
    TravelAndAccommodationsTileActions["LodgingTermsAndConfigurationsVisibilityChanged"] = "LodgingTermsAndConfigurationsVisibilityChanged";
    TravelAndAccommodationsTileActions["TransportTermsAndConfigurationsVisibilityChanged"] = "TransportTermsAndConfigurationsVisibilityChanged";
    TravelAndAccommodationsTileActions["HistoryVisibilityChanged"] = "HistoryVisibilityChanged";
    TravelAndAccommodationsTileActions["LodgingTermsAcceptanceCleared"] = "LodgingTermsAcceptanceCleared";
    TravelAndAccommodationsTileActions["TransportTermsAcceptanceCleared"] = "TransportTermsAcceptanceCleared";
    TravelAndAccommodationsTileActions["ArrivalDepartureDatesCompared"] = "ArrivalDepartureDatesCompared";
})(TravelAndAccommodationsTileActions || (TravelAndAccommodationsTileActions = {}));
exports.default = TravelAndAccommodationsTileActions;
