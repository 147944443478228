import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import MeetingNotesFormProps from './meetingNotesFormProps';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import DownloadButton from '../buttons/downloadButton';
import Alert from '../alerts/alert';
import FieldLoadingPlaceholder from '../fields/fieldLoadingPlaceholder';
import BinaryFileInputField from '../fields/binaryFileInputField';
import Field from '../fields/field';
import { RichTextField as FrontEndRichTextField, DateFormats, Icons } from 'core.frontend';
import DataList from '../dataList/dataList';
import EmptyState from '../uiStates/emptyState';
import Icon from '../icons/icon';
import FormattedDate from '../text/formattedDate';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import RichTextField from '../fields/richTextField';

class MeetingNotesForm extends ObservingComponent<MeetingNotesFormProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.meetingNotesForm.observationProvider);
    }

    private renderNoteContent(note: string): JSX.Element {
        return (
            <div
                class="readonly-meeting-detail-note-content"
                dangerouslySetInnerHTML={{ __html: note }} />
        );
    }

    public render({ meetingNotesForm }: MeetingNotesFormProps): JSX.Element {
        return (
            <Fragment>
                <ConfirmationPrompt confirmationPrompt={meetingNotesForm.deleteConfirmationPrompt} />

                {!meetingNotesForm.deleteConfirmationPrompt.isVisible &&
                    <div>
                        {!!meetingNotesForm.isVisible &&
                            <Fragment>
                                <div class="mt-1">
                                    <Alert alert={meetingNotesForm.alert} />

                                    <div class="readonly-meeting-detail-label">New Note/Attachment:</div>
                                    <div class="readonly-meeting-detail-section text-weight-normal flex pt-1">
                                        {meetingNotesForm.isLoading &&
                                            <div class="fill">
                                                <div class="w-100 mb-1">
                                                    <FieldLoadingPlaceholder />
                                                </div>
                                                <div class="w-100 mb-1">
                                                    <FieldLoadingPlaceholder />
                                                </div>
                                            </div>
                                        }

                                        {!meetingNotesForm.isLoading &&
                                            <div class="fill">
                                                <div class="flex w-100">
                                                    <BinaryFileInputField
                                                        className="ml-auto"
                                                        field={meetingNotesForm.fileUploadField}
                                                        buttonClassName="text-sm btn btn-sm btn-primary v-center"
                                                        leftIcon={Icons.Plus} />
                                                </div>

                                                <div class="form-container">
                                                    <Field
                                                        field={meetingNotesForm.newTitleTextField} />

                                                    <RichTextField
                                                        className="mb-0"
                                                        field={meetingNotesForm.newNoteTextField} />
                                                </div>

                                                {meetingNotesForm.newAttachments.map((attachment, index) => (
                                                    <div class="flex">
                                                        {attachment.id &&
                                                            <div class="v-center flex">
                                                                <Field
                                                                    className={'v-center input-xs ' + (attachment.isRemoved ? 'deleting' : '')}
                                                                    field={meetingNotesForm.buildAttachmentFileNameField(attachment, index)} />

                                                                <DownloadButton
                                                                    className={'btn-link text-sm v-center ' + (attachment.isRemoved ? 'deleting' : '')}
                                                                    button={meetingNotesForm.buildDownloadAttachmentButton(attachment)} />
                                                            </div>
                                                        }

                                                        {!attachment.id &&
                                                            <span class="v-center text-sm">{attachment.fileName}</span>
                                                        }

                                                        <Button
                                                            className="v-center text-sm p-0"
                                                            button={meetingNotesForm.buildRemoveAttachmentButton(attachment, index)} />

                                                        <Button
                                                            className="v-center text-sm p-0"
                                                            button={meetingNotesForm.buildCancelRemoveAttachmentButton(attachment, index)} />
                                                    </div>
                                                ))}

                                                <div class="flex mt-p5 mb-p5">
                                                    <div class="fill flex">
                                                        <div class="pl-4">
                                                            <b>Note content is:</b>
                                                        </div>
                                                        <div class="fill ml-2">
                                                            <Field
                                                                className="mb-0"
                                                                field={meetingNotesForm.noteContentRadioField} />

                                                            <div className={"text-sm " + (meetingNotesForm.noteContentFieldInvalid ? ' text-red' : '')}>
                                                                (Currently only "Unrestricted" Notes are supported and must be selected prior to saving)
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Button
                                                        className="btn-primary btn-sm"
                                                        color={ButtonColors.Primary}
                                                        button={meetingNotesForm.saveButton} />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div class="readonly-meeting-detail-label">All Notes:</div>
                                    <div class="readonly-meeting-detail-section text-weight-normal flex pt-1">
                                        {meetingNotesForm.isLoading &&
                                            <div class="fill">
                                                <div class="w-100 mb-1">
                                                    <FieldLoadingPlaceholder />
                                                </div>
                                                <div class="w-100 mb-1">
                                                    <FieldLoadingPlaceholder />
                                                </div>
                                            </div>
                                        }

                                        {!meetingNotesForm.isLoading &&
                                         !!meetingNotesForm.meetingNotes.length &&

                                            <div class="fill">
                                                <DataList
                                                    dataList={meetingNotesForm.notesDataList}
                                                    itemTemplate={(item, index) => (
                                                        <div class="flex">
                                                            <Icon
                                                                className="v-center text-lg ml-p5 mr-1"
                                                                icon={Icons.Report} />

                                                            <div class="fill v-center text-sm">
                                                                {!meetingNotesForm.editNoteForms[index] &&
                                                                    <Fragment>
                                                                        <span>{item.form.model.title}</span>
                                                                        {this.renderNoteContent(item.form.model.note)}
                                                                    </Fragment>
                                                                }

                                                                {!!meetingNotesForm.editNoteForms[index] &&
                                                                    <div class="form-container">
                                                                        <Field
                                                                            className="mb-0 text-sm"
                                                                            field={meetingNotesForm.editNoteForms[index].fields[0]} />

                                                                        <RichTextField
                                                                            className="mb-0 mt-p5 text-sm"
                                                                            field={meetingNotesForm.editNoteForms[index].fields[1] as FrontEndRichTextField} />
                                                                    </div>
                                                                }

                                                                <div class="text-gray">
                                                                    <span>
                                                                        Added on&nbsp;
                                                                    </span>

                                                                    <FormattedDate
                                                                        date={item.form.model.createdDateTime} />

                                                                    <span> at </span>

                                                                    <FormattedDate
                                                                        date={item.form.model.createdDateTime}
                                                                        format={DateFormats.hhmm} />

                                                                    <span>
                                                                        &nbsp;by&nbsp;
                                                                    </span>

                                                                    {item.form.model.userFullName}
                                                                </div>

                                                                {item.form.model.attachments.map((attachment) =>
                                                                    <div>
                                                                        <DownloadButton
                                                                            className="btn-link mr-1"
                                                                            iconClassName="ml-0"
                                                                            button={meetingNotesForm.buildDownloadAttachmentButton(attachment)} />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {!!item.form.model.isEditable && !meetingNotesForm.editNoteForms[index] &&
                                                                <Button
                                                                    className="btn-icon"
                                                                    button={meetingNotesForm.buildEditNoteButton(item.form.model, index)} />
                                                            }

                                                            {!!item.form.model.isDeletable && !meetingNotesForm.editNoteForms[index] &&
                                                                <Button
                                                                    className="btn-icon"
                                                                    button={meetingNotesForm.buildDeleteNoteButton(item.form.model)} />
                                                            }

                                                            {!!meetingNotesForm.editNoteForms[index] &&
                                                                <div class="v-center ml-1 flex column">
                                                                    <div class="mt-auto mb-p5 flex">
                                                                        <Button
                                                                            className="btn-sm btn-primary fill"
                                                                            button={meetingNotesForm.buildSaveNoteButton(item.form.model, index)} />
                                                                    </div>
                                                                    <div class="mb-auto mt-p5 flex">
                                                                        <Button
                                                                            className="btn-sm btn-secondary fill"
                                                                            button={meetingNotesForm.buildCancelEditNoteButton(item.form.model, index)} />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )} />
                                            </div>
                                        }

                                        {!meetingNotesForm.isLoading &&
                                            !meetingNotesForm.meetingNotes.length &&
                                            <div class="fill">
                                                <EmptyState
                                                    title={'No Notes Found'}
                                                    message="This meeting has no notes" />
                                            </div>
                                        }

                                    </div>
                                </div>

                                {!meetingNotesForm.isLoading &&
                                    <p class="text-sm">
                                        {meetingNotesForm.meetingNotesDisclaimer}
                                    </p>
                                }
                            </Fragment>
                        }
                    </div>
                }
            </Fragment>
        );
    }
}

export default MeetingNotesForm;
