import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import GroupContainer from '../groupContainers/groupContainer';
import EventOverviewLayoutProps from './eventOverviewLayoutProps';
import { EventInventoryRequestStatus, Icons, EventStatus } from 'core.frontend';
import Icon from '../icons/icon';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import Field from '../fields/field';
import SearchField from '../fields/searchField';

class EventOverviewLayout extends ObservingComponent<EventOverviewLayoutProps> {

    public render({
        isLoaded,
        eventOverviewData,
        className,
        editAttendeesButton,
        saveAttendeesButton,
        isEditAttendeeMode,
        userSearchField,
        addAttendeesButton,
        buildRemoveAttendeeButton,
        isEditInventoryRequestMode,
        inventoryRequestSearchField,
        addInventoryRequestButton,
        saveInventoryRequestButton,
        buildRemoveInventoryRequestButton,
        editInventoryRequestButton,
        fulfilledInventoryRequestNoteFields,
        unfulfilledInventoryRequestNoteFields
    }: EventOverviewLayoutProps): JSX.Element {

        return (
            <div class={"event-overview " + className}>
                {isLoaded && (
                    <div>
                        <div class="header">
                            <div class="date">
                                <div>{eventOverviewData.startMonth} {eventOverviewData.startYear}</div>
                                <div>{eventOverviewData.startDay} - {eventOverviewData.endDay}</div>
                            </div>
                            <div class="name">
                                <div>{eventOverviewData.name}</div>
                                <div>{eventOverviewData.city}, {eventOverviewData.state}</div>


                                {eventOverviewData.status === EventStatus.Archived &&
                                    <span class="text-red">Archived</span>
                                }

                                {eventOverviewData.status === EventStatus.Deleted &&
                                    <span class="text-red">Deleted</span>
                                }
                            </div>
                        </div>

                        <hr/>

                        <b>Event Info</b>
                        <div class="pane">
                            <div class="flex">
                                <div class="flex-col-3">
                                    <b>Event Producer:</b>
                                    <br/>
                                    {eventOverviewData.eventProducerFullName}
                                </div>

                                <div class="flex-col-3">
                                    <b>Leads ({eventOverviewData.eventLeads.length}):</b>
                                    {eventOverviewData.eventLeads.map((eventLead) =>
                                        <div>{eventLead.businessAreaName} / {eventLead.eventLeadResponsibility} - {eventLead.fullName}</div>
                                    )}

                                    {eventOverviewData.eventLeads.length === 0 &&
                                        <div><i>No event leads have been set</i></div>
                                    }
                                </div>

                                <div class="flex-col-3">
                                    <b>Event Weblinks:</b>
                                    {eventOverviewData.webLinks.map((webLink) =>
                                        <div>
                                            <a
                                                href={webLink.url}
                                                target="_blank">

                                                    {webLink.url}
                                            </a>
                                        </div>
                                    )}

                                    {!eventOverviewData.webLinks.length &&
                                        <div><i>No web links</i></div>
                                    }
                                </div>
                            </div>

                            {eventOverviewData.notesComments &&
                                <div class="p-1">
                                    <b>Notes:</b> {eventOverviewData.notesComments}
                                </div>
                            }
                        </div>

                        {isEditAttendeeMode && userSearchField && addAttendeesButton && saveAttendeesButton &&
                            <div class="form-container search-field-wrapper pb-1">
                                <SearchField
                                    className="v-center no-label"
                                    searchField={userSearchField} />

                                <Button
                                    size={ButtonSizes.Small}
                                    color={ButtonColors.Primary}
                                    button={addAttendeesButton} />

                                <Button
                                    className="ml-auto p-0 text-sm"
                                    button={saveAttendeesButton} />
                            </div>
                        }

                        {eventOverviewData.attendees.length === 0 &&
                            <div>
                                <div class="flex">
                                    <b>Attendees</b>

                                    {editAttendeesButton && !isEditAttendeeMode && eventOverviewData.daysTillEvent > 30 &&
                                        <Button
                                            className="ml-auto p-0 text-sm"
                                            button={editAttendeesButton} />
                                    }
                                </div>

                                <div class="pane text-center">
                                    <i>No one is set to attend this event</i>
                                </div>
                            </div>
                        }

                        {eventOverviewData.attendeeArrayGroup &&
                        eventOverviewData.attendeeArrayGroup.map((attendeeGroup, index) =>
                            <div>
                                <div class="flex">
                                    <b class="fill mt-auto">
                                        {attendeeGroup.groupHeading} ({attendeeGroup.data.length})
                                    </b>

                                    {editAttendeesButton && index === 0 && !isEditAttendeeMode && eventOverviewData.daysTillEvent > 30 &&
                                        <Button
                                            className="ml-auto p-0 text-sm"
                                            button={editAttendeesButton} />
                                    }
                                </div>

                                <GroupContainer
                                    className="pane flex"
                                    itemClassName="flex-col-3"
                                    hideHeaderOnNoGroup={true}

                                    headingTemplate={(group) => (
                                        <b>{group.groupHeading} ({group.data.length})</b>
                                    )}

                                    itemWrapperTemplate={(group, itemTemplate) => (
                                        <div>
                                            {itemTemplate}
                                        </div>
                                    )}

                                    itemTemplate={(attendee) =>
                                        <div class={'flex ' + (attendee.isRemoved ? 'deleting ' : '')}>
                                            <span>{attendee.fullName}</span>

                                            {isEditAttendeeMode &&
                                                <Button
                                                    className="btn-icon text-sm ml-p5"
                                                    button={buildRemoveAttendeeButton(attendee)} />
                                            }
                                        </div>
                                    }

                                    arrayGroups={attendeeGroup.subGroup}
                                />
                            </div>
                        )}

                        {eventOverviewData.notAttendingAttendeesGroup &&
                        !!eventOverviewData.notAttendingAttendeesGroup.length &&
                            <div>
                                <b>Not Attending ({eventOverviewData.notAttendingAttendees.length})</b>
                                <GroupContainer
                                    className="pane flex"
                                    itemClassName="flex-col-3"
                                    hideHeaderOnNoGroup={true}

                                    headingTemplate={(group) => (
                                        <div class="flex">
                                            <b class="fill mt-auto">
                                                {group.groupHeading} ({group.data.length})
                                            </b>

                                            {editAttendeesButton && eventOverviewData.attendees.length === 0 && !isEditAttendeeMode && eventOverviewData.daysTillEvent > 30 &&
                                                <Button
                                                    className="ml-auto p-0 text-sm"
                                                    button={editAttendeesButton} />
                                            }
                                        </div>
                                    )}

                                    itemWrapperTemplate={(group, itemTemplate) => (
                                        <div>
                                            {itemTemplate}
                                        </div>
                                    )}

                                    itemTemplate={(notAttendingAttendee) =>
                                        <div class={'flex ' + (notAttendingAttendee.isRemoved ? 'deleting ' : '')}>
                                            <span>{notAttendingAttendee.fullName}</span>

                                            {isEditAttendeeMode &&
                                                <Button
                                                    className="btn-icon text-sm ml-p5"
                                                    button={buildRemoveAttendeeButton(notAttendingAttendee)} />
                                            }
                                        </div>
                                    }

                                    arrayGroups={eventOverviewData.notAttendingAttendeesGroup}>
                                </GroupContainer>
                            </div>
                        }

                        {eventOverviewData.inventoryManagementEnabled &&

                            <div>
                                {isEditInventoryRequestMode && inventoryRequestSearchField && addInventoryRequestButton && saveInventoryRequestButton &&
                                    <div class="form-container search-field-wrapper pb-1">
                                        <SearchField
                                            className="v-center no-label"
                                            searchField={inventoryRequestSearchField} />

                                        <Button
                                            size={ButtonSizes.Small}
                                            color={ButtonColors.Primary}
                                            button={addInventoryRequestButton} />

                                        <Button
                                            className="ml-auto p-0 text-sm"
                                            button={saveInventoryRequestButton} />
                                    </div>
                                }

                                <div>
                                    <div class="flex">
                                        <b class="fill mt-auto">
                                            Inventory
                                        </b>

                                        {editInventoryRequestButton && !isEditInventoryRequestMode && eventOverviewData.daysTillEvent > 30 &&
                                            <Button
                                                className="ml-auto p-0 text-sm"
                                                button={editInventoryRequestButton} />
                                        }
                                    </div>

                                    <div class="pane flex">
                                        <div class="flex-col-2">

                                            {!!eventOverviewData.eventInventoryRequests.length &&
                                                <div>
                                                    <div>
                                                        <b>Unfulfilled Inventory Requests ({eventOverviewData.fulfilledEventInventoryRequests && eventOverviewData.fulfilledEventInventoryRequests.length})</b>

                                                        {eventOverviewData.fulfilledEventInventoryRequests && eventOverviewData.fulfilledEventInventoryRequests.map((request, index) =>
                                                            <div class="mt-p5">
                                                                <span class={(request.isRemoved ? 'deleting ' : '')}>
                                                                    <span class={request.eventInventoryRequestStatus === EventInventoryRequestStatus.Requested ? 'text-red' : ''}>
                                                                        {request.inventoryItemGroupName}
                                                                    </span>

                                                                    {request.eventInventoryRequestStatus === EventInventoryRequestStatus.Fulfilled &&
                                                                        <span class="text-green"> <Icon icon={Icons.Check} /> Fulfilled</span>
                                                                    }
                                                                </span>

                                                                {isEditInventoryRequestMode &&
                                                                    <Button
                                                                        className="btn-icon text-sm ml-p5"
                                                                        button={buildRemoveInventoryRequestButton(request)} />
                                                                }

                                                                {isEditInventoryRequestMode &&
                                                                    <Field
                                                                        field={unfulfilledInventoryRequestNoteFields[index]} />
                                                                }

                                                                {!isEditInventoryRequestMode && !!request.notes && <div>Notes: {request.notes}</div> }
                                                            </div>
                                                        )}

                                                    </div>

                                                    <div class="mt-1">
                                                        <b>Fulfilled Inventory Requests ({eventOverviewData.unfulfilledEventInventoryRequests && eventOverviewData.unfulfilledEventInventoryRequests.length})</b>

                                                            {eventOverviewData.unfulfilledEventInventoryRequests && eventOverviewData.unfulfilledEventInventoryRequests.map((request, index) =>
                                                                <div class="mt-p5">
                                                                    <span class={(request.isRemoved ? 'deleting ' : '')}>
                                                                        <span class={request.eventInventoryRequestStatus === EventInventoryRequestStatus.Requested ? 'text-red' : ''}>
                                                                            {request.inventoryItemGroupName}
                                                                        </span>

                                                                        {request.eventInventoryRequestStatus === EventInventoryRequestStatus.Fulfilled &&
                                                                            <span class="text-green"> <Icon icon={Icons.Check} /> Fulfilled</span>
                                                                        }
                                                                    </span>

                                                                    {isEditInventoryRequestMode &&
                                                                        <Button
                                                                            className="btn-icon text-sm ml-p5"
                                                                            button={buildRemoveInventoryRequestButton(request)} />
                                                                    }

                                                                    {isEditInventoryRequestMode &&
                                                                        <Field
                                                                            field={fulfilledInventoryRequestNoteFields[index]} />
                                                                    }

                                                                    {!!request.notes && <div>Notes: {request.notes}</div> }
                                                                </div>
                                                            )}

                                                    </div>
                                                </div>
                                            }

                                            {!eventOverviewData.eventInventoryRequests.length &&
                                                <div class="p-1 text-center">
                                                    <i>No inventory requests</i>
                                                </div>
                                            }
                                        </div>

                                        <div class="flex-col-2">
                                            <b>Assigned Inventory Items ({eventOverviewData.eventInventory.length})</b>

                                            {eventOverviewData.eventInventory.length > 0 &&
                                                <GroupContainer
                                                    className="mt-1"
                                                    hideHeaderOnNoGroup={true}

                                                    headingTemplate={(group) => (
                                                        <div></div>
                                                    )}

                                                    itemWrapperTemplate={(group, itemTemplate) => (
                                                        <div>
                                                            <b>{group.groupHeading} ({group.data.length})</b>
                                                            {itemTemplate}
                                                        </div>
                                                    )}

                                                    itemTemplate={(eventInventory) => (
                                                        <div class="pl-2 mb-p5">
                                                            {eventInventory.name}
                                                            <br/>

                                                            Create#: {
                                                                eventInventory.crates.map((crate, index) => {

                                                                    const seperator = index + 1 < eventInventory.crates.length ? '&' : '';

                                                                    if (crate.number) {
                                                                        return <span> {crate.number} {seperator}</span>
                                                                    } else {
                                                                        return <i> Unknown {seperator}</i>
                                                                    }
                                                                })}

                                                                    &nbsp;|&nbsp;

                                                                Scale: {eventInventory.scale ? eventInventory.scale : <i>Unknown</i>}
                                                        </div>
                                                    )}

                                                    arrayGroups={eventOverviewData.eventInventoryArrayGroup}>
                                                </GroupContainer>
                                            }

                                            {eventOverviewData.inventoryNotes &&
                                                <div>
                                                    <b>Notes: </b>
                                                    {eventOverviewData.inventoryNotes}
                                                </div>
                                            }

                                            {!eventOverviewData.eventInventory.length &&
                                                <div class="text-center p-1">
                                                    <i>No inventory items assigned to this event</i>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )}

                {!isLoaded && (
                    <div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                        <div className="loading-placeholder shimmer"></div>
                    </div>
                )}
            </div>
        )}
}

export default EventOverviewLayout;
