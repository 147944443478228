import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import PageContentWrapperProps from './pageContentWrapperProps';
import { NavigatorActions, AlertActions } from 'core.frontend';
import PageFooter from '../footer/pageFooter';

class PageContentWrapper extends ObservingComponent<PageContentWrapperProps> {
    private scrollObserverId: number;
    private alertScrollObserverId: number;
    private topOfContentElementId: string = 'topOfContentMarker';

    public componentWillMount(): void {
        this.registerUpdateObserver(
            this.props.app.navigator.observationProvider,
            NavigatorActions.PageChanged
        );

        this.scrollObserverId = this.props.app.navigator.observationProvider.observe(this.scrollTop, NavigatorActions.PageChanged);
        this.alertScrollObserverId = this.props.app.navigator.currentPage.alert.observationProvider.observe(this.scrollTop, AlertActions.Shown);
    }

    public componentWillUnmount(): void {
        this.props.app.navigator.observationProvider.stopObserving(this.scrollObserverId);
        this.props.app.navigator.observationProvider.stopObserving(this.alertScrollObserverId);
    }

    private scrollTop = () => {
        document.getElementById(this.topOfContentElementId).scrollIntoView();
    }

    public render({app}: PageContentWrapperProps): JSX.Element {
        return (
            <main>
                <span
                    id={this.topOfContentElementId}
                    class="top-of-content-marker">
                </span>

                <form noValidate>
                    {app.getCurrentPageComponent()}
                </form>

                <PageFooter app={app} />
            </main>
        );
    }
}

export default PageContentWrapper;
