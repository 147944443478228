"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var DeviceRegistrationPage = /** @class */ (function (_super) {
    __extends(DeviceRegistrationPage, _super);
    function DeviceRegistrationPage(api, navigator, currentUserManager, userDeviceId) {
        var _this = _super.call(this, __1.PageTypes.DeviceRegistration, 'Register New Device') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.userDeviceId = userDeviceId;
        _this.submitVerificationCodeClicked = function () {
            _this._submitVerificationCodeForm.submit();
            if (!_this._submitVerificationCodeForm.isValid) {
                _this.submitVerivicationCodeButton.setProcessing(false);
            }
        };
        _this.loadDeviceRegistrationData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._isLoading = true;
                this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.Loading);
                return [2 /*return*/, this.api.get(__1.EndpointUrls.Authentication + '/DeviceRegistrationData' + '/' + this.userDeviceId)
                        .then(function (data) {
                        _this._deviceRegistrationData = data;
                        _this._isLoading = false;
                        _this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.FormLoaded);
                    })
                        .catch(function () {
                        _this._isLoading = false;
                        _this.handleError('Unable to load device registration data. Please contact your system administrator.');
                        _this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.ErrorSubmitting);
                    })];
            });
        }); };
        _this.sendEmailVerification = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.FormSubmitted);
                        request = {
                            userDeviceId: this.userDeviceId,
                            verificationMethod: 'Email',
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Authentication + '/SendDeviceRegistrationCode', request)];
                    case 2:
                        _b.sent();
                        this.sendEmailButton.setProcessing(false);
                        this._verificationCodeSent = true;
                        this.buildSubmitVerificationCodeForm();
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.SubmittedSuccessfully);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        this.handleError('Unable to send verification code. Please contact your system administrator.');
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.ErrorSubmitting);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.sendSmsVerification = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.FormSubmitted);
                        request = {
                            userDeviceId: this.userDeviceId,
                            verificationMethod: 'Sms',
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Authentication + '/SendDeviceRegistrationCode', request)];
                    case 2:
                        _b.sent();
                        this.sendSmsButton.setProcessing(false);
                        this._verificationCodeSent = true;
                        this.buildSubmitVerificationCodeForm();
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.SubmittedSuccessfully);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        this.handleError('Unable to send verification code. Please contact your system administrator.');
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.ErrorSubmitting);
                        return [3 /*break*/, 4];
                    case 4:
                        ;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildSubmitVerificationCodeForm = function () {
            _this._submitVerificationCodeForm = new __1.Form([
                new __1.TextField('verificationCode', 'Verification Code', true, 10, 'Code is required')
            ]);
            _this._submitVerificationCodeForm.setModel({
                verificationCode: null,
                userDeviceId: _this.userDeviceId
            });
            _this._submitVerificationCodeForm.observationProvider.observe(_this.submitVerificationCode, __1.FormActions.FormSubmitted);
        };
        _this.submitVerificationCode = function () { return __awaiter(_this, void 0, void 0, function () {
            var url, authenticatedUserDto, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.submitVerivicationCodeButton.setProcessing(true);
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.FormSubmitted);
                        url = __1.EndpointUrls.Authentication + '/VerifyDeviceRegistration';
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(url, this._submitVerificationCodeForm.model, { withCredentials: true })];
                    case 2:
                        authenticatedUserDto = _b.sent();
                        this.currentUserManager.login(authenticatedUserDto);
                        this.submitVerivicationCodeButton.setProcessing(false);
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.SubmittedSuccessfully);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        this.submitVerivicationCodeButton.setProcessing(false);
                        this.handleError('Your verification code is either invalid or has expired. Please try again');
                        this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.ErrorSubmitting);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.backButtonClicked = function () {
            if (_this._verificationCodeSent) {
                _this._verificationCodeSent = false;
                _this._submitVerificationCodeForm = null;
                _this.observationProvider.notifyObservers(__1.DeviceRegistrationPageActions.BackButtonClicked);
            }
            else {
                _this.navigator.goToLogin();
            }
        };
        _this.handleError = function (message) {
            _this.sendSmsButton.setProcessing(false);
            _this.sendEmailButton.setProcessing(false);
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Unable to complete request',
                message: message
            });
        };
        _this._isLoading = true;
        _this.observationProvider = new __1.ObservationProvider();
        _this.sendEmailButton = new button_1.default({
            text: 'Send Email',
            onClick: _this.sendEmailVerification,
            processingText: 'Submitting...'
        });
        _this.sendSmsButton = new button_1.default({
            text: 'Send Text Message',
            onClick: _this.sendSmsVerification,
            processingText: 'Submitting...'
        });
        _this.submitVerivicationCodeButton = new button_1.default({
            text: 'Register Device',
            onClick: _this.submitVerificationCodeClicked,
            processingText: 'Submitting...',
            buttonType: __1.ButtonTypes.Submit
        });
        _this.backButton = new button_1.default({
            text: 'Back',
            onClick: _this.backButtonClicked
        });
        _this.loadDeviceRegistrationData();
        return _this;
    }
    Object.defineProperty(DeviceRegistrationPage.prototype, "submitVerificationCodeForm", {
        get: function () {
            return this._submitVerificationCodeForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceRegistrationPage.prototype, "verificationCodeSent", {
        get: function () {
            return this._verificationCodeSent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceRegistrationPage.prototype, "deviceRegistrationData", {
        get: function () {
            return this._deviceRegistrationData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceRegistrationPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    return DeviceRegistrationPage;
}(__1.BasePage));
exports.default = DeviceRegistrationPage;
