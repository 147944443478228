import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FormButtonContainerProps from './formButtonContainerProps';

class FormButtonContainer extends ObservingComponent<FormButtonContainerProps> {

    public render({ leftButtons, rightButtons, className }: FormButtonContainerProps): JSX.Element {
        return (
            <div class={"form-footer " + className}>
                <div class="btn-container flex">
                    <div class="fill flex text-left">
                        {leftButtons && leftButtons.map((button) => button)}
                    </div>

                    {rightButtons && rightButtons.map((button) => button)}
                </div>
            </div>
        );
    }
}

export default FormButtonContainer;
