import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import VendorEventLandingPageProps from './vendorEventLandingPageProps';
import EventInfoDetailTile from '../events/eventInfoDetailTile';
import ReportsTile from '../events/reportsTile';
import EmailAndServicesTile from './tiles/emailAndServicesTile';
import HotelConfigurationsTile from './tiles/hotelConfigurationsTile';
import TransportationConfigurationTile from './tiles/transportationConfigurationTile';
import Dropdown from '../dropdown/dropdown';
import { VendorEventLandingPageActions } from 'core.frontend';
import Alert from '../alerts/alert';

class VendorEventLandingPage extends ObservingComponent<VendorEventLandingPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider, [VendorEventLandingPageActions.DataLoaded]);
    }

    public render({ page }: VendorEventLandingPageProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <Dropdown dropdown={page.dropdown} />
                </div>

                <Alert alert={page.alert} />

                <EventInfoDetailTile tile={page.eventInfoTile}></EventInfoDetailTile>
                <EmailAndServicesTile tile={page.emailAndServicesTile}></EmailAndServicesTile>
                <HotelConfigurationsTile tile={page.hotelConfigurationsTile}></HotelConfigurationsTile>
                <TransportationConfigurationTile tile={page.transportationConfigurationTile}></TransportationConfigurationTile>
                <ReportsTile tile={page.reportsTile}></ReportsTile>
            </div>
        );
    }
}

export default VendorEventLandingPage;
