import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import InventorySchedulerPageProps from './inventorySchedulerPageProps';
import EmptyState from '../uiStates/emptyState';
import { Month, InventorySchedulerDateStatus } from 'core.frontend';
import FormattedDate from '../text/formattedDate';
import Card from '../containers/card';
import Button from '../buttons/button';
import EventOverview from '../events/eventOverview';
import FormButtonContainer from '../containers/formButtonContainer';
import ButtonColors from '../buttons/buttonColors';
import GroupContainer from '../groupContainers/groupContainer';
import VideoModalButton from '../buttons/videoModalButton';

class InventorySchedulerPage extends ObservingComponent<InventorySchedulerPageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: InventorySchedulerPageProps): JSX.Element {
        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                {!page.selectedRequest &&
                    <Card
                        headingRightContent={
                            <div>

                                <Button
                                    className="btn-icon btn-lg"
                                    button={page.helpButton} />
                            </div>
                        }>
                        <div>
                            <h4>Inventory Requests</h4>

                            {page.isLoadingRequests &&
                                <div class="text-center">Loading...</div>
                            }

                            {!page.isLoadingRequests &&
                                <div>
                                    {page.inventoryRequests.length > 0 &&

                                        <GroupContainer
                                            arrayGroups={page.inventoryRequests}
                                            hideHeaderOnNoGroup={true}

                                            headingTemplate={(group, index) => (
                                                <div class="attendee-header">
                                                    {group.groupHeading}

                                                    <div class="text-sm">
                                                        {page.getEventGroupSubHeading(index)}
                                                    </div>
                                                </div>
                                            )}

                                            itemWrapperTemplate={(group, itemTemplate) => (
                                                <ul class="mt-0 mb-0 data-list text-sm">
                                                    {itemTemplate}
                                                </ul>
                                            )}

                                            itemTemplate={(request, index) => (
                                                <li key={index}>
                                                    <Button
                                                        className="btn-sm"
                                                        button={page.buildInventoryRequestButton(request)} />
                                                </li>
                                            )}
                                        ></GroupContainer>
                                    }

                                    {!page.inventoryRequests.length &&
                                        <EmptyState
                                            title=""
                                            message="No incomplete inventory requests found" />
                                    }
                                </div>
                            }
                        </div>
                    </Card>
                }

                {page.selectedRequest &&
                    <div>
                        <Card>
                            <div>
                                <div>
                                    <b>Requested Group: {page.selectedRequest.inventoryItemGroupName}</b>
                                </div>

                                <div class="text-sm mt-1">
                                    <div>
                                        <b>Requested By:</b> {page.selectedRequest.requestedByUsersFullName} on <FormattedDate date={page.selectedRequest.createdDateTime} />
                                    </div>
                                    <div>
                                        <b>Event: </b>
                                        <Button
                                            className="btn-icon btn-sm"
                                            button={page.buildEventOverviewButton(page.selectedRequest)} />
                                    </div>
                                    <div class="disabled">
                                        <FormattedDate date={page.selectedRequest.eventStartDate} /> - <FormattedDate date={page.selectedRequest.eventEndDate} /> | {page.selectedRequest.eventCity} {page.selectedRequest.eventCountry}
                                    </div>
                                    <div>
                                        <b>Requested Date Range:</b> <FormattedDate date={page.selectedRequest.preEventInventoryReservationDate} /> - <FormattedDate date={page.selectedRequest.postEventInventoryReleaseDate} />
                                    </div>
                                    <div>
                                        <b>Notes: </b>
                                        {page.selectedRequest.notes}
                                        {!page.selectedRequest.notes && <i> No notes</i>}
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <Card>
                            <div>
                                {page.errorMessage &&
                                    <div class="alert alert-danger">
                                        {page.errorMessage}
                                    </div>
                                }

                                <div class="flex overflow-x-auto">
                                    <div class="text-sm">

                                        <div class="inventory-scheduler-top-row"></div>

                                        <div class="inventory-scheduler-request-header flex">
                                            <b class="v-center">Current Request</b>
                                        </div>

                                        <div class="inventory-scheduler-vertical-spacer inventory-scheduler-vertical-spacer-header">
                                            <b>Similar Requests</b>
                                        </div>

                                        {!page.otherRequests.length &&
                                            <div class="inventory-scheduler-empty-row flex">
                                                <i class="v-center">No other requests</i>
                                            </div>
                                        }

                                        {page.otherRequests.map((request) =>
                                            <div class="inventory-scheduler-item-header flex">
                                                <div class="v-center">
                                                    <b>Requested By: </b>{request.requestedByUsersFullName} on <FormattedDate date={request.createdDateTime} />
                                                    <div><b>Notes:</b>
                                                        {!request.notes && <i> No notes</i>}
                                                        {request.notes}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div class="inventory-scheduler-vertical-spacer inventory-scheduler-vertical-spacer-header">
                                            <b>Inventory Items</b>
                                        </div>

                                        {page.inventoryItems.map((inventoryItem) =>
                                            <div class="inventory-scheduler-item-header flex">

                                                <div class="v-center">
                                                    {page.selectedInventoryItem &&
                                                    inventoryItem.inventoryItemId === page.selectedInventoryItem.inventoryItemId &&

                                                        <i class="inventory-item-selction-check ti ti-check text-green text-lg"></i>
                                                    }
                                                </div>

                                                <span class="v-center">
                                                    <Button
                                                        className="btn-icon"
                                                        button={page.buildInventoryItemButton(inventoryItem)}>
                                                            <b>
                                                                {inventoryItem.inventoryItemName}
                                                            </b>
                                                    </Button>

                                                    <br/>
                                                    Serial/Item #: <span> {inventoryItem.serialNumber ? inventoryItem.serialNumber : <i>Unknown</i>}</span> |

                                                    Crate#: <span> {inventoryItem.crateNumbers ? inventoryItem.crateNumbers : <i>Unknown</i>}</span>
                                                </span>
                                            </div>
                                        )}

                                        {!page.inventoryItems.length &&
                                            <div class="inventory-scheduler-empty-row flex">
                                                <i class="v-center">No inventory items in group</i>
                                            </div>
                                        }
                                    </div>

                                    <div class="fill ml-1">
                                        <div class="flex scheduler-timeline">
                                            {page.schedulerMonths.map((month: Month) =>
                                                <div class="fill text-center text-sm scheduler-month-wrapper">
                                                    <div class="inventory-scheduler-top-row">
                                                        <b>{month.name}</b>

                                                        <div class="flex mt-p5">
                                                            {page.getSchedulerDates(month).map((date: Date) =>
                                                                <div class="fill">
                                                                    {date.getDate()}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="flex inventory-scheduler-row-wrapper">
                                                        {page.getSchedulerDates(month).map((date: Date) => {

                                                            const requestInDateRange = page.isInRequestDateRange(date, page.selectedRequest);

                                                            return (
                                                                <div class={'fill inventory-scheduler-item-block ' + (
                                                                    requestInDateRange ? ' event' : ''
                                                                )}>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div class="fill inventory-scheduler-vertical-spacer ">
                                                        &nbsp;
                                                    </div>

                                                    {!page.otherRequests.length &&
                                                        <div class="inventory-scheduler-empty-row">&nbsp;</div>
                                                    }

                                                    {page.otherRequests.map((request) =>
                                                        <div class="flex inventory-scheduler-row-wrapper">
                                                            {page.getSchedulerDates(month).map((date: Date) => {

                                                                const inventoryItemDateStatus = page.isInRequestDateRange(date, request);

                                                                const isStartDateOfEvent = page.findEventInRequestByDate(date, request);

                                                                return (
                                                                    <div class={'fill inventory-scheduler-item-block ' + (
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.PreEvent ? ' pre-event ' :
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.Event ? ' event ' :
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.PostEvent ? ' post-event ' :
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.Conflict ? ' conflict ' : ''
                                                                    )}>

                                                                        { isStartDateOfEvent &&
                                                                            <div class="scheduler-event-info">
                                                                                <Button
                                                                                    className="btn-icon"
                                                                                    button={page.buildEventOverviewButton(request)} />
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}

                                                    <div class="fill inventory-scheduler-vertical-spacer ">
                                                        &nbsp;
                                                    </div>

                                                    {page.inventoryItems.map((inventoryItem) =>
                                                        <div class="flex inventory-scheduler-row-wrapper">
                                                            {page.getSchedulerDates(month).map((date: Date) => {

                                                                const itemToReference =
                                                                        page.selectedInventoryItem &&
                                                                        page.selectedInventoryItem.inventoryItemId === inventoryItem.inventoryItemId ?
                                                                            page.selectedInventoryItem :
                                                                            inventoryItem;

                                                                const inventoryItemDateStatus = page.isInventoryItemInRequestDateRange(date, itemToReference);

                                                                const event = page.findEventInInventoryItemByDate(date, itemToReference);

                                                                return (
                                                                    <div class={'fill inventory-scheduler-item-block ' + (
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.PreEvent ? ' pre-event ' :
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.Event ? ' event ' :
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.PostEvent ? ' post-event ' :
                                                                        inventoryItemDateStatus === InventorySchedulerDateStatus.Conflict ? ' conflict ' : ''
                                                                    )}>

                                                                        { event &&
                                                                            <div class="scheduler-event-info">
                                                                            <Button
                                                                                className="btn-icon"
                                                                                button={page.buildEventOverviewButton(event)} />
                                                                        </div>
                                                                        }

                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}

                                                    {!page.inventoryItems.length &&
                                                        <div class="inventory-scheduler-empty-row">&nbsp;</div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>

                        <FormButtonContainer
                            className='sticky-button-footer'
                            rightButtons={[
                                <Button
                                    color={ButtonColors.Secondary}
                                    button={page.backButton} />,
                                <Button
                                    color={ButtonColors.Primary}
                                    button={page.saveButton} />
                            ]} />

                        <EventOverview eventOverview={page.eventOverview} />
                    </div>
                }
            </div>
        );
    }
}

export default InventorySchedulerPage;
