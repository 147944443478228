import { BadgingSearchListItem, BadgingTileActions, DateFormats, DateUtils, Icons, NewCustomerMeeting } from 'core.frontend';
import { h, JSX } from 'preact';
import BadgingTileProps from './badgingTileProps';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import ObservingComponent from '../componentBases/observingComponent';
import Card from '../containers/card';
import Modal from '../containers/modal';
import QuickAddCustomerForm from '../customers/quickAddCustomerForm';
import Field from '../fields/field';
import Icon from '../icons/icon';
import SmartTable from '../smartTable/smartTable';
import TableLoadingPlaceholderTBody from '../table/tableLoadingPlaceholderTBody';
import FormattedDate from '../text/formattedDate';
import EmptyState from '../uiStates/emptyState';
import EventInfoTile from './eventInfoTile';
import SmartTableColumn from '../smartTable/smartTableColumn';

class BadgingTile extends ObservingComponent<BadgingTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider);
    }

    constructor(props: BadgingTileProps) {
        super(props);

        props.tile.observationProvider.observe(this.printBadges, BadgingTileActions.BadgesPrinted);
        props.tile.observationProvider.observe(this.printGuestBadge, BadgingTileActions.GuestBadgePrinted);
    }

    private getBadgeTemplate = () => {

        let logoStyle = '';
        if (this.props.tile.printCompanyLogoCheckbox.value) {
            logoStyle = 'background-image: url(' + this.props.tile.logoDataUrl + ')';
        }

        let rankPlaceholder = '';
        if (this.props.tile.printRankCheckbox.value) {
            rankPlaceholder = '[Rank]';
        }

        let titlePlaceholder = '';
        if (this.props.tile.printTitleCheckbox.value) {
            titlePlaceholder = '[Title]';
        }

        let rankTitleSeparatorPlaceholder = '';
        if (this.props.tile.printTitleCheckbox.value && this.props.tile.printRankCheckbox.value) {
            rankTitleSeparatorPlaceholder = '[RankTitleSeparator]';
        }

        let namePlaceholder = '';
        if (this.props.tile.printNameCheckbox.value) {
            namePlaceholder = '[FirstName] [LastName]';
        }

        let companyPlaceHolder = '';
        if (this.props.tile.printCompanyNameCheckbox.value) {
            companyPlaceHolder = '[Company]';
        }

        let abbreviatedWeekDay = '';
        if (this.props.tile.printDayOfWeekCheckbox.value) {
            abbreviatedWeekDay = '' +
                '<div style="position: absolute; bottom: 0; right: 0; font-size: 18pt; font-weight: bold; width: 125px; height: 60px; text-align: center; padding-top: 10px; background-color: #000; color: #FFF; padding-top: 15px; box-sizing: border-box;">' +
                    '[AbbreviatedWeekDay]' +
                '</div>';
        }

        return '' +
            '<div style="width: 500px; height: 280px; text-align: center; font-family: sans-serif; position: relative; overflow: hidden;">' +

                '<div style="height: 98px; width: 100%; background-repeat: no-repeat; background-position: center; background-size: 300px auto; ' + logoStyle + ' ">&nbsp;</div>' +

                '<div style="max-height:50px; padding: 5px 30px; font-size: 12pt; font-weight: normal;">' +
                    rankPlaceholder + ' ' + rankTitleSeparatorPlaceholder + ' ' + titlePlaceholder +
                '</div>' +

                '<div style="max-height: 120px; padding: 5px 30px;font-size: 21pt; font-weight: bold;">' +
                    namePlaceholder +
                '</div>' +

                '<div style=" padding: 5px 30px; font-size: 12pt; font-weight: normal;">' +
                    companyPlaceHolder +
                '</div>' +

                abbreviatedWeekDay +

            '</div >';
    }

    private getSeparatorTemplate = () => {

        return '' +
            '<div style="width: 500px; height: 280px; text-align: center; font-family: sans-serif; position: relative; overflow: hidden;">' +

                '<div style="height: 98px; width: 100%; background-repeat: no-repeat; background-position: center; background-size: 300px auto;">&nbsp;</div>' +

                '<div style="max-height:50px; padding: 5px 30px; font-size: 12pt; font-weight: normal;">' +
                    '[Title]' +
                '</div>' +

                '<div style="max-height: 120px; padding: 5px 30px;font-size: 21pt; font-weight: bold;">' +
                    '[FirstName] [LastName]' +
                '</div>' +

                '<div style=" padding: 5px 30px; font-size: 12pt; font-weight: normal;">' +
                    '[Company]' +
                '</div>' +
            '</div >';
    }

    private getGuestBadgeTemplate = () => {
        let logoStyle = '';
        if (this.props.tile.printCompanyLogoCheckbox.value) {
            logoStyle = 'background-image: url(' + this.props.tile.logoDataUrl + ')';
        }

        let abbreviatedWeekDay = '';
        if (this.props.tile.printDayOfWeekCheckbox.value) {
            abbreviatedWeekDay = '' +
                '<div style="position: absolute; bottom: 0; right: 0; font-size: 18pt; font-weight: bold; width: 125px; height: 60px; text-align: center; padding-top: 10px; padding-top: 15px; box-sizing: border-box;">' +
                    '[AbbreviatedWeekDay]' +
                '</div>';
        }

        return '' +
            '<div style="width: 500px; height: 280px; text-align: center; font-family: sans-serif; position: relative; overflow: hidden;">' +

                '<div style="height: 98px; width: 100%; background-repeat: no-repeat; background-position: center; background-size: 300px auto; ' + logoStyle + ' ">&nbsp;</div>' +

                '<div style="max-height:50px; padding: 5px 30px; font-size: 12pt; font-weight: normal;">' +
                '</div>' +

                '<div style="padding: 20px 30px; font-size: 40pt; font-weight: bold;">' +
                    'GUEST' +
                '</div>' +

                '<div style=" padding: 5px 30px; font-size: 12pt; font-weight: normal;">' +
                    '' +
                '</div>' +

                abbreviatedWeekDay +

            '</div >';
    }

    private printGuestBadge = () => {
        const badgeContent = this.getBadgeHtml(
            1,
            this.injectDataIntoBadge(
                this.getGuestBadgeTemplate(),
                '',
                '',
                '',
                '',
                '',
                ''
            )
        );
        this.showPrintWindow(badgeContent);
    }

    private printBadges = () => {
        const { tile } = this.props;

        let windowContent = '';

        var previousMeetingNumber = -1;
        var count = 0;

        for(let i = 0; i < tile.itemsToPrint.length; i++) {
            const item = tile.itemsToPrint[i];

            if (tile.includeSeparatorCardCheckbox.value && item.meetingNumber !== previousMeetingNumber) {
                windowContent += this.getBadgeHtml(
                    count,
                    this.injectDataIntoBadge(
                        this.getSeparatorTemplate(),
                        '',
                        '',
                        item.meetingName,
                        '(' + item.meetingNumber + ')',
                        DateUtils.formatDate(item.meetingStartDateTime, DateFormats.MMDDYYYY, false) + ' ' + DateUtils.formatDate(item.meetingStartDateTime, DateFormats.hhmm, false) + ' - ' + DateUtils.formatDate(item.meetingEndDateTime, DateFormats.hhmm, false) + ' <br/> Guest Count: ' + item.guestCount,
                        ''
                    )
                );
                count++;
            }

            windowContent += this.getBadgeHtml(
                count,
                this.injectDataIntoBadge(
                    this.getBadgeTemplate(),
                    item.eventName,
                    item.title,
                    item.firstName,
                    item.lastName,
                    item.customerCompanyName,
                    item.rank
                )
            );

            previousMeetingNumber = item.meetingNumber;
            count++;
        }

        if (count === 0) {
            return;
        }

        this.showPrintWindow(windowContent);
    }

    private showPrintWindow = (innerContent: string) => {
        var windowContent = '<html><head><title>TEMS Badges</title></head><body><div>';
        windowContent += innerContent;
        windowContent += '</div></body></html>';

        var printWindow = window.open('', 'TEMS_Badges', 'height=800,width=700');
        printWindow.document.write(windowContent);
        printWindow.document.close(); // necessary for IE >= 10
        printWindow.focus(); // necessary for IE >= 10

        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 2000);
    }

    private getBadgeHtml = (badgeNumber: number, badgeContent: string) => {
        var badgeHtml = '';

        if (badgeNumber === 0)
            badgeHtml += '<div style="display: block; ">';
        else
            badgeHtml += '<div style="display: block; page-break-before:always;">';

        badgeHtml += badgeContent;

        badgeHtml += '</div>';

        return badgeHtml;
    }

    private injectDataIntoBadge = (
        template: string,
        eventName: string,
        title: string,
        firstName: string,
        lastName: string,
        company: string,
        rank: string
    ) => {
        var badgeHtml = template;

        var rankTitleSeparator = '';

        if (rank && title) {
            rankTitleSeparator = '/';
        }

        const today = new Date();

        let abbreviatedWeekDay = '';
        switch (today.getDay()) {
            case 0:
                abbreviatedWeekDay = 'SUN'
                break;
            case 1:
                abbreviatedWeekDay = 'MON'
                break;
            case 2:
                abbreviatedWeekDay = 'TUE'
                break;
            case 3:
                abbreviatedWeekDay = 'WED'
                break;
            case 4:
                abbreviatedWeekDay = 'THU'
                break;
            case 5:
                abbreviatedWeekDay = 'FRI'
                break;
            case 6:
                abbreviatedWeekDay = 'SAT'
                break;
        }

        badgeHtml = badgeHtml.replace(/\[Rank\]/g, rank);
        badgeHtml = badgeHtml.replace(/\[RankTitleSeparator\]/g, rankTitleSeparator);
        badgeHtml = badgeHtml.replace(/\[Title\]/g, title);
        badgeHtml = badgeHtml.replace(/\[AbbreviatedWeekDay\]/g, abbreviatedWeekDay);
        badgeHtml = badgeHtml.replace(/\[EventName\]/g, eventName);
        badgeHtml = badgeHtml.replace(/\[FirstName\]/g, firstName);
        badgeHtml = badgeHtml.replace(/\[LastName\]/g, lastName);
        badgeHtml = badgeHtml.replace(/\[Company\]/g, company);

        return badgeHtml;
    }

    private newCustomersMeetingRowClicked = (meeting: NewCustomerMeeting) => (e: Event) => {
        this.props.tile.selectNewCustomersMeeting(meeting);
        e.stopPropagation();
    }

    private buildColumns() {
        const { tile } = this.props;

        return [
            {
                heading: 'Print',
                cellTemplate: (item, index) => <Field field={tile.searchResultForms[index].fields[0]} />,
                className: 'fit-content text-center'
            },
            {
                heading: '#',
                cellTemplate: (item) => <span>{item.meetingNumber}</span>,
                className: 'fit-content text-center'
            },
            {
                heading: 'Rank',
                cellTemplate: (item, index) => <Field field={tile.searchResultForms[index].fields[1]} />,
            },
            {
                heading: 'Title',
                cellTemplate: (item, index) => <Field field={tile.searchResultForms[index].fields[2]} />,
            },
            {
                heading: 'First Name',
                cellTemplate: (item, index) => <Field field={tile.searchResultForms[index].fields[3]} />,
            },
            {
                heading: 'Last Name',
                cellTemplate: (item, index) => <Field field={tile.searchResultForms[index].fields[4]} />,
            },
            {
                heading: 'Company',
                cellTemplate: (item, index) => <Field field={tile.searchResultForms[index].fields[5]} />,
            },
            {
                className: 'text-md fit-content text-center',
                cellTemplate: (item, index) => {

                    if (!!item.badgePrintDateTime) {
                        return (
                            <Icon
                                className="text-green"
                                icon={Icons.Info}
                                title={"Last printed on " + DateUtils.formatDate(item.badgePrintDateTime, DateFormats.dddMMMMDhmmA, true)}>
                            </Icon>
                        );
                    } else if (!!item.isNewCustomer) {
                        return <b className="text-red">NEW</b>
                    } else {
                        return <span></span>;
                    }
                }
            }
        ] as SmartTableColumn<BadgingSearchListItem>[];
    }

    public render({ tile }: BadgingTileProps): JSX.Element {
        return (
            tile.isVisible && <EventInfoTile
                color="purple"
                tile={tile}
                icon="ti-badge">

                <Card
                    className="mt-1"
                    headingClassName="p-p5 border-bottom"
                    heading={
                        <span class="flex">
                            <b class="v-center mr-p5">Step 1:</b>
                            <span class="text-sm v-center fill">
                                Find Personnel to Badge (Use the "New Customer" button to create a badge for a customer that is not in the system)
                            </span>
                        </span>
                    }>

                    <div class="flex">
                        <div class="fill">
                            <form
                                name="searchForm"
                                noValidate>

                                <div class="form-container flex">
                                    <Field
                                        className="inline-label fill v-center"
                                        field={tile.searchForm.fields[0]} />

                                    <Field
                                        className="inline-label fill v-center ml-p5"
                                        field={tile.searchForm.fields[1]} />

                                    <Field
                                        className="inline-label fill v-center ml-p5"
                                        field={tile.searchForm.fields[2]} />

                                    <Field
                                        className="inline-label fill v-center ml-p5"
                                        field={tile.searchForm.fields[3]} />
                                </div>

                                <div class="form-container flex">

                                    <Field
                                        className="inline-label fill v-center"
                                        field={tile.searchForm.fields[4]} />

                                    <Field
                                        className="inline-label fill v-center ml-p5"
                                        field={tile.searchForm.fields[5]} />

                                    <Field
                                        className="inline-label fill v-center ml-p5"
                                        field={tile.searchForm.fields[6]} />

                                    <div class="fill ml-p5 flex">
                                        <Button
                                            className="btn-primary btn-sm m-auto"
                                            button={tile.searchButton} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="flex pl-1 ml-1 border-left">
                            <Button
                                className="btn-primary btn-sm m-auto"
                                button={tile.newCustomerButton} />
                        </div>
                    </div>
                </Card>

                <Card
                    className="mt-1"
                    headingClassName="p-p5 border-bottom"
                    heading={
                        <span class="flex">
                            <b class="v-center mr-p5">Step 2:</b>
                            <span class="text-sm v-center fill">
                                Check box and verify content for each badge needed (Any content change will NOT be reflected in the customer directory)
                            </span>
                        </span>
                    }>

                    <div>
                        <SmartTable
                            tableClassName="text-sm table-small"
                            columns={this.buildColumns()}
                            table={tile.searchResultsTable} />
                    </div>
                </Card>

                <Card
                    className="mt-1"
                    headingClassName="p-p5 border-bottom"
                    heading={
                        <span class="flex">
                            <b class="v-center mr-p5">Step 3:</b>
                            <span class="text-sm v-center fill">
                                Specifiy the information you wish to print on each badge.
                            </span>
                        </span>
                    }>

                    <div class="form-container">

                        <Field
                            className="right-label"
                            field={tile.printRankCheckbox} />

                        <Field
                            className="right-label"
                            field={tile.printTitleCheckbox} />

                        <Field
                            className="right-label"
                            field={tile.printNameCheckbox} />

                        <Field
                            className="right-label"
                            field={tile.printCompanyNameCheckbox} />

                        <Field
                            className="right-label"
                            field={tile.printCompanyLogoCheckbox} />

                        <Field
                            className="right-label"
                            field={tile.printDayOfWeekCheckbox} />
                    </div>
                </Card>

                <Card
                    className="mt-1"
                    headingClassName="p-p5 border-bottom"
                    heading={
                        <span class="flex">
                            <b class="v-center mr-p5">Step 4:</b>
                            <span class="text-sm v-center fill">
                                Print the badges requested (After the "Print" button is pressed you will see the badges in a new window. Use your browser's print function to print the badges)
                            </span>
                        </span>
                    }>

                    <div>
                        <div class="flex">
                            <Field
                                className="m-auto inline-label"
                                field={tile.includeSeparatorCardCheckbox} />
                        </div>

                        <div class="flex mt-p5">
                            <Button
                                className="btn-primary m-auto"
                                button={tile.printButton} />
                        </div>

                        <div class="flex mt-p5">
                            <Button
                                className="btn-primary m-auto"
                                button={tile.printGuestBadgeButton} />
                        </div>
                    </div>
                </Card>

                <QuickAddCustomerForm quickAddCustomerForm={tile.quickAddCustomerForm} />

                {!!tile.newCustomersToAddToMeetings && !!tile.newCustomersToAddToMeetings.length &&
                    <Modal
                        heading="Add to Meetings"
                        close={tile.closeAddNewCustomerToMeeting}
                        hideBackButton={true}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={tile.closeAddNewCustomerToMeetingButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={tile.saveNewCustomersToMeetingButton} />
                        ]}>

                        <div className="form-container text-sm">
                            <div class="flex">
                                <Field
                                    className="width-8"
                                    field={tile.newCustomerMeetingSearchDateField} />

                                <Field
                                    className="fill ml-1"
                                    field={tile.newCustomerMeetingSearchTextField} />

                                <div className="mt-auto ml-1">
                                    <Button
                                        className="btn-sm"
                                        color={ButtonColors.Primary}
                                        button={tile.newCustomerMeetingSearchButton} />
                                </div>
                            </div>

                            <hr className="mt-1"/>

                            {tile.isSearchingNewCustomerMeetings &&
                                <table className="table table-striped table-small mt-2">
                                    <TableLoadingPlaceholderTBody
                                        rowCount={5}
                                        columnCount={4} />
                                </table>
                            }

                            {!tile.isSearchingNewCustomerMeetings &&
                             !tile.newCustomerHasSearchedMeetings &&
                             !tile.newCustomerMeetings.length &&

                                <div className="mt-2">
                                    <b className="text-lg">Search Meetings</b>
                                    <div>Use the search box above to find the meeting that you like to assign the new customer(s) to.</div>
                                </div>
                            }

                            {!tile.isSearchingNewCustomerMeetings &&
                              tile.newCustomerHasSearchedMeetings &&
                              !tile.newCustomerMeetings.length &&

                                <EmptyState message="No Meetings Found" />
                            }

                            {!tile.isSearchingNewCustomerMeetings &&
                             !!tile.newCustomerMeetings.length &&

                                <table className="table table-striped table-small table-hover text-sm mt-2">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th colSpan={2}>Name</th>
                                        </tr>
                                    </thead>

                                    {tile.newCustomerMeetings.map((meeting, index) =>
                                        <tbody onClick={this.newCustomersMeetingRowClicked(meeting)}>
                                            <tr class={'cursor-pointer ' + (tile.selectedMeetingForNewCustomers && meeting.id === tile.selectedMeetingForNewCustomers.id ? ' selected ' : '')}>
                                                <td className="fit-content">{meeting.meetingNumber}</td>
                                                <td className="fit-content">
                                                    <FormattedDate
                                                        date={meeting.startDateTime}
                                                        format={DateFormats.MMDDYYYY} />
                                                </td>
                                                <td className="fit-content">
                                                    <FormattedDate
                                                        date={meeting.startDateTime}
                                                        format={DateFormats.hhmm} />

                                                    <span> - </span>

                                                    <FormattedDate
                                                        date={meeting.endDateTime}
                                                        format={DateFormats.hhmm} />
                                                </td>
                                                <td>
                                                    {meeting.name}
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            }
                        </div>
                    </Modal>
                }

            </EventInfoTile>
        );
    }
}

export default BadgingTile;
