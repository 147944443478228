import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import LeftPageNavProps from './leftPageNavProps';
import Button from '../buttons/button';

class LeftPageNav extends ObservingComponent<LeftPageNavProps> {

    public render(): JSX.Element {
        const { dropdownItems, heading, className } = this.props;

        let wrappingClass = 'mr-1 page-nav card ';
        if (className) {
            wrappingClass += className
        }

        return (
            <ul class={wrappingClass}>
                <li class="pl-1 pt-1 pr-1 pb-1">
                    <b>{heading}</b>
                </li>

                {dropdownItems.map((dropdownItem) =>
                    <li>
                        <Button button={dropdownItem.button} />
                    </li>
                )}
            </ul>
        );
    }
}

export default LeftPageNav;
