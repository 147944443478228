import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import Button from '../buttons/button';
import Field from '../fields/field';
import LabeledDivider from '../text/labeledDivider';
import CompleteAccessRequestPageProps from './completeAccessRequestPageProps';
import FormButtonContainer from '../containers/formButtonContainer';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import FieldLoadingPlaceholder from '../fields/fieldLoadingPlaceholder';
import InternationalPhoneField from '../fields/internationalPhoneField';

class CompleteAccessRequestForm extends ObservingComponent<CompleteAccessRequestPageProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: CompleteAccessRequestPageProps): JSX.Element {

        return (
            <form noValidate>

                <div class="card-header text-center">
                    <img src="img/logo-blue.png" class="header-logo-blue" />
                    <LabeledDivider text="Tell us about yourself" />
                </div>

                <div class="card-main">
                    <Alert alert={page.alert} />

                    {!page.isLoading && !page.invalidAccessRequest &&
                        <div>
                            <div class="form-container grid-container mt-0">

                                <Field
                                    field={page.firstNameField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.lastNameField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.titleField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.productKnowledgeField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <InternationalPhoneField
                                    className="stacked-label sm-6"
                                    field={page.contactPhoneField}
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.departmentField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.lineOfBusinessField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />
                            </div>

                            <div class="form-container grid-container mt-0">
                                <LabeledDivider text="Create your Login" />

                                <Field
                                    field={page.loginField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.contactEmailField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.passwordField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />

                                <Field
                                    field={page.confirmPasswordField}
                                    className="stacked-label sm-6"
                                    isLoading={page.isLoading} />
                            </div>

                            <p>
                                Password must be at least 8 characters, contain 1 upper-case, 1 lower-case, 1 special character and not be one of your previous 12 passwords.
                            </p>

                            <FormButtonContainer
                                rightButtons={[
                                    <Button
                                        color={ButtonColors.Primary}
                                        className="btn-full-width btn-primary mt-2"
                                        button={page.submitButton} />
                                ]}
                            />

                        </div>
                    }

                    {page.isLoading &&
                        <div class="form-container grid-container">
                            <FieldLoadingPlaceholder className="sm-12"/>
                            <FieldLoadingPlaceholder className="sm-12"/>
                            <FieldLoadingPlaceholder className="sm-12"/>
                            <FieldLoadingPlaceholder className="sm-12"/>
                            <FieldLoadingPlaceholder className="sm-12"/>
                            <FieldLoadingPlaceholder className="sm-12"/>
                            <FieldLoadingPlaceholder className="sm-12"/>
                            <FieldLoadingPlaceholder className="sm-12"/>
                        </div>
                    }

                    {page.invalidAccessRequest && page.isSso === false &&
                        <Button
                            color={ButtonColors.Primary}
                            className="btn btn-full-width mt-2"
                            button={page.resubmitButton} />
                    }
                </div>

            </form>
        );
    }
}

export default CompleteAccessRequestForm;
