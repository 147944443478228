import { App, IPage, NavigatorActions } from 'core.frontend';
import { JSX } from 'preact';
import PageRoute from './pageRoute';
import routes from './routes';

export default class Router {
    private _pageRoutes: PageRoute<IPage>[];
    private _pageTypesDictionary: PageRoute<IPage>[];
    private _pageHashDictionary: PageRoute<IPage>[];
    private _ignorePopState: boolean;

    constructor(private app: App) {
        window.onpopstate = () => {
            // ignore popState when router explicitly sets hash when routing to new page
            if (this._ignorePopState) {
                this._ignorePopState = false;
                return;
            }

            this.routeToUrl();
        };

        this.buildPageRoutes();

        app.navigator.observationProvider.observe(this.setRoute, NavigatorActions.PageChanged);

        app.navigator.observationProvider.observe((url) => {
            this.routeToUrl(url as string);
        }, NavigatorActions.NavigatingToUrl);

        this.routeToUrl();
    }


    public routeToUrl = (url?: string) => {
        if (!url) {
            url = window.location.hash;
        }

        let pageLookup = url;

        if (pageLookup.indexOf('?') >= 0) {
            pageLookup = pageLookup.substring(0, pageLookup.indexOf('?'))
        }

        pageLookup = pageLookup.replace('#', '')
            .replace(/\b[a-f\d-]{36}\b/, '')
            .replace(/[0-9]/g, '')
            .replace('//', '/');

        const page = this._pageHashDictionary[pageLookup];

        if (page) {
            page.navigateTo(url, this.app.navigator);
        } else {
            this.app.navigator.goToLogin();
        }
    }

    private setRoute = (currentPage: IPage) => {
        let pageType = this._pageTypesDictionary[currentPage.pageType];

        if (!pageType) {
            return
        }

        // ignore popState when router explicitly sets hash when routing to new page
        this._ignorePopState = true;

        window.location.hash = pageType.buildUrlHash(currentPage);
    }

    public getCurrentPageComponent = (): JSX.Element  => {
        const currentPage = this.app.navigator.currentPage;

        return this._pageTypesDictionary[currentPage.pageType].getComponent(currentPage);
    }

    private buildPageRoutes = () => {
        this._pageHashDictionary = [];
        this._pageTypesDictionary = [];
        this._pageRoutes = routes;

        for (const route of this._pageRoutes) {
            this._pageTypesDictionary[route.pageType] = route;
            this._pageHashDictionary[route.hash] = route;
        }
    }
}
