"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FormActions;
(function (FormActions) {
    FormActions["FormSubmitted"] = "FormSubmitted";
    FormActions["FormSubmissionFailure"] = "FormSubmissionFailure";
    FormActions["ModelLoadedToForm"] = "ModelLoadedToForm";
    FormActions["ModelChanged"] = "ModelChanged";
})(FormActions || (FormActions = {}));
exports.default = FormActions;
