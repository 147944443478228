"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("..");
var button_1 = require("../button/button");
var PasswordField = /** @class */ (function (_super) {
    __extends(PasswordField, _super);
    function PasswordField(key, isRequired, initialValue) {
        var _this = _super.call(this, key, 'Password', isRequired, 25, 'Password is required', initialValue, _1.FieldType.Password) || this;
        _this.key = key;
        _this.toggleShowPassword = function () {
            _this._showPassword = _this._showPassword !== true;
            if (_this._showPassword) {
                _this.showPasswordButton.setLeftIcon(__1.Icons.EyeSlash);
            }
            else {
                _this.showPasswordButton.setLeftIcon(__1.Icons.Eye);
            }
            _this.observationProvider.notifyObservers(_1.FieldActions.PasswordVisibilityToggled);
        };
        _this.showPasswordButton = new button_1.default({
            leftIcon: __1.Icons.Eye,
            onClick: _this.toggleShowPassword
        });
        return _this;
    }
    Object.defineProperty(PasswordField.prototype, "showPassword", {
        get: function () {
            return this._showPassword;
        },
        enumerable: true,
        configurable: true
    });
    return PasswordField;
}(_1.TextField));
exports.default = PasswordField;
